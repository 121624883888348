import { MassImportExpectedType } from '@bits-app/voggtpit-shared';
import { type MRT_ColumnDef } from 'material-react-table';

import { MassImportResolver } from './matrice';
import { AllowedFeatureForMassImport, GenericCsv } from './type';

export const MASS_IMPORT_COLUMNS_TEMPLATE: Record<AllowedFeatureForMassImport, string[]> = {
  updateFee: ['userid', 'percentagefee', 'fixedfee', 'includeshippingfeesforcommissionbydefault'],
  transferFund: [
    'userId',
    'amount',
    'description',
    'currency',
    'transferReason',
    'transferType',
    'orderId',
    'relatedMonth',
    'shipmentId',
    'showId',
    'sellerId',
  ],
  insertSellerCompany: [
    'userId',
    'legalName',
    'address',
    'city',
    'zipcode',
    'state',
    'country',
    'phone',
    'vatId',
    'isValid',
    'vatIdValidSince',
    'registrationNumber',
    'bankAccountNumber',
    'bic',
    'bankName',
    'routingNumber',
    'taxId',
    'taxIdRegistrar',
    'lucidId',
  ],
  updateBadge: ['userId', 'badgeId'],
};

export function resolveMassImportFeatureBasedOnCsvHeader(header: GenericCsv = {}): {
  name: string | null;
  key: AllowedFeatureForMassImport | null;
} {
  const keys = Object.keys(header);

  const isUpdateFees = MASS_IMPORT_COLUMNS_TEMPLATE.updateFee.every((k) => keys.includes(k));
  if (isUpdateFees) {
    return {
      name: 'Update fees',
      key: 'updateFee',
    };
  }

  const isTest = MASS_IMPORT_COLUMNS_TEMPLATE.insertSellerCompany.every((k) => keys.includes(k));
  if (isTest) {
    return {
      name: 'Test',
      key: 'insertSellerCompany',
    };
  }

  const isTransferFund = MASS_IMPORT_COLUMNS_TEMPLATE.transferFund.every((k) => keys.includes(k));
  if (isTransferFund) {
    return {
      name: 'Transfer fund',
      key: 'transferFund',
    };
  }

  const isUpdateBadge = MASS_IMPORT_COLUMNS_TEMPLATE.updateBadge.every((k) => keys.includes(k));
  if (isUpdateBadge) {
    return {
      name: 'Update badge',
      key: 'updateBadge',
    };
  }

  return {
    name: null,
    key: null,
  };
}

export const resultWithValidation = (
  results: GenericCsv[],
  key: AllowedFeatureForMassImport | null,
) => {
  if (!key) {
    return { resultFormatted: [], hasError: true };
  }

  const parseResult = MassImportResolver[key].validate.safeParse(results);

  if (parseResult.success) {
    return { resultFormatted: results, hasError: false };
  }

  const resultFormatted = results.map((result, index) => {
    const errors = parseResult.error.errors.filter((error) => error.path[0] === index);

    return Object.entries(result).reduce<
      GenericCsv | Record<string, { value: unknown; message: string }>
    >((acc, [key, value]) => {
      const message = errors.find((error) => error.path[1] === key)?.message;
      acc[key] = message ? { value, message } : value;
      return acc;
    }, {});
  });

  return {
    resultFormatted,
    hasError: true,
  };
};

export const getMassImportColumns = (
  result: GenericCsv = {},
): MRT_ColumnDef<GenericCsv | Record<string, { value: string; message: string }>>[] => {
  const columns: MRT_ColumnDef<GenericCsv | Record<string, { value: string; message: string }>>[] =
    Object.keys(result).map((key) => ({
      header: key,
      accessorKey: key,
      Cell: ({ cell }) => {
        const cellValue = cell.getValue() as { value: string; message: string } | string;
        if (!cellValue) {
          return <div />;
        }

        if (typeof cellValue === 'string') {
          return <div>{cellValue}</div>;
        }

        return (
          <div className="text-red-400 flex flex-col">
            <p className="font-bold">{cellValue.value}</p>
            <p>{cellValue.message}</p>
          </div>
        );
      },
    }));

  return [
    {
      header: 'Backend validation',
      accessorKey: '_backendValidation_',
      Cell: ({ cell }) => {
        const cellValue = cell.getValue();
        if (!cellValue || typeof cellValue !== 'string') {
          return <div />;
        }

        if (cellValue === 'ok') {
          return <div>✅</div>;
        }

        return <div className="text-red-400 font-bold">{cellValue}</div>;
      },
    },
    ...columns,
  ];
};

export const withErrorFromBackend = (
  initialData: GenericCsv[],
  backEndValidationResults: MassImportExpectedType[],
  hasPassValidation: boolean,
): GenericCsv[] => {
  return initialData.map((currentRow, index) => {
    if (hasPassValidation) {
      return {
        ...currentRow,
        _backendValidation_: 'ok',
      };
    }

    const rowResult = backEndValidationResults.find((error) => error.rowId === index);
    if (rowResult === undefined) {
      return currentRow;
    }

    if (rowResult.isValid) {
      return {
        ...currentRow,
        _backendValidation_: 'ok',
      };
    }

    if (rowResult.errorMessage) {
      return {
        ...currentRow,
        _backendValidation_: rowResult.errorMessage,
      };
    }

    return currentRow;
  });
};
