import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '../../../../components/elements/buttons/ButtonContained';
type RefundAshowModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  showId: string;
  isLoading: boolean;
};

export const RefundAshowModal = ({
  onCancel,
  onConfirm,
  showId,
  isLoading,
}: RefundAshowModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open>
      <DialogTitle id="alert-dialog-title">
        {t('databaseExplorer.show.refund.title', { showId })}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{t('databaseExplorer.show.refund.description')}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={isLoading}>
          {t('action.cancel')}
        </Button>
        <ButtonContained onClick={onConfirm} disabled={isLoading}>
          {t('action.continue')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
