import {
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryColumn,
  UpdateDateColumn,
} from 'typeorm';

import { User } from './User';

@Entity()
export class UserBannedFromSeller {
  @Index()
  @PrimaryColumn()
  userId!: number;

  @Index()
  @PrimaryColumn()
  sellerId!: number;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date | null;

  @DeleteDateColumn({ name: 'deletedAt', type: 'timestamp' })
  deletedAt!: Date | null;

  @ManyToOne(() => User, (user) => user.bannedUsersRelations)
  @JoinColumn({
    name: 'sellerId',
    referencedColumnName: 'id',
  })
  seller!: User;

  @ManyToOne(() => User, (user) => user.bannedFromSellersRelations)
  @JoinColumn({
    name: 'userId',
    referencedColumnName: 'id',
  })
  user!: User;
}
