import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';

export const CreateMarketPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const redirectToCreateForm = () => navigate('/market-pages/create');

  return (
    <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}>
      <ButtonOutlined onClick={redirectToCreateForm}>{t('Create Market Page')}</ButtonOutlined>
    </Box>
  );
};
