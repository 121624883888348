import { Currency } from '@bits-app/voggtpit-shared';

import { authActions } from '@/auth/auth.slice';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { sellerCancelRequestsActions } from '../redux/seller-cancel-requests.slice';

export const getSellerCancelRequestsCountPerCountries =
  (currency: Currency): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { sellerCancelRequestsGateway, snackbarGateway }) => {
    try {
      const count = await sellerCancelRequestsGateway.getSellerCancelRequestsCountPerCountries(
        currency,
        {
          abortPrevious: true,
        },
      );

      dispatch(sellerCancelRequestsActions.setCountPerCountries(count));
    } catch (error) {
      logger.error(error);

      if (error instanceof CanceledRequestError) {
        return;
      }

      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));
        dispatch(sellerCancelRequestsActions.setLoading(false));

        return;
      }

      dispatch(sellerCancelRequestsActions.setError(String(error)));
      dispatch(sellerCancelRequestsActions.setLoading(false));

      snackbarGateway.error('somethingWrongHappened');
    }
  };
