import { CommercialEvent } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { QUERIES_KEYS } from '../../../queries/queries-keys';

export const useCommercialEvent = (showId: number) => {
  return useQuery(QUERIES_KEYS.showAssociatedCommercialEventList(showId), () =>
    authenticatedInstance
      .get<CommercialEvent[]>(`/database-explorer/commercial-event/by-show/${showId}`)
      .then((response) => response.data),
  );
};
