import { Alert, Dialog, DialogContent, List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ErrorDialogProps = {
  errors: string[];
  onClose: () => void;
};

export const ErrorDialog = ({ errors, onClose }: ErrorDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <Alert severity="error" sx={{ display: 'flex', alignItems: 'center' }} onClose={onClose}>
        {t('somethingWrongHappened')}
      </Alert>

      <DialogContent>
        <List>
          {errors.map((error) => (
            <ListItem key={error} sx={{ fontSize: '0.875rem' }}>
              {error}
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};
