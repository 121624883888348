// eslint-disable-next-line max-classes-per-file
import {
  ChildEntity,
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  TableInheritance,
  UpdateDateColumn,
} from 'typeorm';

import { Order } from './Order';
import { PaymentMethodBancontact } from './PaymentMethodBancontact';
import { PaymentMethodCard } from './PaymentMethodCard';
import { PaymentMethodGiropay } from './PaymentMethodGiropay';
import { PaymentMethodIdeal } from './PaymentMethodIdeal';
import { PaymentMethodPaypal } from './PaymentMethodPaypal';
import { PaymentMethodPaypalRecurringPayment } from './PaymentMethodPaypalRecurringPayment';

export enum OrderEventType {
  status = 'status',
  payment = 'payment',
  shipping = 'shipping',
}

export enum OrderEventStatus {
  created = 'created',
  canceled = 'canceled',
}

export enum OrderEventShipping {
  shipped = 'shipped',
  delivered = 'delivered',
  received = 'received',
}

export enum OrderEventPayment {
  attempt = 'attempt',
  retry = 'retry',
  in_progress = 'in_progress',
  aborted = 'aborted',
  success_pending_sepa = 'success_pending_sepa',
  success = 'success',
  failure = 'failure',
  refunded = 'refunded',
}

export enum OrderPaymentCancellationOrRefundReason {
  duplicate = 'duplicate',
  fraudulent = 'fraudulent',
}

export const ORDER_EVENT_PAYMENTS_CONSIDERED_AS_IN_PROGRESS = [
  OrderEventPayment.in_progress,
  OrderEventPayment.attempt,
  OrderEventPayment.retry,
];

export const ORDER_EVENT_PAYMENTS_CONSIDERED_AS_SUCCESS = [
  OrderEventPayment.success,
  OrderEventPayment.success_pending_sepa,
];

@Entity()
@TableInheritance({
  column: {
    type: 'enum',
    name: 'type',
    enum: OrderEventType,
  },
})
export class OrderEvent {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column({ type: 'enum', enum: OrderEventType })
  type!: OrderEventType;

  @ManyToOne(() => Order)
  @JoinColumn({ name: 'orderId' })
  order!: Order;

  @Index()
  @Column()
  orderId!: number;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;
}

@ChildEntity(OrderEventType.payment)
export class PaymentOrderEvent extends OrderEvent {
  @Column({ type: 'enum', enum: OrderEventPayment })
  paymentEvent!: OrderEventPayment;

  @Column({ nullable: true })
  stripePaymentIntentId!: string;

  @ManyToOne(() => PaymentMethodBancontact)
  @JoinColumn({ name: 'paymentMethodBancontactId' })
  paymentMethodBancontact!: PaymentMethodBancontact | null;

  @Column({ nullable: true })
  paymentMethodBancontactId!: number | null;

  @ManyToOne(() => PaymentMethodCard)
  @JoinColumn({ name: 'paymentMethodId' })
  paymentMethodCard!: PaymentMethodCard | null;

  @Column({ name: 'paymentMethodId', nullable: true })
  paymentMethodCardId!: number | null;

  @ManyToOne(() => PaymentMethodGiropay)
  @JoinColumn({ name: 'paymentMethodGiropayId' })
  paymentMethodGiropay!: PaymentMethodGiropay | null;

  @Column({ nullable: true })
  paymentMethodGiropayId!: number | null;

  @ManyToOne(() => PaymentMethodIdeal)
  @JoinColumn({ name: 'paymentMethodIdealId' })
  paymentMethodIdeal!: PaymentMethodIdeal | null;

  @Column({ nullable: true })
  paymentMethodIdealId!: number | null;

  @ManyToOne(() => PaymentMethodPaypal)
  @JoinColumn({ name: 'paymentMethodPaypalId' })
  paymentMethodPaypal!: PaymentMethodPaypal | null;

  @Column({ nullable: true })
  paymentMethodPaypalId!: number | null;

  @ManyToOne(() => PaymentMethodPaypalRecurringPayment)
  @JoinColumn({ name: 'paymentMethodPaypalRecurringPaymentId' })
  paymentMethodPaypalRecurringPayment!: PaymentMethodPaypalRecurringPayment | null;

  @Column({ nullable: true })
  paymentMethodPaypalRecurringPaymentId!: number | null;

  @Column({
    type: 'enum',
    nullable: true,
    enum: OrderPaymentCancellationOrRefundReason,
    enumName: 'order_payment_cancellation_reason',
  })
  reason!: OrderPaymentCancellationOrRefundReason;
}

@ChildEntity(OrderEventType.status)
export class StatusOrderEvent extends OrderEvent {
  @Column({ type: 'enum', enum: OrderEventStatus })
  statusEvent!: OrderEventStatus;
}

@ChildEntity(OrderEventType.shipping)
export class ShippingOrderEvent extends OrderEvent {
  @Column({ type: 'enum', enum: OrderEventShipping })
  shippingEvent!: OrderEventShipping;
}
