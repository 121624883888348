import { useMemo } from 'react';

import { Show } from '@bits-app/bits-server-data';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';
import { ItemLayout } from '@/voggt-database-explorer/layouts/ItemLayout';

import { Detail } from './Detail';
import { useCountryDetailsView } from './useCountryDetailsView';

export const CountryDetail = () => {
  const { t } = useTranslation();

  const { id: countryId = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { CellShowDefinition, ...rest } = useCountryDetailsView(countryId);

  const currentTab = useMemo(() => {
    return ['show'].find((path) => location.pathname.includes(path)) ?? '/';
  }, [location.pathname]);

  const handleOnTabChange = (tab: string) =>
    navigate(`/country/${countryId}${tab === '/' ? '' : '/' + tab}`);

  const tabs = [
    {
      path: '/',
      label: t('databaseExplorer.country.tabs.main'),
      Element: <Detail key="/" {...rest} />,
    },

    {
      path: 'show',
      label: t('databaseExplorer.country.tabs.shows'),
      Element: (
        <ListWithSearch<Show & { id: string }>
          title="Shows"
          key="show"
          cellDefinition={CellShowDefinition}
          defaultFilters={[{ field: 'countryId', values: [countryId] }]}
          typesenseHookParams={{
            typesenseIndex: 'show',
            typesenseParams: {
              per_page: 20,
              query_by: ['userId'],
            },
          }}
        />
      ),
    },
  ];

  return (
    <ItemLayout
      id={countryId}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
    />
  );
};
