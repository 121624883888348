import { useState } from 'react';

import {
  Currency,
  currencySymbol,
  DynamicFieldData,
  formatAmount,
  unitToCents,
} from '@bits-app/voggtpit-shared';
import { InputAdornment, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useAmountInput } from '@Elements/inputs/AmountInput';

type AmountDynamicInputProps<T> = Pick<
  DynamicFieldData<T>,
  'field' | 'label' | 'disabled' | 'extraData' | 'value'
>;

export const AmountDynamicInput = <T,>({
  field,
  label,
  disabled,
  value,
  extraData,
}: AmountDynamicInputProps<T>) => {
  const { register } = useFormContext();

  const [amount, setAmount] = useState<string>(value != null ? formatAmount(value as number) : '');
  const { onAmountChange, onFormatAmount } = useAmountInput(amount, setAmount);

  const methods = register(field, {
    onChange: onAmountChange,
    onBlur: onFormatAmount,
    setValueAs: (value) => {
      if (value === '') {
        return;
      }

      return unitToCents(Number(value));
    },
  });

  return (
    <TextField
      fullWidth
      label={label}
      type="text"
      disabled={disabled}
      value={amount}
      InputProps={{
        startAdornment: extraData?.currency && (
          <InputAdornment position="end">
            {currencySymbol((extraData?.currency as Currency) ?? Currency.eur)}
          </InputAdornment>
        ),
        inputProps: {
          lang: 'en_US',
          pattern: '[0-9]*.?[0-9]*',
          min: 0,
          sx: {
            pl: 1,
          },
        },
      }}
      {...methods}
    />
  );
};
