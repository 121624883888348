import { useCallback, useEffect, useMemo } from 'react';

import {
  PaymentMethodBancontact,
  PaymentMethodCard,
  PaymentMethodGiropay,
  PaymentMethodIdeal,
  PaymentMethodPaypal,
} from '@bits-app/bits-server-data';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';
import { useAppDispatch } from '@/redux/reduxAppHooks';
import { ItemLayoutWithTabs } from '@/voggt-database-explorer/layouts/ItemLayoutWithTabs';
import { cleanUpResource } from '@/voggt-database-explorer/redux/use-cases/clean-up-resource';

import { availablePaymentMethod, deletePrefix } from '../../payment-method.type';

import { useCellDefinition } from './use-cell-definition';

export const ListPaymentMethod = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const {
    CellPaymentMethodCardDefinition,
    CellPaymentMethodPaypalDefinition,
    CellPaymentMethodGiropayDefinition,
    CellPaymentMethodIdealDefinition,
    CellPaymentMethodBancontactDefinition,
  } = useCellDefinition();

  const currentTab = useMemo(() => {
    return availablePaymentMethod.find((path) =>
      location.pathname.includes(`/payment-method/${deletePrefix(path)}`),
    )
      ? location.pathname.replace(`/payment-method/`, '')
      : 'card';
  }, [location.pathname]);

  const handleOnTabChange = useCallback((tab: string) => navigate(tab), [navigate]);

  const tabs = useMemo(
    () => [
      {
        path: 'card',
        label: t(`databaseExplorer.paymentmethod.tabs.card`),
        Element: (
          <ListWithSearch<PaymentMethodCard & { id: string; user: string }>
            key="card"
            cellDefinition={CellPaymentMethodCardDefinition}
            typesenseHookParams={{
              typesenseIndex: 'payment_method_card',
              typesenseParams: {
                per_page: 20,
                query_by: ['id'],
              },
            }}
            dataListProps={{
              getPathDetail: (paymentMethod) => `/payment-method/card/${paymentMethod.id}`,
            }}
          />
        ),
      },
      {
        path: 'bancontact',
        label: t(`databaseExplorer.paymentmethod.tabs.bancontact`),
        Element: (
          <ListWithSearch<PaymentMethodBancontact & { id: string; user: string }>
            key="bancontact"
            cellDefinition={CellPaymentMethodBancontactDefinition}
            typesenseHookParams={{
              typesenseIndex: 'payment_method_bancontact',
              typesenseParams: {
                per_page: 20,
                query_by: ['id'],
              },
            }}
            dataListProps={{
              getPathDetail: (paymentMethod) => `/payment-method/bancontact/${paymentMethod.id}`,
            }}
          />
        ),
      },
      {
        path: 'ideal',
        label: t(`databaseExplorer.paymentmethod.tabs.ideal`),
        Element: (
          <ListWithSearch<PaymentMethodIdeal & { id: string; user: string }>
            key="ideal"
            cellDefinition={CellPaymentMethodIdealDefinition}
            typesenseHookParams={{
              typesenseIndex: 'payment_method_ideal',
              typesenseParams: {
                per_page: 20,
                query_by: ['id'],
              },
            }}
            dataListProps={{
              getPathDetail: (paymentMethod) => `/payment-method/ideal/${paymentMethod.id}`,
            }}
          />
        ),
      },
      {
        path: 'paypal',
        label: t(`databaseExplorer.paymentmethod.tabs.paypal`),
        Element: (
          <ListWithSearch<PaymentMethodPaypal & { id: string; user: string }>
            key="paypal"
            cellDefinition={CellPaymentMethodPaypalDefinition}
            typesenseHookParams={{
              typesenseIndex: 'payment_method_paypal',
              typesenseParams: {
                per_page: 20,
                query_by: ['id'],
              },
            }}
            dataListProps={{
              getPathDetail: (paymentMethod) => `/payment-method/paypal/${paymentMethod.id}`,
            }}
          />
        ),
      },
      {
        path: 'giropay',
        label: t(`databaseExplorer.paymentmethod.tabs.giropay`),
        Element: (
          <ListWithSearch<PaymentMethodGiropay & { id: string; user: string }>
            key="giropay"
            cellDefinition={CellPaymentMethodGiropayDefinition}
            typesenseHookParams={{
              typesenseIndex: 'payment_method_giropay',
              typesenseParams: {
                per_page: 20,
                query_by: ['id'],
              },
            }}
            dataListProps={{
              getPathDetail: (paymentMethod) => `/payment-method/giropay/${paymentMethod.id}`,
            }}
          />
        ),
      },
    ],
    [
      CellPaymentMethodBancontactDefinition,
      CellPaymentMethodCardDefinition,
      CellPaymentMethodGiropayDefinition,
      CellPaymentMethodIdealDefinition,
      CellPaymentMethodPaypalDefinition,
      t,
    ],
  );

  useEffect(() => {
    return () => dispatch(cleanUpResource());
  }, [dispatch]);

  return <ItemLayoutWithTabs tabs={tabs} currentTab={currentTab} onTabChange={handleOnTabChange} />;
};
