import { Translation } from '@bits-app/voggtpit-shared';
import { FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TranslationFormProps = {
  translationKey: string;
  translations: Translation[];
};

export const TranslateKeyForm = ({ translationKey, translations }: TranslationFormProps) => {
  const { t } = useTranslation();

  const { register, formState } = useFormContext();

  return (
    <Grid container flexDirection="column" flexWrap="nowrap" gap={2}>
      <Grid item xs={6}>
        <Typography fontWeight="bold">{translationKey}</Typography>
      </Grid>

      <Grid item container flexDirection="column" flex={1} gap={2}>
        {translations.map(({ id, language }) => (
          <Grid key={id} item container gap={2}>
            <Grid item xs={1}>
              {language}
            </Grid>

            <Grid item flex={1}>
              <TextField
                multiline
                fullWidth
                size="small"
                {...register(String(id), { required: language === 'en' })}
              />

              {formState.errors[id] && (
                <FormHelperText error>
                  {formState.errors[id]?.type === 'required'
                    ? t('databaseExplorer.translate.englishRequired')
                    : (formState.errors['id']?.message as string)}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
