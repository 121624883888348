import { User } from '@bits-app/bits-server-data';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';

import { ButtonContained } from '@Elements/buttons/ButtonContained';

type RestoreUserAccountModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  toggle: () => void;
  user: User;
};

export const RestoreUserAccountModal = ({
  user,
  onConfirm,
  toggle,
  isOpen,
}: RestoreUserAccountModalProps) => {
  return (
    <Dialog open={isOpen}>
      <Grid container flexDirection="column" p={1} gap={1}>
        <Grid item>
          <DialogTitle id="alert-dialog-title">Restore user account</DialogTitle>

          <DialogContent>
            <DialogContentText>
              If you validate, the account of the user will be restored.
              {user.userSso && (
                <span>
                  The SSO will be restored. This means that the user will be able to log in with
                  their SSO account.
                </span>
              )}
            </DialogContentText>
          </DialogContent>
        </Grid>

        <DialogActions>
          <Button onClick={toggle}>Cancel</Button>
          <ButtonContained onClick={onConfirm}>Restore account</ButtonContained>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};
