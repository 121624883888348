import {
  Column,
  CreateDateColumn,
  Entity,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Category } from './Category';
import { FeaturedShowConcept } from './FeaturedShowConcept';

@Entity()
export class ParentCategory {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @OneToMany(() => Category, (category) => category.parentCategory)
  categories!: Category[];

  @Column()
  name!: string;

  @Column()
  shapeUrl!: string;

  @Column()
  coverFilename!: string;

  @Column({ default: 0 })
  positionIndex!: number;

  @Column()
  primaryColor!: string;

  @Column()
  secondaryColor!: string;

  @OneToMany(
    () => FeaturedShowConcept,
    (featuredShowConcept) => featuredShowConcept.parentCategory,
  )
  featuredShowConcepts!: FeaturedShowConcept[];

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;
}
