import { useEffect, useMemo } from 'react';

import { MaterialReactTableProps, MRT_ColumnFiltersState } from 'material-react-table';

import { AddFilterFunctionParams, FindOptions } from '@/hooks/use-table-options';

import { FiltersAPIType } from '../datalist-type';

export const useFilters = ({
  options,
  setFilters,
  filtersAPI,
  scrollToTop,
}: {
  options: FindOptions;
  setFilters: (filters: AddFilterFunctionParams[]) => void;
  filtersAPI?: FiltersAPIType;
  scrollToTop: () => void;
}): [MRT_ColumnFiltersState, MaterialReactTableProps['onGlobalFilterChange']] => {
  const filters = useMemo(() => fromOptionToState(options.filters), [options.filters]);

  const handleOnFilterChange: MaterialReactTableProps['onColumnFiltersChange'] = (
    updaterOrValue,
  ) => {
    const value = typeof updaterOrValue === 'function' ? updaterOrValue(filters) : updaterOrValue;

    setFilters(value.map(({ id, value }) => ({ field: id, values: value as string[] })));
  };

  useEffect(() => {
    if (!filtersAPI?.defaultFilters || filters.length > 0) {
      return;
    }

    setFilters(filtersAPI.defaultFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersAPI, setFilters]);

  useEffect(() => {
    if (!filtersAPI) {
      return;
    }

    filtersAPI.onFiltersChange(options.filters);
    scrollToTop();
  }, [options.filters, filtersAPI, scrollToTop]);

  return [filters, handleOnFilterChange];
};

const fromOptionToState = (filters: FindOptions['filters']) =>
  Object.entries(filters ?? {}).reduce((state, [key, value]) => {
    state.push({
      id: key,
      value,
    });

    return state;
  }, [] as MRT_ColumnFiltersState) ?? [];
