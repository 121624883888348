import {
  PaymentMethodCard,
  PaymentMethodBancontact,
  PaymentMethodGiropay,
  PaymentMethodIdeal,
  PaymentMethodPaypal,
} from '@bits-app/bits-server-data';
import CredidCardIcon from '@mui/icons-material/CreditCard';
import { UseQueryResult } from '@tanstack/react-query';
import { format } from 'date-fns';

import { TooltipWithTime } from '../date/TooltipWithTime';

enum AvailablePaymentMethodName {
  'card' = 'card',
  'bancontact' = 'bancontact',
  'giropay' = 'giropay',
  'ideal' = 'ideal',
  'paypal' = 'paypal',
}

export type PaymentMethodPreviewProps = {
  payment_method_name: AvailablePaymentMethodName;
} & PaymentMethodAvailable;

type PaymentMethodAvailable =
  | PaymentMethodCard
  | PaymentMethodBancontact
  | PaymentMethodGiropay
  | PaymentMethodIdeal
  | PaymentMethodPaypal;

export const PaymentMethodPreview = ({
  data: paymentMethod,
  isLoading,
}: UseQueryResult<PaymentMethodPreviewProps, unknown>) => {
  if (isLoading) return <p>Loading</p>;
  if (!paymentMethod) return <p className="text-slate-300">-</p>;

  const formattedDate = paymentMethod.createdAt
    ? format(new Date(paymentMethod.createdAt), 'dd/MM/yyyy')
    : 'N/A';

  const title = getTitle(paymentMethod);
  const subtitle = getSubtitle(paymentMethod);

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <CredidCardIcon />

        <div className="ml-2">
          <p className="text-sm">{title}</p>
          <p className="text-sm">{subtitle}</p>
        </div>
      </div>

      <div>
        <TooltipWithTime date={paymentMethod.createdAt}>
          <p className="text-sm">Created {formattedDate}</p>
        </TooltipWithTime>
      </div>
    </div>
  );
};

const getTitle = (paymentMethod: PaymentMethodPreviewProps) => {
  if (paymentMethod?.payment_method_name === AvailablePaymentMethodName.card) {
    const overCast = paymentMethod as PaymentMethodCard;

    return (
      <>
        <b className="capitalize">{overCast.brand}</b> ending with <b>{overCast.last4}</b>
      </>
    );
  }

  switch (paymentMethod.payment_method_name) {
    case AvailablePaymentMethodName.bancontact:
      return 'Bancontact';
    case AvailablePaymentMethodName.giropay:
      return 'Giropay';
    case AvailablePaymentMethodName.ideal:
      return 'iDeal';
    case AvailablePaymentMethodName.paypal:
      return 'Paypal';
  }
};

const getSubtitle = (paymentMethod: PaymentMethodPreviewProps) => {
  if (paymentMethod?.payment_method_name === AvailablePaymentMethodName.card) {
    const overCast = paymentMethod as PaymentMethodCard;
    return `Expiry date ${overCast.expMonth}/${overCast.expYear}`;
  }
  switch (paymentMethod.payment_method_name) {
    case AvailablePaymentMethodName.bancontact:
      return 'Bancontact';
    case AvailablePaymentMethodName.giropay:
      return 'Giropay';
    case AvailablePaymentMethodName.ideal:
      return 'iDeal';
    case AvailablePaymentMethodName.paypal:
      return 'Paypal';
  }
};
