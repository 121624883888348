type DataLongTextProps = {
  title: string;
  value: string;
};

export const DataLongText = ({ title, value }: DataLongTextProps) => (
  <div className="flex flex-col gap-1">
    <p className="font-semibold capitalize">{title}</p>
    <p className="text-slate-500 text-sm border-l-2 border-slate-200 pl-2 ">{value}</p>
  </div>
);
