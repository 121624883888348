import { useState } from 'react';

import { Product, CommercialEvent } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

import { useOwnUser } from '../../../context/own-user.context';

import { AddToCommercialEventModal } from './AddToCommercialEvent.modal';

type AddMultipleItemToCommercialEventProps = {
  productIds: Product['id'][];
};

export const AddMultipleItemToCommercialEvent = ({
  productIds,
}: AddMultipleItemToCommercialEventProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const snackbar = useSnackbar();

  const { hasPermissions } = useOwnUser();
  const canAddToCommercialEvent = hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_COMMERCIAL_EVENT,
  );

  const { mutate: submitUpdate } = useMutation(
    ({ commercialEventId }: { commercialEventId: CommercialEvent['id'] }) =>
      authenticatedInstance.patch(
        `database-explorer/commercial-event/${commercialEventId}/add-batch/products`,
        { productIds },
      ),
    {
      onSuccess: () => {
        snackbar.success(t(`databaseExplorer.commercialEvent.add.products.success`));
        queryClient.invalidateQueries(QUERIES_KEYS.commercialEventList());
        setIsOpen(false);
      },
      onError: () => {
        snackbar.error(t(`databaseExplorer.commercialEvent.add.products.error`));
      },
    },
  );

  if (!canAddToCommercialEvent) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={() => setIsOpen(true)}
        size="small"
        sx={{ '& svg': { width: 20, height: 20 } }}
      >
        <Edit />
      </IconButton>

      {isOpen && (
        <AddToCommercialEventModal onCancel={() => setIsOpen(false)} onConfirm={submitUpdate} />
      )}
    </>
  );
};
