import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { PaymentMethodPaypalPreAuthorization } from './PaymentMethodPaypalPreAuthorization';
import { PreAuthorizedPaymentMethod } from './PreAuthorizedPaymentMethod';
import { User } from './User';

@Entity()
export class PaymentMethodPaypal extends PreAuthorizedPaymentMethod {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Index({ unique: true, where: '"deletedAt" IS NULL' })
  @Column()
  userId!: number;

  @OneToOne(
    () => PaymentMethodPaypalPreAuthorization,
    (paymentMethodPaypalPreAuthorization) =>
      paymentMethodPaypalPreAuthorization.paymentMethodPaypal,
  )
  paymentMethodPaypalPreAuthorization!: PaymentMethodPaypalPreAuthorization;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;

  @Index()
  @Column({ nullable: true })
  verifiedEmail!: string;
}
