import {
  TransferFundType,
  TransferFundPayload,
  Currency,
  TransferReason,
} from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';

import { User } from '../../users';

export type CsvType = {
  userId: string;
  amount: string;
  description: string;
  currency: 'eur' | 'gbp';
  orderId: number;
  shipmentId: string;
  showId: number;
};

export const _checkUsersBalance = async ({ users }: { users: User['id'][] }) => {
  return authenticatedInstance.post<{ isCompatible: true | string; accountId: User['id'] }[]>(
    'payment/available-for-transfer-old',
    {
      userIds: users,
    },
  );
};

export const checkIfAllRowsAreValid = (rows: CsvType[]) =>
  rows.every((row) => {
    const userIdIsValid = row?.userId?.match(/^[0-9]{1,10}$/);
    const amountIsValid = row?.amount?.match(/^[0-9]*$/);
    const descriptionIsValid = row?.description?.length > 0;
    const currencyIsValid = row.currency === 'eur' || row.currency === 'gbp';
    return userIdIsValid && amountIsValid && descriptionIsValid && currencyIsValid;
  });

export const formatRow = (rows: CsvType[]): TransferFundPayload[] =>
  rows.map<TransferFundPayload>((row) => ({
    userId: +row.userId,
    amount: Number(row.amount),
    description: row.description,
    currency: row.currency === 'eur' ? Currency.eur : Currency.gbp,
    transferType: 'stripe-account',
    transferReason: TransferReason.careCommercialGesture,
    orderId: row.orderId,
    shipmentId: row.shipmentId,
    showId: row.showId,
  }));

export const processAllTransfer = async ({
  payload,
  transferType,
  transferReason,
  relatedMonth,
}: {
  payload: Omit<TransferFundPayload, 'transferType'>[];
  transferType: TransferFundType;
  transferReason: TransferReason;
  relatedMonth?: string;
}) => {
  return authenticatedInstance.post<{ error: string; transfer: TransferFundPayload }[]>(
    'payment/transfer/bulk',
    payload.map((transfer) => ({ ...transfer, transferType, transferReason, relatedMonth })),
  );
};
