import { ParentCategory, ShippingAddress, User } from '@bits-app/bits-server-data';
import { SentinelFraudCase, AvailableEntityForComment } from '@bits-app/voggtpit-shared';

export const QUERIES_KEYS = {
  showList: (userId?: User['id']) => ['GET', '/database-explorer/show', 'user', userId],
  productList: (userId?: User['id']) => ['GET', '/database-explorer/product', 'user', userId],
  showOrders: () => ['GET', '/database-explorer/order'],
  userBuyerOrders: (userId: User['id']) => ['GET', `/database-explorer/order`, 'buyer', userId],
  userSellerOrders: (userId: User['id']) => ['GET', `/database-explorer/order`, 'seller', userId],
  showProducts: () => ['GET', '/database-explorer/product'],
  ownUsersList: () => ['GET', '/user'],
  badgeTranslations: (badgeName: string) => [
    'GET',
    `/database-explorer/badge/${badgeName}/translations`,
  ],
  badgeRelatedUsersCount: (badgeId: number) => [
    'GET',
    `/database-explorer/badge/${badgeId}/related-users-count`,
  ],
  draftBadge: (draftBadgeId: string) => ['GET', `/database-explorer/badge/draft/${draftBadgeId}`],
  badgesList: () => ['GET', '/database-explorer/badge'],
  emotesList: () => ['GET', '/database-explorer/emote'],
  emote: (emoteId: number) => ['GET', `/database-explorer/emote/${emoteId}`],
  environment: () => ['GET', '/environment'],
  categoriesList: () => ['GET', '/database-explorer/category'],
  relatedCategoriesList: (parentCategoryId: ParentCategory['id']) => [
    'GET',
    `/database-explorer/category/parent/${parentCategoryId}`,
  ],
  parentCategoriesList: () => ['GET', '/database-explorer/parent-category'],
  sellerNewsList: () => ['GET', '/database-explorer/seller-news'],
  sellerNews: (sellerNewsId: number) => ['GET', `/database-explorer/seller-news/${sellerNewsId}`],
  sellerNewsMutationSchema: () => ['seller-news-mutation-schema'],
  sellerCompanyMutationSchema: () => ['seller-company-mutation-schema'],
  getCountries: () => ['GET', '/database-explorer/country'],
  userTargetPromotion: (promotionId?: number) => [
    'GET',
    `/promotions/${promotionId}/user-attached`,
  ],
  categoryTranslations: (categoryName: string) => [
    'GET',
    `/database-explorer/category/${categoryName}/translations`,
  ],
  getCreationShippingAddressSchema: () => [
    'GET',
    '/database-explorer/shipping-address/creation-schema',
  ],
  getShippingAddress: (shippingAddressId: ShippingAddress['id']) => [
    'GET',
    `/database-explorer/shipping-address/${shippingAddressId}`,
  ],
  getChannel: (channelId: number) => ['GET', `/database-explorer/channel/${channelId}`],
  getShipment: (shipmentId: string) => ['GET', `/database-explorer/shipment/${shipmentId}`],
  userPaymentBalance: (userId: number) => ['GET', `/payment/available-for-transfer/${userId}`],
  getZohoUserLink: (userId: number) => ['GET', `/zoho/${userId}/zoho-user-link`],
  paymentMethodByUserId: (userId: number) => ['GET', `/database-explorer/payment-method/${userId}`],
  getZohoShowLink: (showId: number) => ['GET', `/zoho/${showId}/zoho-show-link`],
  activityLogs: (entity: AvailableEntityForComment, entityId: number | string) => [
    'GET',
    `/activity-logs/${entity}/${entityId}`,
  ],
  ledgerTransactionList: (userId: number, currentPage: number, pageSize: number) => [
    'GET',
    `/ledger/transactions/${userId}`,
    currentPage,
    pageSize,
  ],
  ledgerBalance: (userId: number) => ['GET', `/ledger/balance/${userId}`],
  ledgerDetail: (userId: number) => ['GET', `/ledger/detail/${userId}`],
  ordersFromShipment: (shipmentId?: string) => [
    'GET',
    `/database-explorer/shipment/orders-from-shipment/${shipmentId}`,
  ],
  productsFromShipment: (shipmentId?: string) => [
    'GET',
    `/database-explorer/shipment/products-from-shipment/${shipmentId}`,
  ],
  shipmentsFromUser: (userId?: number) => [
    'GET',
    `/database-explorer/shipment/shipments-from-user/${userId}`,
  ],
  getShipmentDetailFromOrderId: (orderId: number) => [
    'GET',
    `/database-explorer/shipment/shipment-from-order/${orderId}`,
  ],
  shipmentsFromShippingAddress: (shippingAddressId?: number) => [
    'GET',
    `/database-explorer/shipment/shipments-from-shipping-address/${shippingAddressId}`,
  ],
  sentinelList: () => ['GET', '/sentinel'],
  sentinelDetail: (sentinelId?: SentinelFraudCase['id']) => [
    'GET',
    `/sentinel/detail/${sentinelId}`,
  ],

  marketPagesList: () => ['GET', '/market-page'],
  marketPage: (marketPageId: string) => ['GET', `/market-page/${marketPageId}`],
  marketPageMutation: () => ['GET', 'market-page-mutation'],
  getMarketPageSchema: () => ['GET', '/market-page/schema'],

  commercialEventList: () => ['GET', '/database-explorer/commercial-event'],
  commercialEventsByShowId: (showId: number) => ['commercialEventsByShowId', showId],
  commercialEvent: (commercialEventId: number) => [
    'GET',
    `/database-explorer/commercial-event/${commercialEventId}`,
  ],
  commercialEventMutation: () => ['GET', 'commercial-event-mutation'],
  commercialEventRelatedShows: (commercialEventId?: number) => [
    'GET',
    `/database-explorer/commercial-event/${commercialEventId}/related-shows`,
  ],
  commercialEventRelatedProducts: (commercialEventId?: number) => [
    'GET',
    `/database-explorer/commercial-event/${commercialEventId}/related-products`,
  ],
  ledgerPayoutList: (startAt: number, endAt: number, page: number) => [
    'GET',
    'ledger',
    'payout-list',
    startAt,
    endAt,
    page,
  ],
  activeAutoFollowUserCampaignList: () => ['GET', '/active-auto-follow-user-campaigns'],
  archivedAutoFollowUserCampaignList: () => ['GET', '/archived-auto-follow-user-campaigns'],
  autoFollowUserCampaignCreationSchema: () => [
    'GET',
    '/auto-follow-user-campaigns-creation-schema',
  ],
  showDetail: (showId: number) => ['GET', '/database-explorer/show', showId],
  showPlayerInformations: (showId: number) => ['GET', '/database-explorer/show/player', showId],
  userDetail: (userId: number) => ['GET', '/database-explorer/customer', userId],
  userSellerConfigDetail: (userId: number) => [
    'GET',
    '/database-explorer/seller-config/user/',
    userId,
  ],
  userSellerCompanyDetail: (userId: number) => [
    'GET',
    '/database-explorer/seller-company/by-user-id/',
    userId,
  ],
  userZendeskData: (userId: number) => ['GET', '/database-explorer/customer/zendesk-data', userId],
  userShippingAddresses: (userId?: number) => [
    'GET',
    '/database-explorer/shipping-address',
    userId,
  ],
  featuredShowList: (status?: string, isShowAlreadyDone?: boolean) => [
    'GET',
    '/database-explorer/featured-show-application/search',
    status,
    isShowAlreadyDone,
  ],
  shipmentRatingList: (sellerId?: number) => [
    'GET',
    '/database-explorer/shipment-rating',
    sellerId,
  ],
  notificationList: (showId?: number) => ['GET', '/notifications-center', showId],
  showAssociatedCommercialEventList: (showId: number) => [
    'GET',
    `/database-explorer/commercial-event/by-show/${showId}`,
  ],
};
