import { Order, Show, StreamingType } from '@bits-app/bits-server-data';
import { CountryCodeIso2, DeliveryServices } from '@bits-app/voggtpit-shared';
import { format } from 'date-fns';

import { ForestAdminLinkProps } from './components/elements/ForestAdminLink';

export const getEnv = (key: string) => {
  const value = process.env[key];

  if (value === undefined) {
    throw new Error(`${key} is not defined in the environment variables`);
  }

  return value;
};

export function formatText(text: string) {
  const separator = text.includes('-') ? '-' : text.includes('_') ? '_' : undefined;
  if (separator) {
    return text
      .split(separator)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } else {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}

export const formatDate = (date: Date | string, formatString = 'dd/MM/yyyy HH:mm:ss'): string => {
  try {
    if (!date) return format(new Date(), formatString);
    if (typeof date === 'string' && date.match(/^(\d+){10}$/)) {
      return format(new Date(+date), formatString);
    }
    return format(new Date(date), formatString);
  } catch {
    return format(new Date(), formatString);
  }
};

export const formatPercentage = (percentage: number): string => `${percentage.toFixed(2)}%`;

export const isoCodeToEmojiFlag: Record<CountryCodeIso2, string> = {
  AT: '🇦🇹',
  BE: '🇧🇪',
  CH: '🇨🇭',
  DE: '🇩🇪',
  GB: '🇬🇧',
  ES: '🇪🇸',
  FR: '🇫🇷',
  NL: '🇳🇱',
};

export const getStripeTransferUrl = (transferId: Order['promotionTopUpStripeTransferId']) =>
  `${process.env.REACT_APP_STRIPE_URL}/connect/transfers/${transferId}`;

export const getStripeAccountUrl = (getStripeAccountId: string) =>
  getStripeAccountId &&
  `${process.env.REACT_APP_STRIPE_URL}/connect/accounts/${getStripeAccountId}`;

export const getStripeCustomerUrl = (stripeCustomerId: string) =>
  `${process.env.REACT_APP_STRIPE_URL}/customers/${stripeCustomerId}`;

export const getStripePaymentUrl = (paymentIntentId: string) =>
  `${process.env.REACT_APP_STRIPE_URL}/payments/${paymentIntentId}`;

export const getStripePaymentMethodUrl = (paymentMethodId: string) =>
  `${process.env.REACT_APP_STRIPE_URL}/connect/payment/${paymentMethodId}`;

export const getStripeSetupIntentUrl = (setupIntentId: string) =>
  `${process.env.REACT_APP_STRIPE_URL}/setup_intents/${setupIntentId}`;

export const getLinkShippingTracking = (
  order:
    | Pick<Order, 'trackingNumber' | 'shippingProvider'>
    | Pick<Order, 'trackingNumber' | 'shippingProvider' | 'shippingAddress'>,
): string | null => {
  if (!order.shippingProvider) return null;
  const provider = DeliveryServices[order.shippingProvider];

  if ('shippingAddress' in order) {
    if (!provider?.url) return null;
    return provider?.url
      ?.replace('{trackingNumber}', order.trackingNumber ?? '')
      .replace('{postalCode}', order.shippingAddress.inputPostalCode ?? '');
  }

  return provider?.url?.replace('{trackingNumber}', order.trackingNumber ?? '');
};

export const getForestAdminLink = ({ entityId, entity }: ForestAdminLinkProps) => {
  const isSandbox = process.env.NODE_ENV === 'development';
  const ForestEnv = isSandbox ? 'Sandbox' : 'Production';

  return `https://app.forestadmin.com/Voggt/${ForestEnv}/Operations/data/${entity}/index/record/${entity}/${entityId}/details`;
};

export const MUX_BASE_URL = `https://dashboard.mux.com/organizations/mr0m6o/environments/${
  process.env.REACT_APP_ENV === 'production' ? 'sfpcjm' : 'md7g0r'
}`;

export const getStreamInspectorLink = (show: Show): string | null => {
  const isOnMux = show.streamingType === StreamingType.rtmp;
  if (isOnMux && show?.channel?.providerId) {
    return `${MUX_BASE_URL}/video/live-streams/${show.channel.providerId}/health`;
  }
  if (show.openTokSessionId) {
    return `https://tokbox.com/developer/tools/inspector/account/4206061/project/47549651/session/${show.openTokSessionId}`;
  }
  return null;
};

export const zendeskOAuthUrl = () => {
  const url = new URL(getEnv('REACT_APP_ZENDESK_OAUTH_URL'));

  url.searchParams.set('response_type', 'code');
  url.searchParams.set('redirect_uri', getEnv('REACT_APP_ZENDESK_REDIRECT_URI'));
  url.searchParams.set('client_id', getEnv('REACT_APP_ZENDESK_CLIENT_ID'));
  url.searchParams.set('scope', 'read');

  return url.toString();
};
