import {
  TransferReason,
  Currency,
  TransferFundPayload,
  TransferReasonTranslation,
} from '@bits-app/voggtpit-shared';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '@/components/elements/buttons/ButtonContained';

import { TRANSFER_FUND_TYPE_OPTIONS } from '../../../hooks/bulk-direct-fund/use-bulk-direct-fund-transfer';

type DirectFundTransferModalProps = {
  onConfirm: (payload: Omit<TransferFundPayload, 'userId'>) => void;
  onCancel: () => void;
  isOpen: boolean;
  currentBalance?: number;
  isLoading: boolean;
  error: string | null;
};

type DirectFundTransferForm = Omit<TransferFundPayload, 'userId'>;

export const DirectFundTransferModal = ({
  onConfirm,
  onCancel,
  isOpen,
  currentBalance,
  isLoading,
  error,
}: DirectFundTransferModalProps) => {
  const { register, handleSubmit, control, watch } = useForm<DirectFundTransferForm>({
    defaultValues: {
      amount: 0,
      currency: Currency.eur,
      description: '',
      transferReason: TransferReason.careCommercialGesture,
      transferType: 'stripe-account',
      orderId: undefined,
      shipmentId: undefined,
      showId: undefined,
      relatedMonth: undefined,
    },
  });

  const { t } = useTranslation();

  const shouldFillShipmentId = [
    TransferReason.extraShipping,
    TransferReason.secondShipping,
    TransferReason.refundMistake,
    TransferReason.careCommercialGesture,
    TransferReason.careInsuranceReimbursement,
    TransferReason.shippingLabelsOverweightCharges,
  ].includes(watch('transferReason'));

  const shouldFillOrderId = [
    TransferReason.refundMistake,
    TransferReason.careCommercialGesture,
    TransferReason.shippingLabelsOverweightCharges,
  ].includes(watch('transferReason'));

  const shouldFillShowId = [
    TransferReason.refundMistake,
    TransferReason.careCommercialGesture,
    TransferReason.careInsuranceReimbursement,
    TransferReason.commissionCorrection,
    TransferReason.financialAdjustement,
  ].includes(watch('transferReason'));

  const shouldFillRelatedMonth = [
    TransferReason.variableBonus,
    TransferReason.fixedBonus,
    TransferReason.commissionCorrection,
    TransferReason.financialAdjustement,
    TransferReason.marketingBuyersActivation,
    TransferReason.marketingCampaigns,
    TransferReason.commercialCampaigns,
  ].includes(watch('transferReason'));

  const submit = handleSubmit((data: DirectFundTransferForm) => {
    onConfirm(data);
  });

  return (
    <Dialog open={isOpen}>
      <DialogTitle id="alert-dialog-title">
        {t('databaseExplorer.users.paymentDirectTransfer.title')}
      </DialogTitle>

      <DialogContent>
        <Stack direction={'column'} spacing={2}>
          <DialogContentText>
            {t('databaseExplorer.users.paymentDirectTransfer.description', { currentBalance })}
          </DialogContentText>

          <Controller
            render={({ field }) => (
              <Select {...field}>
                {Object.keys(TransferReason).map((transferReasonKey) => (
                  <MenuItem key={transferReasonKey} value={transferReasonKey}>
                    {TransferReasonTranslation[transferReasonKey as TransferReason]}
                  </MenuItem>
                ))}
              </Select>
            )}
            control={control}
            name="transferReason"
          />

          <TextField
            {...register('description')}
            label={t('databaseExplorer.users.paymentDirectTransfer.descriptionLabel')}
          />

          {shouldFillShipmentId && (
            <TextField
              {...register('shipmentId')}
              label={t('databaseExplorer.users.paymentDirectTransfer.shipmentId')}
            />
          )}

          {shouldFillOrderId && (
            <TextField
              {...register('orderId')}
              label={t('databaseExplorer.users.paymentDirectTransfer.orderId')}
            />
          )}

          {shouldFillShowId && (
            <TextField
              {...register('showId')}
              label={t('databaseExplorer.users.paymentDirectTransfer.showId')}
            />
          )}

          {shouldFillRelatedMonth && (
            <TextField
              {...register('relatedMonth')}
              label={t('databaseExplorer.users.paymentDirectTransfer.relatedMonh')}
            />
          )}

          <TextField
            {...register('amount', {
              valueAsNumber: true,
            })}
            label={t('databaseExplorer.users.paymentDirectTransfer.amountLabel')}
          />

          <Controller
            render={({ field }) => (
              <Select {...field} label={t('currency')}>
                {Object.keys(Currency).map((_currency) => (
                  <MenuItem key={_currency} value={_currency}>
                    {_currency}
                  </MenuItem>
                ))}
              </Select>
            )}
            control={control}
            name="currency"
          />

          <Controller
            render={({ field }) => (
              <Select {...field} label={t('transferFundType')}>
                {TRANSFER_FUND_TYPE_OPTIONS.map((_transferFundType) => (
                  <MenuItem key={_transferFundType} value={_transferFundType}>
                    {_transferFundType}
                  </MenuItem>
                ))}
              </Select>
            )}
            control={control}
            name="transferType"
          />

          {error && (
            <DialogContentText color={'red'} fontWeight={'bold'}>
              {error}
            </DialogContentText>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={isLoading}>
          {t('action.cancel')}
        </Button>
        <ButtonContained onClick={submit} disabled={isLoading}>
          {t('action.continue')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
