import { useMemo } from 'react';

import {
  PaymentMethodBancontact,
  PaymentMethodCard,
  PaymentMethodGiropay,
  PaymentMethodIdeal,
  PaymentMethodPaypal,
} from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

export const useCellDefinition = () => {
  const CellPaymentMethodCardDefinition = useMemo(
    (): CellDefinition<PaymentMethodCard & { user: string }>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (paymentMethod) => ({
          link: `/payment-method/card/${paymentMethod?.id}`,
          label: paymentMethod?.id,
        }),
      },
      {
        id: 'user',
        label: 'User',
        type: 'internal-link',
        getLink: (paymentMethod) => ({
          link: `/user/${paymentMethod?.userId}`,
          label: paymentMethod?.user,
        }),
      },
      {
        id: 'last4',
        label: 'Last 4',
      },
      {
        id: 'expMonth',
        label: 'Exp month',
      },
      {
        id: 'expYear',
        label: 'Exp year',
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  const CellPaymentMethodPaypalDefinition = useMemo(
    (): CellDefinition<PaymentMethodPaypal & { user: string }>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (paymentMethod) => ({
          link: `/payment-method/paypal/${paymentMethod?.id}`,
          label: paymentMethod?.id,
        }),
      },
      {
        id: 'user',
        label: 'User',
        type: 'internal-link',
        getLink: (paymentMethod) => ({
          link: `/user/${paymentMethod?.userId}`,
          label: paymentMethod?.user,
        }),
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },
      {
        id: 'deletedAt',
        label: 'Deleted at',
        type: 'date-with-time',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  const CellPaymentMethodGiropayDefinition = useMemo(
    (): CellDefinition<PaymentMethodGiropay & { user: string }>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (paymentMethod) => ({
          link: `/payment-method/giropay/${paymentMethod?.id}`,
          label: paymentMethod?.id,
        }),
      },
      {
        id: 'user',
        label: 'User',
        type: 'internal-link',
        getLink: (paymentMethod) => ({
          link: `/user/${paymentMethod?.userId}`,
          label: paymentMethod?.user,
        }),
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },
      {
        id: 'deletedAt',
        label: 'Deleted at',
        type: 'date-with-time',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  const CellPaymentMethodIdealDefinition = useMemo(
    (): CellDefinition<PaymentMethodIdeal & { user: string }>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (paymentMethod) => ({
          link: `/payment-method/ideal/${paymentMethod?.id}`,
          label: paymentMethod?.id,
        }),
      },
      {
        id: 'user',
        label: 'User',
        type: 'internal-link',
        getLink: (paymentMethod) => ({
          link: `/user/${paymentMethod?.userId}`,
          label: paymentMethod?.user,
        }),
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },
      {
        id: 'deletedAt',
        label: 'Deleted at',
        type: 'date-with-time',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  const CellPaymentMethodBancontactDefinition = useMemo(
    (): CellDefinition<PaymentMethodBancontact & { user: string }>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (paymentMethod) => ({
          link: `/payment-method/bancontact/${paymentMethod?.id}`,
          label: paymentMethod?.id,
        }),
      },
      {
        id: 'user',
        label: 'User',
        type: 'internal-link',
        getLink: (paymentMethod) => ({
          link: `/user/${paymentMethod?.userId}`,
          label: paymentMethod?.user,
        }),
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },
      {
        id: 'deletedAt',
        label: 'Deleted at',
        type: 'date-with-time',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  return {
    CellPaymentMethodBancontactDefinition,
    CellPaymentMethodCardDefinition,
    CellPaymentMethodGiropayDefinition,
    CellPaymentMethodIdealDefinition,
    CellPaymentMethodPaypalDefinition,
  };
};
