import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { MRT_Header } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

type AutocompleteFilterMRTProps<T extends Record<string, unknown>> = {
  header: MRT_Header<T>;
  onAutocomplete: (search: string) => void;
  options: { value: string; label?: string }[];
  activeFilters?: string;
  loading?: boolean;
};

export const AutocompleteFilterMRT = <T extends Record<string, unknown>>({
  onAutocomplete,
  header,
  options,
  activeFilters,
  loading = false,
}: AutocompleteFilterMRTProps<T>) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(activeFilters ?? '');
  const [debouncedInputValue] = useDebounce(inputValue, 200);

  useEffect(() => {
    onAutocomplete(debouncedInputValue);
  }, [debouncedInputValue, onAutocomplete]);

  useEffect(() => {
    if (!activeFilters && inputValue) {
      setInputValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  return (
    <Autocomplete
      freeSolo
      filterOptions={(x) => x}
      options={debouncedInputValue === '' ? [] : options.map(({ value }) => value)}
      autoComplete
      includeInputInList
      loading={loading}
      size="small"
      sx={{
        maxWidth: header.column.columnDef.size,
        '& .MuiAutocomplete-inputRoot': {
          '&.MuiInputBase-sizeSmall': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
          },
        },
        '& .MuiAutocomplete-input': {
          fontSize: '0.75rem',
        },
      }}
      value={(header.column.getFilterValue() ?? null) as string}
      onChange={(_: unknown, newValue: string | number | null) => {
        header.column.setFilterValue(newValue);
      }}
      onInputChange={(_, newInputSeller) => {
        setInputValue(newInputSeller);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            sx={{ margin: 0 }}
            placeholder={t('applyFilters')}
            margin="dense"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={15} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              value: inputValue,
            }}
          />
        );
      }}
      renderOption={(props, value) => {
        const option = options.find((option) => option.value === value);

        if (!option) {
          return '';
        }

        return <li {...props}>{option.label ?? value}</li>;
      }}
    />
  );
};
