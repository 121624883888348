/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

const OPERATORS = [
  { label: 'is', value: ':=' },
  { label: 'is not', value: ':!=' },
  { label: 'is greater than', value: ':>' },
  { label: 'is less than', value: ':<' },
  { label: 'is greater than or equal to', value: ':>=' },
  { label: 'is less than or equal to', value: ':<=' },
  { label: 'contains', value: ':' },
];

export const Filtering = ({
  columns,
  setFilterBy,
}: {
  columns: any[];
  setFilterBy: (filterBy: string) => void;
}) => {
  const [filters, setFilters] = useState<
    { id: string; field: string; operator: string; value: string }[]
  >([]);

  const [currentField, setCurrentField] = useState(columns[0].id);
  const [currentOperator, setCurrentOperator] = useState(OPERATORS[0].label);
  const [currentValue, setCurrentValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const onFilterAdd = () => {
    setFilters([
      ...filters,
      {
        id: `${currentField}${currentOperator}${currentValue}`,
        field: currentField,
        operator: currentOperator,
        value: currentValue,
      },
    ]);
  };

  useEffect(() => {
    if (currentValue === '') return;

    setFilterBy(
      filters
        .map((filter) => {
          const operator = OPERATORS.find((op) => op.label === filter.operator);

          return `${filter.field}${operator?.value}${filter.value}`;
        })
        .join('&&'),
    );
  }, [filters, setFilterBy, currentValue]);

  return (
    <div className="relative">
      <button
        className="text-slate-900 bg-slate-200 hover:bg-slate-400 focus:ring-2 focus:outline-none focus:ring-sky-300 font-semibold rounded text-sm px-4 py-2.5 text-center inline-flex items-center cursor-pointer h-full whitespace-nowrap"
        onClick={toggleIsOpen}
      >
        Filters {filters.length > 0 && `(${filters.length})`}
      </button>

      {isOpen && (
        <div className="absolute bg-white p-4 mt-2 mr-2 right-0 origin-top-right rounded shadow border border-slate-100">
          <div className="flex flex-col justify-between">
            <div className="flex flex-wrap">
              {filters.map((filter) => (
                <div
                  key={filter.id}
                  className="text-xs rounded px-2 py-1.5 mr-2 mb-2 flex border border-slate-300 bg-slate-100"
                >
                  <b className="mr-2">{filter.field}</b> {filter.operator}
                  <b className="ml-2">{filter.value}</b>
                  <button
                    className="text-xs bg-red-300 text-white focus:ring-2 focus:outline-none focus:ring-sky-300 rounded w-4 h-4 ml-2 flex justify-center items-center hover:bg-red-400"
                    onClick={() => setFilters(filters.filter((f) => f.id !== filter.id))}
                  >
                    ⛌
                  </button>
                </div>
              ))}
            </div>

            <div className="flex">
              <select
                placeholder="operator"
                className="text-xs border border-slate-300 focus:ring-2 focus:outline-none focus:ring-sky-300 rounded px-1.5 py-1.5 mr-2"
                onChange={(e) => setCurrentField(e.target.value)}
                value={currentField}
              >
                {columns.map((column) => (
                  <option value={column.id}>{column.header}</option>
                ))}
              </select>

              <select
                placeholder="operator"
                className="text-xs border border-slate-300 focus:ring-2 focus:outline-none focus:ring-sky-300 rounded px-1.5 py-1.5 mr-2"
                onChange={(e) => setCurrentOperator(e.target.value)}
                value={currentOperator}
              >
                {OPERATORS.map((operator) => (
                  <option value={operator.label}>{operator.label}</option>
                ))}
              </select>

              <input
                type="text"
                placeholder="value"
                className="text-xs border border-slate-300 focus:ring-2 focus:outline-none focus:ring-sky-300 rounded px-1.5 py-1.5 mr-2 "
                onChange={(e) => setCurrentValue(e.target.value)}
                onKeyDownCapture={(e) => e.key === 'Enter' && onFilterAdd()}
              />

              <button
                onClick={onFilterAdd}
                className="text-xs bg-sky-700 text-white focus:ring-2 focus:outline-none focus:ring-sky-300 rounded px-2 py-1.5 whitespace-nowrap font-semibold"
              >
                Add filter
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
