import { CountryCodeIso2, Currency } from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import { BaseHTTPGateway } from '@/domain/base-http-gateway';
import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { orderItemDtoToEntity } from '@/entities/order-item.entity';
import logger from '@/logger/logger';

import { sellerCancelRequestRefundSummaryDto } from '../entities/seller-cancel-request-refund-summary.dto';

import {
  SellerCancelRequestsPort,
  SellerCancelRequestsRefundSummary,
} from './seller-cancel-requests.port';

export class HttpSellerCancelRequestsAdapter
  extends BaseHTTPGateway
  implements SellerCancelRequestsPort
{
  async getSellerCancelRequestsForCurrency(
    currency: Currency,
    countries: CountryCodeIso2[],
    options: { abortPrevious: boolean },
  ): Promise<OrderItemRefund[]> {
    const signal = this.handleAbortPrevious(
      options?.abortPrevious ?? false,
      'getSellerCancelRequestsForCurrency',
    );

    const filters = countries.length > 0 ? { country: countries } : {};

    try {
      const result = await authenticatedInstance.get(`/refund/seller-cancel-requests`, {
        params: { currency, filters },
        signal,
      });

      const sellerCancelRequests = result.data.map(orderItemDtoToEntity);

      return sellerCancelRequests;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async getSellerCancelRequestsCountPerCountries(
    currency: Currency,
    options: { abortPrevious: boolean },
  ): Promise<Record<CountryCodeIso2, number>> {
    const signal = this.handleAbortPrevious(
      options?.abortPrevious ?? false,
      'getSellerCancelRequestsCountPerCountries',
    );

    try {
      const result = await authenticatedInstance.get(
        `/refund/seller-cancel-requests/countries-count`,
        {
          params: { currency },
          signal,
        },
      );

      return result.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async getSellerCancelRequestsSummary(
    orderItemIds: number[],
  ): Promise<SellerCancelRequestsRefundSummary> {
    try {
      const result = await authenticatedInstance.post(
        '/order-item/seller-cancel-requests/summary',
        orderItemIds,
      );

      return sellerCancelRequestRefundSummaryDto(result.data);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async refundSellerCancelRequests(orderItemIds: number[]): Promise<void> {
    try {
      await authenticatedInstance.post('/refund/seller-cancel-requests', orderItemIds);
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }
}
