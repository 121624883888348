import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Currency } from '../models/currency';

import { OrderProduct } from './OrderProduct';
import { PaymentMethodBancontact } from './PaymentMethodBancontact';
import { PaymentMethodCard } from './PaymentMethodCard';
import { PaymentMethodGiropay } from './PaymentMethodGiropay';
import { PaymentMethodIdeal } from './PaymentMethodIdeal';
import { PaymentMethodPaypal } from './PaymentMethodPaypal';
import { PaymentMethodPaypalRecurringPayment } from './PaymentMethodPaypalRecurringPayment';
import { Product } from './Product';
import { Promotion } from './Promotion';
import { ShippingAddress } from './ShippingAddress';
import { Shop } from './Shop';
import { Show } from './Show';
import { User } from './User';
import { UserTargetPromotion } from './UserTargetPromotion';

export enum OrderStatus {
  pending = 'pending',
  shipped = 'shipped',
  delivered = 'delivered',
  refunded = 'refunded',
  canceled = 'canceled',
}

export const VALID_ORDER_STATUSES = [
  OrderStatus.delivered,
  OrderStatus.pending,
  OrderStatus.shipped,
];

export enum OrderCancellationReason {
  misclick = 'misclick',
  miss_taken_auction = 'miss_taken_auction',
  bad_item_conditions = 'bad_item_conditions',
  order_preparation_issue = 'order_preparation_issue',
  buyer_refusal = 'buyer_refusal',
  failed_payment = 'failed_payment',
}

export enum OrderCancellationRequesterType {
  ops = 'ops',
  seller = 'seller',
}

export enum PaymentStatus {
  in_progress = 'in_progress',
  success = 'success',
  failed = 'failed',
  pending_sepa_debit = 'pending_sepa_debit',
}

export const PAYMENT_STATUSES_CONSIDERED_AS_PAID = [
  PaymentStatus.success,
  PaymentStatus.pending_sepa_debit,
];

export const PAYMENT_STATUSES_CONSIDERED_AS_NOT_PAID = [
  PaymentStatus.in_progress,
  PaymentStatus.failed,
];

export const PAYMENT_STATUSES_CONSIDERED_AS_IN_PROGRESS = [
  PaymentStatus.in_progress,
];

export enum PayoutStatus {
  unpaid = 'unpaid',
  paid = 'paid',
}

export enum RefundDebitedAccount {
  seller = 'seller',
  platform = 'platform',
}

/* NEW PAYOUT FORMULA - FROM NOVEMBER 1st
If paymentStatus = `success` :
		`GMV` (amount + shippingFee)
		- `feeAmount` [commission]
		- `refundedAmount` if refundDebitedAccount= "seller"
		+ `feeAmount` * `refundedAmount`/GMV if refundDebitedAccount= "seller" [we give back a part of the commission if partial refund]
- `deductedShippingCost`
*/
const NEW_FORMULA_DATE = "'2023-11-01'";
const GMV_EXPRESSION = `"amount" + "shippingAmount"`;

const GROSS_SALES_AMOUNT_EXPRESSION = `CASE WHEN "paymentStatus"='${PaymentStatus.success}' THEN ${GMV_EXPRESSION} ELSE 0 END`;

const REFUNDED_AMOUNT_FOR_SELLER_DEBITED_ACCOUNT_EXPRESSION = `CASE WHEN "paymentStatus"='${PaymentStatus.success}' AND "refundDebitedAccount"='${RefundDebitedAccount.seller}' THEN "refundedAmount" ELSE 0 END`;

const COMMISSION_AMOUNT_EXPRESSION = `CASE WHEN "paymentStatus"='${PaymentStatus.success}' THEN "feeDeductibleFromBuyerPayment" + "feeDeductibleFromTopUpToSeller" ELSE 0 END`;

// From 2023-11-01 onwards, we always give back the commission if there is a refund i.e. COMMISSION_NET_AMOUNT_EXPRESSION=0
const COMMISSION_NET_AMOUNT_EXPRESSION = `CASE
    WHEN "refundedAt" < ${NEW_FORMULA_DATE} AND "refundDebitedAccount"='${RefundDebitedAccount.seller}' AND "refundReason" NOT IN ('Delivered by hand', 'hand_delivery', 'Free shipping') THEN 0
    WHEN "refundedAt" >= ${NEW_FORMULA_DATE} AND "paymentStatus"='${PaymentStatus.success}' AND ${GMV_EXPRESSION}=0 THEN "feeDeductibleFromBuyerPayment" + "feeDeductibleFromTopUpToSeller"
    WHEN "refundedAt" >= ${NEW_FORMULA_DATE} AND "paymentStatus"='${PaymentStatus.success}' THEN (("feeDeductibleFromBuyerPayment" + "feeDeductibleFromTopUpToSeller") * (1 - (${REFUNDED_AMOUNT_FOR_SELLER_DEBITED_ACCOUNT_EXPRESSION})::float / (${GMV_EXPRESSION})))
    ELSE (${COMMISSION_AMOUNT_EXPRESSION})
END`;

const PAYOUT_AMOUNT_EXPRESSION = `((${GROSS_SALES_AMOUNT_EXPRESSION}) - (${REFUNDED_AMOUNT_FOR_SELLER_DEBITED_ACCOUNT_EXPRESSION}) - (${COMMISSION_NET_AMOUNT_EXPRESSION}) - COALESCE("deductedShippingCost", 0))::numeric::integer`;

@Entity()
@Index(['status', 'paymentStatus'])
@Index(['customerId', 'paymentStatus', 'status'])
@Index(['customerId', 'showId', 'paymentStatus'])
@Index(['showId', 'paymentStatus'])
@Index(['paymentMethodCardId', 'paymentStatus'])
export class Order {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column({ type: 'enum', enum: OrderStatus, default: OrderStatus.pending })
  status!: OrderStatus;

  @Column({
    type: 'enum',
    enum: PaymentStatus,
    default: PaymentStatus.in_progress,
  })
  paymentStatus!: PaymentStatus;

  @Column({ type: 'enum', enum: PayoutStatus, default: PayoutStatus.unpaid })
  payoutStatus!: PayoutStatus;

  /**
   * Calculated, equal to the sum of OrderProduct.productAmount
   */
  @Column()
  amount!: number;

  @Column({ nullable: true, type: 'int4' })
  sellerDiscountPercentage!: number | null;

  @Column()
  initialAmountPreSellerDiscount!: number;

  /**
   * Seller commission fee to take on payment
   */
  @Column({ default: 0 })
  feeDeductibleFromBuyerPayment!: number;

  /**
   * Seller commission fee to take on promotion
   * Amount deducted from the promotion when transferring the promotion amount to the seller
   */
  @Column({ default: 0 })
  feeDeductibleFromTopUpToSeller!: number;

  @Column({ default: 0 })
  buyerServiceFeeAmount!: number;

  /**
   * The amount paid by the customer for shipping the order.
   *
   * It's credited onto seller's Stripe account.
   */
  @Column()
  shippingAmount!: number;

  /**
   * @deprecated Will be replaced by Shipment.shippingProvider
   */
  @Column({ nullable: true, type: 'character varying' })
  shippingProvider!: string | null;

  /**
   * @deprecated Will be replaced by Shipment.trackingNumber
   */
  @Index()
  @Column({ nullable: true, type: 'character varying' })
  trackingNumber!: string | null;

  @Index({ unique: true })
  @Column({ nullable: true, type: 'character varying' })
  stripePaymentIntentId!: string | null;

  @Column({ type: 'boolean', default: false })
  isStripePaymentNextActionRequired!: boolean;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'sellerId' })
  seller!: User;

  @Index()
  @Column()
  sellerId!: number;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'customerId' })
  customer!: User;

  @Column()
  customerId!: number;

  @ManyToOne(() => Show)
  @JoinColumn({ name: 'showId' })
  show!: Show;

  @Column({ nullable: true })
  showId!: number;

  @ManyToOne(() => Shop)
  @JoinColumn({ name: 'shopId' })
  shop!: Shop;

  @Index()
  @Column({ nullable: true })
  shopId!: number;

  @ManyToOne(() => PaymentMethodBancontact)
  @JoinColumn({ name: 'paymentMethodBancontactId' })
  paymentMethodBancontact!: PaymentMethodBancontact | null;

  @Column({ nullable: true })
  paymentMethodBancontactId!: number | null;

  @ManyToOne(() => PaymentMethodCard)
  @JoinColumn({ name: 'paymentMethodId' })
  paymentMethodCard!: PaymentMethodCard | null;

  @Column({ name: 'paymentMethodId', nullable: true })
  paymentMethodCardId!: number | null;

  @ManyToOne(() => PaymentMethodGiropay)
  @JoinColumn({ name: 'paymentMethodGiropayId' })
  paymentMethodGiropay!: PaymentMethodGiropay | null;

  @Column({ nullable: true })
  paymentMethodGiropayId!: number | null;

  @ManyToOne(() => PaymentMethodIdeal)
  @JoinColumn({ name: 'paymentMethodIdealId' })
  paymentMethodIdeal!: PaymentMethodIdeal | null;

  @Column({ nullable: true })
  paymentMethodIdealId!: number | null;

  @ManyToOne(() => PaymentMethodPaypal)
  @JoinColumn({ name: 'paymentMethodPaypalId' })
  paymentMethodPaypal!: PaymentMethodPaypal | null;

  @Column({ nullable: true })
  paymentMethodPaypalId!: number | null;

  @ManyToOne(() => PaymentMethodPaypalRecurringPayment)
  @JoinColumn({ name: 'paymentMethodPaypalRecurringPaymentId' })
  paymentMethodPaypalRecurringPayment!: PaymentMethodPaypalRecurringPayment | null;

  @Column({ nullable: true })
  paymentMethodPaypalRecurringPaymentId!: number | null;

  @ManyToOne(() => ShippingAddress)
  @JoinColumn({ name: 'shippingAddressId' })
  shippingAddress!: ShippingAddress;

  @Column()
  shippingAddressId!: number;

  @Column({ default: 0 })
  refundedAmount!: number;

  @Column({ default: 0 })
  refundedCommissionAmount!: number;

  @Column({ type: 'timestamp', nullable: true })
  refundedAt!: Date;

  @Column({ type: 'timestamp', nullable: true })
  buyerServiceFeeAmountRefundedAt!: Date | null;

  @Column({
    nullable: true,
    type: 'enum',
    enum: RefundDebitedAccount,
    enumName: 'refund_debited_account',
  })
  refundDebitedAccount!: RefundDebitedAccount;

  @Column({ nullable: true })
  refundReason!: string;

  @Column({ nullable: true })
  refundAgent!: string;

  /**
   * @deprecated as of 2023-02-21; product ids should be added in OrderProduct.productId instead
   */
  @ManyToOne(() => Product)
  @JoinColumn({ name: 'productId' })
  product!: Product;

  /**
   * @deprecated as of 2023-02-21; product ids should be added in OrderProduct.productId instead
   */
  @Index()
  @Column()
  productId!: Product['id'];

  /**
   * @deprecated as of BUYER-839; use `userTargetPromotion` instead
   */
  @ManyToOne(() => Promotion, (promotion) => promotion.orders, {
    nullable: true,
  })
  @JoinColumn({ name: 'promotionId' })
  promotion!: Promotion;

  /**
   * @deprecated as of BUYER-839; use `userTargetPromotionId` instead
   */
  @Index()
  @Column({ nullable: true })
  promotionId!: number;

  @Column({ type: 'character varying', nullable: true, select: false })
  auctionId!: string | null;

  @Column({ type: 'character varying', nullable: true })
  flashSaleId!: string | null;

  @Column({ type: 'character varying', nullable: true, select: false })
  giveawayId!: string | null;

  @OneToOne(() => UserTargetPromotion, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'userTargetPromotionId' })
  userTargetPromotion!: UserTargetPromotion;

  @Column({ nullable: true })
  userTargetPromotionId!: number;

  @Column({ nullable: true })
  promotionAmount!: number;

  @Column({ nullable: true, default: null })
  promotionTopUpStripeTransferId!: string;

  @Column({ default: 0 })
  promotionTopUpAmountTransferred!: number;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  /**
   * The cost of the shipping when we paid for it (eg. via Boxtal).
   *
   * This cost is debited from seller's Stripe account.
   * @deprecated Will be replaced by Shipment.easyShippingAmount
   */
  @Column({ nullable: true })
  deductedShippingCost!: number;

  /**
   * @deprecated Will be replaced by Shipment.externalShippingOrderId
   */
  @Index()
  @Column({ nullable: true })
  externalShippingOrderId!: string;

  /**
   * @deprecated Will be replaced by Shipment.labelPrintUrl
   */
  @Column({ nullable: true })
  urlLabel!: string;

  @Column({
    type: 'enum',
    enum: Currency,
    default: Currency.eur,
    enumName: 'currency_enum',
  })
  currency!: Currency;

  @Column({
    type: 'enum',
    nullable: true,
    enum: OrderCancellationRequesterType,
    enumName: 'order_cancellation_requester_type',
  })
  cancellationRequesterType!: OrderCancellationRequesterType;

  @Column({
    type: 'enum',
    nullable: true,
    enum: OrderCancellationReason,
    enumName: 'order_cancellation_reason',
  })
  cancellationReason!: OrderCancellationReason;

  @Column({
    type: 'timestamp',
    name: 'cancellationRequestedAt',
    nullable: true,
  })
  cancellationRequestedAt!: Date;

  @Column({ type: 'timestamp', nullable: true })
  shippingFeesCancellationRequestedAt!: Date;

  @Column({ type: 'timestamp', nullable: true })
  shippingFeesRefundedAt!: Date | null;

  @Column({ nullable: true })
  note?: string;

  @OneToMany(() => OrderProduct, (op) => op.order)
  orderProducts!: OrderProduct[];

  /**
   * Constant fee the platform takes on this order. Based on the seller's configuration
   */
  @Column({ nullable: true })
  fixedFee!: number;

  /**
   * Percentage of the sale that the platform takes on this order. Based on the seller's configuration
   */
  @Column({ nullable: true, type: 'float' })
  percentageFee!: number;

  /**
   * Shallow copy of: SellerConfig['includeShippingFeesForCommissionByDefault']
   */
  @Column({ nullable: true })
  includeShippingFeesForCommissionByDefault!: boolean;

  /**
   * Generated fields
   */

  @Column({
    generatedType: 'STORED',
    asExpression: GROSS_SALES_AMOUNT_EXPRESSION,
    select: false,
    update: false,
  })
  grossSalesAmount!: number;

  @Column({
    generatedType: 'STORED',
    asExpression: REFUNDED_AMOUNT_FOR_SELLER_DEBITED_ACCOUNT_EXPRESSION,
    select: false,
    update: false,
  })
  refundedAmountForSellerDebitedAccount!: number;

  @Column({
    generatedType: 'STORED',
    asExpression: COMMISSION_NET_AMOUNT_EXPRESSION,
    select: false,
    update: false,
  })
  commissionNetAmount!: number;

  @Column({
    generatedType: 'STORED',
    asExpression: PAYOUT_AMOUNT_EXPRESSION,
    select: false,
    update: false,
  })
  payoutAmount!: number;

  static getTotalAmount(
    order: Pick<
      Order,
      'amount' | 'buyerServiceFeeAmount' | 'promotionAmount' | 'shippingAmount'
    >,
  ): number {
    return (
      Order.getTotalAmountWithoutShippingFees(order) +
      order.shippingAmount +
      order.buyerServiceFeeAmount
    );
  }

  static getTotalAmountWithoutShippingFees(
    order: Pick<Order, 'amount' | 'promotionAmount'>,
  ): number {
    return order.amount - (order.promotionAmount ?? 0);
  }

  static isCancellable(order: Pick<Order, 'status'>): boolean {
    return order.status === OrderStatus.pending;
  }

  static isCancelled(order: Pick<Order, 'status'>): boolean {
    return order.status === OrderStatus.canceled;
  }

  static isFreePayment(
    order: Pick<
      Order,
      'amount' | 'buyerServiceFeeAmount' | 'promotionAmount' | 'shippingAmount'
    >,
  ): boolean {
    const totalAmount = Order.getTotalAmount(order);
    return Math.round(totalAmount) === 0;
  }

  static isRefunded(order: Pick<Order, 'status'>): boolean {
    return order.status === OrderStatus.refunded;
  }

  static isEligibleToShippingFeesCancellation(
    order: Pick<Order, 'status'>,
  ): boolean {
    return order.status === OrderStatus.pending;
  }

  static isPaid(order: Pick<Order, 'paymentStatus'>): boolean {
    return PAYMENT_STATUSES_CONSIDERED_AS_PAID.includes(order.paymentStatus);
  }

  static isPaymentInProgress(order: Pick<Order, 'paymentStatus'>) {
    return order.paymentStatus === PaymentStatus.in_progress;
  }

  static isPaymentSuccessfulWithSEPADebit(order: Pick<Order, 'paymentStatus'>) {
    return order.paymentStatus === PaymentStatus.pending_sepa_debit;
  }
}
