import {
  Currency,
  currencySymbol,
  formatAmount,
  isMatchingAmountFormat,
} from '@bits-app/voggtpit-shared';

import { InputAdornment } from './InputAdornment';
import { TextField, TextFieldProps } from './TextField';

type AmountInputProps = {
  currency: Currency;
  amount: string;
  setAmount: (amount: string) => void;
  inputProps?: TextFieldProps['inputProps'];
};

export const AmountInput = ({ amount, setAmount, currency, inputProps }: AmountInputProps) => {
  const { onAmountChange, onFormatAmount } = useAmountInput(amount, setAmount);

  return (
    <TextField
      value={amount}
      onChange={onAmountChange}
      onBlur={onFormatAmount}
      size="small"
      InputProps={{
        endAdornment: <InputAdornment position="end">{currencySymbol(currency)}</InputAdornment>,
        inputProps: {
          lang: 'en_US',
          pattern: '[0-9]*.?[0-9]*',
          min: 0,
          sx: {
            textAlign: 'right',
            maxWidth: 100,
          },
          ...inputProps,
        },
      }}
    />
  );
};

export const useAmountInput = (amount: string, setAmount: (amount: string) => void) => {
  const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (!isMatchingAmountFormat(value)) {
      return;
    }

    if (Number(value) < 0) {
      return;
    }

    setAmount(value);
  };

  const onFormatAmount = () => {
    if (amount === '' || isNaN(Number(amount))) {
      setAmount('');
      return;
    }

    setAmount(formatAmount(Number(amount)));
  };

  return {
    onAmountChange,
    onFormatAmount,
  };
};
