import { useEffect, useState } from 'react';

import { Promotion } from '@bits-app/voggtpit-shared';

import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import {
  selectPromotionById,
  selectPromotionIsExpired,
  selectPromotionsUpdating,
} from '../redux/promotions-selector';
import { expirePromotion } from '../use-cases/expire-promotion';

export const useExpirePromotion = () => {
  const [currentPromotionId, setCurrentPromotionId] = useState<Promotion['id'] | null>(null);

  const dispatch = useAppDispatch();

  const promotion = useAppSelector((state) => selectPromotionById(state, currentPromotionId));
  const isExpired = useAppSelector((state) => selectPromotionIsExpired(state, currentPromotionId));
  const loading = useAppSelector(selectPromotionsUpdating);

  const onExpirePromotion = () => {
    if (!currentPromotionId || isExpired) {
      return;
    }

    dispatch(expirePromotion(currentPromotionId));
  };

  const onClose = () => {
    setCurrentPromotionId(null);
  };

  useEffect(() => {
    if (!loading && currentPromotionId) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return {
    openExpirationConfirmation: (id: Promotion['id']) => setCurrentPromotionId(id),
    confirmExpiration: {
      open: Boolean(currentPromotionId),
      onClose,
      onConfirm: onExpirePromotion,
      loading,
      promotionName: promotion?.name ?? '',
    },
  };
};
