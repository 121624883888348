import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export const DeleteButton = ({ onClick }: { onClick: () => void }) => (
  <IconButton
    color="error"
    onClick={(event) => {
      event.stopPropagation();
      onClick();
    }}
  >
    <Delete />
  </IconButton>
);
