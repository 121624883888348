import { useEffect } from 'react';

import { ownUserPasswordValidation } from '@bits-app/voggtpit-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { changeOwnUserPassword } from '@/auth/use-cases';
import { PasswordInput } from '@/components/elements/inputs/PasswordInput';
import { OwnUser } from '@/domain/entities/own-user.entity';
import { useAppDispatch } from '@/redux/reduxAppHooks';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

export interface ChangeOwnUserPasswordProps {
  ownUser: OwnUser;
  open: boolean;
  handleClose(): void;
}

export const ChangeOwnUserPassword = ({
  ownUser,
  open,
  handleClose,
}: ChangeOwnUserPasswordProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { register, handleSubmit, formState } = useForm<{ password: string }>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        password: ownUserPasswordValidation.required(),
      }),
    ),
  });

  const onSubmit = (data: { password: string }) => {
    dispatch(changeOwnUserPassword({ password: data.password, ownUserId: ownUser.id }));
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      handleClose();
    }
  }, [formState.isSubmitSuccessful, handleClose]);

  if (!ownUser) {
    handleClose();
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('members.changePassword', { username: ownUser.fullName })}</DialogTitle>

      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ maxWidth: 350 }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <PasswordInput
            fullWidth
            margin="dense"
            label={t('authentication.password')}
            {...register('password', { required: true })}
          />
          {formState.errors.password && (
            <FormHelperText error>{formState.errors.password?.message as string}</FormHelperText>
          )}

          <DialogActions sx={{ alignSelf: 'flex-end' }}>
            <Button onClick={handleClose} color="error">
              {t('cancel')}
            </Button>

            <LoadingButton variant="contained" loading={formState.isSubmitting} type="submit">
              {t('members.changePasswordValidate')}
            </LoadingButton>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
