import { PartnerProgramType } from '@bits-app/voggtpit-shared';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PartnerProgramState {
  partnerPrograms: Array<PartnerProgramType>;
  isLoading: boolean;
  error: string | null;
  updating: boolean;
  partnerProgramSelected: string | -1 | null;
}

const initialState: PartnerProgramState = {
  isLoading: false,
  error: null,
  updating: false,
  partnerPrograms: [],
  partnerProgramSelected: null,
};

export const partnerProgramSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<PartnerProgramState['partnerPrograms']>) => {
      state.partnerPrograms = payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<PartnerProgramState['isLoading']>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    optimisticCreation: (state, { payload }) => {
      state.partnerPrograms = [...state.partnerPrograms, payload];
    },
    optimisticUpdate: (state, { payload }) => {
      const index = state.partnerPrograms.findIndex(
        (partnerProgram) => partnerProgram.id === payload.partnerProgramId,
      );
      state.partnerPrograms[index] = {
        ...state.partnerPrograms[index],
        ...payload.partnerProgramChange,
      };
    },
    selectForEdition: (state, { payload }) => {
      state.partnerProgramSelected = payload;
    },
  },
});

export const partnerProgramActions = partnerProgramSlice.actions;
export const partnerProgramReducer = partnerProgramSlice.reducer;
