import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { AppThunkAction } from '@/redux/store';
import { NotAllOrderUpdatedError } from '@/voggt-database-explorer/order/gateways/order.port';

import { Order, PayoutStatus } from '../entities/order.entity';
import { ordersActions } from '../redux/order.slice';

export const updatePayoutStatusPurchasedFromShop =
  (
    orderIds: Order['id'][],
    payoutStatus: PayoutStatus,
    onSuccess?: () => void,
  ): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { orderGateway, snackbarGateway }) => {
    dispatch(ordersActions.setLoading(true));

    try {
      await orderGateway.updatePayoutStatusPurchasedFromShop(orderIds, payoutStatus);

      snackbarGateway.success('databaseExplorer.order.bulkUpdateShopOrderPayoutStatus.success', {
        interpolationMap: { count: orderIds.length },
      });

      onSuccess?.();
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));
      }

      if (error instanceof NotAllOrderUpdatedError) {
        snackbarGateway.error('databaseExplorer.order.bulkUpdateShopOrderPayoutStatus.someFailed', {
          interpolationMap: { count: orderIds.length },
        });

        dispatch(ordersActions.setError(error.failedOrders));
      } else {
        snackbarGateway.error('somethingWrongHappened');
      }
    } finally {
      dispatch(ordersActions.setLoading(false));
    }
  };
