import { User } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { useOwnUser } from '../../../context/own-user.context';
import { QUERIES_KEYS } from '../../../queries';

export const useZohoData = (userId: User['id']) => {
  const { hasPermissions } = useOwnUser();
  return useQuery(
    QUERIES_KEYS.getZohoUserLink(Number(userId)),
    () => authenticatedInstance.get<string>(`/zoho/${userId}/zoho-user-link`),
    { enabled: hasPermissions(PERMISSIONS.USER.READ_USER_ZOHO_LINK) },
  );
};
