import { ReactNode, useState } from 'react';

import { Country } from '@bits-app/bits-server-data';
import { Currency } from '@bits-app/voggtpit-shared';
import { Dialog, DialogContent, Step, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AddUsersFromCsv } from './add-users-from-csv.step';
import { SetupPromotionStep } from './setup-promotion.step';
export interface CreatePromotionCampaignProps {
  open: boolean;
  handleClose(): void;
  currency: Currency;
  countries: Country[];
}

export const CreatePromotionCampaignStepper = ({
  open,
  handleClose,
  currency,
  countries,
}: CreatePromotionCampaignProps) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  const steps = [t('promotion.setUpYourPromotion'), t('promotion.uploadUsers')];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose} fullWidth>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <>
          {activeStep === 0 && (
            <SetupPromotionStep
              currency={currency}
              countries={countries ?? []}
              handleNext={handleNext}
            />
          )}

          {activeStep === 1 && <AddUsersFromCsv handleNext={handleClose} />}
        </>
      </DialogContent>
    </Dialog>
  );
};
