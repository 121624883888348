import {
  add,
  formatAmount,
  minus,
  RefundDebitedAccount,
  RefundReason,
} from '@bits-app/voggtpit-shared';
import { t } from 'i18next';

import { OrderItem } from '@/entities/order-item.entity';
import { AppThunkAction } from '@/redux/store';

import {
  selectOrderItemsFromRefund,
  selectRefundableOrderItems,
} from '../../redux/refund.selectors';
import { refundActions } from '../../redux/refund.slice';

export const getDefaultRefundForDeliveredByHand =
  (): AppThunkAction<void> =>
  (dispatch, getState, { snackbarGateway }) => {
    dispatch(refundActions.setDebitedAccount(RefundDebitedAccount.seller));
    dispatch(refundActions.setRefundReason(RefundReason.deliveredByHand));

    const orderItemsToRefund = selectOrderItemsFromRefund(getState());

    const noOrderItemWithShippingFeeInTheRefund = orderItemsToRefund.every(
      ({ shippingAmount }) => !shippingAmount,
    );

    if (noOrderItemWithShippingFeeInTheRefund) {
      const refundableOrderItems = selectRefundableOrderItems(getState());

      const orderItemWithShippingFee = refundableOrderItems.find(({ shippingAmount }) =>
        Boolean(shippingAmount),
      );

      if (!orderItemWithShippingFee) {
        snackbarGateway.error(t('refund.noRefundableWithShippingFee'));
        return;
      }

      dispatch(refundActions.removeAllFromRefund());

      dispatch(
        refundActions.setRefundAmountFor({
          orderItemId: orderItemWithShippingFee.id,
          amount: formatAmount(orderItemWithShippingFee.shippingAmount),
        }),
      );

      return;
    }

    for (const orderItem of orderItemsToRefund) {
      dispatch(getDefaultAmountForDeliveredByHandForOrderItem(orderItem));
    }
  };

export const getDefaultAmountForDeliveredByHandForOrderItem =
  (orderItem: OrderItem): AppThunkAction<void> =>
  (dispatch) => {
    let amount = orderItem.shippingAmount;

    if (orderItem.refundedAmount !== undefined) {
      const maxRefundableAmount = add(orderItem.amount, orderItem.shippingAmount);
      const diffBetweenMaxAndRefunded = minus(maxRefundableAmount, orderItem.refundedAmount);

      if (diffBetweenMaxAndRefunded < orderItem.shippingAmount) {
        amount = amount - (orderItem.shippingAmount - diffBetweenMaxAndRefunded);
      }
    }

    dispatch(
      refundActions.setRefundAmountFor({
        orderItemId: orderItem.id,
        amount: formatAmount(amount),
      }),
    );
  };
