import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { ShippingCategory } from '../models/shippingCategory';

import { Country } from './Country';

@Entity()
@Index(['shippingCategory', 'shippingCountryId', 'destinationCountryId'], {
  unique: true,
})
export class ShippingFeeConfig {
  @PrimaryGeneratedColumn('increment')
  id!: string;

  @Index()
  @Column({
    type: 'enum',
    enum: ShippingCategory,
    enumName: 'shippingcategory_enum',
  })
  shippingCategory!: ShippingCategory;

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'shippingCountryId' })
  shippingCountry!: Country;

  @Index()
  @Column()
  shippingCountryId!: Country['id'];

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'destinationCountryId' })
  destinationCountry!: Country;

  @Index()
  @Column()
  destinationCountryId!: Country['id'];

  @Column()
  fixedFee!: number;

  @Column({ default: 0 })
  intervalCount!: number;

  @Column({ default: 0 })
  intervalFee!: number;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;
}
