import { useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import { User } from '@/voggt-database-explorer/users/users';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { useSnackbar } from '../../../components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '../../../queries';
import { UserTargetPromotionTypeWithPromotion } from '../views/profile/Promotions/type';

export const useRemoveUserToPromotion = (userId: User['id']) => {
  const [userTargetPromotionToDelete, setUserTargetPromotionToDelete] =
    useState<UserTargetPromotionTypeWithPromotion | null>(null);
  const snackbar = useSnackbar();
  const { mutate: handleDeleteUserTargetPromotion } = useMutation({
    mutationFn: () => {
      if (!userTargetPromotionToDelete) {
        return Promise.reject();
      }
      return authenticatedInstance.delete<boolean>(
        `/promotions/user-target-promotion/${userTargetPromotionToDelete.id}`,
      );
    },
    onSuccess: () => {
      setUserTargetPromotionToDelete(null);
      queryClient.invalidateQueries(QUERIES_KEYS.userDetail(userId));
      if (userTargetPromotionToDelete) {
        snackbar.success('databaseExplorer.users.promotion.success', {
          interpolationMap: { promotionId: userTargetPromotionToDelete.id, userId },
        });
      }
    },
    onError: () => {
      snackbar.error('somethingWrongHappened');
    },
  });

  return {
    handleDeleteUserTargetPromotion,
    setUserTargetPromotionToDelete,
    userTargetPromotionToDelete,
  };
};
