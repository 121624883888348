export type ShowLanguage = 'de' | 'en' | 'es' | 'fr' | 'nl';

import { CountryCodeIso2 } from '../entities/CountryCodeIso2';
import { DE, EN, ES, FR, NL } from '../entities/Language';

export interface LocalizedPhoneCode {
  phoneCode: string;
  language: ShowLanguage;
  countryCodeIso2: CountryCodeIso2;
}

/**
 * Add a new entry when creating a new language entity (as part of country launch for instance).
 */
export const localizedPhoneCodes: LocalizedPhoneCode[] = [
  { phoneCode: `+31`, language: NL, countryCodeIso2: CountryCodeIso2.NL }, // Netherlands
  { phoneCode: `+32`, language: FR, countryCodeIso2: CountryCodeIso2.BE }, // Belgium
  { phoneCode: `+33`, language: FR, countryCodeIso2: CountryCodeIso2.FR }, // France
  { phoneCode: `+34`, language: ES, countryCodeIso2: CountryCodeIso2.ES }, // Spain
  { phoneCode: `+41`, language: FR, countryCodeIso2: CountryCodeIso2.CH }, // Switzerland
  { phoneCode: `+44`, language: EN, countryCodeIso2: CountryCodeIso2.GB }, // United Kingdom of Great Britain
  { phoneCode: `+49`, language: DE, countryCodeIso2: CountryCodeIso2.DE }, // Germany
  { phoneCode: `+262`, language: FR, countryCodeIso2: CountryCodeIso2.RE }, // Réunion
  { phoneCode: `+352`, language: FR, countryCodeIso2: CountryCodeIso2.LU }, // Luxembourg
  { phoneCode: `+377`, language: FR, countryCodeIso2: CountryCodeIso2.MC }, // Monaco
];
