import type { ShippingAddress, Shipment, User } from '@bits-app/bits-server-data';
import { AvailableEntityForComment } from '@bits-app/voggtpit-shared';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from '@/components/elements/Dropdown';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParam } from '@/hooks/use-id-params';
import { EditButton } from '@/voggt-database-explorer/components/EditButton';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';
import { RefundAShipment } from '@/voggt-database-explorer/shipment/views/detail/RefundAShipment.modal';
import { useRefundAShipment } from '@/voggt-database-explorer/shipment/views/detail/useRefundAShipment';

import { ActivityLogs } from '../../../../components/elements/ActivityLogs';
import { useActivityLogs } from '../../../../hooks/use-activity-logs';
import { panelDetailsShippingAddress } from '../../../order/views/detail/useDetailsView';
import { panelDetailsUser } from '../../../show/misc/show.const';
import { panelDetailsShipments } from '../../shipment.const';

type DetailProps = {
  shipment: Shipment;
  loading: boolean;
};

export const Detail = ({ shipment, loading }: DetailProps) => {
  const shipmentId = useIdParam();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const activityLogs = useActivityLogs({
    entity: AvailableEntityForComment.shipment,
    entityId: shipmentId,
  });

  const refundShipmentHook = useRefundAShipment(shipmentId, shipment);

  const itemsMenuList = [
    {
      forbidden: !refundShipmentHook.isAuthorized,
      onClick: refundShipmentHook.toggleModalIsOpen,
      key: 'shipment-refund.action',
    },
  ];

  if (!shipmentId) {
    return null;
  }

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'}>
          <Stack spacing={4} direction="row" ml={'auto'} pr={1} pl={1}>
            <EditButton to={`/shipment/${shipmentId}/edit`} />
            <Dropdown
              title="Actions"
              options={itemsMenuList.map((item) => ({
                onClick: () => item.onClick && item.onClick(),
                forbidden: item.forbidden,
                label: t(item.key),
              }))}
            />
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12} md={12}>
            <PanelDetails<Shipment>
              title={t('databaseExplorer.panel.main')}
              informations={panelDetailsShipments}
              data={shipment}
              loading={loading}
            />
          </Grid>
          <Grid xs={12} md={12}>
            <PanelDetails<ShippingAddress>
              title={t('databaseExplorer.panel.shippingAddresses')}
              informations={panelDetailsShippingAddress}
              data={shipment?.shippingAddress}
              loading={loading}
              seeDetails={(data) => navigate(`/shipping-address/${data.id}`)}
            />
          </Grid>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12} md={12}>
            <PanelDetails<User>
              title={t('databaseExplorer.panel.buyer')}
              informations={panelDetailsUser}
              data={shipment?.customer}
              loading={loading}
              seeDetails={(data) => navigate(`/user/${data.id}`)}
            />
          </Grid>
          <Grid xs={12} md={12}>
            <PanelDetails<User>
              title={t('databaseExplorer.panel.seller')}
              informations={panelDetailsUser}
              data={shipment?.seller}
              loading={loading}
              seeDetails={(data) => navigate(`/user/${data.id}`)}
            />
          </Grid>
          <Grid xs={12}>
            <div className="bg-white rounded overflow-hidden">
              <ActivityLogs
                logs={activityLogs.data}
                isLoading={activityLogs.isLoading}
                entityId={shipmentId}
                entityName={AvailableEntityForComment.shipment}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      {refundShipmentHook.modalIsOpen && (
        <RefundAShipment
          shipmentId={shipmentId}
          isLoading={refundShipmentHook.isLoading}
          onCancel={refundShipmentHook.toggleModalIsOpen}
          onConfirm={refundShipmentHook.process}
          register={refundShipmentHook.register}
          control={refundShipmentHook.control}
        />
      )}
    </DetailsLayout>
  );
};
