import { useMemo } from 'react';

import { Language } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

export const useCellDefinition = () => {
  const CellLanguageDefinition = useMemo(
    (): CellDefinition<Language>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (language) => ({ link: `/language/${language.id}`, label: language?.id }),
      },
      {
        id: 'code',
        label: 'code',
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },

      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  return { CellLanguageDefinition };
};
