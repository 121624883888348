import { format } from 'date-fns';

import { TooltipWithTime } from './date/TooltipWithTime';

type DataTextProps = {
  title: string;
  date: string | Date | null;
};

export const DataDate = ({ title, date }: DataTextProps) => {
  const formattedDate = date ? format(new Date(date), 'dd/MM/yyyy') : 'N/A';

  return (
    <div className="flex flex-col gap-1">
      <p className="font-semibold capitalize">{title}</p>

      <div className="flex items-center">
        <TooltipWithTime date={date}>
          <p>{formattedDate}</p>
        </TooltipWithTime>
      </div>
    </div>
  );
};
