import { Operator } from '@bits-app/voggtpit-shared';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DateTimePicker } from '../../inputs/DateTimePicker';
const OPERATORS: Operator[] = ['equal', 'below', 'above', 'between'];

type DateTimeFilterProps = {
  selectedOperator: Operator;
  onChangeOperator: (operator: Operator) => void;
  date: Date | null;
  setDate: (date: Date | null) => void;
  toDate: Date | null;
  setToDate: (date: Date | null) => void;
  operators?: Operator[];
};

export const DateTimeFilter = ({
  selectedOperator,
  onChangeOperator,
  date,
  setDate,
  toDate,
  setToDate,
  operators = OPERATORS,
}: DateTimeFilterProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" gap={2} p={1}>
      <Select
        size="small"
        value={selectedOperator}
        onChange={(event: SelectChangeEvent<Operator>) =>
          onChangeOperator(event.target.value as Operator)
        }
        sx={{ minWidth: 112, alignSelf: 'start' }}
        disabled={operators.length <= 1}
      >
        {operators.map((operator) => (
          <MenuItem key={operator} value={operator}>
            {t(`operators.date.${operator}`)}
          </MenuItem>
        ))}
      </Select>

      <Box display="flex" flexDirection="column" gap={2}>
        <DateTimePicker
          date={date}
          onDateChange={(date) => setDate(date)}
          maxDate={selectedOperator === 'between' ? (toDate ? toDate : undefined) : undefined}
        />

        {selectedOperator === 'between' && (
          <DateTimePicker
            date={toDate}
            onDateChange={(date) => setToDate(date)}
            minDate={selectedOperator === 'between' ? (date ? date : undefined) : undefined}
          />
        )}
      </Box>
    </Box>
  );
};
