import { authenticatedInstance } from '@/axios/axios.instance';

import { DraftBadge } from '../entity/draft-badge';

export const createBadge = async (formData: FormData) => {
  const result = await authenticatedInstance.post<DraftBadge>(
    '/database-explorer/badge',
    formData,
    {
      headers: {
        ['Content-Type']: 'multipart/form-data',
      },
    },
  );

  return result.data;
};
