import { SentinelFraudCase } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { QUERIES_KEYS } from '../../../queries';

type UseSentinelDetailProps = {
  sentinelId?: SentinelFraudCase['id'];
};

export const useSentinelDetail = ({ sentinelId }: UseSentinelDetailProps) => {
  return useQuery(QUERIES_KEYS.sentinelDetail(sentinelId), () => querySentinelDetail(sentinelId), {
    enabled: Boolean(sentinelId),
  });
};

async function querySentinelDetail(sentinelId?: SentinelFraudCase['id']) {
  return authenticatedInstance.get<SentinelFraudCase>(`/sentinel/${sentinelId}`);
}
