import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  Unique,
} from 'typeorm';

import { TermsAndConditions } from './TermsAndConditions';
import { User } from './User';

@Entity()
@Unique(['termsAndConditionsId', 'userId'])
export class TermsAndConditionsAcceptance {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @ManyToOne(
    () => TermsAndConditions,
    (TaC) => TaC.termsAndConditionsAcceptances,
  )
  @JoinColumn({ name: 'termsAndConditionsId' })
  termsAndConditions!: TermsAndConditions;

  @Index()
  @Column()
  termsAndConditionsId!: TermsAndConditions['id'];

  @ManyToOne(() => User, (user) => user.termsAndConditionsAcceptances)
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Index()
  @Column()
  userId!: User['id'];

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;
}
