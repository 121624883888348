import { useEffect } from 'react';

import type { Show } from '@bits-app/bits-server-data';
import { ShowOrderDeliveryData } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';

import { QUERIES_KEYS } from '../../../queries';
import { PayoutData } from '../misc/payoutdata.type';

export const useShowDetails = (showId: number) => {
  const snackbar = useSnackbar();

  const showQuery = useQuery(QUERIES_KEYS.showDetail(showId), () =>
    authenticatedInstance.get<Show>(`/database-explorer/show/${showId}`),
  );

  const payoutDataQuery = useQuery(['show', 'payout', showId], () =>
    authenticatedInstance
      .get<PayoutData>(`/database-explorer/show/${showId}/payout`)
      .then((x) => x.data),
  );

  const orderDeliveryQuery = useQuery(['show', 'orders-delivery', showId], () =>
    authenticatedInstance
      .get<ShowOrderDeliveryData>(`/database-explorer/show/${showId}/orders-delivery`)
      .then((x) => x.data),
  );

  const hasError = [orderDeliveryQuery.error, payoutDataQuery.error, showQuery.error].some(Boolean);

  useEffect(() => {
    if (hasError) {
      snackbar.error('An error occurred while fetching the data. Please try again.');
    }
  }, [hasError, snackbar]);

  return {
    show: showQuery.data?.data,
    loading: showQuery.isLoading || payoutDataQuery.isLoading || orderDeliveryQuery.isLoading,
    payoutData: payoutDataQuery.data,
    orderDeliveryData: orderDeliveryQuery.data,
    hasError,
    refreshAfterEdit: () => {
      showQuery.refetch();
      payoutDataQuery.refetch();
      orderDeliveryQuery.refetch();
    },
  };
};
