import { SellerCanceledRequestError } from '@bits-app/voggtpit-shared';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { BadRequestError } from '@/domain/errors/BadRequestError';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { FieldError } from '@/domain/errors/FieldError';
import { ForbiddenError } from '@/domain/errors/ForbiddenError';
import { NotFoundError } from '@/domain/errors/NotFoundError';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';

const isProduction = process.env.REACT_APP_ENV === 'production';
const baseURL = isProduction ? '/api' : '/api/dev';

const xRobotsTagHeader = {
  'X-Robots-Tag': 'noindex',
};

export const publicInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: xRobotsTagHeader,
});

export const authenticatedInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: xRobotsTagHeader,
});

authenticatedInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const expirationAccessToken = getCookie('expirationAccessToken');

  if (expirationAccessToken) {
    return config;
  }

  await publicInstance.get('/auth/refresh');

  return config;
});

authenticatedInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError<{ message?: unknown; error?: string; field?: string }>) => {
    if (error.response?.status === 401) {
      throw new UnauthorizedError();
    }

    if (error.response?.status === 403) {
      throw new ForbiddenError();
    }

    if (error.response?.status === 404) {
      throw new NotFoundError();
    }

    if (error.response?.status === 400) {
      if (error.response.data) {
        if (error.response.data.error === 'seller-canceled-request-errors') {
          throw new SellerCanceledRequestError(
            error.response.data
              .message as SellerCanceledRequestError['errors'] as SellerCanceledRequestError['errors'],
          );
        }

        if (error.response.data.error === 'field-error') {
          throw new FieldError(
            error.response.data.field as string,
            error.response.data.message as string,
          );
        }
      }

      if (error.response.data.message) {
        throw new BadRequestError(JSON.stringify(error.response.data.message));
      }

      throw new BadRequestError(JSON.stringify(error?.response?.data ?? ''));
    }

    if (error.code === 'ERR_CANCELED') {
      throw new CanceledRequestError('Request was canceled');
    }

    throw error;
  },
);

const getCookie = (name: string): string | null => {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((cookie) => cookie.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
};
