import { CommercialEvent } from '@bits-app/bits-server-data';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';

import { cellCommercialEventsDefinition } from '../../hook/useCellDefinition';

export const CommercialEventsTab = ({
  commercialEvents,
  onRefresh,
}: {
  commercialEvents: CommercialEvent[];
  onRefresh: () => void;
}) => {
  return (
    <ListCustom<CommercialEvent>
      cellDefinition={cellCommercialEventsDefinition}
      results={commercialEvents}
      enableFilters
      onRefresh={onRefresh}
    />
  );
};
