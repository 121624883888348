import { CommercialEvent } from '@bits-app/bits-server-data';
import { isAfter, isBefore } from 'date-fns';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { Information } from '@/components/layouts/PanelDetails';
export const cellDefinitionCommercialEvent: CellDefinition<CommercialEvent>[] = [
  { id: 'id', label: 'Id' },
  {
    id: 'isLive' as unknown as keyof CommercialEvent,
    label: 'is live',
    type: 'badge',
    accessorFn: (commercialEvent) => {
      const isAfterStart = isAfter(new Date(), new Date(commercialEvent.startAt));
      const isBeforeEnd = isBefore(new Date(), new Date(commercialEvent.endAt));
      if (isAfterStart && isBeforeEnd) {
        return 'Active';
      }
      if (!isAfterStart) {
        return 'Not started';
      }
      return 'Ended';
    },
  },
  { id: 'internalName', label: 'Internal name' },
  { id: 'name', label: 'Name' },
  { id: 'commercialEventType', label: 'Type' },
  { id: 'startAt', label: 'Start at', type: 'date-with-time' },
  { id: 'endAt', label: 'End at', type: 'date-with-time' },
  { id: 'weight', label: 'Weight' },
  { id: 'bannerUrl', label: 'Banner Url', type: 'image' },
  { id: 'coverUrl', label: 'Cover url', type: 'image' },
  {
    id: 'countryId',
    label: 'Country',
    type: 'internal-link',
    getLink: (commercialEvent) => ({
      label: commercialEvent?.country?.name,
      link: `/country/${commercialEvent?.countryId}`,
    }),
  },
  { id: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { id: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
  { id: 'deletedAt', label: 'Deleted at', type: 'date-with-time' },
];

export const panelInformation: Information<CommercialEvent>[] = [
  { label: 'ID', value: 'id' },
  { label: 'Name', value: 'name' },
  { label: 'Internal name', value: 'internalName' },
  { label: 'Weight', value: 'weight' },
  { label: 'Country id ', value: 'countryId' },
  { label: 'Banner url', value: 'bannerUrl', type: 'image' },
  { label: 'Cover url', value: 'coverUrl', type: 'image' },
];

export const timestampPanelInformation: Information<CommercialEvent>[] = [
  { label: 'Created at', value: 'createdAt', type: 'date-with-time' },
  { label: 'Updated at', value: 'updatedAt', type: 'date-with-time' },
  { label: 'Deleted at', value: 'deletedAt', type: 'date-with-time' },
];

export const statusPanelInformation: Information<CommercialEvent>[] = [
  { label: 'Start at', value: 'startAt', type: 'date-with-time' },
  { label: 'End at', value: 'endAt', type: 'date-with-time' },
];
