import { useReducer } from 'react';

import { CommercialEvent } from '@bits-app/bits-server-data';
import { Schema } from '@bits-app/voggtpit-shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

import { EntityWrapper } from '../../redux/database-explorer';

export const useDuplicateCommercialEvent = (commercialEventId: CommercialEvent['id']) => {
  const [confirmationModalStatus, toggleConfirmationModalStatus] = useReducer(
    (state) => !state,
    false,
  );

  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const { data: schemas = [] } = useGetDuplicateSchemaCommercialEvent(commercialEventId);

  const { mutate, isLoading } = useMutation(
    (commercialEvent: Partial<CommercialEvent>) =>
      authenticatedInstance.post(
        `database-explorer/commercial-event/duplicate/${commercialEventId}`,
        { commercialEvent },
      ),
    {
      onSuccess: () => {
        snackbar.success(t(`databaseExplorer.commercialEvent.duplicate.success`));
        queryClient.invalidateQueries(QUERIES_KEYS.commercialEventList());
        toggleConfirmationModalStatus();
      },
      onError: () => {
        snackbar.error(t(`databaseExplorer.commercialEvent.duplicate.error`));
      },
    },
  );

  const schemasWithTraduction = schemas.map((schema) => ({
    ...schema,
    categoryLabel: t('duplicate'),
  }));

  return {
    isLoading,
    toggleConfirmationModalStatus,
    confirmationModalStatus,
    mutate,
    header: {
      title: t('databaseExplorer.commercialEvent.duplicate'),
    },
    schemas: schemasWithTraduction,
  };
};

const useGetDuplicateSchemaCommercialEvent = (commercialEventId: CommercialEvent['id']) => {
  return useQuery(QUERIES_KEYS.commercialEventMutation(), async () => {
    const { data } = await authenticatedInstance.get<Schema<EntityWrapper>[]>(
      `/database-explorer/commercial-event/duplicate-schema/${commercialEventId}`,
    );
    return data;
  });
};
