import { useEffect, useState } from 'react';

import { MassImportExpectedType } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import chunk from 'lodash/chunk';

import { authenticatedInstance } from '../../axios/axios.instance';
import { useSnackbar } from '../../components/elements/snackbar/use-snackbar';

import { MassImportResolver } from './matrice';
import { AllowedFeatureForMassImport, GenericCsv } from './type';

const CHUNK_SIZE = 20;

export const useMassImportMutation = (key: AllowedFeatureForMassImport | null) => {
  const [hasPassValidation, setHasPassValidation] = useState<boolean>(false);
  const snackbar = useSnackbar();

  const [progression, setProgression] = useState<number>(0);
  const [validationErrors, setValidationErrors] = useState<MassImportExpectedType[]>([]);
  const [commitErrors, setCommitErrors] = useState<MassImportExpectedType[]>([]);

  const { mutateAsync: executeCommit } = useMutation({
    mutationFn: (payload: GenericCsv[]) => {
      if (!key) {
        throw new Error('No key found');
      }
      const path = MassImportResolver[key].commitPath;
      if (!path) {
        throw new Error(`No mutation resolver found for ${key}`);
      }
      return authenticatedInstance.post<MassImportExpectedType[]>(path, payload);
    },
    onError: (err) => {
      const error = typeof err === 'string' ? err : JSON.stringify(err);
      snackbar.error(`An error occured : ${error}`);
    },
  });

  const { mutateAsync: executeBackendValidation } = useMutation({
    mutationFn: (payload: GenericCsv[]) => {
      if (!key) {
        throw new Error('No key found');
      }
      const path = MassImportResolver[key].backendValidationPath;
      if (!path) {
        throw new Error(`No mutation resolver found for ${key}`);
      }
      return authenticatedInstance.post<MassImportExpectedType[]>(path, payload);
    },
    onError: (err) => {
      const error = typeof err === 'string' ? err : JSON.stringify(err);
      snackbar.error(`An error occured : ${error}`);
    },
  });

  const handleSubmission = async (payload: GenericCsv[]) => {
    const chunks = chunk(payload, CHUNK_SIZE);
    let pointer = 1;
    const stack: MassImportExpectedType[] = [];
    for (const chunk of chunks) {
      const result = await executeCommit(chunk);
      result.data.forEach((data) => {
        const rowIdCalculated = data.rowId + (pointer - 1) * CHUNK_SIZE;
        if (data.errorMessage) {
          stack.push({
            ...data,
            rowId: rowIdCalculated,
          });
        }
      });
      setProgression(Math.floor((pointer++ / chunks.length) * 100));
    }
    if (stack.length) {
      setCommitErrors(stack);
    } else {
      snackbar.success('All rows have been proceed');
    }
  };

  const handleBackendValidation = async (payload: GenericCsv[]) => {
    const chunks = chunk(payload, CHUNK_SIZE);
    let pointer = 1;
    const stack: MassImportExpectedType[] = [];
    for (const chunk of chunks) {
      const result = await executeBackendValidation(chunk);
      result.data.forEach((data) => {
        const rowIdCalculated = data.rowId + (pointer - 1) * CHUNK_SIZE;
        if (data.errorMessage) {
          stack.push({
            ...data,
            rowId: rowIdCalculated,
          });
        }
      });
      setProgression(Math.floor((pointer++ / chunks.length) * 100));
    }
    if (stack.length) {
      setValidationErrors(stack);
    } else {
      setHasPassValidation(true);
    }
  };

  useEffect(() => {
    if (key) {
      const shouldRequireValidation = Boolean(MassImportResolver[key].backendValidationPath);
      setHasPassValidation(!shouldRequireValidation);
      setProgression(0);
      setValidationErrors([]);
      setCommitErrors([]);
    }
  }, [key]);

  return {
    executeCommit: handleSubmission,
    executeBackendValidation: handleBackendValidation,
    hasPassValidation,
    progression,
    validationErrors,
    commitErrors,
  };
};
