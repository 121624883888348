import { CommentType } from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import { CommentPort } from '@/domain/Comments.port';
import logger from '@/logger/logger';

import { BackendCommentResponse } from '../comments.type';

export class HttpCommentAdapter implements CommentPort {
  async getComments(
    entityName: CommentType['entityName'],
    entityId: CommentType['entityId'],
    signal?: AbortController['signal'],
  ): Promise<BackendCommentResponse> {
    try {
      const { data } = await authenticatedInstance.get(
        `comment?entityName=${entityName}&entityId=${entityId}`,
        { signal },
      );
      return data;
    } catch (e) {
      logger.warn('[HTTPCommentAdapter:deleteComment]', e);
      return [];
    }
  }
  async deleteComment(commentId: string): Promise<void> {
    try {
      await authenticatedInstance.delete(`comment/${commentId}`);
    } catch (e) {
      logger.warn('[HTTPCommentAdapter:deleteComment]', e);
    }
  }

  async createComment(comment: Partial<CommentType>): Promise<void> {
    try {
      await authenticatedInstance.post('comment', comment);
    } catch (e) {
      logger.warn('[HTTPCommentAdapter:createComment]', e);
    }
  }
}
