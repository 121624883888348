import { Dispatch, useCallback, useReducer } from 'react';

import { Badge } from '@bits-app/bits-server-data';
import { useMutation } from '@tanstack/react-query';
import { parse as parseCsv } from 'papaparse';
import { useTranslation } from 'react-i18next';

import { useTypesenseSearch } from '@/hooks/use-typesense';
import { useSearchVoggtPayloadType } from '@/hooks/use-typesense/use-typesense';

import { authenticatedInstance } from '../../../../axios/axios.instance';
import { useSnackbar } from '../../../../components/elements/snackbar/use-snackbar';

import { ActionReducer, initialState, reduceUseUpdateBadges, UserList } from './reducer';

export const useBulkUpdateBadges = () => {
  const [state, dispatch] = useReducer(reduceUseUpdateBadges, initialState);

  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const params: useSearchVoggtPayloadType<Badge> = {
    typesenseIndex: 'badge',
    sortBy: 'updatedAt_Unix:desc',
    typesenseParams: {
      per_page: 20,
      query_by: ['name', 'createdAt'],
    },
  };

  const { results: badges } = useTypesenseSearch<Badge>(params);

  const {
    closeModal,
    loadingSwitchOff,
    openModal,
    updateUserList,
    updateSelectedBadge,
    updateError,
    cleanErrorMessage,
    cleanSuccessMessage,
  } = getMethod(dispatch);

  const { mutate: handleSubmit, isLoading } = useMutation({
    mutationFn: () => {
      const { userList, selectedBadge } = state;
      if (!userList.userIds) {
        return Promise.reject();
      }
      if (!userList.userIds && !selectedBadge) {
        return Promise.reject();
      }
      cleanSuccessMessage();
      const payload = userList.userIds.map((userId) => ({
        userId,
        badgeId: selectedBadge,
      }));
      return authenticatedInstance.post('database-explorer/customer/update-mass-badge', payload);
    },
    onSuccess: () => {
      snackbar.success('databaseExplorer.users.badge.add.success');
    },
    onError: () => {
      snackbar.error('SomethingWentWrong');
    },
  });

  const onBadgeChange = (badgeId: Badge['id']) => {
    updateSelectedBadge(badgeId);
  };

  const onDropFile = useCallback((files: File[]) => {
    const [file] = files;
    const reader = new FileReader();

    reader.onloadstart = () => {
      cleanErrorMessage();
      cleanSuccessMessage();
    };

    reader.onload = () => {
      const { result } = reader;
      if (typeof result === 'string') {
        const transformHeaderCsvToJson = (header: string) => {
          if (header.trim() === 'user id') {
            return 'userId';
          }
          return header;
        };

        const { data } = parseCsv<{ userId: string }>(result, {
          header: true,
          transformHeader: transformHeaderCsvToJson,
          skipEmptyLines: true,
        });

        if (data.length === 0) {
          updateError(t('updateBadges.errors.emptyFile'));
          reader.dispatchEvent(new Event('error'));
          return;
        }

        if (data[0].userId === undefined) {
          const transformHeaderJsonToCSV = (header: string) => {
            if (header === 'userId') {
              return 'user id';
            }
            return header;
          };

          updateError(
            t('updateBadges.errors.wrongHeader', {
              given: Object.keys(data[0]).map(transformHeaderJsonToCSV).join(', '),
            }),
          );
          reader.dispatchEvent(new Event('error'));
          return;
        }

        const malFormedUserId = data.filter((userIdObject) => !userIdObject.userId.match(/^\d+$/));

        if (malFormedUserId.length > 0) {
          updateError(t('updateBadges.errors.userIds'));
          reader.dispatchEvent(new Event('error'));
        }

        const userList = { userIds: data.map((userIdObject) => Number(userIdObject.userId)) };

        updateUserList(userList);
      }
      loadingSwitchOff();
    };
    reader.readAsText(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...state,
    isLoading,
    badges: badges.filter((badge) => badge.name && badge.filename),
    handleSubmit,
    openModal,
    updateSelectedBadge,
    closeModal,
    onDropFile,
    onBadgeChange,
  };
};

function getMethod(dispatch: Dispatch<ActionReducer>) {
  const openModal = () => dispatch({ type: 'userOpenModal' });
  const closeModal = () => dispatch({ type: 'userCloseModal' });

  const loadingSwitchOn = () => dispatch({ type: 'loadingSwitchOn' });
  const loadingSwitchOff = () => dispatch({ type: 'loadingSwitchOff' });

  const updateSelectedBadge = (selectedBadge: Badge['id']) => {
    dispatch({ type: 'updateSelectedBadge', payload: { selectedBadge } });
  };

  const updateUserList = (userList: UserList) =>
    dispatch({ type: 'updateUserList', payload: { userList } });

  const updateErrorMessage = (errorUsers: number[]) =>
    dispatch({ type: 'updateErrorUsers', payload: { errorUsers } });

  const cleanError = () => dispatch({ type: 'cleanError' });

  const updateError = (error: string) => dispatch({ type: 'updateError', payload: { error } });

  const cleanErrorMessage = () => dispatch({ type: 'cleanErrorMessage' });

  const cleanSuccessMessage = () => dispatch({ type: 'cleanSuccessMessage' });
  const setSuccessMessage = () => dispatch({ type: 'setSuccessMessage' });

  return {
    openModal,
    closeModal,
    loadingSwitchOff,
    loadingSwitchOn,
    updateUserList,
    updateSelectedBadge,
    updateError,
    cleanError,
    updateErrorMessage,
    cleanErrorMessage,
    cleanSuccessMessage,
    setSuccessMessage,
  };
}
