import { useEffect, useState } from 'react';

import { Box, Paper, TextField } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Label } from '@/components/elements/Label';
import { LoadingButton } from '@/components/elements/buttons/LoadingButton';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import { clearRefundOrderItem } from '../../refund/use-cases';
import { selectIsProcessingRefund, selectIsRefundLoading } from '../redux/refund.selectors';

import { useStartRefund } from './hooks/use-start-refund';

export const Refund = () => {
  const [id, setId] = useState('');
  const isProcessingRefund = useAppSelector(selectIsProcessingRefund);
  const isLoading = useAppSelector(selectIsRefundLoading);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const startRefund = useStartRefund();

  const handleOnChangeId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setId(value);
  };

  const handleOnStartRefundClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    startRefund(id);
  };

  useEffect(() => {
    if (!params.id) {
      dispatch(clearRefundOrderItem());
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (isProcessingRefund) {
      navigate(`/refund/${id}`);
    } else {
      navigate('/refund');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessingRefund, navigate]);

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <Box pt={3}>
        <Paper
          sx={{
            marginBottom: ({ spacing }) => spacing(4),
            padding: ({ spacing }) => spacing(1),
            backgroundColor: ({ palette }) => palette.background.paper,
            fontStyle: 'italic',
          }}
        >
          <Trans i18nKey="refund.featureDescription">
            <p>
              You can use an order item id (e.g: 9012) or a order id (e.g: 1234_5678) as entry point
              of the refund.
            </p>

            <p>
              Take into account that an order item NOT purchased during a show (e.g: in a shop),
              will be considered as not found because they are not (yet) handled by the Voggtpit.
            </p>

            <p>Thanks for your understanding.</p>
          </Trans>
        </Paper>

        <Box component="form" onSubmit={handleOnStartRefundClick}>
          <Label htmlFor="orderItemId">{t('refund.orderItemLabel')}</Label>

          <Box display="flex" alignItems="center" gap={3}>
            <TextField
              required
              name="orderItemId"
              type="text"
              value={id}
              onChange={handleOnChangeId}
              placeholder="xxxxx or xxxxx_xxxxx"
              size="small"
            />

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={!id || isLoading}
            >
              {t('refund.startRefund')}
            </LoadingButton>
          </Box>
        </Box>

        <Outlet />
      </Box>
    </div>
  );
};
