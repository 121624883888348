import { EditionSchema } from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import logger from '@/logger/logger';

import { EntityWrapper, ResourceType } from '../database-explorer';
import { DatabaseExplorerPort } from '../database-explorer.port';

export class HttpDatabaseExplorerAdapter implements DatabaseExplorerPort {
  async commitChange<T extends EntityWrapper>(
    resourceId: T['id'],
    resourceType: ResourceType,
    change: Partial<T>,
  ): Promise<T> {
    try {
      const path = resolveResourcePath(resourceType, resourceId, 'PATCH');
      if (!path) throw new Error('No available path');
      const { data } = await authenticatedInstance.patch(path, change);
      return data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async getResource<T extends EntityWrapper>(
    resourceId: T['id'],
    resourceType: ResourceType,
  ): Promise<T> {
    try {
      const path = resolveResourcePath<T>(resourceType, resourceId, 'GET');
      if (!path) throw new Error('No available path');
      const { data } = await authenticatedInstance.get(path);
      return data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  async getEditionSchema<T extends EntityWrapper>(
    resourceId: T['id'],
    resourceType: ResourceType,
  ): Promise<EditionSchema<T>> {
    try {
      const path = resolveResourcePathForEditionSchema<T>(resourceType, resourceId);

      if (!path) throw new Error(`No available path for resource type ${resourceType}`);

      const { data } = await authenticatedInstance.get(`${path}/edition-schema`);

      return data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }
}

function resolveResourcePath<T extends { id: string | number }>(
  resourceType: ResourceType,
  resourceId: T['id'],
  method: 'GET' | 'PATCH',
) {
  switch (resourceType) {
    case 'User':
      // TODO rename api endpoint to user as it is the naming for referencing voggt user
      return `/database-explorer/customer/${resourceId}`;
    case 'Show':
    case 'Order':
    case 'Product':
    case 'Shipping-Address':
    case 'Channel':
    case 'Category':
    case 'Country':
    case 'Badge':
    case 'Language':
    case 'Shop':
    case 'Blocked-Shipping-Address':
      return `/database-explorer/${resourceType.toLowerCase()}/${resourceId}`;
    case 'paymentMethodBancontact':
    case 'paymentMethodCard':
    case 'paymentMethodGiropay':
    case 'paymentMethodIdeal':
    case 'paymentMethodPaypal':
      return `/database-explorer/payment-method/${resourceType
        .toLowerCase()
        .replace('paymentmethod', '')}/${resourceId}`;

    case 'Seller-Config':
      return method === 'PATCH'
        ? `/database-explorer/seller-config/${resourceId}`
        : `/database-explorer/seller-config/user/${resourceId}`;

    case 'Shipping-Fee-Config':
      return `/database-explorer/shipping-fee-config/${resourceId}`;
    case 'Seller-News':
      return `/database-explorer/seller-news/${resourceId}`;
    case 'Seller-Company':
      return `/database-explorer/seller-company/${resourceId}`;
    case 'Parent-Category':
      return `/database-explorer/parent-category/${resourceId}`;
    case 'Shipment':
      return `/database-explorer/shipment/${resourceId}`;
    case 'CommercialEvent':
      return `/database-explorer/commercial-event/${resourceId}`;
    case 'MarketPage':
      return `/market-page/${resourceId}`;
    default:
      return null;
  }
}

function resolveResourcePathForEditionSchema<T extends { id: string | number }>(
  resourceType: ResourceType,
  resourceId: T['id'],
) {
  switch (resourceType) {
    case 'Seller-Config':
      return `/database-explorer/seller-config/${resourceId}`;
    case 'Seller-Company':
      return `/database-explorer/seller-company/${resourceId}`;
    default:
      return resolveResourcePath(resourceType, resourceId, 'GET');
  }
}
