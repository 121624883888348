import { Badge } from '@bits-app/bits-server-data';

import { authenticatedInstance } from '@/axios/axios.instance';

import { DraftBadge } from '../entity/draft-badge';

export const deleteBadge = async (
  id: Badge['id'] | DraftBadge['id'],
  isDraft?: boolean,
): Promise<void> => {
  if (isDraft) {
    await authenticatedInstance.delete<void>(`/database-explorer/badge/draft/${id}`);
    return;
  }

  await authenticatedInstance.delete<void>(`/database-explorer/badge/${id}`);
};
