import { useEffect, useMemo } from 'react';

import { Country, Show } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { Information } from '@/components/layouts/PanelDetails';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { selectResourceLoading } from '@/voggt-database-explorer/redux/database-explorer.slice';
import {
  getResource,
  selectResource,
  useResourceSelector,
} from '@/voggt-database-explorer/redux/use-cases/get-resource';

export const useCountryDetailsView = (countryId: string) => {
  const dispatch = useAppDispatch();

  const country = useResourceSelector<Country>(selectResource);
  const loading = useAppSelector(selectResourceLoading);

  const CellShowDefinition = useMemo(
    (): CellDefinition<Show>[] => [
      {
        id: 'id',
        label: 'id',
        type: 'internal-link',
        getLink: (show) => ({ label: show.id, link: `/show/${show.id}` }),
      },
      {
        id: 'userId',
        label: 'User',
        type: 'internal-link',
        getLink: (show) => ({
          label: show.user as unknown as string,
          link: `/user/${show.userId}`,
        }),
      },
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'startAt',
        label: 'Start at',
        type: 'date-with-time',
      },
      {
        id: 'endedAt',
        label: 'End at',
        type: 'date-with-time',
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  useEffect(() => {
    if (countryId && country.id !== countryId) {
      dispatch(getResource(countryId, 'Country'));
    }
  }, [countryId, country.id, dispatch]);

  return {
    country,
    loading,
    CellShowDefinition,
    panelDetailsCountry,
  };
};

const panelDetailsCountry: Information<Country>[] = [
  { value: 'id', label: 'id' },
  { value: 'name', label: 'Name' },
  { value: 'iso2Code', label: 'iso 2 code' },
  { value: 'iso3Code', label: 'iso 3 code' },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
];
