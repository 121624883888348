import { Checkbox, TableHead, TableRow } from '@mui/material';

import { TableCellHeaderBorderBottom } from '@/components/elements/StyledTableCell';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import { toggleAllRefundableSellerCancelRequests } from '../../seller-cancel-requests/use-cases';
import {
  selectSellerCancelRequestsInRefund,
  selectAreAllRefundableSellerCancelRequestsInTheRefund,
} from '../redux/seller-cancel-requests.selectors';

import { HeadCell } from './SellerCancelRequests';

type SellerCancelRequestsHeaderProps = {
  headCells: HeadCell[];
  loading: boolean;
  isReadOnly: boolean;
};

export const SellerCancelRequestsHeader = ({
  headCells,
  loading,
  isReadOnly,
}: SellerCancelRequestsHeaderProps) => {
  const selectedSellerCancelRequest = useAppSelector(selectSellerCancelRequestsInRefund);
  const areAllRefundableSelected = useAppSelector(
    selectAreAllRefundableSellerCancelRequestsInTheRefund,
  );

  const dispatch = useAppDispatch();

  const handleOnToggleAll = () => {
    dispatch(toggleAllRefundableSellerCancelRequests());
  };

  return (
    <TableHead>
      <TableRow>
        {!isReadOnly && (
          <TableCellHeaderBorderBottom padding="checkbox">
            <Checkbox
              checked={areAllRefundableSelected}
              indeterminate={!areAllRefundableSelected && selectedSellerCancelRequest.length > 0}
              onChange={handleOnToggleAll}
              disabled={loading}
            />
          </TableCellHeaderBorderBottom>
        )}

        {headCells.map((headCell) => (
          <TableCellHeaderBorderBottom
            key={headCell.id}
            minWidth={headCell.minWidth}
            sx={{
              fontWeight: 'bold',
            }}
          >
            {headCell.label}
          </TableCellHeaderBorderBottom>
        ))}
      </TableRow>
    </TableHead>
  );
};
