import { Product, Show } from '@bits-app/bits-server-data';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ButtonContained } from '@/components/elements/buttons/ButtonContained';

type RemoveFromListModalProps = {
  onConfirm: (itemIdToRemove: Product['id'] | Show['id'], itemType: 'products' | 'shows') => void;
  onCancel: () => void;
  itemIdToRemove?: Product['id'] | Show['id'];
  itemType: 'products' | 'shows';
};

export const RemoveFromListModal = ({
  onConfirm,
  onCancel,
  itemIdToRemove,
  itemType,
}: RemoveFromListModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={Boolean(itemIdToRemove)}>
      <DialogTitle id="alert-dialog-title">
        {t('databaseExplorer.commercialEvent.removeItem.title')}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Trans i18nKey="databaseExplorer.commercialEvent.removeItem.description" />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>{t('action.cancel')}</Button>
        <ButtonContained
          onClick={() => itemIdToRemove && onConfirm(itemIdToRemove, itemType)}
          color="error"
        >
          {t('action.continue')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
