import { currencySymbol, formatAmount } from '@bits-app/voggtpit-shared';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { TableRow } from '@mui/material';

import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { useAppSelector } from '@/redux/reduxAppHooks';
import { TableCellBodyNoBorderBottom, TableCellEllipsisContent } from '@Elements/StyledTableCell';
import { TextWithConditionalPrefixIcon } from '@Elements/TextWithConditionalPrefixIcon';

import {
  selectIsOrderItemInTheRefund,
  selectIsOrderItemRefundable,
  selectOrderItemIsPurchasedWithPromotion,
} from '../../../redux/refund.selectors';

import { OrderItemRefundForm } from './OrderItemRefundForm';

type OrderItemRowProps = {
  orderItem: OrderItemRefund;
  isReadOnly: boolean;
};

export const OrderItemRow = ({ orderItem, isReadOnly }: OrderItemRowProps) => {
  const isPurchasedWithPromotion = useAppSelector((state) =>
    selectOrderItemIsPurchasedWithPromotion(state, orderItem.id),
  );
  const isOrderItemRefundable = useAppSelector((state) =>
    selectIsOrderItemRefundable(state, orderItem),
  );
  const selected = useAppSelector((state) => selectIsOrderItemInTheRefund(state, orderItem.id));

  const promotionAmount = orderItem.promotionAmount ?? 0;
  const amountWithoutPromotion = orderItem.promotionAmount
    ? orderItem.amount - orderItem.promotionAmount
    : orderItem.amount;

  return (
    <TableRow sx={{ opacity: !isOrderItemRefundable ? 0.7 : 1 }}>
      <TableCellBodyNoBorderBottom minWidth={120}>
        {orderItem.shippingStatus}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={130}>
        {orderItem.paymentStatus}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={100} align="right">
        {formatAmount(amountWithoutPromotion)} {currencySymbol(orderItem.currency)}
        {isPurchasedWithPromotion && orderItem.promotionAmount && (
          <TextWithConditionalPrefixIcon
            isIconVisible={isPurchasedWithPromotion}
            Icon={CardGiftcardIcon}
          >
            {formatAmount(promotionAmount)} {currencySymbol(orderItem.currency)}
          </TextWithConditionalPrefixIcon>
        )}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={135} align="right">
        {formatAmount(orderItem.shippingAmount)} {currencySymbol(orderItem.currency)}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={100} align="right">
        {formatAmount(orderItem.commission)} {currencySymbol(orderItem.currency)}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={180}>
        {orderItem.purchaseDate}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={155}>
        {orderItem.refundedAmount !== undefined
          ? `${formatAmount(orderItem.refundedAmount)} ${currencySymbol(orderItem.currency)}`
          : ''}
      </TableCellBodyNoBorderBottom>

      <TableCellEllipsisContent maxWidth={160} minWidth={160} title={orderItem.refundReason}>
        {orderItem.refundReason}
      </TableCellEllipsisContent>

      <TableCellBodyNoBorderBottom minWidth={150}>
        {orderItem.refundDebitedAccount}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom />

      {!isReadOnly && (
        <OrderItemRefundForm orderItem={orderItem} activated={selected && isOrderItemRefundable} />
      )}
    </TableRow>
  );
};
