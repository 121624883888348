import { Promotion } from '@bits-app/voggtpit-shared';
import { createSelector } from '@reduxjs/toolkit';
import { isBefore } from 'date-fns';

import { RootState } from '@/redux/store';

const promotionSelector = (state: RootState) => state.promotions;

export const selectPromotions = createSelector(promotionSelector, (state) => state.promotions);
export const selectPromotionsCount = createSelector(promotionSelector, (state) => state.count);
export const selectPromotionsLoading = createSelector(promotionSelector, (state) => state.loading);
export const selectPromotionsUpdating = createSelector(
  promotionSelector,
  (state) => state.updating,
);
export const selectPromotionById = createSelector(
  [selectPromotions, (_, id: Promotion['id'] | null) => id],
  (promotions, id) => {
    if (!id) {
      return;
    }

    return promotions.find((promotion) => promotion.id === id);
  },
);
export const selectPromotionIsExpired = createSelector(
  [selectPromotionById, (_, id: Promotion['id'] | null) => id],
  (promotion) => {
    if (!promotion) {
      return false;
    }

    return isBefore(new Date(promotion.expireAt), new Date());
  },
);
