import { PERMISSIONS } from '@bits-app/voggtpit-shared';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';
import { useOwnUser } from '@/context/own-user.context';
import { BulkUpdateLedgerModal } from '@/voggt-database-explorer/users/views/list/bulk-update-ledger.modal';

import { CellUserDefinition } from '../../hooks/use-cell-definition';
import { User } from '../../users';

import { BulkTransferFundModal } from './bulk-transfer-fund.modal';
import { BulkUpdateBadgesModal } from './bulk-update-badges.modal';
import { BulkUpdateFeeModal } from './bulk-update-fee.modal';

const TYPESENSE_MAX_RESULT_PER_PAGE = 30;

export const ListUsers = () => {
  const { hasPermissions } = useOwnUser();
  const ownUserCanSelectRows = hasPermissions(
    PERMISSIONS.OWN_USER_INTERFACE.READ_OWN_USER_INTERFACE_SELECT_MULTIPLE_LINES,
  );

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListWithSearch<User & { id: string }>
        title="Users"
        cellDefinition={CellUserDefinition}
        typesenseHookParams={{
          typesenseIndex: 'user',
          typesenseParams: {
            per_page: TYPESENSE_MAX_RESULT_PER_PAGE,
            query_by: [
              'username',
              'email',
              'firstName',
              'lastName',
              'stripeCustomerId',
              'phoneNumber',
              'stripeAccountId',
            ],
            max_candidates: 50,
            num_typos: 1,
            prefix: true,
          },
        }}
        customAction={
          <div className="flex w-full lg:w-fit lg:flex-row flex-row gap-2">
            <BulkUpdateFeeModal />
            <BulkUpdateBadgesModal />
            <BulkTransferFundModal />
            <BulkUpdateLedgerModal />
          </div>
        }
        dataListProps={{ enableRowSelection: ownUserCanSelectRows }}
        isUserList
      />
    </div>
  );
};
