import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '@Elements/buttons/ButtonContained';

import { User } from '../../../users';

type ConfirmUnblockUserModalProps = {
  username: User['username'];
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
};

export const ConfirmUnblockUserModal = ({
  username,
  onConfirm,
  onCancel,
  isOpen,
}: ConfirmUnblockUserModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen}>
      <DialogTitle id="alert-dialog-title">{t('databaseExplorer.users.unblock.title')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('databaseExplorer.users.unblock.description', { username })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <ButtonContained onClick={onConfirm}>
          {t('databaseExplorer.users.unblock.confirm')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
