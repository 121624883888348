import { forwardRef, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { IconButton, ListItemIcon } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
const MENU_ID = 'edit-order-items-menu';

type EditMenuOrderItemsProps = {
  onUpdateShippingStatusClick: () => void;
  onUpdateTrackingNumberClick: () => void;
};

export const EditMenuOrderItems = forwardRef<HTMLButtonElement, EditMenuOrderItemsProps>(
  ({ onUpdateShippingStatusClick, onUpdateTrackingNumberClick }, ref) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          size="small"
          id={MENU_ID}
          ref={ref}
        >
          <EditIcon />
        </IconButton>

        <Menu
          id={MENU_ID}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': MENU_ID,
          }}
        >
          <MenuItem onClick={onUpdateShippingStatusClick}>
            <ListItemIcon>
              <LocalShippingIcon />
            </ListItemIcon>

            {t('orderItemLabels.shippingStatus')}
          </MenuItem>
          <MenuItem onClick={onUpdateTrackingNumberClick}>
            <ListItemIcon>
              <GpsFixedIcon />
            </ListItemIcon>
            {t('orderItemLabels.trackingNumber')}
          </MenuItem>
        </Menu>
      </div>
    );
  },
);
