import { Box, Button, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useTranslation } from 'react-i18next';

import { OwnUser } from '@/domain/entities/own-user.entity';

import { useManageOwnUserPermissions } from './use-manage-permission';
import { getPermissions } from './utils';

type ManageOwnUserPermissionsProps = {
  ownUser: OwnUser | null;
  onClose: () => void;
};

export const ManageOwnUserPermissions = ({ ownUser, onClose }: ManageOwnUserPermissionsProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  const { columns, formState, handleClose, onSubmit, isLoading } = useManageOwnUserPermissions(
    ownUser,
    onClose,
  );

  return (
    <Drawer anchor="right" open>
      <Box
        maxWidth={fullScreen ? '100vw' : 'auto'}
        p={2}
        display="flex"
        flexDirection="column"
        height="100vh"
        gap={2}
      >
        <Typography variant="h6">
          {t('administration.managePermissions.title', { username: ownUser?.fullName })}
        </Typography>

        <MaterialReactTable
          columns={columns}
          data={getPermissions(ownUser?.permissions)}
          enableGrouping
          enableStickyHeader
          enableGlobalFilter
          enableTableFooter={false}
          enablePagination={false}
          enableBottomToolbar={false}
          enableDensityToggle={false}
          enableColumnFilters={false}
          enableFullScreenToggle={false}
          enableHiding={false}
          initialState={{
            density: 'compact',
            expanded: true,
            grouping: ['group'],
            showGlobalFilter: true,
          }}
          muiTableContainerProps={{
            sx: { flex: 1 },
          }}
          muiTablePaperProps={{
            sx: {
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              backgroundColor: ({ palette }) => palette.background.paper,
            },
            elevation: 0,
          }}
          positionGlobalFilter="left"
          muiSearchTextFieldProps={{
            variant: 'outlined',
            size: 'small',
          }}
          muiToolbarAlertBannerProps={{
            sx: {
              display: 'none',
            },
          }}
          muiExpandButtonProps={({ row }) => {
            if (row.depth === 1) {
              return {
                sx: {
                  display: 'none',
                },
              };
            }

            return {};
          }}
        />

        <Box display="flex" justifyContent="space-between" gap={2}>
          <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
            {t('cancel')}
          </Button>

          <Button variant="contained" disabled={!formState.isDirty || isLoading} onClick={onSubmit}>
            {t('action.save')}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
