import { authenticatedInstance } from '@/axios/axios.instance';
import { DraftBadge } from '@/voggt-database-explorer/badge/entity/draft-badge';

export const getDraftBadge = async (draftBadgeId: DraftBadge['id']) => {
  const response = await authenticatedInstance.get(
    `/database-explorer/badge/draft/${draftBadgeId}`,
  );

  return response.data;
};
