import { formatText } from '@/utils';
import {
  FeaturedShowApplicationDecisionReason,
  FeaturedShowConcept,
} from '@bits-app/bits-server-data';

const POSITIVE_DECISION = [
  FeaturedShowApplicationDecisionReason.great_items,
  FeaturedShowApplicationDecisionReason.great_track_record,
  FeaturedShowApplicationDecisionReason.loyal_seller,
];

const NEGATIVE_DECISION = [
  FeaturedShowApplicationDecisionReason.full_calendar,
  FeaturedShowApplicationDecisionReason.not_enough_items,
  FeaturedShowApplicationDecisionReason.not_experienced_enough,
  FeaturedShowApplicationDecisionReason.not_qualitative_enough,
];

export const keepOnlyValidReasonBasedOnDecision = (decision: 'approved' | 'refused') => {
  return ([key]: [key: string, value: string]) => {
    if (!decision) {
      return false;
    }
    if (decision === 'approved') {
      return POSITIVE_DECISION.includes(key as FeaturedShowApplicationDecisionReason);
    }
    return NEGATIVE_DECISION.includes(key as FeaturedShowApplicationDecisionReason);
  };
};

export function mapFeaturedShowConceptsToDisplay(concepts: FeaturedShowConcept[]) {
  return concepts
    .map((concept) => {
      return formatText(concept.name);
    })
    .join(', ');
}
