import { Checkbox, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

type EnumFilterProps<T> = {
  availableOptions: { value: T; label?: string }[];
  checked: T[];
  onToggle: (value: T) => () => void;
  onToggleAll: () => () => void;
};

export const EnumFilter = <T extends string>({
  availableOptions,
  checked,
  onToggle,
  onToggleAll,
}: EnumFilterProps<T>) => {
  const { t } = useTranslation();

  return (
    <List>
      <ListItemButton onClick={onToggleAll()} dense>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked.length === availableOptions.length}
            indeterminate={checked.length > 0 && checked.length < availableOptions.length}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={`[${t('selectAll')}]`} />
      </ListItemButton>

      {availableOptions.map((option) => (
        <ListItemButton key={option.value} onClick={onToggle(option.value)} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(option.value) !== -1}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={option.label ?? option.value} />
        </ListItemButton>
      ))}
    </List>
  );
};
