import { useMemo } from 'react';

import { Country } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

export const useCellDefinition = () => {
  const CellCountryDefinition = useMemo(
    (): CellDefinition<Country>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (country) => ({ link: `/country/${country.id}`, label: country?.id }),
      },
      {
        id: 'name',
        label: 'name',
      },
      {
        id: 'iso2Code',
        label: 'ISO 2 Code',
      },
      {
        id: 'iso3Code',
        label: 'ISO 3 Code',
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  return { CellCountryDefinition };
};
