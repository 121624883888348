import { Category, ParentCategory } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useRelatedCategoriesList = (parentCategoryId: ParentCategory['id']) => {
  const getParentCategories = async (parentCategoryId: ParentCategory['id']) => {
    const response = await authenticatedInstance.get(
      `/database-explorer/category/parent/${parentCategoryId}`,
    );

    return response.data;
  };

  const { data: relatedCategories } = useQuery<Category[]>(
    QUERIES_KEYS.relatedCategoriesList(Number(parentCategoryId)),
    () => getParentCategories(Number(parentCategoryId)),
  );

  return {
    relatedCategories,
  };
};
