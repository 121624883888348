import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { User } from './User';

export enum IssuerName {
  apple = 'apple',
  facebook = 'facebook',
  google = 'google',
}

@Entity()
@Index(['issuer', 'subject'], { unique: true })
export class UserSso {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @OneToOne(() => User, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Column()
  userId!: number;

  @Column()
  issuer!: string;

  @Column({ type: 'enum', enum: IssuerName })
  issuerName!: IssuerName;

  @Column()
  subject!: string;

  @Column({ type: 'character varying', nullable: true })
  email!: string | null;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;
}
