import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/redux/store';

const partnerProgramSelector = (state: RootState) => state.partnerProgram;

export const selectPartnerPrograms = createSelector(
  partnerProgramSelector,
  (state) => state.partnerPrograms,
);
export const selectPartnerProgramFromId = createSelector(
  [selectPartnerPrograms, (_, partnerProgramId: string | -1 | null) => partnerProgramId],
  (partnerPrograms, partnerProgramId) => {
    if (!partnerProgramId) {
      return null;
    }

    return partnerPrograms.find(({ id }) => partnerProgramId === id);
  },
);

export const selectPartnerProgramsLoading = createSelector(
  partnerProgramSelector,
  (state) => state.isLoading,
);

export const selectPartnerProgramsError = createSelector(
  partnerProgramSelector,
  (state) => state.error,
);

export const selectPartnerProgramsCurrent = createSelector(
  partnerProgramSelector,
  (state) => state.partnerProgramSelected,
);
