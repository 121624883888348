import { AxiosError } from 'axios';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { AppThunkAction } from '@/redux/store';

import { selectRefundDetails } from '../redux/refund.selectors';
import { refundActions } from '../redux/refund.slice';

import { clearRefundOrderItem } from './clear-refund-order-item';

export const validateRefundOrderItem =
  (sendEmailToBuyer: boolean, sendEmailToSeller: boolean): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { refundGateway, snackbarGateway }) => {
    dispatch(refundActions.setRefundError(null));
    dispatch(refundActions.setRefundFormLoading(true));

    try {
      const refundDetails = selectRefundDetails(getState());

      await refundGateway.saveRefund({
        ...refundDetails,
        sendEmails: {
          toBuyer: sendEmailToBuyer,
          toSeller: sendEmailToSeller,
        },
      });

      dispatch(clearRefundOrderItem());

      snackbarGateway.success('refund.validated');
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      if (error instanceof AxiosError) {
        if (error.response?.data?.message) {
          snackbarGateway.error('refund.error', { subText: error.response?.data.message });

          return;
        }
      }

      snackbarGateway.error('refund.error');
    } finally {
      dispatch(refundActions.setRefundFormLoading(false));
    }
  };
