import type { Language } from '@bits-app/bits-server-data';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { SoftDeletedBanner } from '@/components/elements/banner/SoftDeletedBanner';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParam } from '@/hooks/use-id-params';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';
import { ItemLayout } from '@/voggt-database-explorer/layouts/ItemLayout';

import { useLanguageDetailsView } from './useLanguageDetailsView';

export const LanguageDetail = () => {
  const languageId = useIdParam();

  const { t } = useTranslation();

  const tabs = [
    {
      path: '/',
      label: t('databaseExplorer.paymentmethod.tabs.main'),
      Element: <LanguageDetailComponent key="/" />,
    },
  ];

  if (!languageId) {
    return null;
  }

  return (
    <ItemLayout<Language>
      id={languageId}
      tabs={tabs}
      currentTab={'/'}
      onTabChange={() => undefined}
    />
  );
};

const LanguageDetailComponent = () => {
  const languageId = useIdParam();

  const { language, loading, panelDetailsLanguage } = useLanguageDetailsView(languageId);
  const { t } = useTranslation();
  return (
    <DetailsLayout>
      {language?.deletedAt && <SoftDeletedBanner />}
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'}>
          <Stack spacing={4} direction="row" ml={'auto'} pr={1} pl={1}>
            <ForestAdminLink entity="language" entityId={languageId} />
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12} md={12}>
            <PanelDetails<Language>
              title={t('databaseExplorer.panel.language')}
              informations={panelDetailsLanguage}
              data={language}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </DetailsLayout>
  );
};
