import { useEffect, useState } from 'react';

import { Check, Close } from '@mui/icons-material';
import { IconButton, Slider } from '@mui/material';
import { Stack } from '@mui/system';
import { MRT_Header } from 'material-react-table';
import { useDebounce } from 'use-debounce';

type SwitchFilterMRTProps<T extends Record<string, unknown>> = {
  header: MRT_Header<T>;
  activeFilters?: ('true' | 'false')[];
};

export const SwitchFilterMRT = <T extends Record<string, unknown>>({
  header,
  activeFilters,
}: SwitchFilterMRTProps<T>) => {
  const [inputValue, setInputValue] = useState(defaultValues(activeFilters));
  const [debouncedInputValue] = useDebounce(inputValue, 200);

  useEffect(() => {
    const value = {
      0: [false],
      1: undefined,
      2: [true],
    }[debouncedInputValue];

    header.column.setFilterValue(value);
  }, [debouncedInputValue, header.column]);

  useEffect(() => {
    if (!activeFilters && inputValue) {
      setInputValue(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        maxWidth: header.column.columnDef.size,
      }}
    >
      <IconButton size="small" onClick={() => setInputValue(0)}>
        <Close fontSize="small" color="error" />
      </IconButton>

      <Slider
        value={inputValue}
        onChange={(_, value) => setInputValue(Array.isArray(value) ? value[0] : value)}
        track={false}
        min={0}
        max={2}
        marks={[{ value: 0 }, { value: 1 }, { value: 2 }]}
        size="small"
      />

      <IconButton size="small" onClick={() => setInputValue(2)}>
        <Check fontSize="small" color="success" />
      </IconButton>
    </Stack>
  );
};

const defaultValues = (filters?: ('true' | 'false')[]) => {
  if (!filters) {
    return 1;
  }

  if (filters.every((value) => value === 'true')) {
    return 2;
  }

  return 0;
};
