import { ActiveAutoFollowUserCampaigns } from '@bits-app/voggtpit-shared';

import { CellDefinition } from '@/components/elements/DataList';

export type Node =
  ActiveAutoFollowUserCampaigns['activeAutoFollowUserCampaigns']['edges'][number]['node'];

export const activeCells: CellDefinition<Node>[] = [
  {
    //@ts-expect-error improperly typed CellDefinition
    id: 'categoryName',
    label: 'Category',
    type: 'internal-link',
    getLink: (node) => ({
      label: node.category.name,
      link: `/category/child/${node.category.legacyId}`,
    }),
  },
  {
    //@ts-expect-error improperly typed CellDefinition
    id: 'countryName',
    label: 'Country',
    type: 'internal-link',
    getLink: (node) => ({
      label: node.country.iso2Code,
      link: `/country/${node.country.iso2Code}`, // id would be more appropriate but graphql does not expose it
    }),
  },
  {
    //@ts-expect-error improperly typed CellDefinition
    id: 'username',
    label: 'Username',
    type: 'internal-link',
    getLink: (node) => ({ label: node.user.username, link: `/user/${node.user.legacyId}` }),
  },
  {
    id: 'targetFollowerCount',
    label: 'Target',
    getValue: (node) => node.targetFollowerCount.toString(),
  },
  {
    id: 'id',
    label: 'Progress to target',
    type: 'component',
    getValue: (node) => {
      const progress = Math.round(
        ((node.user.followers.totalCount - node.createdAtFollowerCount) /
          (node.targetFollowerCount - node.createdAtFollowerCount)) *
          100,
      );

      const width = `${progress}%`;

      const title = `Started at ${node.createdAtFollowerCount} followers. Progress: ${width} = ${node.user.followers.totalCount} followers / ${node.targetFollowerCount}`;

      return (
        <div className="flex flex-1 border h-4 rounded-lg overflow-clip" title={title}>
          <div className="bg-green-400" style={{ width }}></div>
        </div>
      );
    },
  },
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date-with-time',
  },
  {
    id: 'deletedAt',
    label: 'Deleted at',
    type: 'date-with-time',
  },
];
