import { OperatorFilterValue, SellerBuyerFilterValue } from '@bits-app/voggtpit-shared';

export type SetFilterValue = string[];

export const isSetFilter = (
  filter?: SetFilterValue | OperatorFilterValue<string | number> | string | SellerBuyerFilterValue,
): filter is SetFilterValue | undefined => !filter || Array.isArray(filter);

export const isStringFilter = (
  filter?: SetFilterValue | OperatorFilterValue<string | number> | string | SellerBuyerFilterValue,
): filter is string => typeof filter === 'string';

export const isOperatorFilter = <T extends string | number>(
  filter?: SetFilterValue | OperatorFilterValue<string | number> | string | SellerBuyerFilterValue,
): filter is OperatorFilterValue<T> | undefined =>
  typeof filter === 'object' && Object.hasOwn(filter, 'operator');

export const isSellerBuyerFilter = (
  filter?: SellerBuyerFilterValue | SetFilterValue | OperatorFilterValue<string | number> | string,
): filter is SellerBuyerFilterValue =>
  typeof filter === 'object' && Object.hasOwn(filter, 'seller') && Object.hasOwn(filter, 'buyer');
