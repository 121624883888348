import { Show, Product } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';

import { authenticatedInstance } from '../../axios/axios.instance';

export type FeedRecommandationForm = {
  recommandationType: 'show-user' | 'show-device' | 'product-user' | 'product-device';
  identifier?: string;
  eventId?: string;
  categoryIds?: string[];
  isOnlyFromLiveShows?: boolean;
  sortByPrice?: 'ASC' | 'DESC';
  refresh: boolean;
};

export type RecommandationResponse = {
  shows?: Show[];
  products?: Product[];
  paginationMetadata: { totalCount: number; hasNextPage: boolean };
};

type QueryKey = [
  string,
  FeedRecommandationForm['recommandationType'],
  FeedRecommandationForm['identifier'],
  FeedRecommandationForm['eventId'],
  FeedRecommandationForm['categoryIds'],
  FeedRecommandationForm['refresh'],
];

export const useFeedRecommandation = () => {
  const { register, watch, control } = useForm<FeedRecommandationForm>({
    defaultValues: {
      identifier: undefined,
      recommandationType: 'show-user',
      eventId: undefined,
      isOnlyFromLiveShows: undefined,
      sortByPrice: undefined,
      categoryIds: [],
      refresh: false,
    },
  });

  const { refetch, data } = useQuery<
    unknown,
    unknown,
    AxiosResponse<{
      ids: number[];
      paginationMetadata: { totalCount: number; hasNextPage: boolean };
    }>,
    QueryKey
  >(
    [
      'recommandation',
      watch('recommandationType'),
      watch('identifier'),
      watch('eventId'),
      watch('categoryIds'),
      watch('refresh'),
    ],
    {
      queryFn: ({ queryKey }) => {
        const [, recommandationType, identifier, eventId, categoryIds, refresh] = queryKey;
        return getRecommandation({ recommandationType, identifier, eventId, categoryIds, refresh });
      },
      enabled: false,
    },
  );

  return {
    register,
    refetch,
    control,
    watch,
    data,
    disableSubmit: !watch('identifier'),
  };
};

function resolveUrl(recommandationType: FeedRecommandationForm['recommandationType']) {
  switch (recommandationType) {
    case 'show-user':
      return '/data-recommandation/show/user';
    case 'show-device':
      return '/data-recommandation/show/device';
    case 'product-user':
      return '/data-recommandation/product/user';
    case 'product-device':
      return '/data-recommandation/product/device';
  }
}

function getRecommandation(params: FeedRecommandationForm) {
  const url = resolveUrl(params.recommandationType);
  const uri = authenticatedInstance.getUri({ url, params });
  return authenticatedInstance.get<unknown, RecommandationResponse>(
    `${url}/${params.identifier}?${uri}`,
  );
}
