import { useState } from 'react';

import { Badge } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useOwnUser } from '@/context/own-user.context';
import { BadgeTranslations } from '@/voggt-database-explorer/badge/components/badge-translations';
import { UpsertBadgeForm } from '@/voggt-database-explorer/badge/components/upsert-badge/UpsertBadgeForm';
import { DraftBadge } from '@/voggt-database-explorer/badge/entity/draft-badge';

type UpsertBadgeModalProps = {
  badges: (Badge | DraftBadge)[];
  badge?: Badge | DraftBadge;
  onClose: () => void;
  isDraft?: boolean;
};

export const UpsertBadgeModal = ({ badges, onClose, badge, isDraft }: UpsertBadgeModalProps) => {
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();

  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<Set<number>>(new Set());
  const [upsertedBadge, setUpsertedBadge] = useState<DraftBadge | Badge | null>(badge ?? null);

  const ownUserCanAddTranslations = hasPermissions(
    PERMISSIONS.BADGE.WRITE_BADGE_UPDATE_TRANSLATIONS,
  );

  const completeStep = (step: 0 | 1) => {
    setCompletedSteps((steps) => steps.add(step));
    setActiveStep(step + 1);
  };

  const afterBadgeUpsertSuccess = (badge: Badge | DraftBadge) => {
    setUpsertedBadge(badge);
    completeStep(0);

    if (!ownUserCanAddTranslations) {
      onClose();
    }
  };

  return (
    <Dialog open>
      <Grid container justifyContent="space-between" alignItems="center" p={3} gap={2}>
        <Grid item flex={1}>
          <Stepper activeStep={activeStep}>
            <Step completed={completedSteps.has(0)}>
              <StepButton disabled={!badge} onClick={() => setActiveStep(0)}>
                <StepLabel>
                  {t(`databaseExplorer.badge.${badge ? 'update' : 'create'}.form`)}
                </StepLabel>
              </StepButton>
            </Step>

            {ownUserCanAddTranslations && (
              <Step completed={completedSteps.has(1)}>
                <StepButton disabled={!badge} onClick={() => setActiveStep(1)}>
                  <StepLabel>{t('databaseExplorer.badge.upsert.translate')}</StepLabel>
                </StepButton>
              </Step>
            )}
          </Stepper>
        </Grid>

        <Grid item>
          <IconButton size="small" color="inherit" onClick={onClose}>
            <CloseIcon color="inherit" />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent
        sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%', gap: 4 }}
      >
        {activeStep === 0 ? (
          <UpsertBadgeForm
            isDraft={isDraft}
            badge={badge}
            badges={badges}
            afterSuccess={afterBadgeUpsertSuccess}
          />
        ) : null}

        {activeStep === 1 && upsertedBadge ? (
          <BadgeTranslations badge={upsertedBadge} afterSuccess={onClose} />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
