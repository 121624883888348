import { Box, Checkbox, TableRow, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUnsavedWarning } from '@/context/unsaved.context';
import { OrderItem } from '@/entities/order-item.entity';
import { LoadingRow } from '@Elements/LoadingRow';
import {
  TableCellBodyNoBorderBottom,
  TableCellHeaderBorderBottom,
} from '@Elements/StyledTableCell';

import { OrderItemsMassUpdateStickyRow } from './OrderItemsMassUpdateStickyRow';
import { HeadCell } from './hooks/use-header';

type StickyHeaderProps = {
  headCells: HeadCell[];
  areAllSelected: boolean;
  selectedCount: number;
  order: 'asc' | 'desc';
  orderBy: string;
  onToggleAll: () => void;
  onOrderBy: (field: string) => void;
  isUnsaved: boolean;
  loading: boolean;
  disabledSelectRow: boolean;
  isReadOnly: boolean;
};

export const StickyHeader = ({
  headCells,
  areAllSelected,
  selectedCount,
  onToggleAll,
  order,
  orderBy,
  onOrderBy,
  isUnsaved,
  loading,
  disabledSelectRow,
  isReadOnly,
}: StickyHeaderProps) => {
  const onConfirmAfterWarning = useUnsavedWarning();

  const handleOnSort = (id: string) => {
    if (isUnsaved) {
      onConfirmAfterWarning(() => onOrderBy(id));
      return;
    }

    onOrderBy(id);
  };

  return (
    <TableRow>
      {!isReadOnly && (
        <TableCellHeaderBorderBottom padding="checkbox">
          <Checkbox
            checked={areAllSelected}
            indeterminate={!areAllSelected && selectedCount > 0}
            onChange={onToggleAll}
            disabled={loading || disabledSelectRow}
          />
        </TableCellHeaderBorderBottom>
      )}
      {headCells.map((headCell) => (
        <TableCellHeaderBorderBottom
          key={`sticky-${headCell.id}`}
          minWidth={`calc(${headCell.minWidth}px + ${headCell.sortable ? '20px' : '0px'})`}
          sx={{
            fontWeight: 'bold',
            height: 50,
          }}
        >
          {!headCell.sortable ? (
            <Box component="span" mr={1}>
              {headCell.label}
            </Box>
          ) : (
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => handleOnSort(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          )}

          {headCell.filter ?? null}
        </TableCellHeaderBorderBottom>
      ))}
    </TableRow>
  );
};

type StickyBodyProps = {
  headCells: HeadCell[];
  showEmptyData: boolean;
  loading: boolean;
  orderItems: OrderItem[];
  selected: number[];
  handleToggleItem: (id: number) => void;
  disabledSelectRow: boolean;
  isReadOnly: boolean;
};

export const StickyBody = ({
  headCells,
  loading,
  selected,
  orderItems,
  showEmptyData,
  disabledSelectRow,
  isReadOnly,
  handleToggleItem,
}: StickyBodyProps) => {
  const { t } = useTranslation();

  return (
    <>
      {showEmptyData && (
        <TableRow>
          <TableCellBodyNoBorderBottom colSpan={headCells.length + 1} sx={{ fontStyle: 'italic' }}>
            {t('orderItemsMassUpdate.noData')}...
          </TableCellBodyNoBorderBottom>
        </TableRow>
      )}

      {loading ? (
        <LoadingRow emptyFirstCell cellCount={headCells.length + 1} />
      ) : (
        orderItems.map((orderItem) => (
          <OrderItemsMassUpdateStickyRow
            key={`sticky-${orderItem.id}`}
            orderItem={orderItem}
            checked={selected.includes(orderItem.id)}
            onToggleItem={() => handleToggleItem(orderItem.id)}
            disabledSelectRow={disabledSelectRow}
            isReadOnly={isReadOnly}
          />
        ))
      )}
    </>
  );
};
