import * as yup from 'yup';

export abstract class SchemaValidation {
  abstract availableResolver: Record<string, yup.ISchema<any>>;

  resolveFields(fields: string[]) {
    return fields.reduce((schema, field) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!this.availableResolver.hasOwnProperty(field)) {
        return schema;
      }

      const newSchema = yup.object({ [field]: this.availableResolver[field] });

      return schema.concat(newSchema);
    }, yup.object({}));
  }
}
