import { SellerCompany, User } from '@bits-app/bits-server-data';
import { Schema } from '@bits-app/voggtpit-shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { EntityWrapper } from '@/voggt-database-explorer/redux/database-explorer';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export const useCreateSellerCompany = (userId: User['id']) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { data: schemas = [], isLoading } = useGetSchemaSellerCompany();

  const { mutate } = useMutation({
    mutationFn: (sellerCompany: Partial<SellerCompany>) =>
      authenticatedInstance.post(`/database-explorer/seller-company/${userId}`, sellerCompany),
    onSuccess: ({ data: sellerCompanyCreated }) => {
      snackbar.success(
        t('databaseExplorer.sellerCompany.success', { id: sellerCompanyCreated.id }),
      );
      queryClient.invalidateQueries(QUERIES_KEYS.userSellerCompanyDetail(userId));
      navigate(`/user/${userId}/seller-config`);
    },
    onError: (error: AxiosError) => {
      console.log(error.message);
      const formErrors = JSON.parse(error.message);
      console.log('Message', formErrors);
      if (Array.isArray(formErrors)) {
        snackbar.error('Something wrong happenned', {
          subText: formErrors,
        });
      }
    },
  });

  const schemasWithTraduction = schemas.map((schema) => ({
    ...schema,
    categoryLabel: t('infos'),
  }));

  return {
    header: {
      title: 'Create a seller company',
    },
    schemas: schemasWithTraduction,
    onCreate: mutate,
    isLoading,
  };
};

const useGetSchemaSellerCompany = () => {
  return useQuery(QUERIES_KEYS.sellerCompanyMutationSchema(), async () => {
    const { data } = await authenticatedInstance.get<Schema<EntityWrapper>[]>(
      '/database-explorer/seller-company/mutation-schema',
    );
    return data;
  });
};
