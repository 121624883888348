export enum ShippingCategory {
  colissimo = 'colissimo',
  fashion_large_package = 'fashion_large_package',
  fashion_large_package_incremental = 'fashion_large_package_incremental',
  fashion_medium_package = 'fashion_medium_package',
  fashion_medium_package_incremental = 'fashion_medium_package_incremental',
  fashion_small_package = 'fashion_small_package',
  fashion_small_package_incremental = 'fashion_small_package_incremental',
  free = 'free',
  large_letter = 'large_letter',
  large_letter_plus = 'large_letter_plus',
  letter = 'letter',
  letter_incremental = 'letter_incremental',
  letter_plus = 'letter_plus',
  letter_registered = 'letter_registered',
  letter_registered_incremental = 'letter_registered_incremental',
  mondial_relay = 'mondial_relay',
  package = 'package',
  package_incremental = 'package_incremental',
  package_plus = 'package_plus',
  sneakers_affordable = 'sneakers_affordable',
  sneakers_premium = 'sneakers_premium',
  sneakers_standard = 'sneakers_standard',
  ups = 'ups',
}
