import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { TypesenseClientInformation } from '@bits-app/voggtpit-shared';
import { Client } from 'typesense';

import { authenticatedInstance } from '@/axios/axios.instance';

import { useOwnUser } from './own-user.context';

const TypesenseContext = createContext<{
  typesenseClient: Client | null;
}>({ typesenseClient: null });

export const useTypesenseContext = () => useContext(TypesenseContext);

export const TypesenseContextProvider = ({ children }: { children: React.ReactElement }) => {
  const [tokenData, setTokenData] = useState<TypesenseClientInformation | null>(null);
  const [typesenseClient, setTypesenseClient] = useState<Client | null>(null);
  const { ownUser } = useOwnUser();

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    if (ownUser) {
      getToken(signal).then(handleBackendResponse);
    }
    return () => {
      abortController.abort();
    };
  }, [ownUser]);

  useEffect(() => {
    if (tokenData) {
      const newClient = initClient(tokenData);
      setTypesenseClient(newClient);
    }
  }, [tokenData]);

  function handleBackendResponse(potentialToken: TypesenseClientInformation | null) {
    if (potentialToken?.expiresAt && potentialToken.key) {
      setTokenData(potentialToken);
    }
  }

  const contextValue = useMemo(() => ({ typesenseClient }), [typesenseClient]);

  return <TypesenseContext.Provider value={contextValue}>{children}</TypesenseContext.Provider>;
};

function initClient(typesenseClientInformations: TypesenseClientInformation) {
  const useLocalTypesense = process.env.REACT_APP_USE_LOCAL_TYPESENSE === 'true';
  const typesensePort = useLocalTypesense ? 3050 : 443;
  const typesenseProtocol = useLocalTypesense ? 'http' : 'https';
  return new Client({
    nodes: [
      {
        host: typesenseClientInformations.endpoint,
        port: typesensePort,
        protocol: typesenseProtocol,
      },
    ],
    apiKey: typesenseClientInformations.key,
    connectionTimeoutSeconds: 5000,
    numRetries: 3,
    retryIntervalSeconds: 0.1,
    healthcheckIntervalSeconds: 2,
    logLevel: 'silent',
    additionalHeaders: {
      'CF-Access-Client-Id': typesenseClientInformations.CFAccessClientId,
      'CF-Access-Client-Secret': typesenseClientInformations.CFAccessClientSecret,
    },
  });
}

async function getToken(_signal: AbortSignal): Promise<TypesenseClientInformation> {
  const { data } = await authenticatedInstance.get<TypesenseClientInformation>('typesense');
  return data;
}
