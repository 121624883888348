import { useMemo } from 'react';

import { Category } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { CellDefinition } from '@/components/elements/DataList';
import { Information } from '@/components/layouts/PanelDetails';
import { QUERIES_KEYS } from '@/queries';

import { Emote } from '../../entity/emote.entity';

export const useEmoteDetailsView = (emoteId: string) => {
  const { data: selectedEmote, isLoading } = useQuery<Emote, string>({
    queryKey: QUERIES_KEYS.emote(Number(emoteId)),
    queryFn: () => getEmote(Number(emoteId)),
  });

  const CellCategoryDefinition = useMemo(
    (): CellDefinition<Category>[] => [
      {
        id: 'id',
        label: 'id',
        type: 'internal-link',
        getLink: (category) => ({ label: category.id, link: `/category/child/${category.id}` }),
      },
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  return {
    emote: selectedEmote,
    loading: isLoading,
    CellCategoryDefinition,
  };
};

const getEmote = async (emoteId: Emote['id']): Promise<Emote> => {
  try {
    const path = `/database-explorer/emote/${emoteId}`;
    const result = await authenticatedInstance.get<Emote>(path);
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError<{ message?: string }>) {
      throw error.response?.data.message;
    }

    throw String(error);
  }
};

export const panelDetailsEmote: Information<Emote>[] = [
  { value: 'id', label: 'id' },
  {
    value: 'image',
    label: 'Image',
    type: 'image',
  },
  { value: 'name', label: 'Name' },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'isGlobal', label: 'Is global', type: 'boolean' },
];
