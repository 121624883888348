import { useCallback } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';

import { authenticatedInstance } from '../../../../../axios/axios.instance';
import { useSnackbar } from '../../../../../components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '../../../../../queries';
import { useUserDetail } from '../../../hooks/useUserDetail';
import { User } from '../../../users';

export const useHardBlockUser = (userId: User['id']) => {
  const { user, isLoading } = useUserDetail(userId);

  const { hasPermissions } = useOwnUser();

  const navigate = useNavigate();

  const goToModal = useCallback(
    () => navigate(`/user/${userId}/hard-block-user`),
    [navigate, userId],
  );

  const goToUserProfile = useCallback(() => navigate(`/user/${userId}`), [navigate, userId]);

  const snackbar = useSnackbar();

  const { mutate: hardBlockUser } = useMutation({
    mutationFn: () =>
      authenticatedInstance.put<boolean>(`database-explorer/customer/${userId}/hard-block-user`),
    onSuccess: (response) => {
      if (response.data) {
        queryClient.invalidateQueries(QUERIES_KEYS.userDetail(userId));
        snackbar.success('databaseExplorer.users.hasBeenBlocked');
      } else {
        snackbar.error('somethingWrongHappened');
      }
      goToUserProfile();
    },
    onError: () => {
      snackbar.error('somethingWrongHappened');
    },
  });

  const shouldDisplayHardBlockFunctionality =
    !user?.isHardBlocked && hasPermissions(PERMISSIONS.USER.WRITE_USER_HARD_BLOCK);

  return {
    hardBlockUser,
    shouldDisplayHardBlockFunctionality,
    goToModal,
    isLoading,
  };
};
