import { centsToUnit, Promotion, formatAmountWithCurrency } from '@bits-app/voggtpit-shared';
import { Box, TableRow } from '@mui/material';
import { isAfter } from 'date-fns';

import { ChipsCell } from '@/components/elements/DataList/Cell/ChipsCell';
import { TableCellBodyNoBorderBottom } from '@/components/elements/StyledTableCell';
import { selectPromotionIsExpired } from '@/promotions/redux/promotions-selector';
import { PromotionsMenu } from '@/promotions/ui/table/PromotionsMenu';
import { useAppSelector } from '@/redux/reduxAppHooks';
import { formatDate, formatPercentage } from '@/utils';

interface PromotionsRowProps {
  promotion: Promotion;
  isReadOnly: boolean;
  handleExpirePromotion(): void;
  handleEditPromotion(promotionId: Promotion['id']): void;
  handleManageUser(promotionId: Promotion['id']): void;
}

export const PromotionRow = ({
  promotion,
  isReadOnly,
  handleExpirePromotion,
  handleEditPromotion,
  handleManageUser,
}: PromotionsRowProps) => {
  const isExpired = useAppSelector((state) => selectPromotionIsExpired(state, promotion.id));

  const handleClickOnTableRow = (event: React.MouseEvent<HTMLElement>) => {
    const promotionIsExpired = isAfter(new Date(), new Date(promotion.expireAt));
    if (promotionIsExpired) return;
    event.stopPropagation();

    if (isReadOnly) {
      return;
    }

    handleEditPromotion(promotion.id);
  };

  return (
    <TableRow hover onClick={handleClickOnTableRow} sx={{ cursor: 'pointer' }}>
      {!isReadOnly && (
        <TableCellBodyNoBorderBottom minWidth={70} align="center">
          {!isExpired && (
            <PromotionsMenu
              handleManageUser={() => handleManageUser(promotion.id)}
              handleExpirePromotion={handleExpirePromotion}
            />
          )}
        </TableCellBodyNoBorderBottom>
      )}
      <TableCellBodyNoBorderBottom minWidth={70} align="center">
        {promotion.id}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        {promotion.name}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ChipsCell value={promotion.type} />
        </Box>
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        {promotion.countryId}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        {promotion.promotionAmount != null
          ? formatAmountWithCurrency(centsToUnit(promotion.promotionAmount), promotion.currency)
          : null}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        {promotion.promotionPercentage != null
          ? formatPercentage(promotion.promotionPercentage)
          : null}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        {promotion.promotionMaxAmount != null
          ? formatAmountWithCurrency(centsToUnit(promotion.promotionMaxAmount), promotion.currency)
          : null}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        {formatDate(promotion.expireAt)}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        {promotion.spentBudget != null
          ? formatAmountWithCurrency(centsToUnit(promotion.spentBudget), promotion.currency)
          : null}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        {promotion.totalBudget != null
          ? formatAmountWithCurrency(centsToUnit(promotion.totalBudget), promotion.currency)
          : null}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        {promotion.countUsed} / {promotion.countUsers}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={160} align="center">
        {formatDate(promotion.updatedAt)}
      </TableCellBodyNoBorderBottom>
    </TableRow>
  );
};
