import { useMemo } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useLocation } from 'react-router-dom';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { ItemLayout } from '@/voggt-database-explorer/layouts/ItemLayout';

import { ListWithExport } from '../../../components/ListWithExport';
import { ShipmentCellDefinition } from '../../../shipment/shipment.const';
import { ShipmentsWithTotalAmounts } from '../../../shipment/utils/utils';
import { OrderFlat } from '../../shipping-address.type';

import { ShippingDetails } from './Details';
import { CellOrderDefinition, useDetailsView } from './useDetailsView';

export const ShippingAddressDetail = () => {
  const shippingAddressId = useIdParam();
  const { hasPermissions } = useOwnUser();

  const location = useLocation();

  const { loading, shippingAddress, refetchShippingAddresses, shippingAddressIsOnlyEditable } =
    useDetailsView(Number(shippingAddressId));

  const currentTab = useMemo(() => {
    return (
      ['related-order', 'shipments-from-shipping-address'].find((path) =>
        location.pathname.includes(path),
      ) ?? '/'
    );
  }, [location.pathname]);

  const handleOnTabChange = useNavigateInDetailTab(`/shipping-address/${shippingAddressId}`);

  const userCanEditShippingAddress = hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_SHIPPING_ADDRESS,
  );

  const tabs = useMemo(
    () => [
      {
        path: '/',
        label: 'Details',
        Element: (
          <ShippingDetails
            key="/"
            loading={loading}
            shippingAddress={shippingAddress}
            userCanEditShippingAddress={userCanEditShippingAddress}
            shippingAddressIsOnlyEditable={shippingAddressIsOnlyEditable}
          />
        ),
      },
      {
        path: 'orders',
        label: 'Orders',
        Element: (
          <ListCustom<OrderFlat>
            key="related-order"
            enableFilters
            cellDefinition={CellOrderDefinition}
            results={shippingAddress?.orders ?? []}
          />
        ),
      },
      {
        path: 'shipments',
        label: 'Shipments',
        Element: (
          <ListWithExport<ShipmentsWithTotalAmounts>
            key="shipments-from-shipping-address"
            cellDefinition={ShipmentCellDefinition}
            filters={{
              shippingAddressId: Number(shippingAddressId),
            }}
            dataName={'shipments-from-shipping-address'}
          />
        ),
      },
    ],
    [
      loading,
      shippingAddress,
      userCanEditShippingAddress,
      shippingAddressIsOnlyEditable,
      shippingAddressId,
    ],
  );

  return (
    <ItemLayout
      id={shippingAddressId}
      title={`Shipping Address (${shippingAddressId}) - @${shippingAddress?.user.username}`}
      description={`${shippingAddress?.inputLine1} ${shippingAddress?.inputLine2 || ''}, ${
        shippingAddress?.inputCity
      } ${shippingAddress?.inputPostalCode} ${shippingAddress?.inputCountry}`}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
      editionDescriptor={
        userCanEditShippingAddress
          ? {
              goBackName: 'Back',
              resourceId: Number(shippingAddressId),
              resourceType: 'Shipping-Address',
              onSuccess: refetchShippingAddresses,
            }
          : undefined
      }
    />
  );
};
