import { useReducer, useRef } from 'react';

import { FeaturedShowApplicationDecisionReason } from '@bits-app/bits-server-data';
import { FeaturedShowApplicationWithData } from '@bits-app/voggtpit-shared';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';

import { authenticatedInstance } from '@/axios/axios.instance';
import { formatText } from '@/utils';

import { useSnackbar } from '../../../components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '../../../queries';
import { keepOnlyValidReasonBasedOnDecision } from '../helper';

type ApplicationForm = {
  reason: FeaturedShowApplicationDecisionReason;
  decision: 'approved' | 'refused';
};

export const FeaturedShowApplicationRowAction = ({
  row,
}: {
  row: FeaturedShowApplicationWithData;
}) => {
  const snackbar = useSnackbar();
  const { handleSubmit, control, watch } = useForm<ApplicationForm>();
  const [menuIsOpen, toggleMenu] = useReducer((p) => !p, false);
  const ref = useRef(null);

  const { mutate } = useMutation({
    mutationFn: (input: ApplicationForm) => {
      return authenticatedInstance.post(`database-explorer/featured-show-application/decision`, {
        ...input,
        featuredShowApplicationIds: [row.id],
      });
    },
    onSuccess: () => {
      snackbar.success('Application updated');
      queryClient.invalidateQueries(QUERIES_KEYS.featuredShowList());
      queryClient.invalidateQueries(QUERIES_KEYS.featuredShowList('pending'));
      toggleMenu();
    },
  });

  if (row.featuredShowApplication.status !== 'pending') {
    return null;
  }

  return (
    <>
      <IconButton onClick={toggleMenu} ref={ref}>
        <PublishedWithChangesIcon />
      </IconButton>

      <Dialog open={menuIsOpen} onClose={toggleMenu}>
        <DialogTitle>Answer to {row.sellerInfo.username}'s application</DialogTitle>
        <DialogContent className="flex flex-col gap-4">
          <Controller
            name="decision"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="recommandationType-label">Answer</InputLabel>
                <Select labelId="recommandationType-label" {...field} label="Answer">
                  <MenuItem key={'approved'} value={'approved'}>
                    Accept
                  </MenuItem>
                  <MenuItem key={'refused'} value={'refused'}>
                    Reject
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="recommandationType-label">Reason</InputLabel>
                <Select labelId="recommandationType-label" {...field} label="Reason">
                  {Object.entries(FeaturedShowApplicationDecisionReason)
                    .filter(keepOnlyValidReasonBasedOnDecision(watch('decision')))
                    .map(([key, label]) => (
                      <MenuItem key={key} value={key}>
                        {formatText(label)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          />
          <Button
            variant="contained"
            onClick={handleSubmit((d) => mutate(d))}
            disabled={!watch('decision') || !watch('reason')}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
