import { authenticatedInstance } from '@/axios/axios.instance';

import { Emote } from '../../entity/emote.entity';

export const createEmote = async (formData: FormData) => {
  const result = await authenticatedInstance.post<Emote>('/database-explorer/emote', formData, {
    headers: {
      ['Content-Type']: 'multipart/form-data',
    },
  });

  return result.data;
};
