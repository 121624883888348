import { useMemo } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from '@mui/material';
import { Outlet, Route, Routes, Link as RouterLink } from 'react-router-dom';

import { Dropdown } from '@/components/elements/Dropdown';
import {
  EditionView,
  EditionViewProps,
} from '@/voggt-database-explorer/components/edition-view/EditionView';
import { EntityWrapper } from '@/voggt-database-explorer/redux/database-explorer';

export type ItemTab = {
  label: string;
  path: string;
  Element: React.ReactElement;
  hasChildren?: boolean;
  shouldHide?: boolean;
};

type ItemLayoutProps = {
  currentTab: string;
  onTabChange: (value: string) => void;
  tabs: ItemTab[];
  previousPagePath?: string;
  editionDescriptor?: EditionViewProps;
  title?: string;
  children?: React.ReactNode;
  description?: string;
  customActions?: { label: string; onClick: () => void; forbidden?: boolean }[];
  imageURL?: string;
  onEditButtonClick?: () => void;
  id: string;
  placeholderIcon?: React.ReactNode;
};

export const ItemLayout = <T extends EntityWrapper>({
  editionDescriptor,
  customActions = [],
  imageURL = '',
  title = '-',
  description = '',
  previousPagePath,
  onTabChange,
  currentTab,
  placeholderIcon = undefined,
  onEditButtonClick,
  tabs,
  id,
}: ItemLayoutProps) => {
  const _tabs = useMemo(() => tabs.filter((item) => !item.shouldHide), [tabs]);

  return (
    <div className="flex flex-col gap-2 m-4">
      {previousPagePath && (
        <div>
          <Link
            component={RouterLink}
            to={previousPagePath}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <ArrowBackIcon />
            Go back to list
          </Link>
        </div>
      )}

      <div className="w-full bg-white rounded-lg mb-2 border border-slate-200">
        <div className="p-4 flex items-center border-b border-slate-200 rounded-t">
          <div className="flex flex-1 items-center justify-between">
            {imageURL && (
              <img src={imageURL} className="w-32 h-32 object-cover rounded-full shadow" />
            )}
            {!imageURL && (
              <div className="bg-slate-200 w-32 h-32 rounded-full shadow flex items-center justify-center">
                {placeholderIcon}
              </div>
            )}

            <div className="flex flex-col flex-1 ml-4">
              <div
                className="flex items-center text-sm text-slate-100 w-fit bg-slate-500 rounded-full px-2 py-1 cursor-pointer hover:bg-slate-800 duration-100"
                onClick={() => navigator.clipboard.writeText(id)}
              >
                <p className="font-semibold">ID: {id}</p>
              </div>
              <h1 className="text-2xl font-semibold">{title}</h1>
              <h3 className="text-xl">{description}</h3>
            </div>
          </div>

          {onEditButtonClick && (
            <button
              onClick={onEditButtonClick}
              className="text-sm text-slate-800 rounded hover:bg-slate-300 mr-2 px-4 py-2.5 font-semibold bg-slate-200"
            >
              Edit
            </button>
          )}

          <Dropdown title="Actions" options={customActions} />
        </div>

        {_tabs.map(({ path, label }) => (
          <button
            key={path}
            onClick={() => onTabChange(path)}
            className={`text-sm text-slate-800 rounded hover:bg-slate-200 my-1 mx-1 py-2 px-2 ${
              currentTab === path ? 'text-slate-900 font-semibold' : ''
            }`}
          >
            {label}
          </button>
        ))}
      </div>

      <Routes>
        <Route element={<Outlet />}>
          {_tabs.map(({ path, Element, hasChildren }) => (
            <Route
              key={path}
              path={hasChildren ? `${path}/*` : path}
              element={<div className="flex flex-col flex-1">{Element}</div>}
            />
          ))}
        </Route>

        {editionDescriptor && (
          <Route path="edit" element={<EditionView<T> {...editionDescriptor} />} />
        )}
      </Routes>
    </div>
  );
};
