export enum OrderEventType {
  status = 'status',
  payment = 'payment',
  shipping = 'shipping',
}

export enum OrderEventStatus {
  created = 'created',
  canceled = 'canceled',
}

export enum OrderEventShipping {
  shipped = 'shipped',
  delivered = 'delivered',
  received = 'received',
}

export enum OrderEventPayment {
  attempt = 'attempt',
  retry = 'retry',
  in_progress = 'in_progress',
  aborted = 'aborted',
  success_pending_sepa = 'success_pending_sepa',
  success = 'success',
  failure = 'failure',
  refunded = 'refunded',
}

type OrderEventAttributes = {
  id: number;
  type: OrderEventType;
  orderId: number;
  paymentIntentId?: string;
  statusEvent?: OrderEventStatus;
  paymentEvent?: OrderEventPayment;
  shippingEvent?: OrderEventShipping;
  paymentMethodBancontactId?: number;
  paymentMethodCardId?: number;
  paymentMethodIdealId?: number;
  paymentMethodPaypalId?: number;
  updatedAt: Date;
  createdAt: Date;
  deletedAt?: Date;
};

export class OrderEvent {
  readonly id: number;

  readonly type: OrderEventType;

  readonly orderId: number;

  readonly statusEvent?: OrderEventStatus;

  readonly paymentEvent?: OrderEventPayment;

  readonly shippingEvent?: OrderEventShipping;

  readonly paymentIntentId?: string;

  readonly paymentMethodBancontactId?: number;

  readonly paymentMethodCardId?: number;

  readonly paymentMethodIdealId?: number;

  readonly paymentMethodPaypalId?: number;

  readonly updatedAt: Date;

  readonly createdAt: Date;

  readonly deletedAt?: Date;

  constructor(attributes: OrderEventAttributes) {
    this.id = attributes.id;
    this.type = attributes.type;
    this.orderId = attributes.orderId;
    this.statusEvent = attributes.statusEvent;
    this.paymentEvent = attributes.paymentEvent;
    this.shippingEvent = attributes.shippingEvent;
    this.paymentIntentId = attributes.paymentIntentId;
    this.paymentMethodBancontactId = attributes.paymentMethodBancontactId;
    this.paymentMethodCardId = attributes.paymentMethodCardId;
    this.paymentMethodIdealId = attributes.paymentMethodIdealId;
    this.paymentMethodPaypalId = attributes.paymentMethodPaypalId;
    this.updatedAt = attributes.updatedAt;
    this.createdAt = attributes.createdAt;
    this.deletedAt = attributes.deletedAt;
  }
}
