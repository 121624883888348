import { AppThunkAction } from '@/redux/store';

import {
  selectRefundableOrderItems,
  selectIsOrderItemInTheRefund,
} from '../redux/refund.selectors';

import { addOrderItemInRefund } from './add-order-item-in-refund';

export const addAllRefundableOrderItemsInRefund =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const refundableOrderItems = selectRefundableOrderItems(getState());

    for (const { id } of refundableOrderItems) {
      const isOrderItemInRefund = selectIsOrderItemInTheRefund(getState(), id);

      if (isOrderItemInRefund) {
        continue;
      }

      dispatch(addOrderItemInRefund(id));
    }
  };
