import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Order } from '../entities/order.entity';

export type OrderState = {
  loading: boolean;
  error: Record<Order['id'], string> | null;
};

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    loading: false,
    error: null,
  } as OrderState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<OrderState['loading']>) => {
      state.loading = payload;
    },
    setError: (state, { payload }: PayloadAction<OrderState['error']>) => {
      state.error = payload;
    },
  },
});

export const ordersActions = orderSlice.actions;

export default orderSlice.reducer;
