import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '../../../../components/elements/buttons/ButtonContained';

type DeleteAShowModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
  showId: string;
};

export const DeleteAShowModal = ({
  onCancel,
  onConfirm,
  isLoading,
  showId,
}: DeleteAShowModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open>
      <DialogTitle id="alert-dialog-title">
        {t('databaseExplorer.show.delete.title', { showId })}
      </DialogTitle>

      <DialogContent>
        <Stack direction={'column'} spacing={2}>
          <DialogContentText>{t('databaseExplorer.show.delete.description')}</DialogContentText>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={isLoading}>
          {t('action.cancel')}
        </Button>
        <ButtonContained onClick={onConfirm} disabled={isLoading}>
          {t('action.continue')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
