import { useEffect, useMemo } from 'react';

import { MaterialReactTableProps } from 'material-react-table';

import { FindOptions } from '@/hooks/use-table-options';

import { PaginationAPIType } from '../datalist-type';

export const usePagination = ({
  onChangeLimit,
  onChangePage,
  options,
  resultsCount,
  paginationAPI,
  scrollToTop,
}: {
  resultsCount: number;
  options: FindOptions;
  onChangePage: (page: number) => void;
  onChangeLimit: (limit: number) => void;
  paginationAPI?: PaginationAPIType;
  scrollToTop: () => void;
}): [{ pageIndex: number; pageSize: number }, MaterialReactTableProps['onPaginationChange']] => {
  const pagination = useMemo(
    () => ({
      pageIndex: resultsCount > 0 ? options.page : 0,
      pageSize: resultsCount > 0 ? options.limit : 10,
    }),
    [options.page, options.limit, resultsCount],
  );

  const handleOnPaginationChange: MaterialReactTableProps['onPaginationChange'] = (
    updaterOrValue,
  ) => {
    const value =
      typeof updaterOrValue === 'function' ? updaterOrValue(pagination) : updaterOrValue;

    onChangePage(value.pageIndex);
    onChangeLimit(value.pageSize);
  };

  useEffect(() => {
    if (!paginationAPI) {
      return;
    }

    paginationAPI.onPaginationChange({
      page: options.page,
      pageSize: options.limit,
    });

    scrollToTop();
  }, [options.page, options.limit, paginationAPI, scrollToTop]);

  return [pagination, handleOnPaginationChange];
};
