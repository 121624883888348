import { useMemo } from 'react';

import {
  formatAmountWithCurrency,
  PartnerProgramType,
  PERMISSIONS,
} from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { useOwnUser } from '@/context/own-user.context';
import { formatPercentage } from '@/utils';
import { CellDefinition } from '@Elements/DataList/datalist-type';
import { Button } from '@Elements/buttons/Button';

import { usePartnerProgramList } from '../hooks/usePartnerProgramList';

import { PartnerProgramForm } from './form';

export const ListPartnerProgram = () => {
  const { partnerPrograms, selectPartnerProgram } = usePartnerProgramList();
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();

  const CellDefinition = useMemo(
    (): CellDefinition<PartnerProgramType>[] => [
      {
        id: 'name',
        label: t('partnerProgram.form.name'),
      },

      {
        id: 'description',
        label: t('partnerProgram.form.description'),
      },
      {
        id: 'amount',
        label: t('partnerProgram.form.amount'),
        type: 'money',
        getValue: (partnerPrograms) =>
          partnerPrograms.amount
            ? `${formatAmountWithCurrency(partnerPrograms.amount, partnerPrograms.currency)}`
            : '',
      },
      {
        id: 'percentage',
        label: t('partnerProgram.form.percentageBased'),
        type: 'string',
        getValue: (partnerProgram) =>
          partnerProgram.percentage ? formatPercentage(partnerProgram.percentage) : '',
      },
      {
        id: 'percentageMaxAmount',
        label: t('partnerProgram.form.maxAmount'),
        type: 'money',
        getValue: (partnerPrograms) =>
          partnerPrograms.percentageMaxAmount
            ? `${formatAmountWithCurrency(
                partnerPrograms.percentageMaxAmount,
                partnerPrograms.currency,
              )}`
            : '',
      },
      {
        id: 'isPrivate',
        label: 'is private',
        type: 'boolean',
      },
      {
        id: 'currency',
        label: t('partnerProgram.form.currency'),
        type: 'badge',
      },
      {
        id: 'createdAt',
        label: t('form.createdAt'),
        type: 'date-with-time',
      },
      {
        id: 'expireInType',
        label: t('partnerProgram.form.expireType'),
        type: 'string',
        getValue: (partnerProgram) =>
          `${partnerProgram.expireInNumber} - ${partnerProgram.expireInType}`,
      },
      {
        id: 'deletedAt',
        label: t('form.deletedAt'),
        type: 'date',
      },
      {
        id: 'updatedAt',
        label: t('form.updatedAt'),
        type: 'date-with-time',
      },
    ],
    [t],
  );

  const isReadOnly = !hasPermissions(PERMISSIONS.PARTNER_PROGRAM.WRITE_PARTNER_PROGRAM);

  return (
    <>
      <div className="h-full flex flex-col flex-1 p-4">
        <ListCustom<PartnerProgramType>
          cellDefinition={CellDefinition}
          results={partnerPrograms}
          enableFilters
          onClickOnRow={
            !isReadOnly ? (partnerProgram) => selectPartnerProgram(partnerProgram) : undefined
          }
        >
          {!isReadOnly ? (
            <Button
              onClick={() => selectPartnerProgram({ id: -1 })}
              variant="contained"
              size="medium"
              sx={{
                maxHeight: 32,
                marginTop: 'auto',
                marginBottom: 'auto',
                display: {
                  xs: 'none',
                  md: 'inherit',
                },
              }}
            >
              {t('partnerProgram.action.create')}
            </Button>
          ) : (
            <></>
          )}
        </ListCustom>
      </div>

      <PartnerProgramForm />
    </>
  );
};
