import { SellerNews } from '@bits-app/bits-server-data';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';

import { cellDefinitionSellerNews } from '../const';
import { useListSellerNews } from '../hooks/use-list-seller-news';

import { CreateSellerNewsButton } from './create/CreateSellerNews';

export const ListSellerNews = () => {
  const { data } = useListSellerNews();

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListCustom<SellerNews>
        cellDefinition={cellDefinitionSellerNews}
        results={data ?? []}
        getPathDetail={(sellerNews) => `/seller-news/${sellerNews.id}`}
      >
        <CreateSellerNewsButton />
      </ListCustom>
    </div>
  );
};
