import { useState } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Edit, Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton, DialogActions } from '@mui/material';

import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';
import { useOwnUser } from '@/context/own-user.context';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

type EditPayoutProps = {
  handleTriggerPayout: (sellerIds: number[]) => Promise<void>;
  sellerIds: number[];
  isLoading: boolean;
};

export const EditPayout = ({ isLoading, handleTriggerPayout, sellerIds }: EditPayoutProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const { hasPermissions } = useOwnUser();

  const userCanPayout = hasPermissions(PERMISSIONS.PAYOUT.WRITE_PAYOUT);

  if (!userCanPayout) {
    return null;
  }

  const onValidate = () => {
    handleTriggerPayout(sellerIds).then(closeModal);
  };

  return (
    <>
      <IconButton onClick={openModal} size="small" sx={{ '& svg': { width: 20, height: 20 } }}>
        <Edit />
      </IconButton>

      <ValidationModal
        isOpen={isOpen}
        onValidate={onValidate}
        onCancel={closeModal}
        isLoading={isLoading}
      />
    </>
  );
};

type ValidationModalProps = {
  isOpen: boolean;
  onValidate: () => void;
  onCancel: () => void;
  isLoading: boolean;
};

const ValidationModal = ({ isOpen, onValidate, onCancel, isLoading }: ValidationModalProps) => {
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          minWidth: 360,
        },
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        Process payout for selected sellers. Are you sure?
        <IconButton size="small" color="inherit" onClick={onCancel}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogActions
        sx={{
          pr: 3,
        }}
      >
        <ButtonOutlined disabled={isLoading} onClick={onCancel}>
          Cancel
        </ButtonOutlined>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
          onClick={onValidate}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
