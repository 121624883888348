import { ArchivedAutoFollowUserCampaigns } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { Loader } from '@/components/elements/Loader';
import { QUERIES_KEYS } from '@/queries';

import { type Node, activeCells } from './cells';
import { CreateAutoFollowUserCampaignButton } from './create-button';

export const ArchivedAutoFollowUserCampaignsView = () => {
  const { data, isLoading } = useQuery(QUERIES_KEYS.archivedAutoFollowUserCampaignList(), () =>
    authenticatedInstance.get<ArchivedAutoFollowUserCampaigns>(
      '/archived-auto-follow-user-campaigns',
    ),
  );

  if (isLoading || !data?.data) return <Loader />;

  const items = data.data.archivedAutoFollowUserCampaigns.edges.map((x) => x.node);

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListCustom<Node> cellDefinition={activeCells} enableFilters results={items}>
        <CreateAutoFollowUserCampaignButton />
      </ListCustom>
    </div>
  );
};
