import assert from 'assert';

import { DynamicFieldData } from '@bits-app/voggtpit-shared';
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormContext } from 'react-hook-form';

import { DateInput } from '@Elements/inputs/DateInput';

import { AmountDynamicInput } from './AmountDynamicInput';

export const DynamicControl = <T,>({
  inputType,
  field,
  label,
  value,
  extraData,
  disabled,
  options = [],
}: DynamicFieldData<T>) => {
  const { register } = useFormContext();

  switch (inputType) {
    case 'text':
      return (
        <TextField fullWidth label={label} type="text" disabled={disabled} {...register(field)} />
      );

    case 'multiline':
      return (
        <TextField
          fullWidth
          label={label}
          type="text"
          disabled={disabled}
          multiline
          {...register(field)}
        />
      );

    case 'select': {
      return (
        <FormControl fullWidth>
          <InputLabel id={field}>{label}</InputLabel>
          <Select native labelId={field} id={field} label={label} {...register(field)}>
            {options.map((option, index) => (
              <StyledOption key={index} value={option.value}>
                {option.label}
              </StyledOption>
            ))}
          </Select>
        </FormControl>
      );
    }

    case 'number':
      return (
        <TextField
          fullWidth
          label={label}
          type="number"
          disabled={disabled}
          inputProps={{
            step: 0.01,
          }}
          {...register(field, { valueAsNumber: true })}
        />
      );

    case 'amount':
      return (
        <AmountDynamicInput
          field={field}
          label={label}
          disabled={disabled}
          extraData={extraData}
          value={value}
        />
      );

    case 'date':
      // TODO watch for error and find a way to handle those properly.
      try {
        if (value) {
          assert(typeof value === 'string' || value instanceof Date);
        }
      } catch (error) {
        console.error(
          `[DynamicControl]: value should be of type string or Date. Was: ${typeof value}`,
        );
      }

      return (
        <DateInput
          fullWidth
          disabled={disabled}
          label={label}
          InputLabelProps={{ shrink: true }}
          {...register(field, { valueAsDate: true })}
        />
      );

    case 'date-with-time':
      // TODO watch for error and find a way to handle those properly.
      try {
        if (value) {
          assert(typeof value === 'string' || value instanceof Date);
        }
      } catch (error) {
        console.error(
          `[DynamicControl]: value should be of type string or Date. Was: ${typeof value}`,
        );
      }

      return (
        <TextField
          type="datetime-local"
          fullWidth
          disabled={disabled}
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 1,
          }}
          {...register(field, { valueAsDate: true })}
        />
      );

    case 'switch':
      // TODO watch for error and find a way to handle those properly.
      try {
        if (value) {
          assert(typeof value === 'boolean');
        }
      } catch (error) {
        console.error(`[DynamicControl]: value should be of type boolean. Was ${typeof value}`);
      }

      return (
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontWeight="bold">{label}</Typography>
            {extraData?.description && (
              <Typography variant="body2" color="GrayText">
                {extraData.description}
              </Typography>
            )}
          </Grid>

          <Switch {...register(field)} defaultChecked={value as boolean} disabled={disabled} />
        </Grid>
      );

    default:
      return (
        <TextField fullWidth label={label} type="text" disabled={disabled} {...register(field)} />
      );
  }
};

const StyledOption = styled('option')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  background: 'red',
}));
