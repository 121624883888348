import { useState, useCallback, useEffect } from 'react';

import { FileError, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export const useImageUpload = (maxSizeWithUnit?: string) => {
  const { t } = useTranslation();

  const [image, setImage] = useState<(File & { preview: string }) | null>(null);
  const [imageErrors, setImageErrors] = useState<FileError[] | null>(null);

  const onDropImage = useCallback((files: File[], fileRejection: FileRejection[]) => {
    setImageErrors(null);

    if (fileRejection.length > 0) {
      const rejectedFile = fileRejection[0];

      setImage(null);
      setImageErrors(
        rejectedFile.errors.map((error) => {
          if (error.code === 'file-too-large') {
            return {
              code: error.code,
              message: t('imageUpload.errors.fileTooLarge', { maxSizeWithUnit }),
            };
          }

          return error;
        }),
      );

      return;
    }

    const [file] = files;
    setImage(Object.assign(file, { preview: URL.createObjectURL(file) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (!image) {
        return;
      }

      URL.revokeObjectURL(image.preview);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearImage = useCallback(() => {
    setImage(null);
  }, []);

  return {
    onDropImage,
    image,
    imageErrors,
    clearImage,
    setImageErrors,
  };
};
