import { Dialog, DialogContent } from '@mui/material';

import { AddUsersFromCsv } from './add-users-from-csv.step';

export interface AddUsersDialogProps {
  handleClose(): void;
}

export const AddUsersDialog = ({ handleClose }: AddUsersDialogProps) => {
  return (
    <Dialog maxWidth="md" open onClose={handleClose} fullWidth>
      <DialogContent>
        <AddUsersFromCsv handleNext={handleClose} />
      </DialogContent>
    </Dialog>
  );
};
