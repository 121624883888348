import { PERMISSIONS, ZendeskData } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { useOwnUser } from '../../../context/own-user.context';
import { QUERIES_KEYS } from '../../../queries';

export const useZendesk = (userId: number) => {
  const { hasPermissions } = useOwnUser();
  return useQuery(
    QUERIES_KEYS.userZendeskData(userId),
    () =>
      authenticatedInstance.get<ZendeskData>(`/database-explorer/customer/${userId}/zendesk-id`),
    { enabled: hasPermissions(PERMISSIONS.USER.READ_USER_ZENDESK_TICKETS) },
  );
};
