import { useMemo } from 'react';

import { MarketPageType } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { CellDefinition } from '@Elements/DataList/datalist-type';

import { useMarketPageList } from '../../hooks/use-market-page-list';

import { CreateMarketPage } from './create-market-page';

export const ListMarketPages = () => {
  const { marketPages } = useMarketPageList();

  const { t } = useTranslation();

  const CellDefinition = useMemo(
    (): CellDefinition<MarketPageType>[] => [
      { id: 'id', label: 'Id' },
      {
        id: 'categoryId',
        label: t('categoryId'),
        type: 'string',
      },
      {
        id: 'slug',
        label: t('slug'),
        type: 'string',
      },
      {
        id: 'title',
        label: t('title'),
        type: 'string',
      },
      {
        id: 'description',
        label: t('description'),
        type: 'string',
      },
      {
        id: 'coverUrl',
        label: t('coverUrl'),
        type: 'string',
      },
      {
        id: 'countryIds',
        label: t('countries'),
        type: 'string',
      },
      {
        id: 'createdAt',
        label: t('form.createdAt'),
        type: 'date-with-time',
      },
      {
        id: 'updatedAt',
        label: t('form.updatedAt'),
        type: 'date-with-time',
      },
      {
        id: 'deletedAt',
        label: t('form.deletedAt'),
        type: 'date-with-time',
      },
    ],
    [t],
  );

  return (
    <>
      <div className="h-full flex flex-col flex-1 p-4">
        <ListCustom<MarketPageType>
          cellDefinition={CellDefinition}
          results={marketPages}
          getPathDetail={(marketPage) => `/market-pages/${marketPage.id}`}
          enableFilters
          children={<CreateMarketPage />}
        />
      </div>
    </>
  );
};
