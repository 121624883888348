import { Badge } from '@bits-app/bits-server-data';
import Ico from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

import { UpsertBadgeModal } from '@/voggt-database-explorer/badge/components/upsert-badge/UpsertBadge.modal';
import { useUpsertBadge } from '@/voggt-database-explorer/badge/components/upsert-badge/use-upsert-badge.hook';
import { DraftBadge } from '@/voggt-database-explorer/badge/entity/draft-badge';
import { Button } from '@Elements/buttons/Button';

type EditBadgeButtonProps = {
  badge: Badge | DraftBadge;
  isDraft?: boolean;
};

export const EditBadgeButton = ({ badge, isDraft }: EditBadgeButtonProps) => {
  const { t } = useTranslation();

  const { ownUserIsAllowedToUpsertBadge, onCloseForm, onOpenForm, openForm } = useUpsertBadge();

  if (!ownUserIsAllowedToUpsertBadge) {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        endIcon={<Ico fontSize="small" />}
        sx={{ fontWeight: 'bold' }}
        onClick={onOpenForm}
      >
        {t('action.edit')}
      </Button>

      {openForm && (
        <UpsertBadgeModal badges={[]} onClose={onCloseForm} badge={badge} isDraft={isDraft} />
      )}
    </>
  );
};
