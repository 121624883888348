import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/redux/store';

const orderSelector = (state: RootState) => state.order;

export const selectIsLoading = createSelector(orderSelector, (state) => state.loading);
export const selectError = createSelector(orderSelector, (state) => state.error);

export const orderSelectors = {
  selectIsLoading,
  selectError,
};
