import { Category } from '@bits-app/bits-server-data';

import { authenticatedInstance } from '@/axios/axios.instance';

export const editCategory = async (formData: FormData, categoryId: Category['id']) => {
  const result = await authenticatedInstance.patch<Category>(
    `/database-explorer/category/${categoryId}`,
    formData,
    {
      headers: {
        ['Content-Type']: 'multipart/form-data',
      },
    },
  );

  return result.data;
};
