import { useMemo } from 'react';

import { Permission } from '@bits-app/voggtpit-shared';
import { Box, Checkbox } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { useForm, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useMutatePermissions } from '@/administration/ui/manage-own-user-permissions/use-mutate-permissions';
import {
  getDefaultValue,
  isDisabled,
  nameToPermission,
  PermissionDisplay,
} from '@/administration/ui/manage-own-user-permissions/utils';
import { OwnUser } from '@/domain/entities/own-user.entity';

export type FieldValue = { [key in Permission]: boolean };

export const useManageOwnUserPermissions = (ownUser: OwnUser | null, onClose: () => void) => {
  const { t } = useTranslation();

  const handleClose = () => {
    reset();
    onClose();
  };

  const { mutate, isLoading } = useMutatePermissions(ownUser, handleClose);

  const defaultValues = useMemo(
    () => getDefaultValue(ownUser?.permissions),
    [ownUser?.permissions],
  );

  const { register, formState, reset, handleSubmit } = useForm<FieldValue>({
    defaultValues,
  });

  const columns = useMemo(
    (): MRT_ColumnDef<PermissionDisplay>[] => [
      {
        id: 'group',
        accessorFn: ({ group }) => group.replaceAll('_', ' '),
        header: t('administration.managePermissions.group'),
      },
      {
        accessorKey: 'name',
        header: t('administration.managePermissions.name'),
        enableGrouping: false,
      },
      {
        accessorKey: 'read',
        header: t('administration.managePermissions.read'),
        enableGrouping: false,
        Cell: ({ row }) => {
          if (row.original.read === undefined) {
            return null;
          }

          return (
            <CheckPermission
              name={nameToPermission(row.original, 'READ')}
              register={register}
              defaultValues={defaultValues}
            />
          );
        },
      },
      {
        accessorKey: 'write',
        header: t('administration.managePermissions.write'),
        enableGrouping: false,
        Cell: ({ row }) => {
          if (row.original.write === undefined) {
            return null;
          }

          const name = nameToPermission(row.original, 'WRITE');

          return (
            <CheckPermission
              name={name}
              register={register}
              defaultValues={defaultValues}
              disabled={isDisabled(name)}
            />
          );
        },
      },
    ],
    [defaultValues, register, t],
  );

  const onSubmit = handleSubmit((values) => {
    if (!ownUser) {
      return;
    }

    const permissions = Object.entries(values)
      .filter(([_, value]) => value)
      .map(([permission]) => permission) as Permission[];

    mutate([ownUser.id, permissions]);
  });

  return { columns, formState, handleClose, onSubmit, isLoading };
};

type CheckPermissionProps = {
  name: Permission;
  register: UseFormRegister<FieldValue>;
  defaultValues: FieldValue;
  disabled?: boolean;
};

const CheckPermission = ({ name, defaultValues, register, disabled }: CheckPermissionProps) => {
  return (
    <Box
      // react-hook-form getValues doesn't return value of disabled inputs
      // https://github.com/orgs/react-hook-form/discussions/4413
      component={disabled ? 'fieldset' : 'div'}
      disabled={disabled}
      sx={
        disabled
          ? {
              border: 'none',
              opacity: 0.3,
              padding: 0,
              margin: 0,
              input: {
                cursor: 'default',
              },
            }
          : undefined
      }
    >
      <Checkbox
        size="small"
        sx={{
          height: 20,
          width: 20,
        }}
        {...register(name)}
        defaultChecked={defaultValues[name]}
      />
    </Box>
  );
};
