import Big from 'big.js';

import { Currency } from './currency.enum';
import { RefundReason } from './order-item';

export const formatAmount = (amount: number) => amount.toFixed(2);

export const isMatchingAmountFormat = (value: string) =>
  /^\d*\.?\d*$/.test(value);

export const centsToUnit = (amount: number) => {
  if (amount == null || isNaN(amount)) {
    return amount;
  }

  const value = new Big(amount);

  return Number(value.div(100));
};

export const unitToCents = (amount: number) => {
  if (amount == null || isNaN(amount)) {
    return amount;
  }

  const value = new Big(amount);

  return Number(value.mul(100));
};

export const currencySymbol = (currency: Currency) => {
  if (currency === Currency.eur) {
    return '€';
  }

  if (currency === Currency.gbp) {
    return '£';
  }

  return '';
};

export type Nullable<T> = T | null;
export type Undefinable<T> = T | null;
export type NullableAndUndefinable<T> = Nullable<T> | Undefinable<T>;

export const mapRefundReasonValueToKey: Record<RefundReason, string> = {
  Misclick: 'misclick',
  'Lost parcel': 'lostParcel',
  'Delivered by hand': 'deliveredByHand',
  'Change of mind': 'changeOfMind',
  'Missing product': 'missingProduct',
  'Damaged product': 'damagedProduct',
  'Seller mistake': 'sellerMistake',
  'Free shipping': 'freeShipping',
  'Bad protection': 'badProtection',
  'Product lost before shipping': 'productLostBeforeShipping',
  Counterfeit: 'counterfeit',
  Delight: 'delight',
  Others: 'others',
  Fraud: 'fraud',
};

export const add = (...args: number[]) => {
  let total = new Big(0);

  for (const number of args) {
    total = total.plus(new Big(number));
  }

  return Number(total);
};

export const minus = (...args: number[]) => {
  let total = new Big(args[0]);

  for (const number of args.slice(1)) {
    total = total.minus(new Big(number));
  }

  return Number(total);
};

export const divide = (dividend: number, divisor: number) => {
  const total = new Big(dividend).div(new Big(divisor));

  return Number(total);
};

export const multiply = (...args: number[]) => {
  let total = new Big(1);

  for (const number of args) {
    total = total.times(new Big(number));
  }

  return Number(total);
};

export const formatAmountWithCurrency = (amount: number, currency: Currency) =>
  `${formatAmount(amount)} ${currencySymbol(currency)}`;

export const isAtLeastContaining = <T>(container: T[], expected: T[]) =>
  expected.every((value) => container.includes(value));

export const hasOneOf = <T>(container: T[], expected: T[]) =>
  expected.some((value) => container.includes(value));
