import { Language } from '@bits-app/bits-server-data';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';

import { useCellDefinition } from '../../use-cell-definition';

const TYPESENSE_MAX_RESULT_PER_PAGE = 30;

export const ListLanguage = () => {
  const { CellLanguageDefinition } = useCellDefinition();

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListWithSearch<Language & { id: string }>
        title="Languages"
        cellDefinition={CellLanguageDefinition}
        typesenseHookParams={{
          typesenseIndex: 'language',
          sortBy: 'updatedAt_Unix:desc',
          typesenseParams: {
            per_page: TYPESENSE_MAX_RESULT_PER_PAGE,
            query_by: ['code'],
          },
        }}
      />
    </div>
  );
};
