import { useState } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { useOwnUser } from '@/context/own-user.context';

import { User } from '../users';

export type AvailableImpersonate = 'bits-web' | 'seller-studio';

const BASE_URL: Record<AvailableImpersonate, string> = {
  'bits-web': process.env.REACT_APP_BITS_WEB_URL || '',
  'seller-studio': process.env.REACT_APP_SELLER_STUDIO_URL || '',
};

export const useImpersonateFeature = (userId: User['id']) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { hasPermissions } = useOwnUser();
  const snackbar = useSnackbar();

  const toggleImpersonateModalFeature = () => setModalIsOpen(!modalIsOpen);
  const requestImpersonate = async (userChoice: AvailableImpersonate) => {
    try {
      const baseUrl = BASE_URL[userChoice];

      const { data } = await authenticatedInstance.get<{ impersonationTokenId: string } | false>(
        `/database-explorer/customer/${userId}/impersonate`,
      );

      if (typeof data === 'object' && data.impersonationTokenId) {
        window.open(`${baseUrl}/?impersonationTokenId=${data.impersonationTokenId}`, '_blank');
      }
      toggleImpersonateModalFeature();
    } catch (e) {
      snackbar.error('somethingWrongHappened');
    }
  };

  const ownUserIsAllowedToImpersonate = hasPermissions(
    PERMISSIONS.IMPERSONATE_ACCESS.WRITE_IMPERSONATE_ACCESS,
  );

  return {
    ownUserIsAllowedToImpersonate,
    toggleImpersonateModalFeature,
    impersonateModalFeatureIsOpen: modalIsOpen,
    requestImpersonate,
  };
};
