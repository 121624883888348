import { Currency } from '@bits-app/voggtpit-shared';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/redux/reduxAppHooks';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

import {
  selectIsSellerCancelRequestsRefundFormLoading,
  selectIsSellerCancelRequestsRefundFormProcessing,
  selectIsSellerCancelRequestsRefundSummaryLoading,
  selectSellerCancelRequestsRefundSummary,
} from '../../redux/seller-cancel-requests.selectors';

import { SellerCancelRequestsRefundSummary } from './SellerCancelRequestsRefundSummary';

type ConfirmRefundDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  currency: Currency;
};

export const ConfirmRefundSellerCancelRequestsDialog = ({
  onClose,
  onConfirm,
  currency,
}: ConfirmRefundDialogProps) => {
  const { t } = useTranslation();

  const isSummaryLoading = useAppSelector(selectIsSellerCancelRequestsRefundSummaryLoading);
  const isRefundLoading = useAppSelector(selectIsSellerCancelRequestsRefundFormLoading);
  const processingRefunds = useAppSelector(selectIsSellerCancelRequestsRefundFormProcessing);

  const refundSummary = useAppSelector(selectSellerCancelRequestsRefundSummary);

  return (
    <Dialog
      open
      PaperProps={{
        sx: {
          minWidth: 360,
        },
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        {t('refund.confirmation.refundConfirm')}

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Divider
          sx={{
            borderColor: ({ palette }) => palette.common.black,
          }}
        />
      </DialogContent>

      <DialogContent>
        <SellerCancelRequestsRefundSummary loading={isSummaryLoading} currency={currency} />

        <Box
          mt={3}
          py={2}
          sx={{
            borderTop: ({ palette }) => `1px dashed ${palette.grey[400]}`,
            paddingBottom: '6px',
          }}
        >
          {refundSummary.totalAmount > 0 && (
            <Typography>{t('sellerCancelRequests.confirmation.emailsSent')}</Typography>
          )}

          {refundSummary.includesGiveaways && (
            <Typography>{t('sellerCancelRequests.confirmation.emailsNotSent')}</Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          padding: ({ spacing }) => spacing(3),
        }}
      >
        <LoadingButton
          variant="contained"
          loading={isRefundLoading}
          onClick={onConfirm}
          disabled={isSummaryLoading}
        >
          {t('refund.confirmation.confirm')}{' '}
          {isRefundLoading ? `${processingRefunds?.current}/${processingRefunds?.count}` : ''}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
