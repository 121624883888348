import { Badge } from '@bits-app/bits-server-data';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonOutlined } from '@Elements/buttons/ButtonOutlined';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

import { DraftBadge } from '../../../entity/draft-badge';

type DeleteBadgeModalProps = {
  badge: Badge | DraftBadge;
  onClose: () => void;
  isLoading: boolean;
  onDelete: (id: Badge['id'] | DraftBadge['id']) => void;
};

export const DeleteBadgeModal = ({
  badge,
  onClose,
  isLoading,
  onDelete,
}: DeleteBadgeModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="common.black"
      >
        {t('databaseExplorer.badge.delete.title', { badge: badge.name })}

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography>
          {t('databaseExplorer.badge.delete.content', {
            badge: badge.name,
          })}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          px: ({ spacing }) => spacing(3),
          paddingBottom: ({ spacing }) => spacing(2),
        }}
      >
        <ButtonOutlined onClick={onClose} disabled={isLoading}>
          {t('cancel')}
        </ButtonOutlined>

        <LoadingButton
          variant="contained"
          color="error"
          onClick={() => onDelete(badge.id)}
          loading={isLoading}
          disabled={isLoading}
        >
          {t('action.delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
