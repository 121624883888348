import { alpha } from '@mui/material/styles';
import createPaletteSample from '@mui/material/styles/createPalette';

import { neutral, mainColor } from './colors';

export function createPalette() {
  const black = '#1D1D1D';
  const white = '#FCFCFC';
  const palette = createPaletteSample({});

  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
    },
    common: {
      black,
      white,
      ...neutral,
    },
    primary: {
      ...mainColor,
      dark: '#141414',
      light: '#3C505F',
      contrastText: white,
    },
    secondary: {
      dark: '#B0B0B0',
      main: white,
      light: '#FFFFFF',
      contrastText: 'black',
    },
    error: {
      dark: '#a01212',
      main: '#e51a1a',
      light: '#ffb3b3',
      contrastText: white,
    },
    warning: {
      ...palette.warning,
      contrastText: white,
    },
    info: {
      dark: '#877eaf',
      main: '#c1b5fb',
      light: '#e0dafd',
      contrastText: white,
    },
    success: {
      dark: '#294b0a',
      main: '#3b6c0f',
      light: '#d9f7be',
      contrastText: white,
    },
    grey: {
      900: '#111111',
      800: '#1d1d1d',
      700: '#333333',
      600: '#4a4a4a',
      500: '#767676',
      400: '#8a8a8a',
      300: '#a5a5a5',
      200: '#cfcfcf',
      100: '#e9e9e9',
      50: '#f1f1f1',
    },
    background: {
      paper: white,
      default: white,
    },
    text: {
      primary: 'rgba(29, 29, 29, 0.87)',
      secondary: 'rgba(29, 29, 29, 0.6)',
      disabled: 'rgba(29, 29, 29, 0.38)',
    },
  };
}
