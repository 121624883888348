import { useCallback, useEffect, useState } from 'react';

import { ALL_PERMISSIONS, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Delete } from '@mui/icons-material';
import ManagePermissionsIcon from '@mui/icons-material/AddModerator';
import LockResetIcon from '@mui/icons-material/LockReset';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useOwnUser } from '@/context/own-user.context';
import { OwnUser, ownUserDtoToEntity } from '@/domain/entities/own-user.entity';
import { QUERIES_KEYS } from '@/queries';
import { DataList } from '@Elements/DataList';
import { CellDefinition } from '@Elements/DataList/datalist-type';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

import { ChangeOwnUserPassword } from './ChangeOwnUserPassword.modal';
import { DeleteOwnUserModal } from './delete-own-user/DeleteOwnUser.modal';
import { ManageOwnUserPermissions } from './manage-own-user-permissions';

const getOwnUsers = async () => {
  try {
    const response = await authenticatedInstance.get('/user');

    return response.data?.map(ownUserDtoToEntity);
  } catch (error) {
    if (error instanceof AxiosError<{ message?: string }>) {
      console.log(error.response?.data.message);

      throw error.response?.data.message;
    }

    throw String(error);
  }
};

export const OwnUsersList = () => {
  const { error, data } = useQuery<OwnUser[], string>({
    queryKey: QUERIES_KEYS.ownUsersList(),
    queryFn: getOwnUsers,
  });
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [changePasswordOwnUser, setChangePasswordOwnUser] = useState<OwnUser | null>(null);
  const [managePermissionsOwnUser, setManagePermissionsOwnUser] = useState<OwnUser | null>(null);
  const [deleteOwnUser, setDeleteOwnUser] = useState<OwnUser | null>(null);

  const handleCloseChangePassword = useCallback(() => setChangePasswordOwnUser(null), []);

  const columns: CellDefinition<OwnUser>[] = [
    {
      id: 'firstName',
      label: 'First name',
    },
    {
      id: 'lastName',
      label: 'Last name',
    },
    {
      id: 'email',
      label: 'Email',
    },
    {
      id: 'permissions',
      label: 'Permissions',
      getValue: (ownUser) =>
        t('member.permissions', {
          total: ALL_PERMISSIONS.length,
          count: ownUser.permissions.length,
        }),
    },
  ];

  useEffect(() => {
    if (error) {
      snackbar.error(error ?? 'error');
    }
  }, [error, snackbar]);

  return (
    <div className="flex flex-col flex-1 h-full">
      <Box pt={3}>
        <DataList<OwnUser>
          results={data ?? []}
          cellDefinition={columns}
          enableFilters
          enableGlobalFilter
          renderRowActions={({ row: { original } }) => (
            <OwnUserAction
              ownUser={original}
              onClickChangePassword={() => setChangePasswordOwnUser(original)}
              onClickManagePermissions={() => setManagePermissionsOwnUser(original)}
              onClickDeleteOwnUser={() => setDeleteOwnUser(original)}
            />
          )}
        />
      </Box>

      {changePasswordOwnUser && (
        <ChangeOwnUserPassword
          ownUser={changePasswordOwnUser}
          open={Boolean(changePasswordOwnUser)}
          handleClose={handleCloseChangePassword}
        />
      )}

      {deleteOwnUser && (
        <DeleteOwnUserModal ownUser={deleteOwnUser} onClose={() => setDeleteOwnUser(null)} />
      )}

      {Boolean(managePermissionsOwnUser) && (
        <ManageOwnUserPermissions
          ownUser={managePermissionsOwnUser}
          onClose={() => setManagePermissionsOwnUser(null)}
        />
      )}
    </div>
  );
};

type OwnUserActionProps = {
  ownUser: OwnUser;
  onClickChangePassword: () => void;
  onClickManagePermissions: () => void;
  onClickDeleteOwnUser: () => void;
};

const OwnUserAction = ({
  ownUser,
  onClickChangePassword,
  onClickManagePermissions,
  onClickDeleteOwnUser,
}: OwnUserActionProps) => {
  const { hasPermissions, ownUser: me } = useOwnUser();
  const { t } = useTranslation();

  return (
    <>
      {hasPermissions(PERMISSIONS.ADMINISTRATION.WRITE_ADMINISTRATION_PERMISSIONS) && (
        <IconButton
          size="small"
          title={t('permissionsUpdateDialog.title', { name: ownUser.fullName })}
          onClick={onClickManagePermissions}
        >
          <ManagePermissionsIcon fontSize="small" />
        </IconButton>
      )}
      {hasPermissions(PERMISSIONS.SUPER_PRIVILEGE.WRITE_SUPER_PRIVILEGE_DELETE_OWN_USER) && (
        <IconButton
          color="error"
          onClick={onClickDeleteOwnUser}
          title={t('administration.deleteOwnUser.title', { username: ownUser.fullName })}
          disabled={ownUser.id === me?.id}
        >
          <Delete />
        </IconButton>
      )}
      {ownUser.isRegisterWithPassword &&
        hasPermissions(PERMISSIONS.ADMINISTRATION.WRITE_ADMINISTRATION_PASSWORD) && (
          <IconButton
            color="error"
            onClick={onClickChangePassword}
            title={t('members.changePassword', { username: ownUser.fullName })}
          >
            <LockResetIcon />
          </IconButton>
        )}
    </>
  );
};
