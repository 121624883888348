import { useEffect, useRef, useState } from 'react';

import { OrderItemsDistinctValuesFindOptions } from '@bits-app/voggtpit-shared';
import { useDebounce } from 'use-debounce';

import { InfiniteScrollContainerHandle } from '@/components/elements/InfiniteScrollContainer';
import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { selectOrderItemsMassUpdateFilterValuesFor } from '@/order-items-mass-update/redux/order-items-mass-update.selectors';
import {
  getOrderItemsFilterValuesFor,
  getOrderItemsNextPageFilterValuesFor,
} from '@/order-items-mass-update/use-cases';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

type UseUsernameFilter = {
  field: string;
  selected: string;
  setSelected: (value: string) => void;
};

export const useUsernameFilter = ({ field, selected, setSelected }: UseUsernameFilter) => {
  const infiniteScrollContainerRef = useRef<InfiniteScrollContainerHandle | null>(null);
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) =>
    selectOrderItemsMassUpdateFilterValuesFor(state, field),
  );

  const [value, setValue] = useState<string>('');
  const [search] = useDebounce(value, 300);

  const [page, setPage] = useState(0);

  const noResults = options.length === 0 && search.length >= 2;

  const onToggle = (value: string) => () => {
    if (value === selected) {
      setSelected('');
    } else {
      setSelected(value);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onValuesReachEnd = (nextPage: number) => {
    setPage(nextPage);
  };

  const getFilterValuesFor = ({ field, search }: { field: string; search: string }) => {
    if (!isFieldAvailable(field)) {
      return;
    }

    dispatch(getOrderItemsFilterValuesFor({ field, search }));
  };

  const getNextPageFilterValuesFor = ({
    field,
    search,
    page,
  }: {
    field: string;
    search: string;
    page: number;
  }) => {
    if (!isFieldAvailable(field)) {
      return;
    }

    dispatch(getOrderItemsNextPageFilterValuesFor({ field, search, page }));
  };

  useEffect(() => {
    getFilterValuesFor({ field, search });
    setPage(0);
    infiniteScrollContainerRef.current?.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getNextPageFilterValuesFor({ field, search, page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return {
    value,
    onChange,
    options: selected && options.length === 0 ? [selected] : options,
    onValuesReachEnd,
    infiniteScrollContainerRef,
    onToggle,
    noResults,
  };
};

const isFieldAvailable = (
  field: AddFilterFunctionParams['field'],
): field is OrderItemsDistinctValuesFindOptions['field'] => ['buyer', 'seller'].includes(field);
