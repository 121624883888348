import { useState } from 'react';

import { Badge } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { useOwnUser } from '@/context/own-user.context';
import { User } from '@/voggt-database-explorer/users/users';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { useSnackbar } from '../../../components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '../../../queries';

export const useRemoveBadgeFromUser = (userId: User['id']) => {
  const [selectedBadge, setSelectBadge] = useState<Badge | null>(null);

  const { hasPermissions } = useOwnUser();
  const ownUserIsAllowedToRemoveBadge = hasPermissions(
    PERMISSIONS.REMOVE_BADGE_FROM_USER.WRITE_REMOVE_BADGE_FROM_USER_UNIT,
  );

  const snackbar = useSnackbar();

  const { mutate: handleSubmit, isLoading } = useMutation({
    mutationFn: () => {
      if (!selectedBadge?.id) {
        return Promise.reject();
      }
      return authenticatedInstance.delete(
        `database-explorer/customer/${userId}/badge/${selectedBadge.id}`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.userDetail(userId));
      snackbar.success('databaseExplorer.users.badge.remove.success');
    },
    onError: () => {
      snackbar.error('SomethingWentWrong');
      snackbar.error('databaseExplorer.users.badge.remove.error');
    },
  });

  return {
    onClose: () => setSelectBadge(null),
    isLoading,
    handleSubmit,
    selectedBadge,
    setSelectBadge,
    ownUserIsAllowedToRemoveBadge,
  };
};
