import { useEffect, useMemo, useState } from 'react';

import { OrderEvent } from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { useIdParam } from '@/hooks/use-id-params';
import { getStripePaymentUrl } from '@/utils';
import { ContainerDataExplorer } from '@Elements/DataExplorer/ContainerDataExplorer';
import { DataList } from '@Elements/DataList';
import { CellDefinition } from '@Elements/DataList/datalist-type';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export const OrderEventsList = () => {
  const orderId = useIdParam();

  const cellOrderEventsDefinition = useMemo(
    (): CellDefinition<OrderEvent>[] => [
      {
        id: 'id',
        label: 'ID',
      },
      {
        id: 'orderId',
        label: 'Order Id',
        type: 'internal-link',
        getLink: (orderEvent) => ({
          link: `/order/${orderEvent.orderId}`,
          label: orderEvent.orderId,
        }),
      },
      {
        id: 'type',
        label: 'Type',
        type: 'string',
      },
      {
        id: 'statusEvent',
        label: 'Status event',
        type: 'badge',
      },
      {
        id: 'paymentEvent',
        label: 'Payment event',
        type: 'badge',
      },
      {
        id: 'shippingEvent',
        label: 'Shipping event',
        type: 'badge',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },
      {
        id: 'deletedAt',
        label: 'Deleted at',
        type: 'date-with-time',
      },
      {
        id: 'paymentIntentId',
        label: 'Payment intent ID',
        type: 'external-link',
        getLink: (orderEvent) => ({
          link: orderEvent.paymentIntentId ? getStripePaymentUrl(orderEvent.paymentIntentId) : '',
          label: orderEvent.paymentIntentId ?? '',
        }),
      },
      {
        id: 'paymentMethodIdealId',
        label: 'Payment method ideal',
        type: 'internal-link',
        getLink: (orderEvent) => ({
          link: orderEvent.paymentMethodIdealId
            ? `/payment-method/ideal/${orderEvent.paymentMethodIdealId}`
            : '',
          label: orderEvent.paymentMethodIdealId ?? '',
        }),
      },
      {
        id: 'paymentMethodPaypalId',
        label: 'Payment method paypal',
        type: 'internal-link',
        getLink: (orderEvent) => ({
          link: orderEvent.paymentMethodPaypalId
            ? `/payment-method/paypal/${orderEvent.paymentMethodPaypalId}`
            : '',
          label: orderEvent.paymentMethodPaypalId ?? '',
        }),
      },
      {
        id: 'paymentMethodBancontactId',
        label: 'Payment method bancontact',
        type: 'internal-link',
        getLink: (orderEvent) => ({
          link: orderEvent.paymentMethodBancontactId
            ? `/payment-method/bancontact/${orderEvent.paymentMethodBancontactId}`
            : '',
          label: orderEvent.paymentMethodBancontactId ?? '',
        }),
      },
      {
        id: 'paymentMethodCardId',
        label: 'Payment method card',
        type: 'internal-link',
        getLink: (orderEvent) => ({
          link: orderEvent.paymentMethodCardId
            ? `/payment-method/card/${orderEvent.paymentMethodCardId}`
            : '',
          label: orderEvent.paymentMethodCardId ?? '',
        }),
      },
    ],
    [],
  );

  const [data, setData] = useState([]);

  const [error, setError] = useState<string | null>(null);

  const snackbar = useSnackbar();

  useEffect(() => {
    const controller = new AbortController();
    authenticatedInstance
      .get(`/database-explorer/order/${orderId}/order-events`, { signal: controller.signal })
      .then((result) => {
        setData(result.data);
        setError(null);
      })
      .catch((error) => {
        if (error instanceof CanceledRequestError) {
          setError(null);
          return;
        }
        setError(error);
      });

    return () => controller.abort();
  }, [orderId]);

  useEffect(() => {
    if (error) {
      snackbar.error('error');
    }
  }, [error, snackbar]);

  return (
    <ContainerDataExplorer>
      <DataList<OrderEvent> cellDefinition={cellOrderEventsDefinition} results={data} />
    </ContainerDataExplorer>
  );
};
