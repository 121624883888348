import { CountryCodeIso2 } from '../country-code-iso2.enum';
import { Customer } from '../customer';
import { CustomerAttributes } from '../customer/customer.entity';

type SellerAttributes = CustomerAttributes & {
  paymentAccount: string;
  country: CountryCodeIso2;
};

export class Seller extends Customer {
  readonly paymentAccount: string;

  readonly country: CountryCodeIso2;

  constructor({ paymentAccount, country, ...attributes }: SellerAttributes) {
    super(attributes);

    this.paymentAccount = paymentAccount;
    this.country = country;
  }
}
