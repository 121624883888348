import { useMemo } from 'react';

import { Show, Channel } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';
import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { ItemLayout } from '@/voggt-database-explorer/layouts/ItemLayout';

import { Detail } from './Detail';
import { CellShowDefinition, useChannelDetailsView } from './useChannelDetailsView';

export const ChannelDetail = () => {
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();
  const channelId = useIdParam();
  const location = useLocation();
  const navigate = useNavigate();

  const { channel, refetchChannel, loading } = useChannelDetailsView(Number(channelId));

  const currentTab = useMemo(() => {
    return ['show', 'mux-assets'].find((path) => location.pathname.includes(path)) ?? '/';
  }, [location.pathname]);

  const handleOnTabChange = (tab: string) =>
    navigate(`/channel/${channelId}${tab === '/' ? '' : '/' + tab}`);

  const userCanEditChannel = hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_CHANNEL,
  );

  if (!channel) {
    return null;
  }

  const tabs = [
    {
      path: '/',
      label: t('databaseExplorer.channel.tabs.main'),
      Element: <Detail key="/" loading={loading} channel={channel} />,
    },
    {
      path: 'shows',
      label: 'Shows',
      Element: (
        <ListWithSearch<Show & { id: string }>
          title="Shows"
          key="show"
          cellDefinition={CellShowDefinition}
          defaultFilters={[{ field: 'channelId', values: [channelId] }]}
          typesenseHookParams={{
            typesenseIndex: 'show',
            typesenseParams: {
              per_page: 20,
              query_by: ['userId'],
            },
          }}
        />
      ),
    },
  ];

  return (
    <ItemLayout<Channel>
      id={channelId}
      description={`Created on ${new Date(channel.createdAt).toLocaleDateString()}`}
      title={`@${channel.user.username}'s channel`}
      onTabChange={handleOnTabChange}
      currentTab={currentTab}
      tabs={tabs}
      editionDescriptor={
        userCanEditChannel
          ? {
              goBackName: t('databaseExplorer.editionFormPath.shippingAddress', {
                channelId,
              }),
              resourceId: Number(channelId),
              resourceType: 'Channel',
              onSuccess: refetchChannel,
            }
          : undefined
      }
    />
  );
};
