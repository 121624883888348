import { AppThunkAction } from '@/redux/store';

import { selectAreAllRefundableOrderItemsInTheRefund } from '../redux/refund.selectors';
import { refundActions } from '../redux/refund.slice';

import { addAllRefundableOrderItemsInRefund } from './add-all-refundable-order-items-in-refund';

export const toggleAllRefundableOrderItems = (): AppThunkAction<void> => (dispatch, getState) => {
  const areAllRefundableOrderItemsInTheRefund =
    selectAreAllRefundableOrderItemsInTheRefund(getState());

  if (areAllRefundableOrderItemsInTheRefund) {
    dispatch(refundActions.removeAllFromRefund());
    return;
  }

  dispatch(addAllRefundableOrderItemsInRefund());
};
