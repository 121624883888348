import { AppThunkAction } from '@/redux/store';

import { selectPartnerPrograms } from '../partner-program.selector';
import { partnerProgramActions } from '../partner-program.slice';

export const listPartnerProgram =
  (): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { partnerProgramGateway, snackbarGateway }) => {
    dispatch(partnerProgramActions.setIsLoading(true));
    try {
      const allPartnerProgram = await partnerProgramGateway.getAll();
      const current = selectPartnerPrograms(getState());
      if (current !== allPartnerProgram) {
        dispatch(partnerProgramActions.setData(allPartnerProgram));
      }
    } catch (e) {
      console.log(e);
      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(partnerProgramActions.setIsLoading(false));
    }
  };
