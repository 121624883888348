import { ShippingAddress } from '@bits-app/bits-server-data';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { format } from 'date-fns';

import { TooltipWithTime } from '../date/TooltipWithTime';

type ShippingAddressPreviewProps = {
  shippingAddress: ShippingAddress;
};

export const ShippingAddressPreview = ({ shippingAddress }: ShippingAddressPreviewProps) => {
  if (!shippingAddress) return <p className="text-slate-300">-</p>;

  const formattedDate = shippingAddress.createdAt
    ? format(new Date(shippingAddress.createdAt), 'dd/MM/yyyy')
    : 'N/A';
  const nameDisplayed =
    shippingAddress.name || `${shippingAddress.firstName} ${shippingAddress.lastName}`;

  return (
    <div
      className="flex justify-between items-center cursor-pointer"
      onClick={() => handleCopyShippingAddress(shippingAddress)}
    >
      <div className="flex items-center">
        <LocationOnIcon />

        <div className="ml-2">
          <p className="font-semibold">{nameDisplayed}</p>
          <p className="text-sm text-slate-500">{shippingAddress.inputLine1}</p>
          <p className="text-sm text-slate-500">{shippingAddress.inputLine2}</p>
          <p className="text-sm text-slate-500">
            {shippingAddress.inputCity}, {shippingAddress.inputPostalCode},{' '}
            {shippingAddress.inputCountry}
          </p>
        </div>
      </div>

      <div>
        <TooltipWithTime date={shippingAddress.createdAt}>
          <p className="text-sm">Created {formattedDate}</p>
        </TooltipWithTime>
      </div>
    </div>
  );
};

function handleCopyShippingAddress(shippingAddress: ShippingAddress) {
  const text = [
    shippingAddress.name,
    shippingAddress.email,
    shippingAddress.phoneNumber,
    shippingAddress.inputLine1,
    shippingAddress.inputLine2,
    shippingAddress.inputCity,
    shippingAddress.inputPostalCode,
    shippingAddress.inputCountry,
  ]
    .map(valueOrFalse)
    .filter(Boolean)
    .join('');

  navigator.clipboard.writeText(text);
}

const valueOrFalse = (str?: unknown) => {
  if (!str) return false;
  return str + '\n';
};
