import { ShippingAddress, User } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { Information } from '@/components/layouts/PanelDetails';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { getStripeAccountUrl } from '@/utils';

import { OrderFlat, ShippingAddressWithOrders } from '../../shipping-address.type';

const fetchShippingAddress = async (shippingAddressId: ShippingAddress['id']) => {
  const { data } = await authenticatedInstance.get<ShippingAddressWithOrders>(
    `/database-explorer/shipping-address/${shippingAddressId}?orders=true`,
  );
  return data;
};

export const useDetailsView = (shippingAddressId: ShippingAddress['id']) => {
  const { isLoading, data: shippingAddress } = useQuery(
    QUERIES_KEYS.getShippingAddress(shippingAddressId),
    () => fetchShippingAddress(shippingAddressId),
  );

  const refetchShippingAddresses = () => {
    queryClient.invalidateQueries(QUERIES_KEYS.getShippingAddress(shippingAddressId));
  };

  const shippingAddressIsOnlyEditable = Boolean(
    shippingAddress?.orders?.every((order) => order?.status === 'pending'),
  );

  return {
    shippingAddress,
    loading: isLoading,
    refetchShippingAddresses,
    shippingAddressIsOnlyEditable,
  };
};

export const panelDetailsShippingAddress: Information<ShippingAddress>[] = [
  { label: 'id', value: 'id' },

  {
    label: 'Created at',
    value: 'createdAt',
    type: 'date-with-time',
  },
  {
    label: 'Updated at',
    value: 'updatedAt',
    type: 'date-with-time',
  },
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'firstName',
    label: 'First name',
  },
  {
    value: 'lastName',
    label: 'Last name',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'phoneNumber',
    label: 'Phone number',
  },
  { value: 'inputLine1', label: 'Input line 1' },
  { value: 'inputLine2', label: 'Input line 2' },
  { value: 'inputCity', label: 'City' },
  { value: 'inputPostalCode', label: 'Postal code' },
  { value: 'inputCountry', label: 'Input country' },
  {
    value: 'inputCountryCodeIso2',
    label: 'Country code iso 2',
  },
  {
    value: 'resolvedCountry',
    label: 'Resolved country name ',
    format: (shippingAddress) => shippingAddress?.resolvedCountry.name,
  },
];

export const panelDetailsUser: Information<User>[] = [
  { label: 'id', value: 'id' },
  { label: 'Username', value: 'username' },
  { value: 'firstName', label: 'Firstname' },
  { value: 'lastName', label: 'Lastname' },
  {
    value: 'stripeAccountId',
    label: 'Stripe Account ID',
    link: (user) => user.stripeAccountId && getStripeAccountUrl(user.stripeAccountId),
    type: 'external-link',
  },
];

export const CellOrderDefinition: CellDefinition<OrderFlat>[] = [
  {
    id: 'id',
    label: 'OrderId',
    type: 'internal-link',
    getLink: (order) => ({
      link: `/order/${order?.id}`,
      label: order?.id,
    }),
  },
  {
    id: 'status',
    label: 'Status',
    type: 'badge',
  },
  {
    id: 'paymentStatus',
    label: 'Payment status',
    type: 'badge',
  },
  {
    id: 'showId',
    label: 'Show ID',
    type: 'internal-link',
    enableColumnFilter: false,
    getLink: (order) => ({ label: order?.showId, link: `/show/${order.showId}` }),
  },
  {
    id: 'product',
    label: 'Product name',
    type: 'internal-link',
    enableColumnFilter: false,
    getLink: (order) => ({ label: order?.product.name, link: `/product/${order?.productId}` }),
  },
  {
    id: 'sellerId',
    label: 'Seller',
    type: 'internal-link',
    enableColumnFilter: false,
    getLink: (order) => ({ link: `/user/${order.sellerId}`, label: order?.seller?.username }),
  },

  {
    id: 'createdAt',
    label: 'Purchased at',
    type: 'date-with-time',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
  },
];
