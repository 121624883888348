import { authenticatedInstance } from '@/axios/axios.instance';

import { Emote } from '../../entity/emote.entity';

export const editEmote = async (formData: FormData, emoteId: Emote['id']) => {
  const result = await authenticatedInstance.patch<Emote>(
    `/database-explorer/emote/${emoteId}`,
    formData,
    {
      headers: {
        ['Content-Type']: 'multipart/form-data',
      },
    },
  );

  return result.data;
};
