import { MarketPageType } from '@bits-app/voggtpit-shared';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UpsertForm } from '../../voggt-database-explorer/components/upsert-view/UpsertForm';
import { useCreateMarketPageForm } from '../hooks/use-create-market-page-form.hook';

export const MarketPageCreationView = () => {
  const { t } = useTranslation();
  const { onCreate, header, schema, isLoading } = useCreateMarketPageForm();

  if (!schema || !header) {
    return <CircularProgress />;
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <UpsertForm<MarketPageType>
      schemas={schema}
      header={header}
      onUpsert={onCreate}
      goBackName={t('marketPage.detail.goBackToList')}
    />
  );
};
