import { authActions } from '@/auth/auth.slice';
import { BadRequestError } from '@/domain/errors/BadRequestError';
import { NotFoundError } from '@/domain/errors/NotFoundError';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { AppThunkAction } from '@/redux/store';

import { refundActions } from '../redux/refund.slice';

export const startRefundOrder =
  (orderId: string): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { refundGateway, snackbarGateway }) => {
    dispatch(refundActions.setRefundLoading(true));

    try {
      const orderItems: OrderItemRefund[] = await refundGateway.getOrder(orderId);

      dispatch(refundActions.setMainOrderItemId(orderItems[0].id));
      dispatch(refundActions.setEntities(orderItems));
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      dispatch(refundActions.setRefundError(String(error)));

      if (error instanceof NotFoundError) {
        snackbarGateway.error('refund.orderNotFound', { interpolationMap: { id: orderId } });
        return;
      }

      if (error instanceof BadRequestError) {
        snackbarGateway.error('refund.malformedId', {
          interpolationMap: { id: orderId },
        });
        return;
      }

      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(refundActions.setRefundLoading(false));
    }
  };
