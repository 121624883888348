import { useCallback } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { ButtonOutlined } from '@Elements/buttons/ButtonOutlined';

export const CreateSellerNewsButton = () => {
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();
  const isReadOnly = !hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_SELLER_NEWS,
  );
  const navigate = useNavigate();

  const redirectToCreateForm = useCallback(() => navigate('/seller-news/create'), [navigate]);

  if (isReadOnly) {
    return null;
  }

  return (
    <ButtonOutlined onClick={redirectToCreateForm}>
      {t('databaseExplorer.sellerNews.create')}
    </ButtonOutlined>
  );
};
