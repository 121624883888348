import { useMemo } from 'react';

import { BlockedShippingAddress } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

export const useCellDefinition = () => {
  const CellBlockedShippingAddressDefinition = useMemo(
    (): CellDefinition<BlockedShippingAddress>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (blockedShippingAddress) => ({
          link: `/blocked-shipping-address/${blockedShippingAddress.id}`,
          label: blockedShippingAddress?.id,
        }),
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date',
      },
      {
        id: 'name',
        label: 'name',
      },
      {
        id: 'line1',
        label: 'Line 1',
      },
      {
        id: 'line2',
        label: 'Line 2',
      },
      {
        id: 'postalCode',
        label: 'Postal code',
      },
      {
        id: 'city',
        label: 'City',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
        enableSorting: true,
      },
    ],
    [],
  );

  return { CellBlockedShippingAddressDefinition };
};
