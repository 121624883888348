import { Show } from '@bits-app/bits-server-data';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonOutlined } from '@Elements/buttons/ButtonOutlined';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

type StressTestShowModalProps = {
  showId: Show['id'];
  onCancel: () => void;
  isLoading: boolean;
  onConfirm: (id: Show['id']) => void;
};

export const StressTestShowModal = ({
  showId,
  onCancel,
  isLoading,
  onConfirm,
}: StressTestShowModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="common.black"
      >
        {t('databaseExplorer.stressTests.modal.title', { showId: showId })}

        <IconButton size="small" color="inherit" onClick={onCancel}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography>
          {t('databaseExplorer.stressTests.modal.content', {
            showId: showId,
          })}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          px: ({ spacing }) => spacing(3),
          paddingBottom: ({ spacing }) => spacing(2),
        }}
      >
        <ButtonOutlined onClick={onCancel} disabled={isLoading}>
          {t('cancel')}
        </ButtonOutlined>

        <LoadingButton
          variant="contained"
          color="error"
          onClick={() => onConfirm(showId)}
          loading={isLoading}
          disabled={isLoading}
        >
          {t('databaseExplorer.stressTests.modal.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
