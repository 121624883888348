import { createSelector } from '@reduxjs/toolkit';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { AppThunkAction } from '@/redux/store';

import { EntityWrapper, ResourceType } from '../database-explorer';
import { databaseExplorerAction, resourceSelector } from '../database-explorer.slice';

export const getEditionSchema =
  (resourceId: EntityWrapper['id'], resourceType: ResourceType): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { snackbarGateway, databaseExplorerGateway }) => {
    dispatch(databaseExplorerAction.setIsLoading(true));
    dispatch(databaseExplorerAction.setEditionSchema(null));

    try {
      const editionSchema = await databaseExplorerGateway.getEditionSchema(
        resourceId,
        resourceType,
      );

      dispatch(databaseExplorerAction.setEditionSchema(editionSchema));
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));
        return;
      }
      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(databaseExplorerAction.setIsLoading(false));
    }
  };

export const selectEditionSchema = createSelector(resourceSelector, (state) => state.editionSchema);
