import { BlockedShippingAddress } from '@bits-app/bits-server-data';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';

import { useCellDefinition } from '../../use-cell-definition';

const TYPESENSE_MAX_RESULT_PER_PAGE = 30;

export const ListBlockedShippingAddress = () => {
  const { CellBlockedShippingAddressDefinition } = useCellDefinition();

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListWithSearch<BlockedShippingAddress & { id: string }>
        title="Blocked Shipping Address"
        cellDefinition={CellBlockedShippingAddressDefinition}
        typesenseHookParams={{
          typesenseIndex: 'blocked_shipping_address',
          sortBy: 'updatedAt_Unix:desc',
          typesenseParams: {
            per_page: TYPESENSE_MAX_RESULT_PER_PAGE,
            query_by: ['line1', 'city', 'postalCode'],
          },
        }}
        dataListProps={{
          getPathDetail: (blockedShippingAddress) =>
            `/blocked-shipping-address/${blockedShippingAddress.id}`,
        }}
      />
    </div>
  );
};
