import { Alert, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

type WarningUnsavedDialogProps = {
  title: string;
  content: string;
  onClose: () => void;
  onConfirm: () => void;
};

export const WarningUnsavedDialog = ({
  title,
  content,
  onClose,
  onConfirm,
}: WarningUnsavedDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <Alert severity="warning" sx={{ display: 'flex', alignItems: 'center' }} onClose={onClose}>
        {title}
      </Alert>

      <DialogContent>{content}</DialogContent>

      <DialogActions
        sx={{
          padding: ({ spacing }) => spacing(3),
        }}
      >
        <Button color="error" onClick={onClose}>
          {t('cancel')}
        </Button>

        <Button variant="contained" onClick={onConfirm}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
