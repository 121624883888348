import { LedgerPayoutList } from '@bits-app/voggtpit-shared';

import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';
import { DateTimePicker } from '@/components/elements/inputs/DateTimePicker';

import { ListCustom } from '../../components/elements/DataExplorer/ListCustom';

import { EditPayout } from './components/EditPayout';
import { LedgerPayoutListCellDefinition } from './ledger.const';
import { useLedgerList } from './useLedgerList';

export const LedgerPayoutView = () => {
  const {
    results,
    handleTriggerPayout,
    isLoading,
    onPaginationChange,
    totalCount,
    setEndAt,
    setStartAt,
    startAt,
    endAt,
    refetch,
  } = useLedgerList();
  return (
    <div className={'p-2 flex flex-col gap-4'}>
      <div className="flex gap-2 bg-white p-4 rounded-xl items-center">
        <p>Order created between</p>

        <DateTimePicker date={startAt} onDateChange={setStartAt} />

        <p>and</p>
        <DateTimePicker date={endAt} onDateChange={setEndAt} />
        <ButtonOutlined onClick={() => refetch()}>Search</ButtonOutlined>
      </div>
      <div>
        <ListCustom<LedgerPayoutList[0]>
          cellDefinition={LedgerPayoutListCellDefinition}
          results={results}
          title="Ledger payout list"
          enableRowSelection={(row) => row.original.availableBalance !== 0}
          customSelectedRowsAction={(rows) => (
            <EditPayout
              sellerIds={rows.map(({ original: { id } }) => Number(id))}
              handleTriggerPayout={handleTriggerPayout}
              isLoading={isLoading}
            />
          )}
          showGlobalFilter={false}
          paginationAPI={{
            onPaginationChange,
            defaultResultPerPage: 100,
            rowCount: totalCount,
          }}
        />
      </div>
    </div>
  );
};
