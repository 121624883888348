import { useCallback, useEffect, useMemo, useState } from 'react';

import { SellerBuyerFilterValue } from '@bits-app/voggtpit-shared';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUnsavedWarning } from '@/context/unsaved.context';
import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { FilterButton } from '@Elements/FilterButton';

import { UsernameFilter } from './UsernameFilter';

type SellerBuyerIntersectionFilterProps = {
  filter?: SellerBuyerFilterValue;
  addFilter: (filter: AddFilterFunctionParams) => void;
  disabled: boolean;
  isUnsaved?: boolean;
};

export const SellerBuyerIntersectionFilter = ({
  filter,
  addFilter,
  disabled,
  isUnsaved = false,
}: SellerBuyerIntersectionFilterProps) => {
  const { t } = useTranslation();

  const onConfirmAfterWarning = useUnsavedWarning();

  const [seller, setSeller] = useState('');
  const [buyer, setBuyer] = useState('');

  const onApplyFilter = () => {
    const values = seller && buyer ? { seller, buyer } : [];

    if (isUnsaved) {
      onConfirmAfterWarning(() => addFilter({ field: 'sellerBuyerIntersection', values }));
      return;
    }

    addFilter({
      field: 'sellerBuyerIntersection',
      values,
    });
  };

  const onClearFilter = useCallback(() => {
    setSeller('');
    setBuyer('');
  }, []);

  const setToActiveFilterValue = useCallback(() => {
    if (filter) {
      setSeller(filter.seller);
      setBuyer(filter.buyer);

      return;
    }

    onClearFilter();
  }, [filter, onClearFilter]);

  useEffect(() => {
    setToActiveFilterValue();
  }, [filter, setToActiveFilterValue]);

  const isApplyButtonDisabled = useMemo(() => {
    if (filter) {
      if (!seller && !buyer) {
        return false;
      } else if (!seller || !buyer) {
        return true;
      }

      return filter.seller === seller && filter.buyer === buyer;
    }

    if (filter && !buyer && !seller) {
      return true;
    }

    return !(seller && buyer);
  }, [buyer, filter, seller]);

  const onClose = () => {
    if (!filter) {
      onClearFilter();
    }
  };

  const label = useMemo(() => {
    if (!filter || !seller || !buyer) {
      return t('orderItemsMassUpdate.searchFilter.sellerBuyerIntersection');
    }

    return `${seller} - ${buyer}`;
  }, [filter, t, seller, buyer]);

  return (
    <FilterButton
      isActive={false}
      onClose={onClose}
      unSaved={!isApplyButtonDisabled}
      CustomButton={
        <Button variant="outlined" size="small">
          <Box
            title={filter ? label : undefined}
            sx={{
              maxWidth: 250,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {label}
          </Box>
        </Button>
      }
      maxWidth="100%"
      disabled={disabled}
    >
      <Box display="flex" gap={1} flexWrap="nowrap">
        <UsernameFilter
          field="seller"
          inputPlaceholder={t('orderItemsMassUpdate.searchFilter.seller')}
          selected={seller}
          setSelected={setSeller}
        />
        <UsernameFilter
          field="buyer"
          inputPlaceholder={t('orderItemsMassUpdate.searchFilter.buyer')}
          selected={buyer}
          setSelected={setBuyer}
        />
      </Box>

      <Box display="flex" justifyContent="space-between" p={1}>
        <Button onClick={onClearFilter} startIcon={<ClearIcon />} variant="outlined">
          {t('clearFilters')}
        </Button>

        <Button
          onClick={onApplyFilter}
          startIcon={<CheckIcon />}
          variant="contained"
          disabled={isApplyButtonDisabled}
        >
          {t('applyFilters')}
        </Button>
      </Box>
    </FilterButton>
  );
};

export type UsernameFilterProps = {
  inputPlaceholder: string;
  field: string;
  selected: string;
  setSelected: (value: string) => void;
};
