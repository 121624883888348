import * as yup from 'yup';

import { SchemaValidation } from './validation-schema';

export class ShippingFeeConfigValidationSchema extends SchemaValidation {
  availableResolver = {
    fixedFee: this.resolveFixedFee(),
    intervalFee: this.resolveIntervalFee(),
    intervalCount: this.resolveIntervalCount(),
    shippingCategory: this.resolveShippingCategory(),
    destinationCountryId: this.resolveDestinationCountryId(),
    shippingCountryId: this.resolveShippingCountryId(),
  };

  resolveFixedFee(): yup.ISchema<any> {
    return yup.number().min(0).integer().required();
  }

  resolveIntervalFee(): yup.ISchema<any> {
    return yup.number().min(0).integer().required();
  }

  resolveIntervalCount(): yup.ISchema<any> {
    return yup.number().min(0).integer().nullable().optional();
  }

  resolveShippingCategory(): yup.ISchema<any> {
    return yup.string().required();
  }

  resolveDestinationCountryId(): yup.ISchema<any> {
    return yup.string().required();
  }

  resolveShippingCountryId(): yup.ISchema<any> {
    return yup.string().required();
  }
}
