import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '@Elements/buttons/ButtonContained';

import { User } from '../../../users';

type ToggleBanUserProps = {
  user: User;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
};

export const ToggleBanUser = ({ user, onConfirm, onCancel, isOpen }: ToggleBanUserProps) => {
  const { t } = useTranslation();

  const translationKey = user.isBanned
    ? 'databaseExplorer.users.unban'
    : 'databaseExplorer.users.ban';

  return (
    <Dialog open={isOpen}>
      <DialogTitle id="alert-dialog-title">{t(`${translationKey}.action`)}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t(`${translationKey}.description`, { username: user.username })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <ButtonContained onClick={onConfirm}>{t(`${translationKey}.confirm`)}</ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
