import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const PageNotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col flex-1 h-full justify-center items-center">
      <div>
        <div>
          <h2 className="text-2xl font-semibold">{t('404.title')}</h2>
          <p className="mb-2">{t('404.content')}</p>
        </div>

        <button
          className="text-sm text-blue-500 hover:text-blue-600 hover:underline"
          onClick={() => navigate('/')}
        >
          {t('404.back')}
        </button>
      </div>
    </div>
  );
};
