import { authenticatedInstance } from '@/axios/axios.instance';

export const updateCategoryTranslations = async (
  categoryName: string,
  translations: Record<string, string>,
) => {
  await authenticatedInstance.patch<void>(
    `/database-explorer/category/${categoryName}/translations`,
    {
      translations,
    },
  );
};
