import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
} from 'typeorm';

import { Currency } from '../models/currency';

import { Order } from './Order';
import { Product } from './Product';
import { Shipment } from './Shipment';

@Entity()
export class OrderProduct {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @ManyToOne(() => Order)
  @JoinColumn({ name: 'orderId' })
  order!: Order;

  @Index()
  @Column()
  orderId!: Order['id'];

  @ManyToOne(() => Product)
  @JoinColumn({ name: 'productId' })
  product!: Product;

  @Index()
  @Column()
  productId!: Product['id'];

  @ManyToOne(() => Shipment)
  @JoinColumn({ name: 'shipmentId' })
  shipment!: Shipment | null;

  @Index()
  @Column({ nullable: true, type: 'uuid' })
  shipmentId!: Shipment['id'] | null;

  @Column()
  productAmount!: number;

  @Column({
    type: 'enum',
    enum: Currency,
    default: Currency.eur,
    enumName: 'currency_enum',
  })
  currency!: Currency;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;
}
