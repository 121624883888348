import { Badge } from '@bits-app/bits-server-data';

import { authenticatedInstance } from '@/axios/axios.instance';

export const getBadgeRelatedUsersCount = async (badgeId: Badge['id']) => {
  const response = await authenticatedInstance.get(
    `/database-explorer/badge/${badgeId}/related-users-count`,
  );

  return response.data;
};
