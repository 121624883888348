/* eslint-disable max-classes-per-file */
import {
  ChildEntity,
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  TableInheritance,
} from 'typeorm';

import { User } from './User';

export enum RatingType {
  shipment = 'shipment',
  show_seller = 'show_seller',
}

@Entity()
@TableInheritance({
  column: {
    type: 'enum',
    name: 'type',
    enum: RatingType,
  },
})
export class Rating {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column()
  rating!: number;

  // TODO: remove default once in production
  @Column({ type: 'enum', enum: RatingType, default: RatingType.shipment })
  type!: RatingType;

  @Column({ nullable: true, default: null, type: 'character varying' })
  comment!: string | null;

  @Index()
  @Column()
  userId!: number;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'userId' })
  user!: User;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;
}
@ChildEntity(RatingType.shipment)
export class ShipmentRating extends Rating {
  @Column({ default: false })
  isPublic!: boolean;

  @Column({ default: false })
  isModerated!: boolean;
}
@ChildEntity(RatingType.show_seller)
export class ShowSellerRating extends Rating {}
