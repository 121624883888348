import { AppThunkAction } from '@/redux/store';

import { ActionCommentType, BackendCommentResponse } from '../comments.type';

export const createComment =
  ({
    comment,
    entityId,
    entityName,
  }: ActionCommentType['createComment']): AppThunkAction<Promise<BackendCommentResponse>> =>
  async (_dispatch, _getState, { commentGateway }) => {
    try {
      await commentGateway.createComment(comment);
    } catch (error) {
      console.error(error);
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return await commentGateway.getComments(entityName, entityId);
    }
  };

export const deleteComment =
  ({
    commentId,
    entityName,
    entityId,
  }: ActionCommentType['deleteComment']): AppThunkAction<Promise<BackendCommentResponse>> =>
  async (_dispatch, _getState, { commentGateway }) => {
    try {
      await commentGateway.deleteComment(commentId);
    } catch (error) {
      console.error(error);
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return await commentGateway.getComments(entityName, entityId);
    }
  };

export const getComment =
  ({
    entityId,
    entityName,
    signal,
  }: ActionCommentType['getComment']): AppThunkAction<Promise<BackendCommentResponse>> =>
  async (_dispatch, _getState, { commentGateway }) => {
    try {
      return await commentGateway.getComments(entityName, entityId, signal);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

export const CommentsAction = {
  createComment,
  deleteComment,
  getComment,
};
