import { useCallback } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonContained } from '@/components/elements/buttons/ButtonContained';
import { useIdParam } from '@/hooks/use-id-params';

import { useHardBlockUser } from './use-hard-block-user';

export const HardBlockUser = () => {
  const userId = useIdParam();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToUserProfile = useCallback(() => navigate(`/user/${userId}`), [userId, navigate]);

  const { hardBlockUser, shouldDisplayHardBlockFunctionality, isLoading } =
    useHardBlockUser(+userId);

  return (
    <Dialog open>
      <DialogTitle id="alert-dialog-title">
        {t('databaseExplorer.users.hardBlock.title', { userId })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText lineHeight={1.25} whiteSpace={'pre-line'}>
          {t('databaseExplorer.users.hardBlock.description', { joinArrays: '\n\n' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={navigateToUserProfile}>{t('cancel')}</Button>
        <ButtonContained
          onClick={() => hardBlockUser()}
          color={'error'}
          disabled={!shouldDisplayHardBlockFunctionality || isLoading}
        >
          {t('databaseExplorer.users.hardBlock.confirm')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
