import { OrderStatus } from '@bits-app/voggtpit-shared';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { EnumFilterTable } from '@Elements/filters/table';

const ORDER_STATUS = Object.values(OrderStatus) as OrderStatus[];

type ShippingStatusFilterProps = {
  activeFilters?: OrderStatus[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
};

export const ShippingStatusFilter = ({
  addFilter,
  activeFilters = [],
  isUnsaved,
}: ShippingStatusFilterProps) => {
  return (
    <EnumFilterTable
      options={ORDER_STATUS.map((value) => ({ value }))}
      addFilter={addFilter}
      field="shippingStatus"
      activeFilters={activeFilters}
      isUnsaved={isUnsaved}
    />
  );
};
