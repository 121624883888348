import { DateFilterValue } from '@bits-app/voggtpit-shared';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { DateTimeFilter, useDateTimeFilter } from '@Elements/filters/DateTimeFilter';

import { FilterButton } from '../../../FilterButton';

type DateTimeFilterProps = {
  field: AddFilterFunctionParams['field'];
  activeFilters?: DateFilterValue;
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved?: boolean;
};

export const DateTimeFilterTable = ({
  field,
  activeFilters,
  addFilter,
  isUnsaved = false,
}: DateTimeFilterProps) => {
  const { t } = useTranslation();

  const {
    isApplyButtonDisabled,
    applyFilter,
    onClearFilter,
    setToActiveFilterValue,
    ...dateTimeFilterProps
  } = useDateTimeFilter({ field, activeFilters, addFilter, isUnsaved });

  return (
    <FilterButton
      isActive={Boolean(activeFilters)}
      onClose={setToActiveFilterValue}
      unSaved={!isApplyButtonDisabled}
      maxWidth="auto"
    >
      <DateTimeFilter {...dateTimeFilterProps} />

      <Box display="flex" justifyContent="space-between" p={1}>
        <Button onClick={onClearFilter} startIcon={<ClearIcon />} variant="outlined">
          {t('clearFilters')}
        </Button>

        <Button
          onClick={applyFilter}
          startIcon={<CheckIcon />}
          variant="contained"
          disabled={isApplyButtonDisabled}
        >
          {t('applyFilters')}
        </Button>
      </Box>
    </FilterButton>
  );
};
