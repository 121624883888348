import { useEffect } from 'react';

import { Category, Show, ParentCategory } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { Information } from '@/components/layouts/PanelDetails';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { selectResourceLoading } from '@/voggt-database-explorer/redux/database-explorer.slice';
import {
  getResource,
  selectResource,
  useResourceSelector,
} from '@/voggt-database-explorer/redux/use-cases/get-resource';

export const useCategoryDetailsView = (
  categoryId: string,
  entityType: 'Category' | 'Parent-Category',
) => {
  const dispatch = useAppDispatch();

  const category = useResourceSelector<Category | ParentCategory>(selectResource);
  const loading = useAppSelector(selectResourceLoading);

  useEffect(() => {
    if (categoryId && category.id !== Number(categoryId)) {
      dispatch(getResource(+categoryId, entityType));
    }
  }, [categoryId, category.id, dispatch, entityType]);

  return {
    category,
    loading,
  };
};

export const panelDetailsCategory: Information<Category>[] = [
  { value: 'id', label: 'id' },
  { value: 'name', label: 'Name' },
  { value: 'description', label: 'Description' },
  { value: 'colorAccent', label: 'Color accent' },
  { value: 'isVisible', label: 'Is visible', type: 'boolean' },
  { value: 'coverUrl', label: 'Cover url' },
  { value: 'emotes', label: 'Emotes' },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
];

export const panelDetailsParentCategory: Information<ParentCategory>[] = [
  { value: 'id', label: 'id' },
  { value: 'name', label: 'Name' },
  { value: 'positionIndex', label: 'position index' },
  { value: 'primaryColor', label: 'primary color' },
  { value: 'secondaryColor', label: 'secondary color' },
];

export const CellShowDefinition: CellDefinition<Show>[] = [
  {
    id: 'id',
    label: 'id',
    type: 'internal-link',
    getLink: (show) => ({ label: show.id, link: `/show/${show.id}` }),
  },
  {
    id: 'userId',
    label: 'User',
    type: 'internal-link',
    getLink: (show) => ({
      label: show.user as unknown as string,
      link: `/user/${show.userId}`,
    }),
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'startAt',
    label: 'Start at',
    type: 'date-with-time',
  },
  {
    id: 'endedAt',
    label: 'End at',
    type: 'date-with-time',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date-with-time',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
  },
];
