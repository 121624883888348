import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToMany,
  OneToOne,
  PrimaryGeneratedColumn,
} from 'typeorm';

import { FeaturedShowConcept } from './FeaturedShowConcept';
import { Show } from './Show';

export enum FeaturedShowApplicationStatus {
  pending = 'pending',
  approved = 'approved',
  refused = 'refused',
}

export enum FeaturedShowApplicationDecisionReason {
  great_items = 'great_items',
  great_track_record = 'great_track_record',
  loyal_seller = 'loyal_seller',
  not_enough_items = 'not_enough_items',
  not_qualitative_enough = 'not_qualitative_enough',
  not_experienced_enough = 'not_experienced_enough',
  full_calendar = 'full_calendar',
}

@Entity()
export class FeaturedShowApplication {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @OneToOne(() => Show)
  @JoinColumn({ name: 'showId' })
  show!: Show;

  @Column()
  showId!: Show['id'];

  @Column({
    type: 'enum',
    enum: FeaturedShowApplicationStatus,
    default: FeaturedShowApplicationStatus.pending,
  })
  status!: FeaturedShowApplicationStatus;

  @Column({
    nullable: true,
    type: 'enum',
    enum: FeaturedShowApplicationDecisionReason,
  })
  decisionReason!: FeaturedShowApplicationDecisionReason | null;

  @Column()
  targetedGMV!: number;

  @ManyToMany(
    () => FeaturedShowConcept,
    (concept) => concept.featuredShowApplications,
    {
      cascade: true,
    },
  )
  concepts!: FeaturedShowConcept[];

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;
}
