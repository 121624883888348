import { useCallback, useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';
import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { FieldError } from '@/domain/errors/FieldError';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { useAppDispatch } from '@/redux/reduxAppHooks';
import { useImageUpload } from '@/voggt-database-explorer/components/ImageUpload/use-image-upload.hook';
import { Emote } from '@/voggt-database-explorer/emote/entity/emote.entity';
import { databaseExplorerAction } from '@/voggt-database-explorer/redux/database-explorer.slice';

import { EmoteFieldValue } from '../../../components/upsert-emote/UpsertEmoteForm';
import { createEmote } from '../../../components/upsert-emote/create-emote';
import { editEmote } from '../../../components/upsert-emote/edit-emote';

export const useUpsertEmoteForm = ({
  emote,
  setFieldError,
  resetForm,
  afterSuccess,
}: {
  emote?: Emote;
  resetForm: () => void;
  setFieldError: UseFormSetError<EmoteFieldValue>;
  afterSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const dispatch = useAppDispatch();

  const upsertType = emote ? 'update' : 'create';

  const { clearImage, image, imageErrors, onDropImage, setImageErrors } = useImageUpload('100Kb');

  useEffect(() => {
    return () => {
      if (!image) {
        return;
      }

      URL.revokeObjectURL(image.preview);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetAllForm = () => {
    clearImage();
    setImageErrors(null);
    resetForm();
  };

  const onSubmit = async (data: EmoteFieldValue) => {
    if (!emote && !image) {
      setImageErrors((errors) => [
        ...(errors ?? []),
        {
          code: 'preview-is-required',
          message: t(`databaseExplorer.category.upsertEmote.form.errors.required`, {
            field: t(`databaseExplorer.category.upsertEmote.form.image`),
          }),
        },
      ]);

      return;
    }

    const formData = new FormData();

    if (image) {
      formData.append('file', image);
    }

    formData.append('name', data.name);

    formData.append('isGlobal', JSON.stringify(data.isGlobal));

    formData.append('categoryIds', JSON.stringify(data.categories));

    await mutate(formData);
  };

  const upsertEmote = useCallback(
    async (formData: FormData) => {
      if (!emote) {
        return createEmote(formData);
      }

      return editEmote(formData, emote.id);
    },
    [emote],
  );

  const { mutate, isLoading } = useMutation<Emote, unknown, FormData>(upsertEmote, {
    onSuccess: (upsertedEmote) => {
      snackbar.success(`databaseExplorer.emote.upsertEmote.${upsertType}.success`, {
        interpolationMap: { name: upsertedEmote.name },
      });

      if (!emote) {
        queryClient.invalidateQueries(QUERIES_KEYS.emotesList());
      } else {
        dispatch(databaseExplorerAction.setData(upsertedEmote));
      }

      resetAllForm();
      afterSuccess();
    },
    onError: (error) => {
      snackbar.error(`databaseExplorer.emote.upsertEmote.form.errors.generic`);

      if (error instanceof FieldError) {
        switch (error.field) {
          case 'categories':
          case 'name':
            setFieldError(error.field, { message: error.message });
            break;

          case 'image':
            setImageErrors((errors) => [
              ...(errors ?? []),
              {
                code: 'server-error',
                message: error.message,
              },
            ]);
            break;
        }
      }
    },
  });

  return {
    isLoading,
    image,
    imageErrors,
    onDropImage,
    onSubmit,
    resetAllForm,
  };
};
