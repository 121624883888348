import { Product } from '@bits-app/bits-server-data';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';

import { useCellProductDefinition } from '../../use-product-cell-definition';

const TYPESENSE_MAX_RESULT_PER_PAGE = 30;

export const ListProduct = () => {
  const cellDefinition = useCellProductDefinition();

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListWithSearch<Product & { id: string; seller: string; show: string }>
        title="Products"
        cellDefinition={cellDefinition}
        typesenseHookParams={{
          typesenseIndex: 'product',
          typesenseParams: {
            per_page: TYPESENSE_MAX_RESULT_PER_PAGE,
            query_by: ['name', 'seller'],
          },
        }}
        dataListProps={{
          enableFilters: true,
        }}
      />
    </div>
  );
};
