import { useEffect, useReducer } from 'react';

import { Currency, Promotion } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { useOwnUser } from '@/context/own-user.context';
import { useTableOptions } from '@/hooks/use-table-options';
import { selectPromotionById } from '@/promotions/redux/promotions-selector';
import { getPromotions } from '@/promotions/use-cases/get-promotions';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import { ActionReducer, ReducerState, UpdateState } from './use-edit-promotion.type';

export const useEditPromotion = () => {
  const { hasPermissions } = useOwnUser();
  const userCanUseFeature = hasPermissions(PERMISSIONS.PROMOTION.WRITE_PROMOTION);
  const { options } = useTableOptions();
  const snackbar = useSnackbar();
  const reduxDispatch = useAppDispatch();
  const [state, dispatch] = useReducer(manageEditionReducer, initialState);

  const toggleModal = (promotionId?: Promotion['id']) => {
    if (!userCanUseFeature) return;
    dispatch({ type: 'toggleModal', payload: { promotionId: promotionId } });
  };

  const updateState = (payload: UpdateState['payload']) =>
    dispatch({ type: 'updateState', payload });
  const populateState = (payload: Promotion) => dispatch({ type: 'populateState', payload });
  const toggleIsLoading = () => dispatch({ type: 'toggleLoading' });
  const promotion = useAppSelector((reduxState) =>
    selectPromotionById(reduxState, state.selectedPromotionId),
  );

  const handleSubmit = async () => {
    if (!state.selectedPromotionId || !userCanUseFeature) return;
    toggleIsLoading();
    try {
      await authenticatedInstance.patch(`promotions/${state.selectedPromotionId}`, {
        name: state.name,
        currency: state.currency,
        expireAt: state.expireAt,
        promotionAmount: state.promotionAmount,
        promotionMaxAmount: state.promotionMaxAmount,
        promotionPercentage: state.promotionPercentage,
        id: state.selectedPromotionId,
      });
      snackbar.success('answer.update-success');
      reduxDispatch(getPromotions({ currency: state.currency, ...options }));
      toggleModal();
    } catch (e) {
      snackbar.error('somethingWrongHappened');
    } finally {
      toggleIsLoading();
    }
  };

  useEffect(() => {
    if (promotion) {
      populateState(promotion as unknown as Promotion);
    }
  }, [promotion]);

  return {
    ...state,
    toggleModal,
    updateState,
    toggleIsLoading,
    handleSubmit,
  };
};

const initialState: ReducerState = {
  selectedPromotionId: null,
  name: '',
  promotionAmount: 0,
  promotionMaxAmount: 0,
  promotionPercentage: 0,
  modalIsOpen: false,
  currency: Currency.eur,
  expireAt: new Date(),
  isLoading: false,
};

const manageEditionReducer = (state: ReducerState, action: ActionReducer) => {
  switch (action.type) {
    case 'reset': {
      return initialState;
    }
    case 'toggleModal': {
      if (!action.payload.promotionId) {
        return initialState;
      }
      return {
        ...state,
        modalIsOpen: !state.modalIsOpen,
        selectedPromotionId: action.payload.promotionId,
      };
    }
    case 'populateState': {
      const { name, promotionAmount, promotionMaxAmount, promotionPercentage, expireAt, currency } =
        action.payload;
      return {
        ...initialState,
        modalIsOpen: true,
        name,
        promotionAmount,
        promotionMaxAmount,
        promotionPercentage,
        selectedPromotionId: state.selectedPromotionId,
        expireAt,
        currency,
      };
    }

    case 'updateState': {
      return { ...state, [action.payload.key]: action.payload.value };
    }
    case 'toggleLoading': {
      return { ...state, isLoading: !state.isLoading };
    }
    default:
      return state;
  }
};
