import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

export const useNavigateInDetailTab = (baseUrl: string) => {
  const navigate = useNavigate();

  return useCallback(
    (tab: string) => navigate(`${baseUrl}${tab === '/' ? '' : '/' + tab}`),
    [baseUrl, navigate],
  );
};
