import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { authActions } from '../auth.slice';

export const getMe =
  (): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { authGateway }) => {
    dispatch(authActions.setOwnUserLoading(true));

    try {
      const ownUser = await authGateway.getMe();

      dispatch(authActions.setOwnUser(ownUser));
    } catch (error) {
      logger.error(error);
    } finally {
      dispatch(authActions.setOwnUserLoading(false));
    }
  };
