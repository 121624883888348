import { CommercialEvent } from '@bits-app/bits-server-data';

import { CellDefinition } from '../../../components/elements/DataList';

export const cellCommercialEventsDefinition: CellDefinition<CommercialEvent>[] = [
  {
    id: 'id',
    label: 'ID',
    type: 'internal-link',
    getLink: (commercialEvent) => ({
      link: `/commercial-event/${commercialEvent?.id}`,
      label: `${commercialEvent.id}`,
    }),
  },
  { id: 'internalName', label: 'Internal Name' },
  { id: 'name', label: 'Name' },
  { id: 'commercialEventType', label: 'Type' },
  { id: 'realStartAt', label: 'Starts At', type: 'date-with-time' },
  { id: 'startAt', label: 'Displayed Since', type: 'date-with-time' },
  { id: 'endAt', label: 'Ends At', type: 'date-with-time' },
];
