import { useEffect } from 'react';

import { BlockedShippingAddress } from '@bits-app/bits-server-data';

import { Information } from '@/components/layouts/PanelDetails';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { selectResourceLoading } from '@/voggt-database-explorer/redux/database-explorer.slice';
import {
  getResource,
  selectResource,
  useResourceSelector,
} from '@/voggt-database-explorer/redux/use-cases/get-resource';

export const useBlockedShippingAddressDetailsView = (blockedShippingAddressId: string) => {
  const dispatch = useAppDispatch();

  const blockedShippingAddress = useResourceSelector<BlockedShippingAddress>(selectResource);
  const loading = useAppSelector(selectResourceLoading);

  useEffect(() => {
    if (
      blockedShippingAddressId &&
      blockedShippingAddress.id !== Number(blockedShippingAddressId)
    ) {
      dispatch(getResource(+blockedShippingAddressId, 'Blocked-Shipping-Address'));
    }
  }, [blockedShippingAddressId, blockedShippingAddress.id, dispatch]);

  return {
    blockedShippingAddress,
    loading,
    panelDetailsBlockedShippingAddress,
    panelDetailsStandardInformation,
  };
};

const panelDetailsBlockedShippingAddress: Information<BlockedShippingAddress>[] = [
  { value: 'id', label: 'id' },
  { value: 'name', label: 'Name' },
  { value: 'line1', label: 'line1' },
  { value: 'line2', label: 'line2' },
  { value: 'city', label: 'City' },
  { value: 'postalCode', label: 'Postal Code' },
  { value: 'hash1', label: 'hash1' },
  { value: 'hash2', label: 'hash2' },
];

const panelDetailsStandardInformation: Information<BlockedShippingAddress>[] = [
  { value: 'id', label: 'id' },
  { value: 'createdAt', label: 'createdAt', type: 'date-with-time' },
  { value: 'updatedAt', label: 'updatedAt', type: 'date-with-time' },
  { value: 'deletedAt', label: 'deletedAt', type: 'date-with-time' },
];
