import { useEffect } from 'react';

import { Permission } from '@bits-app/voggtpit-shared';
import { Outlet, useNavigate } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

type AuthenticatedRouteProps = { authorizedModule: Permission };

export const AuthorizedRoute = ({ authorizedModule }: AuthenticatedRouteProps) => {
  const { hasPermissions } = useOwnUser();

  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const isAuthorized = hasPermissions(authorizedModule);

  useEffect(() => {
    if (isAuthorized) {
      return;
    }

    snackbar.error('errors.forbidden');
    navigate('/');
  }, [isAuthorized, navigate, snackbar]);

  return <Outlet />;
};
