import { Promotion, PromotionsFindOptions } from '@bits-app/voggtpit-shared';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TableCellBodyNoBorderBottom } from '@/components/elements/StyledTableCell';
import { TableToolbar } from '@/components/elements/TableToolbar';

import { PromotionsHeader } from './PromotionsHeader';
import { PromotionRow } from './PromotionsRow';
import { PromotionsHeadCells } from './hooks/use-promotions-table-headers';

export interface PromotionsTableProps {
  headCells: Array<PromotionsHeadCells>;
  promotions: Array<Promotion>;
  loading: boolean;
  options: PromotionsFindOptions;
  count: number;
  onChangePage(newPage: number): void;
  launchCampaignCreation(): void;

  handleManageUser(id: Promotion['id']): void;
  handleExpirePromotion(id: Promotion['id']): void;
  handleEditPromotion(id: Promotion['id']): void;

  onOrderBy: (field: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;

  isReadOnly: boolean;
}

export const PromotionsTable = ({
  headCells,
  promotions,
  loading,
  options,
  count,
  onChangePage,
  launchCampaignCreation,
  handleManageUser,
  handleExpirePromotion,
  onOrderBy,
  order,
  orderBy,
  handleEditPromotion,
  isReadOnly,
}: PromotionsTableProps) => {
  const { t } = useTranslation();
  return (
    <>
      <TableToolbar
        title={
          <Typography variant="h2" fontSize="1.25rem">
            {t('promotion.promotions')}
          </Typography>
        }
        tableActions={
          !isReadOnly ? (
            <Button variant="contained" color="primary" onClick={launchCampaignCreation}>
              {t('promotion.create')}
            </Button>
          ) : null
        }
      />

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          maxWidth: '100%',
          overflow: 'hidden',
          backgroundColor: ({ palette }) => palette.background.default,
        }}
      >
        <TableContainer>
          <Table stickyHeader>
            <PromotionsHeader
              headCells={headCells}
              onOrderBy={onOrderBy}
              order={order}
              orderBy={orderBy}
            />

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCellBodyNoBorderBottom colSpan={headCells.length + 1}>
                    <CircularProgress />
                  </TableCellBodyNoBorderBottom>
                </TableRow>
              ) : (
                promotions.map((promotion) => (
                  <PromotionRow
                    handleManageUser={handleManageUser}
                    handleExpirePromotion={() => handleExpirePromotion(promotion.id)}
                    promotion={promotion}
                    key={promotion.id}
                    handleEditPromotion={handleEditPromotion}
                    isReadOnly={isReadOnly}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {!loading && promotions.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={options.limit}
          page={options.page}
          onPageChange={(_: unknown, newPage: number) => onChangePage(newPage)}
          sx={{
            backgroundColor: ({ palette }) => palette.background.default,
          }}
        />
      )}
    </>
  );
};
