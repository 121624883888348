import { Refund } from '../refund/refund.entity';

import { OrderItem, OrderItemAttributes } from './order-item.entity';

type OrderItemRefundAttributes = OrderItemAttributes & {
  refunds: Refund[];
};

export class OrderItemRefund extends OrderItem {
  readonly refunds: Refund[];

  constructor(attributes: OrderItemRefundAttributes) {
    super(attributes);

    this.refunds = attributes.refunds;
  }
}
