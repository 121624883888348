import { useState } from 'react';

import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { OrderItem } from '@/entities/order-item.entity';
import { useAppSelector } from '@/redux/reduxAppHooks';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

import { selectOrderItemsMassUpdating } from '../redux/order-items-mass-update.selectors';

type UpdateOrderItemsTrackingNumberDialogProps = {
  count: number;
  onClose: () => void;
  onUpdate: (trackingNumber: OrderItem['trackingNumber']) => void;
};

export const UpdateOrderItemsTrackingNumberDialog = ({
  count,
  onClose,
  onUpdate,
}: UpdateOrderItemsTrackingNumberDialogProps) => {
  const { t } = useTranslation();

  const loading = useAppSelector(selectOrderItemsMassUpdating);

  const [trackingNumber, setTrackingNumber] = useState('');

  const handleOnUpdate = () => {
    if (!trackingNumber) {
      return;
    }

    onUpdate(trackingNumber);
  };

  return (
    <Dialog
      open
      PaperProps={{
        sx: {
          minWidth: 360,
        },
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        {t('orderItemsMassUpdate.update.trackingNumber')}

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <Box mb={2}>{t('orderItemsMassUpdate.update.content', { count })}</Box>

        <TextField
          fullWidth
          label={t('orderItemLabels.trackingNumber')}
          value={trackingNumber}
          onChange={(event) => setTrackingNumber(event.target.value)}
        />
      </DialogContent>

      <DialogActions
        sx={{
          pr: 3,
        }}
      >
        <LoadingButton variant="contained" loading={loading} onClick={handleOnUpdate}>
          {t('update')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
