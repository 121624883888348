import { useReducer } from 'react';

import { User } from '@bits-app/bits-server-data';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../../../axios/axios.instance';
import { useSnackbar } from '../../../../../components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '../../../../../queries';

export const useBanUser = ({
  id: userId,
  isBanned,
  username,
}: Pick<User, 'id' | 'isBanned' | 'username'>) => {
  const [isOpen, toggleIsOpen] = useReducer((p) => !p, false);

  const snackbar = useSnackbar();

  const { mutate: banUser } = useMutation({
    mutationFn: (user: User) =>
      authenticatedInstance.patch(`database-explorer/customer/${user.id}`, {
        isBanned: !user.isBanned,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.userDetail(userId));
      const translationKey = isBanned
        ? 'databaseExplorer.users.unban'
        : 'databaseExplorer.users.ban';

      snackbar.success(`${translationKey}.success`, {
        interpolationMap: { username },
      });
    },
    onError: () => {
      snackbar.error('somethingWrongHappened');
    },
  });

  return {
    isOpen,
    toggleIsOpen,
    banUser,
  };
};
