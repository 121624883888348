import { AnyAction, configureStore, ThunkAction, Selector } from '@reduxjs/toolkit';

import authReducer from '@/auth/auth.slice';
import { AuthPort } from '@/auth/gateways/auth.port';
import { CommentPort } from '@/domain/Comments.port';
import { SnackbarPort } from '@/domain/SnackbarPort';
import { OrderItemsMassUpdatePort } from '@/order-items-mass-update/gateways/order-items-mass-update.port';
import orderItemsMassUpdateReducer from '@/order-items-mass-update/redux/order-items-mass-update.slice';
import { PartnerProgramPort, partnerProgramReducer } from '@/partner-program';
import { PromotionsPort } from '@/promotions/gateways/promotions.port';
import promotionReducer from '@/promotions/redux/promotions.slice';
import { RefundPort } from '@/refund/gateways/refund.port';
import refundReducer from '@/refund/redux/refund.slice';
import { SellerCancelRequestsPort } from '@/seller-cancel-requests/gateways/seller-cancel-requests.port';
import sellerCancelRequestsReducer from '@/seller-cancel-requests/redux/seller-cancel-requests.slice';
import { OrderPort } from '@/voggt-database-explorer/order/gateways/order.port';
import orderReducer from '@/voggt-database-explorer/order/redux/order.slice';
import { DatabaseExplorerPort } from '@/voggt-database-explorer/redux/database-explorer.port';
import databaseExplorerReducer from '@/voggt-database-explorer/redux/database-explorer.slice';

export type Dependencies = {
  authGateway: AuthPort;
  refundGateway: RefundPort;
  sellerCancelRequestsGateway: SellerCancelRequestsPort;
  orderItemsMassUpdateGateway: OrderItemsMassUpdatePort;
  snackbarGateway: SnackbarPort;
  promotionsGateway: PromotionsPort;
  databaseExplorerGateway: DatabaseExplorerPort;
  partnerProgramGateway: PartnerProgramPort;
  commentGateway: CommentPort;
  orderGateway: OrderPort;
};

export const createStore = (dependencies: Dependencies) =>
  configureStore({
    reducer: {
      auth: authReducer,
      refund: refundReducer,
      sellerCancelRequests: sellerCancelRequestsReducer,
      orderItemsMassUpdate: orderItemsMassUpdateReducer,
      promotions: promotionReducer,
      partnerProgram: partnerProgramReducer,
      databaseExplorer: databaseExplorerReducer,
      order: orderReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: dependencies,
        },
      }),
  });

type Store = ReturnType<typeof createStore>;

export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch'];
export type AppThunkAction<Result> = ThunkAction<Result, RootState, Dependencies, AnyAction>;
export type AppSelector<Result, Params extends unknown[]> = Selector<RootState, Result, Params>;
