import { useState } from 'react';

import { CommentType, PERMISSIONS } from '@bits-app/voggtpit-shared';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

import { useOwnUser } from '@/context/own-user.context';

import { TrashIcon } from '../icons/TrashIcon';

export function DeleteComment({
  commentId,
  deleteComment,
}: {
  commentId: string;
  deleteComment: (documentId: CommentType['id']) => void;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { hasPermissions } = useOwnUser();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    deleteComment(commentId);
  };

  if (!hasPermissions(PERMISSIONS.COMMENT.WRITE_COMMENT_DELETE)) {
    return null;
  }

  return (
    <div>
      <TrashIcon onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('comment.askDelete')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>{t('comment.no')}</Button>
          <Button onClick={handleSubmit} autoFocus>
            {t('comment.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
