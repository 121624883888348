import MuxPlayer from '@mux/mux-player-react';

export const MuxPlayerComponent = ({ playbackId }: { playbackId: string }) => {
  return (
    <MuxPlayer
      streamType="on-demand"
      playbackId={playbackId}
      style={{
        maxHeight: '50vh',
        aspectRatio: '9/16',
      }}
    />
  );
};
