import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { availablePaymentMethodType } from '@/voggt-database-explorer/payment-method/payment-method.type';

export type ForestAdminLinkProps = {
  entity:
    | 'shippingAddress'
    | 'user'
    | 'order'
    | 'show'
    | 'category'
    | 'emote'
    | 'channel'
    | 'country'
    | 'product'
    | 'badge'
    | 'shop'
    | 'promotion'
    | 'language'
    | 'sellerConfig'
    | 'blockedShippingAddress'
    | 'shippingFeeConfig'
    | availablePaymentMethodType
    | 'sellerNews';
  entityId: string | number;
};

export const ForestAdminLink = ({ entity, entityId }: ForestAdminLinkProps) => {
  const { t } = useTranslation();
  const ForestEnv = process.env.REACT_APP_ENV === 'sandbox' ? 'Sandbox' : 'Production';

  return (
    <Button
      component={'a'}
      variant="text"
      target={'_blank'}
      sx={{ fontWeight: 'bold' }}
      href={`https://app.forestadmin.com/Voggt/${ForestEnv}/Operations/data/${entity}/index/record/${entity}/${entityId}/details`}
    >
      {t('link.forest')}
    </Button>
  );
};
