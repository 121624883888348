import { ChangeEvent, useState } from 'react';

import { Category } from '@bits-app/bits-server-data';
import {
  Grid,
  TextField,
  FormHelperText,
  Alert,
  alpha,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Checkbox,
  SelectChangeEvent,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { ExternalLink } from '@/components/elements/Link';
import { LoadingButton } from '@/components/elements/buttons/LoadingButton';
import { useGetCategories } from '@/queries';
import { ImageUpload } from '@/voggt-database-explorer/components/ImageUpload/ImageUpload';

import { Emote } from '../../entity/emote.entity';
import { useUpsertEmoteForm } from '../../views/list/hooks/use-upsert-emote-form.hook';

const MAX_FILE_SIZE = 100000;

export type EmoteFieldValue = {
  name: string;
  isGlobal: boolean;
  categories: Category['id'][];
};

type UpsertEmoteFormProps = {
  emote?: Emote;
  emotes?: Emote[];
  afterSuccess: () => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const UpsertEmoteForm = ({ emote, emotes, afterSuccess }: UpsertEmoteFormProps) => {
  const { t } = useTranslation();

  const { data: categories } = useGetCategories();

  const { register, formState, handleSubmit, reset, setError, setValue, control, watch } =
    useForm<EmoteFieldValue>({
      defaultValues: {
        name: emote?.name ?? '',
        isGlobal: emote?.isGlobal ?? false,
        categories: emote?.categories.map(({ id }) => id) ?? [],
      },
    });

  const [disabledCategoriesSelection, setDisabledCategoriesSelection] = useState(
    emote?.isGlobal ?? false,
  );

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const validateName = (value: EmoteFieldValue['name']) => {
    if (!emotes) {
      return true;
    }

    if (emotes.map(({ name }) => name).includes(value)) {
      return t('databaseExplorer.emote.upsertEmote.form.errors.nameAlreadyTaken', {
        name: value,
      });
    }
  };

  const { onSubmit, isLoading, onDropImage, image, imageErrors } = useUpsertEmoteForm({
    emote,
    setFieldError: setError,
    resetForm: reset,
    afterSuccess,
  });

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'string') {
      return;
    } else {
      setValue('categories', value.map(Number));
    }
  };

  const handleIsGlobalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setValue('isGlobal', checked);

    if (checked) {
      setValue('categories', []);
      setDisabledCategoriesSelection(true);
    } else {
      setDisabledCategoriesSelection(false);
    }
  };

  return (
    <Grid container flexDirection="column" gap={3}>
      <Grid item>
        <TextField
          margin="dense"
          fullWidth
          disabled={Boolean(emote)}
          {...register('name', {
            validate: validateName,
            required: t('databaseExplorer.emote.upsertEmote.form.errors.required', {
              field: t('databaseExplorer.emote.upsertEmote.form.name'),
            }),
            minLength: 3,
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;

              if (!value.match(/^[a-zA-Z0-9]*$/)) {
                setValue('name', value.slice(0, value.length - 1));
                return;
              }

              setValue('name', value.replaceAll(' ', ''));
            },
          })}
          label={t('databaseExplorer.emote.upsertEmote.form.name')}
        />

        {formState.errors.name && (
          <FormHelperText error>{formState.errors.name.message}</FormHelperText>
        )}

        <Alert
          severity="info"
          sx={{
            backgroundColor: ({ palette }) => alpha(palette.info.light, 0.3),
          }}
        >
          {t('databaseExplorer.emote.upsertEmote.form.nameHint')}
        </Alert>
      </Grid>

      <Grid item>
        <ImageUpload
          maxSize={MAX_FILE_SIZE}
          imageAcceptedMimeTypes={{
            'image/png': ['.png'],
          }}
          currentImageUrl={emote?.image}
          image={image}
          imageErrors={imageErrors}
          onDropImage={onDropImage}
          dropImageLabel={t('databaseExplorer.emote.upsertEmote.form.file')}
        />

        <Alert
          severity="info"
          sx={{
            mt: 0.5,
            backgroundColor: ({ palette }) => alpha(palette.info.light, 0.3),
          }}
        >
          <Trans i18nKey="databaseExplorer.emote.upsertEmote.form.imageHint">
            Emote's cover must be a .png file of max size 100Kb. If the file is too large, use a
            tool like <ExternalLink href="https://tinypng.com" label="TinyPNG" />
          </Trans>
        </Alert>
      </Grid>

      <Grid item>
        <InputLabel id="CategoryId-label">
          {t('databaseExplorer.emote.upsertEmote.form.isGlobal')}
        </InputLabel>
        <Checkbox
          size="small"
          sx={{
            height: 20,
            width: 20,
          }}
          {...register('isGlobal', { onChange: handleIsGlobalChange })}
          checked={watch('isGlobal')}
        />
      </Grid>

      <Grid item>
        <Controller
          name="categories"
          rules={{
            required: !disabledCategoriesSelection
              ? t('databaseExplorer.emote.upsertEmote.form.errors.required', {
                  field: t('databaseExplorer.emote.upsertEmote.form.categories'),
                })
              : undefined,
            onChange: handleChange,
          }}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel shrink id="CategoryId-label">
                {t('databaseExplorer.emote.upsertEmote.form.categories')}
              </InputLabel>
              <Select
                {...field}
                multiple
                labelId="CategoryId-label"
                id="CategoryId"
                input={
                  <OutlinedInput
                    notched
                    label={t('databaseExplorer.emote.upsertEmote.form.categories')}
                  />
                }
                disabled={disabledCategoriesSelection}
                renderValue={(selectedCategoriesIds: Category['id'][]) =>
                  selectedCategoriesIds
                    .map(
                      (selectedCategory) =>
                        categories?.find((category) => category.id === selectedCategory)?.name,
                    )
                    .join(', ')
                }
                MenuProps={MenuProps}
              >
                {categories &&
                  categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      <Checkbox checked={field.value.includes(category.id)} />
                      <ListItemText primary={category.name} />
                    </MenuItem>
                  ))}
              </Select>

              {formState.errors.categories && (
                <FormHelperText error>{formState.errors.categories.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>

      <Box alignSelf="flex-end">
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={!formState.isDirty || (!emote && !image) || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {emote
            ? t('databaseExplorer.emote.upsertEmote.update.action')
            : t('databaseExplorer.emote.upsertEmote.create.action')}
        </LoadingButton>
      </Box>
    </Grid>
  );
};
