import {
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryColumn,
} from 'typeorm';

import { User } from './User';

@Entity({ name: 'user_moderators' })
export class UserPromoteModerator {
  @Index()
  @PrimaryColumn()
  userId!: number;

  @ManyToOne(() => User, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn({
    name: 'userId',
    referencedColumnName: 'id',
  })
  user!: User;

  @Index()
  @PrimaryColumn()
  moderatorUserId!: number;

  @ManyToOne(() => User)
  @JoinColumn({
    name: 'moderatorUserId',
    referencedColumnName: 'id',
  })
  moderatorUser!: User;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp', nullable: true })
  createdAt!: Date;
}
