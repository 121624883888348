import { SvgIconProps, Box } from '@mui/material';

type TextWithConditionalPrefixIconProps = {
  isIconVisible: boolean;
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  Icon: React.ElementType<SvgIconProps>;
  sxIconProps?: SvgIconProps['sx'];
  iconTitle?: string;
};

export const TextWithConditionalPrefixIcon = ({
  Icon,
  iconTitle,
  isIconVisible,
  sxIconProps = {},
  containerStyle = {},
  children,
}: TextWithConditionalPrefixIconProps) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', ...containerStyle }}
    >
      <Box
        sx={{
          px: 1,
          display: 'flex',
          alignItems: 'center',
        }}
        title={isIconVisible ? iconTitle : undefined}
      >
        <Icon
          fontSize="inherit"
          sx={{
            visibility: isIconVisible ? 'visible' : 'hidden',
            ...sxIconProps,
          }}
        />
      </Box>

      {children}
    </Box>
  );
};
