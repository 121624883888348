import { useTranslation } from 'react-i18next';

import { AddFilterFunctionParams } from '../../../../hooks/use-table-options';

import { OrderItemsMassUpdateFreeFilter } from './OrderItemsMassUpdateFreeFilter';

type OrderIdProps = {
  activeFilters?: string[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
  disabled?: boolean;
};

export const OrderIdFilter = ({
  activeFilters,
  addFilter,
  isUnsaved,
  disabled = false,
}: OrderIdProps) => {
  const { t } = useTranslation();

  return (
    <OrderItemsMassUpdateFreeFilter
      addFilter={addFilter}
      field="orderId"
      isValueValid={isOrderIdValid}
      inputPlaceholder={t('orderItemsMassUpdate.searchFilter.orderId')}
      activeFilters={activeFilters}
      isUnsaved={isUnsaved}
      disabled={disabled}
    />
  );
};

const isOrderIdValid = (value: string) => {
  return Boolean(value.match(/^\d+_\d+$/));
};
