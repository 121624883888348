// From https://www.iban.com/country-codes
export enum CountryCodeIso3 {
  NULL = 'NULL', // FALLBACK
  AND = 'AND', // Andorra
  ARE = 'ARE', // United Arab Emirates (the)
  AFG = 'AFG', // Afghanistan
  ATG = 'ATG', // Antigua and Barbuda
  AIA = 'AIA', // Anguilla
  ALB = 'ALB', // Albania
  ARM = 'ARM', // Armenia
  AGO = 'AGO', // Angola
  ATA = 'ATA', // Antarctica
  ARG = 'ARG', // Argentina
  ASM = 'ASM', // American Samoa
  AUT = 'AUT', // Austria
  AUS = 'AUS', // Australia
  ABW = 'ABW', // Aruba
  ALA = 'ALA', // Åland Islands
  AZE = 'AZE', // Azerbaijan
  BIH = 'BIH', // Bosnia and Herzegovina
  BRB = 'BRB', // Barbados
  BGD = 'BGD', // Bangladesh
  BEL = 'BEL', // Belgium
  BFA = 'BFA', // Burkina Faso
  BGR = 'BGR', // Bulgaria
  BHR = 'BHR', // Bahrain
  BDI = 'BDI', // Burundi
  BEN = 'BEN', // Benin
  BLM = 'BLM', // Saint Barthélemy
  BMU = 'BMU', // Bermuda
  BRN = 'BRN', // Brunei Darussalam
  BOL = 'BOL', // Bolivia (Plurinational State of)
  BES = 'BES', // Bonaire, Sint Eustatius and Saba
  BRA = 'BRA', // Brazil
  BHS = 'BHS', // Bahamas (the)
  BTN = 'BTN', // Bhutan
  BVT = 'BVT', // Bouvet Island
  BWA = 'BWA', // Botswana
  BLR = 'BLR', // Belarus
  BLZ = 'BLZ', // Belize
  CAN = 'CAN', // Canada
  CCK = 'CCK', // Cocos (Keeling) Islands (the)
  COD = 'COD', // Congo (the Democratic Republic of the)
  CAF = 'CAF', // Central African Republic (the)
  COG = 'COG', // Congo (the)
  CHE = 'CHE', // Switzerland
  CIV = 'CIV', // Côte d'Ivoire
  COK = 'COK', // Cook Islands (the)
  CHL = 'CHL', // Chile
  CMR = 'CMR', // Cameroon
  CHN = 'CHN', // China
  COL = 'COL', // Colombia
  CRI = 'CRI', // Costa Rica
  CUB = 'CUB', // Cuba
  CPV = 'CPV', // Cabo Verde
  CUW = 'CUW', // Curaçao
  CXR = 'CXR', // Christmas Island
  CYP = 'CYP', // Cyprus
  CZE = 'CZE', // Czechia
  DEU = 'DEU', // Germany
  DJI = 'DJI', // Djibouti
  DNK = 'DNK', // Denmark
  DMA = 'DMA', // Dominica
  DOM = 'DOM', // Dominican Republic (the)
  DZA = 'DZA', // Algeria
  ECU = 'ECU', // Ecuador
  EST = 'EST', // Estonia
  EGY = 'EGY', // Egypt
  ESH = 'ESH', // Western Sahara
  ERI = 'ERI', // Eritrea
  ESP = 'ESP', // Spain
  ETH = 'ETH', // Ethiopia
  FIN = 'FIN', // Finland
  FJI = 'FJI', // Fiji
  FLK = 'FLK', // Falkland Islands (the) [Malvinas]
  FSM = 'FSM', // Micronesia (Federated States of)
  FRO = 'FRO', // Faroe Islands (the)
  FRA = 'FRA', // France
  GAB = 'GAB', // Gabon
  GBR = 'GBR', // United Kingdom of Great Britain and Northern Ireland (the)
  GRD = 'GRD', // Grenada
  GEO = 'GEO', // Georgia
  GUF = 'GUF', // French Guiana
  GGY = 'GGY', // Guernsey
  GHA = 'GHA', // Ghana
  GIB = 'GIB', // Gibraltar
  GRL = 'GRL', // Greenland
  GMB = 'GMB', // Gambia (the)
  GIN = 'GIN', // Guinea
  GLP = 'GLP', // Guadeloupe
  GNQ = 'GNQ', // Equatorial Guinea
  GRC = 'GRC', // Greece
  SGS = 'SGS', // South Georgia and the South Sandwich Islands
  GTM = 'GTM', // Guatemala
  GUM = 'GUM', // Guam
  GNB = 'GNB', // Guinea-Bissau
  GUY = 'GUY', // Guyana
  HKG = 'HKG', // Hong Kong
  HMD = 'HMD', // Heard Island and McDonald Islands
  HND = 'HND', // Honduras
  HRV = 'HRV', // Croatia
  HTI = 'HTI', // Haiti
  HUN = 'HUN', // Hungary
  IDN = 'IDN', // Indonesia
  IRL = 'IRL', // Ireland
  ISR = 'ISR', // Israel
  IMN = 'IMN', // Isle of Man
  IND = 'IND', // India
  IOT = 'IOT', // British Indian Ocean Territory (the)
  IRQ = 'IRQ', // Iraq
  IRN = 'IRN', // Iran (Islamic Republic of)
  ISL = 'ISL', // Iceland
  ITA = 'ITA', // Italy
  JEY = 'JEY', // Jersey
  JAM = 'JAM', // Jamaica
  JOR = 'JOR', // Jordan
  JPN = 'JPN', // Japan
  KEN = 'KEN', // Kenya
  KGZ = 'KGZ', // Kyrgyzstan
  KHM = 'KHM', // Cambodia
  KIR = 'KIR', // Kiribati
  COM = 'COM', // Comoros (the)
  KNA = 'KNA', // Saint Kitts and Nevis
  PRK = 'PRK', // Korea (the Democratic People's Republic of)
  KOR = 'KOR', // Korea (the Republic of)
  KWT = 'KWT', // Kuwait
  CYM = 'CYM', // Cayman Islands (the)
  KAZ = 'KAZ', // Kazakhstan
  LAO = 'LAO', // Lao People's Democratic Republic (the)
  LBN = 'LBN', // Lebanon
  LCA = 'LCA', // Saint Lucia
  LIE = 'LIE', // Liechtenstein
  LKA = 'LKA', // Sri Lanka
  LBR = 'LBR', // Liberia
  LSO = 'LSO', // Lesotho
  LTU = 'LTU', // Lithuania
  LUX = 'LUX', // Luxembourg
  LVA = 'LVA', // Latvia
  LBY = 'LBY', // Libya
  MAR = 'MAR', // Morocco
  MCO = 'MCO', // Monaco
  MDA = 'MDA', // Moldova (the Republic of)
  MNE = 'MNE', // Montenegro
  MAF = 'MAF', // Saint Martin (French part)
  MDG = 'MDG', // Madagascar
  MHL = 'MHL', // Marshall Islands (the)
  MKD = 'MKD', // Republic of North Macedonia
  MLI = 'MLI', // Mali
  MMR = 'MMR', // Myanmar
  MNG = 'MNG', // Mongolia
  MAC = 'MAC', // Macao
  MNP = 'MNP', // Northern Mariana Islands (the)
  MTQ = 'MTQ', // Martinique
  MRT = 'MRT', // Mauritania
  MSR = 'MSR', // Montserrat
  MLT = 'MLT', // Malta
  MUS = 'MUS', // Mauritius
  MDV = 'MDV', // Maldives
  MWI = 'MWI', // Malawi
  MEX = 'MEX', // Mexico
  MYS = 'MYS', // Malaysia
  MOZ = 'MOZ', // Mozambique
  NAM = 'NAM', // Namibia
  NCL = 'NCL', // New Caledonia
  NER = 'NER', // Niger (the)
  NFK = 'NFK', // Norfolk Island
  NGA = 'NGA', // Nigeria
  NIC = 'NIC', // Nicaragua
  NLD = 'NLD', // Netherlands (the)
  NOR = 'NOR', // Norway
  NPL = 'NPL', // Nepal
  NRU = 'NRU', // Nauru
  NIU = 'NIU', // Niue
  NZL = 'NZL', // New Zealand
  OMN = 'OMN', // Oman
  PAN = 'PAN', // Panama
  PER = 'PER', // Peru
  PYF = 'PYF', // French Polynesia
  PNG = 'PNG', // Papua New Guinea
  PHL = 'PHL', // Philippines (the)
  PAK = 'PAK', // Pakistan
  POL = 'POL', // Poland
  SPM = 'SPM', // Saint Pierre and Miquelon
  PCN = 'PCN', // Pitcairn
  PRI = 'PRI', // Puerto Rico
  PSE = 'PSE', // Palestine, State of
  PRT = 'PRT', // Portugal
  PLW = 'PLW', // Palau
  PRY = 'PRY', // Paraguay
  QAT = 'QAT', // Qatar
  REU = 'REU', // Réunion
  ROU = 'ROU', // Romania
  SRB = 'SRB', // Serbia
  RUS = 'RUS', // Russian Federation (the)
  RWA = 'RWA', // Rwanda
  SAU = 'SAU', // Saudi Arabia
  SLB = 'SLB', // Solomon Islands
  SYC = 'SYC', // Seychelles
  SDN = 'SDN', // Sudan (the)
  SWE = 'SWE', // Sweden
  SGP = 'SGP', // Singapore
  SHN = 'SHN', // Saint Helena, Ascension and Tristan da Cunha
  SVN = 'SVN', // Slovenia
  SJM = 'SJM', // Svalbard and Jan Mayen
  SVK = 'SVK', // Slovakia
  SLE = 'SLE', // Sierra Leone
  SMR = 'SMR', // San Marino
  SEN = 'SEN', // Senegal
  SOM = 'SOM', // Somalia
  SUR = 'SUR', // Suriname
  SSD = 'SSD', // South Sudan
  STP = 'STP', // Sao Tome and Principe
  SLV = 'SLV', // El Salvador
  SXM = 'SXM', // Sint Maarten (Dutch part)
  SYR = 'SYR', // Syrian Arab Republic
  SWZ = 'SWZ', // Eswatini
  TCA = 'TCA', // Turks and Caicos Islands (the)
  TCD = 'TCD', // Chad
  ATF = 'ATF', // French Southern Territories (the)
  TGO = 'TGO', // Togo
  THA = 'THA', // Thailand
  TJK = 'TJK', // Tajikistan
  TKL = 'TKL', // Tokelau
  TLS = 'TLS', // Timor-Leste
  TKM = 'TKM', // Turkmenistan
  TUN = 'TUN', // Tunisia
  TON = 'TON', // Tonga
  TUR = 'TUR', // Turkey
  TTO = 'TTO', // Trinidad and Tobago
  TUV = 'TUV', // Tuvalu
  TWN = 'TWN', // Taiwan (Province of China)
  TZA = 'TZA', // Tanzania, United Republic of
  UKR = 'UKR', // Ukraine
  UGA = 'UGA', // Uganda
  UMI = 'UMI', // United States Minor Outlying Islands (the)
  USA = 'USA', // United States of America (the)
  URY = 'URY', // Uruguay
  UZB = 'UZB', // Uzbekistan
  VAT = 'VAT', // Holy See (the)
  VCT = 'VCT', // Saint Vincent and the Grenadines
  VEN = 'VEN', // Venezuela (Bolivarian Republic of)
  VGB = 'VGB', // Virgin Islands (British)
  VIR = 'VIR', // Virgin Islands (U.S.)
  VNM = 'VNM', // Viet Nam
  VUT = 'VUT', // Vanuatu
  WLF = 'WLF', // Wallis and Futuna
  WSM = 'WSM', // Samoa
  YEM = 'YEM', // Yemen
  MYT = 'MYT', // Mayotte
  ZAF = 'ZAF', // South Africa
  ZMB = 'ZMB', // Zambia
  ZWE = 'ZWE', // Zimbabwe
}
