import { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

export const useRedirection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/payment-method') {
      navigate('/payment-method/card');
    }

    if (location.pathname === '/category') {
      navigate('/category/parent');
    }
  }, [location, navigate]);
};
