import { typeSenseIndex } from '@/hooks/use-typesense/use-typesense';

export const availablePaymentMethod: typeSenseIndex[] = [
  'payment_method_card',
  'payment_method_bancontact',
  'payment_method_giropay',
  'payment_method_ideal',
  'payment_method_paypal',
];

export function deletePrefix(str: string) {
  return str.replace('payment_method_', '');
}

export type availablePaymentMethodType =
  | 'paymentMethodCard'
  | 'paymentMethodPaypal'
  | 'paymentMethodGiropay'
  | 'paymentMethodBancontact'
  | 'paymentMethodIdeal';
