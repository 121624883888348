import { createContext, useContext, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { WarningUnsavedDialog } from '@/components/elements/WarningUnsavedDialog';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnsavedContextValue = (action: (...args: any[]) => void) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unsavedContext = createContext<UnsavedContextValue>(null as any);

type UnsavedProviderProps = {
  children: React.ReactNode;
};

export const UnsavedProvider = ({ children }: UnsavedProviderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onConfirm = useRef<((...args: any[]) => void) | null>(null);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const handleOnClose = () => {
    onConfirm.current = null;
    setOpen(false);
  };

  const handleOnConfirm = () => {
    if (onConfirm.current) {
      onConfirm.current();
    }

    handleOnClose();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSetConfirm = (func: (...args: any[]) => void) => {
    onConfirm.current = func;
    setOpen(true);
  };

  return (
    <unsavedContext.Provider value={handleSetConfirm}>
      {children}

      {open && (
        <WarningUnsavedDialog
          title={t('orderItemsMassUpdate.selectionLost.title')}
          content={t('orderItemsMassUpdate.selectionLost.content')}
          onClose={handleOnClose}
          onConfirm={handleOnConfirm}
        />
      )}
    </unsavedContext.Provider>
  );
};

export const useUnsavedWarning = () => useContext(unsavedContext);
