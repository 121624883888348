import { createContext, useCallback, useContext, useState } from 'react';

import { Show, StreamingAsset } from '@bits-app/voggtpit-shared';
import Drawer from '@mui/material/Drawer';

import { authenticatedInstance } from '@/axios/axios.instance';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';

import { MuxAssetsDetails } from './MuxAssetsDetails';
import { getPath } from './mux.const';
import { LoadAssetsParams, MuxContextProviderType } from './mux.type';

const MuxContext = createContext<MuxContextProviderType>({
  isOpen: false,
  isLoading: false,
  fullStreamingAsset: undefined,
  muxAssets: [],
  ownShowId: undefined,
  setIsLoading: (_isLoading: boolean) => {
    /***/
  },
  onDrawerToggle: (_streamingAssetId: string) => {
    /***/
  },
  setMuxAssets: (_muxAssets: StreamingAsset[]) => {
    /***/
  },
  setSelectedStreamingAssetId: (_selectedStreamingAssetId: StreamingAsset['id']) => {
    /***/
  },
  loadAssets: async (_params: LoadAssetsParams) => {
    /***/
  },
  selectNextAsset: () => {
    /**/
  },
  selectPreviousAsset: () => {
    /**/
  },
  currentAsset: undefined,
});

export const MuxContextProvider = ({ children }: { children: React.ReactElement }) => {
  const [currentPath, setCurrentPath] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentAsset, setCurrentAsset] = useState<StreamingAsset | undefined>(undefined);
  const [muxAssets, setMuxAssets] = useState<StreamingAsset[]>([]);
  const [ownShowId, setOwnShowId] = useState<Show['id']>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cleanUp = () => {
    setMuxAssets([]);
    setOwnShowId(undefined);
    setIsLoading(false);
  };

  const setSelectedStreamingAssetId = (assetId: StreamingAsset['id']) => {
    const currentIndex = muxAssets.findIndex((asset) => asset.id === assetId);
    const currentAsset = muxAssets[currentIndex];
    setCurrentAsset(currentAsset);
  };

  const selectNextAsset = useCallback(() => {
    const currentIndex = muxAssets.findIndex((asset) => asset.id === currentAsset?.id);
    const nextIndex = currentIndex + 1;
    const nextAsset = muxAssets[nextIndex];
    if (nextAsset) {
      setCurrentAsset(nextAsset);
    }
  }, [currentAsset?.id, muxAssets]);

  const selectPreviousAsset = useCallback(() => {
    const currentIndex = muxAssets.findIndex((asset) => asset.id === currentAsset?.id);
    const previousIndex = currentIndex - 1;
    const previousAsset = muxAssets[previousIndex];
    if (previousAsset) {
      setCurrentAsset(previousAsset);
    }
  }, [currentAsset?.id, muxAssets]);

  const onDrawerToggle = (streamingAssetId: string) => {
    setIsOpen(!isOpen);
    setSelectedStreamingAssetId(streamingAssetId);
    const currentAsset = muxAssets.find((asset) => asset.id === streamingAssetId);
    setOwnShowId(currentAsset?.showId);
  };

  const loadAssets = async ({ entityId, type }: LoadAssetsParams) => {
    const path = getPath({ entityId, type });
    if (path === currentPath) return;
    cleanUp();
    try {
      const assetsResponse = await authenticatedInstance.get(path);
      setMuxAssets(assetsResponse.data || []);
      setCurrentPath(path);
    } catch (error) {
      if (error instanceof CanceledRequestError) {
        return;
      }
    }
  };

  const contextValue: MuxContextProviderType = {
    isOpen,
    onDrawerToggle,
    setMuxAssets,
    muxAssets,
    ownShowId,
    setSelectedStreamingAssetId,
    isLoading,
    setIsLoading,
    loadAssets,
    selectNextAsset,
    selectPreviousAsset,
    currentAsset,
  };

  return (
    <MuxContext.Provider value={contextValue}>
      {children}
      <Drawer
        sx={{ display: { md: 'none' } }}
        anchor={'right'}
        open={isOpen}
        onClose={onDrawerToggle}
        justify-content={'center'}
        align-items={'center'}
      >
        <MuxAssetsDetails />
      </Drawer>
    </MuxContext.Provider>
  );
};

export const useMuxContext = () => useContext(MuxContext);
