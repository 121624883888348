import { useTranslation } from 'react-i18next';

import { AddFilterFunctionParams } from '../../../../hooks/use-table-options';

import { OrderItemsMassUpdateFreeFilter } from './OrderItemsMassUpdateFreeFilter';

type OrderIdProps = {
  activeFilters?: string[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
};

export const OrderItemIdFilter = ({ activeFilters, addFilter, isUnsaved }: OrderIdProps) => {
  const { t } = useTranslation();

  return (
    <OrderItemsMassUpdateFreeFilter
      addFilter={addFilter}
      field="id"
      isValueValid={isOrderItemIdValid}
      inputPlaceholder={t('orderItemsMassUpdate.searchFilter.orderItemId')}
      activeFilters={activeFilters}
      isUnsaved={isUnsaved}
    />
  );
};

const isOrderItemIdValid = (value: string) => {
  return Boolean(value.match(/\d+/));
};
