import { Badge, ShippingAddress } from '@bits-app/bits-server-data';
import { Currency, centsToUnit } from '@bits-app/voggtpit-shared';

import { DataDate } from '@/components/elements/DataWrapper/DataDate';
import { DataLink } from '@/components/elements/DataWrapper/DataLink';
import { DataStatus } from '@/components/elements/DataWrapper/DataStatus';
import { DataText } from '@/components/elements/DataWrapper/DataText';
import { Panel } from '@/components/layouts/Panel';
import { getStripeAccountUrl, getStripeCustomerUrl, MUX_BASE_URL } from '@/utils';

import { DataLongText } from '../../../../../components/elements/DataWrapper/DataLongText';
import { User } from '../../../users';

type BuyerInformationBlocProps = {
  user: User;
  shippingAddressPhoneNumber: ShippingAddress['phoneNumber'];
  shippingAddressEmail: ShippingAddress['email'];
};

export const BuyerInformationBloc = ({
  user,
  shippingAddressPhoneNumber,
  shippingAddressEmail,
}: BuyerInformationBlocProps) => {
  const userSsoIssue = user?.email && user?.userSso?.email && user?.userSso?.email !== user?.email;

  const isSsoUser = !!user.userSso;

  const referrer = user.referrer;

  const phoneNumberValue = user.phoneNumber ?? shippingAddressPhoneNumber;

  return (
    <Panel title="Buyer information">
      <div className="flex flex-col gap-3">
        <DataText title="Device" value={user.platform} />
        <DataText title="Registration method" value={isSsoUser ? 'SSO' : 'Email'} />
        {userSsoIssue && (
          <DataStatus title="SSO Email" value={user?.userSso?.email ?? '-'} status={undefined} />
        )}
        <DataLink
          title="Stripe customer ID"
          displayText={user.stripeCustomerId}
          link={getStripeCustomerUrl(user.stripeCustomerId)}
          type="external"
        />
        <DataText title="Referral Code" value={user.referralCode} />
        {referrer && (
          <DataLink
            type="internal"
            title="Referrer"
            displayText={referrer.username}
            link={`/user/${referrer.id}`}
          />
        )}
        <DataStatus title="Email" value={user.email} status={user.isEmailVerified} />
        <DataStatus
          title="Shipping Address Email"
          value={shippingAddressEmail ?? '-'}
          status={user.isEmailVerified}
        />
        <DataStatus title="Phone" value={phoneNumberValue} status={user.isPhoneNumberVerified} />
        <DataText title="Favorite Pickup Point" value={user.favouritePickupPoint} />
        <DataText title="Country" value={user.countryId} />
        <DataDate title="Created at" date={user.createdAt} />
        <DataLongText title="Biography" value={user.biography} />
      </div>
    </Panel>
  );
};

export const SellerInformationBloc = ({
  user,
  currentBalance,
}: {
  user: User & { sellerRating?: number };
  currentBalance?: number;
}) => (
  <Panel title="Seller information">
    <div className="flex flex-col gap-3">
      <DataLink
        title="Stripe account ID"
        displayText={user.stripeAccountId}
        link={getStripeAccountUrl(user.stripeAccountId)}
        type="external"
      />
      <DataText
        title="Rating"
        value={user.sellerRating ? user.sellerRating : 'No rating found'}
        disableCopy
      />
      {currentBalance !== undefined && (
        <DataText title="Current balance (Ledger beta)" value={currentBalance / 100} disableCopy />
      )}
      <DataText
        title="Commission structure"
        value={`${user.percentageFee}% (incl shipping fees) + ${centsToUnit(user.fixedFee)} ${
          user?.sellerConfig?.sellerCurrency ?? Currency.eur
        }`}
        disableCopy
      />
      <DataLink
        title="Mux Provider ID"
        displayText="dashboard.mux.com"
        type="external"
        link={`${MUX_BASE_URL}/video/live-streams/${user?.channel?.providerId}`}
      />
      <DataLink
        title="HLS"
        displayText={user?.channel?.hls}
        type="external"
        link={user?.channel?.hls}
      />
      <DataLink
        title="RTMP"
        displayText={user?.channel?.rtmp}
        type="external"
        link={user?.channel?.rtmp}
      />
      <DataLink
        title="Health inspector"
        displayText="dashboard.mux.com"
        type="external"
        link={`${MUX_BASE_URL}/video/live-streams/${user?.channel?.providerId}/health`}
      />
      <DataDate title="Created at" date={user.sellerCreatedAt} />
    </div>
  </Panel>
);

export const BadgesRow = ({
  badges = [],
  onDoubleClick,
}: {
  badges?: User['badges'];
  onDoubleClick: (badge: Badge) => void;
}) => {
  if (badges.length === 0) return <p className="text-slate-300">-</p>;

  return (
    <div className="grid grid-cols-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2">
      {badges.map((badge) => (
        <div
          className="w-full h-[100px] p-4 flex flex-col justify-between items-center rounded bg-slate-100 overflow-hidden hover:bg-red-100 cursor-pointer hover:text-red-500"
          onClick={() => onDoubleClick(badge)}
          key={badge.id}
        >
          <img
            src={`${process.env.REACT_APP_CDN_UNOPTIMIZED_BADGES_URL}/${badge?.filename}`}
            className="mb-2 w-[40px] h-[40px]"
            alt={badge.name}
          />
          <p className="text-xs w-full whitespace-nowrap overflow-ellipsis overflow-hidden text-center">
            {badge.name}
          </p>
        </div>
      ))}
    </div>
  );
};
