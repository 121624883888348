import { SellerConfig } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { QUERIES_KEYS } from '../../../queries';

export const useSellerConfigByUserId = (userId: number) => {
  return useQuery(QUERIES_KEYS.userSellerConfigDetail(userId), () =>
    authenticatedInstance.get<SellerConfig>(`/database-explorer/seller-config/user/${userId}`),
  );
};
