import { useRef } from 'react';

import { CommercialEvent } from '@bits-app/bits-server-data';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { EditorRef } from '../../../components/elements/PanelDetailImage';
import { useSnackbar } from '../../../components/elements/snackbar/use-snackbar';
import { FieldError } from '../../../domain/errors/FieldError';
import { QUERIES_KEYS, queryClient } from '../../../queries';
import { CommercialEventImageType } from '../commercial-event.type';

export const useEditAssetOfCommercialEvent = (
  assetType: CommercialEventImageType,
  commercialEvent?: CommercialEvent,
) => {
  const editorRef = useRef<EditorRef>(null);
  const snackbar = useSnackbar();

  const { mutate: onEditAsset } = useMutation<CommercialEvent, unknown, File>({
    mutationFn: (image) => commitUpdate(image, assetType, commercialEvent?.id),
    onSuccess: () => {
      if (!commercialEvent) return;
      editorRef.current?.clearImage();
      editorRef.current?.close();
      snackbar.success('databaseExplorer.show.thumbnailUpdate.success');
      queryClient.invalidateQueries(QUERIES_KEYS.commercialEvent(commercialEvent?.id));
    },
    onError: (error) => {
      if (error instanceof FieldError) {
        if (error.field === 'image') {
          editorRef.current?.setImageErrors([
            {
              code: 'server-error',
              message: error.message,
            },
          ]);
        }
      }

      snackbar.error('databaseExplorer.show.thumbnailUpdate.error');
    },
  });

  return {
    onEditAsset,
    editorRef,
  };
};

export const commitUpdate = async (
  thumbnail: File,
  assetType: CommercialEventImageType,
  commercialEventId?: CommercialEvent['id'],
): Promise<CommercialEvent> => {
  if (!commercialEventId) throw new Error('commercialEventId is undefined');

  const formData = new FormData();
  formData.append('file', thumbnail);

  const response = await authenticatedInstance.patch(
    `database-explorer/commercial-event/${commercialEventId}/upload-image/${assetType}`,
    formData,
    {
      headers: {
        ['Content-Type']: 'multipart/form-data',
      },
    },
  );

  return response.data;
};
