import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToOne,
  PrimaryGeneratedColumn,
} from 'typeorm';

import { Order } from './Order';
import { Promotion } from './Promotion';
import { User } from './User';

export enum UserPromotionStatus {
  not_used = 'not_used',
  used = 'used',
}

@Index(['userId', 'promotionId', 'referralId'], { unique: true })
@Entity('user_target_promotion')
export class UserTargetPromotion {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @ManyToOne(() => User, (user) => user.userTargetPromotions)
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Index()
  @Column()
  userId!: number;

  @ManyToOne(() => Promotion, (promotion) => promotion.userTargetPromotions)
  @JoinColumn({ name: 'promotionId' })
  promotion!: Promotion;

  @Index()
  @Column()
  promotionId!: number;

  @ManyToOne(() => User, {})
  @JoinColumn({ name: 'referralId' })
  referral!: User;

  @Index({ unique: true })
  @Column({ nullable: true })
  referralId!: number;

  @OneToOne(() => Order, (order) => order.userTargetPromotion)
  order!: Order;

  @Column({
    type: 'enum',
    enum: UserPromotionStatus,
    default: UserPromotionStatus.not_used,
  })
  status!: UserPromotionStatus;

  @Column({ type: 'timestamp', nullable: true, default: null })
  usedAt!: Date;

  @CreateDateColumn({ name: 'createdAt', nullable: true, type: 'timestamp' })
  createdAt!: Date;

  @Column({ type: 'timestamp' })
  expireAt!: Date;

  isUsed() {
    // TODO: only rely on status after corrupted data is fixed
    return this.status === UserPromotionStatus.used || !!this.usedAt;
  }
}
