import { useParams } from 'react-router-dom';

export const useIdParam = (): string => {
  const { id } = useParams();

  return id ?? '';
};

export const useIdParamAsNumber = () => {
  const { id } = useParams();

  return parseInt(id || '', 10);
};
