import { Category } from '@bits-app/bits-server-data';

import { authenticatedInstance } from '@/axios/axios.instance';

export const getCategoryTranslations = async (category: Category) => {
  const result = await authenticatedInstance.get(
    `/database-explorer/category/${category.name}/translations`,
  );

  return result.data;
};
