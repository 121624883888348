import { PERMISSIONS, AvailableEntityForComment } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { authenticatedInstance } from '@/axios/axios.instance';
import { ActivityLogs } from '@/components/elements/ActivityLogs';
import {
  PaymentMethodPreview,
  PaymentMethodPreviewProps,
} from '@/components/elements/DataWrapper/preview/payment-method.preview';
import { ShippingAddressPreview } from '@/components/elements/DataWrapper/preview/shipping-address.preview';
import { Panel } from '@/components/layouts/Panel';
import { useOwnUser } from '@/context/own-user.context';
import { useActivityLogs } from '@/hooks/use-activity-logs';
import { useIdParam } from '@/hooks/use-id-params';
import { QUERIES_KEYS } from '@/queries';
import { useAddBadgeToUser } from '@/voggt-database-explorer/users/hooks/use-add-badges-to-user';
import { useRemoveBadgeFromUser } from '@/voggt-database-explorer/users/hooks/use-remove-badges-from-user';

import { useUserDetail } from '../../../hooks/useUserDetail';
import { UserBanner } from '../UserBanner';
import { useUserBalanceQuery } from '../UserPayoutList/hooks/ledger.query';

import { BadgesRow, BuyerInformationBloc, SellerInformationBloc } from './Blocs';

type DetailsProps = {
  addBadgeHook: ReturnType<typeof useAddBadgeToUser>;
  removeBadgeHook: ReturnType<typeof useRemoveBadgeFromUser>;
};

export const Details = ({ addBadgeHook, removeBadgeHook }: DetailsProps) => {
  const userId = useIdParam();
  const { hasPermissions } = useOwnUser();
  const navigate = useNavigate();

  const { user } = useUserDetail(Number(userId));

  const userActivityLogsHooks = useActivityLogs({
    entity: AvailableEntityForComment.user,
    entityId: userId,
  });

  const sellerConfigActivityLogsHooks = useActivityLogs({
    entity: AvailableEntityForComment.seller_config,
    entityId: userId,
  });

  const logsMerge = [
    ...(userActivityLogsHooks.data || []),
    ...(sellerConfigActivityLogsHooks.data || []),
  ].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  const userPaymentMethodQuery = useQuery<PaymentMethodPreviewProps>({
    queryKey: QUERIES_KEYS.paymentMethodByUserId(Number(userId)),
    queryFn: async () =>
      (await authenticatedInstance.get(`/database-explorer/payment-method/${userId}/active`)).data,
    enabled: hasPermissions(PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_PAYMENT_METHOD),
  });

  const { data: currentBalance } = useUserBalanceQuery(
    Number(userId),
    Boolean(user?.isSeller && user?.isSelected),
  );

  if (!userId || !user) {
    return null;
  }

  const [userShippingAddress] = (user?.shippingAddresses || []).filter(
    (shippingAddress) => !shippingAddress.deletedAt,
  );

  return (
    <>
      <UserBanner user={user} />

      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex flex-1 flex-col gap-4">
          <BuyerInformationBloc
            user={user}
            shippingAddressEmail={userShippingAddress?.email}
            shippingAddressPhoneNumber={userShippingAddress?.phoneNumber}
          />

          <Panel title="Payment method">
            <PaymentMethodPreview {...userPaymentMethodQuery} />
          </Panel>

          <Panel
            onClickOnAction={() => navigate(`/user/${userId}/shipping-address`)}
            title="Shipping address"
            actionName="View all"
          >
            <ShippingAddressPreview shippingAddress={userShippingAddress} />
          </Panel>
        </div>

        <div className="flex flex-1 flex-col gap-4">
          <SellerInformationBloc user={user} currentBalance={currentBalance} />

          <Panel title="Badges" actionName="Add" onClickOnAction={addBadgeHook.toggleModal}>
            <BadgesRow
              badges={user.badges}
              onDoubleClick={(badge) => removeBadgeHook.setSelectBadge(badge)}
            />
          </Panel>

          <ActivityLogs
            logs={logsMerge}
            isLoading={userActivityLogsHooks.isLoading || sellerConfigActivityLogsHooks.isLoading}
            entityId={userId}
            entityName={AvailableEntityForComment.user}
          />
        </div>
      </div>
    </>
  );
};
