import * as yup from 'yup';

export const signInOwnUserPasswordRegExp = new RegExp(
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%* #?&])([A-Za-z\d@$!%* #?&]{8,})$/g,
);
export const emailIsNotVoggtDomainRegExp = new RegExp(/.*@voggt\.com$/);
export const ownUserPasswordValidation = yup
  .string()
  .matches(signInOwnUserPasswordRegExp, {
    message:
      'Password must be at least 8 characters long, contains at least one letter, one number and one special character',
  });

export const ownUserSignInSchema = yup.object().shape({
  password: ownUserPasswordValidation.required(),
  email: yup
    .string()
    .email()
    .matches(
      emailIsNotVoggtDomainRegExp,
      'Use google sso to sign up with your voggt email',
    )
    .required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export type SignInOwnUserDto = yup.InferType<typeof ownUserSignInSchema>;
