import { PaymentStatus } from '@bits-app/voggtpit-shared';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { EnumFilterTable } from '@Elements/filters/table';

const PAYMENT_STATUS = Object.values(PaymentStatus) as PaymentStatus[];

type PaymentStatusFilterProps = {
  activeFilters?: PaymentStatus[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
};

export const PaymentStatusFilter = ({
  addFilter,
  activeFilters = [],
  isUnsaved,
}: PaymentStatusFilterProps) => {
  return (
    <EnumFilterTable
      options={PAYMENT_STATUS.map((value) => ({ value }))}
      addFilter={addFilter}
      field="paymentStatus"
      activeFilters={activeFilters}
      isUnsaved={isUnsaved}
    />
  );
};
