import { ShippingAddress } from '@bits-app/bits-server-data';
import { Schema } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';

import { useIdParam } from '@/hooks/use-id-params';
import { validationSchemasBuilder } from '@/voggt-database-explorer';
import { UpsertForm } from '@/voggt-database-explorer/components/upsert-view/UpsertForm';
import { EntityWrapper } from '@/voggt-database-explorer/redux/database-explorer';

import { useCreateShippingAddress } from './use-create-shipping-address';

export const ShippingAddressCreationView = () => {
  const { t } = useTranslation();

  const shippingAddressId = useIdParam();

  const { header, schemas, onSubmit } = useCreateShippingAddress();
  const validationSchemaBuilder = validationSchemasBuilder['Shipping-Address'];

  const goBackName = t('databaseExplorer.menu.shipping-address', { shippingAddressId });

  if (!schemas.length || !header) {
    return null;
  }

  const handleOnUpsert = (shippingAddress: Partial<ShippingAddress>) =>
    onSubmit({ originalShippingAddressId: +shippingAddressId, shippingAddress });

  return (
    <UpsertForm<ShippingAddress>
      schemas={schemas as Schema<EntityWrapper>[]}
      header={header}
      validationSchemaBuilder={validationSchemaBuilder}
      onUpsert={handleOnUpsert}
      goBackName={goBackName}
    />
  );
};
