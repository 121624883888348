import { createTheme as createMuiTheme } from '@mui/material';

import { createComponents } from './create-components';
import { createPalette } from './create-palette';
import { createShadows } from './create-shadows';
import { createTypography } from './create-typography';

export const createTheme = () => {
  const palette = createPalette();
  const components = createComponents();
  const shadows = createShadows();
  const typography = createTypography();

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    typography,
    components,
    palette,
    shadows,
    shape: {
      borderRadius: 8,
    },
  });
};

export const theme = createTheme();

export const IconWidth = {
  small: theme.typography.pxToRem(20),
  medium: theme.typography.pxToRem(24),
  large: theme.typography.pxToRem(36),
};

export const Breakpoints = {
  mobile: `@media (max-width: 576px)`,
};
