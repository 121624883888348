import {
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Show } from './Show';
import { User } from './User';

@Entity()
export class UserBannedFromShow {
  @Index()
  @PrimaryColumn()
  userId!: number;

  @Index()
  @PrimaryColumn()
  showId!: number;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date | null;

  @DeleteDateColumn({ name: 'deletedAt', type: 'timestamp' })
  deletedAt!: Date | null;

  @ManyToOne(() => Show, (show) => show.bannedUsersRelations)
  @JoinColumn({
    name: 'showId',
    referencedColumnName: 'id',
  })
  show!: Show;

  @ManyToOne(() => User, (user) => user.bannedFromShowsRelations)
  @JoinColumn({
    name: 'userId',
    referencedColumnName: 'id',
  })
  user!: User;
}
