import { Link } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';

type InternalLinkProps = {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
};
export const InternalLink = ({ to, children, onClick }: InternalLinkProps) => {
  return (
    <Link
      sx={{
        color: ({ palette }) => palette.info.dark,
        fontWeight: 'bold',
      }}
      component={ReactRouterLink}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
