import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@Elements/buttons/LoadingButton';

import { DraftBadge } from '../../../entity/draft-badge';

type PersistDraftBadgeModalProps = {
  draftBadge: DraftBadge;
  onPersist: (id: DraftBadge['id']) => void;
  onClose: () => void;
  isLoading: boolean;
};

export const PersistDraftBadgeModal = ({
  onClose,
  draftBadge,
  onPersist,
  isLoading,
}: PersistDraftBadgeModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        {t('databaseExplorer.badge.persist.title', { name: draftBadge.name })}

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%', gap: 2 }}
      >
        <Typography>{t('databaseExplorer.badge.persist.content')}</Typography>
      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: ({ spacing }) => spacing(3),
          paddingLeft: ({ spacing }) => spacing(3),
          paddingBottom: ({ spacing }) => spacing(2),
        }}
      >
        <LoadingButton
          loading={isLoading}
          onClick={() => onPersist(draftBadge.id)}
          variant="contained"
        >
          {t('databaseExplorer.badge.persist.action')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
