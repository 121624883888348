import { authActions } from '@/auth/auth.slice';
import { BadRequestError } from '@/domain/errors/BadRequestError';
import { NotFoundError } from '@/domain/errors/NotFoundError';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { AppThunkAction } from '@/redux/store';

import { selectIsOrderItemRefundable } from '../redux/refund.selectors';
import { refundActions } from '../redux/refund.slice';

import { addOrderItemInRefund } from './add-order-item-in-refund';

export const startRefundOrderItem =
  (orderItemId: number): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { refundGateway, snackbarGateway }) => {
    dispatch(refundActions.setRefundLoading(true));

    try {
      const orderItems: OrderItemRefund[] = await refundGateway.getSiblingsOrderItems(orderItemId);

      dispatch(refundActions.setMainOrderItemId(orderItemId));
      dispatch(refundActions.setEntities(orderItems));

      const orderItem = orderItems.find(({ id }) => id === orderItemId);

      if (!orderItem) {
        return;
      }

      const isOrderItemRefundable = selectIsOrderItemRefundable(getState(), orderItem);

      if (isOrderItemRefundable) {
        dispatch(addOrderItemInRefund(orderItemId));
      }
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      dispatch(refundActions.setRefundError(String(error)));

      if (error instanceof NotFoundError) {
        snackbarGateway.error('refund.orderItemNotFound', {
          interpolationMap: { id: orderItemId },
        });
        return;
      }

      if (error instanceof BadRequestError) {
        snackbarGateway.error('refund.malformedId', {
          interpolationMap: { id: orderItemId },
        });
        return;
      }

      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(refundActions.setRefundLoading(false));
    }
  };
