import { useState, useEffect, useCallback } from 'react';

import { useTypesenseContext } from '@/context/typesense.context';
import logger from '@/logger/logger';

import {
  useSearchVoggtResponseType,
  useSearchVoggtPayloadType,
  searchType,
  SearchResponse,
} from './use-typesense';

export function useTypesenseSearch<T extends { id: string | number }>(
  { typesenseIndex, typesenseParams, sortBy, filterBy }: useSearchVoggtPayloadType<T>,
  onSearchChange?: (value: string) => void,
): useSearchVoggtResponseType<T> {
  const [ownUserSearch, setSearch] = useState<string>('');
  const [results, setResult] = useState<T[]>([]);
  const [resultMap, setResultMap] = useState<Record<string, T>>({});
  const [wholeResult, setWholeResult] = useState<Omit<SearchResponse<T>, 'hits'>>();
  const [loading, setLoading] = useState(false);
  const { typesenseClient } = useTypesenseContext();

  const fetchData = useCallback(
    async (controller: AbortController) => {
      if (!typesenseClient) return;

      setLoading(true);

      try {
        const result = await search<T>({
          ownUserSearch,
          typesenseIndex,
          sortBy,
          typesenseParams,
          signal: controller.signal,
          typesenseClient,
          filterBy,
        });
        if (!result || !result?.hits) return;
        const { hits, ...typesearchResult } = result;
        const _result = hits.map((hit) => hit.document) as T[];
        setResult(_result);
        const resultMap = _result.reduce<Record<string, T>>((all, r) => {
          all[r.id] = r;
          return all;
        }, {});
        setResultMap(resultMap);
        setWholeResult(typesearchResult);

        setLoading(false);
      } catch (e) {
        const AbortControllerErrorMessage = 'Request aborted by caller.';
        const isKnownError = e instanceof Error && e.message === AbortControllerErrorMessage;
        if (!isKnownError) {
          logger.warn(e);
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterBy,
      ownUserSearch,
      sortBy,
      typesenseClient,
      typesenseIndex,
      typesenseParams.page,
      typesenseParams.per_page,
      typesenseParams.filter_by,
    ],
  );

  useEffect(() => {
    if (!typesenseClient) return;
    const controller = new AbortController();

    fetchData(controller);

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ownUserSearch,
    typesenseParams.page,
    typesenseParams.per_page,
    typesenseParams.filter_by,
    typesenseIndex,
    sortBy,
    filterBy,
    typesenseClient,
    fetchData,
  ]);

  const handleSetSearch = useCallback(
    (value: string) => {
      setSearch(value);
      onSearchChange?.(value);
    },
    [onSearchChange],
  );

  return {
    setSearch: handleSetSearch,
    results,
    ownUserSearch,
    resultMap,
    wholeResult,
    loading,
    fetchData,
  };
}

async function search<T extends { id: string | number }>({
  ownUserSearch,
  typesenseIndex,
  typesenseParams,
  sortBy,
  signal,
  typesenseClient,
  filterBy,
}: searchType<T>) {
  const query_by = typesenseParams.query_by?.join(',') + ',databaseId';
  return typesenseClient
    .collections<T>(typesenseIndex)
    .documents()
    .search(
      {
        q: ownUserSearch || '*',
        sort_by: sortBy,
        ...typesenseParams,
        query_by,
        filter_by: filterBy,
      },
      { abortSignal: signal },
    );
}
