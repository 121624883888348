import { PromotionType } from '@bits-app/voggtpit-shared';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { EnumFilterTable } from '@Elements/filters/table';

const PROMOTION_TYPE = Object.values(PromotionType) as PromotionType[];

type PromotionTypeFilterProps = {
  activeFilters?: PromotionType[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
};

export const PromotionTypeFilter = ({
  addFilter,
  activeFilters = [],
  isUnsaved,
}: PromotionTypeFilterProps) => {
  return (
    <EnumFilterTable
      options={PROMOTION_TYPE.map((value) => ({ value }))}
      addFilter={addFilter}
      field="type"
      activeFilters={activeFilters}
      isUnsaved={isUnsaved}
    />
  );
};
