import Ico from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

import { Button } from '@Elements/buttons/Button';

type EditButtonProps = {
  onClick: () => void;
  hide: boolean;
};

export const EditButton = ({ onClick, hide }: EditButtonProps) => {
  const { t } = useTranslation();

  if (hide) {
    return null;
  }

  return (
    <Button
      variant="text"
      endIcon={<Ico fontSize="small" />}
      sx={{ fontWeight: 'bold' }}
      onClick={onClick}
    >
      {t('action.edit')}
    </Button>
  );
};
