import { unitToCents } from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import { OrderItemRefund, orderItemRefundDtoToEntity } from '@/entities/order-item-refund.entity';
import logger from '@/logger/logger';

import { RefundDetails, RefundPort } from './refund.port';

export class HttpRefundAdapter implements RefundPort {
  async saveRefund(refundDetails: RefundDetails): Promise<OrderItemRefund[]> {
    try {
      const response = await authenticatedInstance.post('/refund', {
        ...refundDetailsWithCentAmounts(refundDetails),
      });

      return response.data.map(orderItemRefundDtoToEntity);
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }

  async getSiblingsOrderItems(orderItemRefundId: number): Promise<OrderItemRefund[]> {
    try {
      const result = await authenticatedInstance.get(`/refund/order-item/${orderItemRefundId}`);

      const orderItemRefunds = result.data.map(orderItemRefundDtoToEntity);

      return orderItemRefunds;
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }

  async getOrder(orderId: string): Promise<OrderItemRefund[]> {
    try {
      const result = await authenticatedInstance.get(`/refund/order-item/order/${orderId}`);

      const orderItemRefunds = result.data.map(orderItemRefundDtoToEntity);

      return orderItemRefunds;
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }
}

const refundDetailsWithCentAmounts = (refundDetails: RefundDetails): RefundDetails => ({
  ...refundDetails,
  orderItems: Object.entries(refundDetails.orderItems).reduce(
    (formatted, [id, { amount, refundFullOrder, refundComissionToSeller }]) => {
      return {
        ...formatted,
        [id]: {
          refundComissionToSeller,
          refundFullOrder,
          amount: unitToCents(amount),
        },
      };
    },
    {} as RefundDetails['orderItems'],
  ),
});
