import type { SellerNews } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { EditButton } from '@/voggt-database-explorer/components/EditButton';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';
import { ItemLayout, ItemTab } from '@/voggt-database-explorer/layouts/ItemLayout';

import { panelInformation, statusPanelInformation, timestampPanelInformation } from '../const';
import { useSellerNews } from '../hooks/use-seller-news';
export const SellerNewsDetail = () => {
  const sellerNewsId = useIdParam();
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();

  const canEdit = hasPermissions(PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_SELLER_NEWS);
  const handleOnTabChange = useNavigateInDetailTab(`/seller-news/${sellerNewsId}`);

  const { data, refetch, isLoading } = useSellerNews(+sellerNewsId);

  const sellerNews = Array.isArray(data) ? data[0] : data;

  const tabs: ItemTab[] = [
    {
      Element: (
        <SellerNewsComponent
          key="/"
          canEdit={canEdit}
          sellerNews={sellerNews}
          loading={isLoading}
        />
      ),
      label: t('databaseExplorer.product.tabs.details'),
      path: '/',
    },
  ];

  if (!sellerNewsId) {
    return null;
  }

  return (
    <ItemLayout<SellerNews>
      id={sellerNewsId}
      tabs={tabs}
      currentTab={'/'}
      onTabChange={handleOnTabChange}
      editionDescriptor={
        canEdit
          ? {
              goBackName: t('databaseExplorer.editionFormPath.sellerNews', { sellerNewsId }),
              resourceId: sellerNewsId,
              resourceType: 'Seller-News',
              onSuccess: refetch,
            }
          : undefined
      }
    />
  );
};

type SellerNewsComponentProps = {
  canEdit: boolean;
  sellerNews?: SellerNews;
  loading: boolean;
};

const SellerNewsComponent = ({ canEdit, sellerNews, loading }: SellerNewsComponentProps) => {
  const sellerNewsId = useIdParam();
  const { t } = useTranslation();

  if (!sellerNews) return null;

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'} rowGap={2}>
          <Stack spacing={2} direction="row" ml="auto" pr={1} pl={1} alignItems="center">
            {canEdit && <EditButton to={`/seller-news/${sellerNewsId}/edit`} />}
            <ForestAdminLink entity="sellerNews" entityId={sellerNewsId} />
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12}>
            <PanelDetails<SellerNews>
              title={t('databaseExplorer.panel.main')}
              informations={panelInformation}
              data={sellerNews}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <Grid xs={12}>
            <PanelDetails<SellerNews>
              title={t('databaseExplorer.panel.start')}
              informations={statusPanelInformation}
              data={sellerNews}
              loading={loading}
              labelRow
            />
          </Grid>
          <Grid xs={12}>
            <PanelDetails<SellerNews>
              title={t('databaseExplorer.panel.timestamp')}
              informations={timestampPanelInformation}
              data={sellerNews}
              loading={loading}
              labelRow
            />
          </Grid>
        </Grid>
      </Grid>
    </DetailsLayout>
  );
};
