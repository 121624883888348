import { useMemo } from 'react';

import { Category, Emote } from '@bits-app/bits-server-data';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { ItemLayout, ItemTab } from '@/voggt-database-explorer/layouts/ItemLayout';

import { Detail } from './Detail';
import { useEmoteDetailsView } from './useEmoteDetailsView';

export const EmoteDetail = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const emoteId = useIdParam();

  const { CellCategoryDefinition, emote, loading } = useEmoteDetailsView(emoteId);

  const currentTab = useMemo(() => {
    return ['category'].find((path) => location.pathname.includes(path)) ?? '/';
  }, [location.pathname]);

  const handleOnTabChange = useNavigateInDetailTab(`/emote/${emoteId}`);

  const tabs: ItemTab[] = useMemo(() => {
    const allTabs: ItemTab[] = [
      {
        path: '/',
        label: t('databaseExplorer.emote.tabs.main'),
        Element: <Detail key="/" emote={emote} loading={loading} />,
      },

      {
        path: 'category',
        label: t('databaseExplorer.emote.tabs.category'),
        Element: (
          <ListCustom<Category>
            key="category"
            cellDefinition={CellCategoryDefinition}
            results={emote?.categories ?? []}
            getPathDetail={(category) => `/category/parent/${category.id}`}
          />
        ),
      },
    ];

    return allTabs;
  }, [CellCategoryDefinition, emote, loading, t]);

  return (
    <ItemLayout<Emote>
      id={emoteId}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
    />
  );
};
