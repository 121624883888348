import { t } from 'i18next';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { OrderItem } from '@/entities/order-item.entity';
import { AppThunkAction } from '@/redux/store';

import { selectOrderItemsMassUpdate } from '../redux/order-items-mass-update.selectors';
import { orderItemsMassUpdateActions } from '../redux/order-items-mass-update.slice';

export const massUpdateOrderItemsTrackingNumber =
  ({
    ids,
    trackingNumber,
  }: {
    ids: number[];
    trackingNumber: OrderItem['trackingNumber'];
  }): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { orderItemsMassUpdateGateway, snackbarGateway }) => {
    dispatch(orderItemsMassUpdateActions.setUpdating(true));

    try {
      await orderItemsMassUpdateGateway.updateOrderItemsTrackingNumber(ids, trackingNumber);

      const orderItems = selectOrderItemsMassUpdate(getState());

      dispatch(
        orderItemsMassUpdateActions.setEntities(
          orderItems.map((orderItem) => {
            if (ids.includes(orderItem.id)) {
              return {
                ...orderItem,
                trackingNumber,
              };
            }

            return orderItem;
          }),
        ),
      );

      snackbarGateway.success('orderItemsMassUpdate.successfulUpdate', {
        interpolationMap: {
          count: ids.length,
          value: trackingNumber ?? '',
          label: t('orderItemLabels.trackingNumber') as string,
        },
      });
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(orderItemsMassUpdateActions.setUpdating(false));
    }
  };
