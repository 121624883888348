import { createContext, useCallback, useContext, useState } from 'react';

import { Alert, Snackbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type SnackbarDefinition = {
  id: string;
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  interpolationMap?: Record<string, string | number>;
  subText?: string | string[];
};

type SnackbarContext = {
  snackbars: SnackbarDefinition[];
  addSnackbar: (snackbar: SnackbarDefinition) => void;
  removeSnackbar: (snackbar: SnackbarDefinition) => void;
};

const snackbarContext = createContext({} as SnackbarContext);

export const useSnackbarContext = () => useContext(snackbarContext);

// cspell:words snackbars
export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [snackbars, setSnackbars] = useState<SnackbarDefinition[]>([]);

  const { t } = useTranslation();

  const addSnackbar = useCallback((snackbar: SnackbarDefinition) => {
    setSnackbars((snacks) => [...snacks, snackbar]);
  }, []);

  const removeSnackbar = useCallback((snackbar: SnackbarDefinition) => {
    setSnackbars((snacks) => snacks.filter(({ id }) => id !== snackbar.id));
  }, []);

  const copySubText = (text: string) => navigator.clipboard.writeText(text);

  return (
    <snackbarContext.Provider
      value={{
        snackbars,
        addSnackbar,
        removeSnackbar,
      }}
    >
      {children}

      {snackbars.map((snackbar) => (
        <Snackbar
          key={snackbar.id}
          open
          autoHideDuration={snackbar.type === 'error' ? 8000 : 4000}
          onClose={() => removeSnackbar(snackbar)}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <Alert
            onClose={() => removeSnackbar(snackbar)}
            severity={snackbar.type}
            variant="filled"
            elevation={6}
            sx={{ width: '100%', alignItems: 'center', display: 'flex' }}
          >
            {t(snackbar.message, snackbar.interpolationMap)}

            <div className="flex flex-col">
              {potentialStringToArray(snackbar.subText).map((t) => (
                <Typography
                  component="div"
                  variant="caption"
                  onClick={() => copySubText(t)}
                  sx={{
                    cursor: 'copy',
                  }}
                >
                  {t}
                </Typography>
              ))}
            </div>
          </Alert>
        </Snackbar>
      ))}
    </snackbarContext.Provider>
  );
};

const potentialStringToArray = (text?: string | string[]) => {
  if (!text) return [];
  return typeof text === 'string' ? [text] : text;
};
