export enum PaymentType {
  bancontact = 'bancontact',
  card = 'card',
  giropay = 'giropay',
  ideal = 'ideal',
  paypal = 'paypal',
  paypal_recurring_payment = 'paypal_recurring_payment',
}

export function throwUnknownPaymentTypeError(paymentType: PaymentType): never {
  throw new Error(`Unknown payment type: '${paymentType}'`);
}

export function getPaymentTypeDescription(paymentType: PaymentType) {
  switch (paymentType) {
    case PaymentType.bancontact:
      return 'Bancontact';
    case PaymentType.card:
      return 'card';
    case PaymentType.giropay:
      return 'Giropay';
    case PaymentType.ideal:
      return 'iDEAL';
    case PaymentType.paypal:
    case PaymentType.paypal_recurring_payment:
      return 'PayPal';
    default:
      return throwUnknownPaymentTypeError(paymentType);
  }
}
