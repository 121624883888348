import { Country } from '@bits-app/bits-server-data';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { EnumFilterTable } from '@Elements/filters/table';

type CountryFilterProps = {
  activeFilters?: Country['id'][];
  countries: Country[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
};

export const CountryFilter = ({
  addFilter,
  countries,
  activeFilters = [],
  isUnsaved,
}: CountryFilterProps) => {
  return (
    <EnumFilterTable
      options={countries.map(({ id }) => ({ value: id, label: id }))}
      addFilter={addFilter}
      field="country"
      activeFilters={activeFilters}
      isUnsaved={isUnsaved}
    />
  );
};
