import { OrderItemsFindOptions } from '@bits-app/voggtpit-shared';

import { authActions } from '@/auth/auth.slice';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { AppThunkAction } from '@/redux/store';

import { orderItemsMassUpdateActions } from '../redux/order-items-mass-update.slice';

export const getOrderItemsFromOptions =
  (options: OrderItemsFindOptions): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { orderItemsMassUpdateGateway, snackbarGateway }) => {
    dispatch(orderItemsMassUpdateActions.setLoading(true));
    dispatch(orderItemsMassUpdateActions.setEntities([]));

    try {
      const { items: orderItems, count } = await orderItemsMassUpdateGateway.getOrderItems(
        options,
        { abortPrevious: true },
      );

      dispatch(orderItemsMassUpdateActions.setEntities(orderItems));
      dispatch(orderItemsMassUpdateActions.setCount(count));
      dispatch(orderItemsMassUpdateActions.setLoading(false));
    } catch (error) {
      if (error instanceof CanceledRequestError) {
        return;
      }

      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));
        dispatch(orderItemsMassUpdateActions.setLoading(false));

        return;
      }

      dispatch(orderItemsMassUpdateActions.setLoading(false));
      snackbarGateway.error('somethingWrongHappened');
    }
  };
