import { MarketPageType } from '@bits-app/voggtpit-shared';

import { Information } from '../components/layouts/PanelDetails';

export const panelDetailsMarketPage: Information<MarketPageType>[] = [
  { value: 'id', label: 'Id' },
  { value: 'categoryId', label: 'Category Id' },
  { value: 'coverUrl', label: 'Cover Url' },
  { value: 'slug', label: 'Slug' },
  { value: 'description', label: 'Description' },
  { value: 'title', label: 'Title' },
  { value: 'countryIds', label: 'Country Ids' },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
];
