import { useMutation } from '@tanstack/react-query';
import { parse as parseCsv } from 'papaparse';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';

export type LedgerManualAdjustementRowBulk = {
  sellerId: string;
  amount: number;
  description: string;
  orderId: string;
  showId: string;
  status: string;
};

export const handleFileLoaded = (event: ProgressEvent<FileReader>) => {
  const { result } = event.target as FileReader;

  const { data } = parseCsv<LedgerManualAdjustementRowBulk>(result as string, { header: true });

  const errors: { row: number; error: string }[] = [];
  const validData: LedgerManualAdjustementRowBulk[] = [];
  data.forEach((row, index) => {
    if (!row.sellerId) {
      errors.push({ error: 'sellerId is required', row: index + 1 });
      return;
    }
    if (!row.amount) {
      errors.push({ error: 'amount is required', row: index + 1 });
      return;
    }
    if (!row.description) {
      errors.push({ error: 'description is required', row: index + 1 });
      return;
    }
    if (!row.status || !['DONE', 'READY'].includes(row.status)) {
      errors.push({ error: 'status is invalid', row: index + 1 });
      return;
    }
    validData.push(row);
    return;
  });

  return { data: validData, errors };
};

export const useStartProcess = () => {
  const snackbar = useSnackbar();
  return useMutation(
    (data: LedgerManualAdjustementRowBulk[]) => {
      return authenticatedInstance.post('/ledger/bulk-update-csv', data);
    },
    {
      onSuccess: () => {
        snackbar.success('databaseExplorer.users.paymentDirectTransfer.bulk.success');
      },
      onError: () => {
        snackbar.error('somethingWentWrong');
      },
    },
  );
};
