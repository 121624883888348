import { useEffect, useMemo } from 'react';

import { Badge, User } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { Information } from '@/components/layouts/PanelDetails';
import { QUERIES_KEYS } from '@/queries';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { DraftBadge } from '@/voggt-database-explorer/badge/entity/draft-badge';
import { getBadgeRelatedUsersCount } from '@/voggt-database-explorer/badge/use-cases/get-badge-related-users-count';
import { getDraftBadge } from '@/voggt-database-explorer/badge/use-cases/get-draft-badge';
import { selectResourceLoading } from '@/voggt-database-explorer/redux/database-explorer.slice';
import {
  getResource,
  selectResource,
  useResourceSelector,
} from '@/voggt-database-explorer/redux/use-cases/get-resource';

export const useBadgeDetailsView = (badgeId: string, isDraft?: boolean) => {
  const dispatch = useAppDispatch();
  const badge = useResourceSelector<Badge>(selectResource);
  const loading = useAppSelector(selectResourceLoading);

  const { data: badgeRelatedUsersCount } = useQuery<number>(
    QUERIES_KEYS.badgeRelatedUsersCount(Number(badgeId)),
    () => getBadgeRelatedUsersCount(Number(badgeId)),
    { enabled: !isDraft },
  );

  const { data: draftBadge, isFetching: isDraftBadgeLoading } = useQuery<DraftBadge>(
    QUERIES_KEYS.draftBadge(badgeId),
    () => getDraftBadge(badgeId),
    { enabled: Boolean(isDraft) },
  );

  const CellUserDefinition = useMemo(
    (): CellDefinition<User>[] => [
      {
        id: 'id',
        label: 'Id',
        type: 'internal-link',
        getLink: (user) => ({ label: user.id, link: `/user/${user.id}` }),
      },
      {
        id: 'username',
        label: 'username',
      },
      {
        id: 'isSelected',
        label: 'Is Selected',
      },
      {
        id: 'isSeller',
        label: 'Is Seller',
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date',
      },
    ],
    [],
  );

  useEffect(() => {
    if (isDraft) {
      return;
    }

    if (badgeId && badge.id !== Number(badgeId)) {
      dispatch(getResource(+badgeId, 'Badge'));
    }
  }, [badgeId, badge.id, dispatch, isDraft]);

  const returnedBadge: Badge | DraftBadge = useMemo(() => {
    if (isDraft && draftBadge) {
      return draftBadge;
    }

    return badge;
  }, [badge, draftBadge, isDraft]);

  return {
    badge: returnedBadge,
    loading: loading || isDraftBadgeLoading,
    CellUserDefinition,
    badgeRelatedUsersCount,
  };
};

export const panelDetailsBadge: Information<Badge | DraftBadge>[] = [
  { value: 'id', label: 'id' },
  {
    value: 'filename',
    label: 'Image',
    type: 'image',
    format: (badge) => `${process.env.REACT_APP_CDN_UNOPTIMIZED_BADGES_URL}/${badge?.filename}`,
  },
  { value: 'name', label: 'Name' },
  { value: 'description', label: 'Description' },
  { value: 'colorAccent', label: 'Color accent' },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
];
