import { useState, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useUnsavedWarning } from '@/context/unsaved.context';
import { AddFilterFunctionParams } from '@/hooks/use-table-options';

type UseEnumFilter<T> = {
  options: { value: T; label?: string }[];
  field: AddFilterFunctionParams['field'];
  activeFilters: T[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
  withEmptyOption?: boolean;
};

export const useEnumFilter = <T extends string>({
  activeFilters,
  addFilter,
  field,
  isUnsaved,
  options,
  withEmptyOption,
}: UseEnumFilter<T>) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<(T | '')[]>(activeFilters);

  const availableOptions: { value: T | ''; label?: string }[] = withEmptyOption
    ? [...options, { value: '', label: t('empty') }]
    : options;

  const onConfirmAfterWarning = useUnsavedWarning();

  useEffect(() => {
    if (activeFilters.length === 0) {
      if (checked.length > 0) {
        setChecked([]);
      }

      return;
    }

    if (options.length === 0 && checked.length === 0) {
      setChecked(activeFilters);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  const isApplyButtonDisabled: boolean = useMemo(() => {
    return JSON.stringify(checked) === JSON.stringify(activeFilters);
  }, [checked, activeFilters]);

  const handleToggle = (value: T | '') => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleAll = () => () => {
    if (checked.length === availableOptions.length) {
      setChecked([]);
      return;
    }

    setChecked(availableOptions.map(({ value }) => value));
  };

  const handleApplyFilter = () => {
    if (isUnsaved) {
      onConfirmAfterWarning(() => addFilter({ field, values: checked }));
      return;
    }

    addFilter({ field, values: checked });
  };

  const handleOnClose = () => {
    setChecked(activeFilters);
  };

  return {
    availableOptions,
    checked,
    isApplyButtonDisabled,
    onToggle: handleToggle,
    onToggleAll: handleToggleAll,
    onApplyFilter: handleApplyFilter,
    onClose: handleOnClose,
  };
};
