export enum RefundReason {
  misclick = 'Misclick',
  lostParcel = 'Lost parcel',
  deliveredByHand = 'Delivered by hand',
  changeOfMind = 'Change of mind',
  missingProduct = 'Missing product',
  damagedProduct = 'Damaged product',
  sellerMistake = 'Seller mistake',
  freeShipping = 'Free shipping',
  badProtection = 'Bad protection',
  productLostBeforeShipping = 'Product lost before shipping',
  counterfeit = 'Counterfeit',
  delight = 'Delight',
  others = 'Others',
  fraud = 'Fraud',
}
