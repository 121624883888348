import { Promotion } from '@bits-app/voggtpit-shared';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { promotionsActions } from '../redux/promotions.slice';

export const expirePromotion =
  (id: Promotion['id']): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { promotionsGateway, snackbarGateway }) => {
    dispatch(promotionsActions.setUpdating(true));

    try {
      const promotion = await promotionsGateway.expirePromotion(id);

      dispatch(promotionsActions.updatePromotion(promotion));

      snackbarGateway.success('promotion.expiration.confirmation.success');
    } catch (error) {
      logger.error(error);

      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(promotionsActions.setUpdating(false));
    }
  };
