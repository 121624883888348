import { Checkbox, TableRow } from '@mui/material';

import { InternalLink } from '@/components/elements/InternalLink';
import {
  TableCellBodyNoBorderBottom,
  TableCellEllipsisContent,
} from '@/components/elements/StyledTableCell';
import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import { toggleOrderItemToRefund } from '../../../../refund/use-cases';
import {
  selectIsOrderItemInTheRefund,
  selectIsOrderItemRefundable,
} from '../../../redux/refund.selectors';

type OrderItemRowProps = {
  orderItem: OrderItemRefund;
  isReadOnly: boolean;
};

export const StickyOrderItemRow = ({ orderItem, isReadOnly }: OrderItemRowProps) => {
  const isOrderItemRefundable = useAppSelector((state) =>
    selectIsOrderItemRefundable(state, orderItem),
  );
  const selected = useAppSelector((state) => selectIsOrderItemInTheRefund(state, orderItem.id));

  const dispatch = useAppDispatch();

  const handleOnOrderItemToggle = () => {
    dispatch(toggleOrderItemToRefund(orderItem.id));
  };

  return (
    <TableRow sx={{ opacity: !isOrderItemRefundable ? 0.7 : 1 }}>
      {!isReadOnly && (
        <TableCellBodyNoBorderBottom padding="checkbox">
          <Checkbox
            checked={selected}
            disabled={!isOrderItemRefundable}
            onChange={handleOnOrderItemToggle}
          />
        </TableCellBodyNoBorderBottom>
      )}
      <TableCellBodyNoBorderBottom minWidth={70}>
        <InternalLink to={`/order/${orderItem.id}`}>{orderItem.id}</InternalLink>
      </TableCellBodyNoBorderBottom>

      <TableCellEllipsisContent maxWidth={140} title={orderItem.product}>
        {orderItem.product}
      </TableCellEllipsisContent>

      <TableCellBodyNoBorderBottom minWidth={70}>
        <InternalLink to={`/show/${orderItem.showId}`}>{orderItem.showId}</InternalLink>
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={70}>{orderItem.buyerId}</TableCellBodyNoBorderBottom>

      <TableCellEllipsisContent maxWidth={130} title={orderItem.buyer}>
        <InternalLink to={`/user/${orderItem.buyerId}`}>{orderItem.buyer}</InternalLink>
      </TableCellEllipsisContent>

      <TableCellEllipsisContent maxWidth={130} title={orderItem.seller}>
        <InternalLink to={`/user/${orderItem.sellerId}`}>{orderItem.seller}</InternalLink>
      </TableCellEllipsisContent>
    </TableRow>
  );
};
