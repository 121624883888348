import { LedgerPayoutList } from '@bits-app/voggtpit-shared';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../../axios/axios.instance';
import { QUERIES_KEYS } from '../../../../queries';

export const useLedgerPayoutList = ({
  startAt,
  endAt,
  page = 1,
}: {
  startAt: number;
  endAt: number;
  page: number;
}) => {
  return useQuery(QUERIES_KEYS.ledgerPayoutList(startAt, endAt, page), queryLedgerPayoutList, {
    enabled: false,
  });
};

async function queryLedgerPayoutList({ queryKey }: { queryKey: QueryKey }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_method, _path1, _path2, startAt, endAt, page] = queryKey;
  console.log(queryKey);
  return authenticatedInstance.get<{ results: LedgerPayoutList; totalCount: number }>(
    '/ledger/list-payout?startAt=' + startAt + '&endAt=' + endAt + '&page=' + page,
  );
}
