export abstract class BaseHTTPGateway {
  protected abortControllers: Map<string, AbortController> = new Map();

  protected handleAbortPrevious(abortPrevious: boolean, from: string) {
    if (abortPrevious) {
      const currentController = this.abortControllers.get(from);

      currentController?.abort();
    }

    const controller = new AbortController();
    this.abortControllers.set(from, controller);

    return controller.signal;
  }
}
