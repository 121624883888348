import { Link as MUILink, LinkProps } from '@mui/material';

export const ExternalLink = ({ sx = {}, label, ...props }: LinkProps & { label: string }) => (
  <MUILink
    {...props}
    sx={{
      fontSize: 14,
      fontWeight: 'bold',
      textDecoration: 'none',
      ...sx,
    }}
    target={'_blank'}
  >
    {label}
  </MUILink>
);
