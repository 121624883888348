import { OrderItemsDistinctValuesFindOptions } from '@bits-app/voggtpit-shared';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { FreeValueFilter } from '@Elements/filters/table';

import {
  selectOrderItemsMassUpdateFilterLoadingFor,
  selectOrderItemsMassUpdateFilterValuesFor,
} from '../../../redux/order-items-mass-update.selectors';
import {
  getOrderItemsFilterValuesFor,
  getOrderItemsNextPageFilterValuesFor,
} from '../../../use-cases';

type OrderItemsMassUpdateFreeFilterProps = {
  activeFilters?: string[];
  field: AddFilterFunctionParams['field'];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isValueValid?: (value: string) => boolean;
  inputPlaceholder: string;
  isUnsaved: boolean;
  disabled?: boolean;
};

export const OrderItemsMassUpdateFreeFilter = (props: OrderItemsMassUpdateFreeFilterProps) => {
  const dispatch = useAppDispatch();
  const values = useAppSelector((state) =>
    selectOrderItemsMassUpdateFilterValuesFor(state, props.field),
  );

  const getFilterValuesFor = ({ field, search }: { field: string; search: string }) => {
    if (!isFieldAvailable(field)) {
      return;
    }

    dispatch(getOrderItemsFilterValuesFor({ field, search }));
  };

  const getNextPageFilterValuesFor = ({
    field,
    search,
    page,
  }: {
    field: string;
    search: string;
    page: number;
  }) => {
    if (!isFieldAvailable(field)) {
      return;
    }

    dispatch(getOrderItemsNextPageFilterValuesFor({ field, search, page }));
  };

  const loading = useAppSelector((state) =>
    selectOrderItemsMassUpdateFilterLoadingFor(state, props.field),
  );

  return (
    <FreeValueFilter
      {...props}
      getFilterValuesFor={getFilterValuesFor}
      getNextPageFilterValuesFor={getNextPageFilterValuesFor}
      values={values}
      loading={loading}
    />
  );
};

const isFieldAvailable = (
  field: AddFilterFunctionParams['field'],
): field is OrderItemsDistinctValuesFindOptions['field'] =>
  ['orderId', 'id', 'showId', 'buyer', 'seller', 'trackingNumber', 'shippingProvider'].includes(
    field,
  );
