import { Tab, Tabs } from '@mui/material';
import { Outlet, Route, Routes } from 'react-router-dom';

import { EditionView } from '@/voggt-database-explorer/components/edition-view/EditionView';
import { EntityWrapper, ResourceType } from '@/voggt-database-explorer/redux/database-explorer';

type EditionDescriptor = {
  goBackName: string;
  resourceId: EntityWrapper['id'];
  resourceType: ResourceType;
};

type ItemTab = {
  label: string;
  path: string;
  Element: React.ReactElement;
};

type ItemLayoutProps = {
  currentTab: string;
  onTabChange: (value: string) => void;
  tabs: ItemTab[];
  editionDescriptor?: EditionDescriptor;
};

export const ItemLayoutWithTabs = <T extends EntityWrapper>({
  currentTab,
  onTabChange,
  tabs,
  editionDescriptor,
}: ItemLayoutProps) => {
  return (
    <Routes>
      <Route
        element={
          <div className="h-full flex flex-col flex-1 p-4">
            <Tabs
              value={currentTab}
              onChange={(_: React.SyntheticEvent, value: string) => onTabChange(value)}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ mb: 3 }}
            >
              {tabs.map(({ path, label }) => (
                <Tab key={path} label={label} value={path} sx={{ textTransform: 'none' }} />
              ))}
            </Tabs>
            <Outlet />
          </div>
        }
      >
        {tabs.map(({ path, Element }) => (
          <Route key={path} path={path} element={Element} />
        ))}
      </Route>

      {editionDescriptor && (
        <Route path="edit" element={<EditionView<T> {...editionDescriptor} />} />
      )}
    </Routes>
  );
};
