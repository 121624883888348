import { useState } from 'react';

import { Badge } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { useOwnUser } from '@/context/own-user.context';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { persistBadge } from '@/voggt-database-explorer/badge/use-cases/persist-badge';

import { DraftBadge } from '../../../entity/draft-badge';

export const usePersistDraftBadge = () => {
  const { hasPermissions } = useOwnUser();

  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const ownUserIsAllowedToPersistDraftBadge = hasPermissions(
    PERMISSIONS.SUPER_PRIVILEGE.WRITE_SUPER_PRIVILEGE_PERSIST_BADGE,
  );

  const { mutate, isLoading } = useMutation<Badge, unknown, DraftBadge['id']>(
    (draftBadgeId) => persistBadge(draftBadgeId),
    {
      onSuccess: (badge: Badge) => {
        snackbar.success('databaseExplorer.badge.persist.success', {
          interpolationMap: { name: badge.name },
        });

        queryClient.invalidateQueries(QUERIES_KEYS.badgesList());

        onClose();
        navigate(`/badge/${badge.id}`);
      },
      onError: () => {
        snackbar.error('databaseExplorer.badge.persist.error');
      },
    },
  );

  return {
    ownUserIsAllowedToPersistDraftBadge,
    isOpen,
    onOpen,
    onClose,
    onPersist: (id: DraftBadge['id']) => mutate(id),
    isLoading,
  };
};
