import { useState, useEffect } from 'react';

import { ShippingFeeConfig } from '@bits-app/bits-server-data';

import { authenticatedInstance } from '@/axios/axios.instance';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export const useShippingFeeConfig = () => {
  const [data, setData] = useState<ShippingFeeConfig[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [shippingFeeConfigToDelete, setShippingFeeConfigToDelete] = useState<
    ShippingFeeConfig | undefined
  >();

  const snackbar = useSnackbar();

  useEffect(() => {
    const controller = new AbortController();
    authenticatedInstance
      .get('/database-explorer/shipping-fee-config', { signal: controller.signal })
      .then((result) => {
        setData(result.data);
        setError(null);
      })
      .catch((error) => {
        if (error instanceof CanceledRequestError) {
          setError(null);
          return;
        }

        setError(error);
      });

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (error) {
      snackbar.error('error');
    }
  }, [error, snackbar]);

  const onDeleteShippingFeeConfig = () => {
    if (!shippingFeeConfigToDelete) {
      return;
    }

    authenticatedInstance
      .delete(`/database-explorer/shipping-fee-config/${shippingFeeConfigToDelete.id}`)
      .then(() => {
        setData((configs) =>
          configs.filter((config) => config.id !== shippingFeeConfigToDelete.id),
        );
        setShippingFeeConfigToDelete(undefined);
        setError(null);
      })
      .catch((error) => {
        if (error instanceof CanceledRequestError) {
          setError(null);
          return;
        }

        setError(error);
      });
  };

  return {
    shippingFeeConfigs: data,
    shippingFeeConfigToDelete,
    setShippingFeeConfigToDelete,
    onDeleteShippingFeeConfig,
  };
};
