import { Badge } from '@bits-app/bits-server-data';

import { authenticatedInstance } from '@/axios/axios.instance';

import { DraftBadge } from '../entity/draft-badge';

export const persistBadge = async (draftBadgeId: DraftBadge['id']) => {
  const result = await authenticatedInstance.post<Badge>(
    `/database-explorer/badge/${draftBadgeId}/persist`,
  );

  return result.data;
};
