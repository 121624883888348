import { ChangeEvent, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { MRT_Header } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

type TextFilterMRTProps<T extends Record<string, unknown>> = {
  header: MRT_Header<T>;
  activeFilters?: string;
};

export const TextFilterMRT = <T extends Record<string, unknown>>({
  header,
  activeFilters,
}: TextFilterMRTProps<T>) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(activeFilters ?? '');
  const [debouncedInputValue] = useDebounce(inputValue, 200);

  useEffect(() => {
    header.column.setFilterValue(debouncedInputValue);
  }, [debouncedInputValue, header.column]);

  useEffect(() => {
    if (!activeFilters && inputValue) {
      setInputValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  return (
    <TextField
      size="small"
      placeholder={t('applyFilters')}
      sx={{
        maxWidth: header.column.columnDef.size,
      }}
      inputProps={{
        sx: { paddingTop: 0.5, paddingBottom: 0.5, paddingLeft: 0.5, fontSize: '0.75rem' },
      }}
      value={inputValue}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
      }}
    />
  );
};
