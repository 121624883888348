import type { BlockedShippingAddress } from '@bits-app/bits-server-data';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { Information, PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParam } from '@/hooks/use-id-params';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';

type DetailProps = {
  blockedShippingAddress: BlockedShippingAddress;
  panelDetailsBlockedShippingAddress: Information<BlockedShippingAddress>[];
  panelDetailsStandardInformation: Information<BlockedShippingAddress>[];
  loading: boolean;
};

export const Detail = ({
  blockedShippingAddress,
  panelDetailsBlockedShippingAddress,
  panelDetailsStandardInformation,
  loading,
}: DetailProps) => {
  const blockedShippingAddressId = useIdParam();
  const { t } = useTranslation();

  if (!blockedShippingAddressId) {
    return null;
  }

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'}>
          <Stack spacing={2} direction="row" ml={'auto'} pr={1} pl={1} alignItems="center">
            <ForestAdminLink entity="blockedShippingAddress" entityId={blockedShippingAddress.id} />
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12}>
            <PanelDetails<BlockedShippingAddress>
              title={t('databaseExplorer.panel.blockedShippingAddress')}
              informations={panelDetailsBlockedShippingAddress}
              data={blockedShippingAddress}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <Grid xs={12}>
            <PanelDetails<BlockedShippingAddress>
              title={t('databaseExplorer.panel.timestamp')}
              informations={panelDetailsStandardInformation}
              data={blockedShippingAddress}
              loading={loading}
              labelRow
            />
          </Grid>
        </Grid>
      </Grid>
    </DetailsLayout>
  );
};
