import { ShipmentRating } from '@bits-app/bits-server-data';

import { CellDefinition } from '@Elements/DataList/datalist-type';

export const ShipmentRatingCellDefinition: CellDefinition<ShipmentRating>[] = [
  {
    id: 'id',
    label: 'Rating ID',
    accessorFn: (rating) => rating?.id,
  },
  {
    id: 'rating',
    label: 'Rating',
  },
  {
    id: 'comment',
    label: 'Comment',
  },
  {
    id: 'userId',
    label: 'Username',
    type: 'internal-link',
    accessorFn: (rating) => rating?.user.username,
    getLink: (rating) => ({ label: rating?.user.username, link: `/user/${rating?.userId}` }),
  },
  {
    id: 'isModerated',
    label: 'Is moderated',
    type: 'boolean',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date-with-time',
  },
];
