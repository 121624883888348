import {
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryColumn,
} from 'typeorm';

import { Product } from './Product';
import { User } from './User';

@Entity()
export class UserFollowProduct {
  @Index()
  @PrimaryColumn()
  userId!: User['id'];

  @ManyToOne(() => User, (user) => user.userFollowProduct)
  @JoinColumn({
    name: 'userId',
    referencedColumnName: 'id',
  })
  user!: User;

  @Index()
  @PrimaryColumn()
  productId!: Product['id'];

  @ManyToOne(() => Product, (product) => product.userFollowProduct)
  @JoinColumn({
    name: 'productId',
    referencedColumnName: 'id',
  })
  product!: Product;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;
}
