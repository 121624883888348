/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

import { CommentType } from '@bits-app/voggtpit-shared';

import { useOwnUser } from '@/context/own-user.context';
import logger from '@/logger/logger';
import { useAppDispatch } from '@/redux/reduxAppHooks';

import {
  BackendCommentResponse,
  FormattedCommentType,
  FunctionProps,
  useCommentProps,
} from './comments.type';
import { CommentsAction } from './http/comments.action';

export const useComment = ({ entityName, entityId }: useCommentProps) => {
  const [state, setState] = useState<Record<CommentType['id'], CommentType['comment']>>({});
  const [comments, setComments] = useState<FormattedCommentType[]>([]);
  const [loading, setLoading] = useState(true);

  const { ownUser } = useOwnUser();
  const dispatch = useAppDispatch();

  const initialFetch = useCallback(
    async (signal?: AbortController['signal']) => {
      try {
        if (!entityName || !entityId) return;
        const comments = await dispatch(
          CommentsAction.getComment({ entityId, entityName, signal }),
        );
        if (comments) handleSetComments(comments);
      } catch (e) {
        logger.error('[useComment:fetchComment]', e);
      }
    },
    [entityName, entityId],
  );

  const handleSetComments = (data: BackendCommentResponse) => {
    if (!data) return;

    const formattedComment = formatComment(
      data.map((comment) => ({
        ...comment,
        createdBy: [comment.user.firstName, comment.user.lastName].join(' '),
      })),
    );
    setComments(formattedComment);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    initialFetch(signal).finally(() => setLoading(false));
    return () => {
      controller.abort;
    };
  }, []);

  const commitComment: FunctionProps['commitComment'] = async (parentId = 'root') => {
    if (!entityName || !entityId || !ownUser?.id || state[parentId].trim() === '') return;

    const formattedComment = Object.assign(
      {
        entityName,
        entityId,
        createdBy: ownUser?.id,
        comment: state[parentId],
      },
      parentId !== 'root'
        ? {
            parentId,
          }
        : {},
    );

    const commentsFetched = await dispatch(
      CommentsAction.createComment({ comment: formattedComment, entityId, entityName }),
    );

    handleSetComments(commentsFetched);
    setState({ ...state, [parentId]: '' });
  };

  const onChangeComment: FunctionProps['onChangeComment'] = ({ parentId, comment }) => {
    setState({ ...state, [parentId || 'root']: comment });
  };

  const deleteComment = useCallback(async (commentId: CommentType['id']) => {
    if (!entityId || !entityName || !commentId) return;
    const commentsFetched = await dispatch(
      CommentsAction.deleteComment({ commentId, entityId, entityName }),
    );
    handleSetComments(commentsFetched);
  }, []);

  return {
    comments,
    commitComment,
    onChangeComment,
    deleteComment,
    state,
    loading,
  };
};

function formatComment(comments: CommentType[]): FormattedCommentType[] {
  return comments
    .sort(sortParentFirst)
    .reduce<FormattedCommentType[]>((all, currentComment: FormattedCommentType['comment']) => {
      const { parentId } = currentComment;

      if (!parentId) {
        all.push({
          comment: currentComment,
          answer: [],
        });
        return all;
      }
      const index = all.findIndex((commentGroup) => commentGroup.comment.id === parentId);

      if (index !== -1) {
        all[index].answer?.push(currentComment);
      }
      return all;
    }, [] as FormattedCommentType[]);
}

function sortParentFirst(a: CommentType): number {
  return !a.parentId as unknown as number;
}

export const getText = (answer: number, isOpen: boolean): string => {
  if (!answer && !isOpen) return 'comment.divider.noAnswerClosed';
  if (answer && !isOpen) return 'comment.divider.answerClose';
  return 'comment.divider.isOpen';
};
