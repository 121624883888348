import { useEffect } from 'react';

import { MarketPageType } from '@bits-app/voggtpit-shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { useImageUpload } from '@/voggt-database-explorer/components/ImageUpload/use-image-upload.hook';

import { QUERIES_KEYS } from '../../queries';

export type MarketPageCreationFormType = Pick<
  MarketPageType,
  'title' | 'slug' | 'description' | 'countryIds' | 'categoryId'
>;

export const useCreateMarketPageForm = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
  } = useForm<MarketPageCreationFormType>({
    defaultValues: {
      title: '',
      slug: '',
      description: '',
      categoryId: undefined,
    },
  });

  const { image, imageErrors, onDropImage } = useImageUpload('150Kb');

  const {
    data: schema,
    isLoading: isLoadingSchema,
    error: schemaError,
  } = useQuery({
    queryKey: QUERIES_KEYS.getMarketPageSchema(),
    queryFn: async () => {
      const { data } = await authenticatedInstance.get('/market-page/schema');
      return data;
    },
  });

  useEffect(() => {
    if (image) {
      URL.revokeObjectURL(image.preview);
    }
  }, [image]);

  const createMarketPageMutation = useMutation({
    mutationFn: createMarketPage,
    onSuccess: ({ data }) => {
      snackbar.success(t('marketPage.create.success'));
      navigate(`/market-pages/${data.id}`);
    },
    onError: (error: AxiosError) => {
      snackbar.error(t('marketPage.create.error', { error: error.message || 'Unknown error' }));
    },
  });

  const onCreate = async (data: Partial<MarketPageType>) => {
    createMarketPageMutation.mutate(data as MarketPageCreationFormType);
  };

  return {
    register,
    header: {
      title: t('marketPage.create.title'),
    },
    onCreate,
    onDropImage,
    schema,
    isLoadingSchema,
    isLoading: isLoadingSchema,
    schemaError,
    createMarketPageMutation,
    image,
    imageErrors,
    errors,
  };
};

const createMarketPage = async (marketPage: MarketPageCreationFormType) => {
  return authenticatedInstance.post('/market-page', marketPage);
};
