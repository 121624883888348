import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '@/components/elements/buttons/ButtonContained';

type ShippingAddressCreationModalProps = {
  toggleModal: () => void;
  modalIsOpen: boolean;
  validate: () => void;
  username: string;
};

export const ShippingAddressCreationModal = ({
  toggleModal,
  modalIsOpen,
  validate,
  username,
}: ShippingAddressCreationModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={modalIsOpen}
      onClose={toggleModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('databaseExplorer.shippingAddress.create.titleInfo')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('databaseExplorer.shippingAddress.create.description', { username })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal}>{t('action.cancel')}</Button>
        <ButtonContained onClick={validate}>{t('action.continue')}</ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
