import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { User } from './User';

@Entity()
@Index(['userId', 'code'])
export class AuthenticationLogs {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @ManyToOne(() => User, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Index()
  @Column({ nullable: true })
  userId!: number;

  @Index()
  @Column()
  code!: string;

  @Index()
  @Column({ nullable: true })
  phoneNumber!: string;

  @Index()
  @Column({ nullable: true })
  email!: string;

  // this saves temporary password for email registration. Once user is created, this is removed
  @Column({ nullable: true })
  password!: string;

  @Column({ nullable: true })
  ipAddress!: string;

  @Column()
  method!: 'phone' | 'email';

  @Column({ type: 'boolean', default: false })
  isVerified!: boolean;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;
}
