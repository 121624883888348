import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';

import { Button } from './Button';

type ClearAllFilterButtonProps = {
  filtersCount: number;
  clearAllFilters: () => void;
};

export const ClearAllFilterButton = ({
  filtersCount,
  clearAllFilters,
}: ClearAllFilterButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<FilterListIcon />}
      onClick={clearAllFilters}
    >
      {t('clearAllFilters', { count: filtersCount })}
    </Button>
  );
};
