import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '@Elements/buttons/ButtonContained';

type PayoutModalProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  amount: string;
};

export const PayoutModal = ({ open, onConfirm, onClose, amount }: PayoutModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('ledger.generate-payout.title', { amount })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('ledger.generate-payout.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <ButtonContained onClick={onConfirm} autoFocus>
          {t('confirm')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
