import { useState } from 'react';

import { isMatchingAmountFormat } from '@bits-app/voggtpit-shared';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, TextField } from '@mui/material';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

type DateTimePickerProps = {
  date: Date | null;
  onDateChange: (date: Date) => void;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
};
export const DateTimePicker = ({
  date,
  onDateChange,
  maxDate,
  minDate,
  disabled,
}: DateTimePickerProps) => {
  const [hours, setHours] = useState(date ? String(date.getHours()).padStart(2, '0') : '00');
  const [minutes, setMinutes] = useState(date ? String(date.getMinutes()).padStart(2, '0') : '00');
  const [seconds, setSeconds] = useState(date ? String(date.getSeconds()).padStart(2, '0') : '00');

  const handleOnCalendarClose = () => {
    const newDate = date ?? new Date();
    newDate.setHours(Number(hours));
    newDate.setMinutes(Number(minutes));
    newDate.setSeconds(Number(seconds));

    onDateChange(newDate);
  };

  return (
    <DatePicker
      className="z-50"
      showTimeInput
      withPortal
      showMonthDropdown
      selected={date}
      onChange={(date) => date && onDateChange(date)}
      shouldCloseOnSelect={false}
      onCalendarClose={handleOnCalendarClose}
      maxDate={maxDate}
      minDate={minDate}
      disabled={disabled}
      customInput={
        <TextField
          size="small"
          sx={{ maxWidth: 220 }}
          InputProps={{ endAdornment: <CalendarMonthIcon /> }}
        />
      }
      customTimeInput={
        <TimeInput
          hours={hours}
          onHoursChange={setHours}
          minutes={minutes}
          onMinutesChange={setMinutes}
          seconds={seconds}
          onSecondsChange={setSeconds}
        />
      }
      dateFormat="yyyy-MM-dd HH:mm:ss"
      timeFormat="HH:mm:ss"
    />
  );
};
type TimeInputProps = {
  hours: string;
  onHoursChange: (hours: string) => void;
  minutes: string;
  onMinutesChange: (minutes: string) => void;
  seconds: string;
  onSecondsChange: (seconds: string) => void;
};
const TimeInput = ({
  hours,
  onHoursChange,
  minutes,
  onMinutesChange,
  seconds,
  onSecondsChange,
}: TimeInputProps) => {
  const handleTimeChange =
    (maxValue: number, changer: (time: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      if (!isMatchingAmountFormat(value)) {
        return;
      }

      if (value.length > 2) {
        return;
      }

      if (Number(value) > maxValue) {
        changer('00');
        return;
      }

      changer(value);
    };

  return (
    <Box display="flex" maxWidth={170}>
      <TextField
        variant="standard"
        inputProps={{ sx: { py: 0.25, px: 1 } }}
        size="small"
        value={hours}
        onChange={handleTimeChange(23, onHoursChange)}
      />
      :
      <TextField
        variant="standard"
        inputProps={{ sx: { py: 0.25, px: 1 } }}
        size="small"
        value={minutes}
        onChange={handleTimeChange(59, onMinutesChange)}
      />
      :
      <TextField
        variant="standard"
        inputProps={{ sx: { py: 0.25, px: 1 } }}
        size="small"
        value={seconds}
        onChange={handleTimeChange(59, onSecondsChange)}
      />
    </Box>
  );
};
