import * as yup from 'yup';

import { SchemaValidation } from './validation-schema';

export class SellerNewsValidationSchema extends SchemaValidation {
  availableResolver = {
    title: this.resolveTitle(),
    message: this.resolveMessage(),
    internalName: this.resolveInternalName(),
  };

  resolveTitle(): yup.ISchema<any> {
    return yup.string().required();
  }

  resolveMessage(): yup.ISchema<any> {
    return yup.string().required();
  }

  resolveInternalName(): yup.ISchema<any> {
    return yup.string().required();
  }
}
