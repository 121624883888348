import { useState } from 'react';

import { Badge } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { useOwnUser } from '@/context/own-user.context';
import { User } from '@/voggt-database-explorer/users/users';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { useSnackbar } from '../../../components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '../../../queries';

export const useAddBadgeToUser = (userId: User['id']) => {
  const [selectedBadge, setSelectBadge] = useState<Badge | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const { hasPermissions } = useOwnUser();
  const ownUserIsAllowedToAddBadge = hasPermissions(
    PERMISSIONS.ADD_BADGE_TO_USER.WRITE_ADD_BADGE_TO_USER_UNIT,
  );

  const snackbar = useSnackbar();

  const { mutate: handleSubmit, isLoading } = useMutation({
    mutationFn: () => {
      if (!selectedBadge?.id) {
        return Promise.reject();
      }
      return authenticatedInstance.post(`database-explorer/customer/update-mass-badge`, [
        {
          userId: [userId],
          badgeId: selectedBadge.id,
        },
      ]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.userDetail(userId));
      snackbar.success('Badge attached to user');
    },
    onError: () => {
      snackbar.error('SomethingWentWrong');
    },
  });

  const toggleModal = () => {
    if (!ownUserIsAllowedToAddBadge) {
      return;
    }

    setModalIsOpen(!modalIsOpen);
  };

  return {
    onClose: toggleModal,
    onOpen: toggleModal,
    modalIsOpen,
    isLoading,
    handleSubmit,
    selectedBadge,
    setSelectBadge,
    ownUserIsAllowedToAddBadge,
    toggleModal,
  };
};
