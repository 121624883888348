import { useMemo, useState } from 'react';

import { OrderStatus, PaymentStatus, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { MoreVert } from '@mui/icons-material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CancelIcon from '@mui/icons-material/Cancel';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useOwnUser } from '@/context/own-user.context';
import { CancelOrder } from '@/voggt-database-explorer/components/CancelOrder.modal';
import { useCancelOrder } from '@/voggt-database-explorer/hooks';
import { ShowOrderShippedOrDeliveredModal } from '@/voggt-database-explorer/show/views/detail/ShowOrders/ShowOrderShippedOrDelivered.modal';

import type { ShowOrder } from '../../../misc/show.type';

import { useSetOrderShippedOrDelivered } from './useSetOrderShippedOrDelivered';

type ShowOrderRowActionsProps = {
  order: ShowOrder;
};

export const ShowOrderRowActions = ({ order }: ShowOrderRowActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  const { hasPermissions } = useOwnUser();
  const { t } = useTranslation();

  const isOrderCancellable = useMemo(() => {
    return (
      (order.paymentStatus === PaymentStatus.failed ||
        order.paymentStatus === PaymentStatus.in_progress) &&
      order.status !== OrderStatus.canceled
    );
  }, [order]);

  const canBeSetAsShippedOrDelivered = useMemo(() => {
    return order.paymentStatus === PaymentStatus.success && order.status !== OrderStatus.canceled;
  }, [order]);

  const {
    isAllowedToCancelOrder,
    onOpenCancelOrder,
    closeCancelOrder,
    openCancelOrder,
    onCancelOrder,
  } = useCancelOrder(order.id);

  const {
    isAllowedToSetAsShippedOrDelivered,
    onOpenSetAsShippedOrDelivered,
    onSetAsShippedOrDelivered,
    closeSetAsShippedOrDelivered,
    statusToSet,
  } = useSetOrderShippedOrDelivered(order.id);

  const itemMenu = useMemo(() => {
    const items = [];

    if (isAllowedToCancelOrder && isOrderCancellable) {
      items.push({
        onClick: onOpenCancelOrder,
        icon: <CancelIcon fontSize="small" sx={{ marginRight: 2 }} />,
        label: t('databaseExplorer.order.cancel.cancelOrder'),
      });
    }

    if (isAllowedToSetAsShippedOrDelivered && canBeSetAsShippedOrDelivered) {
      if (order.status !== OrderStatus.shipped) {
        items.push({
          onClick: () => onOpenSetAsShippedOrDelivered(OrderStatus.shipped),
          icon: <LocalShippingIcon fontSize="small" sx={{ marginRight: 2 }} />,
          label: t('databaseExplorer.show.orders.shippedOrDelivered.setAsShipped'),
        });
      }

      if (order.status !== OrderStatus.delivered) {
        items.push({
          onClick: () => onOpenSetAsShippedOrDelivered(OrderStatus.delivered),
          icon: <AssignmentTurnedInIcon fontSize="small" sx={{ marginRight: 2 }} />,
          label: t('databaseExplorer.show.orders.shippedOrDelivered.setAsDelivered'),
        });
      }
    }

    return items;
  }, [
    isAllowedToCancelOrder,
    isOrderCancellable,
    isAllowedToSetAsShippedOrDelivered,
    canBeSetAsShippedOrDelivered,
    onOpenCancelOrder,
    t,
    order.status,
    onOpenSetAsShippedOrDelivered,
  ]);

  const isReadOnly = !hasPermissions(PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_ORDER);

  if (isReadOnly || (!isOrderCancellable && !canBeSetAsShippedOrDelivered)) {
    return null;
  }

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  if (itemMenu.length <= 0) {
    return null;
  }

  return (
    <>
      <IconButton onClick={onOpen}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {itemMenu.map(({ label, onClick, icon }) => (
          <MenuItem
            key={label}
            dense
            onClick={() => {
              onClick();
              onClose();
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      {openCancelOrder && <CancelOrder onClose={closeCancelOrder} onConfirm={onCancelOrder} />}
      {statusToSet !== null && (
        <ShowOrderShippedOrDeliveredModal
          status={statusToSet}
          onClose={closeSetAsShippedOrDelivered}
          onConfirm={onSetAsShippedOrDelivered}
        />
      )}
    </>
  );
};
