import { TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Box } from '@mui/system';

import { TableCellHeaderBorderBottom } from '@/components/elements/StyledTableCell';
import { useUnsavedWarning } from '@/context/unsaved.context';

import { HeadCell } from './hooks/use-header';

type OrderItemsMassUpdateHeaderProps = {
  headCells: HeadCell[];
  order: 'asc' | 'desc';
  orderBy: string;
  onOrderBy: (field: string) => void;
  isUnsaved: boolean;
};

export const OrderItemsMassUpdateHeader = ({
  headCells,
  order,
  orderBy,
  onOrderBy,
  isUnsaved,
}: OrderItemsMassUpdateHeaderProps) => {
  const onConfirmAfterWarning = useUnsavedWarning();

  const handleOnSort = (id: string) => {
    if (isUnsaved) {
      onConfirmAfterWarning(() => onOrderBy(id));
      return;
    }

    onOrderBy(id);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCellHeaderBorderBottom
            key={headCell.id}
            minWidth={`calc(${headCell.minWidth}px + ${headCell.sortable ? '20px' : '0px'})`}
            sx={{
              height: 50,
              fontWeight: 'bold',
            }}
          >
            {!headCell.sortable ? (
              <Box component="span" mr={1}>
                {headCell.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => handleOnSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}

            {headCell.filter ?? null}
          </TableCellHeaderBorderBottom>
        ))}
      </TableRow>
    </TableHead>
  );
};
