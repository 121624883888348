import { useCallback, useEffect, useMemo, useState } from 'react';

import { DateFilterValue, isRangeFilter, Operator } from '@bits-app/voggtpit-shared';

import { useUnsavedWarning } from '@/context/unsaved.context';
import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { formatDate } from '@/utils';

type UseDateTimeFilterProps = {
  activeFilters?: DateFilterValue;
  field: AddFilterFunctionParams['field'];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
  defaultOperator?: Operator;
};

export const useDateTimeFilter = ({
  activeFilters,
  field,
  addFilter,
  isUnsaved,
  defaultOperator = 'equal',
}: UseDateTimeFilterProps) => {
  const [date, setDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [selectedOperator, setSelectedOperator] = useState<Operator>(defaultOperator);

  const handleClearFilter = useCallback(() => {
    setDate(null);
    setToDate(null);
    setSelectedOperator(defaultOperator);
  }, [defaultOperator]);

  const setToActiveFilterValue = useCallback(() => {
    if (activeFilters) {
      if (isRangeFilter(activeFilters)) {
        setToDate(new Date(activeFilters.to));
        setDate(new Date(activeFilters.from));
      } else {
        setDate(new Date(activeFilters.value));
      }

      setSelectedOperator(activeFilters.operator);

      return;
    }

    handleClearFilter();
  }, [activeFilters, handleClearFilter]);

  useEffect(() => {
    setToActiveFilterValue();
  }, [activeFilters, setToActiveFilterValue]);

  const onConfirmAfterWarning = useUnsavedWarning();

  const isApplyButtonDisabled: boolean = useMemo(() => {
    if (!date && !activeFilters) {
      return true;
    }

    const activeFiltersIsDifferentThanValue =
      JSON.stringify(activeFilters) ===
      JSON.stringify({ operator: selectedOperator, value: date ? formatDate(date) : null });

    const activeFiltersIsDifferentThanRange =
      JSON.stringify(activeFilters) ===
      JSON.stringify({
        operator: selectedOperator,
        from: date ? formatDate(date) : null,
        to: toDate ? formatDate(toDate) : null,
      });

    return Boolean(activeFiltersIsDifferentThanValue || activeFiltersIsDifferentThanRange);
  }, [activeFilters, date, toDate, selectedOperator]);

  const onChangeOperator = (operator: Operator) => {
    setSelectedOperator(operator);
  };

  const handleAddFilter = () => {
    const applyFilter = () => {
      if (!date) {
        addFilter({ field, values: [] });
        return;
      }

      if (selectedOperator === 'between') {
        if (!toDate) {
          return;
        }

        addFilter({
          field,
          values: {
            operator: selectedOperator,
            from: date.toISOString(),
            to: toDate.toISOString(),
          },
        });
        return;
      }

      addFilter({ field, values: { operator: selectedOperator, value: date.toISOString() } });
    };

    if (isUnsaved) {
      onConfirmAfterWarning(applyFilter);
      return;
    }

    applyFilter();
  };

  return {
    date,
    setDate: (date: Date | null) => setDate(date ? new Date(date) : null),
    toDate,
    setToDate: (date: Date | null) => setToDate(date ? new Date(date) : null),
    selectedOperator,
    isApplyButtonDisabled,
    applyFilter: handleAddFilter,
    onChangeOperator,
    setToActiveFilterValue,
    onClearFilter: handleClearFilter,
  };
};
