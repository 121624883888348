import { centsToUnit } from '@bits-app/voggtpit-shared';

import { SellerCancelRequestsRefundSummary } from '../gateways/seller-cancel-requests.port';

export const sellerCancelRequestRefundSummaryDto = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
): SellerCancelRequestsRefundSummary => {
  const summary: SellerCancelRequestsRefundSummary = {};

  if (data.misclick?.count > 0) {
    summary.misclick = {
      ...data.misclick,
      totalAmount: centsToUnit(data.misclick.totalAmount),
    };
  }

  if (data.buyer_refusal?.count > 0) {
    summary.buyer_refusal = {
      ...data.buyer_refusal,
      totalAmount: centsToUnit(data.buyer_refusal.totalAmount),
    };
  }

  if (data.failed_payment?.count > 0) {
    summary.failed_payment = {
      ...data.failed_payment,
      totalAmount: centsToUnit(data.failed_payment.totalAmount),
    };
  }

  return summary;
};
