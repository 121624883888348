import { Currency, promotionDtoToEntity, PromotionsFindOptions } from '@bits-app/voggtpit-shared';

import { authActions } from '@/auth/auth.slice';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { promotionsActions } from '../redux/promotions.slice';
const LIMIT = 100;

export const getPromotions =
  ({
    limit = LIMIT,
    page = 0,
    currency = Currency.eur,
    order,
    filters,
  }: PromotionsFindOptions): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { snackbarGateway, promotionsGateway }) => {
    dispatch(promotionsActions.setLoading(true));
    try {
      const result = await promotionsGateway.getPromotions(
        {
          limit,
          page,
          currency,
          order,
          filters,
        },
        { abortPrevious: true },
      );

      dispatch(promotionsActions.setEntities(result.items.map(promotionDtoToEntity)));
      dispatch(promotionsActions.setCount(result.count));
      dispatch(promotionsActions.setLoading(false));
    } catch (error) {
      logger.error(error);

      if (error instanceof CanceledRequestError) {
        return;
      }

      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));
        dispatch(promotionsActions.setLoading(false));

        return;
      }

      dispatch(promotionsActions.setLoading(false));
      snackbarGateway.error('somethingWrongHappened');
    }
  };
