import { Shipment } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS, queryClient } from '@/queries';

const fetchShipment = async (shipmentId: Shipment['id']) => {
  const { data } = await authenticatedInstance.get<Shipment>(
    `/database-explorer/shipment/${shipmentId}?customer=true&seller=true&shippingAddress=true`,
  );
  return data;
};

export const useShipmentDetail = (shipmentId: Shipment['id']) => {
  const { isLoading, data: shipment } = useQuery(QUERIES_KEYS.getShipment(shipmentId), () =>
    fetchShipment(shipmentId),
  );

  const refetchShipment = () => {
    queryClient.invalidateQueries(QUERIES_KEYS.getShipment(shipmentId));
  };

  return {
    shipment,
    loading: isLoading,
    refetchShipment,
  };
};
