import { PERMISSIONS } from '@bits-app/voggtpit-shared';

import { useOwnUser } from '@/context/own-user.context';
import { useAppDispatch } from '@/redux/reduxAppHooks';
import { updatePayoutStatusPurchasedFromShop } from '@/voggt-database-explorer/order/use-cases';
import { databaseExplorerAction } from '@/voggt-database-explorer/redux/database-explorer.slice';

import { Order, PayoutStatus } from '../../../entities/order.entity';

export const useUpdatePayoutStatus = (order: Order) => {
  const { hasPermissions } = useOwnUser();
  const dispatch = useAppDispatch();

  const isAllowedToUpdatePayoutStatus =
    order.shopId != null &&
    hasPermissions(PERMISSIONS.SHOP_ORDER.WRITE_SHOP_ORDER_UNIT_UPDATE_PAYOUT_STATUS);

  const togglePayoutStatus = () => {
    const payoutStatus =
      order.payoutStatus === PayoutStatus.paid ? PayoutStatus.unpaid : PayoutStatus.paid;

    const updateDataOnSuccess = () => {
      dispatch(
        databaseExplorerAction.setData({
          ...order,
          payoutStatus,
        }),
      );
    };

    dispatch(updatePayoutStatusPurchasedFromShop([order.id], payoutStatus, updateDataOnSuccess));
  };

  return {
    isAllowedToUpdatePayoutStatus,
    togglePayoutStatus,
  };
};
