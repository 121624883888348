import { useState } from 'react';

export const Dropdown = ({
  title,
  options = [],
}: {
  title: string;
  options: { label: string; onClick: () => void; forbidden?: boolean }[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeOutId, setTimeOutId] = useState<NodeJS.Timeout>();

  const onOpen = () => {
    clearTimeout(timeOutId);
    setIsOpen(true);
  };
  const onClose = () => {
    const id = setTimeout(() => setIsOpen(false), 200);
    setTimeOutId(id);
  };

  if (options.length === 0) return null;

  return (
    <div
      className="relative"
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
      onMouseOverCapture={onOpen}
    >
      <button className="text-white bg-sky-700 hover:bg-sky-800 focus:ring-2 focus:outline-none focus:ring-sky-300 font-semibold rounded text-sm px-4 py-2.5 text-center inline-flex items-center h-full">
        {title}
      </button>

      {isOpen && (
        <div className="absolute z-50 right-0 w-56 mt-2 origin-top-right rounded-md shadow overflow-hidden">
          <div
            className="bg-white rounded-md shadow-xs border border-slate-200"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options
              .filter((option) => !option.forbidden)
              .map((option) => (
                <button
                  key={option.label}
                  onClick={option.onClick}
                  className="block break-words px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                  role="menuitem"
                >
                  {option.label}
                </button>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
