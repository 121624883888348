import { AxiosError } from 'axios';

import { OwnUser } from '@/domain/entities/own-user.entity';
import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { authActions } from '../auth.slice';

export const changeOwnUserPassword =
  ({ ownUserId, password }: { ownUserId: OwnUser['id']; password: string }): AppThunkAction<void> =>
  async (dispatch, _getState, { authGateway, snackbarGateway }) => {
    dispatch(authActions.setAuthLoading(true));
    try {
      await authGateway.changeOwnUserPassword({ ownUserId, password });

      snackbarGateway.success('members.changePasswordSuccess');
    } catch (error) {
      logger.error(error);

      if (error instanceof AxiosError) {
        snackbarGateway.error(error.response?.data.message || 'error');
        return;
      }

      snackbarGateway.error('error');
    } finally {
      dispatch(authActions.setAuthLoading(false));
    }
  };
