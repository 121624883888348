import { useCallback, useMemo } from 'react';

import {
  DateFilterValue,
  FindOptions as CommonFindOptions,
  NumberFilterValue,
  SellerBuyerFilterValue,
} from '@bits-app/voggtpit-shared';
import { useSearchParams } from 'react-router-dom';

import { SetFilterValue } from '@/components/elements/filters/types';

export type AddFilterFunctionParams = {
  field: string;
  values:
    | SetFilterValue
    | NumberFilterValue
    | DateFilterValue
    | string
    | string[]
    | SellerBuyerFilterValue;
};

const SIZE_PER_PAGE = 100;

export type TableFilters = Record<
  string,
  SetFilterValue | DateFilterValue | NumberFilterValue | string | SellerBuyerFilterValue
>;

export type FindOptions = CommonFindOptions & { filters: TableFilters };

export const useTableOptions = (sizePerPage = SIZE_PER_PAGE) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onChangePage = useCallback(
    (newPage: number) => {
      if (newPage === 0) {
        searchParams.delete('page');
        setSearchParams(searchParams, { replace: true });
        return;
      }
      searchParams.set('page', String(newPage));
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  const onChangeLimit = useCallback(
    (newLimit: number) => {
      if (newLimit === sizePerPage) {
        searchParams.delete('limit');
        setSearchParams(searchParams, { replace: true });
        return;
      }
      searchParams.set('limit', String(newLimit));
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams, sizePerPage],
  );

  const onChangeSearch = useCallback(
    (search?: string) => {
      if (!search) {
        searchParams.delete('search');
      } else {
        searchParams.set('search', search);
      }

      setSearchParams(searchParams, { replace: true });

      onChangePage(0);
    },
    [onChangePage, searchParams, setSearchParams],
  );

  const addFilter = useCallback(
    ({ field, values }: AddFilterFunctionParams) => {
      const filtersQuery = searchParams.get('filters');
      let filters: TableFilters = {};

      if (filtersQuery) {
        filters = JSON.parse(filtersQuery);
        searchParams.delete('filters');
      }

      if ((Array.isArray(values) || typeof values === 'string') && values.length === 0) {
        delete filters[field];
      } else if (values) {
        filters[field] = values;
      }

      if (Object.keys(filters).length > 0) {
        searchParams.set('filters', JSON.stringify(filters));
      }

      setSearchParams(searchParams, { replace: true });
      onChangePage(0);
    },
    [onChangePage, searchParams, setSearchParams],
  );

  const setFilters = useCallback(
    (newFilters: AddFilterFunctionParams[]) => {
      const filters: TableFilters = {};

      for (const filter of newFilters) {
        const { values, field } = filter;

        if ((Array.isArray(values) || typeof values === 'string') && values.length === 0) {
          continue;
        }

        filters[field] = values;
      }

      if (Object.keys(filters).length > 0) {
        searchParams.set('filters', JSON.stringify(filters));
      } else {
        searchParams.delete('filters');
      }

      setSearchParams(searchParams, { replace: true });
      onChangePage(0);
    },
    [onChangePage, searchParams, setSearchParams],
  );

  const onOrderBy = (field: string) => {
    const orderQuery = searchParams.get('order');

    if (!orderQuery) {
      searchParams.set(
        'order',
        JSON.stringify({
          field,
          value: 'ASC',
        }),
      );
    } else {
      const order = JSON.parse(orderQuery);

      searchParams.delete('order');

      searchParams.set(
        'order',
        JSON.stringify({
          field,
          value: order.value === 'ASC' ? 'DESC' : 'ASC',
        }),
      );
    }

    setSearchParams(searchParams, { replace: true });
    onChangePage(0);
  };

  const clearAllFilters = useCallback(() => {
    searchParams.delete('filters');
    searchParams.delete('search');
    setSearchParams(searchParams, { replace: true });

    onChangePage(0);
  }, [onChangePage, searchParams, setSearchParams]);

  const clearSearch = useCallback(() => {
    searchParams.delete('search');
    setSearchParams(searchParams, { replace: true });

    onChangePage(0);
  }, [onChangePage, searchParams, setSearchParams]);

  const options = useMemo(() => {
    const builtOptions: FindOptions = {
      page: 0,
      limit: sizePerPage,
      order: {
        field: 'id',
        value: 'DESC',
      },
      filters: {},
    };

    const limit = searchParams.get('limit');

    if (limit && Number(limit) !== builtOptions.limit) {
      builtOptions.limit = Number(limit);
    }

    const page = searchParams.get('page');

    if (page && Number(page) !== builtOptions.page) {
      builtOptions.page = Number(page);
    }

    const filters = searchParams.get('filters');

    if (filters) {
      builtOptions.filters = JSON.parse(filters);
    } else {
      builtOptions.filters = {};
    }

    const order = searchParams.get('order');

    if (order) {
      builtOptions.order = JSON.parse(order);
    } else {
      builtOptions.order = {
        field: 'id',
        value: 'DESC',
      };
    }

    const search = searchParams.get('search');

    if (search) {
      builtOptions.search = search;
    }

    return builtOptions;
  }, [searchParams, sizePerPage]);

  return {
    options,
    onChangePage,
    onChangeLimit,
    onChangeSearch,
    addFilter,
    setFilters,
    clearAllFilters,
    clearSearch,
    onOrderBy,
  };
};
