import { useState } from 'react';

import { CheckCircle, Copy, CopyCheck, XCircle } from 'lucide-react';

type DataStatusProps = {
  title: string;
  value: string;
  status: boolean | undefined;
  disableCopy?: boolean;
};

export const DataStatus = ({ title, value, status }: DataStatusProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className="flex justify-between items-center gap-1" onClick={copyToClipboard}>
      <div className="flex flex-col gap-1">
        <p className="font-semibold capitalize">{title}</p>
        <div className="flex gap-2 items-center">
          {value && <p>{value}</p>}
          <Status status={status} />
        </div>
      </div>

      <div className="flex items-center text-slate-300 hover:text-slate-500 cursor-pointer duration-100">
        {isCopied && <CopyCheck size={18} className="text-green-500" />}
        {!isCopied && <Copy size={18} />}
      </div>
    </div>
  );
};

const Status = ({ status }: { status: boolean | undefined }) => {
  if (status === undefined) return null;

  return status ? <CheckCircle size={18} color="green" /> : <XCircle size={18} color="red" />;
};
