import { ChangeEvent, FormEvent, useEffect, useMemo, useRef, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { InfiniteScrollContainerHandle } from '@/components/elements/InfiniteScrollContainer';
import { useUnsavedWarning } from '@/context/unsaved.context';
import { AddFilterFunctionParams } from '@/hooks/use-table-options';

type UseFreeValueFilterProps = {
  activeFilters: string[];
  field: AddFilterFunctionParams['field'];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
  values: string[];
  getFilterValuesFor: ({ field, search }: { field: string; search: string }) => void;
  getNextPageFilterValuesFor: ({
    field,
    search,
    page,
  }: {
    field: string;
    search: string;
    page: number;
  }) => void;
};

export const useFreeValueFilter = ({
  addFilter,
  field,
  isUnsaved,
  activeFilters,
  values,
  getFilterValuesFor,
  getNextPageFilterValuesFor,
}: UseFreeValueFilterProps) => {
  const [value, setValue] = useState<string>('');
  const [search] = useDebounce(value, 300);
  const infiniteScrollContainerRef = useRef<InfiniteScrollContainerHandle | null>(null);

  // code-smell - kind of duplication code
  const [checked, setChecked] = useState<string[]>(activeFilters);
  const [selectable, setSelectable] = useState<string[]>(activeFilters);

  const [page, setPage] = useState(0);

  const onConfirmAfterWarning = useUnsavedWarning();

  const stringifyActiveFilters = JSON.stringify(activeFilters);

  const isApplyButtonDisabled: boolean = useMemo(() => {
    return JSON.stringify(checked) === stringifyActiveFilters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, activeFilters]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedActiveFilters = useMemo(() => activeFilters, [stringifyActiveFilters]);

  useEffect(() => {
    if (memoizedActiveFilters.length === 0) {
      if (checked.length > 0) {
        setChecked([]);
      }

      return;
    }

    if (selectable.length === 0 && checked.length === 0) {
      setChecked(memoizedActiveFilters);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedActiveFilters]);

  useEffect(() => {
    getFilterValuesFor({ field, search });
    setPage(0);
    infiniteScrollContainerRef.current?.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getNextPageFilterValuesFor({ field, search, page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleOnClose = () => {
    setChecked(activeFilters);
    setSelectable(activeFilters);
    setValue('');
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);

      if (!selectable.includes(value)) {
        setSelectable([...selectable, value]);
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleAddOption = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setChecked([...checked, value]);
    setValue('');
  };

  const handleApplyFilter = () => {
    if (isUnsaved) {
      onConfirmAfterWarning(() => addFilter({ field, values: checked }));
      return;
    }

    addFilter({ field, values: checked });
  };

  const handleOnValuesReachEnd = (nextPage: number) => {
    setPage(nextPage);
  };

  return {
    value,
    checked,
    options: selectable,
    availableValues: values.filter((id) => !selectable.includes(id)),
    isApplyButtonDisabled,
    onClose: handleOnClose,
    onChange: handleOnChange,
    onAddOption: handleAddOption,
    onToggle: handleToggle,
    onApplyFilter: handleApplyFilter,
    onValuesReachEnd: handleOnValuesReachEnd,
    noResults: search.length >= 2 && values.length === 0,
    infiniteScrollContainerRef,
  };
};
