import { SentinelFraudCase, SentinelDevice, SentinelFraudster } from '@bits-app/voggtpit-shared';

import { CellDefinition } from '../../components/elements/DataList';
import { Information } from '../../components/layouts/PanelDetails';

export const SentinelListCellDefinition: CellDefinition<SentinelFraudCase>[] = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'name',
    label: 'name',
  },
  {
    id: 'notes',
    type: 'string',
    label: 'Notes',
  },
  {
    id: 'fraudsters',
    label: 'Fraudsters count',
    getValue: (fraudCase) => String(fraudCase.fraudsters?.length),
  },
  {
    id: 'devices',
    label: 'Devices count',
    getValue: (fraudCase) => String(fraudCase.devices?.length),
  },
  {
    id: 'created_at',
    label: 'Created at',
    type: 'date-with-time',
  },
];

export const panelDetailSentinelFraudCase: Information<SentinelFraudCase>[] = [
  {
    value: 'id',
    label: 'value',
  },
  {
    value: 'name',
    label: 'name',
  },
  {
    value: 'notes',
    label: 'Notes',
  },
  {
    value: 'fraudsters',
    label: 'Fraudsters count',

    format: (fraudCase) => fraudCase?.fraudsters?.length,
  },
  {
    value: 'devices',
    label: 'Devices count',
    format: (fraudCase) => fraudCase?.devices?.length,
  },
  {
    value: 'created_at',
    label: 'Created at',
    type: 'date-with-time',
  },
];

export const sentinelFraudsterCellDefinition: CellDefinition<SentinelFraudster>[] = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'voggt_userid',
    label: 'Voggt User ID',
  },
  {
    id: 'notes',
    label: 'Notes',
  },
  {
    id: 'updated_at',
    label: 'Updated at',
    type: 'date-with-time',
  },
];

export const sentinelDevicesCellDefinition: CellDefinition<SentinelDevice>[] = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'type',
    label: 'type',
  },
  {
    id: 'uuid',
    label: 'UUID',
  },
  {
    id: 'created_at',
    label: 'Created at',
    type: 'date-with-time',
  },
];
