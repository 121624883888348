import { Category, Country, ParentCategory } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';

import { QUERIES_KEYS } from './queries-keys';

export const useGetCountries = () => {
  return useQuery(QUERIES_KEYS.getCountries(), async () => {
    const requestResponse = await authenticatedInstance.get<Country[]>(
      '/database-explorer/country',
    );
    return requestResponse.data;
  });
};

export const useGetCategories = () => {
  return useQuery(
    QUERIES_KEYS.categoriesList(),
    async () => {
      const response = await authenticatedInstance.get<Category[]>('/database-explorer/category');
      return response.data;
    },
    {
      initialData: [],
    },
  );
};

export const useGetParentCategories = () => {
  return useQuery<ParentCategory[]>(
    QUERIES_KEYS.parentCategoriesList(),
    async () => {
      const response = await authenticatedInstance.get<ParentCategory[]>(
        '/database-explorer/parent-category',
      );
      return response.data || [];
    },
    {
      initialData: [],
    },
  );
};
