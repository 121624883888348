import Ico from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '@Elements/buttons/Button';

type EditButtonProps = {
  to: string;
};

export const EditButton = ({ to }: EditButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      component={RouterLink}
      to={to}
      variant="text"
      endIcon={<Ico fontSize="small" />}
      sx={{ fontWeight: 'bold' }}
    >
      {t('action.edit')}
    </Button>
  );
};
