import { Badge } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '@/axios/axios.instance';
import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { QUERIES_KEYS } from '@/queries';

import { DraftBadge } from '../../entity/draft-badge';
import { useCellDefinition } from '../../use-cell-definition';

import { CreateBadge } from './create-badge/create-badge';

export const ListBadge = () => {
  const { CellBadgeDefinition } = useCellDefinition();
  const { t } = useTranslation();

  const { data: badges } = useQuery<(Badge | DraftBadge)[]>(QUERIES_KEYS.badgesList(), async () => {
    const result = await authenticatedInstance.get('database-explorer/badge');

    return result.data;
  });

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListCustom<Badge | DraftBadge>
        results={badges ?? []}
        enableFilters
        title={t('databaseExplorer.badge.search')}
        cellDefinition={CellBadgeDefinition}
        children={<CreateBadge badges={badges ?? []} />}
      />
    </div>
  );
};
