import { useMemo } from 'react';

import { AuthAdapter } from '@/auth/gateways/auth-adapter';
import { HttpCommentAdapter } from '@/components/elements/Comments/http/http-comments.adapter';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { HttpPartnerProgramAdapter } from '@/partner-program/gateway/http-partner-program-adapter';
import { createStore, Dependencies } from '@/redux/store';
import { HttpOrderAdapter } from '@/voggt-database-explorer/order/gateways/http-order.adapter';
import { HttpDatabaseExplorerAdapter } from '@/voggt-database-explorer/redux/adapter/http-database-explorer.adapter';

import { HttpOrderItemsMassUpdateAdapter } from '../order-items-mass-update/gateways/http-order-items-mass-update.adapter';
import { HttpPromotionsAdapter } from '../promotions/gateways/http-promotions-adapter';
import { HttpRefundAdapter } from '../refund/gateways/http-refund-adapter';
import { HttpSellerCancelRequestsAdapter } from '../seller-cancel-requests/gateways/http-seller-cancel-requests.adapter';

export const useStore = () => {
  const snackbarGateway = useSnackbar();

  const dependencies: Dependencies = useMemo(() => {
    return {
      authGateway: new AuthAdapter(),
      refundGateway: new HttpRefundAdapter(),
      sellerCancelRequestsGateway: new HttpSellerCancelRequestsAdapter(),
      orderItemsMassUpdateGateway: new HttpOrderItemsMassUpdateAdapter(),
      promotionsGateway: new HttpPromotionsAdapter(),
      databaseExplorerGateway: new HttpDatabaseExplorerAdapter(),
      partnerProgramGateway: new HttpPartnerProgramAdapter(),
      snackbarGateway,
      commentGateway: new HttpCommentAdapter(),
      orderGateway: new HttpOrderAdapter(),
    };
  }, [snackbarGateway]);

  return useMemo(() => createStore(dependencies), [dependencies]);
};
