import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { CreatePromotionDto } from '../gateways/promotions.port';
import { promotionsActions } from '../redux/promotions.slice';

export const createPromotion =
  (data: CreatePromotionDto): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { promotionsGateway, snackbarGateway }) => {
    dispatch(promotionsActions.setUpdating(true));
    try {
      const result = await promotionsGateway.createPromotion(data);

      dispatch(promotionsActions.pushPromotion(result));
      dispatch(promotionsActions.setEditingPromotionId(result.id));
    } catch (error) {
      logger.error(error);

      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(promotionsActions.setUpdating(false));
    }
  };
