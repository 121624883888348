import { RefundReason } from '@bits-app/voggtpit-shared';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { EnumFilterTable } from '@Elements/filters/table';

const REFUND_REASONS = Object.values(RefundReason) as RefundReason[];

type RefundReasonFilterProps = {
  activeFilters?: RefundReason[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
};

export const RefundReasonFilter = ({
  addFilter,
  activeFilters = [],
  isUnsaved,
}: RefundReasonFilterProps) => {
  return (
    <EnumFilterTable
      options={REFUND_REASONS.map((value) => ({ value }))}
      addFilter={addFilter}
      field="refundReason"
      activeFilters={activeFilters}
      isUnsaved={isUnsaved}
    />
  );
};
