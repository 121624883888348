import { useMemo } from 'react';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

import { Emote } from './entity/emote.entity';

export const useCellDefinition = () => {
  const CellEmoteDefinition = useMemo(
    (): CellDefinition<Emote>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (emote: Emote) => {
          return { link: `/emote/${emote.id}`, label: emote?.id };
        },
      },
      {
        id: 'image',
        label: 'Image url',
        type: 'image',
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date',
      },
      {
        id: 'name',
        label: 'name',
      },
    ],
    [],
  );

  return { CellEmoteDefinition };
};
