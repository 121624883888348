import {
  add,
  formatAmount,
  minus,
  RefundDebitedAccount,
  RefundReason,
} from '@bits-app/voggtpit-shared';

import { OrderItem } from '@/entities/order-item.entity';
import { AppThunkAction } from '@/redux/store';

import { selectOrderItemsFromRefund, selectOrderItems } from '../../redux/refund.selectors';
import { refundActions } from '../../redux/refund.slice';

import { isAllOrderInRefund } from './utils';

export const getDefaultRefundForProductLostBeforeShipping =
  (): AppThunkAction<void> => (dispatch, getState) => {
    dispatch(refundActions.setDebitedAccount(RefundDebitedAccount.seller));
    dispatch(refundActions.setRefundReason(RefundReason.productLostBeforeShipping));

    const orderItemsInRefund = selectOrderItemsFromRefund(getState());

    for (const orderItem of orderItemsInRefund) {
      dispatch(getDefaultAmountForProductLostBeforeShippingForOrderItem(orderItem));
    }
  };

export const getDefaultAmountForProductLostBeforeShippingForOrderItem =
  (orderItem: OrderItem): AppThunkAction<void> =>
  (dispatch, getState) => {
    const orderItems = selectOrderItems(getState());
    const orderItemsInRefund = selectOrderItemsFromRefund(getState());

    let amount = orderItem.amount;

    if (isAllOrderInRefund(orderItems, orderItemsInRefund)) {
      amount = add(amount, orderItem.shippingAmount);
    }

    if (orderItem.refundedAmount !== undefined) {
      amount = minus(amount, orderItem.refundedAmount);
    }

    dispatch(
      refundActions.setRefundAmountFor({
        orderItemId: orderItem.id,
        amount: formatAmount(amount),
      }),
    );
  };
