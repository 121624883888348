import { Reducer, useCallback, useEffect, useReducer, useState } from 'react';

import { Currency, formatAmount, PartnerProgramType } from '@bits-app/voggtpit-shared';

import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import {
  selectPartnerProgramFromId,
  selectPartnerProgramsCurrent,
  selectPartnerProgramsLoading,
} from '../redux/partner-program.selector';
import { createPartnerProgram } from '../redux/use-cases/create-partner-program';
import { deletePartnerProgram } from '../redux/use-cases/delete-partner-program';
import { editPartnerProgram } from '../redux/use-cases/edit-partner-program';

type State = Omit<PartnerProgramType, 'amount' | 'percentageMaxAmount' | 'percentage'> & {
  amount: string;
  percentageMaxAmount: string;
  percentage: string;
  isPrivate: boolean;
};

export const usePartnerProgramForm = () => {
  const [mode, setMode] = useState<ModeType>('percentage');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [state, updateState] = useReducer<Reducer<State, { action: Action; value: any }>>(
    reducer,
    initialState,
  );
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectPartnerProgramsLoading);
  const partnerProgramId = useAppSelector(selectPartnerProgramsCurrent);
  const partnerProgram = useAppSelector((state) =>
    selectPartnerProgramFromId(state, partnerProgramId),
  );
  const isCreation = partnerProgramId === -1;

  const handleDelete = useCallback(async () => {
    if (partnerProgramId && partnerProgramId !== -1) {
      dispatch(deletePartnerProgram(partnerProgramId));
    }
  }, [dispatch, partnerProgramId]);

  const handleSubmit = useCallback(async () => {
    if (!partnerProgramId) return;
    const basedPartnerProgram = {
      ...state,
      percentage: numberOrUndefined(state.percentage),
      amount: numberOrUndefined(state.amount),
      percentageMaxAmount: numberOrUndefined(state.percentageMaxAmount),
    };

    if (isCreation) {
      dispatch(createPartnerProgram(withoutId(basedPartnerProgram)));
    } else {
      dispatch(editPartnerProgram(partnerProgramId, basedPartnerProgram));
    }
  }, [partnerProgramId, isCreation, dispatch, state]);

  useEffect(() => {
    if (!partnerProgram) {
      return;
    }

    const wasPreviouslyInFix = !partnerProgram.percentage || partnerProgram.percentage === 0;
    if (wasPreviouslyInFix) {
      setMode('fix');
    }

    updateState({
      action: 'fill',
      value: {
        ...partnerProgram,
        amount: formattedAmountOrEmpty(partnerProgram.amount),
        percentage: formattedAmountOrEmpty(partnerProgram.percentage),
        percentageMaxAmount: formattedAmountOrEmpty(partnerProgram.percentageMaxAmount),
      },
    });
  }, [partnerProgram]);

  useEffect(() => {
    if (partnerProgram) {
      const partnerProgramModeIsCurrent =
        (partnerProgram.percentage != null && mode === 'percentage') ||
        (partnerProgram.amount != null && mode === 'fix');

      if (partnerProgramModeIsCurrent) {
        updateState({
          action: 'fill',
          value: {
            ...partnerProgram,
            amount: formattedAmountOrEmpty(partnerProgram.amount),
            percentage: formattedAmountOrEmpty(partnerProgram.percentage),
            percentageMaxAmount: formattedAmountOrEmpty(partnerProgram.percentageMaxAmount),
          },
        });

        return;
      }
    }

    updateState({
      action: 'fill',
      value: {
        ...partnerProgram,
        amount: '',
        percentage: '',
        percentageMaxAmount: '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return { isLoading, handleSubmit, state, updateState, isCreation, handleDelete, mode, setMode };
};

export type ModeType = 'fix' | 'percentage';

type Action = 'updateField' | 'fill' | 'reset';

const initialState: State = {
  id: '',
  name: '',
  amount: '',
  description: '',
  expireInType: 'days',
  expireInNumber: 0,
  currency: Currency.eur,
  percentageMaxAmount: '',
  percentage: '',
  isPrivate: false,
};

const reducer = (
  state: State,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: { action: Action; value: any },
) => {
  const { action, value } = payload;
  switch (action) {
    case 'fill': {
      return { ...state, ...value };
    }
    case 'updateField':
      return { ...state, [value.key]: value.data };
    case 'reset': {
      return initialState;
    }
  }
};

function withoutId<T>(obj: Record<string, unknown>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...rest } = obj;
  return rest as unknown as T;
}

const formattedAmountOrEmpty = (amount?: number | null) => {
  if (amount == null) {
    return '';
  }

  return formatAmount(amount);
};

const numberOrUndefined = (amount?: string) => {
  if (amount === '') {
    return undefined;
  }

  return Number(amount);
};
