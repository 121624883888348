import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '@/axios/axios.instance';
import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { QUERIES_KEYS } from '@/queries';

import { Emote } from '../../entity/emote.entity';
import { useCellDefinition } from '../../use-cell-definition';

import { UpsertEmote } from './upsert-emote/upsert-emote';

export const ListEmote = () => {
  const { CellEmoteDefinition } = useCellDefinition();
  const { t } = useTranslation();

  const { data: emotes } = useQuery<Emote[]>(QUERIES_KEYS.emotesList(), async () => {
    const result = await authenticatedInstance.get('database-explorer/emote');

    return result.data;
  });

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListCustom<Emote>
        results={emotes ?? []}
        enableFilters
        title={t('databaseExplorer.emote.search')}
        cellDefinition={CellEmoteDefinition}
        children={<UpsertEmote emotes={emotes ?? []} />}
      />
    </div>
  );
};
