import type { Product, Show, User } from '@bits-app/bits-server-data';
import {
  centsToUnit,
  currencySymbol,
  formatAmount,
  NotificationTriggerAudienceEnum,
  ShowOrderDeliveryData,
} from '@bits-app/voggtpit-shared';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { Information } from '@/components/layouts/PanelDetails';
import {
  formatDate,
  formatPercentage,
  formatText,
  getLinkShippingTracking,
  getStripeTransferUrl,
} from '@/utils';

import type { ShowOrder } from './show.type';

export type ShowNotification = {
  id: string;
  title: string;
  body: string;
  audiences: NotificationTriggerAudienceEnum[];
  showName: string;
  category: string;
  sentAt: string;
  sentCount: number;
  receivedCount: number;
  openedCount: number;
};

export const panelDetailsShow: Information<Show>[] = [
  { label: 'id', value: 'id' },
  {
    label: 'Category',
    value: 'category',
    type: 'internal-link',
    link: (show) => `/category/child/${show?.categoryId}`,
    format: (show) => show?.category?.name,
  },
  { value: 'name', label: 'Name' },
  { value: 'description', label: 'Description' },
  { value: 'IVSStageId', label: 'IVS Stage ID' },
  {
    label: 'Start at',
    value: 'startAt',
    format: (show) => (show?.startAt ? formatDate(show.startAt, 'dd/MM/yyyy HH:mm:ss') : undefined),
  },
  {
    label: 'Started at',
    value: 'startedAt',
    format: (show) =>
      show?.startedAt ? formatDate(show.startedAt, 'dd/MM/yyyy HH:mm:ss') : undefined,
  },
  {
    label: 'Deleted at',
    value: 'deletedAt',
    format: (show) =>
      show?.deletedAt ? formatDate(show.deletedAt, 'dd/MM/yyyy HH:mm:ss') : undefined,
  },
  {
    label: 'Ended at',
    value: 'endedAt',
    format: (show) => (show?.endedAt ? formatDate(show.endedAt, 'dd/MM/yyyy HH:mm:ss') : undefined),
  },
  {
    label: 'Terminated at (shops only)',
    value: 'terminatedAt',
    format: (show) =>
      show?.terminatedAt ? formatDate(show.terminatedAt, 'dd/MM/yyyy HH:mm:ss') : undefined,
  },
  {
    label: 'Language',
    value: 'language',
    type: 'internal-link',
    link: (show) => `/language/${show?.languageId}`,
    format: (show) => show?.language?.code,
  },
  {
    label: 'Country',
    value: 'country',
    type: 'internal-link',
    link: (show) => `/country/${show?.countryId}`,
    format: (show) => show?.country?.name,
  },
  { label: 'Currency', value: 'currency', type: 'badge' },
  {
    label: 'Streaming type',
    value: 'streamingType',
  },
  {
    value: 'percentageFee',
    label: 'Percentage fee amount',
    format: (show) => Number(show?.percentageFee),
  },
  {
    value: 'fixedFee',
    label: 'Fixed fee amount',
    format: (show) => Number(show?.fixedFee),
  },
  {
    value: 'includeShippingFeesForCommission',
    label: 'Include shipping fees for commission',
    type: 'boolean',
  },
  {
    label: 'Created at',
    value: 'createdAt',
    type: 'date-with-time',
  },
  {
    label: 'Updated at',
    value: 'updatedAt',
    type: 'date-with-time',
  },
  {
    label: 'Deleted at ',
    value: 'deletedAt',
    type: 'date-with-time',
  },
  {
    value: 'shippingCategory',
    label: 'Shipping category',
  },
  {
    label: 'is Broadcasting',
    value: 'isBroadcasting',
    type: 'boolean',
  },
  {
    label: 'is Anniversary',
    value: 'isAnniversary',
    type: 'boolean',
  },
  {
    label: 'is Premier',
    value: 'isPremier',
    type: 'boolean',
  },
  {
    label: 'is Featured',
    value: 'isFeatured',
    type: 'boolean',
  },
  {
    label: 'Default buyer service fee amount',
    value: 'defaultBuyerServiceFeeAmount',
    format: (show) =>
      show
        ? `${formatAmount(centsToUnit(Number(show.defaultBuyerServiceFeeAmount)))}${currencySymbol(
            show.currency,
          )}`
        : '',
  },
  {
    label: 'Is supplier',
    value: 'isSupplier',
    type: 'boolean',
  },
  {
    label: 'Is offline',
    value: 'isOffline',
    type: 'boolean',
  },
  {
    label: 'Is using notifications center',
    value: 'isUsingNotificationsCenter',
    type: 'boolean',
  },
];

export const panelDetailsUser: Information<User>[] = [
  { value: 'id', label: 'id', type: 'internal-link', link: (user) => `/user/${user.id}` },
  { value: 'username', label: 'Username' },
  { value: 'firstName', label: 'Firstname' },
  { value: 'lastName', label: 'Lastname' },
];

export const panelDetailsOrderDelivery: Information<ShowOrderDeliveryData>[] = [
  {
    value: 'percentageGMVDelivered',
    label: '% GMV delivered',
    format: (delivery) => (delivery ? formatPercentage(delivery.percentageGMVDelivered) : ''),
  },
  {
    value: 'percentageGMVDeliveredOrShipped',
    label: '% GMV delivered or shipped',
    format: (delivery) =>
      delivery ? formatPercentage(delivery.percentageGMVDeliveredOrShipped) : '',
  },
];

export const CellShowDefinition: CellDefinition<Show>[] = [
  {
    id: 'id',
    label: 'ShowId',
    type: 'internal-link',
    getLink: (show) => ({ link: `/show/${show?.id}`, label: show?.id }),
  },
  {
    id: 'startAt',
    label: 'Start at',
    type: 'date-with-time',
  },
  {
    id: 'endedAt',
    label: 'End at',
    type: 'date-with-time',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date-with-time',
  },
];

export const CellProductDefinition: CellDefinition<Product>[] = [
  {
    id: 'id',
    label: 'ProductId',
    type: 'internal-link',
    getLink: (product) => ({ link: `/product/${product?.id}`, label: product.id }),
  },
  {
    id: 'showId',
    label: 'Show',
    type: 'internal-link',
    getLink: (product) => ({ link: `/show/${product?.showId}`, label: product.showId }),
  },
  {
    id: 'sellerId',
    label: 'Seller',
    type: 'internal-link',
    getLink: (product) => ({ link: `/show/${product?.showId}`, label: product.sellerId }),
  },
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date-with-time',
  },
  {
    id: 'name',
    label: 'name',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'startingAmount',
    label: 'Starting Amount',
    type: 'money',
    getValue: (order) =>
      `${formatAmount(centsToUnit(Number(order.startingAmount)))}${currencySymbol(order.currency)}`,
  },

  {
    id: 'currency',
    label: 'Currency',
    width: 110,
    type: 'badge',
  },
  {
    id: 'status',
    label: 'Status',
    type: 'badge',
  },
  {
    id: 'type',
    label: 'type',
    type: 'badge',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
  },
];
export const CellOrderDefinition: CellDefinition<ShowOrder>[] = [
  {
    id: 'id',
    label: 'OrderId',
    type: 'internal-link',
    getLink: (order) => ({ link: `/order/${order?.id}`, label: order.id }),
  },
  {
    id: 'status',
    label: 'Status',
    type: 'badge',
  },
  {
    id: 'paymentStatus',
    label: 'Payment status',
    type: 'badge',
  },
  {
    id: 'customerId',
    accessorFn: (order) => order?.customer.username,
    label: 'Customer',
    type: 'internal-link',
    getLink: (order) => ({
      link: `/user/${order?.customerId}`,
      label: order.customer.username,
    }),
  },
  {
    id: 'productId',
    accessorFn: (order) => order?.product.name,
    label: 'Product',
    type: 'internal-link',
    getLink: (order) => ({ link: `/product/${order?.productId}`, label: order?.product?.name }),
  },
  {
    id: 'amount',
    label: 'Amount',
    type: 'money',
    getValue: (order) =>
      `${formatAmount(centsToUnit(Number(order.amount)))}${currencySymbol(order.currency)}`,
  },
  {
    id: 'shippingAmount',
    label: 'Shipping amount',
    type: 'money',
    getValue: (order) =>
      `${formatAmount(centsToUnit(Number(order.shippingAmount)))}${currencySymbol(order.currency)}`,
  },
  {
    id: 'deductedShippingCost',
    label: 'Deducted shipping cost',
    type: 'money',
    getValue: (order) =>
      order.deductedShippingCost != null
        ? `${formatAmount(centsToUnit(Number(order.deductedShippingCost)))}${currencySymbol(
            order.currency,
          )}`
        : '',
  },
  {
    id: 'trackingNumber',
    label: 'Tracking Number',
    type: 'external-link',
    getLink: (order) => ({
      link: getLinkShippingTracking(order),
      label: order.trackingNumber ?? '',
    }),
  },
  {
    id: 'createdAt',
    label: 'Purchased at',
    type: 'date-with-time',
  },
  {
    id: 'currency',
    label: 'Currency',
    type: 'badge',
  },
  {
    id: 'promotionAmount',
    label: 'Promotion amount',
    type: 'money',
    getValue: (order) =>
      order.promotionAmount != null
        ? `${formatAmount(centsToUnit(Number(order.promotionAmount)))}${currencySymbol(
            order.currency,
          )}`
        : '',
  },
  {
    id: 'promotionTopUpAmountTransferred',
    label: 'Promotion top up amount transferred',
    type: 'money',
    getValue: (order) =>
      order.promotionTopUpAmountTransferred != null
        ? `${formatAmount(
            centsToUnit(Number(order.promotionTopUpAmountTransferred)),
          )}${currencySymbol(order.currency)}`
        : '',
  },
  {
    id: 'promotionTopUpStripeTransferId',
    label: 'Promotion top up stripe transfer ID',
    type: 'external-link',
    getLink: (order) => ({
      link: getStripeTransferUrl(order?.promotionTopUpStripeTransferId),
      label: order?.promotionTopUpStripeTransferId,
    }),
  },
  {
    id: 'refundedAmount',
    label: 'Refunded amount',
    type: 'money',
    getValue: (order) =>
      order.refundedAmount > 0 && order.refundReason
        ? `${formatAmount(centsToUnit(Number(order.refundedAmount)))}${currencySymbol(
            order.currency,
          )}`
        : '',
  },
  {
    id: 'refundReason',
    label: 'Refund reason',
  },
  {
    id: 'refundAgent',
    label: 'Refund agent',
  },
  {
    id: 'refundDebitedAccount',
    label: 'Refund debited account',
  },
  {
    id: 'refundedAt',
    label: 'Refund date',
    type: 'date-with-time',
  },
  {
    id: 'cancellationReason',
    label: 'Cancellation reason',
  },
  {
    id: 'cancellationRequesterType',
    label: 'Cancellation requester type',
  },
  {
    id: 'cancellationRequestedAt',
    label: 'Cancellation requested at',
    type: 'date-with-time',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
  },
];

export const CellNotificationDefinition: CellDefinition<ShowNotification & { id: string }>[] = [
  {
    id: 'id',
    label: 'Notification ID',
  },
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'body',
    label: 'Body',
  },
  {
    id: 'audiences',
    label: 'Audiences',
    getValue: (notification) => {
      return notification.audiences.map((audience) => formatText(audience)).join(', ');
    },
  },
  {
    id: 'showName',
    label: 'Show',
  },
  {
    id: 'category',
    label: 'Category',
    getValue: (notification) => formatText(notification.category),
  },
  {
    id: 'sentAt',
    label: 'Sent at',
    type: 'date-with-time',
  },
  {
    id: 'sentCount',
    label: 'Sent count',
  },
  {
    id: 'receivedCount',
    label: 'Received count',
  },
  {
    id: 'openedCount',
    label: 'Opened count',
  },
];
