import { BlockedShippingAddress } from '@bits-app/bits-server-data';
import { useTranslation } from 'react-i18next';

import { useIdParam } from '@/hooks/use-id-params';
import { ItemLayout } from '@/voggt-database-explorer/layouts/ItemLayout';

import { Detail } from './Detail';
import { useBlockedShippingAddressDetailsView } from './useBlockedShippingAddressDetailsView';

export const BlockedShippingAddressDetail = () => {
  const { t } = useTranslation();

  const blockedShippingAddressId = useIdParam();

  const data = useBlockedShippingAddressDetailsView(blockedShippingAddressId);

  const tabs = [
    {
      path: '/',
      label: t('databaseExplorer.blockedShippingAddress.tabs.main'),
      Element: <Detail key="" {...data} />,
    },
  ];

  return (
    <ItemLayout<BlockedShippingAddress>
      id={blockedShippingAddressId}
      tabs={tabs}
      currentTab={'/'}
      onTabChange={() => undefined}
    />
  );
};
