import { ActivityLog, AvailableEntityForComment } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useActivityLogs = ({
  entity,
  entityId,
}: {
  entity: AvailableEntityForComment;
  entityId: string;
}) => {
  return useQuery<(ActivityLog & { entity: AvailableEntityForComment })[]>({
    queryKey: QUERIES_KEYS.activityLogs(entity, entityId),
    queryFn: async () => {
      const { data } = await authenticatedInstance.get<
        (ActivityLog & { entity: AvailableEntityForComment })[]
      >(`/activity-logs/${entity}/${entityId}`);
      return data.map((activityLog) => ({
        ...activityLog,
        createdAt: new Date(activityLog.createdAt),
        entity,
      }));
    },
  });
};
