/**
 * Based on Bits-Web/src/core/lib/utils/delivery-service.ts
 */
export const DeliveryServices: Record<
  string,
  {
    name: string;
    url: string;
  }
> = {
  colissimo: {
    name: 'Colissimo',
    url: 'https://www.laposte.fr/outils/suivre-vos-envois?code={trackingNumber}',
  },
  'la-poste-colissimo': {
    name: 'La Poste',
    url: 'https://www.laposte.fr/outils/suivre-vos-envois?code={trackingNumber}',
  },
  'La Poste': {
    name: 'La Poste',
    url: 'https://www.laposte.fr/outils/suivre-vos-envois?code={trackingNumber}',
  },
  'LA POSTE': {
    name: 'La Poste',
    url: 'https://www.laposte.fr/outils/suivre-vos-envois?code={trackingNumber}',
  },
  'La poste': {
    name: 'La Poste',
    url: 'https://www.laposte.fr/outils/suivre-vos-envois?code={trackingNumber}',
  },
  'la-poste-lettre-suivie': {
    name: 'Lettre suivie',
    url: 'https://www.laposte.fr/outils/suivre-vos-envois?code={trackingNumber}',
  },
  'mondialrelay-fr': {
    name: 'Mondial Relay France',
    url: 'https://www.mondialrelay.fr/suivi-de-colis?numeroExpedition={trackingNumber}&CodePostal={postalCode}',
  },
  'Mondial Relay': {
    name: 'Mondial Relay',
    url: 'https://www.mondialrelay.fr/suivi-de-colis?numeroExpedition={trackingNumber}&CodePostal={postalCode}',
  },
  mondial_relay: {
    name: 'Mondial Relay',
    url: 'https://www.mondialrelay.fr/suivi-de-colis?numeroExpedition={trackingNumber}&CodePostal={postalCode}',
  },
  mondialrelay: {
    name: 'Mondial Relay',
    url: 'https://www.mondialrelay.fr/suivi-de-colis?numeroExpedition={trackingNumber}&CodePostal={postalCode}',
  },
  chronopost: {
    name: 'Chronopost',
    url: 'https://www.chronopost.fr/fr/chrono_suivi_search?listeNumerosLT={trackingNumber}',
  },
  Chronopost: {
    name: 'Chronopost',
    url: 'https://www.chronopost.fr/fr/chrono_suivi_search?listeNumerosLT={trackingNumber}',
  },
  ups: {
    name: 'UPS',
    url: 'https://www.ups.com/track?tracknum={trackingNumber}',
  },
  UPS: {
    name: 'UPS',
    url: 'https://www.ups.com/track?tracknum={trackingNumber}',
  },
  'royal-mail': {
    name: 'Royal Mail',
    url: 'http://www.royalmail.com/portal/rm/track?trackNumber={trackingNumber}',
  },
  royal_mail: {
    name: 'Royal Mail',
    url: 'http://www.royalmail.com/portal/rm/track?trackNumber={trackingNumber}',
  },
  evri: {
    name: 'Evri',
    url: 'https://m.17track.net/track-details?nums={trackingNumber}',
  },
  hermes_uk: {
    name: 'Hermes UK',
    url: 'https://hermesworld.com/en/our-services/distribution/parcel-delivery/parcel-tracking/?trackingNo={trackingNumber}',
  },
  dhl: {
    name: 'DHL',
    url: 'https://www.dhl.com/en/express/tracking.html?AWB={trackingNumber}&brand=DHL',
  },
  dhl_de: {
    name: 'DHL',
    url: 'https://www.dhl.com/de-de/home/tracking/tracking-express.html?tracking-id={trackingNumber}&submit=1',
  },
  'spain-correos-es': {
    name: 'Spain Correos',
    url: 'https://www.correos.es/es/es/herramientas/localizador/envios/detalle?tracking-number={trackingNumber}',
  },
  'deutsch-post': {
    name: 'Deutsch Post',
    url: 'https://www.deutschepost.de/de/s/sendungsverfolgung/verfolgen.html?piececode={trackingNumber}',
  },
  gls_es: {
    name: 'GLS ES',
    url: 'https://mygls.gls-spain.es/e/{trackingNumber}/0/en',
  },
  yodel: {
    name: 'YODEL',
    url: 'https://www.yodel.co.uk/tracking/{trackingNumber}/0',
  },
  postnl: {
    name: 'PostNL',
    url: 'https://tracking.postnl.nl/track-and-trace/{trackingNumber}-NL',
  },
  hand_delivery: {
    name: 'Hand delivery',
    url: '',
  },
  gls_de: {
    name: 'GLS DE',
    url: 'https://www.gls-pakete.de/sendungsverfolgung?match={trackingNumber}',
  },
  dpd: {
    name: 'DPD',
    url: 'https://tracking.dpd.de/status/fr_LU/parcel/{trackingNumber}',
  },
  'La Poste - Lettre Suivie': {
    name: 'La Poste - Lettre Suivie',
    url: 'https://www.laposte.fr/outils/suivre-vos-envois?code={trackingNumber}',
  },
};

export const ShippingProviders = {
  'la-poste-colissimo': 'La Poste Colissimo',
  'la-poste-lettre-suivie': 'La Poste lettre suivie',
  'mondialrelay-fr': 'Mondial Relay France',
  chronopost: 'Chronopost',
  ups: 'UPS',
  'royal-mail': 'Royal Mail',
  evri: 'Evri',
  hermes_uk: 'Hermes UK',
  dhl: 'DHL',
  dhl_de: 'DHL DE',
  'spain-correos-es': 'Spain Correos',
  'deutsch-post': 'Deutsch Post',
  gls_es: 'GLS ES',
  yodel: 'YODEL',
  postnl: 'PostNL',
  hand_delivery: 'Hand delivery',
  gls_de: 'GLS DE',
  dpd: 'DPD',
};
