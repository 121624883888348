import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';
import { Emote } from '@/voggt-database-explorer/emote/entity/emote.entity';

import { UpsertEmoteModal } from '../../../components/upsert-emote/UpsertEmote.modal';
import { useUpsertEmote } from '../hooks/use-upsert-emote.hook';

export const UpsertEmote = ({ emotes }: { emotes: Emote[] }) => {
  const { t } = useTranslation();

  const { ownUserIsAllowedToUpsertEmote, onOpenForm, openForm, onCloseForm } = useUpsertEmote();

  if (!ownUserIsAllowedToUpsertEmote) {
    return null;
  }

  return (
    <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}>
      <ButtonOutlined onClick={onOpenForm}>
        {t('databaseExplorer.emote.upsertEmote.create.action')}
      </ButtonOutlined>
      {openForm && <UpsertEmoteModal isOpen={openForm} onClose={onCloseForm} emotes={emotes} />}
    </Box>
  );
};
