import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Country } from './Country';

@Entity()
export class SellerNews {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column()
  internalName!: string;

  @Column()
  title!: string;

  @Column()
  message!: string;

  @Column({ nullable: true })
  url!: string;

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'countryId' })
  country!: Country;

  @Column({ nullable: true })
  countryId!: Country['id'];

  @Column({ name: 'startAt', type: 'timestamp', nullable: true })
  startAt!: Date;

  @Column({ name: 'endAt', type: 'timestamp', nullable: true })
  endAt!: Date;

  @Column({ nullable: false, default: false })
  isActive!: boolean;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;
}
