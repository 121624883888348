import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { EditButton } from '@/components/elements/buttons/EditButtonWithChildren';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParam } from '@/hooks/use-id-params';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';
import { ForestAdminLink } from '@Elements/ForestAdminLink';

import { UpsertEmoteModal } from '../../components/upsert-emote/UpsertEmote.modal';
import type { Emote } from '../../entity/emote.entity';
import { useUpsertEmote } from '../list/hooks/use-upsert-emote.hook';

import { panelDetailsEmote } from './useEmoteDetailsView';

type DetailProps = {
  emote?: Emote;
  loading: boolean;
};

export const Detail = ({ emote, loading }: DetailProps) => {
  const emoteId = useIdParam();
  const { t } = useTranslation();

  const { ownUserIsAllowedToUpsertEmote, onCloseForm, onOpenForm, openForm } = useUpsertEmote();

  if (!emote) {
    return null;
  }

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'}>
          <Stack spacing={4} direction="row" ml={'auto'} pr={1} pl={1}>
            <EditButton onClick={onOpenForm} hide={!ownUserIsAllowedToUpsertEmote} />
            <ForestAdminLink entity="emote" entityId={emoteId} />
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12} md={12}>
            <PanelDetails<Emote>
              title={t('databaseExplorer.panel.emote')}
              informations={panelDetailsEmote}
              data={emote}
              loading={loading}
            />
          </Grid>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12} md={12}></Grid>
        </Grid>
      </Grid>
      <UpsertEmoteModal emote={emote} onClose={onCloseForm} isOpen={openForm} />
    </DetailsLayout>
  );
};
