import { SentinelFraudCase } from '@bits-app/voggtpit-shared';
import { useNavigate } from 'react-router-dom';

import { ListCustom } from '../../components/elements/DataExplorer/ListCustom';

import { useSentinelList } from './hooks/useSentinelList';
import { SentinelListCellDefinition } from './sentinel.const';

export const SentinelList = () => {
  const { data } = useSentinelList();
  const results = data?.data || [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListCustom<SentinelFraudCase>
        cellDefinition={SentinelListCellDefinition}
        results={results}
        title="Sentinel"
        // onClickOnRow={(row) => navigate(`/sentinel/${row.id}`)}
      />
    </div>
  );
};
