import { useEffect, useMemo } from 'react';

import { MaterialReactTableProps } from 'material-react-table';

import { FindOptions } from '@/hooks/use-table-options';

import { searchAPIType } from '../datalist-type';

export const useSearch = ({
  options,
  onChangeSearch,
  searchAPI,
  scrollToTop,
}: {
  options: FindOptions;
  onChangeSearch: (search?: string) => void;
  searchAPI?: searchAPIType;
  scrollToTop: () => void;
}): [string, MaterialReactTableProps['onGlobalFilterChange']] => {
  const search = useMemo(() => options.search ?? '', [options.search]);

  const handleOnGlobalFilterChange: MaterialReactTableProps['onGlobalFilterChange'] = (
    updaterOrValue,
  ) => {
    const value = typeof updaterOrValue === 'function' ? updaterOrValue(search) : updaterOrValue;

    onChangeSearch(value);
  };

  useEffect(() => {
    if (!searchAPI) {
      return;
    }

    searchAPI.onGlobalFilterChange(options.search ?? '');
    scrollToTop();
  }, [options.search, scrollToTop, searchAPI]);

  return [search, handleOnGlobalFilterChange];
};
