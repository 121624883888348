import { createSelector } from '@reduxjs/toolkit';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { useAppSelector } from '@/redux/reduxAppHooks';
import { AppThunkAction } from '@/redux/store';

import { EntityWrapper, ResourceType } from '../database-explorer';
import { databaseExplorerAction, resourceSelector } from '../database-explorer.slice';

export const getResource =
  (resourceId: EntityWrapper['id'], resourceType: ResourceType): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { databaseExplorerGateway, snackbarGateway }) => {
    dispatch(databaseExplorerAction.setIsLoading(true));
    try {
      const data = await databaseExplorerGateway.getResource(resourceId, resourceType);
      if (data) {
        dispatch(databaseExplorerAction.setData(data));
      } else {
        dispatch(databaseExplorerAction.setData({}));
      }
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));
        return;
      }
      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(databaseExplorerAction.setIsLoading(false));
    }
  };

export const selectResource = createSelector(resourceSelector, (state) => state.data);

export const useResourceSelector = <T extends EntityWrapper>(
  ...params: Parameters<typeof useAppSelector>
) => useAppSelector(...params) as T;
