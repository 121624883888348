import { useCallback, useMemo } from 'react';

import { nanoid } from '@reduxjs/toolkit';

import { SnackbarOptions, SnackbarPort } from '@/domain/SnackbarPort';

import { useSnackbarContext } from './SnackbarProvider';

export const useSnackbar = () => {
  const { addSnackbar } = useSnackbarContext();

  const add = useCallback(
    (type: 'success' | 'error' | 'info' | 'warning') =>
      (message: string, options: SnackbarOptions = {}) => {
        const { interpolationMap, subText } = options;

        addSnackbar({
          id: nanoid(),
          type,
          message,
          interpolationMap,
          subText,
        });
      },
    [addSnackbar],
  );

  return useMemo<SnackbarPort>(
    () => ({
      success: add('success'),
      error: add('error'),
      info: add('info'),
      warning: add('warning'),
    }),
    [add],
  );
};
