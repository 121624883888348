import { useCallback, useState } from 'react';

import { UserBalance, PERMISSIONS, TransferFundPayload } from '@bits-app/voggtpit-shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { useOwnUser } from '@/context/own-user.context';
import { QUERIES_KEYS } from '@/queries';

import { User } from '../users';

export const useDirectFundTransfer = (userId: User['id']) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const snackbar = useSnackbar();

  const { data } = useQuery(
    QUERIES_KEYS.userPaymentBalance(userId),
    () => getUserPaymentBalance(userId),
    {
      enabled: Boolean(modalIsOpen),
    },
  );

  const { mutate, isLoading } = useMutation(processPayment, {
    onSuccess: () => {
      snackbar.success('databaseExplorer.users.paymentDirectTransfer.success');
    },
    onError: (error: AxiosError) => {
      setError(error.message);
    },
  });

  const toggleModalDirectFundTransfer = useCallback(() => setModalIsOpen((prev) => !prev), []);

  const { hasPermissions } = useOwnUser();

  const userIsAllowed =
    hasPermissions(PERMISSIONS.DIRECT_FUND_TRANSFER.WRITE_DIRECT_FUND_TRANSFER_UNIT) ||
    hasPermissions(PERMISSIONS.DIRECT_FUND_TRANSFER.WRITE_DIRECT_FUND_TRANSFER_BULK);

  const submitUpdate = async (payload: Omit<TransferFundPayload, 'userId'>) => {
    setError(null);
    mutate({ ...payload, userId });
  };

  return {
    modalIsOpen,
    toggleModalDirectFundTransfer,
    error,
    userIsAllowed,
    submitUpdate,
    isLoading,
    ...data,
  };
};

const getUserPaymentBalance = async (userId: User['id']) => {
  const result = await authenticatedInstance.get<UserBalance>(
    `payment/available-for-transfer/${userId}`,
  );
  return result.data;
};

const processPayment = async (transferFundPayload: TransferFundPayload) => {
  return authenticatedInstance.post(`payment/transfer`, transferFundPayload);
};
