import { OrderStatus } from '@bits-app/voggtpit-shared';
import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LoadingButton } from '@/components/elements/buttons/LoadingButton';
import { selectResourceLoading } from '@/voggt-database-explorer/redux/database-explorer.slice';

type ShowOrderShippedOrDeliveredModalProps = {
  status: OrderStatus.delivered | OrderStatus.shipped;
  onClose: () => void;
  onConfirm: () => void;
};

export const ShowOrderShippedOrDeliveredModal = ({
  status,
  onClose,
  onConfirm,
}: ShowOrderShippedOrDeliveredModalProps) => {
  const { t } = useTranslation();

  const isLoading = useSelector(selectResourceLoading);

  return (
    <Dialog open>
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        {t('databaseExplorer.show.orders.shippedOrDelivered.title')}
        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ minWidth: 300 }}>
        {t('databaseExplorer.show.orders.shippedOrDelivered.confirm', { status })}
      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: ({ spacing }) => spacing(3),
          paddingLeft: ({ spacing }) => spacing(3),
          paddingBottom: ({ spacing }) => spacing(2),
        }}
      >
        <LoadingButton variant="contained" loading={isLoading} onClick={onConfirm}>
          {t('action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
