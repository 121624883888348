import { useEffect, useState } from 'react';

import { CountryCodeIso2, Currency, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Box, Button, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { ErrorDialog } from '@Elements/ErrorDialog';
import { LoadingRow } from '@Elements/LoadingRow';
import { TableCellBodyNoBorderBottom } from '@Elements/StyledTableCell';
import { TableToolbar } from '@Elements/TableToolbar';

import { NotAllProcessedErrorDialog } from '../../components/NotAllProcessedErrorDialog';
import {
  getSellerCancelRequestsForCurrency,
  getSellerCancelRequestsCountPerCountries,
  getSellerCancelRequestsRefundSummary,
  refundSellerCancelRequests,
  resetSellerCancelRequests,
} from '../../seller-cancel-requests/use-cases';
import {
  selectIsSellerCancelRequestsLoading,
  selectIsSellerCancelRequestsRefundFormError,
  selectIsSellerCancelRequestsRefundFormNotProcessedError,
  selectSellerCancelRequests,
  selectSellerCancelRequestsInRefund,
} from '../redux/seller-cancel-requests.selectors';
import { sellerCancelRequestsActions } from '../redux/seller-cancel-requests.slice';

import { ConfirmRefundSellerCancelRequestsDialog } from './ConfirmRefundSellerCancelRequestsDialog/ConfirmRefundSellerCancelRequestsDialog';
import { SellerCancelRequestFooter } from './SellerCancelRequestFooter';
import { SellerCancelRequestRow } from './SellerCancelRequestRow';
import { SellerCancelRequestsTabs } from './SellerCancelRequestTabs';
import { SellerCancelRequestsHeader } from './SellerCancelRequestsHeader';

export type HeadCell = {
  id: keyof OrderItemRefund;
  label: string;
  minWidth?: React.CSSProperties['minWidth'];
};

type SellerCancelRequestsProps = {
  currency: Currency;
  countries: CountryCodeIso2[];
  defaultCountry: CountryCodeIso2;
};

export const SellerCancelRequests = ({
  currency,
  countries,
  defaultCountry,
}: SellerCancelRequestsProps) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const { country } = useParams<{ country: string }>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { hasPermissions } = useOwnUser();

  const sellerCancelRequests = useAppSelector(selectSellerCancelRequests);
  const sellerCancelRequestsInRefund = useAppSelector(selectSellerCancelRequestsInRefund);
  const refundErrors = useAppSelector(selectIsSellerCancelRequestsRefundFormError);
  const refundNotAllProcessedErrors = useAppSelector(
    selectIsSellerCancelRequestsRefundFormNotProcessedError,
  );
  const isLoading = useAppSelector(selectIsSellerCancelRequestsLoading);

  const notProcessedError = useAppSelector(selectIsSellerCancelRequestsRefundFormNotProcessedError);

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
    dispatch(getSellerCancelRequestsRefundSummary());
  };

  const handleRefundSellerCancelRequests = async () => {
    await dispatch(refundSellerCancelRequests());

    setOpenConfirmation(false);
  };

  const handleOnCloseError = () => {
    dispatch(sellerCancelRequestsActions.resetRefundForm());
    dispatch(sellerCancelRequestsActions.resetRefundSummary());
  };

  const isReadOnly = !hasPermissions(PERMISSIONS.SELLER_CANCEL_REQUEST.WRITE_SELLER_CANCEL_REQUEST);

  const headCells: HeadCell[] = [
    { id: 'id', label: t('orderItemLabels.orderItemId'), minWidth: 70 },
    { id: 'seller', label: t('orderItemLabels.seller'), minWidth: 130 },
    { id: 'product', label: t('orderItemLabels.product'), minWidth: 140 },
    { id: 'buyer', label: t('orderItemLabels.buyer'), minWidth: 130 },
    { id: 'purchaseDate', label: t('orderItemLabels.purchaseDate'), minWidth: 180 },
    { id: 'cancelDate', label: t('orderItemLabels.cancelDate'), minWidth: 180 },
    { id: 'amount', label: t('orderItemLabels.amount'), minWidth: 80 },
    {
      id: 'shippingAmount',
      label: t('orderItemLabels.shippingAmount'),
      minWidth: 135,
    },
    { id: 'cancellationReason', label: t('orderItemLabels.cancellationReason'), minWidth: 145 },
    { id: 'shippingStatus', label: t('orderItemLabels.shippingStatus'), minWidth: 120 },
    { id: 'paymentStatus', label: t('orderItemLabels.paymentStatus'), minWidth: 130 },
  ];

  useEffect(() => {
    dispatch(resetSellerCancelRequests());

    const filteredCountry =
      country === 'all' ? [] : [(country?.toUpperCase() as CountryCodeIso2) ?? defaultCountry];

    dispatch(getSellerCancelRequestsForCurrency(currency, filteredCountry));

    return () => dispatch(resetSellerCancelRequests());
  }, [currency, dispatch, country, defaultCountry]);

  useEffect(() => {
    dispatch(getSellerCancelRequestsCountPerCountries(currency));
  }, [currency, dispatch]);

  return (
    <div className="flex flex-col flex-1 h-full">
      <TableToolbar
        selectedCount={sellerCancelRequestsInRefund.length}
        title={
          <SellerCancelRequestsTabs
            currency={currency}
            countries={countries}
            tab={country ?? defaultCountry.toLocaleLowerCase()}
          />
        }
      />

      <TableContainer
        sx={{
          height: 'fit-content',
          borderBottom: ({ palette }) => `1px solid ${palette.grey[200]}`,
          backgroundColor: ({ palette }) => palette.background.paper,
        }}
      >
        <Table sx={{ overflow: 'visible' }} stickyHeader>
          <SellerCancelRequestsHeader
            headCells={headCells}
            loading={isLoading}
            isReadOnly={isReadOnly}
          />

          <TableBody>
            {isLoading ? (
              <LoadingRow emptyFirstCell cellCount={headCells.length + 1} />
            ) : sellerCancelRequests.length === 0 ? (
              <TableRow>
                <TableCellBodyNoBorderBottom
                  colSpan={headCells.length + 1}
                  sx={{ fontStyle: 'italic' }}
                >
                  {t('sellerCancelRequests.noData')}...
                </TableCellBodyNoBorderBottom>
              </TableRow>
            ) : (
              sellerCancelRequests.map((sellerCancelRequest) => (
                <SellerCancelRequestRow
                  key={sellerCancelRequest.id}
                  sellerCancelRequest={sellerCancelRequest}
                  isReadOnly={isReadOnly}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <SellerCancelRequestFooter count={sellerCancelRequests.length} />

      {!isReadOnly && (
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <Button
            variant="contained"
            onClick={handleOpenConfirmation}
            disabled={sellerCancelRequestsInRefund.length === 0}
          >
            {t('refund.refund')}
          </Button>
        </Box>
      )}
      {openConfirmation && (
        <ConfirmRefundSellerCancelRequestsDialog
          currency={currency}
          onClose={() => setOpenConfirmation(false)}
          onConfirm={handleRefundSellerCancelRequests}
        />
      )}

      {refundNotAllProcessedErrors && (
        <NotAllProcessedErrorDialog
          errorText={t('sellerCancelRequests.error.notAllProcessed')}
          error={notProcessedError}
          onClose={handleOnCloseError}
        />
      )}
      {refundErrors && <ErrorDialog errors={refundErrors} onClose={handleOnCloseError} />}
    </div>
  );
};
