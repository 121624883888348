/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ZendeskTicket } from '@bits-app/voggtpit-shared';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { useOwnUser } from '@/context/own-user.context';

import { useIdParamAsNumber } from '../../../../hooks/use-id-params';
import { cellZendeskTicketsDefinition } from '../../hooks/use-cell-definition';
import { useZendesk } from '../../hooks/useZendesk';

export const ZendeskTicketsTab = () => {
  const { ownUser } = useOwnUser();
  const userId = useIdParamAsNumber();

  const { data } = useZendesk(userId);

  if (!data) {
    return null;
  }

  return (
    <ListCustom<ZendeskTicket>
      cellDefinition={cellZendeskTicketsDefinition(ownUser!)}
      results={data.data.zendeskTickets || []}
      enableFilters
    />
  );
};
