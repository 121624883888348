import { OrderItemRefund as OrderItemRefundInDto, Refund } from '@bits-app/voggtpit-shared';

import { OrderItem, orderItemDtoToEntity } from './order-item.entity';

export type OrderItemRefund = OrderItem & { refunds: Refund[] };

export const orderItemRefundDtoToEntity = (dto: OrderItemRefundInDto): OrderItemRefund => {
  const orderItem = orderItemDtoToEntity(dto);

  return {
    ...orderItem,
    refunds: dto.refunds,
  };
};
