import { OwnUser as CommonOwnUser, Permission } from '@bits-app/voggtpit-shared';

export type OwnUser = {
  id: string;
  email: string;
  lastName: string;
  firstName: string;
  fullName: string;
  permissions: Permission[];
  isAuthenticatedToZendesk: boolean;
  isRegisterWithPassword?: boolean;
};

export const ownUserDtoToEntity = (dto: CommonOwnUser): OwnUser => ({
  id: dto.id,
  email: dto.email,
  lastName: dto.lastName,
  firstName: dto.firstName,
  fullName: [dto.firstName, dto.lastName].join(' '),
  permissions: dto.permissions,
  isAuthenticatedToZendesk: Boolean(dto.isAuthenticatedToZendesk),
  isRegisterWithPassword: !dto.isRegisteredWithGoogle,
});
