import { useEffect, useRef } from 'react';

import { Box, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import { SxProps } from '@mui/system';

type TableWithStickyColumnsProps = {
  tableHeader: React.ReactNode;
  tableBody: React.ReactNode;
  children: React.ReactNode;
  sxTableContainer?: SxProps;
  mainTableRef?: HTMLDivElement | null;
};

export const TableWithStickyColumns = ({
  tableHeader,
  tableBody,
  mainTableRef,
  children,
  sxTableContainer = {},
}: TableWithStickyColumnsProps) => {
  const stickyTableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mainTableRef) {
      return;
    }

    if (!stickyTableRef.current) {
      return;
    }

    const handleOnScroll = (event: Event) => {
      const element = event.target;

      if (!element) {
        return;
      }

      const div = element as HTMLDivElement;
      const { scrollTop } = div;

      if (!stickyTableRef.current) {
        return;
      }

      stickyTableRef.current.scrollTop = scrollTop;
    };

    mainTableRef.addEventListener('scroll', handleOnScroll);

    return () => {
      mainTableRef.removeEventListener('scroll', handleOnScroll);
    };
  }, [mainTableRef]);

  const handleOnStickyScroll = (event: React.UIEvent) => {
    if (!mainTableRef) {
      return;
    }

    const { scrollTop } = event.currentTarget;
    mainTableRef.scrollTop = scrollTop;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        maxWidth: '100%',
        overflow: 'hidden',
      }}
    >
      <TableContainer
        ref={stickyTableRef}
        component="div"
        onScroll={handleOnStickyScroll}
        sx={{
          overflowX: 'scroll',
          borderRight: ({ palette }) => `0.5px solid ${palette.grey[100]}`,
          boxShadow: ({ palette }) => `3px 0 5px -2px ${palette.grey[400]}`,
          marginRight: ({ spacing }) => spacing(0.5),
          backgroundColor: ({ palette }) => palette.background.paper,
          ...sxTableContainer,
        }}
      >
        <Table sx={{ overflow: 'visible' }} stickyHeader>
          <TableHead>{tableHeader}</TableHead>

          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>

      {children}
    </Box>
  );
};
