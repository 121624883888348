import { useMemo } from 'react';

import { Country } from '@bits-app/bits-server-data';
import { Promotion, PromotionType } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';

import { AddFilterFunctionParams, FindOptions } from '@/hooks/use-table-options';
import { CountryFilter } from '@/promotions/ui/table/filters/CountryFilter';
import { PromotionTypeFilter } from '@/promotions/ui/table/filters/PromotionTypeFilter';
import { DateTimeFilterTable } from '@Elements/filters/table';
import { isOperatorFilter, isSetFilter, isStringFilter } from '@Elements/filters/types';

import { PromotionNameFilter } from '../filters/PromotionNameFilter';

export type PromotionsHeadCells = {
  id: keyof Promotion | 'action' | 'usage';
  label: string;
  minWidth?: number;
  maxWidth?: number;
  sortable?: boolean;
  filter?: React.ReactNode;
};

export interface UsePromotionsTableHeadersProps {
  addFilter: (filter: AddFilterFunctionParams) => void;
  filters: FindOptions['filters'];
  isReadOnly: boolean;
  countries: Country[];
}

export const usePromotionsTableHeaders = ({
  addFilter,
  filters,
  isReadOnly,
  countries,
}: UsePromotionsTableHeadersProps) => {
  const { t } = useTranslation();

  const headCells = useMemo((): PromotionsHeadCells[] => {
    const columns: PromotionsHeadCells[] = [
      { id: 'id', label: t('promotion.id'), minWidth: 70, sortable: true },
      {
        id: 'name',
        label: t('promotion.name'),
        minWidth: 160,
        sortable: true,
        filter: (
          <PromotionNameFilter
            addFilter={addFilter}
            activeFilters={isStringFilter(filters.name) ? filters.name : undefined}
          />
        ),
      },
      {
        id: 'type',
        label: t('promotion.type'),
        minWidth: 150,
        sortable: true,
        filter: (
          <PromotionTypeFilter
            activeFilters={
              isSetFilter(filters.type)
                ? (filters.type?.filter((value) =>
                    Object.values(PromotionType).includes(value as PromotionType),
                  ) as PromotionType[])
                : undefined
            }
            addFilter={addFilter}
            isUnsaved={false}
          />
        ),
      },
      {
        id: 'countryId',
        label: t('promotion.country'),
        minWidth: 150,
        sortable: true,
        filter: (
          <CountryFilter
            activeFilters={isSetFilter(filters.country) ? filters.country : undefined}
            addFilter={addFilter}
            isUnsaved={false}
            countries={countries}
          />
        ),
      },
      {
        id: 'promotionAmount',
        label: t('promotion.promotionAmount'),
        minWidth: 160,
        sortable: true,
      },
      {
        id: 'promotionPercentage',
        label: t('promotion.promotionPercentage'),
        minWidth: 160,
        sortable: true,
      },
      {
        id: 'promotionMaxAmount',
        label: t('promotion.promotionMaxAmount'),
        minWidth: 160,
        sortable: true,
      },
      {
        id: 'expireAt',
        label: t('promotion.expireAt'),
        minWidth: 160,
        sortable: true,
        filter: (
          <DateTimeFilterTable
            field="expireAt"
            addFilter={addFilter}
            activeFilters={
              isOperatorFilter<string>(filters.expireAt) ? filters.expireAt : undefined
            }
          />
        ),
      },
      { id: 'spentBudget', label: t('promotion.spentBudget'), minWidth: 160, sortable: true },
      { id: 'totalBudget', label: t('promotion.totalBudget'), minWidth: 160, sortable: true },
      { id: 'usage', label: t('promotion.usage'), minWidth: 160 },
      { id: 'updatedAt', label: t('promotion.updatedAt'), minWidth: 160 },
    ];

    if (!isReadOnly) {
      columns.unshift({ id: 'action', label: '', minWidth: 20, sortable: false });
    }

    return columns;
  }, [
    t,
    addFilter,
    filters.name,
    filters.type,
    filters.country,
    filters.expireAt,
    countries,
    isReadOnly,
  ]);

  return { headCells };
};
