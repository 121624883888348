import { CountryCodeIso2, Currency } from '@bits-app/voggtpit-shared';
import { Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '@/redux/reduxAppHooks';
import { isoCodeToEmojiFlag } from '@/utils';

import { selectSellerCancelRequestsCounts } from '../redux/seller-cancel-requests.selectors';

type SellerCancelRequestsTabsProps = {
  currency: Currency;
  countries: CountryCodeIso2[];
  tab: string;
};

export const SellerCancelRequestsTabs = ({
  currency,
  countries,
  tab,
}: SellerCancelRequestsTabsProps) => {
  const navigate = useNavigate();
  const counts = useAppSelector(selectSellerCancelRequestsCounts);

  const handleChange = (_: React.SyntheticEvent, value: CountryCodeIso2) => {
    navigate(`/seller-cancel-requests/${currency.toLocaleLowerCase()}/${value.toLowerCase()}`);
  };

  return (
    <Tabs value={tab} onChange={handleChange} scrollButtons="auto">
      {countries.map((country) => (
        <Tab
          key={country}
          sx={{ p: 1 }}
          label={`${isoCodeToEmojiFlag[country]} ${country} (${counts[country]})`}
          value={country.toLowerCase()}
        />
      ))}
      <Tab key={'all'} sx={{ p: 1 }} label={`WORLD 🌍`} value={'all'} />
    </Tabs>
  );
};
