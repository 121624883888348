import {
  buildModulePermissions,
  buildModuleSubModulePermissions,
  buildReadModulePermissions,
  buildReadModuleSubModulePermissions,
  buildWriteModulePermissions,
  buildWriteModuleSubModulePermissions,
} from './builder';

const DATABASE_EXPLORER = [
  'badge',
  'blocked_shipping_address',
  'category',
  'channel',
  'commercial_event',
  'country',
  'emote',
  'featured_show_application',
  'language',
  'order',
  'payment_method',
  'product',
  'seller_news',
  'shipment',
  'shipping_address',
  'shipping_fee_config',
  'seller_company',
  'shop',
  'show',
  'user',
] as const;

const UPDATE_FEES = ['bulk', 'unit'] as const;

const SENTINEL = ['add_user_to_fraud_case'] as const;

const USER_WRITE = [
  'remove_sso',
  'hard_block',
  'add_promotions',
  'ban',
  'seller_config',
  'delete',
] as const;

const USER_READ = ['zendesk_tickets', 'zoho_link'] as const;

const DIRECT_FUND_TRANSFER = ['bulk', 'unit'] as const;

const ORDER_WRITE = ['cancel'] as const;

const ADMINISTRATION = ['permissions'] as const;
const ADMINISTRATION_WRITE = ['password'] as const;

const ADD_BADGE_TO_USER = ['bulk', 'unit'] as const;
const REMOVE_BADGE_FROM_USER = ['unit'] as const;

const BADGE = ['update_translations'] as const;

const CATEGORY = ['update_translations'] as const;

const SHOW_WRITE = ['unlock_auction_and_giveaways'] as const;

const SHOP_ORDER_WRITE = [
  'unit_update_payout_status',
  'bulk_update_payout_status',
] as const;

const COMMENT_WRITE = ['delete'] as const;

const SUPER_PRIVILEGE = [
  'ADMINISTRATION_WRITE_PERMISSIONS_MANAGE_ALL',
  'PERSIST_BADGE',
  'DELETE_OWN_USER',
  'REMOVE_FROM_COMMERCIAL_EVENT',
] as const;

const OWN_USER_INTERFACE = ['select_multiple_lines'] as const;

export const PERMISSIONS = {
  DATABASE_EXPLORER: buildModuleSubModulePermissions(
    'database_explorer',
    DATABASE_EXPLORER,
  ),
  SELLER_COMPANY_SENSITIVE_INFO: buildWriteModulePermissions(
    'seller_company_sensitive_info',
  ),
  // maybe to split per country
  ORDER_ITEM_MASS_UPDATE: buildModulePermissions('order_item_mass_update'),
  UPDATE_FEES: buildWriteModuleSubModulePermissions('update_fees', UPDATE_FEES),
  ADD_BADGE_TO_USER: buildWriteModuleSubModulePermissions(
    'add_badge_to_user',
    ADD_BADGE_TO_USER,
  ),
  DIRECT_FUND_TRANSFER: buildWriteModuleSubModulePermissions(
    'direct_fund_transfer',
    DIRECT_FUND_TRANSFER,
  ),
  REMOVE_BADGE_FROM_USER: buildWriteModuleSubModulePermissions(
    'remove_badge_from_user',
    REMOVE_BADGE_FROM_USER,
  ),
  BADGE: buildWriteModuleSubModulePermissions('badge', BADGE),
  CATEGORY: buildWriteModuleSubModulePermissions('category', CATEGORY),
  // maybe to split per country
  REFUND: buildModulePermissions('refund'),
  REFUND_COMMISSION: buildWriteModulePermissions('refund_commission'),
  REFUND_SHOW: buildWriteModulePermissions('refund_show'),
  // maybe to split per country
  SELLER_CANCEL_REQUEST: buildModulePermissions('seller_cancel_request'),
  // maybe to split per currency
  PROMOTION: buildModulePermissions('promotion'),
  // maybe to split per currency
  PARTNER_PROGRAM: buildModulePermissions('partner_program'),
  LINK_ZENDESK_ACCOUNT: buildWriteModulePermissions('link_zendesk_account'),
  USER: {
    ...buildWriteModuleSubModulePermissions('user', USER_WRITE),
    ...buildReadModuleSubModulePermissions('user', USER_READ),
  },
  ORDER: buildWriteModuleSubModulePermissions('order', ORDER_WRITE),
  PAYOUT: buildModulePermissions('payout'),
  PAYOUT_SCHEME: buildModulePermissions('payout_scheme'),
  ADMINISTRATION: {
    ...buildModuleSubModulePermissions('administration', ADMINISTRATION),
    ...buildWriteModuleSubModulePermissions(
      'administration',
      ADMINISTRATION_WRITE,
    ),
  },
  SHOW: buildWriteModuleSubModulePermissions('show', SHOW_WRITE),

  SHOW_STRESS_TEST: buildWriteModulePermissions('show_stress_test'),

  SHOP_ORDER: buildWriteModuleSubModulePermissions(
    'shop_order',
    SHOP_ORDER_WRITE,
  ),
  COMMENT: {
    ...buildModulePermissions('comment'),
    ...buildWriteModuleSubModulePermissions('comment', COMMENT_WRITE),
  },
  IMPERSONATE_ACCESS: buildWriteModulePermissions('impersonate_access'),
  OWN_USER_INTERFACE: {
    ...buildReadModuleSubModulePermissions(
      'own_user_interface',
      OWN_USER_INTERFACE,
    ),
  },

  SUPER_PRIVILEGE: {
    ...buildWriteModuleSubModulePermissions('super_privilege', SUPER_PRIVILEGE),
  },
  LEDGER: {
    ...buildReadModuleSubModulePermissions('ledger', ['view'] as const),
    ...buildWriteModuleSubModulePermissions('ledger', ['payout'] as const),
    ...buildWriteModuleSubModulePermissions('ledger', [
      'update_transaction',
    ] as const),
    ...buildWriteModuleSubModulePermissions('ledger', [
      'manual_update',
    ] as const),
  },
  SENTINEL: {
    ...buildWriteModuleSubModulePermissions('sentinel', SENTINEL),
  },
  AUTO_FOLLOW_USER_CAMPAIGN: buildModulePermissions(
    'auto_follow_user_campaign',
  ),
  FEED_RECOMMANDATION: buildReadModulePermissions('feed_recommandation'),
  MASS_IMPORT: buildWriteModulePermissions('mass_import'),
};

type Values<T> = T[keyof T];

type PermissionsMap<T extends Record<string, Record<string, string>>> = {
  [Module in keyof T]: keyof T[Module];
};

export type Permission = Values<PermissionsMap<typeof PERMISSIONS>>;

export const ALL_PERMISSIONS: Permission[] = Object.values(PERMISSIONS)
  .map(Object.values)
  .flat();

export const PERMISSIONS_EXCLUDING_SUPER_PRIVILEGES: Permission[] =
  ALL_PERMISSIONS.filter(
    (permission: string) => !permission.includes('SUPER_PRIVILEGE'),
  );

export const SUPER_PRIVILEGES_PERMISSIONS: Permission[] =
  ALL_PERMISSIONS.filter((permission: string) =>
    permission.includes('SUPER_PRIVILEGE'),
  );

/** @protected not to use outside @bits-app/voggtpit-shared itself */
export const SUPER_ADMIN: Permission[] = [
  ...PERMISSIONS_EXCLUDING_SUPER_PRIVILEGES,
  ...SUPER_PRIVILEGES_PERMISSIONS,
];

/** @protected not to use outside @bits-app/voggtpit-shared itself */
export const ADMIN: Permission[] = PERMISSIONS_EXCLUDING_SUPER_PRIVILEGES;

/** @protected not to use outside @bits-app/voggtpit-shared itself */
export const CARE_INTERN_LEAD: Permission[] = [
  ...Object.values({
    ...buildModuleSubModulePermissions('database_explorer', DATABASE_EXPLORER),
    ...buildModulePermissions('order_item_mass_update'),
    ...buildModulePermissions('refund'),
    ...buildModulePermissions('seller_cancel_request'),
    ...buildModulePermissions('promotion'),
    ...buildModulePermissions('partner_program'),
    ...buildWriteModulePermissions('link_zendesk_account'),
    ...buildModulePermissions('payout'),
    ...buildModulePermissions('payout_scheme'),
    ...buildWriteModuleSubModulePermissions('user', USER_WRITE),
    ...buildReadModuleSubModulePermissions('user', USER_READ),
    ...buildWriteModuleSubModulePermissions('order', ORDER_WRITE),
    ...buildWriteModuleSubModulePermissions('show', SHOW_WRITE),
    ...buildWriteModuleSubModulePermissions('shop_order', SHOP_ORDER_WRITE),
    ...buildModulePermissions('comment'),
    ...buildWriteModuleSubModulePermissions(
      'add_badge_to_user',
      ADD_BADGE_TO_USER,
    ),
    ...buildWriteModuleSubModulePermissions(
      'remove_badge_from_user',
      REMOVE_BADGE_FROM_USER,
    ),
    ...buildReadModuleSubModulePermissions(
      'own_user_interface',
      OWN_USER_INTERFACE,
    ),
  }),
];

/** @protected not to use outside @bits-app/voggtpit-shared itself */
export const CARE_INTERN: Permission[] = [
  ...Object.values({
    ...buildModuleSubModulePermissions('database_explorer', DATABASE_EXPLORER),
    ...buildModulePermissions('order_item_mass_update'),
    ...buildModulePermissions('refund'),
    ...buildModulePermissions('seller_cancel_request'),
    ...buildReadModulePermissions('promotion'),
    ...buildReadModulePermissions('partner_program'),
    ...buildWriteModulePermissions('link_zendesk_account'),
    ...buildReadModulePermissions('payout'),
    ...buildReadModulePermissions('payout_scheme'),
    ...buildWriteModuleSubModulePermissions('user', USER_WRITE),
    ...buildReadModuleSubModulePermissions('user', USER_READ),
    ...buildWriteModuleSubModulePermissions('order', ORDER_WRITE),
    ...buildWriteModuleSubModulePermissions('show', SHOW_WRITE),
    ...buildWriteModuleSubModulePermissions('shop_order', SHOP_ORDER_WRITE),
    ...buildModulePermissions('comment'),
    ...buildWriteModuleSubModulePermissions(
      'add_badge_to_user',
      ADD_BADGE_TO_USER,
    ),
    ...buildWriteModuleSubModulePermissions(
      'remove_badge_from_user',
      REMOVE_BADGE_FROM_USER,
    ),
    ...buildReadModuleSubModulePermissions(
      'own_user_interface',
      OWN_USER_INTERFACE,
    ),
  }),
];

/** @protected not to use outside @bits-app/voggtpit-shared itself */
export const GROWTH: Permission[] = [
  ...Object.values({
    ...buildModuleSubModulePermissions('database_explorer', DATABASE_EXPLORER),
    ...buildModulePermissions('promotion'),
    ...buildModulePermissions('partner_program'),
    ...buildWriteModuleSubModulePermissions('show', SHOW_WRITE),
    ...buildReadModuleSubModulePermissions('user', USER_READ),
    ...buildModulePermissions('comment'),
  }),
];

/** @protected not to use outside @bits-app/voggtpit-shared itself */
export const OPS: Permission[] = [
  ...Object.values({
    ...buildModuleSubModulePermissions('database_explorer', DATABASE_EXPLORER),
    ...buildModulePermissions('order_item_mass_update'),
    ...buildWriteModuleSubModulePermissions('show', SHOW_WRITE),
    ...buildWriteModuleSubModulePermissions('shop_order', SHOP_ORDER_WRITE),
    ...buildReadModuleSubModulePermissions('user', USER_READ),
    ...buildModulePermissions('comment'),
  }),
];

/** @protected not to use outside @bits-app/voggtpit-shared itself */
export const REGIONAL_MANAGER: Permission[] = [
  ...Object.values({
    ...buildModuleSubModulePermissions('database_explorer', DATABASE_EXPLORER),
    ...buildWriteModuleSubModulePermissions('update_fees', UPDATE_FEES),
    ...buildWriteModuleSubModulePermissions(
      'add_badge_to_user',
      ADD_BADGE_TO_USER,
    ),
    ...buildWriteModuleSubModulePermissions(
      'remove_badge_from_user',
      REMOVE_BADGE_FROM_USER,
    ),
    ...buildReadModulePermissions('payout'),
    ...buildWriteModuleSubModulePermissions('show', SHOW_WRITE),
    ...buildReadModuleSubModulePermissions('user', USER_READ),
    ...buildModulePermissions('comment'),
  }),
];

/** @protected not to use outside @bits-app/voggtpit-shared itself */
export const FINANCE: Permission[] = [
  ...Object.values({
    ...buildModuleSubModulePermissions('database_explorer', DATABASE_EXPLORER),
    ...buildModulePermissions('payout'),
    ...buildModulePermissions('payout_scheme'),
    ...buildReadModuleSubModulePermissions('user', USER_READ),
    ...buildModulePermissions('comment'),
  }),
];
