import { SellerCanceledRequestError } from '@bits-app/voggtpit-shared';
import { t } from 'i18next';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { OrderItem } from '@/entities/order-item.entity';
import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import {
  selectSellerCancelRequests,
  selectSellerCancelRequestsInRefund,
} from '../redux/seller-cancel-requests.selectors';
import { sellerCancelRequestsActions } from '../redux/seller-cancel-requests.slice';

export const refundSellerCancelRequests =
  (): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { sellerCancelRequestsGateway, snackbarGateway }) => {
    let currentOrderProcessing: OrderItem[] = [];
    let orderItemsNotProcessed: OrderItem[] = [];

    dispatch(sellerCancelRequestsActions.setRefundFormLoading(true));
    try {
      const sellerCancelRequestsIdsInRefund = selectSellerCancelRequestsInRefund(getState());
      const sellerCancelRequests = selectSellerCancelRequests(getState());

      const sellerCancelRequestsInRefund = sellerCancelRequests.filter(({ id }) =>
        sellerCancelRequestsIdsInRefund.includes(id),
      );

      dispatch(
        sellerCancelRequestsActions.setRefundFormProcessing({
          current: 0,
          count: sellerCancelRequestsInRefund.length,
        }),
      );

      const groupByOrder = sellerCancelRequestsInRefund.reduce(
        (result, item) => ({
          ...result,
          [[item.showId, item.buyer].join('_')]: [
            ...(result[[item.showId, item.buyer].join('_')] || []),
            item,
          ],
        }),
        {} as Record<string, OrderItem[]>,
      );

      // Split seller cancel requests per order to prevent long running request
      // Currently, it will still failed if there is more than 13 order items for one order
      const sortByLengthOrder = Object.values(groupByOrder).sort((a, b) => a.length - b.length);
      const orderItemIdsToRefund = sortByLengthOrder.flat().map(({ id }) => id);

      let failedOrderItems: SellerCanceledRequestError['errors'] = {};

      for (const [index, order] of Object.entries(sortByLengthOrder)) {
        currentOrderProcessing = order;
        orderItemsNotProcessed = sortByLengthOrder
          .slice(Number(index) + 1)
          .flat()
          .sort((a, b) => a.id - b.id);

        try {
          await sellerCancelRequestsGateway.refundSellerCancelRequests(order.map(({ id }) => id));
        } catch (error) {
          if (error instanceof SellerCanceledRequestError) {
            failedOrderItems = Object.assign(error.errors, failedOrderItems);

            continue;
          } else {
            throw error;
          }
        }

        const notRefundedSellerCancelRequest = sellerCancelRequests.filter(
          ({ id }) =>
            !orderItemIdsToRefund
              .filter((id) => !orderItemsNotProcessed.map(({ id }) => id).includes(id))
              .includes(id),
        );
        dispatch(sellerCancelRequestsActions.setEntities(notRefundedSellerCancelRequest));

        dispatch(
          sellerCancelRequestsActions.setRefundFormProcessing({
            current: sellerCancelRequestsInRefund.length - orderItemsNotProcessed.length,
            count: sellerCancelRequestsInRefund.length,
          }),
        );
      }

      if (Object.keys(failedOrderItems).length > 0) {
        dispatch(
          sellerCancelRequestsActions.setRefundFormNotAllProcessedError({
            message: t('sellerCancelRequests.error.someFailed'),
            notProcessed: failedOrderItems,
            processing: [],
          }),
        );

        return;
      }

      dispatch(sellerCancelRequestsActions.resetRefundForm());
      dispatch(sellerCancelRequestsActions.resetRefundSummary());

      snackbarGateway.success('refund.validated');
    } catch (error) {
      logger.error(error);
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      dispatch(
        sellerCancelRequestsActions.setRefundFormError([
          t('sellerCancelRequests.error.tooLongRequest'),
          ...currentOrderProcessing.map(({ id }) => String(id)),
        ]),
      );

      snackbarGateway.error('refund.error');
    } finally {
      dispatch(sellerCancelRequestsActions.setRefundFormLoading(false));
      dispatch(sellerCancelRequestsActions.setRefundFormProcessing(null));

      orderItemsNotProcessed = [];
      currentOrderProcessing = [];
    }
  };
