import { useMemo } from 'react';

import { PaymentMethodCard } from '@bits-app/bits-server-data';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';
import { useIdParam } from '@/hooks/use-id-params';
import { ItemLayout } from '@/voggt-database-explorer/layouts/ItemLayout';
import {
  useCellDefinition,
  OverrideTypesenseOrder,
} from '@/voggt-database-explorer/order/use-cell-definition';

import { availablePaymentMethodType } from '../../payment-method.type';

import { Detail } from './PaymentMethodDetails';

export const PaymentMethodDetail = <T,>({
  paymentMethodName,
}: {
  paymentMethodName: availablePaymentMethodType;
}) => {
  const { t } = useTranslation();

  const paymentMethodDataId = useIdParam();
  const location = useLocation();
  const navigate = useNavigate();

  const currentTab = useMemo(() => {
    return (
      ['order'].find((path) =>
        location.pathname.includes(path.toLowerCase().replace(`paymentmethod`, '')),
      ) ?? '/'
    );
  }, [location.pathname]);

  const handleOnTabChange = (tab: string) =>
    navigate(
      `/payment-method/${paymentMethodName
        .replace(`paymentMethod`, '')
        .toLowerCase()}/${paymentMethodDataId}${tab === '/' ? '' : '/' + tab}`,
    );

  const { CellOrderDefinition } = useCellDefinition();

  const filterByPaymentMethod = `${
    paymentMethodName === 'paymentMethodCard' ? 'paymentMethod' : paymentMethodName
  }Id:${paymentMethodDataId}`;

  const tabs = [
    {
      path: '/',
      label: t('databaseExplorer.paymentmethod.tabs.main'),
      Element: <Detail<T> key="/" paymentMethodName={paymentMethodName} />,
    },
    {
      path: 'order',
      label: t('databaseExplorer.paymentmethod.tabs.order'),
      Element: (
        <ListWithSearch<OverrideTypesenseOrder>
          title="Orders"
          key="order"
          cellDefinition={CellOrderDefinition}
          typesenseHookParams={{
            typesenseIndex: 'order',
            typesenseParams: {
              query_by: ['productId', 'product', 'customer', 'seller'],
              filter_by: filterByPaymentMethod,
            },
          }}
          flatElevation
        />
      ),
    },
  ];

  return (
    <ItemLayout<PaymentMethodCard>
      onTabChange={handleOnTabChange}
      id={paymentMethodDataId}
      currentTab={currentTab}
      tabs={tabs}
    />
  );
};
