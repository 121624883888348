import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

@Index(['name', 'line1', 'postalCode', 'city'], { unique: true })
@Entity()
export class BlockedShippingAddress {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column({ nullable: true, type: 'character varying' })
  name!: string | null;

  @Column()
  line1!: string;

  @Column({ nullable: true, type: 'character varying' })
  line2!: string | null;

  @Column()
  postalCode!: string;

  @Column()
  city!: string;

  @Column({ default: '' })
  @Index('hash1_gin_idx', { synchronize: false })
  hash1!: string;

  @Column({ default: '' })
  @Index('hash2_gin_idx', { synchronize: false })
  hash2!: string;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;
}
