import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { authActions } from '../auth.slice';

export const logout =
  (): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { authGateway }) => {
    try {
      await authGateway.logout();

      dispatch(authActions.setOwnUser(null));
    } catch (error) {
      logger.error(error);
    }
  };
