import { useState } from 'react';

import { RefundDebitedAccount } from '@bits-app/bits-server-data';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@/components/elements/buttons/LoadingButton';

type CancelOrderProps = {
  onClose: () => void;
  onConfirm: (t: RefundDebitedAccount) => void;
  isLoading: boolean;
};

export const CancelOrderShippingFee = ({ onClose, onConfirm, isLoading }: CancelOrderProps) => {
  const [selectedDebitedPlatform, setSelectedDebitedPlatform] = useState(
    RefundDebitedAccount.seller,
  );

  const { t } = useTranslation();

  return (
    <Dialog open>
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        {t('databaseExplorer.order.cancel.cancelOrder')}
        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <p>
          A shipping fee refund triggered this way will be a seller refund. Are you sure you want to
          refund it?
        </p>

        <div className="flex justify-between py-4">
          <p className="font-bold">Choose debited account : </p>

          <Select
            size="small"
            value={selectedDebitedPlatform}
            onChange={(v) => setSelectedDebitedPlatform(v.target.value as RefundDebitedAccount)}
          >
            {Object.values(RefundDebitedAccount).map((operator) => (
              <MenuItem key={operator} value={operator}>
                {operator}
              </MenuItem>
            ))}
          </Select>
        </div>
      </DialogContent>

      <DialogActions className="px-4 py-2">
        <LoadingButton
          variant="contained"
          color="error"
          loading={isLoading}
          disabled={isLoading}
          onClick={() => onConfirm(selectedDebitedPlatform)}
        >
          Refund shipping fee
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
