import { useTranslation } from 'react-i18next';

import { AddFilterFunctionParams } from '../../../../hooks/use-table-options';

import { OrderItemsMassUpdateFreeFilter } from './OrderItemsMassUpdateFreeFilter';

type ShowIdProps = {
  activeFilters?: string[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
};

export const ShowIdFilter = ({ activeFilters, addFilter, isUnsaved }: ShowIdProps) => {
  const { t } = useTranslation();

  return (
    <OrderItemsMassUpdateFreeFilter
      addFilter={addFilter}
      field="showId"
      isValueValid={isShowIdValid}
      inputPlaceholder={t('orderItemsMassUpdate.searchFilter.showId')}
      activeFilters={activeFilters}
      isUnsaved={isUnsaved}
    />
  );
};

const isShowIdValid = (value: string) => {
  return Boolean(value.match(/\d+/));
};
