import { add, formatAmount, RefundDebitedAccount, RefundReason } from '@bits-app/voggtpit-shared';

import { OrderItem } from '@/entities/order-item.entity';
import { AppThunkAction } from '@/redux/store';

import { selectRefundableOrderItems } from '../../redux/refund.selectors';
import { refundActions } from '../../redux/refund.slice';

export const getDefaultRefundForFraud = (): AppThunkAction<void> => (dispatch, getState) => {
  dispatch(refundActions.setDebitedAccount(RefundDebitedAccount.seller));
  dispatch(refundActions.setRefundReason(RefundReason.fraud));

  const refundableOrderItems = selectRefundableOrderItems(getState());

  for (const orderItem of refundableOrderItems) {
    dispatch(
      refundActions.setRefundComissionToSeller({
        orderItemId: orderItem.id,
        refundComissionToSeller: true,
      }),
    );
    dispatch(getDefaultAmountForFraudForOrderItem(orderItem));
  }
};

export const getDefaultAmountForFraudForOrderItem =
  (orderItem: OrderItem): AppThunkAction<void> =>
  (dispatch) => {
    let amount = add(orderItem.amount, orderItem.shippingAmount);

    if (orderItem.refundedAmount !== undefined) {
      amount -= orderItem.refundedAmount;
    }

    dispatch(
      refundActions.setRefundAmountFor({
        orderItemId: orderItem.id,
        amount: formatAmount(amount),
      }),
    );
  };
