import { Channel } from '@bits-app/bits-server-data';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';

import { useCellDefinition } from '../../use-cell-definition';

const TYPESENSE_MAX_RESULT_PER_PAGE = 30;

export const ListChannel = () => {
  const { CellChannelDefinition } = useCellDefinition();

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListWithSearch<Channel & { id: string }>
        title="Channels"
        cellDefinition={CellChannelDefinition}
        typesenseHookParams={{
          typesenseIndex: 'channel',
          typesenseParams: {
            per_page: TYPESENSE_MAX_RESULT_PER_PAGE,
            query_by: ['user'],
          },
        }}
      />
    </div>
  );
};
