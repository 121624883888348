import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { OwnUser } from '@/domain/entities/own-user.entity';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

import { useDeleteOwnUser } from './use-delete-own-user.hook';

export interface DeleteOwnUserModalProps {
  ownUser: OwnUser;
  onClose(): void;
}

export const DeleteOwnUserModal = ({ ownUser, onClose }: DeleteOwnUserModalProps) => {
  const { t } = useTranslation();

  const { mutate: onDelete, isLoading } = useDeleteOwnUser(ownUser, onClose);

  if (!ownUser) {
    onClose();
    return null;
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        {t('administration.deleteOwnUser.title', { username: ownUser.fullName })}
      </DialogTitle>

      <DialogContent>
        <Typography>
          {t('administration.deleteOwnUser.content', { username: ownUser.fullName })}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ alignSelf: 'flex-end' }}>
        <Button onClick={onClose}>{t('cancel')}</Button>

        <LoadingButton
          onClick={() => onDelete(ownUser.id)}
          variant="contained"
          color="error"
          loading={isLoading}
        >
          {t('action.delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
