import { useEffect } from 'react';

import { User } from '@bits-app/voggtpit-shared';

import { MuxAssetsTab, useMuxContext } from '@/mux';

export const UserMuxAssetsTab = ({ userId }: { userId: User['id'] }) => {
  const { loadAssets } = useMuxContext();
  useEffect(() => {
    loadAssets({ entityId: userId, type: 'seller' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <MuxAssetsTab />;
};
