import { currencySymbol, centsToUnit, unitToCents } from '@bits-app/voggtpit-shared';
import {
  Box,
  Dialog,
  Divider,
  FormControl,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';
import {
  ReducerState,
  UpdateState,
} from '@/promotions/hooks/use-edit-promotion/use-edit-promotion.type';
import { formatDate } from '@/utils';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

export interface EditPromotionModalProps {
  handleChange: () => void;
  discard: () => void;
  state: ReducerState & { updateState: (payload: UpdateState['payload']) => void };
}

export const EditPromotionModal = ({ handleChange, state, discard }: EditPromotionModalProps) => {
  const {
    currency,
    expireAt,
    isLoading,
    name,
    promotionAmount,
    promotionMaxAmount,
    promotionPercentage,
    modalIsOpen,
    updateState,
  } = state;

  const promotionType = promotionAmount !== null ? 'fix' : 'percentage';
  const { t } = useTranslation();

  return (
    <Dialog open={modalIsOpen} onClose={discard}>
      <>
        <FormControl margin="dense" component="fieldset" variant="standard" fullWidth>
          <FormGroup sx={{ px: 2, my: 2 }}>
            <TextField
              name="name"
              value={name}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                updateState({ key: 'name', value: ev.target.value })
              }
              variant="outlined"
              placeholder={t('promotion.campaignName')}
              fullWidth
              label="Campaign name"
            />

            <Divider sx={{ my: 2 }} />

            <Stack spacing={3}>
              {promotionType === 'fix' ? (
                <TextField
                  name="promotionAmount"
                  value={centsToUnit(promotionAmount || 0)}
                  type="number"
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                    updateState({ key: 'promotionAmount', value: unitToCents(+ev.target.value) })
                  }
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{currencySymbol(currency)}</InputAdornment>
                    ),
                  }}
                  label={t('promotion.amountPerPromotion')}
                />
              ) : (
                <>
                  <TextField
                    name="promotionPercentage"
                    value={promotionPercentage}
                    type="number"
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      updateState({ key: 'promotionPercentage', value: +ev.target.value })
                    }
                    variant="outlined"
                    sx={{ width: '100%' }}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    label={t('promotion.promotionPercentage')}
                  />

                  <TextField
                    name="promotionMaxAmount"
                    value={centsToUnit(promotionMaxAmount || 0)}
                    type="number"
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      updateState({
                        key: 'promotionMaxAmount',
                        value: unitToCents(+ev.target.value),
                      })
                    }
                    variant="outlined"
                    sx={{ width: '100%' }}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{currencySymbol(currency)}</InputAdornment>
                      ),
                    }}
                    label={t('promotion.promotionMaxAmount')}
                  />
                </>
              )}
            </Stack>
            <Divider sx={{ my: 2 }} />

            <TextField
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                updateState({ key: 'expireAt', value: ev.target.value })
              }
              name="expireAt"
              type="datetime-local"
              value={formatDate(expireAt, 'yyyy-MM-dd HH:mm')}
              variant="outlined"
              fullWidth
              label={t('promotion.expirationDate')}
            />
          </FormGroup>
        </FormControl>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            px: 2,
            my: 2,
            justifyContent: 'space-between',
          }}
        >
          <Box my={1}>
            <ButtonOutlined onClick={discard}>Close</ButtonOutlined>
          </Box>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleChange}
          >
            {t('action.save')}
          </LoadingButton>
        </Box>
      </>
    </Dialog>
  );
};
