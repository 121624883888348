import { cloneElement, useState } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { WarningUnsavedDialog } from './WarningUnsavedDialog';

type FilterButtonProps = {
  isActive: boolean;
  children: React.ReactNode;
  onClose: () => void;
  unSaved: boolean;
  maxWidth?: React.CSSProperties['maxWidth'];
  CustomButton?: JSX.Element;
  disabled?: boolean;
};

export const FilterButton = ({
  isActive,
  onClose,
  children,
  unSaved,
  maxWidth = 250,
  disabled = false,
  CustomButton,
}: FilterButtonProps) => {
  const { t } = useTranslation();

  const [openWarningLostUnApplyFilters, setOpenWarningLostUnApplyFilters] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    if (unSaved) {
      setOpenWarningLostUnApplyFilters(true);
      return;
    }

    handleConfirmClose();
  };

  const handleConfirmClose = () => {
    setAnchorEl(null);
    onClose();
    setOpenWarningLostUnApplyFilters(false);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {CustomButton ? (
        cloneElement(CustomButton, {
          onClick,
          disabled,
        })
      ) : (
        <IconButton
          onClick={onClick}
          disabled={disabled}
          size="small"
          sx={{
            backgroundColor: ({ palette }) => (isActive ? palette.primary.main : undefined),
            color: ({ palette }) => (isActive ? palette.primary.contrastText : undefined),
          }}
        >
          <FilterListIcon fontSize="small" />
        </IconButton>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: { overflow: 'visible', maxWidth },
        }}
      >
        {children}
      </Popover>

      {openWarningLostUnApplyFilters && (
        <WarningUnsavedDialog
          title={t('unApplyFilters.title')}
          content={t('unApplyFilters.content')}
          onClose={() => setOpenWarningLostUnApplyFilters(false)}
          onConfirm={handleConfirmClose}
        />
      )}
    </>
  );
};
