import { useState } from 'react';

import { CommercialEvent } from '@bits-app/bits-server-data';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '../../../components/elements/buttons/ButtonContained';
import { AddToCommercialEventMutateFunctionParams } from '../hooks/use-add-to-commercial-event';
import { useListCommercialEvent } from '../hooks/use-list-commercial-event';

type AddToCommercialEventModalProps = {
  onConfirm: (params: AddToCommercialEventMutateFunctionParams) => void;
  onCancel: () => void;
  enableOptionToLinkAllProductFromShow?: boolean;
};

export const AddToCommercialEventModal = ({
  onCancel,
  onConfirm,
  enableOptionToLinkAllProductFromShow = false,
}: AddToCommercialEventModalProps) => {
  const { t } = useTranslation();
  const [selectedCommercialEventId, setSelectedCommercialEventId] = useState<
    CommercialEvent['id'] | ''
  >('');

  const [automaticallyAddProductFromShow, setAutomaticallyAddProductFromShow] = useState(false);
  const { data, isLoading } = useListCommercialEvent();

  const withoutExpiredCommercialEvent = data?.filter(
    (commercialEvent) =>
      new Date(commercialEvent.endAt).valueOf() > new Date().valueOf() &&
      commercialEvent.deletedAt === null,
  );

  return (
    <Dialog open>
      <DialogTitle id="alert-dialog-title">
        {t('databaseExplorer.commercialEvent.modal.title')}
      </DialogTitle>

      <DialogContent>
        <Stack direction={'column'} spacing={2}>
          <DialogContentText>
            {t('databaseExplorer.commercialEvent.modal.description')}
          </DialogContentText>

          {isLoading && Array.isArray(data) ? (
            <Box>Is Loading...</Box>
          ) : (
            <Select
              value={selectedCommercialEventId}
              onChange={(e: SelectChangeEvent<CommercialEvent['id']>) =>
                setSelectedCommercialEventId(e.target.value as CommercialEvent['id'])
              }
            >
              {withoutExpiredCommercialEvent?.map((commercialEvent) => (
                <MenuItem key={commercialEvent.id} value={commercialEvent.id}>
                  {commercialEvent.internalName ? (
                    <>
                      <strong>{commercialEvent.internalName} </strong> - ({commercialEvent.name})
                    </>
                  ) : (
                    commercialEvent.name
                  )}
                </MenuItem>
              ))}
            </Select>
          )}
          {enableOptionToLinkAllProductFromShow && (
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent="space-between"
            >
              <DialogContentText>
                {t('databaseExplorer.commercialEvent.modal.addAllProducts')}
              </DialogContentText>
              <Switch
                checked={automaticallyAddProductFromShow}
                onChange={(e) => {
                  setAutomaticallyAddProductFromShow(Boolean(e.target.checked));
                }}
              />
            </Box>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={isLoading}>
          {t('action.cancel')}
        </Button>
        <ButtonContained
          onClick={() =>
            selectedCommercialEventId &&
            onConfirm({
              commercialEventId: selectedCommercialEventId,
              automaticallyAddProductFromShow,
            })
          }
          disabled={isLoading || selectedCommercialEventId === ''}
        >
          {t('action.continue')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
