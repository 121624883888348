import { useEffect } from 'react';

import { KeysWithTranslations } from '@bits-app/voggtpit-shared';
import { Alert, alpha, Box, Grid, Skeleton } from '@mui/material';
import _pick from 'lodash/pick';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@/components/elements/buttons/LoadingButton';
import { TranslateKeyForm } from '@/voggt-database-explorer/components/translations/TranslateKeyForm';

type TranslationsFormProps = {
  update: (fields: Record<string, string>) => void;
  keysWithTranslations: KeysWithTranslations;
  isLoading: boolean;
};

export const TranslationsForm = ({
  keysWithTranslations,
  isLoading,
  update,
}: TranslationsFormProps) => {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {},
  });

  const onSubmit = (data: Record<string, string>) => {
    const updatedFields = _pick(data, Object.keys(formMethods.formState.dirtyFields));

    if (Object.keys(updatedFields).length <= 0) {
      return;
    }

    update(updatedFields);
  };

  useEffect(() => {
    if (!keysWithTranslations) {
      return;
    }

    const translations = Object.values(keysWithTranslations ?? []).flat();

    formMethods.reset(
      translations.reduce(
        (defaultTranslations, translation) => ({
          ...defaultTranslations,
          [translation.id]: translation.translation,
        }),
        {} as Record<string, string>,
      ),
    );
  }, [formMethods, keysWithTranslations]);

  if (isLoading) {
    return (
      <Grid container flexDirection="column" gap={2}>
        <Skeleton variant="rectangular" width={552} height={70} />
        <Skeleton />
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <Skeleton key={index} variant="rectangular" width={552} height={40} />
          ))}

        <Skeleton />
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <Skeleton key={index} variant="rectangular" width={552} height={40} />
          ))}
        <Skeleton variant="rectangular" width={552} height={50} />
      </Grid>
    );
  }

  return (
    <Grid container flexDirection="column" gap={2}>
      <Alert
        severity="info"
        sx={{
          backgroundColor: ({ palette }) => alpha(palette.info.light, 0.3),
        }}
      >
        {t('databaseExplorer.translate.info')}
      </Alert>

      <FormProvider {...formMethods}>
        {keysWithTranslations &&
          Object.entries(keysWithTranslations).map(([key, translations]) => (
            <TranslateKeyForm key={key} translationKey={key} translations={translations} />
          ))}
      </FormProvider>

      <Alert
        severity="info"
        sx={{
          backgroundColor: ({ palette }) => alpha(palette.info.light, 0.3),
        }}
      >
        {t('databaseExplorer.translate.savingInfo')}
      </Alert>

      <Box alignSelf="flex-end">
        <LoadingButton
          variant="contained"
          loading={formMethods.formState.isSubmitting}
          disabled={!formMethods.formState.isDirty || formMethods.formState.isSubmitting}
          onClick={formMethods.handleSubmit(onSubmit)}
        >
          {t('databaseExplorer.translate.save')}
        </LoadingButton>
      </Box>
    </Grid>
  );
};
