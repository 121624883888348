import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { SearchValueFilter } from '@Elements/filters/table';

export interface PromotionNameFilterProps {
  activeFilters?: string;
  addFilter: (filter: AddFilterFunctionParams) => void;
}

export const PromotionNameFilter = ({ activeFilters, addFilter }: PromotionNameFilterProps) => {
  return (
    <SearchValueFilter
      addFilter={addFilter}
      activeFilters={activeFilters}
      inputPlaceholder="Promotion name"
      field="name"
    />
  );
};
