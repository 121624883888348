import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { PreAuthorizedPaymentMethod } from './PreAuthorizedPaymentMethod';
import { User } from './User';

@Entity({ name: 'payment_method' })
export class PaymentMethodCard extends PreAuthorizedPaymentMethod {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column({ unique: true })
  stripePaymentMethodId!: string;

  @Column()
  brand!: string;

  @Column({ nullable: true })
  country!: string;

  @Column()
  expMonth!: number;

  @Column()
  expYear!: number;

  @Index()
  @Column({ nullable: true })
  fingerprint!: string;

  @Column()
  funding!: string;

  @Column()
  last4!: string;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Index()
  @Column()
  userId!: number;

  @Column({ default: false })
  isApplePay!: boolean;

  @Column({ default: false })
  isGooglePay!: boolean;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;
}
