import { Show, Channel, User, SellerShippingAddress } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { Information } from '@/components/layouts/PanelDetails';
import { QUERIES_KEYS, queryClient } from '@/queries';

const fetchChannel = async (channelId: Channel['id']) => {
  const { data } = await authenticatedInstance.get<Channel>(
    `/database-explorer/channel/${channelId}`,
  );
  return data;
};

export const useChannelDetailsView = (channelId: Channel['id']) => {
  const { isLoading, data: channel } = useQuery(QUERIES_KEYS.getChannel(channelId), () =>
    fetchChannel(channelId),
  );

  const refetchChannel = () => {
    queryClient.invalidateQueries(QUERIES_KEYS.getChannel(channelId));
  };

  return {
    channel,
    loading: isLoading,
    refetchChannel,
  };
};

export const panelDetailsChannel: Information<Channel>[] = [
  { value: 'id', label: 'id' },
  { value: 'provider', label: 'provider' },
  { value: 'providerId', label: 'Provider id' },
  { value: 'rtmp', label: 'RTMP' },
  {
    value: 'restreamUrls',
    label: 'Restream urls',
    format: (channel: Channel | null) =>
      `[${channel?.restreamUrls?.map((url) => JSON.stringify(url)).join(', ')}]`,
  },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
];

export const panelsDetailsSellerShippingAddress: Information<SellerShippingAddress>[] = [
  { value: 'id', label: 'id' },
  { value: 'city', label: 'City' },
  { value: 'countryIso2Code', label: 'Country iso2 code' },
  { value: 'email', label: 'Email' },
  { value: 'firstName', label: 'Firstname' },
  { value: 'houseNumber', label: 'House number' },
  { value: 'lastName', label: 'Lastname' },
  { value: 'phoneNumber', label: 'Phone number' },
  { value: 'street', label: 'Street' },
  { value: 'street2', label: 'Street2' },
  { value: 'zipCode', label: 'Zip code' },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
];

export const panelDetailsUser: Information<User>[] = [
  { value: 'id', label: 'id', type: 'internal-link', link: (user) => `/user/${user.id}` },
  { value: 'username', label: 'Username' },
  { value: 'firstName', label: 'Firstname' },
  { value: 'lastName', label: 'Lastname' },
];

export const CellShowDefinition: CellDefinition<Show>[] = [
  {
    id: 'id',
    label: 'id',
    type: 'internal-link',
    getLink: (show) => ({ label: show.id, link: `/show/${show.id}` }),
  },
  {
    id: 'userId',
    label: 'User',
    type: 'internal-link',
    getLink: (show) => ({
      label: show.user as unknown as string,
      link: `/user/${show.userId}`,
    }),
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'startAt',
    label: 'Start at',
    type: 'date-with-time',
  },
  {
    id: 'endedAt',
    label: 'End at',
    type: 'date-with-time',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date-with-time',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
  },
];
