import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { User } from './User';

@Entity()
export class ImpersonationToken {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @ManyToOne(() => User, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Index()
  @Column()
  userId!: number;

  @Column({ default: false })
  isUsed!: boolean;

  @Column({ type: 'timestamp', nullable: true })
  usedAt!: Date | null;

  @Column()
  voggtpitUserId!: string;

  @Column()
  voggtpitUserFullName!: string;

  @Column()
  voggtpitUserEmail!: string;

  @CreateDateColumn()
  createdAt!: Date;

  @UpdateDateColumn()
  updatedAt!: Date;
}
