import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../../axios/axios.instance';

export const useLedgerTriggerPayout = () => {
  return useMutation(triggerPayout, {});
};

async function triggerPayout(sellerIds: number[]) {
  return authenticatedInstance.post('/ledger/trigger-payout', { sellerIds });
}
