import { validationSchemasBuilder } from '@/voggt-database-explorer';
import { useEditionView } from '@/voggt-database-explorer/components/edition-view/use-edition-view';
import { UpsertForm } from '@/voggt-database-explorer/components/upsert-view/UpsertForm';
import { EntityWrapper, ResourceType } from '@/voggt-database-explorer/redux/database-explorer';

export type EditionViewProps = {
  resourceId: EntityWrapper['id'];
  resourceType: ResourceType;
  goBackName?: string;
  onSuccess?: () => void;
};

export const EditionView = <T extends EntityWrapper>({
  resourceId,
  resourceType,
  goBackName,
  onSuccess,
}: EditionViewProps) => {
  const { header, schemas, onUpdate } = useEditionView<T>(resourceId, resourceType, onSuccess);
  const validationSchemaBuilder = validationSchemasBuilder[resourceType];

  if (!schemas || !header) {
    return null;
  }

  return (
    <UpsertForm<T>
      schemas={schemas}
      header={header}
      validationSchemaBuilder={validationSchemaBuilder}
      onUpsert={onUpdate}
      goBackName={goBackName}
    />
  );
};
