import { useMemo } from 'react';

import { Product, ProductStatusInAuction, ProductType, User } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { useTypesenseSearch } from '@/hooks/use-typesense';
import { useSearchVoggtPayloadType } from '@/hooks/use-typesense/use-typesense';

const PRODUCT_TYPE_OPTIONS = Object.values(ProductType) as ProductType[];
const PRODUCT_STATUS_OPTIONS = Object.values(ProductStatusInAuction) as ProductStatusInAuction[];

export const useCellProductDefinition = () => {
  const params: useSearchVoggtPayloadType<User> = useMemo(
    () => ({
      typesenseIndex: 'user',
      typesenseParams: {
        per_page: 20,
        query_by: ['username'],
        include_fields: 'username',
      },
    }),
    [],
  );

  const {
    results: sellerOptions,
    setSearch: setSellerSearch,
    loading: sellerLoading,
  } = useTypesenseSearch<User>(params);

  return useMemo(
    (): CellDefinition<Product & { seller: string; show: string }>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (product) => ({ link: `/product/${product?.id}`, label: product?.id }),
        enableColumnFilter: false,
      },
      {
        id: 'seller',
        label: 'Seller',
        type: 'internal-link',
        getLink: (product) => ({ link: `/user/${product?.sellerId}`, label: product?.seller }),
        columnFilter: {
          type: 'autocomplete',
          options: sellerOptions
            .map((seller) => ({ value: seller.username }))
            .filter((option) => Boolean(option?.value)),
          onAutocomplete: setSellerSearch,
          loading: sellerLoading,
        },
      },
      {
        id: 'name',
        label: 'Name',
        enableColumnFilter: false,
      },
      {
        id: 'type',
        label: 'Type',
        type: 'badge',
        columnFilter: {
          type: 'multi-select',
          options: PRODUCT_TYPE_OPTIONS.map((type) => ({ value: type })),
        },
      },
      {
        id: 'status',
        label: 'Status',
        type: 'badge',
        columnFilter: {
          type: 'multi-select',
          options: PRODUCT_STATUS_OPTIONS.map((type) => ({ value: type })),
        },
      },
      {
        id: 'show',
        label: 'Show',
        type: 'internal-link',
        getLink: (product) => ({ link: `/show/${product?.showId}`, label: product?.show }),
        enableColumnFilter: false,
      },
      {
        id: 'createdAt',
        label: 'CreatedAt',
        type: 'date-with-time',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    [sellerLoading, sellerOptions, setSellerSearch],
  );
};
