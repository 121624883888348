import { useCallback, useState } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import chunk from 'lodash/chunk';

import { useOwnUser } from '@/context/own-user.context';

import {
  useStartProcess,
  handleFileLoaded,
  LedgerManualAdjustementRowBulk,
} from './use-bulk-update-ledger.helper';

export const useBulkUpdateLedger = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [errors, setError] = useState<{ row: number; error: string }[]>([]);
  const [rows, setRows] = useState<LedgerManualAdjustementRowBulk[]>([]);
  const [pointer, setPointer] = useState(0);
  const { hasPermissions } = useOwnUser();
  const userIsAllowed = hasPermissions(
    PERMISSIONS.DIRECT_FUND_TRANSFER.WRITE_DIRECT_FUND_TRANSFER_UNIT,
  );

  const { mutateAsync, isLoading } = useStartProcess();

  const toggleModal = useCallback(() => {
    setModalIsOpen((prev) => !prev);
    setError([]);
    setRows([]);
  }, []);

  const onDropFile = useCallback((files: File[]) => {
    const [file] = files;
    const reader = new FileReader();
    reader.addEventListener('load', (file) => {
      setError([]);
      setRows([]);
      const { data, errors } = handleFileLoaded(file);
      if (errors.length) {
        setError(errors.map((error) => error));
      } else {
        setRows(data);
      }
    });
    reader.readAsText(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const _chunk = chunk(rows, 20);
    for (const chunk of _chunk) {
      await mutateAsync(chunk);
      setPointer(pointer + 1);
    }
    setError([]);
    setRows([]);
  };

  return {
    toggleModal,
    modalIsOpen,
    errors,
    userIsAllowed,
    onDropFile,
    handleSubmit,
    submitButtonIsDisabled: isLoading || errors.length > 0 || rows.length === 0,
    pointer,
    rows,
    isLoading,
  };
};
