import { Currency, currencySymbol, formatAmount } from '@bits-app/voggtpit-shared';
import { Grid, Skeleton, SkeletonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/redux/reduxAppHooks';

import { selectSellerCancelRequestsRefundSummary } from '../../redux/seller-cancel-requests.selectors';

type SellerCancelRequestsRefundSummaryProps = {
  currency: Currency;
  loading: boolean;
};

export const SellerCancelRequestsRefundSummary = ({
  currency,
  loading,
}: SellerCancelRequestsRefundSummaryProps) => {
  const refundSummary = useAppSelector(selectSellerCancelRequestsRefundSummary);

  const { t } = useTranslation();

  return (
    <Grid container gap={2}>
      <Grid item container sx={{ fontWeight: 'bold' }}>
        <ShowLoading loading={loading}>
          <Grid item xs={5}>
            {t('orderItemLabels.refundReason')}
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            {t('sellerCancelRequests.confirmation.count')}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            {t('orderItemLabels.amount')}
          </Grid>
        </ShowLoading>
      </Grid>

      <ShowLoading loading={loading} variant="rectangular" height={100}>
        <>
          {refundSummary.misclick && (
            <Grid item container>
              <Grid item xs={5}>
                {t('orderItemLabels.refundReasons.misclick')}
              </Grid>
              <Grid item xs={3} sx={{ textAlign: 'center' }}>
                {refundSummary.misclick.count}
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                {formatAmount(refundSummary.misclick.totalAmount)} {currencySymbol(currency)}
              </Grid>
            </Grid>
          )}

          {refundSummary.buyer_refusal && (
            <Grid item container>
              <Grid item xs={5}>
                {t('orderItemLabels.refundReasons.changeOfMind')}
              </Grid>
              <Grid item xs={3} sx={{ textAlign: 'center' }}>
                {refundSummary.buyer_refusal.count}
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                {formatAmount(refundSummary.buyer_refusal.totalAmount)} {currencySymbol(currency)}
              </Grid>
            </Grid>
          )}

          {refundSummary.failed_payment && (
            <Grid item container>
              <Grid item xs={5}>
                {t('orderItemLabels.refundReasons.others')}
              </Grid>
              <Grid item xs={3} sx={{ textAlign: 'center' }}>
                {refundSummary.failed_payment.count}
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                {formatAmount(refundSummary.failed_payment.totalAmount)} {currencySymbol(currency)}
              </Grid>
            </Grid>
          )}

          <Grid item container>
            <Grid item xs={5} sx={{ fontWeight: 'bold' }}>
              {t('sellerCancelRequests.confirmation.total')}
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
              {refundSummary.totalOrderItems}
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right', fontWeight: 'bold' }}>
              {formatAmount(refundSummary.totalAmount)} {currencySymbol(currency)}
            </Grid>
          </Grid>
        </>
      </ShowLoading>
    </Grid>
  );
};

type ShowLoadingProps = SkeletonProps & {
  loading: boolean;
  children: React.ReactNode;
};

const ShowLoading = ({ loading, children, ...props }: ShowLoadingProps) => (
  <>{loading ? <Skeleton width="100%" {...props} /> : children}</>
);
