import { Box } from '@mui/system';

import { AlertBar } from '@/components/elements/AlertBar';

export const SoftDeletedBanner = () => {
  return (
    <Box width={'100%'} justifyContent={'center'} mb={1}>
      <AlertBar title="banner.entity.entityIsSoftDeleted" text={undefined} severity="error" />
    </Box>
  );
};
