import { PERMISSIONS } from '@bits-app/voggtpit-shared';

import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { authActions, selectOwnUser } from '../auth.slice';

import { getMe } from './get-me';

export const zendeskAuth =
  (code: string): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { authGateway }) => {
    const ownUser = selectOwnUser(getState());

    if (ownUser?.isAuthenticatedToZendesk) {
      return;
    }

    if (
      !ownUser?.permissions.includes(PERMISSIONS.LINK_ZENDESK_ACCOUNT.WRITE_LINK_ZENDESK_ACCOUNT)
    ) {
      return;
    }

    dispatch(authActions.setZendeskLoading(true));

    try {
      await authGateway.zendeskAuth(code);

      dispatch(getMe());
    } catch (error) {
      logger.error(error);
    } finally {
      dispatch(authActions.setZendeskLoading(false));
    }
  };
