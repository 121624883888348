import * as yup from 'yup';

import { SchemaValidation } from './validation-schema';

export class UserValidationSchema extends SchemaValidation {
  availableResolver = {
    username: this.resolveUsername(),
  };

  resolveUsername(): yup.ISchema<any> {
    return yup
      .string()
      .test(
        'validate-user-name',
        'Only alphanumeric characters and ".", "_", "-" are authorized',
        (value) => {
          const deletedAccountModel =
            /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]-deleted-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
          if (value && deletedAccountModel.test(value)) {
            return true;
          }

          // <https://www.notion.so/voggt/Change-username-04f7a8b8a7cb42c18d1da4706e9db825?pvs=4#9a3c0bd582924b0d8183578408811c1d>
          const originalModel =
            /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/;

          if (value) {
            return originalModel.test(value);
          }
        },
      );
  }
}
