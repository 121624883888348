import { useReducer } from 'react';

import { Order, RefundDebitedAccount } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { useOwnUser } from '@/context/own-user.context';

import { authenticatedInstance } from '../../../../../axios/axios.instance';
import { useSnackbar } from '../../../../../components/elements/snackbar/use-snackbar';

export const useCancelOrderShippingFee = (order: Order) => {
  const { hasPermissions } = useOwnUser();
  const [isOpen, toggleIsOpen] = useReducer((p) => !p, false);

  const snackbar = useSnackbar();

  const isAllowedToRefundShippingFee =
    hasPermissions(PERMISSIONS.ORDER.WRITE_ORDER_CANCEL) && order.shippingFeesRefundedAt === null;

  const { mutate } = useMutation({
    mutationFn: ({
      debitedAccount = RefundDebitedAccount.platform,
    }: {
      debitedAccount: RefundDebitedAccount;
    }) => authenticatedInstance.post(`/refund/${order.id}/refund-shipping-fee`, { debitedAccount }),
    onSuccess: () => {
      snackbar.success('Shipping fee refunded');
      toggleIsOpen();
    },
    onError: () => {
      snackbar.error('Failed to refund shipping fee');
    },
  });

  return {
    isAllowedToRefundShippingFee,
    onConfirm: (debitedAccount: RefundDebitedAccount) => mutate({ debitedAccount }),
    isOpen,
    toggleIsOpen,
    onClose: toggleIsOpen,
    isLoading: false,
  };
};
