import { useEffect } from 'react';

import { Show } from '@bits-app/voggtpit-shared';

import { MuxAssetsTab, useMuxContext } from '@/mux';

export const ShowMuxAssetsTab = ({ showId }: { showId: Show['id'] }) => {
  const { loadAssets } = useMuxContext();
  useEffect(() => {
    loadAssets({ entityId: showId, type: 'show' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <MuxAssetsTab />;
};
