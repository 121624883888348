import { useReducer } from 'react';

import { Show } from '@bits-app/bits-server-data';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export const useStressTestOnShow = () => {
  const [modalIsOpen, toggleModalStatus] = useReducer((state) => !state, false);

  const { t } = useTranslation();

  const snackbar = useSnackbar();

  const { mutate: stressTestShow, isLoading } = useMutation(
    (showId: Show['id']) =>
      authenticatedInstance.post(`database-explorer/show/${showId}/stress-test`),
    {
      onSuccess: () => {
        snackbar.success(t(`databaseExplorer.stressTests.success`));
        toggleModalStatus();
      },
      onError: () => {
        snackbar.error(t(`databaseExplorer.stressTests.error`));
      },
    },
  );

  return {
    isLoading,
    stressTestShow,
    toggleModalStatus,
    modalIsOpen,
  };
};
