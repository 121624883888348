import { centsToUnit, formatAmountWithCurrency, Currency } from '@bits-app/voggtpit-shared';

import { Information } from '@/components/layouts/PanelDetails';

export type PayoutData = {
  totalAmountOrdersNetInCurrencyCents: number;
  totalAmountOrdersSuccessInCurrencyCents: number;
  totalAmountRefundedInCurrencyCents: number;
  totalCommissionInCurrencyCents: number;
  totalDeductedShippingCostInCurrencyCents: number;
  currency: Currency;
  netIncome: number;
};

export const panelDetailsPayoutData: Information<PayoutData>[] = [
  {
    value: 'totalAmountOrdersSuccessInCurrencyCents',
    label: 'Total amount orders success',
    format: (payoutData) =>
      format(payoutData?.totalAmountOrdersSuccessInCurrencyCents, payoutData?.currency),
  },
  {
    value: 'totalAmountRefundedInCurrencyCents',
    label: 'Total amount refunded',
    format: (payoutData) =>
      format(payoutData?.totalAmountRefundedInCurrencyCents, payoutData?.currency),
  },
  {
    value: 'totalAmountOrdersNetInCurrencyCents',
    label: 'Total amount orders net',
    format: (payoutData) =>
      format(payoutData?.totalAmountOrdersNetInCurrencyCents, payoutData?.currency),
  },
  {
    value: 'totalCommissionInCurrencyCents',
    label: 'Total commission',
    format: (payoutData) =>
      format(payoutData?.totalCommissionInCurrencyCents, payoutData?.currency),
  },
  {
    value: 'totalDeductedShippingCostInCurrencyCents',
    label: 'Total deducted shipping cost',
    format: (payoutData) =>
      format(Number(payoutData?.totalDeductedShippingCostInCurrencyCents), payoutData?.currency),
  },
  {
    value: 'netIncome',
    label: 'Net income',
    format: (payoutData) => format(Number(payoutData?.netIncome), payoutData?.currency),
  },
];

const format = (value = 0, currency?: Currency) => {
  return currency ? formatAmountWithCurrency(centsToUnit(Number(value)), currency) : '';
};
