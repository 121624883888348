import { add, OrderCancellationReason } from '@bits-app/voggtpit-shared';
import { createSelector } from '@reduxjs/toolkit';
import { differenceInMinutes } from 'date-fns';

import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { RootState } from '@/redux/store';

const sellerCancelRequestsSelector = (state: RootState) => state.sellerCancelRequests;

export const selectSellerCancelRequests = createSelector(sellerCancelRequestsSelector, (subState) =>
  [...subState.sellerCancelRequests].sort((a, b) => a.id - b.id),
);

export const selectSellerCancelRequestsCounts = createSelector(
  sellerCancelRequestsSelector,
  (subState) => subState.countPerCountries,
);

export const selectIsSellerCancelRequestsLoading = createSelector(
  sellerCancelRequestsSelector,
  (subState) => subState.loading,
);

export const selectSellerCancelRequestsRefundForm = createSelector(
  sellerCancelRequestsSelector,
  (subState) => subState.refundForm,
);

export const selectAreAllRefundableSellerCancelRequestsInTheRefund = (state: RootState) => {
  const subState = state.sellerCancelRequests;

  if (subState.sellerCancelRequests.length === 0) {
    return false;
  }

  return subState.sellerCancelRequests.length === subState.refundForm.orderItems.length;
};

export const selectSellerCancelRequestsInRefund = createSelector(
  sellerCancelRequestsSelector,
  (subState) => subState.refundForm.orderItems,
);

export const selectIsSellerCancelRequestsRefundFormLoading = createSelector(
  sellerCancelRequestsSelector,
  (subState) => subState.refundForm.loading,
);

export const selectIsSellerCancelRequestsRefundFormProcessing = createSelector(
  sellerCancelRequestsSelector,
  (subState) => subState.refundForm.processing,
);

export const selectIsSellerCancelRequestsRefundFormError = createSelector(
  sellerCancelRequestsSelector,
  (subState) => subState.refundForm.error,
);

export const selectIsSellerCancelRequestsRefundFormNotProcessedError = createSelector(
  sellerCancelRequestsSelector,
  (subState) => subState.refundForm.notAllProcessedError,
);

export const selectIsSellerCancelRequestInTheRefund = createSelector(
  [selectSellerCancelRequestsRefundForm, (_, orderItemId) => orderItemId],
  (refundForm, orderItemId) => {
    return refundForm.orderItems.includes(orderItemId);
  },
);

export const selectIsSellerCancelRequestSuspicious = createSelector(
  [selectSellerCancelRequests, (_, orderItemId) => orderItemId],
  (sellerCancelRequests, orderItemId) => {
    const sellerCancelRequest: OrderItemRefund | undefined = sellerCancelRequests.find(
      ({ id }) => id === orderItemId,
    );

    if (
      !sellerCancelRequest ||
      !sellerCancelRequest.cancelDate ||
      sellerCancelRequest.cancellationReason !== OrderCancellationReason.misclick
    ) {
      return false;
    }

    return (
      differenceInMinutes(
        new Date(sellerCancelRequest.cancelDate),
        new Date(sellerCancelRequest.purchaseDate),
      ) >
      4 * 60
    );
  },
);

export const selectIsSellerCancelRequestPurchasedWithPromotion = createSelector(
  [selectSellerCancelRequests, (_, orderItemId) => orderItemId],
  (sellerCancelRequests, orderItemId) => {
    const sellerCancelRequest: OrderItemRefund | undefined = sellerCancelRequests.find(
      ({ id }) => id === orderItemId,
    );

    if (!sellerCancelRequest) {
      return false;
    }

    return Boolean(sellerCancelRequest.promotionAmount);
  },
);

export const selectSellerCancelRequestsSummary = createSelector(
  sellerCancelRequestsSelector,
  (subState) => subState.refundSummary,
);

export const selectIsSellerCancelRequestsRefundSummaryLoading = createSelector(
  selectSellerCancelRequestsSummary,
  (subState) => subState.loading,
);

export const selectSellerCancelRequestsRefundSummary = createSelector(
  selectSellerCancelRequestsInRefund,
  selectSellerCancelRequestsSummary,
  selectSellerCancelRequests,
  (orderItemIds, subState, orderItems) => {
    const totalAmount = Object.values(subState.summary).reduce(
      (total, { totalAmount }) => add(total, totalAmount),
      0,
    );

    const includesGiveaways = orderItems
      .filter(({ id }) => orderItemIds.includes(id))
      .some(({ amount }) => amount === 0);

    return {
      ...subState.summary,
      totalOrderItems: orderItemIds.length,
      totalAmount,
      includesGiveaways,
    };
  },
);
