import { useCallback } from 'react';

import { FeaturedShowApplicationStatus } from '@bits-app/bits-server-data';
import { FeaturedShowApplicationWithData } from '@bits-app/voggtpit-shared';
import { useLocation, useNavigate } from 'react-router-dom';

import { ItemLayoutWithTabs } from '@/voggt-database-explorer/layouts/ItemLayoutWithTabs';

import { ListWithExport } from '../../voggt-database-explorer/components/ListWithExport';

import { BulkUpdateFeaturedShowApplication } from './components/BulkUpdateFeaturedShowApplication';
import { FeaturedShowApplicationRowAction } from './components/FeaturedShowApplicationRowAction';
import { FeaturedShowApplicationWithDataCellDefinition } from './const';

const TABS = ['to-review', 'approved-and-not-started', 'approved-and-done', 'all'] as const;

export const FeaturedShowApplicationList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentTab = TABS.find((path) => location.pathname.includes(path)) ?? TABS[0];

  const handleOnTabChange = useCallback((tab: string) => navigate(tab), [navigate]);

  const tabs = [
    {
      path: TABS[0],
      label: 'To review',
      Element: (
        <div className="flex flex-col gap-2">
          <ListWithExport<FeaturedShowApplicationWithData>
            cellDefinition={FeaturedShowApplicationWithDataCellDefinition}
            filters={{ status: 'pending' }}
            dataName={'featured-show'}
            renderRowActions={({ row }) => <FeaturedShowApplicationRowAction row={row.original} />}
            customSelectedRowsAction={(selectedRows) => (
              <BulkUpdateFeaturedShowApplication rows={selectedRows.map((x) => x.original)} />
            )}
          />
        </div>
      ),
    },
    {
      path: TABS[1],
      label: 'Approved and not started',
      Element: (
        <ListWithExport<FeaturedShowApplicationWithData>
          cellDefinition={FeaturedShowApplicationWithDataCellDefinition}
          filters={{ status: 'approved', isDone: false }}
          dataName={'featured-show'}
        />
      ),
    },
    {
      path: TABS[2],
      label: 'Approved and done',
      Element: (
        <ListWithExport<FeaturedShowApplicationWithData>
          cellDefinition={FeaturedShowApplicationWithDataCellDefinition}
          filters={{ status: 'approved', isDone: true }}
          dataName={'featured-show'}
        />
      ),
    },
    {
      path: TABS[3],
      label: 'All',
      Element: (
        <ListWithExport<FeaturedShowApplicationWithData>
          cellDefinition={FeaturedShowApplicationWithDataCellDefinition}
          filters={{}}
          dataName={'featured-show'}
          enableRowSelection={(row) =>
            row.original.featuredShowApplication.status === FeaturedShowApplicationStatus.pending
          }
          renderRowActions={({ row }) => <FeaturedShowApplicationRowAction row={row.original} />}
          customSelectedRowsAction={(selectedRows) => (
            <BulkUpdateFeaturedShowApplication rows={selectedRows.map((x) => x.original)} />
          )}
        />
      ),
    },
  ];
  return <ItemLayoutWithTabs tabs={tabs} currentTab={currentTab} onTabChange={handleOnTabChange} />;
};
