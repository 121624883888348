import { Category } from '@bits-app/bits-server-data';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';

import { UpsertCategoryModal } from '../../../components/upsert-category/UpsertCategory.modal';
import { useUpsertCategory } from '../hooks/use-upsert-category.hook';

type UpsertCategoryProps = {
  categories: Category[];
};

export const UpsertCategory = ({ categories }: UpsertCategoryProps) => {
  const { t } = useTranslation();

  const { ownUserIsAllowedToUpsertCategory, onOpenForm, openForm, onCloseForm } =
    useUpsertCategory();

  if (!ownUserIsAllowedToUpsertCategory) {
    return null;
  }

  return (
    <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}>
      <ButtonOutlined onClick={onOpenForm}>
        {t('databaseExplorer.category.upsertCategory.create.action')}
      </ButtonOutlined>
      {openForm && (
        <UpsertCategoryModal isOpen={openForm} categories={categories} onClose={onCloseForm} />
      )}
    </Box>
  );
};
