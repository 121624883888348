import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

import { isAtLeastContaining, Permission, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Box, CircularProgress } from '@mui/material';

import { OwnUser } from '@/domain/entities/own-user.entity';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import { selectOwnUser, selectOwnUserLoading } from '../auth/auth.slice';
import { getMe } from '../auth/use-cases';

export const ownUserContext = createContext<{
  ownUser: OwnUser | null;
  ownUserPermissions: Permission[];
  hasPermissions: (...permissions: Permission[]) => boolean;
  refetchMe: () => void;
}>({
  ownUser: null,
  ownUserPermissions: [],
  hasPermissions: () => true,
  refetchMe: () => {
    //
  },
});

export const useOwnUser = () => useContext(ownUserContext);

export const OwnUserProvider = ({ children }: { children: React.ReactNode }) => {
  const ownUser = useAppSelector(selectOwnUser);
  const ownUserPermissions = useMemo(() => ownUser?.permissions ?? [], [ownUser?.permissions]);

  const ownUserLoading = useAppSelector(selectOwnUserLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!ownUser) {
      dispatch(getMe());
    }
  }, [dispatch, ownUser]);

  const hasPermissions = useCallback(
    (...permissions: Permission[]) => {
      if (!ownUser) {
        throw new Error('No one is connected');
      }

      if (
        ownUser &&
        ownUser.permissions.includes(
          PERMISSIONS.SUPER_PRIVILEGE
            .WRITE_SUPER_PRIVILEGE_ADMINISTRATION_WRITE_PERMISSIONS_MANAGE_ALL,
        )
      ) {
        return true;
      }

      return isAtLeastContaining(ownUser?.permissions, permissions);
    },
    [ownUser],
  );

  const refetchMe = useCallback(() => dispatch(getMe()), [dispatch]);

  const value = useMemo(
    () => ({ ownUser, ownUserPermissions, hasPermissions, refetchMe }),
    [ownUser, ownUserPermissions, hasPermissions, refetchMe],
  );

  if (ownUserLoading) {
    return (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return <ownUserContext.Provider value={value}>{children}</ownUserContext.Provider>;
};
