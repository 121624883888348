import { useReducer, useRef } from "react";

import { ShipmentRating } from '@bits-app/bits-server-data';
import { MoreVert } from "@mui/icons-material";
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useForm, Controller } from "react-hook-form";

import { authenticatedInstance } from "@/axios/axios.instance";
import { useSnackbar } from "@/components/elements/snackbar/use-snackbar";
import { QUERIES_KEYS, queryClient } from "@/queries";

import { ModerateRatingForm } from "./utils";


export const BulkUpdateShipmentRating = ({
  rows,
  sellerId,
}: {
  rows: ShipmentRating[];
  sellerId: number;
}) => {
  const snackbar = useSnackbar();
  const { handleSubmit, control, watch } = useForm<ModerateRatingForm>();
  const [menuIsOpen, toggleMenu] = useReducer((p) => !p, false);
  const ref = useRef(null);

  const { mutate } = useMutation({
    mutationFn: (input: ModerateRatingForm) => {
      return authenticatedInstance.post(`database-explorer/shipment-rating/moderate`, {
        ...input,
        shipmentRatingIds: rows.map((r) => r.id),
      });
    },
    onSuccess: () => {
      snackbar.success('Rating updated');
      toggleMenu();
      queryClient.invalidateQueries(QUERIES_KEYS.shipmentRatingList(sellerId));
    },
    onError: (error: Error) => {
      snackbar.error(error.message);
    }
  });

  return (
    <>
      <IconButton onClick={toggleMenu} ref={ref}>
        <MoreVert />
      </IconButton>

      <Dialog open={menuIsOpen} onClose={toggleMenu}>
        <DialogTitle>Moderate on {rows.length} ratings. </DialogTitle>
        <DialogContent className="flex flex-col gap-4">
          <DialogContentText>
            All the ratings will get the same moderation, be careful
          </DialogContentText>
          <Controller
            name="shouldBeModerated"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="recommandationType-label">Moderation</InputLabel>
                <Select labelId="recommandationType-label" {...field} label="Moderation">
                  <MenuItem key={'true'} value={"true"}>
                    Moderate: hide from seller and remove from their average rating
                  </MenuItem>
                  <MenuItem key={'false'} value={"false"}>
                    Remove moderation: show to seller and include in their average rating
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Button
            variant="contained"
            onClick={handleSubmit((d) => mutate(d))}
            disabled={!watch('shouldBeModerated')}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
