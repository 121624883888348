import CheckIcon from '@mui/icons-material/Check';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { FilterButton } from '@Elements/FilterButton';

import { EnumFilter } from '../../EnumFilter/EnumFilter';
import { useEnumFilter } from '../../EnumFilter/use-enum-filter';

type EnumFilterProps<T> = {
  withEmptyOption?: boolean;
  options: { value: T; label?: string }[];
  field: AddFilterFunctionParams['field'];
  activeFilters?: T[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
};

export const EnumFilterTable = <T extends string>({
  field,
  options,
  addFilter,
  activeFilters = [],
  isUnsaved,
  withEmptyOption,
}: EnumFilterProps<T>) => {
  const { t } = useTranslation();

  const { isApplyButtonDisabled, onApplyFilter, onClose, ...enumFilterProps } = useEnumFilter({
    activeFilters,
    addFilter,
    field,
    isUnsaved,
    options,
    withEmptyOption,
  });

  return (
    <FilterButton
      isActive={activeFilters.length > 0}
      onClose={onClose}
      unSaved={!isApplyButtonDisabled}
    >
      <EnumFilter {...enumFilterProps} />

      <Box display="flex" flexDirection="column" alignItems="flex-end" p={1}>
        <Button
          onClick={onApplyFilter}
          startIcon={<CheckIcon />}
          variant="contained"
          disabled={isApplyButtonDisabled}
        >
          {t('applyFilters')}
        </Button>
      </Box>
    </FilterButton>
  );
};
