import { SellerNews } from '@bits-app/bits-server-data';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { validationSchemasBuilder } from '@/voggt-database-explorer';
import { UpsertForm } from '@/voggt-database-explorer/components/upsert-view/UpsertForm';

import { useCreateSellerNews } from './use-create-seller-news';

export type CreateSellerNews = Pick<
  SellerNews,
  'countryId' | 'internalName' | 'isActive' | 'title' | 'url' | 'message' | 'startAt' | 'endAt'
>;

export const SellerNewsCreationView = () => {
  const { t } = useTranslation();

  const { header, schemas, onCreate, isLoading } = useCreateSellerNews();
  const validationSchemaBuilder = validationSchemasBuilder['Seller-News'];

  const goBackName = t('databaseExplorer.menu.seller-news');

  if (!schemas.length || !header) {
    return null;
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <UpsertForm<SellerNews>
      schemas={schemas}
      header={header}
      onUpsert={onCreate}
      goBackName={goBackName}
      validationSchemaBuilder={validationSchemaBuilder}
    />
  );
};
