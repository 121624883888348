import { useCallback, useEffect } from 'react';

import { ShippingFeeConfig } from '@bits-app/bits-server-data';
import { centsToUnit, formatAmount } from '@bits-app/voggtpit-shared';

import { Information } from '@/components/layouts/PanelDetails';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { selectResourceLoading } from '@/voggt-database-explorer/redux/database-explorer.slice';
import {
  getResource,
  selectResource,
  useResourceSelector,
} from '@/voggt-database-explorer/redux/use-cases/get-resource';

export const useShippingFeeConfigDetailsView = (shippingFeeConfigId: string) => {
  const dispatch = useAppDispatch();

  const shippingFeeConfig = useResourceSelector<ShippingFeeConfig>(selectResource);
  const loading = useAppSelector(selectResourceLoading);

  useEffect(() => {
    if (shippingFeeConfigId && String(shippingFeeConfig.id) !== String(shippingFeeConfigId)) {
      dispatch(getResource(shippingFeeConfigId, 'Shipping-Fee-Config'));
    }
  }, [shippingFeeConfigId, shippingFeeConfig.id, dispatch]);

  const refreshAfterEdit = useCallback(() => {
    dispatch(getResource(shippingFeeConfigId, 'Shipping-Fee-Config'));
  }, [dispatch, shippingFeeConfigId]);

  return {
    shippingFeeConfig,
    loading,
    refreshAfterEdit,
  };
};

export const panelDetailsShippingFeeConfig: Information<ShippingFeeConfig>[] = [
  { value: 'id', label: 'id' },
  { value: 'shippingCountryId', label: 'Shipping country' },
  { value: 'destinationCountryId', label: 'Destination country' },
  { value: 'shippingCategory', label: 'Shipping category' },
  {
    value: 'fixedFee',
    label: 'Fixed fee',
    format: (config) => {
      if (config?.fixedFee == null) {
        return '';
      }

      return `${formatAmount(centsToUnit(Number(config.fixedFee)))}`;
    },
  },
  {
    value: 'intervalFee',
    label: 'Interval fee',
    format: (config) => {
      if (config?.intervalFee == null) {
        return '';
      }

      return `${formatAmount(centsToUnit(Number(config.intervalFee)))}`;
    },
  },
  {
    value: 'intervalCount',
    label: 'Interval count',
  },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
];
