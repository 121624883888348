import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

type AlertBarProps = {
  title?: string;
  text?: string;
  severity?: 'success' | 'info' | 'warning' | 'error';
  extraText?: Record<string, string>;
};

export const AlertBar = ({
  title = '',
  text = '',
  severity = 'error',
  extraText = {},
}: AlertBarProps) => {
  const { t } = useTranslation();
  return (
    <Alert severity={severity}>
      <AlertTitle sx={{ m: 0 }}>{t(title)}</AlertTitle>
      {t(text, extraText)}
    </Alert>
  );
};
