import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Country } from './Country';
import { CountryCodeIso2 } from './CountryCodeIso2';
import { User } from './User';

@Entity()
export class ShippingAddress {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column()
  name!: string;

  @Column({ nullable: true, type: 'character varying' })
  firstName?: string | null;

  @Column({ nullable: true, type: 'character varying' })
  lastName?: string | null;

  @Column({ nullable: true, type: 'character varying' })
  email?: string | null;

  @Column({ nullable: true, type: 'character varying' })
  phoneNumber?: string | null;

  @Column({ nullable: true, type: 'character varying' })
  region?: string | null;

  @Column()
  inputLine1!: string;

  @Column({ nullable: true, type: 'character varying' })
  inputLine2?: string | null;

  @Column()
  inputPostalCode!: string;

  @Column()
  inputCity!: string;

  @Column()
  inputCountry!: string;

  @Column({
    type: 'enum',
    enum: CountryCodeIso2,
    nullable: true,
    enumName: 'country_iso2code_enum',
  })
  inputCountryCodeIso2!: CountryCodeIso2;

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'resolvedCountryId' })
  resolvedCountry!: Country;

  @Column()
  resolvedCountryId!: Country['id'];

  @ManyToOne(() => User, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Index()
  @Column()
  userId!: User['id'];

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;
}
