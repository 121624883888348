import { TableCell } from '@mui/material';

import { TableCellHeaderBorderBottom } from '@/components/elements/StyledTableCell';

import { HeadCell } from '../RefundOrderItem';

type RefundParametersHeaderProps = {
  headCells: (Omit<HeadCell, 'id'> & { id: string })[];
};

export const RefundFormHeader = ({ headCells }: RefundParametersHeaderProps) => {
  return (
    <>
      <TableCell
        sx={{ borderBottom: 'none', maxWidth: 10, padding: ({ spacing }) => spacing(1) }}
      />

      {headCells.map((headCell) => (
        <TableCellHeaderBorderBottom
          key={headCell.id}
          maxWidth={headCell.maxWidth}
          sx={{
            fontWeight: 'bold',
            minWidth: 150,
          }}
        >
          {headCell.label}
        </TableCellHeaderBorderBottom>
      ))}
    </>
  );
};
