import { currencySymbol, formatAmount } from '@bits-app/voggtpit-shared';
import { TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  TableCellBodyNoBorderBottom,
  TableCellEllipsisContent,
} from '@/components/elements/StyledTableCell';
import { OrderItem } from '@/entities/order-item.entity';
import { InternalLink } from '@Elements/InternalLink';

type OrderItemsMassUpdateRowProps = {
  orderItem: OrderItem;
};

export const OrderItemsMassUpdateRow = ({ orderItem }: OrderItemsMassUpdateRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCellBodyNoBorderBottom minWidth={70}>
        <InternalLink to={`/show/${orderItem.showId}`}>{orderItem.showId}</InternalLink>
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={185}>{orderItem.showDate}</TableCellBodyNoBorderBottom>

      <TableCellEllipsisContent maxWidth={130} title={orderItem.showName}>
        {orderItem.showName}
      </TableCellEllipsisContent>

      <TableCellEllipsisContent maxWidth={140} title={orderItem.product}>
        {orderItem.product}
      </TableCellEllipsisContent>

      <TableCellBodyNoBorderBottom minWidth={100} align="right">
        {formatAmount(orderItem.amount)} {currencySymbol(orderItem.currency)}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={135} align="right">
        {formatAmount(orderItem.shippingAmount)} {currencySymbol(orderItem.currency)}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={120}>
        {orderItem.shippingStatus}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom>{orderItem.shippingProvider}</TableCellBodyNoBorderBottom>

      <TableCellEllipsisContent maxWidth={130}>{orderItem.trackingNumber}</TableCellEllipsisContent>

      <TableCellBodyNoBorderBottom minWidth={180}>
        {orderItem.purchaseDate}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={180}>
        {orderItem.updateDate}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={130}>
        {orderItem.paymentStatus}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={130}>
        {orderItem.refundAgent}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={130}>
        {orderItem.refundDebitedAccount
          ? t(`orderItemLabels.debitedAccounts.${orderItem.refundDebitedAccount}`)
          : ''}
      </TableCellBodyNoBorderBottom>

      <TableCellEllipsisContent maxWidth={160} minWidth={160} title={orderItem.refundReason}>
        {orderItem.refundReason}
      </TableCellEllipsisContent>

      <TableCellBodyNoBorderBottom minWidth={185}>
        {orderItem.refundedDate}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={155} align="right">
        {orderItem.refundedAmount !== undefined
          ? `${formatAmount(orderItem.refundedAmount)} ${currencySymbol(orderItem.currency)}`
          : ''}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={185}>
        {orderItem.cancelDate}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={145}>
        {orderItem.cancellationReason
          ? t(`orderItemLabels.cancellationReasons.${orderItem.cancellationReason}`)
          : ''}
      </TableCellBodyNoBorderBottom>
    </TableRow>
  );
};
