import {
  PrimaryGeneratedColumn,
  Entity,
  TableInheritance,
  CreateDateColumn,
  UpdateDateColumn,
  ChildEntity,
  ManyToOne,
  JoinColumn,
  Column,
  Index,
} from 'typeorm';

import { InventoryProduct } from './InventoryProduct';
import { Product } from './Product';

const fileKeyVerification =
  /.*\/([A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}\.[a-z]*)/i;

export enum FileUploadType {
  inventory_product_image = 'inventory_product_image',
  product_image = 'product_image',
}

@Entity()
@TableInheritance({
  column: {
    type: 'enum',
    name: 'type',
    enum: FileUploadType,
  },
})
export class FileUpload {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column()
  url!: string;

  @Column({ type: 'enum', enum: FileUploadType })
  type!: FileUploadType;

  extractKeyFromUrl() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const match = this.url.match(fileKeyVerification)!;
    return match[1];
  }

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;
}

@ChildEntity(FileUploadType.product_image)
export class ProductImageUpload extends FileUpload {
  @ManyToOne(() => Product)
  @JoinColumn({ name: 'productId' })
  product!: Product;

  @Column()
  @Index()
  productId!: Product['id'];

  @Column({ default: 0 })
  position!: number;
}

@ChildEntity(FileUploadType.inventory_product_image)
export class InventoryProductImageUpload extends FileUpload {
  @ManyToOne(() => InventoryProduct)
  @JoinColumn({ name: 'inventoryProductId' })
  inventoryProduct!: InventoryProduct;

  @Column()
  @Index()
  inventoryProductId!: InventoryProduct['id'];

  @Column({ default: 0 })
  position!: number;
}
