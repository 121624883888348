import { Show } from '@bits-app/bits-server-data';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';

import { useCellDefinition } from './useCellDefinition';

const TYPESENSE_MAX_RESULT_PER_PAGE = 30;

export const ListShow = () => {
  const { CellShowDefinition } = useCellDefinition();

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListWithSearch<Show & { id: string; user: string }>
        title="Shows"
        cellDefinition={CellShowDefinition}
        typesenseHookParams={{
          typesenseIndex: 'show',
          typesenseParams: {
            per_page: TYPESENSE_MAX_RESULT_PER_PAGE,
            query_by: ['name', 'user', 'country'],
          },
        }}
        dataListProps={{
          enableFilters: true,
        }}
      />
    </div>
  );
};
