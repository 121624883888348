export type CustomerAttributes = {
  id: number;
  username: string;
  email?: string;
  language?: string;
  phoneNumber?: string;
};

export class Customer {
  readonly id: number;

  readonly username: string;

  readonly email?: string;

  readonly language?: string;

  readonly phoneNumber?: string;

  constructor(attributes: CustomerAttributes) {
    this.id = attributes.id;
    this.username = attributes.username;
    this.email = attributes.email;
    this.language = attributes.language;
    this.phoneNumber = attributes.phoneNumber;
  }
}
