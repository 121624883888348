import { ShippingFeeConfig } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '@/components/elements/buttons/ButtonContained';
import { useOwnUser } from '@/context/own-user.context';
import { ListCustom } from '@Elements/DataExplorer/ListCustom';

import { useCellDefinition } from '../../use-cell-definition';
import { CreateShippingFeeConfig } from '../create/CreateShippingFeeConfig';

import { useShippingFeeConfig } from './use-shipping-fee-config';

export const ListShippingFeeConfig = () => {
  const { CellShippingFeeConfigDefinition } = useCellDefinition();
  const {
    shippingFeeConfigs,
    shippingFeeConfigToDelete,
    setShippingFeeConfigToDelete,
    onDeleteShippingFeeConfig,
  } = useShippingFeeConfig();

  const { hasPermissions } = useOwnUser();
  const isReadOnly = !hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_SHIPPING_FEE_CONFIG,
  );

  return (
    <>
      <div className="h-full flex flex-col flex-1 p-4">
        <ListCustom<ShippingFeeConfig>
          cellDefinition={CellShippingFeeConfigDefinition}
          results={shippingFeeConfigs}
          getPathDetail={(row) => `/shipping-fee-config/${row.id}`}
          enableFilters
          children={<CreateShippingFeeConfig />}
          renderRowActions={
            isReadOnly
              ? undefined
              : ({ row }) => (
                  <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <IconButton
                      color="error"
                      onClick={(event) => {
                        event.stopPropagation();
                        setShippingFeeConfigToDelete(row.original);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                )
          }
        />
      </div>

      {shippingFeeConfigToDelete && (
        <ConfirmDelete
          shippingFeeConfig={shippingFeeConfigToDelete}
          onConfirm={onDeleteShippingFeeConfig}
          onCancel={() => setShippingFeeConfigToDelete(undefined)}
        />
      )}
    </>
  );
};

type ConfirmDeleteProps = {
  shippingFeeConfig: ShippingFeeConfig;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmDelete = ({ onCancel, onConfirm, shippingFeeConfig }: ConfirmDeleteProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <DialogTitle>{t('databaseExplorer.shippingFeeConfig.delete.title')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('databaseExplorer.shippingFeeConfig.delete.content', {
            destination: shippingFeeConfig.destinationCountryId,
            shipping: shippingFeeConfig.shippingCountryId,
            category: shippingFeeConfig.shippingCategory,
          })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <ButtonContained color="error" onClick={onConfirm}>
          {t('action.delete')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
