import { useState } from 'react';

import { Copy, CopyCheck } from 'lucide-react';

type DataTextProps = {
  title: string;
  value?: string | null | number;
  disableCopy?: boolean;
  currency?: 'eur' | 'gbp';
  subDescription?: string;
};

export const DataText = ({ title, value, subDescription }: DataTextProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value as string);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className="flex justify-between items-center gap-1" onClick={copyToClipboard}>
      <div className="flex flex-col gap-1">
        <div className="flex flex-col ">
          <p className="font-semibold">{title}</p>
          {subDescription && <p className="font-light italic text-sm">{subDescription}</p>}
        </div>
        <div className="flex gap-2 items-center">{value && <p>{value}</p>}</div>
      </div>

      <div className="flex items-center text-slate-300 hover:text-slate-500 cursor-pointer duration-100">
        {isCopied && <CopyCheck size={18} className="text-green-500" />}
        {!isCopied && <Copy size={18} />}
      </div>
    </div>
  );
};
