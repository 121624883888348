import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';

type LoadingButtonProps = ButtonProps & {
  loading: boolean;
};

export const LoadingButton = ({ loading, children, ...buttonProps }: LoadingButtonProps) => (
  <Box sx={{ m: 1, position: 'relative', textAlign: 'right' }}>
    <Button disabled={loading} {...buttonProps}>
      {children}
    </Button>

    {loading && (
      <CircularProgress
        size={24}
        sx={{
          color: ({ palette }) => palette.success.main,
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    )}
  </Box>
);
