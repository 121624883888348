import {
  PrimaryGeneratedColumn,
  UpdateDateColumn,
  CreateDateColumn,
  JoinColumn,
  ManyToOne,
  Column,
  Entity,
  DeleteDateColumn,
  OneToMany,
  Index,
} from 'typeorm';

import { Currency } from '../models/currency';
import { GiveawayAudience } from '../models/giveaway-audience';
import { ProductType } from '../models/product-type';
import { ProductStatusInAuction } from '../models/productStatusInAuction';

import { ProductImageUpload } from './FileUpload';
import { InventoryProduct } from './InventoryProduct';
import { OrderProduct } from './OrderProduct';
import { Shop } from './Shop';
import { Show } from './Show';
import { User } from './User';
import { UserFollowProduct } from './UserFollowProduct';

@Entity()
export class Product {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Index()
  @Column({ nullable: true, type: 'uuid' })
  inventoryProductId!: InventoryProduct['id'] | null;

  @ManyToOne(() => InventoryProduct, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'inventoryProductId' })
  inventoryProduct!: InventoryProduct | null;

  @Column()
  @Index('product_name_gin_idx', { synchronize: false })
  name!: string;

  @Column({ nullable: true, type: 'character varying' })
  categoryName!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  @Index('product_description_gin_idx', { synchronize: false })
  description!: string | null;

  @Column({ default: 0 })
  startingAmount!: number;

  @Column({ default: 0 })
  fixedAmount!: number;

  @Column({ default: 0 })
  availableQuantity!: number;

  /**
   * Can be null because we have no easy way to catch up with initial quantities for existing products at the time of adding this column.
   */
  @Column({ nullable: true, type: 'int4' })
  initialQuantity!: number | null;

  @Column({ type: 'enum', enum: ProductType, default: ProductType.auction })
  type!: ProductType;

  @Column({
    type: 'enum',
    enum: Currency,
    default: Currency.eur,
    enumName: 'currency_enum',
  })
  currency!: Currency;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'sellerId' })
  seller!: User;

  @Index()
  @Column()
  sellerId!: number;

  @ManyToOne(() => Show, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'showId' })
  show!: Show;

  @Index()
  @Column({ nullable: true })
  showId!: number;

  @ManyToOne(() => Shop, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'shopId' })
  shop!: Shop;

  @Index()
  @Column({ nullable: true })
  shopId!: number;

  @OneToMany(() => UserFollowProduct, (ufp) => ufp.product)
  userFollowProduct!: UserFollowProduct[];

  @Column({
    type: 'enum',
    enum: ProductStatusInAuction,
    default: ProductStatusInAuction.pending,
    nullable: false,
  })
  status!: ProductStatusInAuction;

  @OneToMany(() => ProductImageUpload, (upload) => upload.product)
  @JoinColumn({ name: 'id', referencedColumnName: 'productId' })
  images!: ProductImageUpload[];

  @Column({ type: 'int4', nullable: true })
  weight!: number | null;

  @OneToMany(() => OrderProduct, (op) => op.product)
  orderProducts!: OrderProduct[];

  @Column({ default: false })
  isPinned!: boolean;

  @Column({ nullable: true, type: 'boolean' })
  isCreatedByVoggt!: boolean | null;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;

  // hack around the fact that the mobile apps don't use the OrderedProducts entities yet.
  // TODO: remove when apps use `allOrderedProducts` field instead of AllProduct and filter with status sold
  computedSoldPrice?: number;

  computedOrderId?: number;

  // used to store the real product ID when generating negative IDs for sold products in ProductGetter.getProductsFromShow
  computedProductId?: number;

  // only set by product-by-id dataloader for product Node
  computedIsSupplierShow?: boolean;

  @Column({ nullable: true, type: 'character varying' })
  brand!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  size!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  color!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  condition!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  model!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  gender!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  cardCondition!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  cardGradingService!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  cardGrade!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  cardLanguage!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  cardType!: string | null;

  @Column({ nullable: true, type: 'enum', enum: GiveawayAudience })
  giveawayAudience!: GiveawayAudience | null;
}
