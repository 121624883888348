import { AppThunkAction } from '@/redux/store';

import { partnerProgramActions } from '../partner-program.slice';

export const deletePartnerProgram =
  (partnerProgramId: string): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { partnerProgramGateway, snackbarGateway }) => {
    dispatch(partnerProgramActions.setIsLoading(true));
    try {
      await partnerProgramGateway.deleteSafe(partnerProgramId);
      const all = await partnerProgramGateway.getAll();
      dispatch(partnerProgramActions.setData(all));
    } catch (e) {
      console.log('Error ', e);
      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(partnerProgramActions.setIsLoading(false));
      dispatch(partnerProgramActions.selectForEdition(null));
    }
  };
