import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { LoadingRow } from '@Elements/LoadingRow';

import { HeadCell } from '../RefundOrderItem';
import { RefundOrderItemHeader } from '../StickyTable/RefundOrderItemHeader';

import { OrderItemRow } from './OrderItemRow';
import { RefundFormHeader } from './RefundFormHeader';
type ScrollableTableProps = {
  descriptionHeadCells: HeadCell[];
  refundFormHeadCells: (Omit<HeadCell, 'id'> & { id: string })[];
  orderItems: OrderItemRefund[];
  mainOrderItem: OrderItemRefund;
  borderRowColSpan: number;
  tableContainerRef: (ref: HTMLDivElement | null) => void;
  loading: boolean;
  isReadOnly: boolean;
};

export const ScrollableRefundTable = ({
  descriptionHeadCells,
  refundFormHeadCells,
  orderItems,
  mainOrderItem,
  borderRowColSpan,
  tableContainerRef,
  loading,
  isReadOnly,
}: ScrollableTableProps) => {
  return (
    <TableContainer ref={tableContainerRef} sx={{ overflowX: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <RefundOrderItemHeader headCells={descriptionHeadCells} />
            {!isReadOnly && <RefundFormHeader headCells={refundFormHeadCells} />}
          </TableRow>
        </TableHead>

        <TableBody>
          {/* <EmptyTableRow /> */}

          {loading ? (
            <LoadingRow cellCount={borderRowColSpan} />
          ) : (
            <OrderItemRow orderItem={mainOrderItem} isReadOnly={isReadOnly} />
          )}

          {/* <EmptyTableRow /> */}
          {/* <EmptyTableRow borderTop colSpan={borderRowColSpan} /> */}

          {loading ? (
            <LoadingRow cellCount={borderRowColSpan} />
          ) : (
            orderItems.map((orderItem) => (
              <OrderItemRow key={orderItem.id} orderItem={orderItem} isReadOnly={isReadOnly} />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
