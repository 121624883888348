import { StrictMode } from 'react';

import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from '@/App';
import { SnackbarProvider } from '@/components/elements/snackbar/SnackbarProvider';

import { theme } from './theme';

import '@/index.css';
import '@/translations/index';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <SnackbarProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </SnackbarProvider>
  </StrictMode>,
);
