import { DataList } from '../DataList';
import { DataListType } from '../DataList/datalist-type';

import { ContainerDataExplorer } from './ContainerDataExplorer';

export const ListCustom = <T extends { id?: string | number }>(
  dataListProps: DataListType<T & { id: string | number }> & {
    hideWhenNoData?: boolean;
    children?: React.ReactNode;
  },
) => {
  const shouldHide = dataListProps.hideWhenNoData && !dataListProps.results.length;
  if (shouldHide) {
    return null;
  }
  return (
    <ContainerDataExplorer>
      <DataList<T & { id: string | number }> {...dataListProps} />
    </ContainerDataExplorer>
  );
};
