/* eslint-disable @typescript-eslint/ban-ts-comment */

import { AutoFollowUserCampaign, Schema } from '@bits-app/voggtpit-shared';
import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS } from '@/queries';
import { UpsertForm } from '@/voggt-database-explorer/components/upsert-view/UpsertForm';

export const AutoFollowUserCampaignCreationView = () => {
  const navigate = useNavigate();
  const { error } = useSnackbar();
  const { remove } = useQuery(QUERIES_KEYS.activeAutoFollowUserCampaignList());

  const { data, isLoading } = useQuery(QUERIES_KEYS.autoFollowUserCampaignCreationSchema(), () =>
    authenticatedInstance.get<Schema<AutoFollowUserCampaign>[]>(
      '/auto-follow-user-campaign-creation-schema',
    ),
  );

  if (isLoading || !data) return <CircularProgress />;

  return (
    <UpsertForm<AutoFollowUserCampaign>
      onUpsert={async (x) => {
        try {
          await authenticatedInstance.post('/auto-follow-user-campaign', x);
          remove();
          navigate('/auto-follow-user-campaign/active');
        } catch (e: unknown) {
          if (axios.isAxiosError(e)) {
            if (e.response?.data) {
              error(typeof e.response?.data === 'string' ? e.response?.data : 'An error occurred');
            }
          }
        }
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      schemas={data.data as Schema<any>[]}
    />
  );
};
