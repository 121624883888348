import * as Colors from '@/theme/colors';

type ChipsCellProps = {
  value: string;
};

export const ChipsCell = ({ value }: ChipsCellProps) => {
  const color = getBadgeColor(value);

  return (
    <p
      className="text-xs font-semibold text-white rounded-md px-3 py-1 w-fit capitalize"
      style={{ backgroundColor: color }}
    >
      {value}
    </p>
  );
};

const getBadgeColor = (data: string) => {
  switch (data.toLowerCase()) {
    case 'failed':
    case 'referrer':
    case 'instant_buy':
    case 'not started':
    case 'ivs_web_rtc':
      return '#4D5DD0';
    case 'shipped':
    case 'payment':
    case 'referral':
    case 'gbp':
    case 'giveaway':
    case 'ivs_rtmp':
      return '#4E9AD1';
    case 'pending':
    case 'in_progress':
      return '#b5b5b5';
    case 'status':
    case 'active':
    case 'success':
    case 'created':
    case 'attempt':
    case 'campaign':
    case 'sold':
    case 'used':
    case 'mux':
    case 'approved':
      return '#4DD099';
    case 'delivered':
      return '#29855E';
    case 'canceled':
    case 'rejected':
      return '#D04DC6';
    case 'lock':
    case 'refunded':
      return '#A076DB';
    case 'error':
      return Colors.error.main;
    case 'welcome':
    case 'auction':
      return '#a855f7';
    case 'refused':
      return '#f44336';
    case 'eur':
    case 'ended':
    case 'unsold':
    default:
      return Colors.neutral[400];
  }
};
