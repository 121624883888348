import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  Unique,
} from 'typeorm';

import { Country } from './Country';
import { SellerTierName } from './SellerTierName';

@Entity()
@Unique(['name', 'countryId'])
export class SellerTier {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column({
    type: 'enum',
    enum: SellerTierName,
    default: SellerTierName.tier0,
  })
  name!: SellerTierName;

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'countryId' })
  country!: Country | null;

  @Column({ nullable: true })
  countryId!: Country['id'] | null;

  @Column({ default: true })
  canCreateShow!: boolean;

  @Column({ nullable: true, type: 'int4' }) // Max minute of the day until midnight
  showStartMaxTime!: number | null;
}
