import { Badge, User } from '@bits-app/bits-server-data';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonOutlined } from '@Elements/buttons/ButtonOutlined';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

type RemoveBadgeModalProps = {
  onClose: () => void;
  isLoading: boolean;
  handleSubmit: () => void;
  username: User['username'] | User['id'];
  selectedBadge: Badge | null;
};

export const RemoveBadgeModal = ({
  onClose,
  isLoading,
  handleSubmit,
  username,
  selectedBadge,
}: RemoveBadgeModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={Boolean(selectedBadge)}>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="common.black"
      >
        {t('databaseExplorer.users.badge.remove.title', { username })}

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography>
          {t('databaseExplorer.users.badge.remove.content', {
            username,
            badge: selectedBadge?.name,
          })}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          px: ({ spacing }) => spacing(3),
          paddingBottom: ({ spacing }) => spacing(2),
        }}
      >
        <ButtonOutlined onClick={onClose} disabled={isLoading}>
          {t('cancel')}
        </ButtonOutlined>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isLoading || !selectedBadge}
        >
          {t('action.delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
