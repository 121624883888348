import { useMemo } from 'react';

import type { CommercialEvent, Show, Product } from '@bits-app/bits-server-data';
import { PERMISSIONS, AvailableEntityForComment } from '@bits-app/voggtpit-shared';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { EditButton } from '@/voggt-database-explorer/components/EditButton';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';
import { ItemLayout, ItemTab } from '@/voggt-database-explorer/layouts/ItemLayout';

import { ActivityLogs } from '../../../components/elements/ActivityLogs';
import { PanelDetailImage } from '../../../components/elements/PanelDetailImage';
import { DeleteButton } from '../../../components/elements/buttons/DeleteButton';
import { useActivityLogs } from '../../../hooks/use-activity-logs';
import { useGetCategories } from '../../../queries';
import { ListWithExport } from '../../components/ListWithExport';
import { CellProductDefinition } from '../../show/misc/show.const';
import { getCellDefinitionForShowInUser } from '../../users/hooks/use-cell-definition';
import { panelInformation, statusPanelInformation, timestampPanelInformation } from '../const';
import { useCommercialEvent } from '../hooks/use-commercial-event';
import { useEditAssetOfCommercialEvent } from '../hooks/use-edit-asset-of-commercial-event';
import { useRemoveFromCommercialEvent } from '../hooks/use-remove-from-commercial-event';

import { DuplicateCommercialEvent } from './DuplicateCommercialEvent';
import { RemoveFromListModal } from './RemoveFromList.modal';

const MAX_FILE_SIZE = 10000000;

export const CommercialEventDetail = () => {
  const commercialEventId = useIdParam();
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();
  const location = useLocation();

  const canEdit = hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_COMMERCIAL_EVENT,
  );

  const canDelete = hasPermissions(
    PERMISSIONS.SUPER_PRIVILEGE.WRITE_SUPER_PRIVILEGE_REMOVE_FROM_COMMERCIAL_EVENT,
  );
  const handleOnTabChange = useNavigateInDetailTab(`/commercial-event/${commercialEventId}`);

  const { discardCurrentItemIdSelected, selectedIdItem, setItemIdToRemove, onConfirm } =
    useRemoveFromCommercialEvent(Number(commercialEventId));

  const currentTab = useMemo(() => {
    discardCurrentItemIdSelected();
    return (
      ['shows', 'products', 'duplicate'].find((path) => location.pathname.includes(path)) ?? '/'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { data, refetch, isLoading } = useCommercialEvent(+commercialEventId);

  const { data: categories } = useGetCategories();
  const cellShowDefinition = getCellDefinitionForShowInUser(categories);

  const tabs: ItemTab[] = [
    {
      Element: (
        <CommercialEventComponent
          key="/"
          canEdit={canEdit}
          commercialEvent={data}
          loading={isLoading}
        />
      ),
      label: t('databaseExplorer.commercialEvent.tabs.details'),
      path: '/',
    },
    {
      path: 'shows',
      label: t('databaseExplorer.commercialEvent.tabs.shows'),
      Element: (
        <>
          <ListWithExport<Show & { id: string; gmv: number }>
            key="commercial-event-related-shows"
            cellDefinition={cellShowDefinition}
            filters={{ commercialEventId: Number(commercialEventId) }}
            dataName={'commercial-event-related-shows'}
            renderRowActions={({ row }) => {
              if (!canDelete) return null;
              return <DeleteButton onClick={() => setItemIdToRemove(row.original.id)} />;
            }}
          />
          <RemoveFromListModal
            itemType="shows"
            onCancel={discardCurrentItemIdSelected}
            onConfirm={onConfirm}
            itemIdToRemove={selectedIdItem}
          />
        </>
      ),
    },
    {
      path: 'products',
      label: t('databaseExplorer.commercialEvent.tabs.products'),
      Element: (
        <>
          <ListWithExport<Product & { id: string }>
            key="commercial-event-related-products"
            cellDefinition={CellProductDefinition}
            filters={{ commercialEventId: Number(commercialEventId) }}
            dataName={'commercial-event-related-products'}
            renderRowActions={({ row }) => {
              if (!canDelete) return null;
              return <DeleteButton onClick={() => setItemIdToRemove(row.original.id)} />;
            }}
          />
          <RemoveFromListModal
            itemType="products"
            onCancel={discardCurrentItemIdSelected}
            onConfirm={onConfirm}
            itemIdToRemove={selectedIdItem}
          />
        </>
      ),
    },
    {
      path: 'duplicate',
      label: t('databaseExplorer.commercialEvent.tabs.duplicate'),
      Element: <DuplicateCommercialEvent commercialEventId={+commercialEventId} />,
    },
  ];

  if (!commercialEventId) {
    return null;
  }

  return (
    <ItemLayout<CommercialEvent>
      tabs={tabs}
      id={commercialEventId}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
      editionDescriptor={
        canEdit
          ? {
              goBackName: t('databaseExplorer.editionFormPath.commercialEvent', {
                commercialEventId,
              }),
              resourceId: commercialEventId,
              resourceType: 'CommercialEvent',
              onSuccess: refetch,
            }
          : undefined
      }
    />
  );
};

type CommercialEventComponentProps = {
  canEdit: boolean;
  commercialEvent?: CommercialEvent;
  loading: boolean;
};

const CommercialEventComponent = ({
  canEdit,
  commercialEvent,
  loading,
}: CommercialEventComponentProps) => {
  const commercialEventId = useIdParam();
  const { t } = useTranslation();

  const { editorRef: editorRefBanner, onEditAsset: onEditAssetBanner } =
    useEditAssetOfCommercialEvent('bannerUrl', commercialEvent);

  const { editorRef: editorRefCover, onEditAsset: onEditAssetCover } =
    useEditAssetOfCommercialEvent('coverUrl', commercialEvent);

  const { editorRef: editorRefLargeCover, onEditAsset: onEditAssetLargeCover } =
    useEditAssetOfCommercialEvent('largeCoverUrl', commercialEvent);

  const { editorRef: editorRefLargeBanner, onEditAsset: onEditAssetLargeBanner } =
    useEditAssetOfCommercialEvent('largeBannerUrl', commercialEvent);

  const { editorRef: editorRefIcon, onEditAsset: onEditAssetIcon } = useEditAssetOfCommercialEvent(
    'iconUrl',
    commercialEvent,
  );

  const activityLogs = useActivityLogs({
    entity: AvailableEntityForComment.commercial_event,
    entityId: commercialEventId,
  });

  if (!commercialEvent) return null;

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'} rowGap={2}>
          <Stack spacing={2} direction="row" ml="auto" pr={1} pl={1} alignItems="center">
            {canEdit && <EditButton to={`/commercial-event/${commercialEventId}/edit`} />}
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12}>
            <PanelDetails<CommercialEvent>
              title={t('databaseExplorer.panel.main')}
              informations={panelInformation}
              data={commercialEvent}
              loading={loading}
            />
          </Grid>
          <Grid xs={12}>
            <div className="bg-white rounded overflow-hidden">
              <ActivityLogs
                logs={activityLogs.data}
                isLoading={activityLogs.isLoading}
                entityId={commercialEventId}
                entityName={AvailableEntityForComment.show}
              />
            </div>
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <Grid xs={12}>
            <PanelDetailImage
              title={t('databaseExplorer.commercialEvent.coverUrl')}
              thumbnailUrl={commercialEvent.coverUrl || ''}
              alt={commercialEvent.name}
              onEdit={canEdit ? onEditAssetCover : undefined}
              maxSize={MAX_FILE_SIZE}
              imageAcceptedMimeTypes={{ 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }}
              ref={editorRefCover}
            />
          </Grid>
          <Grid xs={12}>
            <PanelDetailImage
              title={t('databaseExplorer.commercialEvent.largeCoverUrl')}
              thumbnailUrl={commercialEvent.largeCoverUrl || ''}
              alt={commercialEvent.name}
              onEdit={canEdit ? onEditAssetLargeCover : undefined}
              maxSize={MAX_FILE_SIZE}
              imageAcceptedMimeTypes={{ 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }}
              ref={editorRefLargeCover}
            />
          </Grid>

          <Grid xs={12}>
            <PanelDetailImage
              title={t('databaseExplorer.commercialEvent.bannerUrl')}
              thumbnailUrl={commercialEvent.bannerUrl || ''}
              alt={commercialEvent.name}
              onEdit={canEdit ? (image) => onEditAssetBanner(image) : undefined}
              maxSize={MAX_FILE_SIZE}
              imageAcceptedMimeTypes={{ 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }}
              ref={editorRefBanner}
            />
          </Grid>

          <Grid xs={12}>
            <PanelDetailImage
              title={t('databaseExplorer.commercialEvent.largeBannerUrl')}
              thumbnailUrl={commercialEvent.largeBannerUrl || ''}
              alt={commercialEvent.name}
              onEdit={canEdit ? onEditAssetLargeBanner : undefined}
              maxSize={MAX_FILE_SIZE}
              imageAcceptedMimeTypes={{ 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }}
              ref={editorRefLargeBanner}
            />
          </Grid>
          <Grid xs={12}>
            <PanelDetailImage
              title={t('databaseExplorer.commercialEvent.iconUrl')}
              thumbnailUrl={commercialEvent.iconUrl || ''}
              alt={commercialEvent.name}
              onEdit={canEdit ? onEditAssetIcon : undefined}
              maxSize={MAX_FILE_SIZE}
              imageAcceptedMimeTypes={{ 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }}
              ref={editorRefIcon}
            />
          </Grid>
          <Grid xs={12}>
            <PanelDetails<CommercialEvent>
              title={t('databaseExplorer.panel.start')}
              informations={statusPanelInformation}
              data={commercialEvent}
              loading={loading}
              labelRow
            />
          </Grid>
          <Grid xs={12}>
            <PanelDetails<CommercialEvent>
              title={t('databaseExplorer.panel.timestamp')}
              informations={timestampPanelInformation}
              data={commercialEvent}
              loading={loading}
              labelRow
            />
          </Grid>
        </Grid>
      </Grid>
    </DetailsLayout>
  );
};
