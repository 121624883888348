import { Promotion } from '@bits-app/voggtpit-shared';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@/components/elements/buttons/LoadingButton';

type ConfirmPromotionExpirationProps = {
  open: boolean;
  promotionName: Promotion['name'];
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmPromotionExpiration = ({
  onConfirm,
  onClose,
  open,
  promotionName,
  loading,
}: ConfirmPromotionExpirationProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('promotion.expiration.confirmation.title')}</DialogTitle>

      <DialogContent>
        {t('promotion.expiration.confirmation.content', { name: promotionName })}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <LoadingButton loading={loading} onClick={onConfirm} variant="contained" color="error">
          {t('promotion.expiration.confirmation.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
