import { OrderCancellationReason, OrderStatus } from '@bits-app/voggtpit-shared';
import { t } from 'i18next';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { AppThunkAction } from '@/redux/store';

import { selectOrderItemsMassUpdate } from '../redux/order-items-mass-update.selectors';
import { orderItemsMassUpdateActions } from '../redux/order-items-mass-update.slice';

export const massUpdateOrderItemsShippingStatus =
  ({
    ids,
    shippingStatus,
    cancellationReason,
  }: {
    ids: number[];
    shippingStatus: OrderStatus;
    cancellationReason?: OrderCancellationReason;
  }): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { orderItemsMassUpdateGateway, snackbarGateway }) => {
    dispatch(orderItemsMassUpdateActions.setUpdating(true));

    try {
      await orderItemsMassUpdateGateway.updateOrderItemsShippingStatus(
        ids,
        shippingStatus,
        cancellationReason,
      );

      const orderItems = selectOrderItemsMassUpdate(getState());

      dispatch(
        orderItemsMassUpdateActions.setEntities(
          orderItems.map((orderItem) => {
            if (ids.includes(orderItem.id)) {
              const order = {
                ...orderItem,
                shippingStatus,
              };

              if (cancellationReason) {
                return {
                  ...order,
                  cancellationReason,
                };
              }

              return order;
            }

            return orderItem;
          }),
        ),
      );

      snackbarGateway.success('orderItemsMassUpdate.successfulUpdate', {
        interpolationMap: {
          count: ids.length,
          value: shippingStatus,
          label: t('orderItemLabels.shippingStatus') as string,
        },
      });
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(orderItemsMassUpdateActions.setUpdating(false));
    }
  };
