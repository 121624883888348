import { useCallback, useEffect } from 'react';

import { Category } from '@bits-app/bits-server-data';
import { useMutation } from '@tanstack/react-query';
import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { FieldError } from '@/domain/errors/FieldError';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { useAppDispatch } from '@/redux/reduxAppHooks';
import { useImageUpload } from '@/voggt-database-explorer/components/ImageUpload/use-image-upload.hook';
import { databaseExplorerAction } from '@/voggt-database-explorer/redux/database-explorer.slice';

import { FieldValue } from '../../../components/upsert-category/UpsertCategoryForm';
import { createCategory } from '../../../components/upsert-category/create-category';
import { editCategory } from '../../../components/upsert-category/edit-category';

export const useUpsertCategoryForm = ({
  category,
  setFieldError,
  resetForm,
  afterSuccess,
}: {
  category?: Category;
  resetForm: () => void;
  afterSuccess: (category: Category) => void;
  setFieldError: UseFormSetError<FieldValue>;
}) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const dispatch = useAppDispatch();

  const upsertType = category ? 'update' : 'create';

  const { clearImage, image, imageErrors, onDropImage, setImageErrors } = useImageUpload('400Kb');

  useEffect(() => {
    return () => {
      if (!image) {
        return;
      }

      URL.revokeObjectURL(image.preview);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetAllForm = () => {
    clearImage();
    setImageErrors(null);
    resetForm();
  };

  const onSubmit = async (data: FieldValue) => {
    if (!category && !image) {
      setImageErrors((errors) => [
        ...(errors ?? []),
        {
          code: 'file-is-required',
          message: t(`databaseExplorer.category.upsertCategory.form.errors.required`, {
            field: t(`databaseExplorer.category.upsertCategory.form.image`),
          }),
        },
      ]);

      return;
    }

    const formData = new FormData();

    if (image) {
      formData.append('file', image);
    }

    formData.append('name', data.name);

    formData.append('parentCategoryId', `${data.parentCategoryId}`);

    if (data.description) {
      formData.append('description', data.description);
    }

    if (data.colorAccent) {
      formData.append('colorAccent', data.colorAccent);
    }

    formData.append('isVisible', `${data.isVisible}`);

    await mutate(formData);
  };

  const upsertCategory = useCallback(
    async (formData: FormData) => {
      if (!category) {
        return createCategory(formData);
      }

      return editCategory(formData, category.id);
    },
    [category],
  );

  const { mutate, isLoading } = useMutation<Category, unknown, FormData>(upsertCategory, {
    onSuccess: (upsertedCategory) => {
      snackbar.success(`databaseExplorer.category.upsertCategory.${upsertType}.success`, {
        interpolationMap: { name: upsertedCategory.name },
      });

      if (!category) {
        queryClient.invalidateQueries(QUERIES_KEYS.categoriesList());
      } else {
        dispatch(databaseExplorerAction.setData(upsertedCategory));
      }

      resetAllForm();
      afterSuccess(upsertedCategory);
    },
    onError: (error) => {
      snackbar.error(`databaseExplorer.category.upsertCategory.form.errors.generic`);

      if (error instanceof FieldError) {
        switch (error.field) {
          case 'name':
          case 'description':
          case 'parentCategoryId':
          case 'colorAccent':
          case 'isVisible':
            setFieldError(error.field, { message: error.message });
            break;

          case 'image':
            setImageErrors((errors) => [
              ...(errors ?? []),
              {
                code: 'server-error',
                message: error.message,
              },
            ]);
            break;
        }
      }
    },
  });

  return {
    isLoading,
    image,
    imageErrors,
    onDropImage,
    onSubmit,
    resetAllForm,
  };
};
