import { Grid, Link, Typography } from '@mui/material';
import { Link as LinkReactRouterDom } from 'react-router-dom';

import { LinkRedirectionProps } from '../datalist-type';

export const LinkRedirection = ({
  type,
  link,
  label,
  icon,
}: LinkRedirectionProps): React.ReactElement | null => {
  if (!link)
    return (
      <Grid container alignItems="center" gap={1}>
        {icon}
        <Typography>{label}</Typography>
      </Grid>
    );

  const isExternalLink = type === 'external-link';
  return (
    <Link
      onClick={(ev) => ev.stopPropagation()}
      sx={{
        color: ({ palette }) => palette.info.dark,
        fontWeight: 'bold',
      }}
      target={isExternalLink ? '_blank' : ''}
      component={isExternalLink ? 'a' : LinkReactRouterDom}
      to={link}
      href={link}
    >
      {label}
    </Link>
  );
};
