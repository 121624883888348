import { RefundReason } from '@bits-app/voggtpit-shared';

import { AppThunkAction } from '@/redux/store';

import { getDefaultRefundForBadProtection } from './get-default-refund-for-bad-protection';
import { getDefaultRefundForChangeOfMind } from './get-default-refund-for-change-of-mind';
import { getDefaultRefundForCounterfeit } from './get-default-refund-for-counterfeit';
import { getDefaultRefundForDamagedProduct } from './get-default-refund-for-damaged-product';
import { getDefaultRefundForDelight } from './get-default-refund-for-delight';
import { getDefaultRefundForDeliveredByHand } from './get-default-refund-for-delivered-by-hand';
import { getDefaultRefundForFraud } from './get-default-refund-for-fraud';
import { getDefaultRefundForFreeShipping } from './get-default-refund-for-free-shipping';
import { getDefaultRefundForLostParcel } from './get-default-refund-for-lost-parcel';
import { getDefaultRefundForMisclick } from './get-default-refund-for-misclick';
import { getDefaultRefundForMissingProduct } from './get-default-refund-for-missing-product';
import { getDefaultRefundForOthers } from './get-default-refund-for-others';
import { getDefaultRefundForProductLostBeforeShipping } from './get-default-refund-for-product-lost-before-shipping';
import { getDefaultRefundForSellerMistake } from './get-default-refund-for-seller-mistake';

export const getDefaultRefundStateForReason =
  (reason: RefundReason): AppThunkAction<void> =>
  (dispatch) => {
    switch (reason) {
      case RefundReason.misclick:
        dispatch(getDefaultRefundForMisclick());
        break;

      case RefundReason.lostParcel:
        dispatch(getDefaultRefundForLostParcel());
        break;

      case RefundReason.deliveredByHand:
        dispatch(getDefaultRefundForDeliveredByHand());
        break;

      case RefundReason.freeShipping:
        dispatch(getDefaultRefundForFreeShipping());
        break;

      case RefundReason.changeOfMind:
        dispatch(getDefaultRefundForChangeOfMind());
        break;

      case RefundReason.missingProduct:
        dispatch(getDefaultRefundForMissingProduct());
        break;

      case RefundReason.sellerMistake:
        dispatch(getDefaultRefundForSellerMistake());
        break;

      case RefundReason.damagedProduct:
        dispatch(getDefaultRefundForDamagedProduct());
        break;

      case RefundReason.badProtection:
        dispatch(getDefaultRefundForBadProtection());
        break;

      case RefundReason.productLostBeforeShipping:
        dispatch(getDefaultRefundForProductLostBeforeShipping());
        break;

      case RefundReason.counterfeit:
        dispatch(getDefaultRefundForCounterfeit());
        break;

      case RefundReason.delight:
        dispatch(getDefaultRefundForDelight());
        break;

      case RefundReason.others:
        dispatch(getDefaultRefundForOthers());
        break;

      case RefundReason.fraud:
        dispatch(getDefaultRefundForFraud());
        break;

      default:
        throw new Error(`Unknown refund reason: ${reason}`);
    }
  };
