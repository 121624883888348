import { useState } from 'react';

import { SentinelFraudCase, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { useOwnUser } from '../../../context/own-user.context';
import { useSentinelList } from '../../../pages/sentinel/hooks/useSentinelList';
import { QUERIES_KEYS, queryClient } from '../../../queries';
import { User } from '../users';

type UseAddToSentinelProps = {
  userId: User['id'];
};

type AddUserToSentinelRequest = {
  userId: User['id'];
  sentinelFraudCaseId: SentinelFraudCase['id'];
};

export const useAddToSentinel = ({ userId }: UseAddToSentinelProps) => {
  const { hasPermissions } = useOwnUser();

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const { mutate, isLoading: mutateIsLoading } = useMutation(
    (sentinelFraudCaseId: SentinelFraudCase['id']) =>
      addUserToSentinelRequest({ userId, sentinelFraudCaseId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES_KEYS.sentinelList());
        queryClient.invalidateQueries(QUERIES_KEYS.userDetail(userId));
      },
    },
  );

  const { data, isLoading } = useSentinelList();

  const userIsAllowed = hasPermissions(PERMISSIONS.SENTINEL.WRITE_SENTINEL_ADD_USER_TO_FRAUD_CASE);

  return {
    userIsAllowed,
    mutate,
    sentinelFraudCases: data?.data ?? [],
    modalIsOpen,
    toggleModalIsOpen: () => setModalIsOpen((prev) => !prev),
    isLoading: isLoading || mutateIsLoading,
  };
};

const addUserToSentinelRequest = ({ userId, sentinelFraudCaseId }: AddUserToSentinelRequest) => {
  return authenticatedInstance.post(`/sentinel/${sentinelFraudCaseId}/add-user`, { userId });
};
