import { useCallback, useEffect, useMemo, useState } from 'react';

import { createColumnHelper, OnChangeFn, SortingState } from '@tanstack/react-table';
import { format } from 'date-fns';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { useNavigate } from 'react-router-dom';

import { useSearchVoggtPayloadType } from '@/hooks/use-typesense/use-typesense';

import { useTypesenseSearch } from '../../../hooks/use-typesense';
import { ChipsCell } from '../DataList/Cell/ChipsCell';
import { CellDefinition, DataListType } from '../DataList/datalist-type';
import { Dropdown } from '../Dropdown';
import { Filtering } from '../Filtering';
import { Table } from '../Table';
import { WarningTooltip } from '../WarningTooltip';

export type ListWithSearchProps<T extends Record<string, unknown>> = {
  cellDefinition: CellDefinition<T>[];
  typesenseHookParams: useSearchVoggtPayloadType<T>;
  title?: string;
  dataListProps?:
    | Pick<
        DataListType<T>,
        | 'children'
        | 'getPathDetail'
        | 'title'
        | 'defaultHiddenColumns'
        | 'enableRowSelection'
        | 'enableFilters'
        | 'customSelectedRowsAction'
      >
    | ((props: {
        fetchData: (controller: AbortController) => void;
      }) => Pick<
        DataListType<T>,
        | 'children'
        | 'getPathDetail'
        | 'title'
        | 'defaultHiddenColumns'
        | 'enableRowSelection'
        | 'enableFilters'
        | 'customSelectedRowsAction'
      >);
  customAction?: React.ReactElement;
  flatElevation?: boolean;
  onSearchChange?: (value: string) => void;
  getPathDetail?: (data: T) => string;
  manualPagination?: boolean;
  disableAutoFocus?: boolean;
  defaultFilters?: { field: string; values: string[] }[];
  isUserList?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const columnHelper = createColumnHelper<any>();

export function ListWithSearch<T extends { id: string }>({
  typesenseHookParams,
  cellDefinition,
  customAction,
  onSearchChange,
  defaultFilters = [],
  disableAutoFocus,
  title,
  isUserList = false,
}: ListWithSearchProps<T>) {
  const navigate = useNavigate();
  const nbRows = Math.min(Math.floor((window.innerHeight - 300) / 40), 40);
  const [pagination, setPagination] = useState({ page: 1, pageSize: nbRows });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filterBy, setFilterBy] = useState('');
  const filters = defaultFilters.map((filter) => `${filter.field}:${filter.values[0]}`).join('&&');

  const { typesenseParams, typesenseIndex } = typesenseHookParams;

  const typesenseParamsMemoized = useMemo(() => {
    let sortBy;
    if (sorting.length > 0) {
      const currentSortField = sorting[0].id;
      const sortableColumn = cellDefinition.find(
        (cell) => cell.id === currentSortField && cell.enableSorting === true,
      );
      if (sortableColumn) {
        sortBy = `${currentSortField}:${sorting[0].desc ? 'desc' : 'asc'}`;
      }
    }

    return {
      filterBy: filters || filterBy,
      typesenseParams: {
        ...typesenseParams,
        page: pagination.page,
        per_page: pagination.pageSize,
        sort_by: sortBy,
      },
      typesenseIndex,
    };
  }, [filters, filterBy, typesenseParams, pagination, sorting, cellDefinition, typesenseIndex]);

  const { results, ownUserSearch, wholeResult, setSearch } =
    useTypesenseSearch<T>(typesenseParamsMemoized);

  useEffect(() => onSearchChange && onSearchChange(ownUserSearch), [ownUserSearch, onSearchChange]);

  const columns = cellDefinition.map(({ id, label, type, getLink, enableSorting }) =>
    columnHelper.accessor(id.toString(), {
      cell: (info) => {
        const value = info.renderValue();
        const linkGetter = getLink?.(info.row.original);
        const openRow = () => onRowClick(info.row.original.id);
        const { label, link } = linkGetter || {};

        try {
          if (!value)
            return (
              <div onClick={openRow} className="py-2 px-3 cursor-pointer">
                <p className="text-slate-300">-</p>
              </div>
            );

          if (type === 'money')
            return (
              <div onClick={openRow} className="py-2 px-3 cursor-pointer">
                {value / 100 + '€'}
              </div>
            );

          if (type === 'date-with-time')
            return (
              <div onClick={openRow} className="py-2 px-3 cursor-pointer">
                {format(new Date(parseInt(value, 10)), 'dd/MM/yyyy HH:mm')}
              </div>
            );

          if (type === 'internal-link' && link)
            return (
              <div className="py-2 px-3">
                <a
                  href={link}
                  className="text-sky-600 cursor-pointer hover:text-sky-800 hover:underline"
                >
                  {label}
                </a>
              </div>
            );
          if (type === 'external-link' && link)
            return (
              <div className="py-2 px-3">
                <a
                  href={link}
                  target="_blank"
                  className="text-sky-600 cursor-pointer hover:text-sky-800 hover:underline"
                >
                  {label}
                </a>
              </div>
            );

          if (type === 'badge')
            return (
              <div onClick={openRow} className="py-2 px-3 cursor-pointer">
                <ChipsCell value={value} />
              </div>
            );

          return (
            <div
              onClick={openRow}
              className="py-2 px-3 cursor-pointer overflow-hidden text-ellipsis"
            >
              {value}
            </div>
          );
        } catch (error) {
          console.error(error);

          return (
            <div
              onClick={openRow}
              className="py-2 px-3 cursor-pointer overflow-hidden text-ellipsis"
            >
              {value}
            </div>
          );
        }
      },
      id: id.toString(),
      header: label,
      enableSorting,
    }),
  );

  const dropdownOptions = [{ label: 'Export to CSV', onClick: () => onExportToCSV(results) }];
  const maxPages = Math.ceil((wholeResult?.found || 0) / pagination.pageSize);

  const onNextPage = () =>
    pagination.page < maxPages && setPagination({ ...pagination, page: pagination.page + 1 });
  const onPreviousPage = () =>
    pagination.page > 1 && setPagination({ ...pagination, page: pagination.page - 1 });
  const onRowClick = (rowId: string | number) => navigate(`${rowId}`);
  const onExportToCSV = (results: T[]) => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const csv = generateCsv(csvConfig)(results);
    download(csvConfig)(csv);
  };

  const handleSortingChange = useCallback<OnChangeFn<SortingState>>(
    (updaterOrValue) => {
      const newSorting =
        typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;

      setSorting(newSorting);
      setPagination((prev) => ({ ...prev, page: 1 }));
    },
    [sorting],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  };

  return (
    <>
      <div className="pb-4 flex justify-between flex-col lg:items-center lg:flex-row">
        <h1 className="flex-1 lg:w-full text-xl text-slate-800 font-semibold mb-4 lg:mb-0">
          {title} ({wholeResult?.found})
        </h1>

        <div className="flex flex-col lg:items-center gap-2 lg:flex-row">{customAction}</div>
      </div>

      <div className="flex gap-2 pb-4 items-center">
        <input
          type="search"
          placeholder="Search"
          autoFocus={!disableAutoFocus}
          onChange={handleSearchChange}
          className="flex-1 w-full lg:flex-initial border border-slate-300 rounded-md px-4 py-2.5 text-sm focus:ring-2 focus:outline-none focus:ring-sky-300"
        />

        {!filters && <Filtering columns={columns} setFilterBy={setFilterBy} />}

        <Dropdown title="Actions" options={dropdownOptions} />
      </div>

      {isUserList && (
        <WarningTooltip warningText="databaseExplorer.users.searchUserByPhoneNumberWarning" />
      )}

      <Table
        data={results}
        columns={columns}
        pagination={pagination}
        onRowClick={onRowClick}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        page={pagination.page}
        perPage={pagination.pageSize}
        sorting={sorting}
        onSortingChange={handleSortingChange}
      />
    </>
  );
}
