import { useMemo } from 'react';

import { centsToUnit, HeaderDescriptor, Schema } from '@bits-app/voggtpit-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Avatar,
  Chip,
  FormHelperText,
  Grid,
  Link,
  Paper,
  Skeleton,
  Typography,
  Box,
} from '@mui/material';
import _pick from 'lodash/pick';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { EntityWrapper } from '@/voggt-database-explorer/redux/database-explorer';
import { Button } from '@Elements/buttons/Button';

import { formatDate } from '../../../utils';

import { DynamicControl } from './DynamicControl';

// didn't yet manage to better type the returned of the function because of conflict
// with defaultValues in useForm
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDefaultValuesFromSchemas = <T extends EntityWrapper>(schemas: Schema<T>[]): any => {
  let result: Partial<T> = {};

  for (const { schema } of schemas) {
    const fields = schema.reduce((values, { inputType, value, field }) => {
      if (inputType === 'amount') {
        let amount = value;

        if (value == null) {
          amount = 0;
        }

        return {
          ...values,
          [field]: centsToUnit(amount as number),
        };
      }

      if (inputType === 'date') {
        const formattedDateValue =
          typeof value === 'string' || value instanceof Date
            ? formatDate(new Date(value), 'yyyy-MM-dd')
            : undefined;

        return {
          ...values,
          [field]: formattedDateValue,
        };
      }

      if (inputType === 'date-with-time') {
        const formattedDateTimeValue =
          typeof value === 'string' || value instanceof Date
            ? formatDate(new Date(value), 'yyyy-MM-dd HH:mm:ss')
            : undefined;
        return {
          ...values,
          [field]: formattedDateTimeValue,
        };
      }

      return {
        ...values,
        [field]: value,
      };
    }, {} as Partial<T>);

    result = {
      ...result,
      ...fields,
    };
  }

  return result;
};

type UpsertFormProps<T extends EntityWrapper> = {
  schemas: Schema<EntityWrapper>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchemaBuilder?: (fields: string[]) => yup.ObjectSchema<any>;
  header?: HeaderDescriptor;
  onUpsert: (data: Partial<T>) => void;
  goBackName?: string;
};

export const UpsertForm = <T extends EntityWrapper>({
  header,
  schemas,
  validationSchemaBuilder,
  onUpsert,
  goBackName,
}: UpsertFormProps<T>) => {
  const location = useLocation();
  const isCreation = location.pathname.includes('/create');

  const values = useMemo(() => getDefaultValuesFromSchemas(schemas), [schemas]);

  const formMethods = useForm<Partial<T>>({
    defaultValues: useMemo(() => values, [values]),
    ...(validationSchemaBuilder
      ? { resolver: yupResolver(validationSchemaBuilder(Object.keys(values))) }
      : {}),
  });

  const { t } = useTranslation();

  const previousPagePath = useMemo(
    () => location.pathname.replace(/\/(edit|create).*/, ''),
    [location.pathname],
  );

  const onSubmit = (data: Partial<T>) => {
    if (isCreation) {
      onUpsert(data);
      return;
    }

    const updatedFields = _pick(data, Object.keys(formMethods.formState.dirtyFields));

    if (Object.keys(updatedFields).length <= 0) {
      return;
    }

    onUpsert(updatedFields as Partial<T>);
  };

  const isSaveDisabled = useMemo(() => {
    if (isCreation) {
      return false;
    }

    return !formMethods.formState.isDirty;
  }, [formMethods.formState.isDirty, isCreation]);

  return (
    <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
        }}
      >
        {goBackName && (
          <div>
            <Link
              component={RouterLink}
              to={previousPagePath}
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
              <ArrowBackIcon />
              {goBackName}
            </Link>
          </div>
        )}

        {header && (
          <Box sx={{ my: 4 }}>
            <Header {...header} />
          </Box>
        )}

        <FormProvider {...formMethods}>
          <Paper sx={{ p: 4, flex: 1, overflow: 'hidden' }}>
            <Box
              component="form"
              onSubmit={formMethods.handleSubmit(onSubmit)}
              sx={{
                overflow: 'hidden',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                item
                direction="row"
                spacing={4}
                flex={1}
                sx={{ overflow: 'auto', pb: 1 }}
              >
                {schemas.map(({ categoryLabel, schema, fullWidth }: Schema<T>) => (
                  <CategoryForm title={categoryLabel} fullWidth={fullWidth} key={categoryLabel}>
                    <Grid container item spacing={2} key={categoryLabel}>
                      {schema.map((inputDescriptor) => (
                        <Grid
                          item
                          key={inputDescriptor.field as string}
                          md={inputDescriptor.fullWidth ? 12 : 6}
                          xs={12}
                        >
                          {schemas.length === 0 ? (
                            <Skeleton />
                          ) : (
                            <>
                              <DynamicControl
                                inputType={inputDescriptor.inputType}
                                field={inputDescriptor.field}
                                label={inputDescriptor.label}
                                value={values[inputDescriptor.field] ?? undefined}
                                options={inputDescriptor.options}
                                extraData={inputDescriptor.extraData}
                                disabled={inputDescriptor.disabled}
                              />

                              {formMethods.formState.errors[inputDescriptor.field] && (
                                <FormHelperText error>
                                  {
                                    formMethods.formState.errors[inputDescriptor.field]
                                      ?.message as string
                                  }
                                </FormHelperText>
                              )}
                            </>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </CategoryForm>
                ))}
              </Grid>

              <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
                <Button variant="contained" type="submit" disabled={isSaveDisabled}>
                  {t('action.save')}
                </Button>

                <Button component={RouterLink} to={previousPagePath}>
                  {t('cancel')}
                </Button>
              </Box>
            </Box>
          </Paper>
        </FormProvider>
      </Box>
    </Box>
  );
};

const Header = ({ title, imageUrl, identifier }: HeaderDescriptor) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      {imageUrl && <Avatar src={imageUrl} />}
      <Box>
        <Typography variant="h4" sx={{ wordBreak: 'break-all' }}>
          {title}
        </Typography>
        {identifier && (
          <Typography fontWeight={600} component="div">
            id: <Chip label={identifier} size="small" />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

type CategoryFormProps = {
  title: string;
  fullWidth?: boolean;
  children: React.ReactNode;
};

const CategoryForm = ({ title, fullWidth, children }: CategoryFormProps) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" item spacing={2} xl={fullWidth ? 12 : 6} lg={12}>
      <Grid item component={Typography} variant="h6" fontWeight="bold">
        {t(title)}
      </Grid>

      <Grid item>{children}</Grid>
    </Grid>
  );
};
