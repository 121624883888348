import { AppThunkAction } from '@/redux/store';

import { refundActions } from '../redux/refund.slice';

export const clearRefundOrderItem = (): AppThunkAction<void> => (dispatch) => {
  dispatch(refundActions.setMainOrderItemId(null));
  dispatch(refundActions.setEntities([]));
  dispatch(refundActions.resetRefundForm());
  dispatch(refundActions.setRefundError(null));
};
