import { forwardRef } from 'react';

import { filledInputClasses, outlinedInputClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MuiColorInput, MuiColorInputProps } from 'mui-color-input';

import { neutral } from '@/theme/colors';

// typed as any here because of typing inference issue with styled and mui-color-input
// but exporting the react function component with correct props (excluding styled props)
// allows to use the MuiColorInput component with expected props and custom styled to match theme
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledColorInput: any = styled(MuiColorInput)(({ theme }) => ({
  ['&.MuiColorInput-TextField']: {
    ['& .MuiOutlinedInput-root']: {
      borderRadius: theme.shape.borderRadius,
      fontSize: 14,
      color: theme.palette.text.primary,
      lineHeight: '24px',
      '&::placeholder': {
        opacity: 1,
        color: theme.palette.text.secondary,
      },
      '& fieldset': {
        borderColor: neutral[200],
      },
      [`&.${outlinedInputClasses.focused}`]: {
        backgroundColor: 'transparent',
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.primary.main,
        },
      },
      [`&.${filledInputClasses.error}`]: {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.error.main,
          boxShadow: `${theme.palette.error.main} 0 0 0 2px`,
        },
      },
    },
    ['& .Mui-focused.MuiFormLabel-root.MuiInputLabel-outlined']: {
      color: theme.palette.primary.main,
    },
  },
}));

export const ColorInput = forwardRef<HTMLDivElement, MuiColorInputProps>((props, ref) => (
  <StyledColorInput ref={ref} {...props} />
));
