import { UserTargetPromotion } from '@bits-app/bits-server-data';
import { Promotion, promotionDtoToEntity, PromotionsFindOptions } from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import { BaseHTTPGateway } from '@/domain/base-http-gateway';

import { CreatePromotionDto, PromotionsPort, UploadUsersToPromotionDto } from './promotions.port';

export class HttpPromotionsAdapter extends BaseHTTPGateway implements PromotionsPort {
  async getPromotions(
    findOptions: PromotionsFindOptions,
    options?: { abortPrevious: boolean },
  ): Promise<{ items: Array<Promotion>; count: number }> {
    const signal = this.handleAbortPrevious(options?.abortPrevious ?? false, 'getPromotions');

    const result = await authenticatedInstance.get('/promotions', {
      params: findOptions,
      signal,
    });

    return { items: result.data.items.map(promotionDtoToEntity), count: result.data.count };
  }

  async createPromotion(data: CreatePromotionDto): Promise<Promotion> {
    try {
      const result = await authenticatedInstance.post('/promotions', data);

      return promotionDtoToEntity(result.data);
    } catch (error) {
      throw new Error(`Something wrong happened: ${error}`);
    }
  }

  async uploadUsersToPromotion(
    promotionId: Promotion['id'],
    data: UploadUsersToPromotionDto,
  ): Promise<Promotion> {
    try {
      // Actually we do nothing about users into a promotion
      const result = await authenticatedInstance.post(`/promotions/${promotionId}/users`, data);

      return promotionDtoToEntity(result.data);
    } catch (error) {
      throw new Error(`Something wrong happened: ${error}`);
    }
  }

  async expirePromotion(id: Promotion['id']): Promise<Promotion> {
    const result = await authenticatedInstance.patch(`/promotions/${id}/expire`);
    return promotionDtoToEntity(result.data);
  }

  async deleteUserTargetToPromotion(userTargetPromotionId: UserTargetPromotion['id']) {
    await authenticatedInstance.delete<boolean>(
      `/promotions/user-target-promotion/${userTargetPromotionId}`,
    );
  }
}
