import { useReducer } from 'react';

import { Show } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { useSnackbar } from '../../../components/elements/snackbar/use-snackbar';
import { useOwnUser } from '../../../context/own-user.context';

export const useRefundShow = (showId: Show['id']) => {
  const [modalIsOpen, toggleModalIsOpen] = useReducer((state) => !state, false);
  const snackbar = useSnackbar();
  const { hasPermissions } = useOwnUser();
  const isAuthorized = hasPermissions(PERMISSIONS.REFUND_SHOW.WRITE_REFUND_SHOW);

  const { mutate: process, isLoading } = useMutation(
    async () => {
      if (!isAuthorized) return Promise.reject();
      const response = await authenticatedInstance.post(`/refund/show/${showId}`);
      return response.data;
    },
    {
      onSuccess: () => {
        snackbar.success('databaseExplorer.show.refund.action');
      },
      onError: () => {
        snackbar.error('somethingWrongHappened');
      },
    },
  );

  return {
    isLoading,
    modalIsOpen,
    toggleModalIsOpen,
    process,
    isAuthorized,
  };
};
