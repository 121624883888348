import {
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryColumn,
} from 'typeorm';

import { Category } from './Category';
import { User } from './User';

@Entity()
export class UserFollowCategory {
  @Index()
  @PrimaryColumn()
  categoryId!: number;

  @Index()
  @PrimaryColumn()
  userId!: number;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @ManyToOne(() => Category, (category) => category.userFollowCategory)
  @JoinColumn({
    name: 'categoryId',
    referencedColumnName: 'id',
  })
  category!: Category;

  @ManyToOne(() => User, (user) => user.userFollowCategory)
  @JoinColumn({
    name: 'userId',
    referencedColumnName: 'id',
  })
  user!: User;
}
