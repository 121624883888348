import { useTranslation } from 'react-i18next';

import { AddFilterFunctionParams } from '../../../../hooks/use-table-options';

import { OrderItemsMassUpdateFreeFilter } from './OrderItemsMassUpdateFreeFilter';

type SellerNameProps = {
  activeFilters?: string[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
  disabled?: boolean;
};

export const SellerNameFilter = ({
  activeFilters,
  addFilter,
  isUnsaved,
  disabled,
}: SellerNameProps) => {
  const { t } = useTranslation();

  return (
    <OrderItemsMassUpdateFreeFilter
      addFilter={addFilter}
      field="seller"
      isValueValid={isSellerNameValid}
      inputPlaceholder={t('orderItemsMassUpdate.searchFilter.seller')}
      activeFilters={activeFilters}
      isUnsaved={isUnsaved}
      disabled={disabled}
    />
  );
};

const isSellerNameValid = (value: string) => {
  return value.length > 0;
};
