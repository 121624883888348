import { StreamingAsset } from '@bits-app/voggtpit-shared';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ContainerDataExplorer } from '@/components/elements/DataExplorer/ContainerDataExplorer';
import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { CellAssetMuxDefinition } from '@/mux/mux.const';

import { MuxAssetsDetails } from './MuxAssetsDetails';
import { useMuxContext } from './mux.context';

export const MuxAssetsTab = () => {
  const { onDrawerToggle, muxAssets } = useMuxContext();
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', gap: '20px' }}>
      <Box sx={{ width: 'calc(100% / 3 * 2)' }}>
        <ListCustom<StreamingAsset>
          cellDefinition={CellAssetMuxDefinition}
          results={muxAssets}
          title={t('databaseExplorer.panel.mux-assets')}
          enableGlobalFilter={false}
          onClickOnRow={(streamingAsset) => onDrawerToggle(streamingAsset.id)}
        />
      </Box>

      <Box sx={{ width: 'calc(100% / 3 * 1)', display: { fullScreen: 'none' } }}>
        <ContainerDataExplorer>
          <MuxAssetsDetails />
        </ContainerDataExplorer>
      </Box>
    </Box>
  );
};
