import * as yup from 'yup';

import { SchemaValidation } from './validation-schema';

export class CommercialEventValidationSchema extends SchemaValidation {
  availableResolver = {
    name: this.resolveName(),
    startAt: this.resolveStartAt(),
    endAt: this.resolveEndAt(),
  };

  resolveName(): yup.ISchema<any> {
    return yup.string().required();
  }

  resolveStartAt(): yup.ISchema<any> {
    return yup.date().required();
  }

  resolveEndAt(): yup.ISchema<any> {
    return yup.date().required();
  }
}
