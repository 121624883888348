type PanelProps = {
  title: string;
  actionName?: string;
  children?: React.ReactNode;
  skipPadding?: boolean;
  onClickOnAction?: () => void;
};

export const Panel = ({
  title,
  children,
  onClickOnAction,
  actionName = '',
  skipPadding,
}: PanelProps) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden w-full border border-slate-200">
      <div
        className={`flex justify-between items-center p-4 border-b border-slate-200 ${
          !skipPadding && 'mb-4'
        }`}
      >
        <h2 className="text-base">{title}</h2>

        {onClickOnAction && (
          <button className="text-sm text-blue-500 cursor-pointer" onClick={onClickOnAction}>
            {actionName}
          </button>
        )}
      </div>

      <div className={skipPadding ? '' : 'px-4 pb-4'}>{children}</div>
    </div>
  );
};
