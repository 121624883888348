// We have 2 providers today, Boxtal and Sendcloud. The only pattern that differenciate their id is the string lenght (Boxtal - 20, Sendcloud - 8)
// The first one can start with both letters and numbers while the second one always starts with letters
// And we do not retreive the info if it's handled by one or the other here.

import { Shipment } from '@bits-app/bits-server-data';

const BOXTAL_LINK_FORMAT_LENGTH = 20;

export const getExternalProviderShipmentLink = (externalShippingOrderId: string) => {
  if (externalShippingOrderId.length === BOXTAL_LINK_FORMAT_LENGTH)
    return `https://shipping.boxtal.com/fr/fr/app/centrale-expeditions/recherche/${externalShippingOrderId}?orderType=READY_TO_SEND`;

  return `https://app.sendcloud.com/v2/shipping/${externalShippingOrderId}/details`;
};

export type ShipmentsWithTotalAmounts = Shipment & {
  totalProductsAmount: number;
  totalShippingFeesAmount: number;
};
