import {
  Column,
  CreateDateColumn,
  Entity,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { CountryCodeIso2 } from './CountryCodeIso2';

@Entity()
export class SellerShippingAddress {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column()
  city!: string;

  @Column({ type: 'enum', enum: CountryCodeIso2 })
  countryIso2Code!: CountryCodeIso2;

  @Column()
  email!: string;

  @Column()
  firstName!: string;

  @Column({ type: 'character varying', nullable: true })
  houseNumber!: string | null;

  @Column()
  lastName!: string;

  @Column()
  phoneNumber!: string;

  @Column()
  street!: string;

  @Column({ type: 'character varying', nullable: true })
  street2!: string | null;

  @Column()
  zipCode!: string;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;
}
