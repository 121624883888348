import { useCallback, useEffect } from 'react';

import { Badge } from '@bits-app/bits-server-data';
import { useMutation } from '@tanstack/react-query';
import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { FieldError } from '@/domain/errors/FieldError';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { useAppDispatch } from '@/redux/reduxAppHooks';
import { editBadge } from '@/voggt-database-explorer/badge/use-cases/edit-badge';
import { useImageUpload } from '@/voggt-database-explorer/components/ImageUpload/use-image-upload.hook';
import { databaseExplorerAction } from '@/voggt-database-explorer/redux/database-explorer.slice';

import { DraftBadge } from '../../entity/draft-badge';
import { createBadge } from '../../use-cases/create-badge';

import { FieldValue } from './UpsertBadgeForm';

export const useUpsertBadgeForm = ({
  badge,
  isDraft,
  setFieldError,
  resetForm,
  afterSuccess,
}: {
  badge?: DraftBadge | Badge;
  isDraft?: boolean;
  setFieldError: UseFormSetError<FieldValue>;
  resetForm: () => void;
  afterSuccess: (badge: DraftBadge | Badge) => void;
}) => {
  const snackbar = useSnackbar();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const upsertType = badge ? 'update' : 'create';

  const { clearImage, image, imageErrors, onDropImage, setImageErrors } = useImageUpload('150Kb');

  useEffect(() => {
    return () => {
      if (!image) {
        return;
      }

      URL.revokeObjectURL(image.preview);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetAllForm = () => {
    clearImage();
    setImageErrors(null);
    resetForm();
  };

  const onSubmit = async (data: Partial<FieldValue>) => {
    if (!badge && !image) {
      setImageErrors((errors) => [
        ...(errors ?? []),
        {
          code: 'file-is-required',
          message: t(`databaseExplorer.badge.upsert.errors.required`, {
            field: t(`databaseExplorer.badge.upsert.image`),
          }),
        },
      ]);

      return;
    }

    const formData = new FormData();

    if (image) {
      formData.append('file', image);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append('name', data.name!);

    if (data.description) {
      formData.append('description', data.description);
    }

    if (data.colorAccent) {
      formData.append('colorAccent', data.colorAccent);
    }

    await mutate(formData);
  };

  const upsertBadge = useCallback(
    async (formData: FormData) => {
      if (!badge) {
        return createBadge(formData);
      }

      return editBadge(formData, badge.id, isDraft);
    },
    [badge, isDraft],
  );

  const { mutate, isLoading } = useMutation<DraftBadge | Badge, unknown, FormData>(upsertBadge, {
    onSuccess: (upsertedBadge) => {
      snackbar.success(`databaseExplorer.badge.${upsertType}.success`, {
        interpolationMap: { name: upsertedBadge.name },
      });

      if (!badge) {
        queryClient.invalidateQueries(QUERIES_KEYS.badgesList());
      } else {
        if (isDraft) {
          queryClient.invalidateQueries(QUERIES_KEYS.draftBadge(badge.id as string));
        } else {
          dispatch(databaseExplorerAction.setData(upsertedBadge));
        }
      }

      resetAllForm();
      afterSuccess(upsertedBadge);
    },
    onError: (error) => {
      snackbar.error(`databaseExplorer.badge.${upsertType}.error`);

      if (error instanceof FieldError) {
        switch (error.field) {
          case 'name':
          case 'description':
          case 'colorAccent':
            setFieldError(error.field, { message: error.message });
            break;

          case 'image':
            setImageErrors((errors) => [
              ...(errors ?? []),
              {
                code: 'server-error',
                message: error.message,
              },
            ]);
            break;
        }
      }
    },
  });

  return {
    onSubmit,
    isLoading,
    onDropImage,
    image,
    imageErrors,
  };
};
