import { useState } from 'react';

import { OrderCancellationReason, OrderStatus, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useOwnUser } from '@/context/own-user.context';
import { useAppSelector } from '@/redux/reduxAppHooks';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

import { selectOrderItemsMassUpdating } from '../order-items-mass-update/redux/order-items-mass-update.selectors';

const ORDER_STATUS = Object.values(OrderStatus) as OrderStatus[];

type UpdateOrderItemsShippingStatusModalProps = {
  count: number;
  onClose: () => void;
  onUpdate: (shippingStatus: OrderStatus, cancellationReason?: OrderCancellationReason) => void;
};

export const UpdateOrderItemsShippingStatusModal = ({
  count,
  onClose,
  onUpdate,
}: UpdateOrderItemsShippingStatusModalProps) => {
  const { t } = useTranslation();

  const loading = useAppSelector(selectOrderItemsMassUpdating);
  const { hasPermissions } = useOwnUser();

  const [shippingStatus, setShippingStatus] = useState<OrderStatus | ''>('');
  const [cancellationReason, setCancellationReason] = useState<OrderCancellationReason | null>(
    null,
  );

  const orderStatus = ORDER_STATUS.filter((status) => {
    if (status === OrderStatus.canceled) {
      if (!hasPermissions(PERMISSIONS.ORDER.WRITE_ORDER_CANCEL)) {
        return false;
      }
    }

    return true;
  });

  const handleOnUpdate = () => {
    if (!shippingStatus) {
      return;
    }

    onUpdate(shippingStatus, cancellationReason ?? undefined);
  };

  const handleChangeShippingStatus = (event: SelectChangeEvent<OrderStatus>) => {
    const status = event.target.value as OrderStatus;

    if (status !== OrderStatus.canceled) {
      setCancellationReason(null);
    }

    setShippingStatus(status);
  };

  const handleChangeCancellationReason = (event: SelectChangeEvent<OrderCancellationReason>) => {
    setCancellationReason(event.target.value as OrderCancellationReason);
  };

  return (
    <Dialog
      open
      PaperProps={{
        sx: {
          minWidth: 360,
        },
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        {t('orderItemsMassUpdate.update.shippingStatus')}

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <Box mb={2}>{t('orderItemsMassUpdate.update.content', { count })}</Box>

        <FormControl fullWidth>
          <InputLabel>{t('orderItemLabels.shippingStatus')}</InputLabel>
          <Select
            value={shippingStatus}
            label={t('orderItemLabels.shippingStatus')}
            onChange={handleChangeShippingStatus}
          >
            {orderStatus.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {shippingStatus === OrderStatus.canceled && (
          <FormControl fullWidth margin="dense">
            <InputLabel>{t('orderItemLabels.cancellationReason')}</InputLabel>
            <Select
              value={cancellationReason || ''}
              label={t('orderItemLabels.cancellationReason')}
              onChange={handleChangeCancellationReason}
              sx={{ textTransform: 'capitalize' }}
            >
              {Object.values(OrderCancellationReason).map((reason) => (
                <MenuItem key={reason} value={reason} sx={{ textTransform: 'capitalize' }}>
                  {reason.replaceAll('_', ' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          pr: 3,
        }}
      >
        <LoadingButton
          variant="contained"
          loading={loading}
          disabled={
            loading ||
            !shippingStatus ||
            (shippingStatus === OrderStatus.canceled && !cancellationReason)
          }
          onClick={handleOnUpdate}
        >
          {t('update')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
