import { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { selectZendeskLoading } from '@/auth/auth.slice';
import { zendeskAuth } from '@/auth/use-cases/zendesk-auth';
import { useOwnUser } from '@/context/own-user.context';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

export const ZendeskAuth = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectZendeskLoading);
  const { ownUser } = useOwnUser();

  useEffect(() => {
    const code = searchParams.get('code');

    if (!code || ownUser?.isAuthenticatedToZendesk) {
      navigate('/');
      return;
    }

    if (!isLoading) {
      dispatch(zendeskAuth(code));
      navigate('/');
    }
  }, [navigate, dispatch, searchParams, ownUser?.isAuthenticatedToZendesk, isLoading]);

  return (
    <div className="flex flex-col flex-1 h-full">
      <CircularProgress />
    </div>
  );
};
