import { useState } from 'react';

import { type PubsubEventPayload } from '@bits-app/pubsub';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { parse } from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '@Elements/buttons/ButtonContained';
type BulkUpdateTransactionStatusModalProps = {
  onConfirm: (messages: PubsubEventPayload['ledger-update-transaction-status'][]) => void;
  onClose: () => void;
};

export const BulkUpdateTransactionStatusModal = ({
  onConfirm,
  onClose,
}: BulkUpdateTransactionStatusModalProps) => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState<
    PubsubEventPayload['ledger-update-transaction-status'][]
  >([]);

  const onDrop = (files: File[]) => {
    const reader = new FileReader();
    reader.onload = () => {
      const csvData = reader.result as string;
      const parsed = parse<{
        ['id']: PubsubEventPayload['ledger-update-transaction-status']['transactionId'];
        ['status']: PubsubEventPayload['ledger-update-transaction-status']['status'];
      }>(csvData, {
        header: true,
      });
      if (!parsed.data) return;
      const messages = parsed.data
        .map((data) => {
          return {
            transactionId: data['id'],
            status: data['status'],
          };
        })
        .filter(
          (message) =>
            message.transactionId &&
            ['DONE', 'READY', 'FAILED', 'SUCCESS', 'IN_PROGRESS'].includes(message.status),
        );
      setMessages(messages);
    };
    reader.readAsText(files[0]);
  };

  const { getRootProps, acceptedFiles } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { 'text/csv': ['.csv'] },
  });

  const _fileName = acceptedFiles?.[0]?.name;

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {t('ledger.generate-manual-status-update.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('ledger.generate-manual-status-update.description')}
        </DialogContentText>
        <Box
          {...getRootProps()}
          sx={{
            width: '100%',
            height: '50%',
            minHeight: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          borderRadius={4}
        >
          {t('updateBadges.draganddropzone')}

          {_fileName && (
            <Typography variant="body2" color={({ palette }) => palette.text.secondary}>
              {_fileName}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <ButtonContained
          onClick={() => {
            onConfirm(messages);
          }}
          autoFocus
        >
          {t('confirm')}
        </ButtonContained>
      </DialogActions>
    </>
  );
};
