import { useReducer } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from '@Elements/snackbar/use-snackbar';

import { authenticatedInstance } from '../../../axios/axios.instance';

export const useDeleteShow = (showId: number, refresh: () => void) => {
  const [modalIsOpen, toggleModalStatus] = useReducer((state) => !state, false);
  const { t } = useTranslation();

  const snackbar = useSnackbar();

  const { mutate: onConfirm, isLoading } = useMutation(
    () => {
      if (!showId) {
        throw new Error('Missing show id');
      }
      return authenticatedInstance.patch(`database-explorer/show/${showId}`, {
        deletedAt: new Date(),
      });
    },
    {
      onSuccess: () => {
        snackbar.success(t(`databaseExplorer.show.success`));
        toggleModalStatus();
        refresh();
      },
      onError: () => {
        snackbar.error(t(`databaseExplorer.show.error`));
      },
    },
  );

  return {
    isLoading,
    toggleModalStatus,
    onCancel: toggleModalStatus,
    modalIsOpen,
    onConfirm,
  };
};
