import { RefundReason } from '@bits-app/voggtpit-shared';

import { AppThunkAction } from '@/redux/store';

import {
  selectIsOrderItemInTheRefund,
  selectOrderItemFromId,
  selectRefundReason,
} from '../redux/refund.selectors';
import { refundActions } from '../redux/refund.slice';

import { getDefaultRefundStateForReason } from './on-refund-reason/get-default-refund-state-for-reason';

export const removeOrderItemFromRefund =
  (orderItemId: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const isOrderItemInTheRefund = selectIsOrderItemInTheRefund(getState(), orderItemId);

    if (!isOrderItemInTheRefund) {
      return;
    }

    dispatch(refundActions.removeFromRefund(orderItemId));

    const orderItem = selectOrderItemFromId(getState(), orderItemId);

    if (!orderItem) {
      return;
    }

    dispatch(getDefaultRefundAmountAfterSelectionUpdate());
  };

const getDefaultRefundAmountAfterSelectionUpdate =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const reason = selectRefundReason(getState());

    switch (reason) {
      case RefundReason.misclick:
      case RefundReason.changeOfMind:
      case RefundReason.counterfeit:
      case RefundReason.missingProduct:
      case RefundReason.productLostBeforeShipping:
      case RefundReason.sellerMistake:
      case RefundReason.others:
        dispatch(getDefaultRefundStateForReason(reason));
        break;

      default:
        return;
    }
  };
