import { SellerFees } from '@bits-app/voggtpit-shared';

export type UserGrouped = {
  [key: string]: {
    userIds: number[];
    percentageFee: SellerFees['percentageFee'];
    fixedFee: SellerFees['fixedFee'];
    includeShippingFeesForCommissionByDefault?: boolean;
  };
};

type ReducerStateType = {
  modalIsOpen: boolean;
  isLoading: boolean;
  error: null | string;
  currentProcessing: '0' | string;
  userGrouped: UserGrouped;
  errorUsers: number[] | null;
  result: boolean;
  percentageFee?: SellerFees['percentageFee'];
  fixedFee?: SellerFees['fixedFee'];
  includeShippingFeesForCommissionByDefault?: boolean;
};

export const CHUNK_SIZE = 5;

export const initialState: ReducerStateType = {
  modalIsOpen: false,
  isLoading: false,
  error: null,
  currentProcessing: '0',
  userGrouped: {},
  errorUsers: null,
  result: false,
};

type ActionReducer_UserUpdateGrouped = {
  type: 'usersUpdateGrouped';
  payload: { userGrouped: UserGrouped };
};

type ActionReducer_UpdateCurrentProcessing = {
  type: 'updateCurrentProcessing';
  payload: { percentageUpdate: string };
};
type ActionReducer_UpdateErrorUsers = {
  type: 'updateErrorUsers';
  payload: { errorUsers: number[] };
};

type ActionReducer_UpdateError = {
  type: 'updateError';
  payload: { error: string };
};

type ActionReducer_UpdateFeeAmount = {
  type: 'updatePercentageFeeAmount';
  payload: { feeAmount?: number };
};

type ActionReducer_UpdateFixedFee = {
  type: 'updateFixedFee';
  payload: { fixedFee?: number };
};

type ActionReducer_UpdateIncludeShippingFeesForCommissionByDefault = {
  type: 'updateIncludeShippingFeesForCommissionByDefault';
  payload: { includeShippingFeesForCommissionByDefault?: boolean };
};

export type ActionReducer =
  | {
      type:
        | 'userOpenModal'
        | 'userSubmitForm'
        | 'loadingSwitchOn'
        | 'loadingSwitchOff'
        | 'userCloseModal'
        | 'cleanSuccessMessage'
        | 'cleanError'
        | 'setSuccessMessage'
        | 'cleanErrorMessage';
    }
  | ActionReducer_UpdateCurrentProcessing
  | ActionReducer_UserUpdateGrouped
  | ActionReducer_UpdateFeeAmount
  | ActionReducer_UpdateFixedFee
  | ActionReducer_UpdateErrorUsers
  | ActionReducer_UpdateError
  | ActionReducer_UpdateIncludeShippingFeesForCommissionByDefault;

export const reduceUseUpdateFee = (
  state: ReducerStateType,
  action: ActionReducer,
): ReducerStateType => {
  switch (action.type) {
    case 'userOpenModal': {
      return {
        ...state,
        modalIsOpen: true,
      };
    }
    case 'userSubmitForm': {
      return {
        ...state,
      };
    }
    case 'userCloseModal': {
      return initialState;
    }
    case 'loadingSwitchOn': {
      return { ...state, isLoading: true };
    }
    case 'loadingSwitchOff': {
      return { ...state, isLoading: false, currentProcessing: '0' };
    }
    case 'updateCurrentProcessing': {
      return { ...state, currentProcessing: action.payload.percentageUpdate, isLoading: true };
    }
    case 'usersUpdateGrouped': {
      return { ...state, userGrouped: action.payload.userGrouped };
    }
    case 'updateError': {
      return { ...state, error: action.payload.error };
    }
    case 'cleanError': {
      return { ...state, error: null };
    }
    case 'cleanErrorMessage': {
      return { ...state, errorUsers: null };
    }
    case 'updateErrorUsers': {
      return { ...state, errorUsers: [...(state.errorUsers || []), ...action.payload.errorUsers] };
    }
    case 'cleanSuccessMessage': {
      return { ...state, result: false };
    }
    case 'setSuccessMessage': {
      return { ...state, result: !state?.errorUsers?.length };
    }
    case 'updatePercentageFeeAmount': {
      const percentageFee = action.payload.feeAmount;
      if (percentageFee != null) {
        const isOutOfRange = percentageFee < 0 || percentageFee > 100;
        if (isOutOfRange) return state;
      }
      return { ...state, percentageFee: percentageFee };
    }
    case 'updateFixedFee': {
      const fixedFee = action.payload.fixedFee;
      if (fixedFee != null) {
        const isOutOfRange = fixedFee < 0;
        if (isOutOfRange) return state;
      }
      return { ...state, fixedFee };
    }
    case 'updateIncludeShippingFeesForCommissionByDefault': {
      const includeShippingFeesForCommissionByDefault =
        action.payload.includeShippingFeesForCommissionByDefault;

      return { ...state, includeShippingFeesForCommissionByDefault };
    }
    default:
      return state;
  }
};
