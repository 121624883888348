import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';
import { useBulkUpdateLedger } from '@/voggt-database-explorer/users/hooks/bulk-update-ledger/use-bulk-update-ledger';

export const BulkUpdateLedgerModal = () => {
  const {
    toggleModal,
    modalIsOpen,
    onDropFile,
    errors,
    handleSubmit,
    userIsAllowed,
    submitButtonIsDisabled,
    rows,
    isLoading,
    pointer,
  } = useBulkUpdateLedger();

  const { t } = useTranslation();

  const theme = useTheme();

  const { getRootProps } = useDropzone({
    onDrop: onDropFile,
    maxFiles: 1,
    accept: { 'text/csv': ['.csv'] },
  });

  const csvTemplateUrl = window.URL.createObjectURL(
    new Blob(['sellerId,amount,description,orderId,showId,status'], {
      type: 'text/csv',
    }),
  );

  if (!userIsAllowed) {
    return null;
  }

  return (
    <>
      <button
        className="text-slate-900 bg-slate-200 hover:bg-slate-400 focus:ring-2 focus:outline-none focus:ring-sky-300 font-semibold rounded text-xs px-3 py-1.5 text-center inline-flex items-center"
        onClick={toggleModal}
      >
        Bulk update ledger
      </button>

      <Dialog open={modalIsOpen} onClose={toggleModal} PaperProps={{ sx: { width: '50%' } }}>
        <DialogTitle display="flex" justifyContent="space-between" color="common.black">
          Bulk update ledger with CSV
          <IconButton size="small" color="inherit" onClick={toggleModal}>
            <CloseIcon color="inherit" />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
          <Box
            {...getRootProps()}
            sx={{
              backgroundColor: theme.palette.grey[200],
              width: '100%',
              height: '50%',
              minHeight: 100,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            borderRadius={4}
          >
            {t('updateBadges.draganddropzone')}
          </Box>
          <Typography
            component="a"
            href={csvTemplateUrl}
            download="bulk-transfer-fund-to-users.csv"
            variant="caption"
            sx={{
              textAlign: 'right',
              fontStyle: 'italic',
              textDecoration: 'underline',
              cursor: 'pointer',
              display: 'block',
              marginBottom: '16px',
            }}
          >
            {t('databaseExplorer.users.paymentDirectTransfer.bulk.downloadTemplate')}
          </Typography>
          {errors.length
            ? errors.map(({ error, row }) => (
                <Typography variant="body2" color={({ palette }) => palette.error.main}>
                  Line {row} - {error}
                </Typography>
              ))
            : null}
          {rows.length ? (
            <Typography variant="body2" color={({ palette }) => palette.success.main}>
              {rows.length} rows ready to be updated
            </Typography>
          ) : null}
        </DialogContent>

        <DialogActions
          sx={{
            paddingRight: ({ spacing }) => spacing(3),
            paddingLeft: ({ spacing }) => spacing(3),
            paddingBottom: ({ spacing }) => spacing(2),
          }}
        >
          {isLoading && (
            <Typography
              variant="caption"
              sx={{
                fontWeight: 'bold',
                display: 'block',
                marginBottom: '18px',
              }}
            >
              Processing : {pointer}/{rows.length}
            </Typography>
          )}
          <ButtonOutlined onClick={toggleModal}>{t('action.cancel')}</ButtonOutlined>
          <ButtonOutlined onClick={handleSubmit} disabled={submitButtonIsDisabled}>
            {t('action.submit')}
          </ButtonOutlined>
        </DialogActions>
      </Dialog>
    </>
  );
};
