import { Badge } from '@bits-app/bits-server-data';
import { KeysWithTranslations } from '@bits-app/voggtpit-shared';
import { useMutation, useQuery } from '@tanstack/react-query';

import { QUERIES_KEYS, queryClient } from '@/queries';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

import { TranslationsForm } from '../../../components/translations/TranslationsForm';
import { DraftBadge } from '../../entity/draft-badge';
import { getBadgeTranslations } from '../../use-cases/get-badge-translations';
import { updateBadgeTranslations } from '../../use-cases/update-badge-translations';

type BadgeTranslationsProps = {
  badge: DraftBadge | Badge;
  afterSuccess: () => void;
};

export const BadgeTranslations = ({ badge, afterSuccess }: BadgeTranslationsProps) => {
  const snackbar = useSnackbar();

  const { data: keysWithTranslations, isLoading } = useQuery<KeysWithTranslations>(
    QUERIES_KEYS.badgeTranslations(badge.name),
    () => getBadgeTranslations(badge),
  );

  const { mutate } = useMutation<void, unknown, Record<string, string>>(
    (translations) => updateBadgeTranslations(badge.name, translations),
    {
      onSuccess: () => {
        snackbar.success('databaseExplorer.translate.success', {
          interpolationMap: { entity: badge.name },
        });

        queryClient.invalidateQueries(QUERIES_KEYS.badgeTranslations(badge.name));

        afterSuccess();
      },

      onError: () => {
        snackbar.error('databaseExplorer.translate.error', {
          interpolationMap: { entity: badge.name },
        });
      },
    },
  );

  return (
    <TranslationsForm
      isLoading={isLoading}
      keysWithTranslations={keysWithTranslations ?? {}}
      update={mutate}
    />
  );
};
