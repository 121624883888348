import { useEffect } from 'react';

import {
  PaymentMethodBancontact,
  PaymentMethodCard,
  PaymentMethodGiropay,
  PaymentMethodIdeal,
  PaymentMethodPaypal,
  User,
} from '@bits-app/bits-server-data';

import { Information } from '@/components/layouts/PanelDetails';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { getStripeSetupIntentUrl } from '@/utils';
import { selectResourceLoading } from '@/voggt-database-explorer/redux/database-explorer.slice';
import {
  getResource,
  selectResource,
  useResourceSelector,
} from '@/voggt-database-explorer/redux/use-cases/get-resource';

import { availablePaymentMethodType } from '../../payment-method.type';

export const useDetailsView = <
  T extends
    | PaymentMethodBancontact
    | PaymentMethodCard
    | PaymentMethodGiropay
    | PaymentMethodIdeal
    | PaymentMethodPaypal,
>(
  paymentMethodDataId: string,
  paymentMethodType: availablePaymentMethodType,
) => {
  const dispatch = useAppDispatch();
  const paymentMethodData = useResourceSelector<T>(selectResource);
  const loading = useAppSelector(selectResourceLoading);

  useEffect(() => {
    if (paymentMethodDataId && paymentMethodData.id !== Number(paymentMethodDataId)) {
      dispatch(getResource(+paymentMethodDataId, paymentMethodType));
    }
  }, [paymentMethodDataId, dispatch, paymentMethodData.id, paymentMethodType]);

  return {
    paymentMethodData,
    loading,
    panelDetailsUser,
    panelDetailsPaymentMethodCard: panelDetailsPaymentMethodCard,
    panelDetailsPaymentMethodBancontact: panelDetailsPaymentMethodBancontact,
    panelDetailsPaymentMethodGiropay: panelDetailsPaymentMethodGiropay,
    panelDetailsPaymentMethodIdeal: panelDetailsPaymentMethodIdeal,
    panelDetailsPaymentMethodPaypal: panelDetailsPaymentMethodPaypal,
  };
};

const panelDetailsPaymentMethodCard: Information<PaymentMethodCard>[] = [
  { value: 'brand', label: 'brand' },
  { value: 'country', label: 'country' },
  { value: 'last4', label: 'last4' },
  { value: 'expMonth', label: 'expMonth' },
  { value: 'expYear', label: 'expYear' },
  { value: 'fingerprint', label: 'fingerprint' },
  { value: 'funding', label: 'funding' },
  { value: 'preAuthorizationAmount', label: 'preAuthorizationAmount' },
  { value: 'preAuthorizationCreatedAt', label: 'preAuthorizationCreatedAt', type: 'date' },
  { value: 'preAuthorizationDeletedAt', label: 'preAuthorizationDeletedAt' },
  { value: 'preAuthorizationExpireAt', label: 'preAuthorizationExpireAt' },
  {
    value: 'preAuthorizationStripePaymentIntentId',
    label: 'preAuthorizationStripePaymentIntentId',
  },
  {
    value: 'stripePaymentMethodId',
    label: 'stripePaymentMethodId',
  },
];

const panelDetailsPaymentMethodPaypal: Information<PaymentMethodPaypal>[] = [
  {
    value: 'paymentMethodPaypalPreAuthorization',
    label: 'paymentMethodPaypalPreAuthorization',
  },
  { value: 'preAuthorizationAmount', label: 'preAuthorizationAmount' },
  { value: 'preAuthorizationCreatedAt', label: 'preAuthorizationCreatedAt' },
  { value: 'preAuthorizationDeletedAt', label: 'preAuthorizationDeletedAt' },
  { value: 'preAuthorizationExpireAt', label: 'preAuthorizationExpireAt' },
  {
    value: 'preAuthorizationStripePaymentIntentId',
    label: 'preAuthorizationStripePaymentIntentId',
  },
  { value: 'verifiedEmail', label: 'verifiedEmail' },
];

const panelDetailsPaymentMethodGiropay: Information<PaymentMethodGiropay>[] = [];

const panelDetailsPaymentMethodBancontact: Information<PaymentMethodBancontact>[] = [
  {
    value: 'stripeSetupIntentId',
    label: 'Stripe Setup intent ID',
    type: 'external-link',
    link: (paymentMethod) => getStripeSetupIntentUrl(paymentMethod.stripeSetupIntentId),
  },
];

const panelDetailsPaymentMethodIdeal: Information<PaymentMethodIdeal>[] = [
  {
    value: 'stripeSetupIntentId',
    label: 'Stripe Setup intent ID',
    type: 'external-link',
    link: (paymentMethod) => getStripeSetupIntentUrl(paymentMethod.stripeSetupIntentId),
  },
];

const panelDetailsUser: Information<User>[] = [
  { value: 'id', label: 'id', type: 'internal-link', link: (user) => `/user/${user.id}` },
  { value: 'username', label: 'Username' },
  { value: 'firstName', label: 'Firstname' },
  { value: 'lastName', label: 'Lastname' },
];
