import { useMemo } from 'react';

import { Product, Show, User, Order, ShippingAddress, ShipmentRating } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { UserIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { Loader } from '@/components/elements/Loader';
import { useOwnUser } from '@/context/own-user.context';
import { useIdParamAsNumber } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { QUERIES_KEYS, queryClient, useGetCategories } from '@/queries';
import { OrdersWithExport } from '@/voggt-database-explorer/components/OrdersWithExport';
import { ItemLayout, ItemTab } from '@/voggt-database-explorer/layouts/ItemLayout';
import { ShowOrderRowActions } from '@/voggt-database-explorer/show/views/detail/ShowOrders/ShowOrderRowActions';
import {
  CellOrderDefinition,
  CellProductDefinition,
  getCellDefinitionForShowInUser,
  CellUserDefinition,
  CellShippingAddressDefinition,
} from '@/voggt-database-explorer/users/hooks/use-cell-definition';

import { ListWithExport } from '../../../components/ListWithExport';
import { ShipmentCellDefinition } from '../../../shipment/shipment.const';
import { ShipmentsWithTotalAmounts } from '../../../shipment/utils/utils';
import { RestoreSso } from '../../components/RestoreSso.modal';
import { RestoreUserAccountModal } from '../../components/RestoreUserAccount.modal';
import { useAddBadgeToUser } from '../../hooks/use-add-badges-to-user';
import { useAddToSentinel } from '../../hooks/use-add-to-sentinel';
import { useDirectFundTransfer } from '../../hooks/use-direct-fund-transfer';
import { useImpersonateFeature } from '../../hooks/use-impersonate-feature';
import { useRemoveBadgeFromUser } from '../../hooks/use-remove-badges-from-user';
import { useRestoreAccount } from '../../hooks/useRestoreAccount';
import { useSsoRemoval } from '../../hooks/useSsoRemoval';
import { useSsoRestoration } from '../../hooks/useSsoRestoration';
import { useUserDetail } from '../../hooks/useUserDetail';
import { useZendesk } from '../../hooks/useZendesk';
import { useZohoData } from '../../hooks/useZohoData';

import { DeleteUser } from './DeleteUser/DeleteUser';
import { useDeleteUser } from './DeleteUser/use-delete-functionality';
import { Details } from './Details/Details';
import { useHardBlockUser } from './HardBlockUser/use-hard-block-user';
import { PromotionDetail } from './Promotions';
import { FieldValue, RemoveUserSsoModal } from './RemoveUserSso/RemoveUserSso.modal';
import { SellerPanel } from './Seller/SellerPanel';
import { BulkUpdateShipmentRating } from './ShipmentRating/BulkUpdateShipmentRating';
import { ShipmentRatingRowAction } from './ShipmentRating/ShipmentRatingRowAction';
import { ToggleBanUser } from './ToggleBanUser/ToggleBanUser';
import { useBanUser } from './ToggleBanUser/use-toggle-ban-user';
import { ConfirmUnblockUserModal } from './UnblockUser/ConfirmUnblockUserModal';
import { useUnblockUser } from './UnblockUser/use-unblock-user';
import { UserMuxAssetsTab } from './UserMuxAssetsTab';
import { UserPayoutList } from './UserPayoutList/UserPayoutList';
import { ZendeskTicketsTab } from './ZendeskTicketsTab';
import { ShipmentRatingCellDefinition } from './const/shipment-rating';
import { AddBadgeModal } from './modal/AddBadge.modal';
import { DirectFundTransferModal } from './modal/DirectFundTransfer.modal';
import { ImpersonateAccessModal } from './modal/ImpersonateAccess.modal';
import { RemoveBadgeModal } from './modal/RemoveBadge.modal';
import { SentinelModal } from './modal/Sentinel.modal';

export const UserProfile = () => {
  const userId = useIdParamAsNumber();
  const { user, isLoading } = useUserDetail(Number(userId));
  if (isLoading) {
    return <Loader />;
  }

  if (!user) {
    return null;
  }

  return <Profile user={user} userId={user.id} />;
};

const Profile = ({ userId, user }: { user: User; userId: User['id'] }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermissions } = useOwnUser();

  const { data: zendeskData } = useZendesk(userId);
  const { data: categories } = useGetCategories();
  const { setError } = useForm<FieldValue>();

  const handleOnTabChange = useNavigateInDetailTab(`/user/${userId}`);
  const isReadOnly = !hasPermissions(PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_USER);

  const hardblockUserHook = useHardBlockUser(userId);
  const sentinelActionHook = useAddToSentinel({ userId: Number(userId) });
  const directFundTransferHook = useDirectFundTransfer(Number(userId));
  const removeBadgeHook = useRemoveBadgeFromUser(Number(userId));
  const addBadgeHook = useAddBadgeToUser(Number(userId));
  const banUserHook = useBanUser(user);
  const zohoDataHook = useZohoData(userId);
  const deleteUserHook = useDeleteUser(user);
  const ssoRemovalHook = useSsoRemoval(Number(userId), setError, user);
  const impersonateFeatureHook = useImpersonateFeature(Number(userId));
  const unblockUserHook = useUnblockUser(+userId, user?.isHardBlocked);
  const restoreUserAccountHook = useRestoreAccount(user);
  const restoreUserSsoHook = useSsoRestoration(user);

  const itemsMenuList = useMemo(
    () => [
      {
        forbidden: !hasPermissions(PERMISSIONS.USER.READ_USER_ZOHO_LINK),
        onClick: () => window.open(zohoDataHook.data?.data, '_blank'),
        label: 'Open on Zoho',
      },
      {
        forbidden: !impersonateFeatureHook.ownUserIsAllowedToImpersonate,
        onClick: impersonateFeatureHook.toggleImpersonateModalFeature,
        label: t('databaseExplorer.users.impersonate.action'),
      },
      {
        label: t('databaseExplorer.users.hardBlock.action'),
        onClick: hardblockUserHook.goToModal,
        forbidden: !hardblockUserHook.shouldDisplayHardBlockFunctionality,
      },
      {
        label: t('databaseExplorer.users.unblock.action'),
        onClick: unblockUserHook.toggle,
        forbidden: !unblockUserHook.shouldDisplayUnblockUser,
      },
      {
        label: t('databaseExplorer.users.paymentDirectTransfer.action'),
        onClick: directFundTransferHook.toggleModalDirectFundTransfer,
        forbidden: !directFundTransferHook?.userIsAllowed || !user?.stripeAccountId,
      },
      {
        onClick: banUserHook.toggleIsOpen,
        label: t(`databaseExplorer.users.${user?.isBanned ? 'unban' : 'ban'}.action`),
      },
      {
        label: t('databaseExplorer.users.delete.action'),
        onClick: deleteUserHook.onOpen,
        forbidden: !deleteUserHook.shouldDisplayDeleteUserFunctionality,
      },
      {
        label: t('databaseExplorer.users.sentinel.action'),
        onClick: sentinelActionHook.toggleModalIsOpen,
        forbidden: !sentinelActionHook.userIsAllowed,
      },
      {
        label: t('databaseExplorer.users.unlinkSso.action'),
        forbidden: !ssoRemovalHook.shouldDisplaySsoRemoval,
        onClick: ssoRemovalHook.toggleSso,
      },
      {
        label: 'Restore user account',
        forbidden: !restoreUserAccountHook.shouldDisplayRestoreAccount,
        onClick: restoreUserAccountHook.toggleRestorationModal,
      },
      {
        label: 'Restore SSO',
        forbidden: !restoreUserSsoHook.shouldDisplaySsoRestoration,
        onClick: restoreUserSsoHook.toggleRestorationModal,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      impersonateFeatureHook.ownUserIsAllowedToImpersonate,
      impersonateFeatureHook.toggleImpersonateModalFeature,
      hardblockUserHook.goToModal,
      hardblockUserHook.shouldDisplayHardBlockFunctionality,
      unblockUserHook.toggle,
      unblockUserHook.shouldDisplayUnblockUser,
      directFundTransferHook.toggleModalDirectFundTransfer,
      directFundTransferHook.userIsAllowed,
      user?.isBanned,
      banUserHook.toggleIsOpen,
      deleteUserHook.onOpen,
      deleteUserHook.shouldDisplayDeleteUserFunctionality,
      sentinelActionHook.toggleModalIsOpen,
      sentinelActionHook.userIsAllowed,
    ],
  );

  const tabs: ItemTab[] = [
    {
      path: '/',
      label: t('databaseExplorer.users.tabs.user'),
      Element: <Details key="/" addBadgeHook={addBadgeHook} removeBadgeHook={removeBadgeHook} />,
    },
    {
      path: 'buyer-order',
      label: t('databaseExplorer.users.tabs.buyerOrders'),
      Element: (
        <OrdersWithExport<Order & { id: string }>
          key="buyer-order"
          filters={{
            customerId: Number(userId),
          }}
          cellDefinition={CellOrderDefinition}
          renderRowActions={({ row }) => {
            if (row.original === null) {
              return null;
            }
            return <ShowOrderRowActions order={row.original} />;
          }}
        />
      ),
    },
    {
      path: 'seller-order',
      label: t('databaseExplorer.users.tabs.sellerOrders'),
      shouldHide: !user?.isSeller,
      Element: (
        <OrdersWithExport<Order & { id: string }>
          key="seller-order"
          filters={{
            sellerId: Number(userId),
          }}
          cellDefinition={CellOrderDefinition}
          defaultHiddenColumns={{
            refundedAmount: false,
            refundReason: false,
            refundAgent: false,
            refundedAt: false,
            feeDeductibleFromBuyerPayment: false,
          }}
        />
      ),
    },
    {
      path: 'shipping-address',
      label: 'Shipping Addresses',
      Element: (
        <ListWithExport<ShippingAddress & { id: string }>
          cellDefinition={CellShippingAddressDefinition}
          filters={{ userId: Number(userId) }}
          dataName={'shipping-address'}
          key="shipping-address"
        />
      ),
    },
    {
      path: 'product',
      label: t('databaseExplorer.users.tabs.products'),
      Element: (
        <ListWithExport<Product & { id: string; show: string }>
          cellDefinition={CellProductDefinition}
          filters={{ sellerId: Number(userId) }}
          dataName={'product'}
          key="product"
        />
      ),
    },
    {
      path: 'show',
      label: t('databaseExplorer.users.tabs.shows'),
      Element: (
        <ListWithExport<Show & { id: string; gmv: number }>
          key="show"
          cellDefinition={getCellDefinitionForShowInUser(categories)}
          defaultSortBy={{ id: 'startAt', desc: true }}
          filters={{ userId: Number(userId), withGmv: true }}
          dataName={'show'}
        />
      ),
    },
    {
      path: 'shipment-buyer',
      label: 'Shipments (buyer)',
      shouldHide: !hasPermissions(PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SHIPMENT),
      Element: (
        <ListWithExport<ShipmentsWithTotalAmounts>
          key="shipment-buyer"
          cellDefinition={ShipmentCellDefinition.filter((column) => column.id !== 'customer')}
          filters={{ customerId: Number(userId) }}
          dataName={'shipments-from-user'}
        />
      ),
    },
    {
      path: 'shipment-seller',
      label: 'Shipments (seller)',
      shouldHide: !(
        user?.isSeller &&
        hasPermissions(PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SHIPMENT)
      ),
      Element: (
        <ListWithExport<ShipmentsWithTotalAmounts>
          key="shipment-seller"
          cellDefinition={ShipmentCellDefinition.filter((column) => column.id !== 'seller')}
          filters={{ sellerId: Number(userId) }}
          dataName={'shipments-from-user'}
        />
      ),
    },
    {
      path: 'shipment-rating',
      label: 'Ratings (seller)',
      shouldHide: !(
        user?.isSeller
      ),
      Element: (
        <ListWithExport<ShipmentRating>
          key="shipment-seller"
          cellDefinition={ShipmentRatingCellDefinition}
          filters={{ sellerId: userId }}
          dataName={'shipment-rating'}
          renderRowActions={({ row }) => <ShipmentRatingRowAction row={row.original} sellerId={userId} />}
          customSelectedRowsAction={(selectedRows) => (
            <BulkUpdateShipmentRating rows={selectedRows.map((x) => x.original)} sellerId={userId} />
          )}
        />
      ),
    },
    {
      path: 'referrer',
      label: 'Referrals',
      Element: (
        <ListCustom<User>
          key="referrer"
          cellDefinition={CellUserDefinition}
          results={user?.referrals || []}
          enableFilters
        />
      ),
    },
    {
      path: 'promotions',
      label: t('databaseExplorer.users.tabs.promotions'),
      Element: (
        <PromotionDetail
          userTargetPromotionList={user?.userTargetPromotions || []}
          key="promotions"
          user={user}
        />
      ),
    },
    {
      path: 'zendesk',
      shouldHide: !hasPermissions(PERMISSIONS.USER.READ_USER_ZENDESK_TICKETS),
      label: `Zendesk (${zendeskData?.data.zendeskAllTicketsCount || 0})`,
      Element: <ZendeskTicketsTab key="zendesk" />,
    },
    {
      path: 'seller-config',
      label: t('databaseExplorer.users.tabs.sellerInformation'),
      shouldHide: !user?.isSeller,
      Element: <SellerPanel channel={user?.channel} key="seller-config" user={user} />,
      hasChildren: true,
    },
    {
      path: 'payout',
      shouldHide: !(user?.isSeller && hasPermissions(PERMISSIONS.LEDGER.READ_LEDGER_VIEW)),
      label: t('databaseExplorer.users.tabs.payout'),
      Element: <UserPayoutList key="payout" userId={Number(userId)} />,
    },
    {
      path: 'mux-assets',
      shouldHide: !user?.channel?.providerId,
      label: t('databaseExplorer.channel.tabs.mux'),
      Element: <UserMuxAssetsTab userId={Number(userId)} key="mux-assets" />,
    },
  ];

  return (
    <>
      <ItemLayout<User>
        placeholderIcon={<UserIcon className="text-white" size={90} strokeWidth={2.5} />}
        id={String(userId)}
        tabs={tabs}
        title={`@${user?.username}`}
        onEditButtonClick={() => navigate(`/user/${userId}/edit`)}
        imageURL={user?.avatarUrl ?? undefined}
        description={`${user?.firstName} ${user?.lastName}`}
        currentTab={
          [
            'buyer-order',
            'seller-order',
            'show',
            'shipping-address',
            'product',
            'referrer',
            'promotions',
            'seller-config',
            'channel',
            'mux-assets',
            'zendesk',
            'payout',
            'shipment-buyer',
            'shipment-rating',
            'shipment-seller',
          ].find((path) => location.pathname.includes(path)) ?? '/'
        }
        onTabChange={handleOnTabChange}
        customActions={itemsMenuList}
        editionDescriptor={
          isReadOnly
            ? undefined
            : {
                goBackName: t('databaseExplorer.editionFormPath.users', { userId }),
                resourceId: Number(userId),
                resourceType: 'User',
                onSuccess: () => {
                  queryClient.invalidateQueries(QUERIES_KEYS.userDetail(Number(userId)));
                },
              }
        }
      />

      <ConfirmUnblockUserModal
        isOpen={unblockUserHook.isOpen}
        onCancel={unblockUserHook.toggle}
        onConfirm={unblockUserHook.onConfirmUnblockUser}
        username={user.username}
      />

      <ToggleBanUser
        onConfirm={() => banUserHook.banUser(user)}
        onCancel={banUserHook.toggleIsOpen}
        user={user}
        isOpen={banUserHook.isOpen}
      />

      <DeleteUser
        onConfirm={deleteUserHook.onUserDelete}
        onCancel={deleteUserHook.onClose}
        user={user}
        isOpen={deleteUserHook.isOpen}
      />

      <AddBadgeModal {...addBadgeHook} username={user?.username || user?.id} />
      <RemoveBadgeModal {...removeBadgeHook} username={user?.username || user?.id} />

      <ImpersonateAccessModal
        requestImpersonate={impersonateFeatureHook.requestImpersonate}
        impersonateModalFeatureIsOpen={impersonateFeatureHook.impersonateModalFeatureIsOpen}
        toggleModal={impersonateFeatureHook.toggleImpersonateModalFeature}
      />

      {directFundTransferHook.modalIsOpen && (
        <DirectFundTransferModal
          onConfirm={directFundTransferHook.submitUpdate}
          onCancel={directFundTransferHook.toggleModalDirectFundTransfer}
          isOpen={directFundTransferHook.modalIsOpen}
          currentBalance={directFundTransferHook.availableBalance}
          error={directFundTransferHook.error}
          isLoading={directFundTransferHook.isLoading}
        />
      )}

      <SentinelModal
        onConfirm={sentinelActionHook.mutate}
        onCancel={sentinelActionHook.toggleModalIsOpen}
        isOpen={sentinelActionHook.modalIsOpen}
        sentinelFraudCases={sentinelActionHook.sentinelFraudCases}
        isLoading={sentinelActionHook.isLoading}
      />

      <RemoveUserSsoModal
        isOpen={ssoRemovalHook.unlinkSsoIsOpen}
        onConfirm={ssoRemovalHook.handleConfirm}
        onCancel={ssoRemovalHook.toggleSso}
        user={user}
      />

      <RestoreUserAccountModal
        isOpen={restoreUserAccountHook.restorationModalIsOpen}
        onConfirm={restoreUserAccountHook.handleConfirm}
        toggle={restoreUserAccountHook.toggleRestorationModal}
        user={user}
      />

      <RestoreSso
        isOpen={restoreUserSsoHook.ssoRestorationModalIsOpen}
        onConfirm={restoreUserSsoHook.handleConfirm}
        toggle={restoreUserSsoHook.toggleRestorationModal}
      />
    </>
  );
};
