import {
  ReadModulePermissions,
  ReadModuleSubModulePermissions,
  WriteModulePermissions,
  WriteModuleSubModulePermissions,
} from './permissions.type';

export const buildReadModuleSubModulePermissions = <
  Module extends string,
  Permissions extends readonly string[],
>(
  module: Module,
  permissions: Permissions,
) => {
  if (permissions.length === 0) {
    throw new Error(
      'No submodules. Please use `buildReadModulePermissions` function instead.',
    );
  }

  return permissions.reduce(
    (
      all: ReadModuleSubModulePermissions<Module, Permissions>,
      permission: Permissions[number],
    ) => {
      const READ = `READ_${module.toUpperCase()}_${permission.toUpperCase()}`;

      return {
        ...all,
        [READ]: READ,
      };
    },
    {} as ReadModuleSubModulePermissions<Module, Permissions>,
  );
};

export const buildWriteModuleSubModulePermissions = <
  Module extends string,
  Permissions extends readonly string[],
>(
  module: Module,
  permissions: Permissions,
) => {
  if (permissions.length === 0) {
    throw new Error(
      'No submodules. Please use `buildWriteModulePermissions` function instead.',
    );
  }

  return permissions.reduce(
    (all, permission) => {
      const WRITE = `WRITE_${module.toUpperCase()}_${permission.toUpperCase()}`;

      return {
        ...all,
        [WRITE]: WRITE,
      } as const;
    },
    {} as WriteModuleSubModulePermissions<Module, Permissions>,
  );
};

export const buildModuleSubModulePermissions = <
  Module extends string,
  Permissions extends readonly string[],
>(
  module: Module,
  permissions: Permissions,
) => {
  if (permissions.length === 0) {
    throw new Error(
      'No submodules. Please use `buildModulePermissions` function instead.',
    );
  }

  return {
    ...buildReadModuleSubModulePermissions(module, permissions),
    ...buildWriteModuleSubModulePermissions(module, permissions),
  };
};

export const buildReadModulePermissions = <Module extends string>(
  module: Module,
) => {
  const READ = `READ_${module.toUpperCase()}`;

  return {
    [READ]: READ,
  } as ReadModulePermissions<Module>;
};

export const buildWriteModulePermissions = <Module extends string>(
  module: Module,
) => {
  const WRITE = `WRITE_${module.toUpperCase()}`;

  return {
    [WRITE]: WRITE,
  } as WriteModulePermissions<Module>;
};

export const buildModulePermissions = <Module extends string>(
  module: Module,
) => {
  return {
    ...buildReadModulePermissions(module),
    ...buildWriteModulePermissions(module),
  };
};
