import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderItem } from '@/entities/order-item.entity';

const INITIAL_FIELD_STATE = {
  loading: false,
  error: null,
  values: [],
  count: 0,
};

export type OrderItemsMassUpdateState = {
  orderItems: OrderItem[];
  count: number;
  loading: boolean;
  error: string | null;
  updating: boolean;
  filters: Record<
    string,
    {
      loading: boolean;
      error: string | null;
      values: string[];
      count: number;
    }
  >;
};

export const orderItemsMassUpdateSlice = createSlice({
  name: 'orderItemsMassUpdate',
  initialState: {
    orderItems: [],
    count: 0,
    loading: false,
    error: null,
    updating: false,
    filters: {},
  } as OrderItemsMassUpdateState,
  reducers: {
    setEntities: (state, { payload }: PayloadAction<OrderItem[]>) => {
      state.orderItems = payload;
    },
    setCount: (state, { payload }: PayloadAction<OrderItemsMassUpdateState['count']>) => {
      state.count = payload;
    },
    setLoading: (state, { payload }: PayloadAction<OrderItemsMassUpdateState['loading']>) => {
      state.loading = payload;
    },
    setError: (state, { payload }: PayloadAction<OrderItemsMassUpdateState['error']>) => {
      state.error = payload;
    },
    setUpdating: (state, { payload }: PayloadAction<OrderItemsMassUpdateState['updating']>) => {
      state.updating = payload;
    },
    setFiltersLoading: (state, { payload }: PayloadAction<{ field: string; loading: boolean }>) => {
      if (!state.filters[payload.field]) {
        state.filters[payload.field] = { ...INITIAL_FIELD_STATE };
      }

      state.filters[payload.field].loading = payload.loading;
    },
    setFiltersError: (
      state,
      { payload }: PayloadAction<{ field: string; error: null | string }>,
    ) => {
      if (!state.filters[payload.field]) {
        state.filters[payload.field] = { ...INITIAL_FIELD_STATE };
      }

      state.filters[payload.field].error = payload.error;
    },
    setFiltersValues: (
      state,
      { payload }: PayloadAction<{ field: string; values: string[]; count: number }>,
    ) => {
      if (!state.filters[payload.field]) {
        state.filters[payload.field] = { ...INITIAL_FIELD_STATE };
      }

      state.filters[payload.field].values = payload.values;
      state.filters[payload.field].count = payload.count;
    },
    addFiltersValues: (state, { payload }: PayloadAction<{ field: string; values: string[] }>) => {
      if (!state.filters[payload.field]) {
        state.filters[payload.field] = { ...INITIAL_FIELD_STATE };
      }

      state.filters[payload.field].values = [
        ...new Set([...state.filters[payload.field].values, ...payload.values]),
      ];
    },
    resetFiltersValues: (state, { payload }: PayloadAction<string>) => {
      if (state.filters[payload]) {
        state.filters[payload] = { ...INITIAL_FIELD_STATE };
      }
    },
  },
});

export const orderItemsMassUpdateActions = orderItemsMassUpdateSlice.actions;

export default orderItemsMassUpdateSlice.reducer;
