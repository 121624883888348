import { CommercialEvent } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useListCommercialEventsByShowId = (showId: number) => {
  return useQuery<CommercialEvent[], string>({
    queryKey: QUERIES_KEYS.commercialEventsByShowId(showId),
    queryFn: () => getCommercialEventsByShowId(showId),
  });
};

const getCommercialEventsByShowId = async (showId: number): Promise<CommercialEvent[]> => {
  try {
    const result = await authenticatedInstance.get<CommercialEvent[]>(
      `/database-explorer/commercial-event/by-show/${showId}`,
    );
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError<{ message?: string }>) {
      throw error.response?.data.message;
    }

    throw String(error);
  }
};
