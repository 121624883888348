import { OrderItemsDistinctValuesFindOptions } from '@bits-app/voggtpit-shared';

import { authActions } from '@/auth/auth.slice';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { orderItemsMassUpdateActions } from '../redux/order-items-mass-update.slice';

const LIMIT = 100;

export const getOrderItemsFilterValuesFor =
  ({
    field,
    search,
    limit = LIMIT,
  }: {
    field: OrderItemsDistinctValuesFindOptions['field'];
    search: OrderItemsDistinctValuesFindOptions['search'];
    limit?: OrderItemsDistinctValuesFindOptions['limit'];
  }): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { orderItemsMassUpdateGateway, snackbarGateway }) => {
    if (search.length < 2) {
      if (search.length === 0) {
        dispatch(orderItemsMassUpdateActions.resetFiltersValues(field));
      }

      return;
    }

    dispatch(orderItemsMassUpdateActions.setFiltersLoading({ field: field, loading: true }));

    try {
      const result = await orderItemsMassUpdateGateway.getFiltersValueFor(
        {
          field,
          search,
          limit,
          page: 0,
        },
        { abortPrevious: true },
      );

      dispatch(orderItemsMassUpdateActions.setFiltersValues({ field: field, ...result }));
      dispatch(orderItemsMassUpdateActions.setFiltersLoading({ field: field, loading: false }));
    } catch (error) {
      logger.error(error);

      if (error instanceof CanceledRequestError) {
        return;
      }

      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));
        dispatch(orderItemsMassUpdateActions.setFiltersLoading({ field: field, loading: false }));

        return;
      }

      dispatch(orderItemsMassUpdateActions.setFiltersLoading({ field: field, loading: false }));
      snackbarGateway.error('somethingWrongHappened');
    }
  };
