import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  JoinColumn,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { User } from './User';

export interface RestreamUrls {
  name: string;
  url: string;
}

@Entity()
export class Channel {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column({ nullable: true })
  provider!: string;

  @Column({ nullable: true })
  providerId!: string;

  @Column({ nullable: true, select: false })
  rtmp!: string;

  @Column({ nullable: true, select: false })
  streamKey!: string;

  @Column({
    type: 'jsonb',
    array: false,
    default: () => "'[]'",
    nullable: false,
    select: false,
  })
  restreamUrls: Array<RestreamUrls> = [];

  @Column({ nullable: true })
  hls!: string;

  @OneToOne(() => User, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Column({ nullable: true })
  userId!: number;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;
}
