import { useMemo } from 'react';

import { Badge } from '@bits-app/bits-server-data';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { BadgeRelatedUserView } from '@/voggt-database-explorer/badge/views/detail/RelatedUserView';
import { ItemLayout, ItemTab } from '@/voggt-database-explorer/layouts/ItemLayout';

import { Detail } from './Detail';
import { useBadgeDetailsView } from './useBadgeDetailsView';

type BadgeDetailProps = {
  isDraft?: boolean;
};

export const BadgeDetail = ({ isDraft }: BadgeDetailProps) => {
  const { t } = useTranslation();

  const badgeId = useIdParam();
  const location = useLocation();

  const { CellUserDefinition, ...rest } = useBadgeDetailsView(badgeId, isDraft);

  const currentTab = useMemo(() => {
    return ['user'].find((path) => location.pathname.includes(path)) ?? '/';
  }, [location.pathname]);

  const handleOnTabChange = useNavigateInDetailTab(`/badge/${badgeId}`);

  const tabs: ItemTab[] = useMemo(() => {
    const allTabs: ItemTab[] = [
      {
        path: '/',
        label: t('databaseExplorer.badge.tabs.main'),
        Element: <Detail key="/" {...rest} isDraft={isDraft} />,
      },
    ];

    if (!isDraft) {
      allTabs.push({
        path: 'user',
        label: t('databaseExplorer.badge.tabs.users', { count: rest.badgeRelatedUsersCount ?? 0 }),
        Element: (
          <BadgeRelatedUserView
            key="badge-related-user"
            badgeId={Number(badgeId)}
            cellDefinition={CellUserDefinition}
          />
        ),
      });
    }

    return allTabs;
  }, [CellUserDefinition, badgeId, isDraft, rest, t]);

  return (
    <ItemLayout<Badge>
      id={badgeId}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
    />
  );
};
