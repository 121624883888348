import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ButtonContained } from '@/components/elements/buttons/ButtonContained';

import { UserTargetPromotionTypeWithPromotion } from '../Promotions/type';

type DeleteUserTargetPromotionModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  userTargetPromotionToDelete: UserTargetPromotionTypeWithPromotion | null;
};

export const DeleteUserTargetPromotionModal = ({
  onConfirm,
  onCancel,
  userTargetPromotionToDelete,
}: DeleteUserTargetPromotionModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={Boolean(userTargetPromotionToDelete)}>
      <DialogTitle id="alert-dialog-title">
        {t('databaseExplorer.userTargetPromotion.delete.title')}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Trans
            i18nKey="databaseExplorer.userTargetPromotion.delete.description"
            values={{ promotionName: userTargetPromotionToDelete?.promotion?.name }}
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>{t('action.cancel')}</Button>
        <ButtonContained onClick={onConfirm} color="error">
          {t('action.continue')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
