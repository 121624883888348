import { useState } from 'react';

import { type PubsubEventPayload } from '@bits-app/pubsub';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';

import { authenticatedInstance } from '../../../../../../axios/axios.instance';
import { useOwnUser } from '../../../../../../context/own-user.context';
import { User } from '../../../../users';

export const useLedgerBulkUpdateTransactionStatus = (sellerId: User['id']) => {
  const { hasPermissions } = useOwnUser();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModalStatus = () => setModalIsOpen((prev) => !prev);
  const hasPermissionsToBulkUpdateTransactionStatus = hasPermissions(
    PERMISSIONS.LEDGER.WRITE_LEDGER_UPDATE_TRANSACTION,
  );

  const snackbar = useSnackbar();

  const { mutate } = useMutation(
    async (payload: {
      sellerId: User['id'];
      messages: PubsubEventPayload['ledger-update-transaction-status'][];
    }) => {
      if (!hasPermissionsToBulkUpdateTransactionStatus) return Promise.reject('No permission');
      if (!payload.messages.length) return Promise.reject('No messages');
      const result = await authenticatedInstance.post(
        '/ledger/bulk-update-transaction-status',
        payload,
      );
      snackbar.success('Transaction status updated');
      toggleModalStatus();
      return result?.data;
    },
  );

  const submit = (messages: PubsubEventPayload['ledger-update-transaction-status'][]) => {
    mutate({ sellerId, messages });
  };

  return {
    hasPermissionsToBulkUpdateTransactionStatus,
    toggleModalStatus,
    modalIsOpen,
    submit,
  };
};
