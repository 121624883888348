import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';

import { OverrideTypesenseOrder, useCellDefinition } from '../../use-cell-definition';

const TYPESENSE_MAX_RESULT_PER_PAGE = 30;

export const ListOrder = () => {
  const { CellOrderDefinition } = useCellDefinition();

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListWithSearch<OverrideTypesenseOrder>
        title="Orders"
        cellDefinition={CellOrderDefinition}
        typesenseHookParams={{
          typesenseIndex: 'order',
          typesenseParams: {
            per_page: TYPESENSE_MAX_RESULT_PER_PAGE,
            query_by: [
              'product',
              'seller',
              'customer',
              'orderId',
              'trackingNumber',
              'stripePaymentIntentId',
            ],
          },
        }}
        dataListProps={{ enableFilters: true }}
      />
    </div>
  );
};
