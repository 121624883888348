import { OrderCancellationReason } from '@bits-app/voggtpit-shared';

import { Order } from '../entities/order.entity';

export interface OrderPort {
  updatePayoutStatusPurchasedFromShop(
    orderIds: Order['id'][],
    payoutStatus: Order['payoutStatus'],
  ): Promise<void>;
  cancelOrder(orderId: Order['id'], cancellationReason: OrderCancellationReason): Promise<Order>;
}

export class NotAllOrderUpdatedError extends Error {
  constructor(readonly failedOrders: Record<Order['id'], string>) {
    super();
  }
}
