import { Alert, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '@/redux/reduxAppHooks';

import { authActions } from '../../auth/auth.slice';

export const ShouldReLogDialog = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onLogin = () => {
    dispatch(authActions.setOwnUser(null));
    dispatch(authActions.setShouldReLog(false));
  };

  return (
    <Dialog open>
      <Alert severity="error" sx={{ display: 'flex', alignItems: 'center' }}>
        {t('shouldReLog.title')}
      </Alert>

      <DialogContent>{t('shouldReLog.content')}</DialogContent>

      <DialogActions
        sx={{
          padding: ({ spacing }) => spacing(3),
        }}
      >
        <Button variant="contained" onClick={onLogin}>
          {t('login')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
