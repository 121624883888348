import { useState } from 'react';

import { Box, Popover, Typography } from '@mui/material';
import { MRT_Header } from 'material-react-table';
import { useTranslation } from 'react-i18next';

import { EnumFilter } from '@/components/elements/filters/EnumFilter/EnumFilter';

type EnumFilterMRTProps<T extends Record<string, unknown>> = {
  options: { value: string; label?: string }[];
  activeFilters?: string[];
  header: MRT_Header<T>;
};

export const EnumFilterMRT = <T extends Record<string, unknown>>({
  options,
  activeFilters = [],
  header,
}: EnumFilterMRTProps<T>) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);

  const onOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => setAnchorEl(null);

  const handleToggle = (value: string) => () => {
    const currentIndex = activeFilters.indexOf(value);
    const newChecked = [...activeFilters];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    header.column.setFilterValue(newChecked);
  };

  const handleToggleAll = () => () => {
    if (activeFilters.length === options.length) {
      header.column.setFilterValue([]);
      return;
    }

    header.column.setFilterValue(options.map(({ value }) => value));
  };

  return (
    <>
      <Box
        onClick={onOpen}
        sx={{
          maxWidth: header.column.columnDef.size,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
          px: 0.5,
        }}
      >
        <Typography fontWeight={400} variant="caption" title={activeFilters.join(', ')}>
          {activeFilters.length ? activeFilters.join(', ') : t('applyFilters')}
        </Typography>
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          maxHeight: 400,
        }}
      >
        <EnumFilter
          checked={(header.column.getFilterValue() ?? []) as string[]}
          onToggle={handleToggle}
          availableOptions={options}
          onToggleAll={handleToggleAll}
        />
      </Popover>
    </>
  );
};
