import { useMemo } from 'react';

import { ShippingAddress } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { useTypesenseSearch } from '@/hooks/use-typesense';
import { useSearchVoggtPayloadType } from '@/hooks/use-typesense/use-typesense';

import { User } from '../users/users';

export const useCellShippingAddressDefinition = () => {
  const params: useSearchVoggtPayloadType<User> = useMemo(
    () => ({
      typesenseIndex: 'user',
      typesenseParams: {
        per_page: 20,
        query_by: ['username'],
        include_fields: 'username',
      },
    }),
    [],
  );

  const {
    results: userOptions,
    setSearch: setUserSearch,
    loading: userLoading,
  } = useTypesenseSearch<User>(params);

  return useMemo(
    (): CellDefinition<ShippingAddress & { user: string }>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (shippingAddress) => ({
          link: `/shipping-address/${shippingAddress?.id}`,
          label: shippingAddress?.id,
        }),
        enableColumnFilter: false,
      },
      {
        id: 'user',
        label: 'User',
        type: 'internal-link',
        getLink: (shippingAddress) => ({
          link: `/user/${shippingAddress?.userId}`,
          label: shippingAddress?.user,
        }),
        columnFilter: {
          type: 'autocomplete',
          options: userOptions
            .map((user) => ({ value: user.username }))
            .filter((option) => Boolean(option?.value)),
          onAutocomplete: setUserSearch,
          loading: userLoading,
        },
      },
      { id: 'name', label: 'Name', columnFilter: { type: 'text' } },
      { id: 'inputLine1', label: 'Input line 1', enableColumnFilter: false },
      {
        id: 'inputPostalCode',
        label: 'Postal code',
        enableColumnFilter: false,
      },
      { id: 'inputCity', label: 'City', enableColumnFilter: false },
      { id: 'inputCountry', label: 'Country', enableColumnFilter: false },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
        enableColumnFilter: false,
      },
      {
        id: 'deletedAt',
        label: 'Deleted at',
        type: 'date-with-time',
        enableColumnFilter: false,
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
        enableColumnFilter: false,
      },
    ],
    [setUserSearch, userLoading, userOptions],
  );
};
