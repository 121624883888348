type ProductAttributes = {
  id: number;
  name: string;
};

export class Product {
  readonly id: number;

  readonly name: string;

  constructor(attributes: ProductAttributes) {
    this.id = attributes.id;
    this.name = attributes.name;
  }
}
