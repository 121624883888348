import { useMemo } from 'react';

import { Category, ParentCategory } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

export const useCellDefinition = () => {
  const CellCategoryDefinition = useMemo(
    (): CellDefinition<Category>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (category) => ({ link: `/category/child/${category.id}`, label: category?.id }),
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date',
      },
      {
        id: 'name',
        label: 'name',
      },
      {
        id: 'isNew',
        label: 'is new',
        type: 'boolean',
      },
      {
        id: 'isVisible',
        label: 'Is visible',
        type: 'boolean',
      },

      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  const CellParentCategoryDefinition = useMemo(
    (): CellDefinition<ParentCategory>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (parentCategory) => ({
          link: `/category/parent/${parentCategory.id}`,
          label: parentCategory?.id,
        }),
      },
      {
        id: 'name',
        label: 'name',
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date-with-time',
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  return { CellCategoryDefinition, CellParentCategoryDefinition };
};
