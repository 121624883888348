import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';

import { useOwnUser } from '@/context/own-user.context';
import { ButtonOutlined } from '@Elements/buttons/ButtonOutlined';

export const CreateShippingFeeConfig = () => {
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();
  const isReadOnly = !hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_SHIPPING_FEE_CONFIG,
  );

  if (isReadOnly) {
    return null;
  }

  return (
    <ButtonOutlined href="/shipping-fee-config/create">
      {t('databaseExplorer.shippingFeeConfig.create.action')}
    </ButtonOutlined>
  );
};
