import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
} from 'typeorm'

import { SellerConfig } from './SellerConfig'

@Entity()
export class SellerSubAccount {
  @PrimaryGeneratedColumn('uuid')
  id!: string

  @ManyToOne(() => SellerConfig, (sellerConfig) => sellerConfig.subAccounts)
  @JoinColumn({ name: 'sellerConfigId' })
  sellerConfig!: SellerConfig

  @Index()
  @Column()
  sellerConfigId!: SellerConfig['id']

  @Column()
  email!: string

  @Column({ type: 'timestamp', nullable: true })
  lastLoginAt!: Date | null

  @CreateDateColumn()
  createdAt!: Date
}
