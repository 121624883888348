import { Permission } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { updateOwnUserPermissions } from '@/administration/use-cases';
import { useOwnUser } from '@/context/own-user.context';
import { OwnUser } from '@/domain/entities/own-user.entity';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export const useMutatePermissions = (ownUser: OwnUser | null, handleClose: () => void) => {
  const snackbar = useSnackbar();

  const { ownUser: me, refetchMe } = useOwnUser();

  return useMutation(
    (params: [OwnUser['id'], Permission[]]) => updateOwnUserPermissions(...params),
    {
      onMutate: async ([ownUserId, permissions]: [OwnUser['id'], Permission[]]) => {
        const ownUsers = await queryClient.getQueryData<OwnUser[]>(QUERIES_KEYS.ownUsersList());

        if (ownUsers) {
          queryClient.setQueryData(QUERIES_KEYS.ownUsersList(), (old?: OwnUser[]) => {
            if (!old) {
              return;
            }

            return old.map((oldOwnUser) =>
              oldOwnUser.id === ownUserId ? { ...oldOwnUser, permissions } : oldOwnUser,
            );
          });
        }

        return ownUsers ? { ownUsers } : undefined;
      },
      onSuccess: async () => {
        if (ownUser?.id === me?.id) {
          refetchMe();
        }

        snackbar.success('administration.managePermissions.success', {
          interpolationMap: { username: ownUser?.fullName ?? '' },
        });

        handleClose();
      },
      onError: (_err, _params, context?: { ownUsers: OwnUser[] }) => {
        queryClient.setQueryData(QUERIES_KEYS.ownUsersList(), context?.ownUsers);

        snackbar.error('administration.managePermissions.error', {
          interpolationMap: { username: ownUser?.fullName ?? '' },
        });
      },
    },
  );
};
