import { CommercialEvent } from '@bits-app/bits-server-data';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { validationSchemasBuilder } from '@/voggt-database-explorer';
import { UpsertForm } from '@/voggt-database-explorer/components/upsert-view/UpsertForm';

import { useCreateCommercialEvent } from './use-create-commercial-event';

export const CommercialEventCreationView = () => {
  const { t } = useTranslation();

  const { header, schemas, onCreate, isLoading } = useCreateCommercialEvent();
  const validationSchemaBuilder = validationSchemasBuilder['CommercialEvent'];

  const goBackName = t('databaseExplorer.commercialEvent.list');

  if (!schemas.length || !header) {
    return null;
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <UpsertForm<CommercialEvent>
      schemas={schemas}
      header={header}
      onUpsert={onCreate}
      goBackName={goBackName}
      validationSchemaBuilder={validationSchemaBuilder}
    />
  );
};
