import { useEffect, useMemo } from 'react';

import { Order } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { ShoppingBasket } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { useAppDispatch } from '@/redux/reduxAppHooks';
import { OrdersWithExport } from '@/voggt-database-explorer/components/OrdersWithExport';
import { ItemLayout, ItemTab } from '@/voggt-database-explorer/layouts/ItemLayout';
import { cleanUpResource } from '@/voggt-database-explorer/redux/use-cases/clean-up-resource';
import { ShowOrderRowActions } from '@/voggt-database-explorer/show/views/detail/ShowOrders/ShowOrderRowActions';

import { useShipmentDetailFromOrderId } from '../../../shipment/useShipmentDetailFromOrderId.hook';

import { Details } from './Details';
import { OrderEventsList } from './OrderEventsList';
import { CellOrderDefinition, useDetailsView } from './useDetailsView';

export const OrderDetail = () => {
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();
  const isReadOnly = !hasPermissions(PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_PRODUCT);

  const orderId = useIdParam();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const { order, refreshData, loading } = useDetailsView(orderId);

  const { data: shipment } = useShipmentDetailFromOrderId(Number(orderId));

  const handleOnTabChange = useNavigateInDetailTab(`/order/${orderId}`);

  const tabs: ItemTab[] = useMemo(
    () => [
      {
        path: '/',
        label: t('databaseExplorer.order.tabs.details'),
        Element: (
          <Details
            key="/"
            loading={loading}
            order={order}
            isReadOnly={isReadOnly}
            shipment={shipment}
          />
        ),
      },
      {
        path: 'other-item-in-order',
        label: t('databaseExplorer.order.tabs.buyerOrders'),
        Element: (
          <OrdersWithExport<
            Order & { id: string; seller: string; customer: string; product: string }
          >
            key="other-item-in-order"
            cellDefinition={CellOrderDefinition}
            filters={{ showId: order.showId, customerId: order.customerId }}
            renderRowActions={({ row }) => {
              if (row.original === null) {
                return null;
              }
              return <ShowOrderRowActions order={row.original} />;
            }}
          />
        ),
      },
      {
        path: 'order-events',
        label: t('databaseExplorer.order.tabs.orderEvents'),
        Element: <OrderEventsList key="order-events" />,
      },
    ],
    [t, loading, order, isReadOnly, shipment],
  );

  const currentTab = useMemo(() => {
    return (
      ['other-item-in-order', 'order-events'].find((path) => location.pathname.includes(path)) ??
      '/'
    );
  }, [location.pathname]);

  useEffect(() => {
    return () => dispatch(cleanUpResource());
  }, [dispatch]);

  return (
    <ItemLayout<Order>
      placeholderIcon={<ShoppingBasket className="text-white" size={90} strokeWidth={2.5} />}
      id={orderId}
      title={`Order #${orderId}`}
      description={order?.product?.name ?? '-'}
      imageURL={order?.product?.images?.[0]?.url ?? ''}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
      editionDescriptor={
        isReadOnly
          ? undefined
          : {
              goBackName: t('databaseExplorer.editionFormPath.orders', { orderId }),
              resourceId: Number(orderId),
              resourceType: 'Order',
              onSuccess: refreshData,
            }
      }
    />
  );
};
