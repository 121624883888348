import { useCallback, useState } from 'react';

import { Promotion } from '@bits-app/bits-server-data';
import { useMutation } from '@tanstack/react-query';

import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { User } from '@/voggt-database-explorer/users/users';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { QUERIES_KEYS, queryClient } from '../../../queries';

export type SelectedPromotion = Record<Promotion['id'], { count: number; name: Promotion['name'] }>;
export const useAttachUserToPromotion = (userId: User['id']) => {
  const [selectedPromotion, setSelectPromotions] = useState<SelectedPromotion>({});
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const snackbar = useSnackbar();

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const list = Object.keys(selectedPromotion).map((promotionId) => {
        const count = selectedPromotion[Number(promotionId)].count || 1;
        const userIds = new Array(count).fill(userId);
        return authenticatedInstance.post(`/promotions/${promotionId}/users`, { userIds });
      });
      return Promise.all(list);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.userDetail(userId));
      snackbar.success('databaseExplorer.users.promotion.done');
    },
    onError: () => {
      snackbar.error('somethingWrongHappened');
    },
  });

  const toggleModal = useCallback(() => setModalIsOpen(!modalIsOpen), [modalIsOpen]);

  return {
    toggleModal,
    modalIsOpen,
    isLoading,
    handleSubmit: () => mutate(),
    selectedPromotion,
    setSelectPromotions,
  };
};
