import { useCallback, useState } from 'react';

import { DEFAULT_LANG_ID, User } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { UseFormSetError } from 'react-hook-form';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { useOwnUser } from '@/context/own-user.context';
import { FieldError } from '@/domain/errors/FieldError';

import { QUERIES_KEYS, queryClient } from '../../../queries';
import { FieldValue } from '../views/profile/RemoveUserSso/RemoveUserSso.modal';

type UnlinkSsoParams = {
  userId: User['id'];
  language: User['language'];
  verifiedPhone?: User['phoneNumber'];
  verifiedEmail?: User['email'];
};

export const useSsoRemoval = (
  userId: User['id'],
  setFieldError: UseFormSetError<FieldValue>,
  user: User,
) => {
  const [unlinkSsoIsOpen, setUnlinkSsoIsOpen] = useState(false);
  const snackbar = useSnackbar();
  const { hasPermissions } = useOwnUser();

  const { mutate: UnlinkSso, isLoading: isUnlinkingSso } = useMutation(unlinkSso, {
    onSuccess: () => {
      snackbar.success('SSO removed successfully.');
      queryClient.invalidateQueries(QUERIES_KEYS.userDetail(userId));
    },
    onError: (error) => {
      snackbar.error('Failed to remove SSO.');
      if (error instanceof FieldError) {
        switch (error.field) {
          case 'verifiedPhone':
            setFieldError(error.field, { message: error.message });
            break;
          case 'verifiedEmail':
            setFieldError(error.field, { message: error.message });
            break;
        }
      }
    },
    onSettled: () => {
      setUnlinkSsoIsOpen(false);
    },
  });

  const toggleSso = useCallback(() => {
    setUnlinkSsoIsOpen((prev) => !prev);
  }, []);

  const handleConfirm = (data: FieldValue) => {
    UnlinkSso({
      userId: user.id,
      verifiedEmail: data.verifiedEmail ?? user.email,
      verifiedPhone: data.verifiedPhone ?? user.phoneNumber,
      language: user.language,
    });
  };

  const shouldDisplaySsoRemoval = [
    hasPermissions(PERMISSIONS.USER.WRITE_USER_REMOVE_SSO),
    user,
    user?.userSso,
    !user?.userSso?.subject?.includes('deleted'),
  ].every(Boolean);

  return {
    unlinkSsoIsOpen,
    toggleSso,
    isUnlinkingSso,
    UnlinkSso,
    shouldDisplaySsoRemoval,
    handleConfirm,
  };
};

const unlinkSso = async ({
  userId,
  verifiedPhone,
  verifiedEmail,
  language = DEFAULT_LANG_ID,
}: UnlinkSsoParams) => {
  try {
    await authenticatedInstance.post(`/database-explorer/customer/${userId}/unlink-sso`, {
      userId,
      verifiedPhone,
      verifiedEmail,
      language,
    });
  } catch (error) {
    throw new Error('Failed to remove SSO.');
  }
};
