export enum PayoutState {
  'not_started' = 'not_started',
  'paying' = 'paying',
  'paid' = 'paid',
  'payment_failed' = 'payment_failed',
  'sending_email' = 'sending_email',
  'email_sent' = 'email_sent',
  'emailing_failed' = 'emailing_failed',
  'completed' = 'completed',
  'manual' = 'manual',
}
