import { Permission } from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import { OwnUser, ownUserDtoToEntity } from '@/domain/entities/own-user.entity';

export const updateOwnUserPermissions = async (
  ownUserId: OwnUser['id'],
  permissions: Permission[],
): Promise<OwnUser> => {
  const response = await authenticatedInstance.put(`/user/${ownUserId}/permissions`, {
    permissions,
  });

  return ownUserDtoToEntity(response.data);
};
