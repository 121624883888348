import {
  centsToUnit,
  CreatePartnerProgramDto,
  PartnerProgramType,
  UpdatePartnerProgramDto,
} from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';

import { PartnerProgramPort } from './partner-program.port';

export class HttpPartnerProgramAdapter implements PartnerProgramPort {
  async updateOne(
    partnerProgramId: string,
    partnerProgramChange: UpdatePartnerProgramDto,
  ): Promise<void> {
    try {
      await authenticatedInstance.post(
        `/partner-program/${partnerProgramId}`,
        partnerProgramChange,
      );
    } catch (error) {
      console.log('Error ', error);
      throw new Error(`Something wrong happened: ${error}`);
    }
  }

  async getAll(): Promise<PartnerProgramType[]> {
    try {
      const { data } = await authenticatedInstance.get<PartnerProgramType[]>('/partner-program');
      return data.map((partnerProgram) => ({
        ...partnerProgram,
        amount: partnerProgram.amount != null ? centsToUnit(partnerProgram.amount) : undefined,
        percentageMaxAmount:
          partnerProgram.percentageMaxAmount != null
            ? centsToUnit(partnerProgram.percentageMaxAmount)
            : undefined,
      }));
    } catch (error) {
      throw new Error(`Something wrong happened: ${error}`);
    }
  }

  async deleteSafe(partnerProgramId: string): Promise<void> {
    try {
      await authenticatedInstance.delete(`/partner-program/${partnerProgramId}`);
    } catch (error) {
      console.log(error);
      throw new Error(`Something wrong happened: ${error}`);
    }
  }

  async create(partnerProgramDraft: CreatePartnerProgramDto): Promise<PartnerProgramType> {
    try {
      const response = await authenticatedInstance.post('/partner-program', partnerProgramDraft);
      return response.data;
    } catch (error) {
      console.log('Error ', error);
      throw new Error(`Something wrong happened: ${error}`);
    }
  }
}
