import { unitToCents, CreatePartnerProgramDto, centsToUnit } from '@bits-app/voggtpit-shared';

import { AppThunkAction } from '@/redux/store';

import { selectPartnerPrograms } from '../partner-program.selector';
import { partnerProgramActions } from '../partner-program.slice';

export const createPartnerProgram =
  (data: CreatePartnerProgramDto): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { partnerProgramGateway, snackbarGateway }) => {
    const hasAmountOrPercentage = data.amount || (data.percentage && data.percentageMaxAmount);
    if (![data.name, hasAmountOrPercentage].every(Boolean)) {
      snackbarGateway.error('Missing Field');
      return;
    }

    const allPartnerProgram = selectPartnerPrograms(getState());
    const allName = allPartnerProgram.map((partnerProgram) => partnerProgram.name);
    const nameAlreadyExist = allName.includes(data.name);
    if (nameAlreadyExist) {
      snackbarGateway.error('name already exist');
      return;
    }

    dispatch(partnerProgramActions.setIsLoading(true));
    try {
      const { amount, percentageMaxAmount, ..._data } = data;

      const newPartnerProgramFormatted = Object.assign(
        _data,
        amount != null && { amount: unitToCents(amount) },
        percentageMaxAmount != null && { percentageMaxAmount: unitToCents(percentageMaxAmount) },
      );

      const result = await partnerProgramGateway.create(newPartnerProgramFormatted);

      dispatch(
        partnerProgramActions.optimisticCreation({
          ...result,
          amount: result.amount != null ? centsToUnit(result.amount) : undefined,
          percentageMaxAmount:
            result.percentageMaxAmount != null
              ? centsToUnit(result.percentageMaxAmount)
              : undefined,
        }),
      );
    } catch (e) {
      console.log('Error ', e);
      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(partnerProgramActions.setIsLoading(false));
      dispatch(partnerProgramActions.selectForEdition(null));
    }
  };
