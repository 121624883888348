import { useMemo } from 'react';

import { Promotion, User } from '@bits-app/bits-server-data';
import { Close as CloseIcon } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';
import { useTypesenseSearch } from '@/hooks/use-typesense';
import { useSearchVoggtPayloadType } from '@/hooks/use-typesense/use-typesense';
import { SelectedPromotion } from '@/voggt-database-explorer/users/hooks/use-attach-user-to-promotion';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type UpdateFeeAmountModalProps = {
  toggleModal: () => void;
  modalIsOpen: boolean;
  isLoading: boolean;
  handleSubmit: () => void;
  username: User['username'] | User['id'];
  setSelectPromotions: (promotions: SelectedPromotion) => void;
  selectedPromotion: SelectedPromotion;
};

export const AttachToPromotionModal = ({
  toggleModal,
  modalIsOpen,
  isLoading,
  handleSubmit,
  username,
  setSelectPromotions,
  selectedPromotion,
}: UpdateFeeAmountModalProps) => {
  const { t } = useTranslation();

  const params: useSearchVoggtPayloadType<Promotion> = useMemo(
    () => ({
      typesenseIndex: 'promotion',
      typesenseParams: {
        per_page: 20,
        query_by: ['name'],
        include_fields: 'name,id, expireAt',
      },
      // filterBy: `expireAt:>=${new Date().getTime()}`,
    }),
    [],
  );

  const { results, setSearch, ownUserSearch } = useTypesenseSearch<Promotion>(params);

  const promotionsNotExpired = results.filter((promotion) =>
    isAfter(new Date(+promotion.expireAt), new Date()),
  );

  const handleChange = (promotion: Promotion[]) => {
    const newSelectedPromotion = promotion.reduce<SelectedPromotion>((all, current) => {
      // eslint-disable-next-line no-prototype-builtins
      const count = selectedPromotion.hasOwnProperty(current.id)
        ? selectedPromotion[current.id].count
        : 1;

      all[current.id] = {
        count,
        name: current.name,
      };
      return all;
    }, {});
    setSelectPromotions(newSelectedPromotion);
  };

  const handleUpdateCount = (promotionId: Promotion['id'], count: number) => () => {
    const newSelectedPromotion = {
      ...selectedPromotion,
      [promotionId]: {
        ...selectedPromotion[promotionId],
        count: count < 1 ? 1 : count,
      },
    };
    setSelectPromotions(newSelectedPromotion);
  };

  return (
    <Dialog open={modalIsOpen}>
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        {t('databaseExplorer.users.promotion.title', { username })}
        <IconButton size="small" color="inherit" onClick={toggleModal}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          sx={{
            m: 1,
          }}
          options={promotionsNotExpired}
          filterOptions={(x) => x}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, result) => handleChange(result)}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('databaseExplorer.users.promotion.select')}
              onChange={(ev) => setSearch(ev.target.value)}
              value={ownUserSearch}
            />
          )}
        />
        <Stack sx={{ m: 1, mt: 3 }} spacing={1} divider={<Divider />}>
          {Object.entries(selectedPromotion).map(([promotionId, { name, count }]) => (
            <ManageNumberOfPromotion
              promotionName={name}
              key={promotionId}
              count={count}
              increaseCount={handleUpdateCount(+promotionId, count + 1)}
              decreaseCount={handleUpdateCount(+promotionId, count - 1)}
            />
          ))}
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: ({ spacing }) => spacing(3),
          paddingLeft: ({ spacing }) => spacing(3),
          paddingBottom: ({ spacing }) => spacing(2),
        }}
      >
        <ButtonOutlined onClick={toggleModal} disabled={isLoading}>
          {t('cancel')}
        </ButtonOutlined>
        <Button variant="contained" onClick={handleSubmit} disabled={isLoading}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ManageNumberOfPromotion = ({
  promotionName,
  count,
  increaseCount,
  decreaseCount,
}: {
  promotionName: Promotion['name'];
  count: number;
  increaseCount: () => void;
  decreaseCount: () => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="button">{promotionName}</Typography>
      <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Button onClick={decreaseCount}>-</Button>
        <Typography>{count}</Typography>
        <Button onClick={increaseCount}>+</Button>
      </Stack>
    </Box>
  );
};
