import { useEffect, useRef, useState } from 'react';

import { StreamingAsset } from '@bits-app/voggtpit-shared';
import { Close as CloseIcon } from '@mui/icons-material';
import Ico from '@mui/icons-material/OpenInNew';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import { InternalLink } from '@/components/elements/InternalLink';
import { Loader } from '@/components/elements/Loader';
import { PaginationButtonIcon } from '@/components/elements/buttons/PaginationButtonIcon';
import { useMuxContext } from '@/mux/mux.context';
import { formatDate } from '@/utils';

import { MuxPlayerComponent } from '../components/elements/MuxPlayer';
import { useSnackbar } from '../components/elements/snackbar/use-snackbar';
import { getPlayerOptions } from '../voggt-database-explorer/show/views/detail/ShowPlayer';

import { useDownloadMuxAsset } from './useDownloadMuxAsset';

export const MuxAssetsDetails = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const isUserPath = window.location.pathname.includes('user');

  const {
    onDrawerToggle,
    ownShowId,
    isLoading,
    currentAsset,
    selectNextAsset,
    selectPreviousAsset,
  } = useMuxContext();

  const { requestDownload } = useDownloadMuxAsset(currentAsset);
  const useMuxDownloadAsset = !currentAsset?.downloadLink;

  const isIvsLink = Boolean(
    currentAsset?.downloadLink && ['ivs_rtmp', 'ivs_web_rtc'].includes(currentAsset?.type || ''),
  );

  const getShareableLink = () => {
    if (!currentAsset?.muxAssetId) return;
    const shareableLink = `https://stream.mux.com/${currentAsset.muxAssetId}.m3u8`;
    navigator.clipboard.writeText(shareableLink);
    snackbar.success('Link copied to clipboard');
  };

  return (
    <Box
      sx={{
        width: { xs: '100vw', md: '100%' },
        paddingLeft: 6,
        paddingRight: isLoading ? 0 : 6,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        maxHeight: '100vh',
        height: '100%',
      }}
      role="presentation"
      display="flex"
      gap={3}
    >
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          <Loader />
        </Box>
      ) : currentAsset ? (
        <>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Box display={'flex'} gap={'10px'} flexDirection="column">
              <Typography variant="h5">
                {t('mux.drawer.title', { type: 'Show', id: ownShowId })}
              </Typography>
              <p>{currentAsset?.id}</p>
              {isUserPath && (
                <InternalLink to={`/show/${ownShowId}`} onClick={() => onDrawerToggle('')}>
                  <Ico />
                </InternalLink>
              )}
            </Box>
            <Box sx={{ display: { md: 'none' } }}>
              <IconButton size="small" color="inherit" onClick={() => onDrawerToggle('')}>
                <CloseIcon color="inherit" />
              </IconButton>
            </Box>
          </Box>

          <Box display="flex" alignItems="flex-end" justifyContent="space-between">
            <div>
              <Typography variant="h6">{t('mux.drawer.streamedAt')} </Typography>
              <Typography>
                {currentAsset?.streamedAt ? formatDate(currentAsset.streamedAt) : ''}
              </Typography>
            </div>
          </Box>
          <Player asset={currentAsset} key={currentAsset?.id} />
          {useMuxDownloadAsset && (
            <>
              <Button onClick={requestDownload}>Download asset from Mux</Button>
              <Button onClick={getShareableLink}>Copy shareable link</Button>
            </>
          )}
          {isIvsLink && (
            <Button
              onClick={() => {
                if (currentAsset.downloadLink) {
                  navigator.clipboard.writeText(currentAsset.downloadLink);
                }
              }}
            >
              Copy m3u8 link
            </Button>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <PaginationButtonIcon iconName="Previous" onClick={selectPreviousAsset} />
            <PaginationButtonIcon iconName="Next" onClick={selectNextAsset} />
          </Box>
        </>
      ) : (
        <Box sx={{ mt: 2, p: 10 }}>
          <Typography>{t('mux.drawer.noAssetSelected')}</Typography>
        </Box>
      )}
    </Box>
  );
};

const Player = ({ asset }: { asset: StreamingAsset | undefined }) => {
  const [player, setPlayer] = useState<ReturnType<typeof videojs> | null>(null);
  const videoNode = useRef(null);

  const isAwsReplay = asset?.type === 'ivs_rtmp' || asset?.type === 'ivs_web_rtc';

  useEffect(() => {
    if (!isAwsReplay) {
      return;
    }

    if (videoNode.current && asset?.downloadLink && !player) {
      const _player = videojs(videoNode.current, getPlayerOptions({ url: asset.downloadLink }));
      setPlayer(_player);
    }

    return () => {
      if (player) {
        player.dispose();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset?.type, asset?.downloadLink, asset?.id]);

  if (isAwsReplay) {
    return (
      <div className="aspect-[9/16]">
        <div data-vjs-player className="aspect-[9/16]">
          <video ref={videoNode} className="video-js"></video>
        </div>
      </div>
    );
  }

  if (asset?.downloadLink) {
    return (
      <video
        key={asset.downloadLink}
        controls
        style={{
          maxHeight: '50vh',
          aspectRatio: '9/16',
        }}
      >
        <source src={asset.downloadLink}></source>
      </video>
    );
  }

  if (asset?.muxAssetId) {
    return <MuxPlayerComponent playbackId={asset.muxAssetId} />;
  }

  return null;
};
