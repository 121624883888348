import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Currency } from '../models/currency';

import { OrderProduct } from './OrderProduct';
import { ShipmentRating } from './Rating';
import { ShippingAddress } from './ShippingAddress';
import { User } from './User';

export enum ShipmentStatus {
  ready_to_ship = 'ready_to_ship',
  dispatching = 'dispatching',
  label_created = 'label_created',
  shipped = 'shipped',
  ready_to_pickup = 'ready_to_pickup',
  delivered = 'delivered',
  received = 'received',
}

export enum ShipmentEasyShippingProvider {
  boxtal = 'boxtal',
  sendcloud = 'sendcloud',
}

@Entity()
@Index(['customerId', 'status'])
export class Shipment {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column({
    type: 'enum',
    enum: ShipmentStatus,
    default: ShipmentStatus.ready_to_ship,
  })
  status!: ShipmentStatus;

  @Index()
  @Column({ nullable: true, type: 'character varying' })
  trackingNumber!: string | null;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'sellerId' })
  seller!: User;

  @Index()
  @Column()
  sellerId!: number;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'customerId' })
  customer!: User;

  @Column()
  customerId!: number;

  @ManyToOne(() => ShippingAddress)
  @JoinColumn({ name: 'shippingAddressId' })
  shippingAddress!: ShippingAddress;

  @Column()
  shippingAddressId!: number;

  @Column({ nullable: true, type: 'character varying' })
  shippingProvider!: string | null;

  @Index()
  @Column({ nullable: true })
  externalShippingOrderId!: string;

  @Column({ nullable: true })
  labelPrintUrl!: string;

  @Column({ type: 'character varying', nullable: true })
  labelStripePaymentId!: string | null;

  @Column({ nullable: true, type: 'int4' })
  easyShippingAmount!: number | null;

  @Column({
    type: 'enum',
    enum: Currency,
    default: Currency.eur,
    enumName: 'currency_enum',
  })
  currency!: Currency;

  @OneToMany(() => OrderProduct, (op) => op.shipment)
  orderProducts!: OrderProduct[];

  @Index()
  @Column({ nullable: true, default: null, type: 'character varying' })
  ratingId!: string | null;

  @OneToOne(() => ShipmentRating)
  @JoinColumn({ name: 'ratingId' })
  rating!: ShipmentRating | null;

  /**
   * @deprecated THIS FIELD SHOULD NOT BE USED, IT IS ONLY USED FOR MIGRATING DATA FROM SHOW_ORDERS_GROUP INTO SHIPMENT
   */
  @Index()
  @Column({
    nullable: true,
    default: null,
    type: 'character varying',
    select: false,
  })
  showOrdersGroupId!: string | null;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  static hasBeenShipped(shipment: Pick<Shipment, 'externalShippingOrderId'>) {
    // Assume that if we have an externalShippingOrderId, the shipment has been shipped
    return !!shipment.externalShippingOrderId;
  }

  /**
   * Actually when the label was downloaded by the seller
   */
  @Column({ nullable: true, type: 'timestamp' })
  labelGeneratedAt!: Date | null;

  @Column({ nullable: true, type: 'enum', enum: ShipmentEasyShippingProvider })
  easyShippingProvider!: ShipmentEasyShippingProvider | null;

  @Column({ default: false })
  createdWithSellerShippingContract!: boolean;

  @Column({ nullable: true, type: 'character varying' })
  createdWithShippingContractId!: string | null;

  // shipping status tracking
  @Column({ nullable: true, type: 'timestamp' })
  readyToShipAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  shippedAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  readyToPickupAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  deliveredAt!: Date | null;

  @Column({ nullable: true, type: 'timestamp' })
  receivedAt!: Date | null;
}
