import { AppThunkAction } from '@/redux/store';

import { selectIsOrderItemInTheRefund } from '../redux/refund.selectors';

import { addOrderItemInRefund } from './add-order-item-in-refund';
import { removeOrderItemFromRefund } from './remove-order-item-from-refund';

export const toggleOrderItemToRefund =
  (orderItemId: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const isOrderItemInTheRefund = selectIsOrderItemInTheRefund(getState(), orderItemId);

    if (isOrderItemInTheRefund) {
      dispatch(removeOrderItemFromRefund(orderItemId));
      return;
    }

    dispatch(addOrderItemInRefund(orderItemId));
  };
