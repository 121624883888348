import { Badge, User } from '@bits-app/bits-server-data';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useTypesenseSearch } from '@/hooks/use-typesense';
import { useSearchVoggtPayloadType } from '@/hooks/use-typesense/use-typesense';
import { ButtonOutlined } from '@Elements/buttons/ButtonOutlined';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

type AddBadgeModalProps = {
  onClose: () => void;
  modalIsOpen: boolean;
  isLoading: boolean;
  handleSubmit: () => void;
  username: User['username'] | User['id'];
  setSelectBadge: (badge: Badge | null) => void;
  selectedBadge: Badge | null;
};

export const AddBadgeModal = ({
  onClose,
  modalIsOpen,
  isLoading,
  handleSubmit,
  username,
  setSelectBadge,
  selectedBadge,
}: AddBadgeModalProps) => {
  const { t } = useTranslation();

  const params: useSearchVoggtPayloadType<Badge> = {
    typesenseIndex: 'badge',
    typesenseParams: {
      per_page: 20,
      query_by: ['name'],
      include_fields: 'name,id',
    },
  };

  const { results, setSearch, ownUserSearch } = useTypesenseSearch<Badge>(params);

  return (
    <Dialog open={modalIsOpen}>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="common.black"
      >
        {t('databaseExplorer.users.badge.title', { username })}

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          value={selectedBadge}
          sx={{ m: 1 }}
          options={results}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, result) => {
            setSelectBadge(result);
          }}
          getOptionLabel={(option) => option.name}
          inputValue={ownUserSearch ?? ''}
          onInputChange={(_, value) => setSearch(value)}
          renderInput={(params) => (
            <TextField {...params} label={t('databaseExplorer.users.badge.select')} />
          )}
        />
      </DialogContent>

      <DialogActions
        sx={{
          px: ({ spacing }) => spacing(3),
          paddingBottom: ({ spacing }) => spacing(2),
        }}
      >
        <ButtonOutlined onClick={onClose} disabled={isLoading}>
          {t('cancel')}
        </ButtonOutlined>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isLoading || !selectedBadge}
        >
          {t('confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
