import { UserTargetPromotion, Promotion } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { User } from '@/voggt-database-explorer/users/users';

export const ManageUserInTargetPromotionDefinition: CellDefinition<UserTargetPromotion>[] = [
  {
    id: 'id',
    label: 'userId',
    type: 'internal-link',
    getLink: (record) => ({
      label: record?.user?.id,
      link: `/user/${record?.user?.id}`,
    }),
  },
  {
    id: 'user',
    label: 'Username',
    getValue: (record: UserTargetPromotion) => record?.user?.username,
  },
  {
    id: 'status',
    label: 'Status',
    type: 'badge',
  },
  {
    id: 'order',
    label: 'Order',
    type: 'internal-link',
    getLink: (record) => ({
      label: record?.order?.id,
      link: `/order/${record?.order?.id}`,
    }),
  },
];

export type ManageUserPromotionAction =
  | {
      type: 'selectPromotion';
      payload: { promotionId?: Promotion['id']; promotionName?: Promotion['name'] };
    }
  | {
      type: 'populateWithUserTargetPromotion';
      payload: { userTargetPromotion: UserTargetPromotion[] };
    }
  | { type: 'reset' }
  | { type: 'submit' }
  | { type: 'addUser'; payload: { users: User[] } };

export type ManageUserPromotionState = {
  promotionId?: Promotion['id'];
  promotionName?: Promotion['name'];
  userTargetPromotion: UserTargetPromotion[];
  newUsers: User[];
};
