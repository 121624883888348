import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import { clearRefundOrderItem, validateRefundOrderItem } from '../../../refund/use-cases';
import { selectIsProcessingRefund } from '../../redux/refund.selectors';

export const useRefundOrderItem = () => {
  const [openConfirmRefund, setOpenConfirmRefund] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isProcessingRefund = useAppSelector(selectIsProcessingRefund);

  const handleRefundConfirmation = useCallback(() => setOpenConfirmRefund(true), []);
  const handleRefundConfirmationClose = useCallback(() => setOpenConfirmRefund(false), []);

  const handleRefundCancelation = () => {
    dispatch(clearRefundOrderItem());
    navigate(-1);
  };

  const handleOnConfirmRefund = (sendEmailToBuyer: boolean, sendEmailToSeller: boolean) => {
    dispatch(validateRefundOrderItem(sendEmailToBuyer, sendEmailToSeller));
  };

  useEffect(() => {
    if (!isProcessingRefund && openConfirmRefund) {
      navigate(-1);
    }
  }, [isProcessingRefund, openConfirmRefund, navigate]);

  return {
    confirmRefund: {
      open: openConfirmRefund,
      onClose: handleRefundConfirmationClose,
      onConfirm: handleOnConfirmRefund,
    },
    handleRefundCancelation,
    handleRefundConfirmation,
  };
};
