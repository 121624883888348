import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { authActions } from '../auth.slice';

export const googleAuthentication =
  (googleToken: string): AppThunkAction<void> =>
  async (dispatch, _getState, { authGateway }) => {
    dispatch(authActions.setAuthLoading(true));
    try {
      const ownUser = await authGateway.loginWithGoogle(googleToken);

      dispatch(authActions.setOwnUser(ownUser));
    } catch (error) {
      logger.error(error);
    } finally {
      dispatch(authActions.setAuthLoading(false));
    }
  };
