import { useCallback } from 'react';

import { useAppDispatch } from '@/redux/reduxAppHooks';
import { startRefundOrder, startRefundOrderItem } from '@/refund/use-cases';

export const useStartRefund = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (id: string) => {
      if (id.match('_')) {
        dispatch(startRefundOrder(id));
        return;
      }

      dispatch(startRefundOrderItem(Number(id)));
    },
    [dispatch],
  );
};
