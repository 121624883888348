import { Box, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TableToolbarProps = {
  title: React.ReactNode;
  selectedCount?: number;
  tableActions?: React.ReactNode;
  selectionActions?: React.ReactNode;
};

export const TableToolbar = ({
  title,
  selectedCount = 0,
  tableActions = null,
  selectionActions = null,
}: TableToolbarProps) => {
  const { t } = useTranslation();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        gap: 2,
        justifyContent: 'space-between',
      }}
    >
      {title}

      {selectedCount > 0 && (
        <Box display="flex" alignItems="center" gap={3} flex={1} justifyContent="space-between">
          <Box
            borderRadius={2}
            display="flex"
            alignItems="center"
            p={0.5}
            pl={2}
            pr={selectionActions ? 0.5 : 2}
            gap={3}
            bgcolor={({ palette }) => palette.grey[300]}
          >
            <Typography color="inherit" variant="subtitle1" component="div">
              {selectedCount} {t('selected')}
            </Typography>

            {selectionActions}
          </Box>
        </Box>
      )}

      {tableActions}
    </Toolbar>
  );
};
