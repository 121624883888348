import type { Show } from '@bits-app/bits-server-data';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { FieldError } from '@/domain/errors/FieldError';

import { QUERIES_KEYS, queryClient } from '../../../queries';

export const useEditShowThumbnail = ({
  showId,
  onSuccess,
  onError,
}: {
  showId: number;
  onSuccess: () => void;
  onError: (
    params: [
      {
        code: string;
        message: string;
      },
    ],
  ) => void;
}) => {
  const snackbar = useSnackbar();
  return useMutation<Show, unknown, File>({
    mutationFn: (image) => editShowThumbnail(showId, image),
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.showDetail(showId));
      onSuccess();
      snackbar.success('databaseExplorer.show.thumbnailUpdate.success', {
        interpolationMap: { showId },
      });
    },
    onError: (error) => {
      if (error instanceof FieldError) {
        if (error.field === 'image') {
          onError([
            {
              code: 'server-error',
              message: error.message,
            },
          ]);
        }
      }

      snackbar.error('databaseExplorer.show.thumbnailUpdate.error', {
        interpolationMap: { showId },
      });
    },
  });
};

const editShowThumbnail = async (showId: Show['id'], thumbnail: File): Promise<Show> => {
  const formData = new FormData();
  formData.append('file', thumbnail);

  const response = await authenticatedInstance.patch(
    `/database-explorer/show/${showId}/thumbnail`,
    formData,
    {
      headers: {
        ['Content-Type']: 'multipart/form-data',
      },
    },
  );

  return response.data;
};
