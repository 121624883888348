import { useReducer } from 'react';

import { Product, Show, CommercialEvent } from '@bits-app/bits-server-data';
import { AvailableEntityForComment } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export type AddToCommercialEventMutateFunctionParams = {
  commercialEventId: CommercialEvent['id'];
  automaticallyAddProductFromShow?: boolean;
};

export const useAddToCommercialEvent = (
  recordId: Show['id'] | Product['id'],
  recordType: 'shows' | 'products',
) => {
  const [modalIsOpen, toggleModalStatus] = useReducer((state) => !state, false);

  const { t } = useTranslation();

  const snackbar = useSnackbar();

  const { mutate, isLoading } = useMutation(
    async ({
      automaticallyAddProductFromShow = false,
      commercialEventId,
    }: AddToCommercialEventMutateFunctionParams) => {
      await authenticatedInstance.patch(
        `database-explorer/commercial-event/${commercialEventId}/add/${recordType}/${recordId}`,
      );

      if (automaticallyAddProductFromShow && recordType === 'shows') {
        await authenticatedInstance.patch(
          `database-explorer/commercial-event/${commercialEventId}/attach-products-of-show/${recordId}`,
        );
      }
    },
    {
      onSuccess: () => {
        snackbar.success(t(`databaseExplorer.commercialEvent.add.${recordType}.success`));
        queryClient.invalidateQueries(QUERIES_KEYS.commercialEventList());
        toggleModalStatus();
        queryClient.invalidateQueries(
          QUERIES_KEYS.activityLogs(
            recordType === 'shows'
              ? AvailableEntityForComment.show
              : AvailableEntityForComment.product,
            recordId,
          ),
        );
      },
      onError: () => {
        snackbar.error(t(`databaseExplorer.commercialEvent.add.${recordType}.error`));
      },
    },
  );

  return {
    isLoading,
    toggleModalStatus,
    modalIsOpen,
    submitUpdate: (params: AddToCommercialEventMutateFunctionParams) => mutate(params),
  };
};
