import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Currency } from '../models/currency';
import {
  EasyShippingMethod,
  FavoriteShippingLabelFormat,
} from '../models/seller-config';

import { Category } from './Category';
import { Country } from './Country';
import { Language } from './Language';
import { ParentCategory } from './ParentCategory';
import { SellerShippingAddress } from './SellerShippingAddress';
import { SellerSubAccount } from './SellerSubAccount';
import { SellerTier } from './SellerTier';
import { User } from './User';

@Entity()
export class SellerConfig {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @OneToOne(() => User, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Column({ nullable: false })
  userId!: number;

  @Column({ nullable: false, default: false })
  isSuperSeller!: boolean;

  @Column({ nullable: false, default: false })
  isMondialRelayAuthorized!: boolean;

  @Column({ nullable: false, default: true })
  canCreateGiveaway!: boolean;

  @Column({ nullable: false, default: true })
  canCreateInstantBuy!: boolean;

  @Column({ nullable: false, default: false })
  canAccessSendcloud!: boolean;

  @Column({ nullable: false, default: false })
  canAccessPreAuth!: boolean;

  @Column({ nullable: false, default: false })
  canCreateShop!: boolean;

  @Column({ nullable: false, default: true })
  canCreateShow!: boolean;

  @Column({ nullable: false, default: true })
  canAccessStripeTransactions!: boolean;

  @Column({ nullable: true })
  batchAudiences!: string;

  @Column({ nullable: true, type: 'timestamp' })
  acceptedStudioConditionsOn!: Date;

  @Column({ default: true })
  canRequestOrderCancellation!: boolean;

  @Column({
    type: 'enum',
    enum: Currency,
    default: Currency.eur,
    enumName: 'currency_enum',
  })
  sellerCurrency!: Currency;

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'countryId' })
  country!: Country;

  @Column({ nullable: false, default: 'FR' })
  countryId!: Country['id'];

  @ManyToOne(() => Language)
  @JoinColumn({ name: 'defaultShowLanguageId' })
  defaultShowLanguage!: Language;

  @Column({ nullable: false })
  defaultShowLanguageId!: Language['id'];

  @Column({ nullable: false, default: true })
  canAccessWebRTC!: boolean;

  @Column({ nullable: false, default: true })
  canAddBulkProduct!: boolean;

  @Column({ nullable: true, default: null })
  taxNumber!: string;

  @Column({ nullable: true, default: null })
  registrationNumber!: string;

  @Column({ default: true })
  includeShippingFeesForCommissionByDefault!: boolean;

  @Column({ default: 0 })
  defaultShowBuyerServiceFeeAmount!: number;

  @Column({ default: true })
  isPreBidEligible!: boolean;

  @Column({ default: true })
  canUseLedger!: boolean;

  @Column({ default: false })
  canStreamAbove720p!: boolean;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;

  @Column({ default: false })
  canSkipStripeDebitAgreement!: boolean;

  @Column({ default: true })
  stripeAccountChargesEnabled!: boolean;

  @Column({ default: true })
  stripeAccountPayoutsEnabled!: boolean;

  @Column({ type: 'timestamp', nullable: true })
  stripeMissingInfoDueDate!: Date | null;

  @Column({ default: true })
  canAccessInventory!: boolean;

  @ManyToOne(() => SellerTier)
  @JoinColumn({ name: 'sellerTierId' })
  sellerTier!: SellerTier | null;

  @Column({ type: 'uuid', nullable: true })
  sellerTierId!: SellerTier['id'] | null;

  @Column({ default: false })
  isInventoryInitialized!: boolean;

  @Column({ default: false })
  canCreateSupplierShops!: boolean;

  @Column({ nullable: true, type: 'int4' })
  presentUserCountTarget!: number | null;

  @Column({
    nullable: true,
    type: 'enum',
    enum: EasyShippingMethod,
    enumName: 'easy_shipping_method_enum',
  })
  easyShippingMethod!: EasyShippingMethod | null;

  @Column({
    type: 'enum',
    enum: FavoriteShippingLabelFormat,
    default: FavoriteShippingLabelFormat.A4,
  })
  favoriteShippingLabelFormat!: FavoriteShippingLabelFormat;

  @ManyToOne(() => ParentCategory)
  @JoinColumn({ name: 'favoriteParentCategoryId' })
  favoriteParentCategory!: ParentCategory | null;

  @Column({ nullable: true })
  favoriteParentCategoryId!: ParentCategory['id'] | null;

  @ManyToOne(() => Category)
  @JoinColumn({ name: 'favoriteCategoryId' })
  favoriteCategory!: Category | null;

  @Column({ nullable: true })
  favoriteCategoryId!: Category['id'] | null;

  @Column({ type: 'character varying', nullable: true })
  externalSalesAmount!: string | null;

  @Column({ default: true })
  canSellerShipFromTheirCountry!: boolean;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'referrerId' })
  referrer!: User | null;

  @Column({ nullable: true })
  referrerId!: User['id'] | null;

  @Column({ default: false })
  useSellerShippingAddress!: boolean;

  @OneToOne(() => SellerShippingAddress)
  @JoinColumn({ name: 'sellerShippingAddressId' })
  sellerShippingAddress!: SellerShippingAddress | null;

  @Column({ nullable: true })
  sellerShippingAddressId!: SellerShippingAddress['id'] | null;

  @OneToMany(() => SellerSubAccount, (subAccount) => subAccount.sellerConfig)
  subAccounts!: SellerSubAccount[];

  @Column({ default: false })
  canBeContactedByCustomersForOrderIssues!: boolean;

  @Column({ type: 'int4', nullable: true })
  sendcloudContractIdForDhlDe!: number | null;
}
