/* eslint-disable max-classes-per-file */
import {
  ChildEntity,
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  TableInheritance,
  UpdateDateColumn,
} from 'typeorm';

import { Show } from './Show';

export enum IVSEventType {
  real_time = 'real_time',
  low_latency = 'low_latency',
}

@Entity()
@TableInheritance({
  column: {
    type: 'enum',
    name: 'type',
    enum: IVSEventType,
  },
})
export class IVSStreamSession {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column({
    type: 'enum',
    enum: IVSEventType,
    default: IVSEventType.low_latency,
  })
  type!: IVSEventType;

  @ManyToOne(() => Show)
  @JoinColumn({ name: 'showId' })
  show!: Show;

  @Index()
  @Column()
  showId!: Show['id'];

  @Column({ nullable: true, type: 'character varying' })
  recordingBucketName!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  recordingKeyPrefix!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  recordingSessionId!: string | null;

  @Column({ nullable: true, type: 'int4' })
  recordingDurationMs!: number | null;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;
}

@ChildEntity(IVSEventType.low_latency)
export class LowLatencyIVSStreamSession extends IVSStreamSession {
  @Index({ unique: true })
  @Column()
  streamId!: string;
}

@ChildEntity(IVSEventType.real_time)
export class RealTimeIVSStreamSession extends IVSStreamSession {
  @Index()
  @Column()
  streamSessionId!: string;
}
