import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  JoinColumn,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { User } from './User';

@Entity()
export class SellerCompany {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @OneToOne(() => User, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'sellerId' })
  seller!: User;

  @Column()
  sellerId!: number;

  @Column()
  legalName!: string;

  @Column()
  address!: string;

  @Column()
  city!: string;

  @Column()
  zipcode!: string;

  @Column({ nullable: true })
  state!: string;

  @Column()
  country!: string;

  @Column()
  phone!: string;

  @Column({ nullable: true })
  taxId!: string; // DE only

  @Column({ nullable: true })
  taxIdRegistrar!: string; // DE only

  @Column({ nullable: true })
  lucidId!: string; // DE only

  @Column({ nullable: true })
  vatId!: string;

  @Column({ default: false })
  vatIdValidInEu!: boolean;

  @Column({ type: 'timestamp', nullable: true })
  vatIdValidSince!: Date | null;

  @Column({ nullable: true })
  registrationNumber!: string;

  @Column({ nullable: true })
  bic!: string;

  @Column({ nullable: true })
  routingNumber!: string;

  @Column({ nullable: true })
  bankAccountNumber!: string;

  @Column({ nullable: true })
  bankName!: string;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;
}
