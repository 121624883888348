import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '@/components/elements/buttons/ButtonContained';
import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';

import { AvailableImpersonate } from '../../../hooks/use-impersonate-feature';

type ImpersonateAccessModalProps = {
  requestImpersonate: (choice: AvailableImpersonate) => void;
  toggleModal: () => void;
  impersonateModalFeatureIsOpen: boolean;
};
export const ImpersonateAccessModal = ({
  requestImpersonate,
  impersonateModalFeatureIsOpen,
  toggleModal,
}: ImpersonateAccessModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={impersonateModalFeatureIsOpen}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}
      onClose={toggleModal}
    >
      <DialogTitle>{t('databaseExplorer.users.impersonate.choose')}</DialogTitle>

      <DialogContent>
        <DialogContentText lineHeight={1.25} whiteSpace={'pre-line'}>
          {t('databaseExplorer.users.impersonate.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <ButtonOutlined onClick={() => requestImpersonate('bits-web')}>
          {t('databaseExplorer.users.impersonate.bits-web')}
        </ButtonOutlined>
        <ButtonContained onClick={() => requestImpersonate('seller-studio')}>
          {t('databaseExplorer.users.impersonate.seller-studio')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
