import { MarketPageType } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useMarketPage = (marketPageId: MarketPageType['id']) => {
  return useQuery<MarketPageType, string>({
    queryKey: QUERIES_KEYS.marketPage(marketPageId),
    queryFn: () => getMarketPage(marketPageId),
    initialData: undefined,
  });
};

const getMarketPage = async (marketPageId: MarketPageType['id']): Promise<MarketPageType> => {
  try {
    const result = await authenticatedInstance.get<MarketPageType>(`/market-page/${marketPageId}`);
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError<{ message?: string }>) {
      throw error.response?.data.message;
    }

    throw String(error);
  }
};
