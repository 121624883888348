import { OrderCancellationReason } from '@bits-app/voggtpit-shared';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { AppThunkAction } from '@/redux/store';
import { NotAllOrderUpdatedError } from '@/voggt-database-explorer/order/gateways/order.port';
import { databaseExplorerAction } from '@/voggt-database-explorer/redux/database-explorer.slice';

import { Order } from '../order/entities/order.entity';
import { ordersActions } from '../order/redux/order.slice';

export const cancelOrder =
  (
    orderId: Order['id'],
    cancellationReason: OrderCancellationReason,
  ): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { orderGateway, snackbarGateway }) => {
    dispatch(ordersActions.setLoading(true));

    try {
      const order = await orderGateway.cancelOrder(orderId, cancellationReason);

      dispatch(databaseExplorerAction.setData(order));

      snackbarGateway.success('databaseExplorer.order.cancel.success', {
        interpolationMap: { orderId, cancellationReason },
      });
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));
      }

      if (error instanceof NotAllOrderUpdatedError) {
        snackbarGateway.error('databaseExplorer.order.cancel.failed', {
          interpolationMap: { orderId },
        });

        dispatch(ordersActions.setError(error.failedOrders));
      } else {
        snackbarGateway.error('somethingWrongHappened');
      }
    } finally {
      dispatch(ordersActions.setLoading(false));
    }
  };
