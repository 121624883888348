import { useAppSelector } from '@/redux/reduxAppHooks';

import { selectShouldReLog } from '../../../auth/auth.slice';
import { Navigation } from '../../elements/Navigation';
import { ShouldReLogDialog } from '../../elements/ShouldReLogDialog';

import { useGetNavigationItemsForOwnUser } from './hooks/use-get-navigation-items-for-own-user';

export const AuthenticatedLayout = ({ children }: { children: React.ReactNode }) => {
  const shouldReLog = useAppSelector(selectShouldReLog);

  const navigationsItems = useGetNavigationItemsForOwnUser();

  return (
    <div className="bg-slate-100 md:h-screen md:overflow-hidden bg-repeat bg-[url('/public/bg1.png')] bg-[length:100px]">
      <div className="md:flex md:h-full">
        <Navigation items={navigationsItems} />

        <div className="md:overflow-x-hidden w-full">{children}</div>
      </div>

      {shouldReLog && <ShouldReLogDialog />}
    </div>
  );
};
