import { useCallback, useEffect, useMemo } from 'react';

import { Category, ParentCategory } from '@bits-app/bits-server-data';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { useAppDispatch } from '@/redux/reduxAppHooks';
import { ItemLayoutWithTabs } from '@/voggt-database-explorer/layouts/ItemLayoutWithTabs';
import { cleanUpResource } from '@/voggt-database-explorer/redux/use-cases/clean-up-resource';

import { useGetCategories, useGetParentCategories } from '../../../../queries';
import { useCellDefinition } from '../../use-cell-definition';

import { UpsertCategory } from './upsert-category/upsert-category';

export const ListCategory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { CellCategoryDefinition, CellParentCategoryDefinition } = useCellDefinition();
  const { data: parentCategories } = useGetParentCategories();
  const { data: categories } = useGetCategories();
  const dispatch = useAppDispatch();

  const currentTab = useMemo(() => {
    if (location.pathname.includes('child')) return 'child';
    return 'parent';
  }, [location]);

  const handleOnTabChange = useCallback((tab: string) => navigate(tab), [navigate]);

  const tabs = useMemo(
    () => [
      {
        path: 'parent',
        label: t(`databaseExplorer.category.tabs.parentCategories`),
        Element: (
          <ListCustom<ParentCategory>
            key="parent"
            cellDefinition={CellParentCategoryDefinition}
            results={parentCategories || []}
            enableFilters
            getPathDetail={(row) => `/category/parent/${row.id}`}
          />
        ),
      },
      {
        path: 'child',
        label: t(`databaseExplorer.category.tabs.categories`),
        Element: (
          <ListCustom<Category>
            key="child"
            cellDefinition={CellCategoryDefinition}
            results={categories || []}
            enableFilters
            getPathDetail={(row) => `/category/child/${row.id}`}
            children={<UpsertCategory categories={categories} />}
          />
        ),
      },
    ],
    [CellCategoryDefinition, CellParentCategoryDefinition, categories, parentCategories, t],
  );

  useEffect(() => {
    return () => dispatch(cleanUpResource());
  }, [dispatch]);

  return <ItemLayoutWithTabs tabs={tabs} currentTab={currentTab} onTabChange={handleOnTabChange} />;
};
