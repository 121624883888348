import { OwnUser } from '../../own-user';
import { EntityName, DatabaseSource } from '../common';

export type ActivityEvent = 'update' | 'create' | 'hard-delete' | 'soft-delete';

type OwnUserActivityAttributes = {
  id: string;
  ownUser: OwnUser;
  beforeContent: Record<string, unknown>;
  afterContent: Record<string, unknown>;
  event: ActivityEvent;
  entityName: EntityName;
  entityId: string;
  databaseSource: DatabaseSource;
  createdAt: Date;
};

export class OwnUserActivity {
  readonly id: string;

  readonly ownUser: OwnUser;

  readonly beforeContent: Record<string, unknown>;

  readonly afterContent: Record<string, unknown>;

  readonly event: ActivityEvent;

  readonly entityName: EntityName;

  readonly entityId: string;

  readonly databaseSource: DatabaseSource;

  readonly createdAt: Date;

  constructor(attributes: OwnUserActivityAttributes) {
    this.id = attributes.id;
    this.ownUser = attributes.ownUser;
    this.beforeContent = attributes.beforeContent;
    this.afterContent = attributes.afterContent;
    this.event = attributes.event;
    this.entityName = attributes.entityName;
    this.entityId = attributes.entityId;
    this.databaseSource = attributes.databaseSource;
    this.createdAt = attributes.createdAt;
  }
}
