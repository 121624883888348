import { useCallback, useMemo } from 'react';

import { Product } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useLocation } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { ItemLayout } from '@/voggt-database-explorer/layouts/ItemLayout';

import { AddMultipleItemToCommercialEvent } from '../../../commercial-event/components/AddMultipleItemToCommercialEvent';
import { ListWithExport } from '../../../components/ListWithExport';
import { OrdersWithExport } from '../../../components/OrdersWithExport';
import { useCommercialEvent } from '../../hook/useCommercialEvents';
import { useShowDetails } from '../../hook/useShowDetails';
import {
  CellNotificationDefinition,
  CellOrderDefinition,
  CellProductDefinition,
  ShowNotification,
} from '../../misc/show.const';
import { ShowOrder } from '../../misc/show.type';

import { CommercialEventsTab } from './CommercialEventsTab';
import { Details } from './Details';
import { ShowMuxAssetsTab } from './ShowMuxAssetsTab';
import { ShowOrderRowActions } from './ShowOrders/ShowOrderRowActions';
import { ShowPlayer } from './ShowPlayer';
import { ShowNotificationRowAction } from './component/ShowNotificationRowAction';

export const ShowDetail = () => {
  const { hasPermissions } = useOwnUser();

  const isReadOnly = !hasPermissions(PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_SHOW);
  const ownUserCanSelectRows = hasPermissions(
    PERMISSIONS.OWN_USER_INTERFACE.READ_OWN_USER_INTERFACE_SELECT_MULTIPLE_LINES,
  );

  const showId = useIdParam();
  const { data: commercialEvents, refetch: refetchCommercialEvents } = useCommercialEvent(
    Number(showId),
  );
  const location = useLocation();
  const handleOnTabChange = useNavigateInDetailTab(`/show/${showId}`);

  const { loading, orderDeliveryData, payoutData, show, refreshAfterEdit, hasError } =
    useShowDetails(Number(showId));

  const refreshCommercialEvents = useCallback(() => {
    refetchCommercialEvents();
  }, [refetchCommercialEvents]);

  const currentTab = useMemo(() => {
    return (
      ['products', 'orders', 'recordings', 'player', 'notifications', 'commercial-events'].find(
        (path) => location.pathname.includes(path),
      ) ?? '/'
    );
  }, [location.pathname]);

  if (loading || hasError) {
    return null;
  }

  const tabs = [
    {
      path: 'products',
      label: 'Products',
      Element: (
        <ListWithExport<Product & { id: string }>
          key="products"
          dataName="product"
          filters={{ showId: Number(showId) }}
          cellDefinition={CellProductDefinition}
          customSelectedRowsAction={(rows) => (
            <AddMultipleItemToCommercialEvent
              productIds={rows.map(({ original: { id } }) => Number(id))}
            />
          )}
        />
      ),
    },
    {
      path: 'orders',
      label: 'Orders',
      Element: (
        <OrdersWithExport<ShowOrder>
          key="order-in-show"
          cellDefinition={CellOrderDefinition}
          filters={{ showId: Number(showId) }}
          enableRowSelection={ownUserCanSelectRows}
          renderRowActions={({ row }) => {
            if (row.original === null) {
              return null;
            }

            return <ShowOrderRowActions order={row.original} />;
          }}
        />
      ),
    },
    {
      path: 'recordings',
      label: 'Recordings',
      Element: <ShowMuxAssetsTab showId={Number(showId)} key="recordings" />,
    },
    {
      path: 'notifications',
      label: 'Notifications',
      Element: (
        <ListWithExport<ShowNotification>
          key="notifications"
          dataName="notifications-center"
          filters={{ showId: Number(showId) }}
          cellDefinition={CellNotificationDefinition}
          renderRowActions={({ row }) => <ShowNotificationRowAction row={row.original} />}
        />
      ),
    },
    {
      path: 'commercial-events',
      label: `Commercial events (${commercialEvents?.length || 0})`,
      Element: (
        <CommercialEventsTab
          commercialEvents={commercialEvents || []}
          key="commercial-events"
          onRefresh={refreshCommercialEvents}
        />
      ),
    },
  ];

  if (!show) {
    return null;
  } else {
    tabs.unshift({
      path: '/',
      label: 'Details',
      Element: (
        <Details
          loading={loading}
          show={show}
          key={'/'}
          orderDeliveryData={orderDeliveryData}
          payoutData={payoutData}
          isReadOnly={isReadOnly}
          refreshAfterEdit={refreshAfterEdit}
        />
      ),
    });
  }

  const isSuperAdmin = hasPermissions(
    PERMISSIONS.SUPER_PRIVILEGE.WRITE_SUPER_PRIVILEGE_ADMINISTRATION_WRITE_PERMISSIONS_MANAGE_ALL,
  );
  if (isSuperAdmin) {
    tabs.push({
      path: 'player',
      label: 'Player',
      Element: <ShowPlayer showId={Number(showId)} />,
    });
  }

  return (
    <ItemLayout
      id={showId}
      tabs={tabs}
      title={show.name}
      description={`By @${show.user?.username} on the ${new Date(show.startAt).toLocaleString()}`}
      imageURL={show?.thumbnailUrl}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
      editionDescriptor={
        isReadOnly
          ? undefined
          : {
              goBackName: 'Back',
              resourceId: Number(showId),
              resourceType: 'Show',
              onSuccess: refreshAfterEdit,
            }
      }
    />
  );
};
