import {
  ShippingFeeConfigValidationSchema,
  UserValidationSchema,
  SellerNewsValidationSchema,
  CommercialEventValidationSchema,
  MarketPageValidationSchema,
} from '@bits-app/voggtpit-shared';
import {
  Person,
  LocalGroceryStore,
  Slideshow,
  Sell,
  Home,
  Park,
  Tv,
  Public,
  Sms,
  Circle,
  CreditCard,
  WrongLocation,
  LocalShipping,
  Newspaper,
  EmojiEmotions,
  LocalShippingOutlined,
  Event,
} from '@mui/icons-material';
import * as yup from 'yup';

import { ResourceType } from '@/voggt-database-explorer/redux/database-explorer';

export const AVAILABLE_DATABASE_EXPLORER_MODULE_PATH = {
  badge: '/badge',
  blocked_shipping_address: '/blocked-shipping-address',
  category: '/category',
  channel: '/channel',
  commercial_event: '/commercial-event',
  country: '/country',
  emote: '/emote',
  language: '/language',
  order: '/order',
  payment_method: '/payment-method',
  product: '/product',
  seller_news: '/seller-news',
  shipment: '/shipment',
  shipping_fee_config: '/shipping-fee-config',
  shippingAddress: '/shipping-address',
  show: '/show',
  user: '/user',
};

export const AVAILABLE_DATABASE_EXPLORER_MODULE = [
  { route: 'user', Icon: Person },
  { route: 'order', Icon: LocalGroceryStore },
  { route: 'show', Icon: Slideshow },
  { route: 'product', Icon: Sell },
  { route: 'shipping-address', Icon: Home },
  { route: 'category', Icon: Park },
  { route: 'channel', Icon: Tv },
  { route: 'country', Icon: Public },
  { route: 'language', Icon: Sms },
  { route: 'badge', Icon: Circle },
  { route: 'payment-method', Icon: CreditCard },
  { route: 'blocked-shipping-address', Icon: WrongLocation },
  { route: 'shipping-fee-config', Icon: LocalShipping },
  { route: 'seller-news', Icon: Newspaper },
  { route: 'emote', Icon: EmojiEmotions },
  { route: 'shipment', Icon: LocalShippingOutlined },
  { route: 'commercial-event', Icon: Event },
];

export { ListUsers } from './users/views/list';
export { UserProfile } from './users/views/profile';
export { HardBlockUser } from './users/views/profile/HardBlockUser/HardBlockUser';
export { ListShow, ShowDetail } from './show';
export { ListOrder, OrderDetail } from './order';
export { ListProduct, ProductDetail } from './product';
export {
  ListShippingAddress,
  ShippingAddressDetail,
  ShippingAddressCreationView,
} from './shipping-address';
export { ListCategory, CategoryDetail } from './category';
export { ChannelDetail, ListChannel } from './channel';
export { CountryDetail, ListCountry } from './country';
export { BadgeDetail, ListBadge } from './badge';
export { EmoteDetail, ListEmote } from './emote';
export { LanguageDetail, ListLanguage } from './language';
export { ListPaymentMethod, PaymentMethodDetail } from './payment-method';
export {
  BlockedShippingAddressDetail,
  ListBlockedShippingAddress,
} from './blocked-shipping-address';
export { ShippingFeeConfigDetail, ListShippingFeeConfig } from './shipping-fee-config';
export { ListSellerNews, SellerNewsDetail, SellerNewsCreationView } from './seller-news';
export { ListShipment, ShipmentDetail } from './shipment';

export {
  CommercialEventCreationView,
  CommercialEventDetail,
  ListCommercialEvent,
} from './commercial-event';

export const validationSchemasBuilder: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in ResourceType]?: (fields: string[]) => yup.ObjectSchema<any>;
} = {
  User: new UserValidationSchema().resolveFields.bind(new UserValidationSchema()),
  'Shipping-Fee-Config': new ShippingFeeConfigValidationSchema().resolveFields.bind(
    new ShippingFeeConfigValidationSchema(),
  ),
  'Seller-News': new SellerNewsValidationSchema().resolveFields.bind(
    new SellerNewsValidationSchema(),
  ),
  CommercialEvent: new CommercialEventValidationSchema().resolveFields.bind(
    new CommercialEventValidationSchema(),
  ),
  MarketPage: new MarketPageValidationSchema().resolveFields.bind(new MarketPageValidationSchema()),
};
