import { useCallback } from 'react';

import { Box, Modal } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import { MuxPlayerComponent } from '@/components/elements/MuxPlayer';

export const MuxModal = () => {
  const { assetId } = useParams();
  const navigate = useNavigate();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  if (!assetId) {
    goBack();
    return null;
  }

  return (
    <Modal
      open
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClose={goBack}
    >
      <Box width={'xl'} height={'xl'}>
        <MuxPlayerComponent playbackId={assetId} />
      </Box>
    </Modal>
  );
};
