import { CommercialEvent } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useCommercialEvent = (commercialEventId: CommercialEvent['id']) => {
  return useQuery<CommercialEvent, string>({
    queryKey: QUERIES_KEYS.commercialEvent(commercialEventId),
    queryFn: () => getCommercialEvent(commercialEventId),
  });
};

const getCommercialEvent = async (
  commercialEventId: CommercialEvent['id'],
): Promise<CommercialEvent> => {
  try {
    const path = `/database-explorer/commercial-event/${commercialEventId}`;
    const result = await authenticatedInstance.get<CommercialEvent>(path);
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError<{ message?: string }>) {
      throw error.response?.data.message;
    }

    throw String(error);
  }
};
