export enum CountryCodeIso2 {
  AT = 'AT',
  BE = 'BE',
  CH = 'CH',
  FR = 'FR',
  GB = 'GB',
  DE = 'DE',
  NL = 'NL',
  ES = 'ES',
}
