import { useMemo } from 'react';

import { OrderStatus, PaymentStatus, RefundReason } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';

import { OrderItem } from '@/entities/order-item.entity';
import { AddFilterFunctionParams, FindOptions } from '@/hooks/use-table-options';
import { DateTimeFilterTable } from '@Elements/filters/table';
import { isSetFilter, isOperatorFilter } from '@Elements/filters/types';

import { BuyerNameFilter } from '../filters/BuyerNameFilter';
import { OrderIdFilter } from '../filters/OrderIdFilter';
import { OrderItemIdFilter } from '../filters/OrderItemIdFilter';
import { PaymentStatusFilter } from '../filters/PaymentStatusFilter';
import { RefundReasonFilter } from '../filters/RefundReasonFilter';
import { SellerNameFilter } from '../filters/SellerNameFilter';
import { ShippingProviderFilter } from '../filters/ShippingProviderFilter';
import { ShippingStatusFilter } from '../filters/ShippingStatusFilter';
import { ShowIdFilter } from '../filters/ShowIdFilter';
import { TrackingNumberFilter } from '../filters/TrackingNumberFilter';

export type HeadCell = {
  id: keyof OrderItem;
  label: string;
  minWidth?: number;
  maxWidth?: number;
  filter?: React.ReactNode;
  sortable: boolean;
};

type UseHeader = {
  addFilter: (filter: AddFilterFunctionParams) => void;
  filters: FindOptions['filters'];
  isUnsaved: boolean;
};

export const useHeader = ({ addFilter, filters, isUnsaved }: UseHeader) => {
  const { t } = useTranslation();

  const stickyHeadCells = useMemo(
    (): HeadCell[] => [
      {
        id: 'orderId',
        label: t('orderItemLabels.orderId'),
        minWidth: 110,
        sortable: false,
        filter: (
          <OrderIdFilter
            addFilter={addFilter}
            activeFilters={isSetFilter(filters.orderId) ? filters.orderId : undefined}
            isUnsaved={isUnsaved}
            disabled={Boolean(filters.sellerBuyerIntersection)}
          />
        ),
      },
      {
        id: 'id',
        label: t('orderItemLabels.orderItemId'),
        minWidth: 100,
        sortable: true,
        filter: (
          <OrderItemIdFilter
            addFilter={addFilter}
            activeFilters={isSetFilter(filters.id) ? filters.id : undefined}
            isUnsaved={isUnsaved}
          />
        ),
      },
      {
        id: 'seller',
        label: t('orderItemLabels.seller'),
        minWidth: 130,
        sortable: true,
        filter: (
          <SellerNameFilter
            addFilter={addFilter}
            activeFilters={isSetFilter(filters.seller) ? filters.seller : undefined}
            isUnsaved={isUnsaved}
            disabled={Boolean(filters.sellerBuyerIntersection)}
          />
        ),
      },
      {
        id: 'buyer',
        label: t('orderItemLabels.buyer'),
        minWidth: 130,
        sortable: true,
        filter: (
          <BuyerNameFilter
            addFilter={addFilter}
            activeFilters={isSetFilter(filters.buyer) ? filters.buyer : undefined}
            isUnsaved={isUnsaved}
            disabled={Boolean(filters.sellerBuyerIntersection)}
          />
        ),
      },
    ],
    [
      addFilter,
      filters.buyer,
      filters.id,
      filters.orderId,
      filters.seller,
      filters.sellerBuyerIntersection,
      isUnsaved,
      t,
    ],
  );

  const headCells = useMemo(
    (): HeadCell[] => [
      {
        id: 'showId',
        label: t('orderItemLabels.showId'),
        minWidth: 105,
        sortable: true,
        filter: (
          <ShowIdFilter
            addFilter={addFilter}
            activeFilters={isSetFilter(filters.showId) ? filters.showId : undefined}
            isUnsaved={isUnsaved}
          />
        ),
      },
      {
        id: 'showDate',
        label: t('orderItemLabels.showDate'),
        minWidth: 180,
        sortable: true,
        filter: (
          <DateTimeFilterTable
            field="showDate"
            addFilter={addFilter}
            activeFilters={
              isOperatorFilter<string>(filters.showDate) ? filters.showDate : undefined
            }
            isUnsaved={isUnsaved}
          />
        ),
      },
      { id: 'showName', label: t('orderItemLabels.showName'), minWidth: 130, sortable: true },
      { id: 'product', label: t('orderItemLabels.product'), minWidth: 140, sortable: true },
      { id: 'amount', label: t('orderItemLabels.amount'), minWidth: 80, sortable: true },
      {
        id: 'shippingAmount',
        label: t('orderItemLabels.shippingAmount'),
        minWidth: 135,
        sortable: true,
      },
      {
        id: 'shippingStatus',
        label: t('orderItemLabels.shippingStatus'),
        minWidth: 160,
        sortable: true,
        filter: (
          <ShippingStatusFilter
            activeFilters={
              isSetFilter(filters.shippingStatus)
                ? (filters.shippingStatus?.filter((value) =>
                    Object.values(OrderStatus).includes(value as OrderStatus),
                  ) as OrderStatus[])
                : undefined
            }
            addFilter={addFilter}
            isUnsaved={isUnsaved}
          />
        ),
      },
      {
        id: 'shippingProvider',
        label: t('orderItemLabels.shippingProvider'),
        minWidth: 180,
        sortable: true,
        filter: (
          <ShippingProviderFilter
            addFilter={addFilter}
            activeFilters={
              isSetFilter(filters.shippingProvider) ? filters.shippingProvider : undefined
            }
            isUnsaved={isUnsaved}
          />
        ),
      },
      {
        id: 'trackingNumber',
        label: t('orderItemLabels.trackingNumber'),
        minWidth: 175,
        sortable: true,
        filter: (
          <TrackingNumberFilter
            addFilter={addFilter}
            activeFilters={isSetFilter(filters.trackingNumber) ? filters.trackingNumber : undefined}
            isUnsaved={isUnsaved}
          />
        ),
      },
      {
        id: 'purchaseDate',
        label: t('orderItemLabels.purchaseDate'),
        minWidth: 180,
        sortable: true,
        filter: (
          <DateTimeFilterTable
            field="purchaseDate"
            addFilter={addFilter}
            activeFilters={
              isOperatorFilter<string>(filters.purchaseDate) ? filters.purchaseDate : undefined
            }
            isUnsaved={isUnsaved}
          />
        ),
      },
      {
        id: 'updateDate',
        label: t('orderItemLabels.updateDate'),
        minWidth: 180,
        sortable: true,
        filter: (
          <DateTimeFilterTable
            field="updateDate"
            addFilter={addFilter}
            activeFilters={
              isOperatorFilter<string>(filters.updateDate) ? filters.updateDate : undefined
            }
            isUnsaved={isUnsaved}
          />
        ),
      },
      {
        id: 'paymentStatus',
        label: t('orderItemLabels.paymentStatus'),
        minWidth: 170,
        sortable: true,
        filter: (
          <PaymentStatusFilter
            activeFilters={
              isSetFilter(filters.paymentStatus)
                ? (filters.paymentStatus?.filter((value) =>
                    Object.values(PaymentStatus).includes(value as PaymentStatus),
                  ) as PaymentStatus[])
                : undefined
            }
            addFilter={addFilter}
            isUnsaved={isUnsaved}
          />
        ),
      },
      { id: 'refundAgent', label: t('orderItemLabels.refundAgent'), minWidth: 130, sortable: true },
      {
        id: 'refundDebitedAccount',
        label: t('orderItemLabels.debitedAccount'),
        minWidth: 140,
        sortable: true,
      },
      {
        id: 'refundReason',
        label: t('orderItemLabels.refundReason'),
        minWidth: 200,
        sortable: true,
        filter: (
          <RefundReasonFilter
            activeFilters={
              isSetFilter(filters.refundReason)
                ? (filters.refundReason?.filter((value) =>
                    Object.values(RefundReason).includes(value as RefundReason),
                  ) as RefundReason[])
                : undefined
            }
            addFilter={addFilter}
            isUnsaved={isUnsaved}
          />
        ),
      },
      {
        id: 'refundedDate',
        label: t('orderItemLabels.refundedDate'),
        minWidth: 180,
        sortable: true,
      },
      {
        id: 'refundedAmount',
        label: t('orderItemLabels.refundedAmount'),
        minWidth: 150,
        sortable: true,
      },
      { id: 'cancelDate', label: t('orderItemLabels.cancelDate'), minWidth: 180, sortable: true },
      {
        id: 'cancellationReason',
        label: t('orderItemLabels.cancellationReason'),
        minWidth: 175,
        sortable: true,
      },
    ],
    [
      addFilter,
      filters.paymentStatus,
      filters.purchaseDate,
      filters.refundReason,
      filters.shippingProvider,
      filters.shippingStatus,
      filters.showDate,
      filters.showId,
      filters.trackingNumber,
      filters.updateDate,
      isUnsaved,
      t,
    ],
  );

  return {
    stickyHeadCells,
    headCells,
  };
};
