import { useState } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';

import { useOwnUser } from '@/context/own-user.context';

export const useUpsertBadge = () => {
  const [openForm, setOpenForm] = useState(false);

  const { hasPermissions } = useOwnUser();
  const ownUserIsAllowedToUpsertBadge = hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_BADGE,
  );

  return {
    openForm,
    onOpenForm: () => setOpenForm(true),
    onCloseForm: () => setOpenForm(false),
    ownUserIsAllowedToUpsertBadge,
  };
};
