import { InfoOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const WarningTooltip = ({ warningText }: { warningText: string }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-slate-600 text-white rounded-lg flex items-center gap-2 p-2 mb-2">
      <InfoOutlined />
      <p>{t(warningText)}</p>
    </div>
  );
};
