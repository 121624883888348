import { useCallback, useEffect } from 'react';

import { Product, User, Order } from '@bits-app/bits-server-data';
import {
  centsToUnit,
  currencySymbol,
  formatAmount,
  formatAmountWithCurrency,
} from '@bits-app/voggtpit-shared';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { Information } from '@/components/layouts/PanelDetails';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { getLinkShippingTracking, getStripeTransferUrl } from '@/utils';
import { selectResourceLoading } from '@/voggt-database-explorer/redux/database-explorer.slice';
import {
  getResource,
  selectResource,
  useResourceSelector,
} from '@/voggt-database-explorer/redux/use-cases/get-resource';

export const useDetailsView = (productId: string) => {
  const dispatch = useAppDispatch();

  const product = useResourceSelector<Product>(selectResource);
  const loading = useAppSelector(selectResourceLoading);

  useEffect(() => {
    if (productId && product.id !== Number(productId)) {
      dispatch(getResource(+productId, 'Product'));
    }
  }, [productId, dispatch, product.id]);

  const refreshData = useCallback(() => {
    dispatch(getResource(+productId, 'Product'));
  }, [dispatch, productId]);

  return {
    product,
    loading,
    refreshData,
  };
};

export const panelDetailsProduct: Information<Product>[] = [
  { value: 'id', label: 'id' },
  { value: 'name', label: 'Name' },
  { value: 'description', label: 'Description' },
  {
    value: 'startingAmount',
    label: 'Starting amount',
    format: (product) =>
      product?.startingAmount && product?.currency
        ? formatAmountWithCurrency(centsToUnit(product?.startingAmount), product?.currency)
        : product?.startingAmount,
  },
  {
    value: 'fixedAmount',
    label: 'Fixed amount (default to 0, used for buy now experience)',
    format: (product) =>
      product?.fixedAmount && product?.currency
        ? formatAmountWithCurrency(centsToUnit(product?.fixedAmount), product?.currency)
        : product?.fixedAmount,
  },
  { value: 'type', label: 'Type', type: 'badge' },
  { value: 'availableQuantity', label: 'Available quantity' },
  { value: 'status', label: 'Status', type: 'badge' },
  { value: 'weight', label: 'Weight' },
  { value: 'currency', label: 'Currency', type: 'badge' },
  {
    value: 'show',
    label: 'Show',
    format: (product) => product?.showId,
    type: 'internal-link',
    link: (product) => `/show/${product?.showId}`,
  },
  {
    value: 'shop',
    label: 'Shop',
    format: (product) => product?.shopId,
    type: 'internal-link',
    link: (product) => `/shop/${product?.shopId}`,
  },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
  { value: 'deletedAt', label: 'Deleted at', type: 'date-with-time' },
];

export const panelDetailsUser: Information<User>[] = [
  { value: 'id', label: 'id', link: (user) => `/user/${user.id}`, type: 'internal-link' },
  { value: 'username', label: 'Username' },
  { value: 'firstName', label: 'FirstName' },
  { value: 'lastName', label: 'LastName' },
  { value: 'isSeller', label: 'is seller', type: 'boolean' },
];

export const CellOrderDefinition: CellDefinition<
  Order & { seller: string; customer: string; product: string }
>[] = [
  {
    id: 'id',
    label: 'OrderId',
    type: 'internal-link',
    getLink: (order) => ({ link: `/order/${order?.id}`, label: order?.id }),
  },
  {
    id: 'status',
    label: 'Status',
    type: 'badge',
  },
  {
    id: 'paymentStatus',
    label: 'Payment Status',
    type: 'badge',
  },
  {
    id: 'seller',
    label: 'Seller',
    type: 'internal-link',
    getLink: (order) => ({ link: `/user/${order?.sellerId}`, label: order?.seller }),
  },
  {
    id: 'customer',
    label: 'Buyer',
    type: 'internal-link',
    getLink: (order) => ({ link: `/user/${order?.customerId}`, label: order?.customer }),
  },
  {
    id: 'product',
    label: 'Product',
    type: 'internal-link',
    getLink: (order) => ({ link: `/product/${order?.productId}`, label: order?.product }),
  },
  {
    id: 'createdAt',
    label: 'Purchased at',
    type: 'date-with-time',
  },
  {
    id: 'shippingProvider',
    label: 'Shipping Provider',
  },
  {
    id: 'trackingNumber',
    label: 'Tracking Number',
    type: 'external-link',
    getLink: (order) => ({
      link: getLinkShippingTracking(order),
      label: order?.trackingNumber ?? '',
    }),
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
  },
  {
    id: 'promotionAmount',
    label: 'Promotion amount',
    type: 'money',
    getValue: (order) =>
      order.promotionAmount != null
        ? `${formatAmount(centsToUnit(Number(order.promotionAmount)))}${currencySymbol(
            order.currency,
          )}`
        : '',
  },
  {
    id: 'promotionTopUpAmountTransferred',
    label: 'Promotion top up amount transferred',
    type: 'money',
    getValue: (order) =>
      order.promotionTopUpAmountTransferred != null
        ? `${formatAmount(
            centsToUnit(Number(order.promotionTopUpAmountTransferred)),
          )}${currencySymbol(order.currency)}`
        : '',
  },
  {
    id: 'promotionTopUpStripeTransferId',
    label: 'Promotion top up stripe transfer ID',
    type: 'external-link',
    getLink: (order) => ({
      link: getStripeTransferUrl(order?.promotionTopUpStripeTransferId),
      label: order?.promotionTopUpStripeTransferId,
    }),
  },
  {
    id: 'refundedAmount',
    label: 'Refunded amount',
    type: 'money',
    getValue: (order) =>
      order.refundedAmount > 0 && order.refundReason
        ? `${formatAmount(centsToUnit(Number(order.refundedAmount)))}${currencySymbol(
            order.currency,
          )}`
        : '',
  },
  {
    id: 'refundReason',
    label: 'Refund reason',
  },
  {
    id: 'refundAgent',
    label: 'Refund agent',
  },
  {
    id: 'refundDebitedAccount',
    label: 'Refund debited account',
  },
  {
    id: 'refundedAt',
    label: 'Refund date',
    type: 'date-with-time',
  },
  {
    id: 'cancellationReason',
    label: 'Cancellation reason',
  },
  {
    id: 'cancellationRequesterType',
    label: 'Cancellation requester type',
  },
  {
    id: 'cancellationRequestedAt',
    label: 'Cancellation requested at',
    type: 'date-with-time',
  },
];

export const panelProductAttributes: Information<Product>[] = [
  {
    value: 'brand',
    label: 'Brand',
  },
  {
    value: 'size',
    label: 'Size',
  },
  {
    value: 'color',
    label: 'Color',
  },
  {
    value: 'condition',
    label: 'Condition',
  },
  {
    value: 'model',
    label: 'Model',
  },
  {
    value: 'gender',
    label: 'Gender',
  },
  {
    value: 'cardCondition',
    label: 'Card condition',
  },
  {
    value: 'cardGradingService',
    label: 'Card Grading service',
  },
  {
    value: 'cardGrade',
    label: 'Card grade',
  },
  {
    value: 'cardLanguage',
    label: 'Card language',
  },
  {
    value: 'cardType',
    label: 'Card type',
  },
];
