import { useEffect, useReducer } from 'react';

import { Promotion, UserTargetPromotion } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { selectPromotions } from '@/promotions/redux/promotions-selector';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { useAppSelector } from '@/redux/reduxAppHooks';
import { User } from '@/voggt-database-explorer/users/users';

import { ManageUserPromotionAction, ManageUserPromotionState } from './use-manager-users.type';

export const useManagerUsers = () => {
  const [state, dispatch] = useReducer(useManagerUsersReducer, initialState);

  const promotions = useAppSelector(selectPromotions);
  const snackbar = useSnackbar();
  const selectPromotion = (promotionId?: Promotion['id']) => {
    const promotionName = promotions.find((promotion) => promotion.id === promotionId)?.name;
    dispatch({
      type: 'selectPromotion',
      payload: { promotionId, promotionName },
    });
  };

  const populateWithUserTargetPromotion = (userTargetPromotion: UserTargetPromotion[]) => {
    dispatch({
      type: 'populateWithUserTargetPromotion',
      payload: { userTargetPromotion },
    });
  };

  const addUserToPromotion = (users: User[]) => dispatch({ type: 'addUser', payload: { users } });

  const { data: userTargetPromotion } = useQuery<UserTargetPromotion[]>({
    queryKey: QUERIES_KEYS.userTargetPromotion(state.promotionId),
    queryFn: () => fetchUserTargetPromotion(state.promotionId),
    enabled: Boolean(state.promotionId),
  });

  useEffect(() => {
    if (state.promotionId && userTargetPromotion)
      populateWithUserTargetPromotion(userTargetPromotion);
  }, [state.promotionId, userTargetPromotion]);

  const handleSubmitAddUserToPromotion = () => {
    if (!state.promotionId || !state.newUsers) return;
    authenticatedInstance
      .post(`/promotions/${state.promotionId}/users`, {
        userIds: state.newUsers.map((user) => user.id),
      })
      .then(handleUpdateResult)
      .catch(handleUpdateResult)
      .finally(() => {
        queryClient.invalidateQueries(QUERIES_KEYS.userTargetPromotion(state.promotionId));
      });
  };

  const handleUpdateResult = (result: AxiosResponse) => {
    if (new RegExp(/^2/).test(String(result.status))) {
      snackbar.success('databaseExplorer.users.promotion.success');
      return;
    }
    snackbar.error('databaseExplorer.users.promotion.fail');
  };

  return { ...state, selectPromotion, addUserToPromotion, handleSubmitAddUserToPromotion };
};

const initialState: ManageUserPromotionState = {
  promotionId: undefined,
  promotionName: '',
  userTargetPromotion: [],
  newUsers: [],
};

const useManagerUsersReducer = (
  state: ManageUserPromotionState,
  action: ManageUserPromotionAction,
) => {
  switch (action.type) {
    case 'populateWithUserTargetPromotion': {
      return {
        ...state,
        userTargetPromotion: action.payload.userTargetPromotion,
      };
    }
    case 'reset': {
      return initialState;
    }
    case 'selectPromotion': {
      if (action.payload.promotionId) {
        return {
          ...state,
          promotionId: action.payload.promotionId,
          promotionName: action.payload.promotionName,
        };
      }
      return initialState;
    }
    case 'addUser': {
      return {
        ...state,
        newUsers: action.payload.users,
      };
    }
    case 'submit': {
      return {
        ...state,
        newUsers: [],
      };
    }
    default:
      return state;
  }
};

const fetchUserTargetPromotion = async (promotionId?: Promotion['id']) => {
  if (!promotionId) return [];
  return authenticatedInstance
    .get<UserTargetPromotion[]>(`/promotions/${promotionId}/user-attached`)
    .then(({ data }) => data);
};
