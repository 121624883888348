import { Checkbox, TableRow } from '@mui/material';

import { InternalLink } from '@/components/elements/InternalLink';
import { OrderItem } from '@/entities/order-item.entity';
import { TableCellBodyNoBorderBottom, TableCellEllipsisContent } from '@Elements/StyledTableCell';

type OrderItemsMassUpdateStickyRowProps = {
  orderItem: OrderItem;
  checked: boolean;
  onToggleItem: () => void;
  disabledSelectRow: boolean;
  isReadOnly: boolean;
};

export const OrderItemsMassUpdateStickyRow = ({
  orderItem,
  checked,
  onToggleItem,
  disabledSelectRow,
  isReadOnly,
}: OrderItemsMassUpdateStickyRowProps) => {
  return (
    <TableRow>
      {!isReadOnly && (
        <TableCellBodyNoBorderBottom padding="checkbox">
          <Checkbox onChange={onToggleItem} checked={checked} disabled={disabledSelectRow} />
        </TableCellBodyNoBorderBottom>
      )}
      <TableCellBodyNoBorderBottom minWidth={70}>{orderItem.orderId}</TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={70}>
        <InternalLink to={`/order/${orderItem.id}`}>{orderItem.id}</InternalLink>
      </TableCellBodyNoBorderBottom>

      <TableCellEllipsisContent maxWidth={130} title={orderItem.seller}>
        <InternalLink to={`/user/${orderItem.sellerId}`}>{orderItem.seller}</InternalLink>
      </TableCellEllipsisContent>

      <TableCellEllipsisContent maxWidth={130} title={orderItem.buyer}>
        <InternalLink to={`/user/${orderItem.buyerId}`}>{orderItem.buyer}</InternalLink>
      </TableCellEllipsisContent>
    </TableRow>
  );
};
