import { CountryCodeIso2 } from '@bits-app/voggtpit-shared';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderItemRefund } from '@/entities/order-item-refund.entity';

import { SellerCancelRequestsRefundSummary } from '../gateways/seller-cancel-requests.port';

export type SellerCancelRequestsState = {
  sellerCancelRequests: OrderItemRefund[];
  countPerCountries: Record<CountryCodeIso2, number>;
  refundForm: {
    orderItems: number[];
    loading: boolean;
    processing: {
      current: number;
      count: number;
    } | null;
    error: string[] | null;
    notAllProcessedError: {
      message: string;
      processing: number[];
      notProcessed: { [id: OrderItemRefund['id']]: string };
    } | null;
  };
  refundSummary: {
    loading: boolean;
    summary: SellerCancelRequestsRefundSummary;
    error: string | null;
  };
  loading: boolean;
  error: string | null;
};

export const sellerCancelRequestSlice = createSlice({
  name: 'sellerCancelRequests',
  initialState: {
    sellerCancelRequests: [],
    countPerCountries: Object.values(CountryCodeIso2).reduce(
      (obj, country) => ({ ...obj, [country]: 0 }),
      {} as Record<CountryCodeIso2, number>,
    ),
    refundForm: {
      orderItems: [],
      loading: false,
      processing: null,
      error: null,
      notAllProcessedError: null,
    },
    refundSummary: {
      loading: false,
      summary: {},
      error: null,
    },
    loading: false,
    error: null,
  } as SellerCancelRequestsState,
  reducers: {
    setEntities: (
      state,
      { payload }: PayloadAction<SellerCancelRequestsState['sellerCancelRequests']>,
    ) => {
      state.sellerCancelRequests = payload;
    },
    setCountPerCountries: (
      state,
      { payload }: PayloadAction<SellerCancelRequestsState['countPerCountries']>,
    ) => {
      state.countPerCountries = payload;
    },
    setRefundFormLoading: (
      state,
      { payload }: PayloadAction<SellerCancelRequestsState['refundForm']['loading']>,
    ) => {
      state.refundForm.loading = payload;
    },
    setRefundFormProcessing: (
      state,
      { payload }: PayloadAction<SellerCancelRequestsState['refundForm']['processing']>,
    ) => {
      state.refundForm.processing = payload;
    },
    addInRefund: (state, { payload }: PayloadAction<number>) => {
      state.refundForm.orderItems.push(payload);
    },
    addAllRefundableInRefund: (state) => {
      state.refundForm.orderItems = state.sellerCancelRequests.map(({ id }) => id);
    },
    removeFromRefund: (state, { payload }: PayloadAction<number>) => {
      state.refundForm.orderItems = state.refundForm.orderItems.filter((id) => id !== payload);
    },
    removeAllFromRefund: (state) => {
      state.refundForm.orderItems = [];
    },
    setRefundFormError: (
      state,
      { payload }: PayloadAction<SellerCancelRequestsState['refundForm']['error']>,
    ) => {
      state.refundForm.error = payload;
    },
    setRefundFormNotAllProcessedError: (
      state,
      { payload }: PayloadAction<SellerCancelRequestsState['refundForm']['notAllProcessedError']>,
    ) => {
      state.refundForm.notAllProcessedError = payload;
    },
    resetRefundForm: (state) => {
      state.refundForm = {
        orderItems: [],
        loading: false,
        processing: null,
        error: null,
        notAllProcessedError: null,
      };
    },
    setLoading: (state, { payload }: PayloadAction<SellerCancelRequestsState['loading']>) => {
      state.loading = payload;
    },
    setError: (state, { payload }: PayloadAction<SellerCancelRequestsState['error']>) => {
      state.error = payload;
    },
    setRefundSummaryLoading: (
      state,
      { payload }: PayloadAction<SellerCancelRequestsState['refundSummary']['loading']>,
    ) => {
      state.refundSummary.loading = payload;
    },
    setRefundSummary: (
      state,
      { payload }: PayloadAction<SellerCancelRequestsState['refundSummary']['summary']>,
    ) => {
      state.refundSummary.summary = payload;
    },
    resetRefundSummary: (state) => {
      state.refundSummary = {
        summary: {},
        loading: false,
        error: null,
      };
    },
    setRefundSummaryError: (
      state,
      { payload }: PayloadAction<SellerCancelRequestsState['refundSummary']['error']>,
    ) => {
      state.error = payload;
    },
  },
});

export const sellerCancelRequestsActions = sellerCancelRequestSlice.actions;

export default sellerCancelRequestSlice.reducer;
