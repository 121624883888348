import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';

import { ShipmentTypesenseCellDefinition, ShipmentTypesense } from '../../shipment.const';

const TYPESENSE_MAX_RESULT_PER_PAGE = 30;

export const ListShipment = () => {
  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListWithSearch<ShipmentTypesense>
        title="Shipments"
        cellDefinition={ShipmentTypesenseCellDefinition}
        typesenseHookParams={{
          typesenseIndex: 'shipment',
          typesenseParams: {
            per_page: TYPESENSE_MAX_RESULT_PER_PAGE,
            query_by: [
              'customer',
              'seller',
              'shippingAddress',
              'customerId',
              'sellerId',
              'trackingNumber',
              'externalShippingOrderId',
            ],
          },
        }}
      />
    </div>
  );
};
