import { useMemo } from 'react';

import { Order, OrderStatus, PaymentStatus, User } from '@bits-app/bits-server-data';
import {
  centsToUnit,
  currencySymbol,
  formatAmount,
  formatAmountWithCurrency,
} from '@bits-app/voggtpit-shared';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { useTypesenseSearch } from '@/hooks/use-typesense';
import { useSearchVoggtPayloadType } from '@/hooks/use-typesense/use-typesense';
import { getLinkShippingTracking, getStripeTransferUrl, getStripePaymentUrl } from '@/utils';

const PAYMENT_STATUS = Object.values(PaymentStatus) as PaymentStatus[];
const ORDER_STATUS = Object.values(OrderStatus) as OrderStatus[];

export type OverrideTypesenseOrder = Order & {
  customer: string;
  seller: string;
  id: string;
  orderId: string;
};

export const useCellDefinition = () => {
  const params: useSearchVoggtPayloadType<User> = useMemo(
    () => ({
      typesenseIndex: 'user',
      typesenseParams: {
        per_page: 20,
        query_by: ['username'],
        include_fields: 'username',
      },
    }),
    [],
  );

  const {
    results: sellerOptions,
    setSearch: setSellerSearch,
    loading: sellerLoading,
  } = useTypesenseSearch<User>(params);

  const {
    results: buyerOptions,
    setSearch: setBuyerSearch,
    loading: buyerLoading,
  } = useTypesenseSearch<User>(params);

  const CellOrderDefinition = useMemo(
    (): CellDefinition<OverrideTypesenseOrder>[] => [
      {
        id: 'id',
        label: 'Order Id',
        type: 'internal-link',
        getLink: (order) => ({ link: `/order/${order?.id}`, label: order?.id }),
        enableColumnFilter: false,
      },
      {
        id: 'showId',
        label: 'Show ID',
        type: 'internal-link',
        getLink: (order) => ({ link: `/show/${order?.showId}`, label: `${order?.showId}` }),
        columnFilter: { type: 'text' },
        enableSorting: true,
      },
      {
        id: 'status',
        label: 'Status',
        type: 'badge',
        columnFilter: { type: 'multi-select', options: ORDER_STATUS.map((value) => ({ value })) },
      },
      {
        id: 'paymentStatus',
        label: 'Payment Status',
        type: 'badge',
        columnFilter: { type: 'multi-select', options: PAYMENT_STATUS.map((value) => ({ value })) },
      },
      {
        id: 'seller',
        label: 'Seller',
        type: 'internal-link',
        getLink: (order) => ({ link: `/user/${order?.sellerId}`, label: order?.seller }),
        enableSorting: true,
        columnFilter: {
          type: 'autocomplete',
          options: sellerOptions
            .map((seller) => ({ value: seller.username }))
            .filter((option) => Boolean(option?.value)),
          onAutocomplete: setSellerSearch,
          loading: sellerLoading,
        },
      },
      {
        id: 'customer',
        label: 'Buyer',
        type: 'internal-link',
        getLink: (order) => ({ link: `/user/${order?.customerId}`, label: order?.customer }),
        columnFilter: {
          type: 'autocomplete',
          options: buyerOptions
            .map((buyer) => ({ value: buyer.username }))
            .filter((option) => Boolean(option?.value)),
          onAutocomplete: setBuyerSearch,
          loading: buyerLoading,
        },
      },
      {
        id: 'product',
        label: 'Product',
        enableColumnFilter: false,
      },

      {
        id: 'shippingProvider',
        label: 'Shipping Provider',
        enableColumnFilter: false,
      },
      {
        id: 'trackingNumber',
        label: 'Tracking Number',
        type: 'external-link',
        getLink: (order) => ({
          link: getLinkShippingTracking(order),
          label: order?.trackingNumber ?? '',
        }),
      },
      {
        id: 'stripePaymentIntentId',
        label: 'Payment Intent Id',
        type: 'external-link',
        getLink: (order) => ({
          link: order?.stripePaymentIntentId
            ? getStripePaymentUrl(order?.stripePaymentIntentId)
            : '',
          label: order?.stripePaymentIntentId ?? '',
        }),
      },
      {
        id: 'amount',
        label: 'Amount',
        type: 'money',
        getValue: (order) =>
          order?.amount
            ? formatAmountWithCurrency(centsToUnit(Number(order?.amount)), order.currency)
            : '',
      },
      {
        id: 'shippingAmount',
        label: 'Shipping Amount',
        type: 'money',
        getValue: (order) =>
          order?.shippingAmount
            ? formatAmountWithCurrency(centsToUnit(Number(order?.shippingAmount)), order.currency)
            : '',
      },
      {
        id: 'deductedShippingCost',
        label: 'Deducted shipping cost',
        type: 'money',
        getValue: (order) =>
          order.deductedShippingCost != null
            ? `${formatAmount(centsToUnit(Number(order.deductedShippingCost)))}${currencySymbol(
                order.currency,
              )}`
            : '',
        enableSorting: true,
      },
      {
        id: 'feeDeductibleFromBuyerPayment',
        label: 'Commission',
        type: 'money',
        getValue: (order) =>
          order?.feeDeductibleFromBuyerPayment
            ? formatAmountWithCurrency(
                centsToUnit(Number(order?.feeDeductibleFromBuyerPayment)),
                order.currency,
              )
            : '',
      },
      {
        id: 'promotionAmount',
        label: 'Promotion amount',
        type: 'money',
        getValue: (order) =>
          order.promotionAmount != null
            ? `${formatAmount(centsToUnit(Number(order.promotionAmount)))}${currencySymbol(
                order.currency,
              )}`
            : '',
      },
      {
        id: 'promotionTopUpAmountTransferred',
        label: 'Promotion top up amount transferred',
        type: 'money',
        getValue: (order) =>
          order.promotionTopUpAmountTransferred != null
            ? `${formatAmount(
                centsToUnit(Number(order.promotionTopUpAmountTransferred)),
              )}${currencySymbol(order.currency)}`
            : '',
      },
      {
        id: 'promotionTopUpStripeTransferId',
        label: 'Promotion top up stripe transfer ID',
        type: 'external-link',
        getLink: (order) => ({
          link: getStripeTransferUrl(order?.promotionTopUpStripeTransferId),
          label: order?.promotionTopUpStripeTransferId,
        }),
      },
      {
        id: 'refundedAmount',
        label: 'Refunded amount',
        type: 'money',
        getValue: (order) =>
          order.refundedAmount > 0 && order.refundReason
            ? `${formatAmount(centsToUnit(Number(order.refundedAmount)))}${currencySymbol(
                order.currency,
              )}`
            : '',
      },
      {
        id: 'refundReason',
        label: 'Refund reason',
        enableColumnFilter: false,
      },
      {
        id: 'refundAgent',
        label: 'Refund agent',
        enableColumnFilter: false,
      },
      {
        id: 'refundDebitedAccount',
        label: 'Refund debited account',
        enableColumnFilter: false,
      },
      {
        id: 'refundedAt',
        label: 'Refund date',
        type: 'date-with-time',
        enableColumnFilter: false,
      },
      {
        id: 'cancellationReason',
        label: 'Cancellation reason',
        enableColumnFilter: false,
      },
      {
        id: 'cancellationRequesterType',
        label: 'Cancellation requester type',
        enableColumnFilter: false,
      },
      {
        id: 'cancellationRequestedAt',
        label: 'Cancellation requested at',
        type: 'date-with-time',
        enableColumnFilter: false,
      },
      {
        id: 'createdAt',
        label: 'Purchased at',
        type: 'date-with-time',
        enableColumnFilter: false,
      },
      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
        enableColumnFilter: false,
      },
    ],
    [buyerLoading, buyerOptions, sellerLoading, sellerOptions, setBuyerSearch, setSellerSearch],
  );
  return { CellOrderDefinition };
};
