import { SellerNews } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useSellerNews = (sellerNewsId: SellerNews['id']) => {
  return useQuery<SellerNews, string>({
    queryKey: QUERIES_KEYS.sellerNews(sellerNewsId),
    queryFn: () => getSellerNews(sellerNewsId),
  });
};

const getSellerNews = async (sellerNewsId: SellerNews['id']): Promise<SellerNews> => {
  try {
    const path = `/database-explorer/seller-news/${sellerNewsId}`;
    const result = await authenticatedInstance.get<SellerNews>(path);
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError<{ message?: string }>) {
      throw error.response?.data.message;
    }

    throw String(error);
  }
};
