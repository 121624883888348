import { useEffect, useState } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@/components/elements/buttons/LoadingButton';
import { useOwnUser } from '@/context/own-user.context';

import { useBulkUpdateBadges } from '../../hooks/bulk-update-badge/use-bulk-update-badge';

export const BulkUpdateBadgesModal = () => {
  const {
    closeModal,
    openModal,
    modalIsOpen,
    isLoading,
    handleSubmit,
    error,
    onDropFile,
    currentProcessing,
    userList,
    errorUsers,
    badges,
    onBadgeChange,
    selectedBadge,
    result,
  } = useBulkUpdateBadges();

  const { hasPermissions } = useOwnUser();

  const { t } = useTranslation();

  const theme = useTheme();
  const { getRootProps, acceptedFiles } = useDropzone({
    onDrop: onDropFile,
    maxFiles: 1,
    accept: { 'text/csv': ['.csv'] },
  });

  const [fileName, setFileName] = useState<string | null>(null);

  const ownUserIsAllowedToUpdateBadge = hasPermissions(
    PERMISSIONS.ADD_BADGE_TO_USER.WRITE_ADD_BADGE_TO_USER_BULK,
  );

  useEffect(() => {
    if (acceptedFiles.length === 0) {
      setFileName(null);
    } else {
      setFileName(acceptedFiles[0].name);
    }
  }, [acceptedFiles]);

  const csvTemplateUrl = window.URL.createObjectURL(new Blob(['user id'], { type: 'text/csv' }));

  if (!ownUserIsAllowedToUpdateBadge) {
    return null;
  }

  const findBadgeName = () => {
    return badges.find((badge) => +badge.id === selectedBadge)?.name;
  };

  return (
    <>
      <button
        className="text-slate-900 bg-slate-200 hover:bg-slate-400 focus:ring-2 focus:outline-none focus:ring-sky-300 font-semibold rounded text-xs px-3 py-1.5 text-center inline-flex items-center "
        onClick={openModal}
      >
        {t('updateBadges.button')}
      </button>

      <Dialog
        open={modalIsOpen}
        PaperProps={{
          sx: {
            width: '50%',
          },
        }}
      >
        <DialogTitle display="flex" justifyContent="space-between" color="common.black">
          {t('updateBadges.title')}

          <IconButton size="small" color="inherit" onClick={closeModal}>
            <CloseIcon color="inherit" />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
          <Box
            {...getRootProps()}
            sx={{
              backgroundColor: theme.palette.grey[200],
              width: '100%',
              height: '50%',
              minHeight: 100,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            borderRadius={4}
          >
            {t('updateBadges.draganddropzone')}

            {fileName && (
              <Typography variant="body2" color={({ palette }) => palette.text.secondary}>
                {fileName}
              </Typography>
            )}
          </Box>
          <Typography
            component="a"
            href={csvTemplateUrl}
            download="bulk-update-user-badges.csv"
            variant="caption"
            sx={{
              textAlign: 'right',
              fontStyle: 'italic',
              textDecoration: 'underline',
              cursor: 'pointer',
              display: 'block',
              marginBottom: '16px',
            }}
          >
            {t('updateBadges.downloadTemplate')}
          </Typography>
          <Typography variant="subtitle1">Please select a badge</Typography>
          <Select
            value={selectedBadge ?? ''}
            onChange={(val) => onBadgeChange(Number(val.target.value))}
          >
            {badges.map((badge) => (
              <MenuItem key={badge.id} value={badge.id}>
                {badge.name}
              </MenuItem>
            ))}
          </Select>

          <Typography component="i">{t('updateBadges.description')}</Typography>
          <Box mt={1} mb={2}>
            {userList.userIds && (
              <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <Typography mt={0} mb={0} component="h3" fontWeight="bold">
                  {t('updateBadges.hint', {
                    badge: findBadgeName(),
                    amountOfUsers: userList.userIds.length,
                  })}
                </Typography>
              </Box>
            )}
          </Box>

          {error && <FormHelperText error>{error}</FormHelperText>}
          {errorUsers?.length ? (
            <Typography color="red" mt={2} mb={0}>
              {t('updateBadges.errorMessage')}
              <Typography color="red" component="span" fontWeight="bold">
                {errorUsers.join(' , ')}
              </Typography>
            </Typography>
          ) : null}
          {result ? (
            <Typography color="green" mt={2} mb={0}>
              {t('updateBadges.successMessage', {
                badge: findBadgeName(),
              })}
            </Typography>
          ) : null}
        </DialogContent>

        <DialogActions
          sx={{
            paddingRight: ({ spacing }) => spacing(3),
            paddingLeft: ({ spacing }) => spacing(3),
            paddingBottom: ({ spacing }) => spacing(2),
          }}
        >
          <UpdatingState isLoading={isLoading} current={currentProcessing} />
          <LoadingButton
            variant="contained"
            loading={isLoading}
            disabled={isLoading || Boolean(error) || !userList.userIds}
            onClick={() => handleSubmit()}
          >
            {t('update')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UpdatingState = ({ current, isLoading }: { current: string; isLoading: boolean }) => {
  const { t } = useTranslation();
  return isLoading ? <p>{t('updateBadges.updatingState', { current })}</p> : null;
};
