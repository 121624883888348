import { SellerNews } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useListSellerNews = () => {
  return useQuery<SellerNews[], string>({
    queryKey: QUERIES_KEYS.sellerNewsList(),
    queryFn: getAllSellerNews,
  });
};

const getAllSellerNews = async (): Promise<SellerNews[]> => {
  try {
    const result = await authenticatedInstance.get<SellerNews[]>('/database-explorer/seller-news');
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError<{ message?: string }>) {
      throw error.response?.data.message;
    }

    throw String(error);
  }
};
