import { useState } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { useOwnUser } from '@/context/own-user.context';

import { authenticatedInstance } from '../../../../../axios/axios.instance';
import { useSnackbar } from '../../../../../components/elements/snackbar/use-snackbar';
import { User } from '../../../users';

export const useDeleteUser = (user: User) => {
  const { hasPermissions } = useOwnUser();
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const snackbar = useSnackbar();

  const { mutate: onUserDelete } = useMutation({
    mutationFn: () =>
      authenticatedInstance.delete<boolean>(`database-explorer/customer/${user.id}/delete-user`),
    onSuccess: () => {
      snackbar.success(`databaseExplorer.users.delete.success`, {
        interpolationMap: { username: user.username },
      });
      onClose();
    },
    onError: () => {
      snackbar.error('somethingWrongHappened');
    },
  });

  const shouldDisplayDeleteUserFunctionality =
    !user?.accountDeletedAt && hasPermissions(PERMISSIONS.USER.WRITE_USER_DELETE);

  return {
    isOpen,
    onOpen,
    onClose,
    onUserDelete,
    shouldDisplayDeleteUserFunctionality,
  };
};
