import { Checkbox, TableRow } from '@mui/material';

import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { LoadingRow } from '@Elements/LoadingRow';
import { TableCellHeaderBorderBottom } from '@Elements/StyledTableCell';

import { toggleAllRefundableOrderItems } from '../../../../refund/use-cases';
import {
  selectAreAllRefundableOrderItemsInTheRefund,
  selectOrderItemsFromRefund,
} from '../../../redux/refund.selectors';
import { HeadCell } from '../RefundOrderItem';

import { RefundOrderItemHeader } from './RefundOrderItemHeader';
import { StickyOrderItemRow } from './StickyOrderItemRow';

type StickyHeaderProps = {
  headCell: HeadCell[];
  isReadOnly: boolean;
};

export const StickyHeader = ({ headCell, isReadOnly }: StickyHeaderProps) => {
  const selectedOrderItems = useAppSelector(selectOrderItemsFromRefund);
  const areAllRefundableSelected = useAppSelector(selectAreAllRefundableOrderItemsInTheRefund);

  const dispatch = useAppDispatch();

  const handleOnToggleAll = () => {
    dispatch(toggleAllRefundableOrderItems());
  };

  return (
    <TableRow>
      {!isReadOnly && (
        <TableCellHeaderBorderBottom sx={{ borderBottom: 'none', height: 50 }} padding="checkbox">
          <Checkbox
            checked={areAllRefundableSelected}
            indeterminate={!areAllRefundableSelected && selectedOrderItems.length > 0}
            onChange={handleOnToggleAll}
          />
        </TableCellHeaderBorderBottom>
      )}
      <RefundOrderItemHeader headCells={headCell} />
    </TableRow>
  );
};

type StickyBodyProps = {
  borderRowColSpan: number;
  mainOrderItem: OrderItemRefund;
  orderItems: OrderItemRefund[];
  loading: boolean;
  isReadOnly: boolean;
};

export const StickyBody = ({
  loading,
  orderItems,
  mainOrderItem,
  borderRowColSpan,
  isReadOnly,
}: StickyBodyProps) => {
  return (
    <>
      {loading ? (
        <LoadingRow emptyFirstCell cellCount={borderRowColSpan} />
      ) : (
        <StickyOrderItemRow orderItem={mainOrderItem} isReadOnly={isReadOnly} />
      )}
      {loading ? (
        <LoadingRow emptyFirstCell cellCount={borderRowColSpan} />
      ) : (
        orderItems.map((orderItem) => (
          <StickyOrderItemRow
            key={`sticky-${orderItem.id}`}
            orderItem={orderItem}
            isReadOnly={isReadOnly}
          />
        ))
      )}
    </>
  );
};
