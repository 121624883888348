import { useState } from 'react';

import { Groups, MoreVert, Block } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PromotionsMenuProps {
  handleManageUser(): void;
  handleExpirePromotion(): void;
}

export const PromotionsMenu = ({
  handleManageUser,
  handleExpirePromotion,
}: PromotionsMenuProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="PROMOTIONS-ACTIONS"
        aria-controls={open ? 'PROMOTIONS-ACTIONS' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>

      <Menu
        id="PROMOTIONS-ACTIONS"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          dense
          onClick={(event) => {
            event.stopPropagation();
            handleManageUser();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <Groups fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('promotion.manageUsers')}</ListItemText>
        </MenuItem>

        <MenuItem
          dense
          onClick={(event) => {
            event.stopPropagation();
            handleExpirePromotion();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <Block fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('promotion.expire')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
