import { useRef } from 'react';

import { MarketPageType } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { QUERIES_KEYS, queryClient } from '@/queries';

import { EditorRef } from '../../components/elements/PanelDetailImage';
import { FieldError } from '../../domain/errors/FieldError';

export const useEditMarketPageImage = (marketPageId: string) => {
  const snackbar = useSnackbar();
  const editorRef = useRef<EditorRef>(null);

  const { mutate: editImage } = useMutation<MarketPageType, unknown, File>({
    mutationFn: (file) => updateMarketPageImage(file, marketPageId),
    onSuccess: () => {
      if (!marketPageId) return;
      editorRef.current?.clearImage();
      editorRef.current?.close();
      snackbar.success('Image updated successfully');
      queryClient.invalidateQueries([QUERIES_KEYS.marketPage(marketPageId)]);
    },
    onError: (error) => {
      if (error instanceof FieldError) {
        if (error.field === 'image') {
          editorRef.current?.setImageErrors([
            {
              code: 'server-error',
              message: error.message,
            },
          ]);
        }
      }

      snackbar.error('databaseExplorer.show.thumbnailUpdate.error');
    },
  });

  return { editImage, editorRef };
};

const updateMarketPageImage = async (file: File, marketPageId: MarketPageType['id']) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await authenticatedInstance.put(`/market-page/${marketPageId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
