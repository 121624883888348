import {
  centsToUnit,
  formatAmountWithCurrency,
  LedgerPayoutList,
  Currency,
} from '@bits-app/voggtpit-shared';

import { CellDefinition } from '../../components/elements/DataList';

export const LedgerPayoutListCellDefinition: CellDefinition<LedgerPayoutList[0]>[] = [
  {
    id: 'id',
    label: 'Seller ID',
    type: 'internal-link',
    getLink: (record: LedgerPayoutList[0]) => ({
      label: record.id,
      link: `/user/${record.id}`,
    }),
  },
  {
    id: 'username',
    label: 'Username',
    type: 'internal-link',
    getLink: (record: LedgerPayoutList[0]) => ({
      label: record.username,
      link: `/user/${record.id}`,
    }),
  },
  {
    id: 'pendingBalance',
    label: 'Pending balance',
    getValue: (record) =>
      formatAmountWithCurrency(centsToUnit(Number(record.pendingBalance)), Currency.eur),
  },
  {
    id: 'availableBalance',
    label: 'Available balance',
    type: 'money',
    getValue: (record) =>
      formatAmountWithCurrency(centsToUnit(Number(record.availableBalance)), Currency.eur),
  },
  {
    id: 'transferInProgress',
    label: 'Transfer in progress',
    getValue: (record) =>
      formatAmountWithCurrency(centsToUnit(Number(record.transferInProgress)), Currency.eur),
  },
];
