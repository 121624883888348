import { promotionDtoToEntity } from '@bits-app/voggtpit-shared';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { UploadUsersToPromotionDto } from '../gateways/promotions.port';
import { promotionsActions } from '../redux/promotions.slice';

export const uploadUsersToPromotion =
  (data: UploadUsersToPromotionDto): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { promotionsGateway, snackbarGateway }) => {
    dispatch(promotionsActions.setUpdating(true));

    const promotionId = getState().promotions.editingPromotionId;

    try {
      if (!promotionId) throw new Error('No promotion in edition');

      const promotion = await promotionsGateway.uploadUsersToPromotion(promotionId, data);

      dispatch(promotionsActions.updatePromotion(promotionDtoToEntity(promotion)));

      snackbarGateway.success('Users were added to this promotion');
    } catch (error) {
      logger.error(error);

      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(promotionsActions.setUpdating(false));
    }
  };
