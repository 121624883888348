import { useMemo } from 'react';

import { Badge } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

import { DraftBadge } from './entity/draft-badge';

export const useCellDefinition = () => {
  const CellBadgeDefinition = useMemo(
    (): CellDefinition<Badge | DraftBadge>[] => [
      {
        id: 'id',
        label: 'ID',
        type: 'internal-link',
        getLink: (badge) => {
          if (typeof badge.id === 'number') {
            return { link: `/badge/${badge.id}`, label: badge?.id };
          }

          return {
            link: `/badge/draft/${badge.id}`,
            label: `draft-${(badge.id as string).slice(0, 5)}`,
          };
        },
      },
      {
        id: 'filename',
        label: 'Image url',
        type: 'image',
        getValue: (badge) =>
          `${process.env.REACT_APP_CDN_UNOPTIMIZED_BADGES_URL}/${badge.filename}`,
      },
      {
        id: 'createdAt',
        label: 'Created at',
        type: 'date',
      },
      {
        id: 'name',
        label: 'name',
      },
      {
        id: 'colorAccent',
        label: 'Color accent',
        type: 'color',
      },

      {
        id: 'updatedAt',
        label: 'Updated at',
        type: 'date-with-time',
      },
    ],
    [],
  );

  return { CellBadgeDefinition };
};
