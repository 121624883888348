import { Show } from '@bits-app/bits-server-data';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';

import { useSnackbar } from '../../../components/elements/snackbar/use-snackbar';

export const useUnlockShow = (showId: Show['id']) => {
  const snackbar = useSnackbar();
  return useMutation({
    mutationFn: () => authenticatedInstance.put(`/database-explorer/show/${showId}/unlock`),
    onSuccess: () => {
      snackbar.success('databaseExplorer.show.clearCache.success');
    },
    onError: () => {
      snackbar.error('somethingWrongHappened');
    },
  });
};
