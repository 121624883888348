import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/redux/store';

import { DatabaseExplorerState } from './database-explorer';

const initialState: DatabaseExplorerState = {
  data: {},
  editionSchema: null,
  loading: false,
  error: null,
};

export const databaseExplorerSlice = createSlice({
  name: 'databaseExplorer',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<DatabaseExplorerState['data']>) => {
      state.data = payload;
    },
    setIsLoading: (
      state,
      { payload: loading }: PayloadAction<DatabaseExplorerState['loading']>,
    ) => {
      state.loading = loading;
    },
    setError: (state, { payload }: PayloadAction<DatabaseExplorerState['error']>) => {
      state.error = payload;
    },
    setEditionSchema: (
      state,
      { payload }: PayloadAction<DatabaseExplorerState['editionSchema']>,
    ) => {
      state.editionSchema = payload;
    },
  },
});

export const databaseExplorerAction = databaseExplorerSlice.actions;

export const resourceSelector = (state: RootState) => state.databaseExplorer;
export const selectResourceLoading = createSelector(resourceSelector, (state) => state.loading);

export default databaseExplorerSlice.reducer;
