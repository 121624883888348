import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';

import { useOwnUser } from '@/context/own-user.context';

export const Home = () => {
  const { ownUserPermissions } = useOwnUser();
  const { t } = useTranslation();

  if (ownUserPermissions.length != 0) return <Navigate to="/user" />;

  return (
    <div className="flex flex-col flex-1 h-full items-center justify-center">
      <h2 className="text-2xl font-semibold">{t('noAccess.title')}</h2>
      <p className="mb-2">{t('noAccess.content')}</p>
      <p>{t('noAccess.email')}</p>
    </div>
  );
};
