import { Badge } from '@bits-app/bits-server-data';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';
import { UpsertBadgeModal } from '@/voggt-database-explorer/badge/components/upsert-badge/UpsertBadge.modal';
import { DraftBadge } from '@/voggt-database-explorer/badge/entity/draft-badge';

import { useUpsertBadge } from '../../../components/upsert-badge/use-upsert-badge.hook';

type CreateBadgeProps = {
  badges: (Badge | DraftBadge)[];
};

export const CreateBadge = ({ badges }: CreateBadgeProps) => {
  const { t } = useTranslation();

  const { ownUserIsAllowedToUpsertBadge, onOpenForm, openForm, onCloseForm } = useUpsertBadge();

  if (!ownUserIsAllowedToUpsertBadge) {
    return null;
  }

  return (
    <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}>
      <ButtonOutlined onClick={onOpenForm}>
        {t('databaseExplorer.badge.create.action')}
      </ButtonOutlined>

      {openForm && <UpsertBadgeModal badges={badges} onClose={onCloseForm} />}
    </Box>
  );
};
