import { useTranslation } from 'react-i18next';

import { AddFilterFunctionParams } from '../../../../hooks/use-table-options';

import { OrderItemsMassUpdateFreeFilter } from './OrderItemsMassUpdateFreeFilter';

type TrackingNumberProps = {
  activeFilters?: string[];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isUnsaved: boolean;
};

export const TrackingNumberFilter = ({
  activeFilters,
  addFilter,
  isUnsaved,
}: TrackingNumberProps) => {
  const { t } = useTranslation();

  return (
    <OrderItemsMassUpdateFreeFilter
      addFilter={addFilter}
      field="trackingNumber"
      isValueValid={isTrackingNumberValid}
      inputPlaceholder={t('orderItemsMassUpdate.searchFilter.trackingNumber')}
      activeFilters={activeFilters}
      isUnsaved={isUnsaved}
    />
  );
};

const isTrackingNumberValid = (value: string) => {
  return value.length > 0;
};
