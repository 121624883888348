import { useCallback, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { parse } from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { QUERIES_KEYS, queryClient } from '@/queries';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

import { selectPromotionsUpdating } from '../../../redux/promotions-selector';
import { uploadUsersToPromotion } from '../../../use-cases/upload-users-to-promotion';

export interface AddUsersFromCsvStepProps {
  handleNext: () => void;
}

export const AddUsersFromCsv = ({ handleNext }: AddUsersFromCsvStepProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isUpdating = useAppSelector(selectPromotionsUpdating);
  const promotionId = useAppSelector((state) => state.promotions.editingPromotionId);

  const [userIds, setUserIds] = useState<Array<number>>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    parse(acceptedFiles[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results: { data: Array<Record<string, unknown>> }) {
        const ids: Array<number> = [];

        for (const row of results.data) {
          if (row['UserId']) ids.push(Number(row['UserId']));
          if (row['userIds']) ids.push(Number(row['userIds']));
        }

        setUserIds((prev) => [...ids, ...prev]);
      },
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'text/csv': [],
    },
  });

  const handleUpload = async () => {
    try {
      await dispatch(uploadUsersToPromotion({ userIds }));
      if (promotionId) queryClient.invalidateQueries(QUERIES_KEYS.userTargetPromotion(promotionId));
      handleNext();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {!userIds.length && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            p: 12,
            border: 'solid 1px black',
            borderRadius: 5,
            my: 4,
            textAlign: 'center',
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <>{t('promotion.dropYourCsvHere')}</>
          ) : (
            <>{t('promotion.dragAndDropYourCsvHere')}</>
          )}
        </Box>
      )}

      {!!userIds.length && (
        <>
          <Typography variant="h6" textAlign="center" mt={8} mb={2}>
            {t('promotion.youWillUploadUsers', { count: userIds.length })}
          </Typography>

          <Box display="flex" justifyContent="center">
            <Button color="primary" variant="outlined" onClick={() => setUserIds([])}>
              {t('promotion.resetDropdown')}
            </Button>
          </Box>
        </>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 4 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <LoadingButton
          loading={isUpdating}
          disabled={!userIds?.length || isUpdating}
          variant="contained"
          color="primary"
          onClick={handleUpload}
        >
          {t('promotion.uploadAndFinish')}
        </LoadingButton>
      </Box>
    </>
  );
};
