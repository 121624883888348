export type EntityName =
  | 'badge'
  | 'category'
  | 'channel'
  | 'comment'
  | 'commercial_event'
  | 'draft_badge'
  | 'emote'
  | 'operational_users'
  | 'order'
  | 'ownUser'
  | 'partner_program'
  | 'payment'
  | 'payout'
  | 'product'
  | 'promotion'
  | 'refund'
  | 'seller_config'
  | 'seller_news'
  | 'shipment'
  | 'shipping_address'
  | 'shippingFeeConfig'
  | 'shop'
  | 'show'
  | 'user'
  | 'market_page'
  | 'seller_company'
  | 'featured_show_application';

export enum DatabaseSource {
  own = 'own',
  bits = 'bits',
}
