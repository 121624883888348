import { unitToCents, UpdatePartnerProgramDto } from '@bits-app/voggtpit-shared';

import { AppThunkAction } from '@/redux/store';

import { selectPartnerPrograms } from '../partner-program.selector';
import { partnerProgramActions } from '../partner-program.slice';

export const editPartnerProgram =
  (
    partnerProgramId: string,
    partnerProgramChange: UpdatePartnerProgramDto,
  ): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { partnerProgramGateway, snackbarGateway }) => {
    const allPartnerProgram = selectPartnerPrograms(getState());
    const allName = withoutSelf(allPartnerProgram, partnerProgramId).map(
      (partnerProgram) => partnerProgram.name,
    );
    const nameAlreadyExist = allName.includes(partnerProgramChange.name);
    if (nameAlreadyExist) {
      snackbarGateway.error('name already exist');
      return;
    }

    dispatch(partnerProgramActions.setIsLoading(true));
    try {
      const { amount, percentageMaxAmount, ...rest } = partnerProgramChange;

      const changes = Object.assign(
        rest,
        amount != null && { amount: unitToCents(amount) },
        percentageMaxAmount != null && { percentageMaxAmount: unitToCents(percentageMaxAmount) },
      );

      await partnerProgramGateway.updateOne(partnerProgramId, changes);

      dispatch(partnerProgramActions.optimisticUpdate({ partnerProgramId, partnerProgramChange }));
    } catch (e) {
      console.log('Error : ', e);
      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(partnerProgramActions.setIsLoading(false));
      dispatch(partnerProgramActions.selectForEdition(null));
    }
  };

function withoutSelf<T extends { id: string | number }>(ref: T[], id: string | number): T[] {
  return ref.filter((data) => data.id !== id);
}
