import { useState } from 'react';

import { NavLink } from 'react-router-dom';

import { logout } from '@/auth/use-cases';
import { useAppDispatch } from '@/redux/reduxAppHooks';

import { useEnvironment } from '../../hooks/use-environment';

export type NavigationItem = {
  label: string;
  route: string;
  isActive?: boolean;
  children?: Array<NavigationItem>;
  isOpen?: boolean;
};

type NavigationProps = {
  items: NavigationItem[];
};

export const Navigation = ({ items }: NavigationProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  const handleLogout = () => dispatch(logout());

  const { data: environmentData, isLoading, error } = useEnvironment();

  const getEnvironmentColor = () => {
    if (isLoading || error) return 'bg-slate-700';

    switch (environmentData?.environment) {
      case 'development':
        return 'bg-[#6D0041]';
      case 'sandbox':
        return 'bg-[#055536]';
      default:
        return 'bg-slate-700';
    }
  };

  return (
    <>
      <div className={`flex-col min-w-[250px] bg-slate-700 pt-2 overflow-y-auto hidden md:flex`}>
        {environmentData?.environment !== 'production' && (
          <div className={`${getEnvironmentColor()} p-2`}>
            <p className="text-white font-semibold">{environmentData?.environment}</p>
          </div>
        )}

        {items.map(({ label, children }) => (
          <div key={label} className="pl-2 py-2.5">
            <p className="text-slate-400 text-sm font-semibold mb-2">{label}</p>

            {children?.map((child) => (
              <NavLink to={child.route} key={child.label}>
                {({ isActive }) => (
                  <p
                    className={`text-base px-2 py-2 rounded-l-lg ${
                      isActive ? 'text-slate-700 bg-slate-100' : 'text-white hover:bg-slate-500'
                    }`}
                  >
                    {child.label}
                  </p>
                )}
              </NavLink>
            ))}
          </div>
        ))}

        <div className="flex px-4 mt-5 mb-4 border-t pt-2 border-slate-700">
          <button className="text-slate-300" color="primary" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>

      <div className="md:hidden">
        <div className="bg-sky-950 flex justify-between p-4" onClick={() => setIsOpen(!isOpen)}>
          <h3 className="text-white uppercase text-sm text-bold">Vpit</h3>

          <p className="text-slate-300 text-sm flex text-semibold">Menu</p>
        </div>

        {isOpen && (
          <div className="absolute w-full p-1 h-2/4 z-40">
            <div className="bg-sky-950 rounded shadow overflow-hidden h-full">
              <div className="overflow-auto h-full">
                {items.map(({ label, children }) => (
                  <div className="px-4 py-2" key={label}>
                    <p className="mb-0 text-sm text-slate-500 uppercase mb-2">{label}</p>

                    {children?.map((child) => (
                      <NavLink to={child.route} key={child.label}>
                        {({ isActive }) => (
                          <p
                            className={`text-base px-2 py-1 rounded hover:bg-sky-900 ${
                              isActive ? 'text-white bg-sky-800' : 'text-slate-300'
                            }`}
                          >
                            {child.label}
                          </p>
                        )}
                      </NavLink>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
