import { AppThunkAction } from '@/redux/store';

import { selectIsSellerCancelRequestInTheRefund } from '../redux/seller-cancel-requests.selectors';
import { sellerCancelRequestsActions } from '../redux/seller-cancel-requests.slice';

export const toggleSellerCancelRequestFromRefund =
  (orderItemId: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const isSellerCancelRequestInTheRefund = selectIsSellerCancelRequestInTheRefund(
      getState(),
      orderItemId,
    );

    if (isSellerCancelRequestInTheRefund) {
      dispatch(sellerCancelRequestsActions.removeFromRefund(orderItemId));
      return;
    }

    dispatch(sellerCancelRequestsActions.addInRefund(orderItemId));
  };
