import {
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryColumn,
} from 'typeorm';

import { User } from './User';

@Entity({ name: 'user_follow_seller' })
export class UserFollowUser {
  @Index()
  @PrimaryColumn()
  sellerId!: number;

  @ManyToOne(() => User, (user) => user.followedUsersRelations, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn({
    name: 'sellerId',
    referencedColumnName: 'id',
  })
  seller!: User;

  @Index()
  @PrimaryColumn()
  userId!: number;

  @ManyToOne(() => User, (user) => user.followedByUsersRelations, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn({
    name: 'userId',
    referencedColumnName: 'id',
  })
  user!: User;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp', nullable: true })
  createdAt!: Date;
}
