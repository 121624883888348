import { Category } from '@bits-app/bits-server-data';
import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Emote } from '../../entity/emote.entity';

import { UpsertEmoteForm } from './UpsertEmoteForm';

type UpsertEmoteModalProps = {
  emotes?: Emote[];
  emote?: Emote;
  categories?: Category[];
  onClose: () => void;
  isOpen: boolean;
};

export const UpsertEmoteModal = ({ emote, emotes, onClose, isOpen }: UpsertEmoteModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen}>
      <Grid container justifyContent="space-between" alignItems="center" p={2} gap={1}>
        <Grid item flex={1}>
          <Typography variant="h6">
            {t(`databaseExplorer.emote.upsertEmote.create.action`)}
          </Typography>
        </Grid>

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </Grid>

      <DialogContent
        sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%', gap: 4 }}
      >
        <UpsertEmoteForm emotes={emotes} emote={emote} afterSuccess={onClose} />
      </DialogContent>
    </Dialog>
  );
};
