import { RefundDebitedAccount, RefundReason } from '@bits-app/voggtpit-shared';

import { AppThunkAction } from '@/redux/store';

import { refundActions } from '../../redux/refund.slice';

export const getDefaultRefundForDamagedProduct = (): AppThunkAction<void> => (dispatch) => {
  dispatch(refundActions.setDebitedAccount(RefundDebitedAccount.platform));
  dispatch(refundActions.setRefundReason(RefundReason.damagedProduct));
};
