import CheckIcon from '@mui/icons-material/Check';
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';
import { FilterButton } from '@Elements/FilterButton';
import { InfiniteScrollContainer } from '@Elements/InfiniteScrollContainer';

import { useFreeValueFilter } from './use-free-value-filter';

type FreeValueFilterProps = {
  activeFilters?: string[];
  field: AddFilterFunctionParams['field'];
  addFilter: (filter: AddFilterFunctionParams) => void;
  isValueValid?: (value: string) => boolean;
  inputPlaceholder: string;
  isUnsaved: boolean;
  loading: boolean;
  values: string[];
  getFilterValuesFor: ({ field, search }: { field: string; search: string }) => void;
  getNextPageFilterValuesFor: ({
    field,
    search,
    page,
  }: {
    field: string;
    search: string;
    page: number;
  }) => void;
  disabled?: boolean;
};

export const FreeValueFilter = ({
  field,
  addFilter,
  activeFilters = [],
  inputPlaceholder,
  isUnsaved,
  loading,
  values,
  getFilterValuesFor,
  getNextPageFilterValuesFor,
  disabled,
}: FreeValueFilterProps) => {
  const { t } = useTranslation();

  const {
    availableValues,
    value,
    checked,
    options,
    isApplyButtonDisabled,
    onClose,
    onChange,
    onAddOption,
    onToggle,
    onApplyFilter,
    onValuesReachEnd,
    noResults,
    infiniteScrollContainerRef,
  } = useFreeValueFilter({
    addFilter,
    activeFilters,
    field,
    isUnsaved,
    getFilterValuesFor,
    getNextPageFilterValuesFor,
    values,
  });

  return (
    <FilterButton
      isActive={activeFilters.length > 0}
      onClose={onClose}
      unSaved={!isApplyButtonDisabled}
      disabled={disabled}
    >
      <Box component="form" onSubmit={onAddOption} p={2}>
        <TextField
          variant="standard"
          value={value}
          onChange={onChange}
          placeholder={inputPlaceholder}
          InputProps={{
            endAdornment: loading ? <CircularProgress size={16} /> : <Box width={16} />,
          }}
        />
      </Box>

      {options.length > 0 && (
        <>
          <Box maxHeight={200} sx={{ overflowY: 'auto' }}>
            <List>
              {options.map((id) => (
                <ListItemButton key={id} onClick={onToggle(id)} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={id} />
                </ListItemButton>
              ))}
            </List>
          </Box>
          <Divider />
        </>
      )}

      {availableValues.length > 0 ? (
        <InfiniteScrollContainer
          maxHeight={200}
          onReachEnd={onValuesReachEnd}
          ref={infiniteScrollContainerRef}
        >
          <List>
            {availableValues.map((id) => (
              <ListItemButton key={id} onClick={onToggle(id)} dense>
                <ListItemIcon>
                  <Checkbox edge="start" tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={id} />
              </ListItemButton>
            ))}
            {loading && (
              <ListItem>
                <CircularProgress size={20} />
              </ListItem>
            )}
          </List>
        </InfiniteScrollContainer>
      ) : (
        noResults && !loading && <Box p={2}>{t('noEntries', { field })}</Box>
      )}

      {options.length > 0 && (
        <Box display="flex" flexDirection="column" alignItems="flex-end" p={1}>
          <Button
            onClick={onApplyFilter}
            startIcon={<CheckIcon />}
            variant="contained"
            disabled={isApplyButtonDisabled}
          >
            {t('applyFilters')}
          </Button>
        </Box>
      )}
    </FilterButton>
  );
};
