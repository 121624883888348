import { Badge } from '@bits-app/bits-server-data';

export type UserList = {
  userIds: number[] | null;
};

type ReducerStateType = {
  modalIsOpen: boolean;
  isLoading: boolean;
  error: null | string;
  currentProcessing: '0' | string;
  userList: UserList;
  errorUsers: number[] | null;
  result: boolean;
  selectedBadge: Badge['id'] | null;
};

export const CHUNK_SIZE = 5;

export const initialState: ReducerStateType = {
  modalIsOpen: false,
  isLoading: false,
  error: null,
  currentProcessing: '0',
  userList: { userIds: null },
  errorUsers: null,
  result: false,
  selectedBadge: null,
};

type ActionReducer_UpdateUserList = {
  type: 'updateUserList';
  payload: { userList: UserList };
};

type ActionReducer_UpdateSelectedBadge = {
  type: 'updateSelectedBadge';
  payload: { selectedBadge: Badge['id'] };
};

type ActionReducer_UpdateCurrentProcessing = {
  type: 'updateCurrentProcessing';
  payload: { percentageUpdate: string };
};
type ActionReducer_UpdateErrorUsers = {
  type: 'updateErrorUsers';
  payload: { errorUsers: number[] };
};

type ActionReducer_UpdateError = {
  type: 'updateError';
  payload: { error: string };
};

export type ActionReducer =
  | {
      type:
        | 'userOpenModal'
        | 'loadingSwitchOn'
        | 'loadingSwitchOff'
        | 'userCloseModal'
        | 'cleanSuccessMessage'
        | 'cleanError'
        | 'setSuccessMessage'
        | 'cleanErrorMessage';
    }
  | ActionReducer_UpdateCurrentProcessing
  | ActionReducer_UpdateUserList
  | ActionReducer_UpdateSelectedBadge
  | ActionReducer_UpdateErrorUsers
  | ActionReducer_UpdateError;

export const reduceUseUpdateBadges = (
  state: ReducerStateType,
  action: ActionReducer,
): ReducerStateType => {
  switch (action.type) {
    case 'userOpenModal': {
      return {
        ...state,
        modalIsOpen: true,
      };
    }
    case 'userCloseModal': {
      return initialState;
    }
    case 'loadingSwitchOn': {
      return { ...state, isLoading: true };
    }
    case 'updateError': {
      return { ...state, error: action.payload.error };
    }
    case 'updateErrorUsers': {
      return { ...state, errorUsers: [...(state.errorUsers || []), ...action.payload.errorUsers] };
    }
    case 'loadingSwitchOff': {
      return { ...state, isLoading: false, currentProcessing: '0' };
    }
    case 'updateUserList': {
      return { ...state, userList: action.payload.userList };
    }
    case 'updateSelectedBadge': {
      return { ...state, selectedBadge: action.payload.selectedBadge };
    }
    case 'cleanError': {
      return { ...state, error: null };
    }
    case 'cleanErrorMessage': {
      return { ...state, errorUsers: null };
    }
    case 'cleanSuccessMessage': {
      return { ...state, result: false };
    }
    case 'setSuccessMessage': {
      return { ...state, result: !state?.errorUsers?.length };
    }

    default:
      return state;
  }
};
