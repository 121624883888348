import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Currency } from '../models/currency';
import { ShippingCategory } from '../models/shippingCategory';

import { Category } from './Category';
import { Country } from './Country';
import { Language } from './Language';
import { Product } from './Product';
import { User } from './User';

@Entity()
export class Shop {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @ManyToOne(() => Category)
  @JoinColumn({ name: 'categoryId' })
  category!: Category;

  @Column()
  categoryId!: Category['id'];

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'countryId' })
  country!: Country;

  @Column()
  countryId!: Country['id'];

  @ManyToOne(() => Language)
  @JoinColumn({ name: 'languageId' })
  language!: Language;

  @Column({ nullable: false })
  languageId!: Language['id'];

  @Column({
    type: 'enum',
    enum: Currency,
    default: Currency.eur,
    enumName: 'currency_enum',
  })
  currency!: Currency;

  @Column({ type: 'float', default: 8.9 })
  percentageFee!: number;

  @Column({ type: 'integer', default: 0 })
  fixedFee!: number;

  @Column({ type: 'boolean', default: false })
  includeShippingFeesForCommission!: boolean;

  @Column({
    type: 'enum',
    enum: ShippingCategory,
    default: ShippingCategory.letter,
    enumName: 'shippingcategory_enum',
  })
  shippingCategory!: ShippingCategory;

  @OneToMany(() => Product, (product) => product.shop, { cascade: true })
  products!: Product[];

  @ManyToOne(() => User)
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Index()
  @Column()
  userId!: number;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;
}
