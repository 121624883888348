import { Dispatch, useCallback, useReducer } from 'react';

import { unitToCents } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { parse as parseCsv } from 'papaparse';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '../../../../axios/axios.instance';
import { useSnackbar } from '../../../../components/elements/snackbar/use-snackbar';

import { ActionReducer, initialState, reduceUseUpdateFee, UserGrouped } from './reducer';

export const useBulkUpdateFees = () => {
  const [state, dispatch] = useReducer(reduceUseUpdateFee, initialState);

  const { t } = useTranslation();

  const {
    closeModal,
    loadingSwitchOff,
    openModal,
    updateUserGrouped,
    updateError,
    cleanError,
    cleanErrorMessage,
    cleanSuccessMessage,
    updateCurrent,
  } = getMethod(dispatch);

  const snackbar = useSnackbar();

  const { mutate: handleSubmit, isLoading } = useMutation({
    mutationFn: async () => {
      const batch = Object.values(state.userGrouped);
      let pointer = 0;
      let hasError = false;
      for (const {
        fixedFee,
        percentageFee,
        includeShippingFeesForCommissionByDefault,
        userIds,
      } of batch) {
        const percentage = ((pointer++ / (batch.length + 1)) * 100).toFixed(2);
        await authenticatedInstance
          .post(`/database-explorer/customer/update-mass-fee/`, {
            userIds,
            fees: {
              percentageFee: percentageFee,
              fixedFee: fixedFee != null ? unitToCents(fixedFee) : undefined,
              includeShippingFeesForCommissionByDefault: includeShippingFeesForCommissionByDefault,
            },
          })
          .catch((error) => {
            if (error instanceof Error) {
              const errorParsed = JSON.parse(error.message);
              if (errorParsed.userIdsNotUpdated) {
                updateError(errorParsed.userIdsNotUpdated);
              }
              hasError = true;
            }
          })
          .finally(() => {
            updateCurrent(percentage);
          });
      }
      if (hasError) {
        throw new Error('userIdsNotUpdated');
      }
    },
    onSuccess: () => {
      snackbar.success('updateFees.success');
    },
    onError: () => {
      snackbar.error('updateFees.error');
    },
  });

  const onDropFile = useCallback((files: File[]) => {
    const [file] = files;
    const reader = new FileReader();

    reader.onloadstart = () => {
      cleanErrorMessage();
      cleanSuccessMessage();
      cleanError();
    };

    reader.onload = () => {
      const { result } = reader;
      if (typeof result === 'string') {
        const transformHeaderCsvToJson = (header: string) => {
          if (header.trim() === 'user id') {
            return 'userId';
          }

          if (header.trim() === 'percentage fee') {
            return 'percentageFee';
          }

          if (header.trim() === 'fixed fee') {
            return 'fixedFee';
          }

          if (header.trim() === 'include shipping fees for commission by default') {
            return 'includeShippingFeesForCommissionByDefault';
          }

          return header;
        };

        const { data } = parseCsv<{
          userId: string;
          percentageFee: string;
          fixedFee: string;
          includeShippingFeesForCommissionByDefault: string;
        }>(result, {
          header: true,
          transformHeader: transformHeaderCsvToJson,
          skipEmptyLines: true,
        });

        if (data.length === 0) {
          updateError(t('updateFees.errors.emptyFile'));
          reader.dispatchEvent(new Event('error'));
          return;
        }

        if (
          data[0].userId === undefined ||
          (data[0].percentageFee === undefined &&
            data[0].fixedFee === undefined &&
            data[0].includeShippingFeesForCommissionByDefault === undefined)
        ) {
          const transformHeaderJsonToCSV = (header: string) => {
            if (header === 'userId') {
              return 'user id';
            }

            if (header === 'percentageFee') {
              return 'percentage fee';
            }

            if (header === 'fixedFee') {
              return 'fixed fee';
            }

            if (header.trim() === 'includeShippingFeesForCommissionByDefault') {
              return 'include shipping fees for commission by default';
            }

            return header;
          };

          updateError(
            t('updateFees.errors.wrongHeader', {
              given: Object.keys(data[0]).map(transformHeaderJsonToCSV).join(', '),
            }),
          );
          reader.dispatchEvent(new Event('error'));
          return;
        }

        const userGrouped = data.reduce<UserGrouped>((all, row) => {
          const { userId, percentageFee, fixedFee, includeShippingFeesForCommissionByDefault } =
            row;

          if (!userId.match(/^\d+$/)) {
            updateError(t('updateFees.errors.userIds', { userId }));
            return all;
          }

          if (isNaN(Number(percentageFee))) {
            updateError(t('updateFees.errors.percentageFeeNotANumber', { percentageFee }));
            return all;
          }

          if (isNaN(Number(fixedFee))) {
            updateError(t('updateFees.errors.fixedFeeNotANumber', { fixedFee }));
            return all;
          }

          const truthy = ['true', 'oui', 'yes'];
          const falsy = ['false', 'non', 'no'];
          const none = '';
          const formattedValue = includeShippingFeesForCommissionByDefault.trim().toLowerCase();

          if (![...truthy, ...falsy, none].some((value) => value === formattedValue)) {
            updateError(
              t('updateFees.errors.includeShippingFeesForCommissionByDefault', {
                includeShippingFeesForCommissionByDefault,
              }),
            );
            return all;
          }

          const key = `${percentageFee}_${fixedFee}`;
          const currentIds = all[key]?.userIds || [];

          all[key] = {
            userIds: [...currentIds, Number(userId)],
            percentageFee: percentageFee === '' ? undefined : Number(percentageFee),
            fixedFee: fixedFee === '' ? undefined : Number(fixedFee),
            includeShippingFeesForCommissionByDefault: truthy.includes(formattedValue)
              ? true
              : falsy.includes(formattedValue)
                ? false
                : undefined,
          };

          return all;
        }, {} as UserGrouped);

        updateUserGrouped(userGrouped);
      }
      loadingSwitchOff();
    };
    reader.readAsText(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...state,
    isLoading,
    handleSubmit,
    openModal,
    closeModal,
    onDropFile,
  };
};

function getMethod(dispatch: Dispatch<ActionReducer>) {
  const openModal = () => dispatch({ type: 'userOpenModal' });
  const closeModal = () => dispatch({ type: 'userCloseModal' });

  const updateCurrent = (percentageUpdate: string) =>
    dispatch({ type: 'updateCurrentProcessing', payload: { percentageUpdate } });

  const loadingSwitchOn = () => dispatch({ type: 'loadingSwitchOn' });
  const loadingSwitchOff = () => dispatch({ type: 'loadingSwitchOff' });

  const updateUserGrouped = (userGrouped: UserGrouped) =>
    dispatch({ type: 'usersUpdateGrouped', payload: { userGrouped } });

  const updateErrorMessage = (errorUsers: number[]) =>
    dispatch({ type: 'updateErrorUsers', payload: { errorUsers } });

  const cleanError = () => dispatch({ type: 'cleanError' });

  const updateError = (error: string) => dispatch({ type: 'updateError', payload: { error } });

  const cleanErrorMessage = () => dispatch({ type: 'cleanErrorMessage' });

  const cleanSuccessMessage = () => dispatch({ type: 'cleanSuccessMessage' });
  const setSuccessMessage = () => dispatch({ type: 'setSuccessMessage' });

  return {
    openModal,
    closeModal,
    updateCurrent,
    loadingSwitchOff,
    loadingSwitchOn,
    updateUserGrouped,
    updateError,
    cleanError,
    updateErrorMessage,
    cleanErrorMessage,
    cleanSuccessMessage,
    setSuccessMessage,
  };
}
