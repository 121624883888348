import { useEffect, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { EntityWrapper, ResourceType } from '@/voggt-database-explorer/redux/database-explorer';
import {
  getEditionSchema,
  selectEditionSchema,
} from '@/voggt-database-explorer/redux/use-cases/get-edition-schema';
import { updateResource } from '@/voggt-database-explorer/redux/use-cases/update-resource';

export const useEditionView = <T extends EntityWrapper>(
  resourceId: T['id'],
  resourceType: ResourceType,
  onSuccess?: () => void,
) => {
  const editionSchema = useAppSelector(selectEditionSchema);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const previousPagePath = useMemo(
    () => location.pathname.replace(/\/(edit|create).*/, ''),
    [location.pathname],
  );

  const handleOnSuccess = () => {
    onSuccess?.();
    navigate(previousPagePath);
  };

  const onUpdate = async (changes: Partial<T>) => {
    dispatch(updateResource<T>({ resourceId, resourceType, changes }, handleOnSuccess));
  };

  useEffect(() => {
    dispatch(getEditionSchema(resourceId, resourceType));
  }, [dispatch, resourceId, resourceType]);

  return {
    ...editionSchema,
    onUpdate,
  };
};
