import { ALL_PERMISSIONS, Permission, PERMISSIONS } from '@bits-app/voggtpit-shared';

export const getDefaultValue = (permissions: Permission[] = []) => {
  return ALL_PERMISSIONS.reduce(
    (record, permission) => ({
      ...record,
      [permission]: Boolean(permissions.includes(permission)),
    }),
    {} as { [key in Permission]: boolean },
  );
};

export const getPermissions = (permissions: Permission[] = []): PermissionDisplay[] => {
  const perm = Object.entries(PERMISSIONS).reduce(
    (obj, [group, perms]) => {
      return Object.values(perms).reduce((subObj, permission) => {
        const isRead = permission.includes('READ_');
        const isWrite = permission.includes('WRITE_');
        const typedGroup = group as keyof typeof PERMISSIONS;
        const name = permissionToName(permission, typedGroup);

        const key = `${group}_${name}`;

        if (!subObj[key]) {
          subObj[key] = {
            group: typedGroup,
            name,
          };
        }

        if (isRead) {
          subObj[key] = {
            ...subObj[key],
            read: permissions.includes(permission),
          };
        }

        if (isWrite) {
          subObj[key] = {
            ...subObj[key],
            write: permissions.includes(permission),
          };
        }

        return subObj;
      }, obj);
    },
    {} as Record<string, PermissionDisplay>,
  );

  return Object.values(perm);
};

export type PermissionDisplay = {
  group: keyof typeof PERMISSIONS;
  name: string;
  description?: string;
  read?: boolean;
  write?: boolean;
};

const permissionToName = (permission: Permission, group: keyof typeof PERMISSIONS): string =>
  permission
    .replaceAll(group, '')
    .replace('READ_', '')
    .replace('WRITE_', '')
    .replaceAll('_', ' ')
    .trim();

export const nameToPermission = (
  permissionDisplay: PermissionDisplay,
  type: 'READ' | 'WRITE',
): Permission =>
  [type, permissionDisplay.group, permissionDisplay.name.replaceAll(' ', '_')]
    .filter(Boolean)
    .join('_') as Permission;

export const isDisabled = (permission: Permission) => {
  const disabledPermissions: Permission[] = [
    PERMISSIONS.SUPER_PRIVILEGE.WRITE_SUPER_PRIVILEGE_ADMINISTRATION_WRITE_PERMISSIONS_MANAGE_ALL,
    PERMISSIONS.ADMINISTRATION.WRITE_ADMINISTRATION_PERMISSIONS,
  ];

  return disabledPermissions.includes(permission);
};
