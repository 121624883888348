import { useMemo } from 'react';

import { add, centsToUnit, Currency, formatAmountWithCurrency } from '@bits-app/voggtpit-shared';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '@/components/elements/Dropdown';
import { DeleteButton } from '@/components/elements/buttons/DeleteButton';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';
import { euroPanelPromotions } from '@/voggt-database-explorer/promotions/const/promotions-panel-details';
import { EuroPromotionPanelTypes } from '@/voggt-database-explorer/promotions/const/promotions.types';
import { useAttachUserToPromotion } from '@/voggt-database-explorer/users/hooks/use-attach-user-to-promotion';
import { useRemoveUserToPromotion } from '@/voggt-database-explorer/users/hooks/use-remove-user-to-promotion';
import { ListCustom } from '@Elements/DataExplorer/ListCustom';

import { CellUserTargetPromotionDefinition } from '../../../hooks/use-cell-definition';
import { User } from '../../../users';
import { AttachToPromotionModal } from '../modal/AttachToPromotion.modal';
import { DeleteUserTargetPromotionModal } from '../modal/DeleteUserTargetPromotion.modal';

import { UserTargetPromotionTypeWithPromotion } from './type';

type PromotionDetailProps = {
  user: User;
  userTargetPromotionList: User['userTargetPromotions'];
};

export const PromotionDetail = ({ user, userTargetPromotionList = [] }: PromotionDetailProps) => {
  const { t } = useTranslation();

  const attachToPromotionHook = useAttachUserToPromotion(user.id);

  const { globalGivenPromotionsAmountEuro, globalSpentEuro, globalGivenPromotionsEuro } =
    userTargetPromotionList.reduce(
      (all, userTargetPromotion) => {
        const isEuroPromotion = userTargetPromotion.promotion.currency === Currency.eur;
        const isUsed = userTargetPromotion.status === 'used';

        const euroAmount = isEuroPromotion ? userTargetPromotion.promotion.promotionAmount ?? 0 : 0;
        const gbpAmount = !isEuroPromotion ? userTargetPromotion.promotion.promotionAmount ?? 0 : 0;

        const globalEuroProm = isEuroPromotion ? 1 : 0;
        const globalGbpProm = !isEuroPromotion ? 1 : 0;

        return {
          globalSpentEuro: isUsed
            ? all.globalGivenPromotionsAmountEuro
            : (all.globalGivenPromotionsAmountEuro, euroAmount),
          globalSpentGbp: isUsed
            ? all.globalGivenPromotionsAmountGbp
            : add(all.globalGivenPromotionsAmountGbp, gbpAmount),
          globalGivenPromotionsAmountEuro: add(all.globalSpentEuro, euroAmount),
          globalGivenPromotionsAmountGbp: add(all.globalSpentEuro, gbpAmount),
          globalGivenPromotionsEuro: add(all.globalGivenPromotionsEuro, globalEuroProm),
          globalGivenPromotionsGbp: add(all.globalGivenPromotionsGbp, globalGbpProm),
        };
      },
      {
        globalSpentEuro: 0,
        globalSpentGbp: 0,
        globalGivenPromotionsAmountEuro: 0,
        globalGivenPromotionsAmountGbp: 0,
        globalGivenPromotionsEuro: 0,
        globalGivenPromotionsGbp: 0,
      },
    );

  const eurPromotionData: EuroPromotionPanelTypes = {
    globalGivenPromotionsEuro,
    globalSpentEuro: formatAmountWithCurrency(centsToUnit(globalSpentEuro), Currency.eur),
    globalGivenPromotionsAmountEuro: formatAmountWithCurrency(
      centsToUnit(globalGivenPromotionsAmountEuro),
      Currency.eur,
    ),
  };

  const itemsMenuList = useMemo(
    () => [
      {
        onClick: attachToPromotionHook.toggleModal,
        key: 'databaseExplorer.users.promotion.action',
        forbidden: false,
      },
    ],
    [attachToPromotionHook.toggleModal],
  );

  const removeUserToPromotionHook = useRemoveUserToPromotion(user.id);

  return (
    <DetailsLayout>
      <Grid spacing={2} container>
        <Grid
          xs={12}
          justifyContent="flex-end"
          display="flex"
          rowGap={2}
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
        >
          <Stack spacing={2} direction="row" ml={'auto'} pr={1} pl={1} alignItems="center">
            <Dropdown
              title="Actions"
              options={itemsMenuList.map((item) => ({
                onClick: () => item.onClick && item.onClick(),
                forbidden: item.forbidden,
                label: t(item.key),
              }))}
            />
          </Stack>
        </Grid>

        <Grid md={2} xs={12}>
          <Grid sx={{ mb: 2, p: { xs: 0 } }}>
            <PanelDetails<EuroPromotionPanelTypes>
              title={t('databaseExplorer.panel.eurosPromotionDetails')}
              informations={euroPanelPromotions}
              data={eurPromotionData}
            />
          </Grid>
        </Grid>

        <Grid md={10} xs={12}>
          <ListCustom<UserTargetPromotionTypeWithPromotion>
            cellDefinition={CellUserTargetPromotionDefinition}
            results={userTargetPromotionList as unknown as UserTargetPromotionTypeWithPromotion[]}
            enableFilters={false}
            enableGlobalFilter={false}
            renderRowActions={({ row }) => {
              const userTargetPromotionIsUsed = row.original.status === 'used';
              if (userTargetPromotionIsUsed) return null;
              return (
                <DeleteButton
                  onClick={() =>
                    removeUserToPromotionHook.setUserTargetPromotionToDelete(row.original)
                  }
                />
              );
            }}
          />
        </Grid>
      </Grid>

      <AttachToPromotionModal {...attachToPromotionHook} username={user.username || user.id} />
      <DeleteUserTargetPromotionModal
        onConfirm={removeUserToPromotionHook.handleDeleteUserTargetPromotion}
        onCancel={() => removeUserToPromotionHook.setUserTargetPromotionToDelete(null)}
        userTargetPromotionToDelete={removeUserToPromotionHook.userTargetPromotionToDelete}
      />
    </DetailsLayout>
  );
};
