import { useCallback, useState } from 'react';

import { subDays } from 'date-fns';

import { useLedgerPayoutList } from './query/useLedgerPayoutList';
import { useLedgerTriggerPayout } from './query/useLedgerTriggerPayout';

const defaultValues = {
  starAt: subDays(new Date(), 7),
  endAt: new Date(),
};

export const useLedgerList = () => {
  const [page, setPage] = useState(1);
  const [startAt, setStartAt] = useState(defaultValues.starAt);
  const [endAt, setEndAt] = useState(defaultValues.endAt);

  const { data, refetch } = useLedgerPayoutList({
    startAt: startAt.valueOf(),
    endAt: endAt.valueOf(),
    page,
  });

  const { isLoading, mutateAsync } = useLedgerTriggerPayout();

  const handleTriggerPayout = async (sellerIds: number[]) => {
    await mutateAsync(sellerIds);
  };

  const onPaginationChange = useCallback(({ page }: { pageSize: number; page: number }) => {
    setPage(page + 1);
  }, []);

  const results = data?.data.results || [];
  const totalCount = data?.data.totalCount || 0;

  return {
    setStartAt,
    results,
    handleTriggerPayout,
    isLoading,
    setEndAt,
    onPaginationChange,
    totalCount,
    startAt,
    endAt,
    refetch,
  };
};
