import { Promotion } from '@bits-app/voggtpit-shared';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PromotionsState {
  promotions: Array<Promotion>;
  count: number;
  loading: boolean;
  error: string | null;
  updating: boolean;

  editingPromotionId: number | null;
}

const initialState: PromotionsState = {
  count: 0,
  loading: false,
  error: null,
  updating: false,
  promotions: [],
  editingPromotionId: null,
};

export const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    setEntities: (state, { payload }: PayloadAction<PromotionsState['promotions']>) => {
      state.promotions = payload;
    },
    setCount: (state, { payload }: PayloadAction<PromotionsState['count']>) => {
      state.count = payload;
    },
    setLoading: (state, { payload }: PayloadAction<PromotionsState['loading']>) => {
      state.loading = payload;
    },
    setUpdating: (state, { payload }: PayloadAction<PromotionsState['updating']>) => {
      state.updating = payload;
    },
    setError: (state, { payload }: PayloadAction<PromotionsState['error']>) => {
      state.error = payload;
    },
    pushPromotion: (state, { payload }: PayloadAction<Promotion>) => {
      state.promotions.unshift(payload);
    },
    setEditingPromotionId: (
      state,
      { payload }: PayloadAction<PromotionsState['editingPromotionId']>,
    ) => {
      state.editingPromotionId = payload;
    },
    updatePromotion: (state, { payload }: PayloadAction<Promotion>) => {
      const index = state.promotions.findIndex((promotion) => promotion.id === payload.id);

      if (index !== -1) {
        state.promotions[index] = payload;
      }
    },
  },
});

export const promotionsActions = promotionsSlice.actions;
export default promotionsSlice.reducer;
