import { useState } from 'react';

import { Product, Show, CommercialEvent } from '@bits-app/bits-server-data';
import { AvailableEntityForComment } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export const useRemoveFromCommercialEvent = (initialCommercialEventId?: CommercialEvent['id']) => {
  const { t } = useTranslation();
  const [selectedIdItem, setSelectedIdItem] = useState<Show['id'] | Product['id'] | undefined>(
    undefined,
  );

  const snackbar = useSnackbar();

  const getItemType = (itemType: 'products' | 'shows') => {
    if (itemType === 'products') return 'Product';

    return 'Show';
  };

  const { mutate: submitUpdate, isLoading } = useMutation(
    ({
      itemType,
      itemId,
      commercialEventId,
    }: {
      itemType: 'products' | 'shows';
      itemId: Show['id'] | Product['id'];
      commercialEventId: CommercialEvent['id'];
    }) =>
      authenticatedInstance.patch(
        `database-explorer/commercial-event/${commercialEventId}/remove/${itemType}/${itemId}`,
      ),
    {
      onSuccess: (_, variables) => {
        snackbar.success(
          `${getItemType(variables.itemType) + ' ' + variables.itemId} successfully removed from the commercial event ${variables.commercialEventId}`,
        );
        queryClient.invalidateQueries(QUERIES_KEYS.commercialEvent(variables.commercialEventId));
        if (selectedIdItem) {
          queryClient.invalidateQueries(
            QUERIES_KEYS.commercialEventRelatedProducts(variables.commercialEventId),
          );
          queryClient.invalidateQueries(
            QUERIES_KEYS.commercialEventRelatedShows(variables.commercialEventId),
          );
          queryClient.invalidateQueries(
            QUERIES_KEYS.activityLogs(AvailableEntityForComment.show, variables.itemId),
          );
          queryClient.invalidateQueries(
            QUERIES_KEYS.activityLogs(AvailableEntityForComment.product, variables.itemId),
          );
          setSelectedIdItem(undefined);
        }
      },
      onError: () => {
        snackbar.error(t(`databaseExplorer.commercialEvent.remove.shows.error`));
      },
    },
  );

  const onConfirm = (
    itemId: Show['id'] | Product['id'],
    itemType: 'products' | 'shows',
    commercialEventId?: CommercialEvent['id'],
  ) => {
    const eventId = commercialEventId || initialCommercialEventId;
    if (!eventId) {
      throw new Error('Commercial Event not found');
    }
    submitUpdate({ itemType, itemId, commercialEventId: eventId });
  };

  return {
    isLoading,
    selectedIdItem,
    onConfirm,
    discardCurrentItemIdSelected: () => setSelectedIdItem(undefined),
    setItemIdToRemove: (id: Show['id'] | Product['id']) => setSelectedIdItem(id),
  };
};
