import { authenticatedInstance } from '@/axios/axios.instance';

export const updateBadgeTranslations = async (
  badgeName: string,
  translations: Record<string, string>,
) => {
  await authenticatedInstance.patch<void>(`/database-explorer/badge/${badgeName}/translations`, {
    translations,
  });
};
