import { useCallback, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ItemLayoutWithTabs } from '@/voggt-database-explorer/layouts/ItemLayoutWithTabs';

import { ActiveAutoFollowUserCampaignsView } from './active';
import { ArchivedAutoFollowUserCampaignsView } from './archived';

export const AutoFollowUserCampaignTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentTab = location.pathname.includes('archived') ? 'archived' : 'active';

  const handleOnTabChange = useCallback((tab: string) => navigate(tab), [navigate]);
  const tabs = useMemo(
    () => [
      {
        path: 'active',
        label: 'Active',
        Element: <ActiveAutoFollowUserCampaignsView />,
      },
      {
        path: 'archived',
        label: 'Archived',
        Element: <ArchivedAutoFollowUserCampaignsView />,
      },
    ],
    [],
  );

  return <ItemLayoutWithTabs tabs={tabs} currentTab={currentTab} onTabChange={handleOnTabChange} />;
};
