import { useReducer } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { useOwnUser } from '@/context/own-user.context';

import { authenticatedInstance } from '../../../../../axios/axios.instance';
import { QUERIES_KEYS, queryClient } from '../../../../../queries';
import { User } from '../../../users';

export const useUnblockUser = (userId: User['id'], isHardBlocked?: boolean) => {
  const { hasPermissions } = useOwnUser();
  const [isOpen, toggle] = useReducer((v) => !v, false);

  const { mutate: onConfirmUnblockUser } = useMutation({
    mutationFn: () =>
      authenticatedInstance.patch(`/database-explorer/customer/${userId}`, {
        isHardBlocked: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES_KEYS.userDetail(userId));
    },
  });

  return {
    shouldDisplayUnblockUser:
      isHardBlocked && hasPermissions(PERMISSIONS.USER.WRITE_USER_HARD_BLOCK),
    isOpen,
    onConfirmUnblockUser,
    toggle,
  };
};
