import { ShipmentStatus } from '@bits-app/bits-server-data';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  UseRefundAShipmentRegisterController,
  UseRefundAShipmentRegisterForm,
} from '@/voggt-database-explorer/shipment/views/detail/useRefundAShipment';

import { ButtonContained } from '../../../../components/elements/buttons/ButtonContained';

type RefundAShipmentProps = {
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
  shipmentId: string;
  register: UseRefundAShipmentRegisterForm;
  control: UseRefundAShipmentRegisterController;
};

export const RefundAShipment = ({
  onCancel,
  onConfirm,
  isLoading,
  shipmentId,
  register,
  control,
}: RefundAShipmentProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open>
      <DialogTitle id="alert-dialog-title">
        {t('shipment-refund.title', { shipmentId })}
      </DialogTitle>

      <DialogContent>
        <Stack direction={'column'} spacing={2}>
          <DialogContentText>{t('shipment-refund.description')}</DialogContentText>

          <TextField {...register('easyShippingAmount')} label={'easyShippingAmount'} />

          <TextField {...register('externalShippingOrderId')} label={'externalShippingOrderId'} />

          <TextField {...register('labelPrintUrl')} label={'labelPrintUrl'} />

          <TextField {...register('trackingNumber')} label={'trackingNumber'} />

          <Controller
            render={({ field }) => (
              <Select {...field} label={t('shipmentStatus')}>
                {Object.keys(ShipmentStatus).map((shipmentStatus) => (
                  <MenuItem key={shipmentStatus} value={shipmentStatus}>
                    {shipmentStatus}
                  </MenuItem>
                ))}
              </Select>
            )}
            control={control}
            name="status"
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={isLoading}>
          {t('action.cancel')}
        </Button>
        <ButtonContained onClick={onConfirm} disabled={isLoading}>
          {t('action.continue')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
