export type CommentType = {
  id: string;
  entityName: AvailableEntityForComment;
  entityId: string;
  createdBy: string;
  highlightAt?: Date;
  parentId?: string;
  comment: string;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
};

export enum AvailableEntityForComment {
  user = 'user',
  seller_config = 'seller_config',
  order = 'order',
  shipment = 'shipment',
  show = 'show',
  product = 'product',
  commercial_event = 'commercial_event',
}
