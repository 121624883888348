import { Category } from '@bits-app/bits-server-data';
import { KeysWithTranslations } from '@bits-app/voggtpit-shared';
import { useMutation, useQuery } from '@tanstack/react-query';

import { QUERIES_KEYS, queryClient } from '@/queries';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

import { TranslationsForm } from '../../../components/translations/TranslationsForm';

import { getCategoryTranslations } from './use-cases/get-category-translations';
import { updateCategoryTranslations } from './use-cases/update-category-translations';

type CategoryTranslationsProps = {
  category: Category;
  afterSuccess: () => void;
};

export const CategoryTranslations = ({ category, afterSuccess }: CategoryTranslationsProps) => {
  const snackbar = useSnackbar();

  const { data: keysWithTranslations, isLoading } = useQuery<KeysWithTranslations>(
    QUERIES_KEYS.categoryTranslations(category.name),
    () => getCategoryTranslations(category),
  );

  const { mutate } = useMutation<void, unknown, Record<string, string>>(
    (translations) => updateCategoryTranslations(category.name, translations),
    {
      onSuccess: () => {
        snackbar.success('databaseExplorer.translate.success', {
          interpolationMap: { entity: category.name },
        });

        queryClient.invalidateQueries(QUERIES_KEYS.categoryTranslations(category.name));

        afterSuccess();
      },

      onError: () => {
        snackbar.error('databaseExplorer.translate.error', {
          interpolationMap: { entity: category.name },
        });
      },
    },
  );

  return (
    <TranslationsForm
      isLoading={isLoading}
      keysWithTranslations={keysWithTranslations ?? {}}
      update={mutate}
    />
  );
};
