import { useEffect, useState } from 'react';

import { Currency } from '@bits-app/voggtpit-shared';

import { useTableOptions } from '@/hooks/use-table-options';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import {
  selectPromotions,
  selectPromotionsCount,
  selectPromotionsLoading,
} from '../redux/promotions-selector';
import { getPromotions } from '../use-cases/get-promotions';

export const usePromotions = ({ currency }: { currency: Currency }) => {
  const dispatch = useAppDispatch();
  const { options, onChangePage, onOrderBy, addFilter } = useTableOptions();
  const loading = useAppSelector(selectPromotionsLoading);
  const promotions = useAppSelector(selectPromotions);
  const count = useAppSelector(selectPromotionsCount);

  const [isCreatingCampaign, setIsCreatingCampaign] = useState<boolean>(false);
  const [isAddingUsers, setIsAddingUsers] = useState<boolean>(false);

  const launchCampaignCreation = () => setIsCreatingCampaign(true);
  const closeCampaignCreate = () => setIsCreatingCampaign(false);

  const launchAddUsersDialog = () => setIsAddingUsers(true);
  const closeAddUsersDialog = () => setIsAddingUsers(false);

  useEffect(() => {
    dispatch(getPromotions({ ...options, currency }));
  }, [currency, dispatch, options]);

  return {
    loading,
    count,
    promotions,
    options,
    onChangePage,

    isCreatingCampaign,
    launchCampaignCreation,
    closeCampaignCreate,

    isAddingUsers,
    launchAddUsersDialog,
    closeAddUsersDialog,
    onOrderBy,
    addFilter,
  };
};
