import { useMutation } from '@tanstack/react-query';

import { deleteOwnUser } from '@/administration/use-cases';
import { OwnUser } from '@/domain/entities/own-user.entity';
import { QUERIES_KEYS, queryClient } from '@/queries';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export const useDeleteOwnUser = (ownUser: OwnUser | null, onClose: () => void) => {
  const snackbar = useSnackbar();

  return useMutation((ownUserId) => deleteOwnUser(ownUserId), {
    onMutate: async (ownUserId: OwnUser['id']) => {
      const ownUsers = await queryClient.getQueryData<OwnUser[]>(QUERIES_KEYS.ownUsersList());

      if (ownUsers) {
        queryClient.setQueryData(QUERIES_KEYS.ownUsersList(), (old?: OwnUser[]) => {
          if (!old) {
            return;
          }

          return old.filter((oldOwnUser) => oldOwnUser.id !== ownUserId);
        });
      }

      return ownUsers ? { ownUsers } : undefined;
    },
    onSuccess: async () => {
      snackbar.success('administration.deleteOwnUser.success', {
        interpolationMap: { username: ownUser?.fullName ?? '' },
      });

      onClose();
    },
    onError: (_err, _params, context?: { ownUsers: OwnUser[] }) => {
      queryClient.setQueryData(QUERIES_KEYS.ownUsersList(), context?.ownUsers);

      snackbar.error('administration.deleteOwnUser.error', {
        interpolationMap: { username: ownUser?.fullName ?? '' },
      });
    },
  });
};
