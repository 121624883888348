import { forwardRef } from 'react';

import { TextField as TextFieldMUI, TextFieldProps } from '@mui/material';

export const DateInput = forwardRef(
  (props: Omit<TextFieldProps, 'type' | 'ref' | 'variant'>, ref: TextFieldProps['ref']) => {
    return <TextFieldMUI {...props} ref={ref} type="date" />;
  },
);

DateInput.displayName = 'DateInput';
