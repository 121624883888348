import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinTable,
  ManyToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';

import { Category } from './Category';

@Entity()
export class Emote {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Index()
  @Column({ unique: true })
  name!: string;

  @CreateDateColumn()
  createdAt!: Date;

  @DeleteDateColumn()
  deletedAt!: Date;

  @ManyToMany(() => Category, (category) => category.emotes)
  @JoinTable()
  categories!: Category[];

  @Column({ type: 'boolean', default: false })
  isGlobal!: boolean;
}
