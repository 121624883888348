import { TableCell, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableCellHeaderBorderBottom = styled(TableCell, {
  shouldForwardProp: (props) => props !== 'minWidth' && props !== 'maxWidth',
})<{ minWidth?: React.CSSProperties['minWidth']; maxWidth?: React.CSSProperties['maxWidth'] }>(
  ({ theme, minWidth, maxWidth }) => ({
    [`&.${tableCellClasses.head}`]: {
      borderBottomColor: theme.palette.common.black,
      maxWidth: maxWidth,
      minWidth,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  }),
);

export const TableCellBodyNoBorderBottom = styled(TableCell, {
  shouldForwardProp: (props) => props !== 'maxWidth' && props !== 'minWidth',
})<{ maxWidth?: React.CSSProperties['maxWidth']; minWidth?: React.CSSProperties['minWidth'] }>(
  ({ theme, maxWidth, minWidth }) => ({
    [`&.${tableCellClasses.body}`]: {
      borderBottom: 'none',
      height: 52,
      width: maxWidth,
      maxWidth,
      minWidth,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  }),
);

export const TableCellEllipsisContent = styled(TableCellBodyNoBorderBottom, {
  shouldForwardProp: (props) => props !== 'maxWidth',
})<{ maxWidth?: React.CSSProperties['maxWidth'] }>(({ theme, maxWidth }) => ({
  height: 52,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth,
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
}));
