/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  SortingState,
  OnChangeFn,
  getSortedRowModel,
} from '@tanstack/react-table';

export const Table = ({
  perPage,
  columns,
  data,
  page,
  onNextPage = () => null,
  onPreviousPage = () => null,
  sorting = [],
  onSortingChange,
}: {
  columns: any;
  data: any;
  onRowClick?: (rowId: string | number) => void;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  totalResults?: number;
  pagination?: any;
  page?: number;
  perPage: number;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
}) => {
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns: columns,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: { sorting, rowSelection },
    onSortingChange,
    enableSorting: true,
    enableRowSelection: true,
  });

  return (
    <>
      <div className="w-full rounded-lg shadow overflow-auto border border-slate-200">
        <table className="w-full table-auto bg-white p-2">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className="border-b py-2.5 px-3.5 text-left whitespace-nowrap bg-white text-slate-900 text-sm font-semibold border-slate-200 border-r last:border-r-0"
                  >
                    {header.isPlaceholder ? null : (
                      <div className="cursor-pointer select-none flex justify-between align-center">
                        <div className="mr-2">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                        <div>
                          <div className="w-1">
                            {{ asc: '↑', desc: '↓' }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={`border-b border-slate-200 hover:bg-gray-100 hover:text-slate-800 last:border-b-0 whitespace-nowrap`}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="max-w-[850px] overflow-hidden text-ellipsis">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center my-3 w-full">
        <p className="text-slate-600">Rows per page: {perPage}</p>

        <div className="flex items-center">
          <button onClick={onPreviousPage} className="px-1 bg-white rounded mr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-slate-600 hover:text-slate-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          <p className="text-slate-600 text-lg">{page}</p>

          <button onClick={onNextPage} className="px-1 bg-white rounded ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-slate-600 hover:text-slate-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};
