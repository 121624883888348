import { Tab, Tabs } from '@mui/material';
import { subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { formatDate } from '../../utils';

export type OrderItemsMassUpdateTabsValues = 'default' | 'payment-failed' | 'shipped';

const PAYMENT_FAILED_ABOVE_1_DAY_FILTERS = {
  paymentStatus: ['in_progress', 'failed'],
  shippingStatus: ['pending'],
  purchaseDate: { operator: 'below', value: formatDate(subDays(new Date(), 1)) },
};

const SHIPPED_ABOVE_30_DAYS_FILTERS = {
  shippingStatus: ['shipped'],
  updateDate: { operator: 'below', value: formatDate(subDays(new Date(), 30)) },
};

type OrderItemsMassUpdateTabsProps = {
  tab: OrderItemsMassUpdateTabsValues;
};

export const OrderItemsMassUpdateTabs = ({ tab }: OrderItemsMassUpdateTabsProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleChange = (_: React.SyntheticEvent, value: OrderItemsMassUpdateTabsValues) => {
    const params = new URLSearchParams();

    if (value === 'default') {
      navigate('/order-items-mass-update/default');
      return;
    }

    if (value === 'payment-failed') {
      params.set('filters', JSON.stringify(PAYMENT_FAILED_ABOVE_1_DAY_FILTERS));

      navigate(`/order-items-mass-update/payment-failed-above-1day?${params}`);

      return;
    }

    if (value === 'shipped') {
      params.set('filters', JSON.stringify(SHIPPED_ABOVE_30_DAYS_FILTERS));

      navigate(`/order-items-mass-update/shipped-above-30days?${params}`);

      return;
    }
  };

  return (
    <Tabs value={tab} onChange={handleChange} scrollButtons="auto">
      <Tab sx={{ p: 1 }} label={t('orderItemsMassUpdate.tabs.default')} value="default" />
      <Tab
        sx={{ p: 1 }}
        label={t('orderItemsMassUpdate.tabs.paymentFailed')}
        value="payment-failed"
      />
      <Tab sx={{ p: 1 }} label={t('orderItemsMassUpdate.tabs.shipped')} value="shipped" />
    </Tabs>
  );
};
