import { useMemo } from 'react';

import { Show, Category } from '@bits-app/bits-server-data';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';
import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { ItemLayout } from '@/voggt-database-explorer/layouts/ItemLayout';

import { CategoryType } from '../../category.type';
import { useCellDefinition } from '../../use-cell-definition';

import { Detail } from './Detail';
import { useRelatedCategoriesList } from './hooks/use-related-categories-list.hook';
import { CellShowDefinition, useCategoryDetailsView } from './useCategoryDetailsView';

export const CategoryDetail = ({ type }: { type: CategoryType }) => {
  const { t } = useTranslation();

  const { CellCategoryDefinition } = useCellDefinition();

  const categoryId = useIdParam();
  const location = useLocation();

  const { category, loading } = useCategoryDetailsView(
    categoryId,
    type === 'child' ? 'Category' : 'Parent-Category',
  );

  const { relatedCategories } = useRelatedCategoriesList(Number(categoryId));

  const currentTab = useMemo(() => {
    return ['related-categories', 'show'].find((path) => location.pathname.includes(path)) ?? '/';
  }, [location]);

  const handleOnTabChange = useNavigateInDetailTab(`/category/${type}/${categoryId}`);

  const tabs = useMemo(() => {
    const _tabs = [
      {
        path: '/',
        label: t('databaseExplorer.category.tabs.main'),
        Element: <Detail key="/" category={category} loading={loading} type={type} />,
      },
    ];

    if (type === 'child') {
      _tabs.push({
        path: 'show',
        label: t('databaseExplorer.category.tabs.shows'),
        Element: (
          <ListWithSearch<Show & { id: string }>
            title="Shows"
            key="show"
            cellDefinition={CellShowDefinition}
            defaultFilters={[{ field: 'categoryId', values: [categoryId] }]}
            typesenseHookParams={{
              typesenseIndex: 'show',
              typesenseParams: {
                per_page: 20,
                query_by: ['userId'],
              },
            }}
          />
        ),
      });
    } else {
      _tabs.push({
        path: 'related-categories',
        label: t(`databaseExplorer.category.tabs.relatedCategories`),
        Element: (
          <ListCustom<Category>
            key="related-categories"
            cellDefinition={CellCategoryDefinition}
            results={relatedCategories || []}
            enableFilters
            getPathDetail={(row) => `/category/child/${row.id}`}
          />
        ),
      });
    }

    return _tabs;
  }, [CellCategoryDefinition, category, categoryId, loading, relatedCategories, t, type]);

  return (
    <ItemLayout<Category>
      id={categoryId}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
    />
  );
};
