import { SellerCompany } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { QUERIES_KEYS } from '../../../queries';

export const useSellerCompanyByUserId = (userId: number) => {
  return useQuery(QUERIES_KEYS.userSellerCompanyDetail(userId), () =>
    authenticatedInstance.get<SellerCompany>(
      `/database-explorer/seller-company/by-user-id/${userId}`,
    ),
  );
};
