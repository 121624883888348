import React from 'react';

import { Paper, useTheme } from '@mui/material';

export const ContainerDataExplorer = ({
  children,
  flatElevation = false,
}: {
  children: React.ReactNode;
  flatElevation?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Paper
      component="section"
      sx={{
        boxShadow: flatElevation
          ? 'rgb(0 0 0 / 4%) 0px 5px 22px, rgb(0 0 0 / 3%) 0px 0px 0px 0.5px'
          : null,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {children}
    </Paper>
  );
};
