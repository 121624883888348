import { ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';

type DataLinkProps = {
  title: string;
  displayText: string;
  link: string;
  type: 'internal' | 'external';
};

export const DataLink = ({ title, displayText, link, type }: DataLinkProps) => {
  const LinkComponent = type === 'internal' ? Link : 'a';

  if (!link) return null;

  return (
    <LinkComponent
      className="flex justify-between items-center gap-1"
      to={link}
      href={link}
      target={'_blank'}
    >
      <div className="flex flex-col gap-1">
        <p className="font-semibold capitalize">{title}</p>

        <div className="flex gap-1 items-center text-blue-500 hover:underline cursor-pointer break-all">
          {displayText}
        </div>
      </div>

      <div className="flex items-center text-slate-300 hover:text-slate-500 cursor-pointer duration-100 text-blue-500">
        <ExternalLink size={18} />
      </div>
    </LinkComponent>
  );
};
