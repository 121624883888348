import * as yup from 'yup';

import { SchemaValidation } from './validation-schema';

export class MarketPageValidationSchema extends SchemaValidation {
  availableResolver = {
    title: this.resolveTitle(),
    description: this.resolveDescription(),
    slug: this.resolveSlug(),
    categoryId: this.resolveCategoryId(),
  };

  resolveTitle(): yup.StringSchema {
    return yup.string().min(3).required('Title is required.');
  }

  resolveDescription(): yup.StringSchema {
    return yup.string().min(3).required('Description is required.');
  }

  resolveSlug(): yup.StringSchema {
    return yup.string().min(3).required('Slug is required.');
  }

  resolveCategoryId(): yup.NumberSchema {
    return yup.number().required('Category ID is required.');
  }
}
