import { ChangeEvent } from 'react';

import { Category, ParentCategory } from '@bits-app/bits-server-data';
import {
  Grid,
  TextField,
  FormHelperText,
  Alert,
  alpha,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { ExternalLink } from '@/components/elements/Link';
import { LoadingButton } from '@/components/elements/buttons/LoadingButton';
import { ColorInput } from '@/components/elements/inputs/ColorInput';
import { ImageUpload } from '@/voggt-database-explorer/components/ImageUpload/ImageUpload';

import { useGetParentCategories } from '../../../../queries';
import { useUpsertCategoryForm } from '../../views/list/hooks/use-upsert-category-form.hook';

const MAX_FILE_SIZE = 400000;

export type FieldValue = {
  name: string;
  description: string;
  parentCategoryId: ParentCategory['id'] | '';
  colorAccent: string;
  isVisible?: boolean;
};

type UpsertCategoryFormProps = {
  category?: Category;
  categories?: Category[];
  afterSuccess: (category: Category) => void;
  ownUserCanAddTranslations: boolean;
};

export const UpsertCategoryForm = ({
  category,
  categories,
  afterSuccess,
}: UpsertCategoryFormProps) => {
  const { t } = useTranslation();

  const { register, formState, handleSubmit, reset, setError, setValue, control } =
    useForm<FieldValue>({
      defaultValues: {
        name: category?.name ?? '',
        description: category?.description ?? '',
        parentCategoryId: category?.parentCategoryId ?? '',
        colorAccent: category?.colorAccent ?? '',
        isVisible: category?.isVisible,
      },
    });

  const validateName = (value: FieldValue['name']) => {
    if (!categories) {
      return true;
    }

    if (categories.map(({ name }) => name).includes(value)) {
      return t('databaseExplorer.category.upsertCategory.form.errors.nameAlreadyTaken', {
        name: value,
      });
    }
  };

  const { onSubmit, isLoading, onDropImage, image, imageErrors } = useUpsertCategoryForm({
    category,
    setFieldError: setError,
    resetForm: reset,
    afterSuccess,
  });

  const { data: parentCategories } = useGetParentCategories();

  return (
    <Grid container flexDirection="column" gap={3}>
      <Grid item>
        <TextField
          margin="dense"
          fullWidth
          disabled={Boolean(category)}
          {...register('name', {
            validate: validateName,
            required: t('databaseExplorer.category.upsertCategory.form.errors.required', {
              field: t('databaseExplorer.category.upsertCategory.form.name'),
            }),
            minLength: 3,
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;

              if (!value.match(/^[a-zA-Z0-9-]*$/)) {
                setValue('name', value.slice(0, value.length - 1));
                return;
              }

              setValue('name', value.toLowerCase().replaceAll(' ', '-'));
            },
          })}
          label={t('databaseExplorer.category.upsertCategory.form.name')}
        />

        {formState.errors.name && (
          <FormHelperText error>{formState.errors.name.message}</FormHelperText>
        )}

        <Alert
          severity="info"
          sx={{
            backgroundColor: ({ palette }) => alpha(palette.info.light, 0.3),
          }}
        >
          {t('databaseExplorer.category.upsertCategory.form.nameHint')}
        </Alert>
      </Grid>

      <Grid item>
        <Controller
          name="parentCategoryId"
          control={control}
          rules={{
            required: t('databaseExplorer.category.upsertCategory.form.errors.required', {
              field: t('databaseExplorer.category.upsertCategory.form.parentCategory'),
            }),
          }}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="parentCategoryId-label">
                {t('databaseExplorer.category.upsertCategory.form.parentCategory')}
              </InputLabel>
              <Select
                labelId="parentCategoryId-label"
                id="parentCategoryId"
                label={t('databaseExplorer.category.upsertCategory.form.parentCategory')}
                {...field}
                margin="dense"
              >
                {parentCategories.map((parentCategory) => (
                  <MenuItem key={parentCategory.id} value={parentCategory.id}>
                    {parentCategory.name}
                  </MenuItem>
                ))}
              </Select>

              {formState.errors.parentCategoryId && (
                <FormHelperText error>{formState.errors.parentCategoryId.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>

      <Grid item>
        <ImageUpload
          maxSize={MAX_FILE_SIZE}
          imageAcceptedMimeTypes={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
          }}
          currentImageUrl={
            category &&
            `${process.env.REACT_APP_CDN_UNOPTIMIZED_CATEGORIES_URL}/categories/${category?.id}/image`
          }
          image={image}
          imageErrors={imageErrors}
          onDropImage={onDropImage}
          dropImageLabel={t('databaseExplorer.category.upsertCategory.form.file')}
        />

        <Alert
          severity="info"
          sx={{
            mt: 0.5,
            backgroundColor: ({ palette }) => alpha(palette.info.light, 0.3),
          }}
        >
          <Trans i18nKey="databaseExplorer.category.upsertCategory.form.imageHint">
            Category's cover must be a .png file of max size 400Kb. If the file is too large, use a
            tool like <ExternalLink href="https://tinypng.com" label="TinyPNG" />
          </Trans>
        </Alert>
      </Grid>

      <Grid item>
        <TextField
          margin="dense"
          fullWidth
          {...register('description')}
          label={t('databaseExplorer.category.upsertCategory.form.description')}
        />
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Controller
            name="colorAccent"
            control={control}
            render={({ field, fieldState }) => (
              <ColorInput
                format="hex"
                {...field}
                label={t('databaseExplorer.category.upsertCategory.form.colorAccent')}
                helperText={fieldState.invalid ? formState.errors.colorAccent?.message : ''}
                error={fieldState.invalid}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="isVisible"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label={t('databaseExplorer.category.upsertCategory.form.isVisible')}
                control={<Switch {...field} checked={field.value} />}
              />
            )}
          />
        </Grid>
      </Grid>

      <Box alignSelf="flex-end">
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={(!formState.isDirty && !image) || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {category
            ? t('databaseExplorer.category.upsertCategory.update.action')
            : t('databaseExplorer.category.upsertCategory.create.action')}
        </LoadingButton>
      </Box>
    </Grid>
  );
};
