import { currencySymbol, formatAmount } from '@bits-app/voggtpit-shared';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { Checkbox, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InternalLink } from '@/components/elements/InternalLink';
import { OrderItemRefund } from '@/entities/order-item-refund.entity';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { TableCellBodyNoBorderBottom, TableCellEllipsisContent } from '@Elements/StyledTableCell';
import { TextWithConditionalPrefixIcon } from '@Elements/TextWithConditionalPrefixIcon';

import { toggleSellerCancelRequestFromRefund } from '../../seller-cancel-requests/use-cases';
import {
  selectIsSellerCancelRequestInTheRefund,
  selectIsSellerCancelRequestPurchasedWithPromotion,
  selectIsSellerCancelRequestSuspicious,
} from '../redux/seller-cancel-requests.selectors';

type SellerCancelRequestRowProps = {
  sellerCancelRequest: OrderItemRefund;
  isReadOnly: boolean;
};

export const SellerCancelRequestRow = ({
  sellerCancelRequest,
  isReadOnly,
}: SellerCancelRequestRowProps) => {
  const selected = useAppSelector((state) =>
    selectIsSellerCancelRequestInTheRefund(state, sellerCancelRequest.id),
  );
  const isSuspicious = useAppSelector((state) =>
    selectIsSellerCancelRequestSuspicious(state, sellerCancelRequest.id),
  );
  const isPurchasedWithPromotion = useAppSelector((state) =>
    selectIsSellerCancelRequestPurchasedWithPromotion(state, sellerCancelRequest.id),
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleOnSellerCancelRequestToggle = () => {
    dispatch(toggleSellerCancelRequestFromRefund(sellerCancelRequest.id));
  };

  return (
    <TableRow>
      {!isReadOnly && (
        <TableCellBodyNoBorderBottom padding="checkbox">
          <Checkbox checked={selected} onChange={handleOnSellerCancelRequestToggle} />
        </TableCellBodyNoBorderBottom>
      )}

      <TableCellBodyNoBorderBottom minWidth={70}>
        <InternalLink to={`/order/${sellerCancelRequest.id}`}>
          {sellerCancelRequest.id}
        </InternalLink>
      </TableCellBodyNoBorderBottom>

      <TableCellEllipsisContent maxWidth={130} title={sellerCancelRequest.seller}>
        <InternalLink to={`/user/${sellerCancelRequest.sellerId}`}>
          {sellerCancelRequest.seller}
        </InternalLink>
      </TableCellEllipsisContent>

      <TableCellEllipsisContent maxWidth={140} title={sellerCancelRequest.product}>
        {sellerCancelRequest.product}
      </TableCellEllipsisContent>

      <TableCellEllipsisContent maxWidth={130} title={sellerCancelRequest.buyer}>
        <InternalLink to={`/user/${sellerCancelRequest.buyerId}`}>
          {sellerCancelRequest.buyer}
        </InternalLink>
      </TableCellEllipsisContent>

      <TableCellBodyNoBorderBottom minWidth={180}>
        {sellerCancelRequest.purchaseDate}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={185}>
        <TextWithConditionalPrefixIcon
          iconTitle={t('sellerCancelRequests.suspicious')}
          Icon={WarningIcon}
          sxIconProps={{ color: ({ palette }) => palette.warning.main }}
          isIconVisible={isSuspicious}
        >
          {sellerCancelRequest.cancelDate}
        </TextWithConditionalPrefixIcon>
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={100} align="right">
        <TextWithConditionalPrefixIcon
          iconTitle={t('promotionAmount', {
            amount: `${formatAmount(sellerCancelRequest.promotionAmount ?? 0)} ${currencySymbol(
              sellerCancelRequest.currency,
            )}`,
          })}
          Icon={CardGiftcardIcon}
          isIconVisible={isPurchasedWithPromotion}
        >
          {formatAmount(sellerCancelRequest.amount)} {currencySymbol(sellerCancelRequest.currency)}
        </TextWithConditionalPrefixIcon>
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={135} align="right">
        {formatAmount(sellerCancelRequest.shippingAmount)}{' '}
        {currencySymbol(sellerCancelRequest.currency)}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={145}>
        {t(`orderItemLabels.cancellationReasons.${sellerCancelRequest.cancellationReason}`)}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={120}>
        {sellerCancelRequest.shippingStatus}
      </TableCellBodyNoBorderBottom>

      <TableCellBodyNoBorderBottom minWidth={130}>
        {sellerCancelRequest.paymentStatus}
      </TableCellBodyNoBorderBottom>
    </TableRow>
  );
};
