import { ShippingAddress } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';
import { useOwnUser } from '@/context/own-user.context';

import { useCellShippingAddressDefinition } from '../../use-cell-definition';

const TYPESENSE_MAX_RESULT_PER_PAGE = 30;

export const ListShippingAddress = () => {
  const { hasPermissions } = useOwnUser();

  const cellShippingAddressDefinition = useCellShippingAddressDefinition();

  const ownUserCanSelectRows = hasPermissions(
    PERMISSIONS.OWN_USER_INTERFACE.READ_OWN_USER_INTERFACE_SELECT_MULTIPLE_LINES,
  );

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListWithSearch<ShippingAddress & { id: string; user: string }>
        title="Shipping Addresses"
        cellDefinition={cellShippingAddressDefinition}
        dataListProps={{
          getPathDetail: (shippingAddress) => `/shipping-address/${shippingAddress.id}`,
          enableRowSelection: ownUserCanSelectRows,
          enableFilters: true,
        }}
        typesenseHookParams={{
          typesenseIndex: 'shipping_address',
          typesenseParams: {
            per_page: TYPESENSE_MAX_RESULT_PER_PAGE,
            query_by: [
              'name',
              'user',
              'inputCity',
              'inputLine1',
              'inputCountry',
              'inputPostalCode',
            ],
          },
        }}
      />
    </div>
  );
};
