import {
  OrderCancellationReason,
  OrderItemsDistinctValuesFindOptions,
  OrderItemsFindOptions,
  OrderStatus,
} from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import { BaseHTTPGateway } from '@/domain/base-http-gateway';
import { OrderItem, orderItemDtoToEntity } from '@/entities/order-item.entity';
import logger from '@/logger/logger';

import { OrderItemsMassUpdatePort } from './order-items-mass-update.port';

export class HttpOrderItemsMassUpdateAdapter
  extends BaseHTTPGateway
  implements OrderItemsMassUpdatePort
{
  async getOrderItems(
    findOptions: OrderItemsFindOptions,
    options?: { abortPrevious: boolean },
  ): Promise<{ items: OrderItem[]; count: number }> {
    const signal = this.handleAbortPrevious(options?.abortPrevious ?? false, 'getOrderItems');

    try {
      const response = await authenticatedInstance.get('/order-item', {
        params: findOptions,
        signal,
      });

      return { items: response.data.items.map(orderItemDtoToEntity), count: response.data.count };
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }

  async updateOrderItemsShippingStatus(
    ids: number[],
    shippingStatus: OrderStatus,
    cancellationReason?: OrderCancellationReason,
  ): Promise<void> {
    try {
      await authenticatedInstance.post('/order-item/update-shipping-status', {
        ids,
        shippingStatus,
        cancellationReason,
      });
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }

  async updateOrderItemsTrackingNumber(
    ids: number[],
    trackingNumber: OrderItem['trackingNumber'],
  ): Promise<void> {
    try {
      await authenticatedInstance.post('/order-item/update-tracking-number', {
        ids,
        trackingNumber,
      });
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }

  async getFiltersValueFor(
    findOptions: OrderItemsDistinctValuesFindOptions,
    options?: { abortPrevious: boolean },
  ): Promise<{ values: string[]; count: number }> {
    const signal = this.handleAbortPrevious(options?.abortPrevious ?? false, 'getFiltersValueFor');

    try {
      const response = await authenticatedInstance.get('/order-item/filters', {
        params: findOptions,
        signal,
      });

      return { values: response.data.values, count: response.data.count };
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }
}
