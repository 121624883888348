import { CountryCodeIso2, Currency } from '@bits-app/voggtpit-shared';

import { authActions } from '@/auth/auth.slice';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { sellerCancelRequestsActions } from '../redux/seller-cancel-requests.slice';

export const getSellerCancelRequestsForCurrency =
  (currency: Currency, countries: CountryCodeIso2[] = []): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { sellerCancelRequestsGateway, snackbarGateway }) => {
    dispatch(sellerCancelRequestsActions.setLoading(true));
    dispatch(sellerCancelRequestsActions.setEntities([]));

    try {
      const sellerCancelRequests =
        await sellerCancelRequestsGateway.getSellerCancelRequestsForCurrency(currency, countries, {
          abortPrevious: true,
        });

      dispatch(sellerCancelRequestsActions.setEntities(sellerCancelRequests));
      dispatch(sellerCancelRequestsActions.setLoading(false));
    } catch (error) {
      logger.error(error);

      if (error instanceof CanceledRequestError) {
        return;
      }

      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));
        dispatch(sellerCancelRequestsActions.setLoading(false));

        return;
      }

      dispatch(sellerCancelRequestsActions.setError(String(error)));
      dispatch(sellerCancelRequestsActions.setLoading(false));

      snackbarGateway.error('somethingWrongHappened');
    }
  };
