import { useCallback } from 'react';

import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useNavigate } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { ButtonOutlined } from '@Elements/buttons/ButtonOutlined';

export const CreateAutoFollowUserCampaignButton = () => {
  const { hasPermissions } = useOwnUser();
  const isReadOnly = !hasPermissions(
    PERMISSIONS.AUTO_FOLLOW_USER_CAMPAIGN.WRITE_AUTO_FOLLOW_USER_CAMPAIGN,
  );
  const navigate = useNavigate();

  const redirectToCreateForm = useCallback(
    () => navigate('/auto-follow-user-campaign/create'),
    [navigate],
  );

  if (isReadOnly) {
    return null;
  }

  return <ButtonOutlined onClick={redirectToCreateForm}>Create campaign</ButtonOutlined>;
};
