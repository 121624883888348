import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Currency } from '../models/currency';

import { Order } from './Order';
import { Product } from './Product';
import { ShippingAddress } from './ShippingAddress';
import { Show } from './Show';
import { User } from './User';

export enum PreBidStatus {
  pending_pre_authorization = 'pending_pre_authorization',
  pre_authorization_in_progress = 'pre_authorization_in_progress',
  pre_authorization_failed = 'pre_authorization_failed',
  pre_authorization_succeeded = 'pre_authorization_succeeded',
  pre_authorization_aborted = 'pre_authorization_aborted',
  capture_succeeded = 'capture_succeeded',
  pre_authorization_cancelation_in_progress = 'pre_authorization_cancelation_in_progress',
  pre_authorization_canceled = 'pre_authorization_canceled',
}

export const VALID_PRE_BID_STATUSES_FOR_CANCELATION_EVENT = [
  PreBidStatus.pre_authorization_cancelation_in_progress,
  PreBidStatus.pre_authorization_succeeded,
  PreBidStatus.pre_authorization_aborted,
];

export const VALID_PRE_BID_STATUSES_FOR_CANCELATION = [
  PreBidStatus.pre_authorization_succeeded,
  PreBidStatus.pre_authorization_aborted,
];

export const VALID_PRE_BID_STATUSES_FOR_PRE_AUTH_FAILURE = [
  PreBidStatus.pre_authorization_in_progress,
  PreBidStatus.pre_authorization_aborted,
];

export const VALID_PRE_BID_STATUSES_FOR_PRE_AUTH_SUCCESS = [
  PreBidStatus.pre_authorization_in_progress,
];

export type UserIdProductIdTuple = [
  userId: PreBid['userId'],
  productId: PreBid['productId'],
];

@Entity()
@Index(['userId', 'productId'])
@Index(['userId', 'status'])
export class PreBid {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column({
    type: 'enum',
    enum: PreBidStatus,
    default: PreBidStatus.pending_pre_authorization,
  })
  status!: PreBidStatus;

  @ManyToOne(() => Product)
  @JoinColumn({ name: 'productId' })
  product!: Product;

  @Index()
  @Column()
  productId!: Product['id'];

  @ManyToOne(() => Show)
  @JoinColumn({ name: 'showId' })
  show!: Show;

  @Index()
  @Column()
  showId!: Show['id'];

  @ManyToOne(() => User)
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Index()
  @Column()
  userId!: number;

  @OneToOne(() => Order, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'orderId' })
  order!: Order | null;

  @Index()
  @Column({ nullable: true })
  orderId!: Order['id'] | null;

  @Column()
  amount!: number;

  @Column()
  estimatedShippingAmount!: number;

  @Column({ nullable: true, type: 'int4' })
  capturedAmount!: number | null;

  @Column({
    type: 'enum',
    enum: Currency,
    default: Currency.eur,
    enumName: 'currency_enum',
  })
  currency!: Currency;

  @ManyToOne(() => ShippingAddress)
  @JoinColumn({ name: 'shippingAddressId' })
  shippingAddress!: ShippingAddress;

  @Column()
  shippingAddressId!: number;

  @Index()
  @Column({ nullable: true, type: 'character varying' })
  stripePaymentIntentId!: string | null;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt!: Date | null;
}
