import {
  centsToUnit,
  CountryCodeIso2,
  Currency,
  OrderCancellationReason,
  OrderItem as OrderItemInDto,
  OrderStatus as ShippingStatus,
  PaymentStatus,
  RefundDebitedAccount,
  RefundReason,
} from '@bits-app/voggtpit-shared';

import { formatDate } from '../utils';

export type OrderItem = {
  orderId: string;
  id: number;
  product: string;
  showId: number;
  showName: string;
  showDate: string;
  buyerId: number;
  buyer: string;
  seller: string;
  sellerId: number;
  shippingStatus: ShippingStatus;
  paymentStatus: PaymentStatus;
  shippingProvider?: string;
  trackingNumber?: string;
  amount: number;
  shippingAmount: number;
  commission: number;
  purchaseDate: string;
  updateDate: string;
  refundAgent?: string;
  refundedAmount?: number;
  refundReason?: RefundReason;
  refundDebitedAccount?: RefundDebitedAccount;
  refundedDate?: string;
  currency: Currency;
  cancellationReason?: OrderCancellationReason;
  cancelDate?: string;
  promotionAmount?: number;
  country: CountryCodeIso2;
};

export const orderItemDtoToEntity = (dto: OrderItemInDto): OrderItem => {
  return {
    amount: centsToUnit(dto.amount),
    id: dto.id,
    paymentStatus: dto.paymentStatus,
    shippingStatus: dto.shippingStatus,
    refundedAmount: dto.refundedAmount !== undefined ? centsToUnit(dto.refundedAmount) : undefined,
    refundDebitedAccount: dto.refundDebitedAccount,
    refundAgent: dto.refundAgent,
    refundReason: dto.refundReason,
    commission: centsToUnit(dto.commission),
    shippingAmount: centsToUnit(dto.shippingAmount),
    promotionAmount: dto.promotionAmount ? centsToUnit(dto.promotionAmount) : undefined,
    buyer: dto.buyer.username,
    buyerId: dto.buyer.id,
    product: dto.product.name,
    seller: dto.seller.username,
    sellerId: dto.seller.id,
    purchaseDate: formatDate(dto.purchaseDate),
    updateDate: formatDate(dto.updateDate),
    refundedDate: dto.refundedAt ? formatDate(dto.refundedAt) : undefined,
    showId: dto.show.id,
    showName: dto.show.name,
    showDate: formatDate(dto.show.startAt),
    currency: dto.currency,
    cancellationReason: dto.cancellationReason,
    cancelDate: dto.cancellationRequestedAt ? formatDate(dto.cancellationRequestedAt) : undefined,
    trackingNumber: dto.trackingNumber,
    shippingProvider: dto.shippingProvider,
    orderId: `${dto.show.id}_${dto.buyer.id}`,
    country: dto.show.country,
  };
};
