import { Currency } from '../currency.enum';

export enum PromotionType {
  campaign = 'campaign',
  referral = 'referral',
  referrer = 'referrer',
  welcome = 'welcome',
}

export interface Promotion {
  id: number;
  name: string;
  currency: Currency;
  promotionAmount?: number;
  promotionMaxAmount?: number;
  promotionPercentage?: number;
  totalBudget: number;
  spentBudget: number;
  expireAt: Date;
  updatedAt: Date;
  type: PromotionType;
  countUsers: number;
  countUsed: number;
  countryId: string;
}

export interface PromotionDto {
  id: number;
  name: string;
  currency: Currency;
  promotionAmount?: number;
  promotionMaxAmount?: number;
  promotionPercentage?: number;
  totalBudget: number;
  spentBudget: number;
  expireAt: Date;
  updatedAt: Date;
  type: PromotionType;
  countUsers: number;
  countUsed: number;
  countryId: string;
}

export const promotionDtoToEntity = (dto: PromotionDto): Promotion => ({
  ...dto,
});
