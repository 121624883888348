import { AppThunkAction } from '@/redux/store';

import { selectAreAllRefundableSellerCancelRequestsInTheRefund } from '../redux/seller-cancel-requests.selectors';
import { sellerCancelRequestsActions } from '../redux/seller-cancel-requests.slice';

export const toggleAllRefundableSellerCancelRequests =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const areAllRefundableSellerCancelRequestsInTheRefund =
      selectAreAllRefundableSellerCancelRequestsInTheRefund(getState());

    if (areAllRefundableSellerCancelRequestsInTheRefund) {
      dispatch(sellerCancelRequestsActions.removeAllFromRefund());
      return;
    }

    dispatch(sellerCancelRequestsActions.addAllRefundableInRefund());
  };
