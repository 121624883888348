import { useState } from 'react';

import { SentinelFraudCase } from '@bits-app/voggtpit-shared';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '@/components/elements/buttons/ButtonContained';

type SentinelModalProps = {
  onConfirm: (selectedFraudCase: SentinelFraudCase['id']) => void;
  onCancel: () => void;
  isOpen: boolean;
  sentinelFraudCases: SentinelFraudCase[];
  isLoading: boolean;
};

export const SentinelModal = ({
  onConfirm,
  onCancel,
  isOpen,
  sentinelFraudCases,
  isLoading,
}: SentinelModalProps) => {
  const [selectedFraudCase, setSelectedFraudCase] = useState<SentinelFraudCase['id']>(-1);
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen}>
      <DialogTitle id="alert-dialog-title">
        {t('databaseExplorer.users.sentinel.title')}
      </DialogTitle>

      <DialogContent>
        <Stack direction={'column'} spacing={2}>
          <DialogContentText>{t('databaseExplorer.users.sentinel.description')}</DialogContentText>

          <Select
            value={selectedFraudCase}
            onChange={(e: SelectChangeEvent<SentinelFraudCase['id']>) =>
              setSelectedFraudCase(e.target.value as SentinelFraudCase['id'])
            }
          >
            {sentinelFraudCases.map((sentinelFraudCase) => (
              <MenuItem key={sentinelFraudCase.id} value={sentinelFraudCase.id}>
                {sentinelFraudCase.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={isLoading}>
          {t('action.cancel')}
        </Button>
        <ButtonContained
          onClick={() => onConfirm(selectedFraudCase)}
          disabled={isLoading || selectedFraudCase === -1}
        >
          {t('action.continue')}
        </ButtonContained>
      </DialogActions>
    </Dialog>
  );
};
