import { OrderCancellationReason } from '@bits-app/voggtpit-shared';

import { authenticatedInstance } from '@/axios/axios.instance';
import { BadRequestError } from '@/domain/errors/BadRequestError';
import logger from '@/logger/logger';

import { Order } from '../entities/order.entity';

import { NotAllOrderUpdatedError, OrderPort } from './order.port';

export class HttpOrderAdapter implements OrderPort {
  async updatePayoutStatusPurchasedFromShop(
    orderIds: Order['id'][],
    payoutStatus: Order['payoutStatus'],
  ): Promise<void> {
    try {
      await authenticatedInstance.patch('/database-explorer/order/update-payout-status-from-shop', {
        ids: orderIds,
        payoutStatus,
      });
    } catch (error) {
      logger.error(error);

      if (error instanceof BadRequestError) {
        const content = JSON.parse(error.message);

        if (Object.keys(content).length > 0) {
          throw new NotAllOrderUpdatedError(content);
        }
      }

      throw error;
    }
  }

  async cancelOrder(
    orderId: Order['id'],
    cancellationReason: OrderCancellationReason,
  ): Promise<Order> {
    try {
      const response = await authenticatedInstance.patch(
        `/database-explorer/order/${orderId}/cancel-order`,
        {
          cancellationReason,
        },
      );

      return response.data;
    } catch (error) {
      logger.error(error);

      if (error instanceof BadRequestError) {
        const content = JSON.parse(error.message);

        if (Object.keys(content).length > 0) {
          throw new NotAllOrderUpdatedError(content);
        }
      }

      throw error;
    }
  }
}
