import { Show } from '@bits-app/bits-server-data';

export const ShowFeedRecommandationPreview = ({ show, index }: { show: Show; index: number }) => {
  return (
    <a href={'/show/' + show.id} target="_blank" className="bg-white p-4 rounded-lg relative">
      <div className="absolute top-0 right-0 left-0 flex">
        <div className="h-10 w-10 bg-green-500 rounded-full flex items-center justify-center">
          <span className="text-white text-base font-semibold">{index}</span>
        </div>
      </div>

      <div className="rounded-lg flex flex-col gap-1" key={show.id}>
        <img src={show.thumbnailUrl} alt={show.name} className={'w-full h-auto aspect-[2/3]'} />
        <p className="text-base">
          {show.id} - {show.name}
        </p>
        <p className="font-medium text-xs">@{show.user.username}</p>
        <p className="text-xs">{show.category.name}</p>
      </div>
    </a>
  );
};
