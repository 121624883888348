import { useState } from 'react';

import { CommentType, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { Send } from 'lucide-react';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useOwnUser } from '@/context/own-user.context';
import { QUERIES_KEYS, queryClient } from '@/queries';

import { CommentsProps } from './comments.type';

type CommentPayload = Pick<CommentType, 'comment' | 'entityName' | 'entityId' | 'createdBy'>;

export const CommentsFromActivityLogs = ({ entityName, entityId }: CommentsProps) => {
  const [comment, setComment] = useState<string>('');

  const { hasPermissions } = useOwnUser();
  const { ownUser } = useOwnUser();

  const { mutate } = useMutation({
    mutationFn: (comment: CommentPayload) => authenticatedInstance.post('comment', comment),
    onSuccess: () => {
      setComment('');
      queryClient.invalidateQueries(QUERIES_KEYS.activityLogs(entityName, entityId));
    },
  });

  const sendComment = async () => {
    if (!entityName || !entityId || !ownUser?.id) return;
    if (!comment) return;

    const formattedComment: CommentPayload = Object.assign({
      entityName,
      entityId,
      createdBy: ownUser?.id,
      comment: comment,
    });

    mutate(formattedComment);
  };

  const isReadOnly = !hasPermissions(PERMISSIONS.COMMENT.WRITE_COMMENT);

  if (isReadOnly) {
    return null;
  }

  return (
    <div className="relative flex items-center">
      <input
        className="px-4 py-2.5 w-full border-t border-slate-200 focus:outline-2 focus:outline-sky-300 rounded-b-lg"
        onKeyDown={({ key }) => key === 'Enter' && sendComment()}
        onChange={(event) => setComment(event.target.value)}
        placeholder="Lache des coms <3"
        value={comment}
      />

      <Send
        className="cursor-pointer absolute right-4 text-blue-300 hover:text-blue-500"
        onClick={sendComment}
        size={18}
      />
    </div>
  );
};
