import { useState } from 'react';

import { Close as CloseIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@Elements/buttons/IconButton';

type NotAllProcessedErrorDialogProps = {
  errorText?: string;
  error: {
    message: string;
    processing?: number[];
    notProcessed: { [id: string | number]: string };
  } | null;
  onClose: () => void;
};

export const NotAllProcessedErrorDialog = ({
  errorText,
  error,
  onClose,
}: NotAllProcessedErrorDialogProps) => {
  const [expanded, setExpanded] = useState(true);
  const { t } = useTranslation();

  if (!error) {
    onClose();
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        {t('error')}

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {error?.message && (
          <Alert severity="error" sx={{ display: 'flex', alignItems: 'center' }}>
            {error?.message}
          </Alert>
        )}

        <List>
          {error?.processing?.map((id) => (
            <ListItem key={id} sx={{ fontSize: '0.875rem' }}>
              {id}
            </ListItem>
          ))}
        </List>

        {error?.notProcessed && Object.keys(error?.notProcessed).length > 0 && (
          <>
            <Divider />
            <Accordion
              elevation={0}
              expanded={expanded}
              onChange={() => setExpanded((exp) => !exp)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>{errorText}</AccordionSummary>
              <AccordionDetails>
                <List>
                  {Object.entries(error.notProcessed).map(([id, message]) => (
                    <ListItem key={id}>
                      <Typography
                        fontWeight="bold"
                        component="span"
                        sx={{ display: 'inline', mr: 2 }}
                      >
                        {id}
                      </Typography>
                      {message}
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
