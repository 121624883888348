import Ico from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const CreateButtonRequest = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      variant="text"
      endIcon={<Ico fontSize="small" />}
      sx={{ fontWeight: 'bold' }}
    >
      {t('databaseExplorer.shippingAddress.create.action')}
    </Button>
  );
};
