import { OrderSearchOptions, PERMISSIONS } from '@bits-app/voggtpit-shared';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '@/axios/axios.instance';
import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { CellDefinition, DataListType } from '@/components/elements/DataList/datalist-type';
import { useOwnUser } from '@/context/own-user.context';
import { QUERIES_KEYS } from '@/queries';
import { EditSelectedOrdersStatus } from '@/voggt-database-explorer/components/EditSelectedOrdersStatus';

type UserOrdersProps<T extends { id: string | number }> = {
  filters: OrderSearchOptions['filters'];
  cellDefinition: CellDefinition<T>[];
  defaultHiddenColumns?: DataListType<T>['defaultHiddenColumns'];
  enableRowSelection?: DataListType<T>['enableRowSelection'];
  renderRowActions?: DataListType<T>['renderRowActions'];
};

export const OrdersWithExport = <T extends { id: string }>({
  renderRowActions,
  filters,
  cellDefinition,
  defaultHiddenColumns,
}: UserOrdersProps<T>) => {
  const { t } = useTranslation();

  const { hasPermissions } = useOwnUser();

  const ownUserCanSelectRows = hasPermissions(
    PERMISSIONS.OWN_USER_INTERFACE.READ_OWN_USER_INTERFACE_SELECT_MULTIPLE_LINES,
  );

  const { data, refetch } = useQuery<T[]>(getQueryKeys(filters), () => getQueryFunction(filters));

  const refetchData = () => refetch();

  return (
    <ListCustom
      cellDefinition={cellDefinition}
      defaultHiddenColumns={defaultHiddenColumns}
      enableRowSelection={ownUserCanSelectRows}
      customSelectedRowsAction={(rows) => (
        <EditSelectedOrdersStatus
          orderIds={rows.map(({ original: { id } }) => Number(id))}
          afterUpdate={refetch}
        />
      )}
      enableFilters
      results={data ?? []}
      renderRowActions={renderRowActions}
      allowMRTExport
      enableSorting
    >
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <IconButton title={t('action.refresh')} onClick={refetchData}>
          <RefreshIcon />
        </IconButton>
      </Box>
    </ListCustom>
  );
};

const getQueryFunction = async (filters: OrderSearchOptions['filters']) => {
  const response = await authenticatedInstance.get('/database-explorer/order', {
    params: { filters },
  });

  return response.data;
};

const getQueryKeys = (filters: OrderSearchOptions['filters']) => {
  if (filters?.sellerId) {
    return QUERIES_KEYS.userSellerOrders(filters.sellerId);
  }
  if (filters?.customerId) {
    return QUERIES_KEYS.userBuyerOrders(filters.customerId);
  }
  return QUERIES_KEYS.showOrders();
};
