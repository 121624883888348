import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Show } from './Show';

@Entity()
export class ShowNotification {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @OneToOne(() => Show, (show) => show.notification)
  @JoinColumn({ name: 'showId' })
  show!: Show;

  @Index()
  @Column()
  showId!: number;

  @Column()
  androidCampaignToken!: string;

  @Column()
  iosCampaignToken!: string;

  @Column({ nullable: true, type: 'timestamp' })
  sentAt!: Date | null;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  // These fields are relevant to use this entity as a model in GraphQL types
  isApproved?: boolean;

  message?: string;

  title?: string;
}
