import { SentinelFraudCase } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { QUERIES_KEYS } from '../../../queries';

export const useSentinelList = () => {
  return useQuery(QUERIES_KEYS.sentinelList(), querySentinelList);
};

async function querySentinelList() {
  return authenticatedInstance.get<(SentinelFraudCase & { id: string })[]>('/sentinel');
}
