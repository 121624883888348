import { Skeleton, TableRow } from '@mui/material';

import { TableCellBodyNoBorderBottom } from '@Elements/StyledTableCell';

type LoadingRowProps = {
  cellCount: number;
  emptyFirstCell?: boolean;
};

export const LoadingRow = ({ cellCount, emptyFirstCell }: LoadingRowProps) => (
  <TableRow>
    {[...Array(cellCount).keys()].map((cell, index) => (
      <TableCellBodyNoBorderBottom key={cell}>
        {emptyFirstCell && index === 0 ? null : <Skeleton />}
      </TableCellBodyNoBorderBottom>
    ))}
  </TableRow>
);
