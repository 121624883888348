import { useEffect, useState } from 'react';

import { UserTargetPromotion, Promotion } from '@bits-app/bits-server-data';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Drawer,
  Typography,
  Autocomplete,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
  Checkbox,
  TextField,
  DialogActions,
  useTheme,
  Stack,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';
import { useTypesenseSearch } from '@/hooks/use-typesense';
import { useSearchVoggtPayloadType } from '@/hooks/use-typesense/use-typesense';
import { ManageUserInTargetPromotionDefinition } from '@/promotions/hooks/use-manager-users/use-manager-users.type';
import { promotionsActions } from '@/promotions/redux/promotions.slice';
import { User } from '@/voggt-database-explorer/users/users';

type ManageUserProps = {
  promotionId?: Promotion['id'];
  promotionName?: Promotion['name'];
  userTargetPromotion: UserTargetPromotion[];
  addUserToPromotion: (users: User[]) => void;
  close: () => void;
  handleSubmitAddUserToPromotion: () => void;
  newUsers: User[];
  handleAddUsers: () => void;
};

export const ManagerUser = ({
  promotionId,
  promotionName = '',
  userTargetPromotion,
  addUserToPromotion,
  close,
  handleSubmitAddUserToPromotion,
  newUsers,
  handleAddUsers,
}: ManageUserProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!promotionId) return;
    dispatch(promotionsActions.setEditingPromotionId(promotionId));
    return () => {
      dispatch(promotionsActions.setEditingPromotionId(null));
    };
  }, [dispatch, promotionId]);

  const [autocompleteIsOpen, setAutocompleteIsOpen] = useState<boolean>(false);
  const toggleAutocomplete = () => setAutocompleteIsOpen(!autocompleteIsOpen);
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Drawer anchor={'right'} open={Boolean(promotionId)} onClose={close}>
      <Box sx={{ maxWidth: fullScreen ? '100vw' : '50vw' }} p={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" mt={2}>
            {promotionName}
          </Typography>
          <IconButton onClick={close}>
            <CloseIcon color="inherit" />
          </IconButton>
        </Box>
        <Stack justifyContent={'flex-end'} display={'flex'} spacing={2} direction={'row'}>
          <ButtonOutlined onClick={toggleAutocomplete}>
            {t('promotion.add-users', { promotionName })}
          </ButtonOutlined>
          <ButtonOutlined onClick={handleAddUsers}>{t('promotion.uploadUsers')}</ButtonOutlined>
        </Stack>
        <AddUser
          toggleAutocomplete={toggleAutocomplete}
          autoCompleteIsOpen={autocompleteIsOpen}
          handleSubmit={handleSubmitAddUserToPromotion}
          promotionName={promotionName}
          setSelectedUser={addUserToPromotion}
          newUsers={newUsers}
        />
        <ListCustom<UserTargetPromotion>
          results={userTargetPromotion}
          cellDefinition={ManageUserInTargetPromotionDefinition}
          enableGlobalFilter={false}
          enableHiding={false}
        />
      </Box>
    </Drawer>
  );
};

export const AddUser = ({
  toggleAutocomplete,
  autoCompleteIsOpen,
  handleSubmit,
  promotionName,
  setSelectedUser,
  newUsers,
}: {
  toggleAutocomplete: () => void;
  autoCompleteIsOpen: boolean;
  handleSubmit: () => void;
  promotionName: string;
  setSelectedUser: (user: User[]) => void;
  newUsers: User[];
}) => {
  const { t } = useTranslation();

  const params: useSearchVoggtPayloadType<User> = {
    typesenseIndex: 'user',
    typesenseParams: {
      per_page: 20,
      query_by: ['username'],
      include_fields: 'username,id',
    },
  };

  const { results, setSearch, ownUserSearch } = useTypesenseSearch<User>(params);
  const resultsWithSelected = uniqBy(results.concat(newUsers), 'id');

  return (
    <Dialog open={autoCompleteIsOpen} onClose={toggleAutocomplete}>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        color="common.black"
        alignItems={'center'}
      >
        {t('promotion.add-users', { promotionName })}
        <IconButton size="small" color="inherit" onClick={toggleAutocomplete}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          value={newUsers}
          sx={{
            m: 1,
          }}
          options={resultsWithSelected}
          disableCloseOnSelect
          onChange={(_, result) => {
            setSelectedUser(result);
          }}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          getOptionLabel={(option) => `${option.username || option.id}`}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.username} (id : {option.id})
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('databaseExplorer.users.promotion.select')}
              onChange={(ev) => setSearch(ev.target.value)}
              value={ownUserSearch}
            />
          )}
        />
      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: ({ spacing }) => spacing(3),
          paddingLeft: ({ spacing }) => spacing(3),
          paddingBottom: ({ spacing }) => spacing(2),
        }}
      >
        <ButtonOutlined onClick={toggleAutocomplete}>{t('cancel')}</ButtonOutlined>
        <Button
          variant="contained"
          onClick={() => {
            handleSubmit();
            toggleAutocomplete();
          }}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
