import { StreamingAsset } from '@bits-app/voggtpit-shared';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

import { LoadAssetsParams } from './mux.type';

export const CellAssetMuxDefinition: CellDefinition<StreamingAsset>[] = [
  { id: 'id', label: 'Id', type: 'string' },

  {
    id: 'showId',
    label: 'Show Id',
    type: 'internal-link',
    getLink: (asset) => ({ label: asset.showId, link: `/show/${asset.showId}` }),
  },
  {
    id: 'sellerId',
    label: 'Seller Id',
    type: 'internal-link',
    getLink: (asset) => ({ label: asset.sellerId, link: `/user/${asset.sellerId}` }),
  },
  {
    id: 'downloadLink',
    label: 'Download',
    type: 'external-link',
    getLink: (ShowMuxAsset) => ({
      label:
        ShowMuxAsset.type !== 'ivs_rtmp' &&
        ShowMuxAsset.type !== 'ivs_web_rtc' &&
        ShowMuxAsset?.downloadLink
          ? 'Download'
          : '',
      link: ShowMuxAsset?.downloadLink || '',
    }),
  },
  {
    id: 'streamedAt',
    label: 'Streamed at',
    type: 'date-with-time',
  },
  {
    id: 'type',
    label: 'Streaming type',
    type: 'badge',
  },
];

export const getPath = (params: LoadAssetsParams) => {
  switch (params.type) {
    case 'show':
      return `/database-explorer/show/${params.entityId}/replay-assets`;
    case 'seller':
      return `/bits-data/streaming-asset/seller/${params.entityId}`;
  }
};
