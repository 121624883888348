import { Shipment, Order } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../axios/axios.instance';
import { QUERIES_KEYS } from '../../queries';

async function getShipment(orderId: Order['id']): Promise<Shipment> {
  const { data } = await authenticatedInstance.get(
    `/database-explorer/shipment/shipment-from-order/${orderId}`,
  );
  return data;
}

export const useShipmentDetailFromOrderId = (orderId: Order['id']) => {
  return useQuery<Shipment>(
    QUERIES_KEYS.getShipmentDetailFromOrderId(orderId),
    () => getShipment(orderId),
    {
      enabled: Boolean(orderId),
    },
  );
};
