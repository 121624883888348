import { useState, useEffect } from 'react';

import { OrderCancellationReason, OrderStatus } from '@bits-app/voggtpit-shared';

import { useUnsavedWarning } from '@/context/unsaved.context';
import { OrderItem } from '@/entities/order-item.entity';
import { useTableOptions } from '@/hooks/use-table-options';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import {
  selectOrderItemsMassUpdate,
  selectOrderItemsMassUpdateCount,
  selectOrderItemsMassUpdateLoading,
} from '../../redux/order-items-mass-update.selectors';
import {
  getOrderItemsFromOptions,
  massUpdateOrderItemsShippingStatus,
  massUpdateOrderItemsTrackingNumber,
} from '../../use-cases';
import { useHeader } from '../table/hooks/use-header';

export const useOrderItemsMassUpdate = () => {
  const dispatch = useAppDispatch();

  const orderItems = useAppSelector(selectOrderItemsMassUpdate);
  const orderItemsCount = useAppSelector(selectOrderItemsMassUpdateCount);
  const loading = useAppSelector(selectOrderItemsMassUpdateLoading);
  const { options, onChangePage, addFilter, clearAllFilters, onOrderBy } = useTableOptions();

  const [selected, setSelected] = useState<number[]>([]);
  const [openUpdateDialog, setOpenUpdateDialog] = useState<
    'shipping-status' | 'tracking-number' | null
  >(null);

  const { stickyHeadCells, headCells } = useHeader({
    addFilter,
    filters: options.filters,
    isUnsaved: selected.length > 0,
  });

  const onConfirmAfterWarning = useUnsavedWarning();

  useEffect(() => {
    dispatch(getOrderItemsFromOptions(options));
    setSelected([]);
  }, [dispatch, options]);

  const handleToggleItem = (id: number) => {
    if (Object.keys(options.filters).length === 0) {
      return;
    }

    if (selected.includes(id)) {
      setSelected(selected.filter((orderItemId) => orderItemId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleToggleAll = () => {
    if (Object.keys(options.filters).length === 0) {
      return;
    }

    if (selected.length === orderItems.length) {
      setSelected([]);

      return;
    }

    setSelected(orderItems.map(({ id }) => id));
  };

  const handleUpdateOrderItemShippingStatus = async (
    shippingStatus: OrderStatus,
    cancellationReason?: OrderCancellationReason,
  ) => {
    if (!selected) {
      return;
    }

    await dispatch(
      massUpdateOrderItemsShippingStatus({ ids: selected, shippingStatus, cancellationReason }),
    );

    setSelected([]);
    setOpenUpdateDialog(null);
  };

  const handleUpdateOrderItemTrackingNumber = async (
    trackingNumber: OrderItem['trackingNumber'],
  ) => {
    if (!selected) {
      return;
    }

    await dispatch(
      massUpdateOrderItemsTrackingNumber({
        ids: selected,
        trackingNumber,
      }),
    );

    setSelected([]);
    setOpenUpdateDialog(null);
  };

  const handleOnChangePage = (page: number) => {
    if (selected.length > 0) {
      onConfirmAfterWarning(() => handleOnConfirmChangePage(page));
      return;
    }

    onChangePage(page);
  };

  const handleOnConfirmChangePage = (page: number) => {
    onChangePage(page);
    setSelected([]);
  };

  const handleClearAllFilters = () => {
    if (selected.length > 0) {
      onConfirmAfterWarning(() => clearAllFilters());
      return;
    }

    clearAllFilters();
  };

  return {
    addFilter,
    options,
    orderItems,
    selected,
    loading,
    orderItemsCount,
    openUpdateDialog,
    setOpenUpdateDialog,
    onOrderBy,
    stickyHeadCells,
    headCells,
    onClearAllFilters: handleClearAllFilters,
    onChangePage: handleOnChangePage,
    onUpdateOrderItemShippingStatus: handleUpdateOrderItemShippingStatus,
    onUpdateOrderItemTrackingNumber: handleUpdateOrderItemTrackingNumber,
    onToggleAll: handleToggleAll,
    onToggleItem: handleToggleItem,
  };
};
