import { ChangeEvent, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonContained } from '@Elements/buttons/ButtonContained';

import { User } from '../../../users';

export type FieldValue = {
  verifiedPhone: User['phoneNumber'];
  verifiedEmail: User['email'];
};

type ToggleBanUserProps = {
  user: User;
  onConfirm: (data: FieldValue) => void;
  onCancel: () => void;
  isOpen: boolean;
};

export const RemoveUserSsoModal = ({ user, onConfirm, onCancel, isOpen }: ToggleBanUserProps) => {
  const { t } = useTranslation();

  const { register, formState, handleSubmit, reset, setValue } = useForm<FieldValue>({
    defaultValues: {
      verifiedPhone: '',
      verifiedEmail: '',
    },
  });

  const { errors } = formState;

  useEffect(() => {
    reset({
      verifiedPhone: '',
      verifiedEmail: '',
    });
  }, [user, reset]);

  const handleCancel = () => {
    reset();
    onCancel();
  };

  const handleTextFieldChange =
    (name: 'verifiedPhone' | 'verifiedEmail') => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setValue(name, value.toLowerCase().replaceAll(' ', '-'));
    };

  const phoneNumberPattern = /^[0-9+-]*$/;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return (
    <Dialog open={isOpen}>
      <Grid container flexDirection="column" p={3} gap={1}>
        <Grid item>
          <DialogTitle id="alert-dialog-title">
            {t(`databaseExplorer.users.unlinkSso.action`)}
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              {t(`databaseExplorer.users.unlinkSso.description`, { username: user.username })}
            </DialogContentText>
          </DialogContent>
        </Grid>

        {!user.isPhoneNumberVerified && (
          <Grid item>
            <TextField
              margin="dense"
              fullWidth
              {...register('verifiedPhone', {
                required: t('databaseExplorer.category.upsertCategory.form.errors.required', {
                  field: t('databaseExplorer.users.unlinkSso.form.verifiedPhoneNumber'),
                }),
                pattern: {
                  value: phoneNumberPattern,
                  message: t('databaseExplorer.users.unlinkSso.form.invalidPhoneNumber'),
                },
                minLength: 9,
                onChange: handleTextFieldChange,
              })}
              label={t('databaseExplorer.users.unlinkSso.form.verifiedPhoneNumber')}
              error={!!errors.verifiedPhone}
              helperText={errors.verifiedPhone?.message}
            />
          </Grid>
        )}

        {!user.isEmailVerified && (
          <Grid item>
            <TextField
              margin="dense"
              fullWidth
              {...register('verifiedEmail', {
                required: t('databaseExplorer.category.upsertCategory.form.errors.required', {
                  field: t('databaseExplorer.users.unlinkSso.form.verifiedEmail'),
                }),
                pattern: {
                  value: emailPattern,
                  message: t('databaseExplorer.users.unlinkSso.form.invalidEmail'),
                },
                minLength: 7,
                onChange: handleTextFieldChange,
              })}
              label={t('databaseExplorer.users.unlinkSso.form.verifiedEmail')}
              error={!!errors.verifiedEmail}
              helperText={errors.verifiedEmail?.message}
            />
          </Grid>
        )}

        <DialogActions>
          <Button onClick={handleCancel}>{t('cancel')}</Button>
          <ButtonContained onClick={handleSubmit(onConfirm)}>
            {t(`databaseExplorer.users.unlinkSso.confirm`)}
          </ButtonContained>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};
