import { useCallback, useEffect, useState } from 'react';

import { PartnerProgramType } from '@bits-app/voggtpit-shared';

import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';

import {
  selectPartnerPrograms,
  selectPartnerProgramsLoading,
} from '../redux/partner-program.selector';
import { partnerProgramActions } from '../redux/partner-program.slice';
import { listPartnerProgram } from '../redux/use-cases/list-partner-program';

export const usePartnerProgramList = () => {
  const [search, setSearch] = useState<string>('');

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectPartnerProgramsLoading);
  const partnerPrograms = useAppSelector(selectPartnerPrograms);
  const partnerProgramsCount = partnerPrograms.length;

  const selectPartnerProgram = useCallback(
    (data: PartnerProgramType | { id: -1 }) =>
      dispatch(partnerProgramActions.selectForEdition(data.id)),
    [dispatch],
  );

  useEffect(() => {
    dispatch(listPartnerProgram());
  }, [dispatch]);

  return {
    isLoading,
    partnerPrograms: withSearch(partnerPrograms, search),
    partnerProgramsCount,
    selectPartnerProgram,

    search,
    setSearch,
  };
};

function withSearch(partnerPrograms: PartnerProgramType[], search: string) {
  if (search === '') return partnerPrograms;
  return partnerPrograms.filter((partnerProgram) => partnerProgram.name.includes(search));
}
