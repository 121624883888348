import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import englishTranslation from './en.json';

// eslint-disable-next-line import/no-named-as-default-member
i18next.use(initReactI18next).init({
  resources: {
    en: { translation: englishTranslation },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
