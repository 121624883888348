import {
  Channel,
  SellerConfig,
  User,
  SellerCompany,
  SellerShippingAddress,
} from '@bits-app/bits-server-data';
import { SellerFees } from '@bits-app/voggtpit-shared';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, Link as RouterLink } from 'react-router-dom';

import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { SoftDeletedBanner } from '@/components/elements/banner/SoftDeletedBanner';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParamAsNumber } from '@/hooks/use-id-params';
import { MUX_BASE_URL } from '@/utils';
import {
  panelDetailsChannel,
  panelsDetailsSellerShippingAddress,
} from '@/voggt-database-explorer/channel/views/detail/useChannelDetailsView';
import { EditButton } from '@/voggt-database-explorer/components/EditButton';
import { EditionView } from '@/voggt-database-explorer/components/edition-view/EditionView';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';

import { useSellerCompanyByUserId } from '../../../hooks/useSellerCompanyByUserId';
import { useSellerConfigByUserId } from '../../../hooks/useSellerConfigByUserId';
import {
  panelDetailsSellerCompany,
  panelDetailsSellerConfig,
  panelDetailsSellerConfigFeaturesFlag,
} from '../const/seller-panel';

import { SelleCompanyCreationView } from './CreateSellerCompany/SellerCompanyCreationView';

type SellerPanelDetails = {
  user: User;
  channel?: Channel;
};

export const SellerPanel = ({ user, channel }: SellerPanelDetails) => {
  const userId = useIdParamAsNumber();
  const navigate = useNavigate();
  const sellerConfigByUserIdHook = useSellerConfigByUserId(userId);
  const sellerCompanyByUserIdHook = useSellerCompanyByUserId(userId);

  const sellerConfig = sellerConfigByUserIdHook.data?.data;

  const { t } = useTranslation();

  if (!sellerConfig) {
    return null;
  }

  return (
    <Routes>
      <Route
        index
        element={
          <DetailsLayout>
            {sellerConfig?.deletedAt && <SoftDeletedBanner />}
            <Grid container spacing={1}>
              <Grid xs={12} justifyContent="flex-end" display={'flex'}>
                <Stack spacing={4} direction="row" ml={'auto'} pr={1} pl={1} alignItems="center">
                  <EditButton to={`/user/${userId}/seller-config/edit`} />
                  {!sellerCompanyByUserIdHook.data?.data && !sellerCompanyByUserIdHook.isLoading ? (
                    <Button
                      component={RouterLink}
                      variant="text"
                      to={`/user/${userId}/seller-config/create-seller-company`}
                    >
                      Create seller company
                    </Button>
                  ) : null}
                  <Button
                    component={'a'}
                    variant="text"
                    target={'_blank'}
                    href={`${MUX_BASE_URL}/video/live-streams/${channel?.providerId}/health`}
                  >
                    {t('link.mux-inspector')}
                  </Button>
                  <ForestAdminLink entity="sellerConfig" entityId={sellerConfig.id} />
                </Stack>
              </Grid>

              <Grid xs={12} md={6}>
                <Grid xs={12}>
                  <PanelDetails<SellerConfig & SellerFees>
                    loading={sellerConfigByUserIdHook.isLoading}
                    title={t('databaseExplorer.panel.sellerConfig')}
                    informations={panelDetailsSellerConfig}
                    data={{
                      ...sellerConfig,
                      fixedFee: user.fixedFee,
                      percentageFee: user.percentageFee,
                    }}
                    labelRow
                  />
                </Grid>
                <Grid xs={12}>
                  <PanelDetails<SellerConfig>
                    loading={sellerConfigByUserIdHook.isLoading}
                    title={t('databaseExplorer.panel.sellerConfigFeaturesFlag')}
                    informations={panelDetailsSellerConfigFeaturesFlag}
                    data={sellerConfig}
                    labelRow
                  />
                </Grid>
              </Grid>
              <Grid xs={12} md={6}>
                {channel ? (
                  <Grid xs={12}>
                    <PanelDetails<Channel>
                      title={t('databaseExplorer.panel.channel')}
                      informations={panelDetailsChannel}
                      data={channel}
                      loading={sellerConfigByUserIdHook.isLoading}
                    />
                  </Grid>
                ) : null}
                {sellerConfigByUserIdHook.data?.data.sellerShippingAddress ? (
                  <Grid xs={12}>
                    <PanelDetails<SellerShippingAddress>
                      title={'Seller shipping address'}
                      informations={panelsDetailsSellerShippingAddress}
                      data={sellerConfigByUserIdHook.data?.data.sellerShippingAddress}
                      loading={sellerCompanyByUserIdHook.isLoading}
                    />
                  </Grid>
                ) : null}
                {sellerCompanyByUserIdHook.data?.data ? (
                  <Grid xs={12}>
                    <PanelDetails<SellerCompany>
                      title={t('databaseExplorer.panel.sellerCompany')}
                      informations={panelDetailsSellerCompany}
                      data={sellerCompanyByUserIdHook.data.data}
                      loading={sellerCompanyByUserIdHook.isLoading}
                      seeDetails={() =>
                        navigate(`/user/${userId}/seller-config/edit-seller-company`)
                      }
                      actionName={'Edit'}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </DetailsLayout>
        }
      />

      <Route
        path="edit"
        element={
          <EditionView<SellerConfig>
            goBackName={t('databaseExplorer.editionFormPath.sellerInformation')}
            resourceId={sellerConfig.id}
            resourceType="Seller-Config"
            onSuccess={sellerConfigByUserIdHook.refetch}
          />
        }
      />
      <Route
        path="edit-seller-company"
        element={
          <>
            {sellerCompanyByUserIdHook.data?.data ? (
              <EditionView<SellerCompany>
                goBackName={t('databaseExplorer.editionFormPath.sellerInformation')}
                resourceId={sellerCompanyByUserIdHook.data.data.id}
                resourceType="Seller-Company"
                onSuccess={() => {
                  sellerCompanyByUserIdHook.refetch();
                }}
              />
            ) : null}
          </>
        }
      />
      <Route path="create-seller-company" element={<SelleCompanyCreationView userId={userId} />} />
    </Routes>
  );
};
