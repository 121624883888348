/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';

import { Show } from '@bits-app/bits-server-data';
import type { ShowPlayerMarker } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as datefns from 'date-fns';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import { authenticatedInstance } from '../../../../axios/axios.instance';
import { ListCustom } from '../../../../components/elements/DataExplorer/ListCustom';
import { Loader } from '../../../../components/elements/Loader';
import { QUERIES_KEYS } from '../../../../queries';

export const ShowPlayer = ({ showId }: { showId: Show['id'] }) => {
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>(null);
  const [initialTime, setInitialTime] = useState<number | null>(null);
  const [player, setPlayer] = useState<ReturnType<typeof videojs> | null>(null);

  const { data, isLoading } = useQuery<AxiosResponse<ShowPlayerMarker[]>>({
    queryFn: async () =>
      authenticatedInstance.get(`/database-explorer/show/${showId}/player-informations`),
    queryKey: QUERIES_KEYS.showPlayerInformations(showId),
  });

  if (isLoading) {
    return <Loader />;
  }

  const handleClickOnRow = (row: ShowPlayerMarker) => {
    const currentVideoIsDifferent = currentVideoUrl !== row.url;
    if (currentVideoIsDifferent && row.url) {
      setCurrentVideoUrl(row.url);
      setInitialTime(row.timeInSecondsFromStartOfAsset);
      return;
    }

    if (player) {
      player.currentTime(row.timeInSecondsFromStartOfAsset);
      player.play();
    }
  };

  return (
    <div className="w-full h-full min-h-[60vh] max-h-[60vh] flex flex-col md:flex-row justify-between gap-6 ">
      <section className="w-full md:w-1/2 h-full ">
        <ListCustom<ShowPlayerMarker>
          cellDefinition={[
            { id: 'id', label: 'ID' },
            {
              id: 'timeInSecondsFromStartOfShow',
              label: 'Time',
              getValue: (record) => {
                return customFormatDuration({
                  start: 0,
                  end: record.timeInSecondsFromStartOfShow * 1000,
                });
              },
            },
            {
              id: 'product',
              label: 'Product name',
              getValue: (record) => record.product.name,
              type: 'internal-link',
              getLink: (record) => ({
                label: record.product.name,
                link: `/product/${record.buyer.id}`,
              }),
              accessorFn: (record) => record.product.name,
            },
            {
              id: 'buyer',
              label: 'Buyer name',
              getValue: (record) => record.buyer.name,
              type: 'internal-link',
              getLink: (record) => ({
                label: record.buyer.name,
                link: `/user/${record.buyer.id}`,
              }),
              accessorFn: (record) => record.buyer.name,
            },
            {
              id: 'order',
              label: 'Order amount',
              getValue: (record) => (record.order.amount / 100).toFixed(2),
              type: 'money',
              accessorFn: (record) => String(record.order.amount),
            },
          ]}
          onClickOnRow={handleClickOnRow}
          enableFilters
          results={data?.data || []}
          enableSorting
        />
      </section>

      <section className="flex justify-center w-1/2">
        <Player
          url={currentVideoUrl}
          key={currentVideoUrl}
          player={player}
          setPlayer={setPlayer}
          initialTime={initialTime}
        />
      </section>
    </div>
  );
};

const Player = ({
  url,
  player,
  setPlayer,
  initialTime,
}: {
  url: string | null;
  player: ReturnType<typeof videojs> | null;
  setPlayer: (player: ReturnType<typeof videojs> | null) => void;
  initialTime: number | null;
}) => {
  const videoNode = useRef(null);

  useEffect(() => {
    if (!url || !videoNode || !videoNode.current) {
      return;
    }

    const _player = videojs(videoNode.current, getPlayerOptions({ url }));
    setPlayer(_player);
    _player.play();
    if (initialTime) {
      _player.currentTime(initialTime);
    }
    return () => {
      if (player) {
        setPlayer(null);
      }
    };
  }, []);

  return (
    <div data-vjs-player className="w-full h-full">
      <video ref={videoNode} className="video-js"></video>
    </div>
  );
};

export const getPlayerOptions = ({ url }: { url: string }) => ({
  autoplay: false,
  controls: true,
  preload: 'metadata',
  fill: true,
  sources: [
    {
      src: url,
      type: 'application/x-mpegURL',
    },
  ],
});

const customFormatDuration = ({ start, end }: { start: number; end: number }) => {
  const durations = datefns.intervalToDuration({ start, end });
  return datefns.formatDuration(durations);
};
