import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Currency } from '../models/currency';
import { ShippingCategory } from '../models/shippingCategory';

import { Category } from './Category';
import { Channel } from './Channel';
import { Country } from './Country';
import { Language } from './Language';
import { Product } from './Product';
import { ShowSellerRating } from './Rating';
import { ShowNotification } from './ShowNotification';
import { User } from './User';
import { UserBannedFromShow } from './UserBannedFromShow';
import { UserFollowShow } from './UserFollowShow';

export enum AuctionInitialDurationPreset {
  time_5_seconds = 'time_5_seconds',
  time_15_seconds = 'time_15_seconds',
  time_30_seconds = 'time_30_seconds',
  time_60_seconds = 'time_60_seconds',
}

const AuctionInitialDurationPresetToTime = {
  [AuctionInitialDurationPreset.time_5_seconds]: 5000,
  [AuctionInitialDurationPreset.time_15_seconds]: 15000,
  [AuctionInitialDurationPreset.time_30_seconds]: 30000,
  [AuctionInitialDurationPreset.time_60_seconds]: 60000,
};

export function mapAuctionInitialDurationPresetToTime(
  key: AuctionInitialDurationPreset,
) {
  return AuctionInitialDurationPresetToTime[key];
}

export enum AuctionResetDurationPreset {
  time_00_seconds = 'time_00_seconds',
  time_03_seconds = 'time_03_seconds',
  time_10_seconds = 'time_10_seconds',
}

const AuctionResetDurationPresetToTime = {
  [AuctionResetDurationPreset.time_00_seconds]: 0,
  [AuctionResetDurationPreset.time_03_seconds]: 3000,
  [AuctionResetDurationPreset.time_10_seconds]: 10000,
};

export function mapAuctionResetDurationPresetToTime(
  key: AuctionResetDurationPreset,
) {
  return AuctionResetDurationPresetToTime[key];
}

export enum StreamingType {
  rtmp = 'rtmp', // mux
  web_rtc = 'web_rtc', // vonage (deprecated)
  ivs_rtmp = 'ivs_rtmp',
  ivs_web_rtc = 'ivs_web_rtc',
}

export type PreShowTeaserVideoMediaFileInfo = {
  url: string;
  size: number | null;
};

export type PreShowTeaserOriginalUploadedFileInfo = {
  name: string;
  size?: number;
};

export type PreShowTeaserVideoTranscodingStatus =
  | 'in_progress'
  | 'succeeded'
  | 'failed';
export enum PreShowTeaserVideoTranscodingErrorCode {
  audioMissing = 'AUDIO_MISSING',
  unknown = 'UNKNOWN',
}

export type PreShowTeaserVideoInfo = {
  mp4HdFileInfo: PreShowTeaserVideoMediaFileInfo;
  mp4SdFileInfo?: PreShowTeaserVideoMediaFileInfo;
  mpegDashManifest?: PreShowTeaserVideoMediaFileInfo;
  hlsManifest?: PreShowTeaserVideoMediaFileInfo;
  transcodingInfo?: {
    status: PreShowTeaserVideoTranscodingStatus;
    jobName: string;
    errorCode?: PreShowTeaserVideoTranscodingErrorCode;
  };
  originalFileInfo?: PreShowTeaserOriginalUploadedFileInfo;
};

export type RGB = [number, number, number];

@Entity()
@Index(['userId', 'isBroadcasting'], {
  unique: true,
  where: '"isBroadcasting" = true',
})
@Index(['startAt'])
export class Show {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column()
  @Index('show_name_gin_idx', { synchronize: false })
  name!: string;

  /**
   * @deprecated use `note` instead.
   * TODO: 1. select false when seller studio will have switched to the new mutation to create a show (they still use REST to update)
   * TODO: 2. remove this field in a migration
   */
  @Column({ nullable: true, type: 'character varying' })
  description!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  note!: string | null;

  @Column({ nullable: true })
  thumbnailUrl!: string;

  @Column({ nullable: true, type: 'jsonb' })
  thumbnailColorPalette!: RGB[] | null;

  @Column({ type: 'timestamp' })
  startAt!: Date;

  @Column({ type: 'timestamp', nullable: true, select: false })
  startedAt!: Date | null;

  @Column({ type: 'timestamp', nullable: true })
  endedAt!: Date | null;

  @Column({ type: 'timestamp', nullable: true })
  terminatedAt!: Date | null;

  @Column({ nullable: true })
  channelId!: number;

  @ManyToOne(() => Channel, { onDelete: 'CASCADE' })
  @JoinColumn({ name: 'channelId' })
  channel!: Channel;

  @ManyToOne(() => Category)
  @JoinColumn({ name: 'categoryId' })
  category!: Category;

  @Column()
  categoryId!: Category['id'];

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'countryId' })
  country!: Country;

  @Column()
  countryId!: Country['id'];

  @ManyToOne(() => Language)
  @JoinColumn({ name: 'languageId' })
  language!: Language;

  @Column({ nullable: false })
  languageId!: Language['id'];

  @Column({ type: 'boolean', default: false })
  isBroadcasting!: boolean;

  @Column({ type: 'boolean', default: false })
  isPremier!: boolean;

  @Column({ type: 'boolean', default: false })
  isFeatured!: boolean;

  @Column({ type: 'boolean', default: false })
  isSponsored!: boolean;

  @Column({ type: 'boolean', default: false })
  isGoldenDays!: boolean;

  @Column({ default: false })
  isAnniversary!: boolean;

  @Column({ default: false })
  isOffline!: boolean;

  @Column({ default: false })
  isSupplier!: boolean;

  @Column({
    type: 'enum',
    enum: Currency,
    default: Currency.eur,
    enumName: 'currency_enum',
  })
  currency!: Currency;

  @Column({ type: 'float', default: 8.9 })
  percentageFee!: number;

  @Column({ type: 'integer', default: 0 })
  fixedFee!: number;

  @Column({ default: 0 })
  defaultBuyerServiceFeeAmount!: number;

  @Column({ type: 'boolean', default: false })
  includeShippingFeesForCommission!: boolean;

  @OneToOne(() => ShowNotification, (notification) => notification.show)
  notification?: ShowNotification;

  @Column({
    type: 'enum',
    enum: ShippingCategory,
    default: ShippingCategory.letter,
    enumName: 'shippingcategory_enum',
  })
  shippingCategory!: ShippingCategory;

  @OneToMany(() => Product, (product) => product.show, { cascade: true })
  products!: Product[];

  @Column({
    type: 'enum',
    enum: AuctionInitialDurationPreset,
    default: AuctionInitialDurationPreset.time_60_seconds,
  })
  auctionInitialDurationPreset!: AuctionInitialDurationPreset;

  @Column({
    type: 'enum',
    enum: AuctionResetDurationPreset,
    default: AuctionResetDurationPreset.time_10_seconds,
  })
  auctionResetDurationPreset!: AuctionResetDurationPreset;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'userId' })
  user!: User;

  @Index()
  @Column()
  userId!: number;

  @Column({ nullable: true, unique: true })
  slug!: string;

  @Column({ type: 'enum', enum: StreamingType, default: StreamingType.rtmp })
  streamingType!: StreamingType;

  @Column({ nullable: true })
  openTokSessionId!: string;

  @Index()
  @Column({ nullable: true, type: 'character varying' })
  IVSStageId!: string | null;

  @Column({ nullable: true, type: 'character varying', select: false })
  IVSSellerParticipantId!: string | null;

  @Column({ default: 0 })
  paymentPreAuthorizationAmount!: number;

  @Column({ default: 0 })
  gmvTargetAmount!: number;

  @Column({ nullable: true, type: 'character varying' })
  teaserVideoUrl!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  preShowTeaserVideoUrl!: string | null;

  @Column({ nullable: true, type: 'json' })
  preShowTeaserVideoInfo!: PreShowTeaserVideoInfo | null;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;

  @OneToMany(() => UserFollowShow, (ufp) => ufp.show)
  followedByUsersRelations!: UserFollowShow[];

  @OneToMany(() => UserBannedFromShow, (ubfs) => ubfs.show)
  bannedUsersRelations!: UserBannedFromShow[];

  @Column({ nullable: true, type: 'character varying' })
  ratingId!: string | null;

  @OneToOne(() => ShowSellerRating)
  @JoinColumn({ name: 'ratingId' })
  rating!: ShowSellerRating | null;

  @Column({ default: false })
  isReusingPaidShippingFeesFromPreviousShows!: boolean;

  @Column({ nullable: true, type: 'boolean' })
  isUsingNotificationsCenter!: boolean | null;

  // !!! startAt (expected start time) != startedAt (effective start time)
  static shouldHaveBeenStarted(show: Pick<Show, 'startAt'>) {
    if (!show.startAt) {
      throw new Error(
        'Cannot compute shouldHaveBeenStarted. Missing startAt field on show',
      );
    }
    const currentDate = new Date();
    return new Date(show.startAt) < currentDate;
  }
}
