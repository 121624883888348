import { Entity, Index, JoinColumn, ManyToOne, PrimaryColumn } from 'typeorm';

import { Language } from './Language';
import { User } from './User';

@Entity()
export class UserFollowLanguage {
  @Index()
  @PrimaryColumn()
  userId!: User['id'];

  @ManyToOne(() => User, {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn({
    name: 'userId',
    referencedColumnName: 'id',
  })
  user!: User;

  @Index()
  @PrimaryColumn()
  languageId!: Language['id'];

  @ManyToOne(() => Language)
  @JoinColumn({
    name: 'languageId',
    referencedColumnName: 'id',
  })
  language!: Language;
}
