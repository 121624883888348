import { TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Box } from '@mui/system';

import { TableCellHeaderBorderBottom } from '@/components/elements/StyledTableCell';

import { PromotionsHeadCells } from './hooks/use-promotions-table-headers';

type PromotionsHeaderProps = {
  headCells: PromotionsHeadCells[];
  order: 'asc' | 'desc';
  orderBy: string;
  onOrderBy: (field: string) => void;
};

export const PromotionsHeader = ({
  headCells,
  orderBy,
  order,
  onOrderBy,
}: PromotionsHeaderProps) => {
  const handleOnSort = (id: string) => {
    onOrderBy(id);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCellHeaderBorderBottom
            key={headCell.id}
            minWidth={`${headCell.minWidth}px`}
            sx={{
              fontWeight: 'bold',
              pl: 0.5,
            }}
            align="center"
          >
            {!headCell.sortable ? (
              <Box component="span">{headCell.label}</Box>
            ) : (
              <TableSortLabel
                sx={{ pl: 5 }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => handleOnSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}

            {headCell.filter ?? null}
          </TableCellHeaderBorderBottom>
        ))}
      </TableRow>
    </TableHead>
  );
};
