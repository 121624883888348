import { TableCellHeaderBorderBottom } from '@/components/elements/StyledTableCell';

import { HeadCell } from '../RefundOrderItem';

type RefundOrderItemHeaderProps = {
  headCells: HeadCell[];
};

export const RefundOrderItemHeader = ({ headCells }: RefundOrderItemHeaderProps) => {
  return (
    <>
      {headCells.map((headCell) => (
        <TableCellHeaderBorderBottom
          key={`sticky-${headCell.id}`}
          maxWidth={headCell.maxWidth}
          minWidth={headCell.minWidth}
          sx={{
            fontWeight: 'bold',
            height: 50,
          }}
        >
          {headCell.label}
        </TableCellHeaderBorderBottom>
      ))}
    </>
  );
};
