import { InputLabel, InputLabelProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Label = (props: InputLabelProps) => (
  <StyledLabel htmlFor="orderItemId">{props.children}</StyledLabel>
);

const StyledLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  textTransform: 'uppercase',
}));
