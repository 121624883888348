import { useReducer } from 'react';

import { User } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { useOwnUser } from '@/context/own-user.context';

import { QUERIES_KEYS, queryClient } from '../../../queries';

export const useRestoreAccount = (user: User) => {
  const [restorationModalIsOpen, toggleRestorationModal] = useReducer((v) => !v, false);
  const snackbar = useSnackbar();
  const { hasPermissions } = useOwnUser();

  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      authenticatedInstance.patch(`/database-explorer/customer/${user.id}/`, {
        accountDeletedAt: null,
      }),
    onSuccess: () => {
      snackbar.success('User account restored successfully.');
      queryClient.invalidateQueries(QUERIES_KEYS.userDetail(user.id));
      toggleRestorationModal();
    },
    onError: () => {
      snackbar.error('Failed to restore user account.');
    },
  });

  const shouldDisplayRestoreAccount = [
    hasPermissions(PERMISSIONS.USER.WRITE_USER_HARD_BLOCK),
    user,
    Boolean(user.accountDeletedAt),
  ].every(Boolean);

  return {
    restorationModalIsOpen,
    toggleRestorationModal,
    handleConfirm: () => {
      mutate();
    },
    shouldDisplayRestoreAccount,
    isLoading,
  };
};
