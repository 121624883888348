import { ChangeEvent, useMemo, useState } from 'react';

import { Country, CountryCodeIso2, Promotion } from '@bits-app/bits-server-data';
import { Currency, currencySymbol, PromotionType, unitToCents } from '@bits-app/voggtpit-shared';
import {
  Box,
  Divider,
  FormControl,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CreatePromotionDto } from '@/promotions/gateways/promotions.port';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

import { selectPromotionsUpdating } from '../../../redux/promotions-selector';
import { createPromotion } from '../../../use-cases/create-promotion';

type PromotionState = Pick<
  Promotion,
  | 'name'
  | 'promotionAmount'
  | 'totalBudget'
  | 'promotionMaxAmount'
  | 'promotionPercentage'
  | 'type'
  | 'countryId'
> & {
  promotionType: 'percentage' | 'fix';
  expireAt: string;
};

const PROMOTION_TYPE_WITH_COUNTRY = [
  PromotionType.referral,
  PromotionType.referrer,
  PromotionType.welcome,
];

export interface SetupCampaignStepProps {
  currency: Currency;
  countries: Country[];
  handleNext(): void;
}

export const SetupPromotionStep = ({ currency, countries, handleNext }: SetupCampaignStepProps) => {
  const { t } = useTranslation();
  const isCreating = useAppSelector(selectPromotionsUpdating);
  const dispatch = useAppDispatch();
  const [state, setState] = useState<PromotionState>({
    name: '',
    promotionAmount: 0,
    totalBudget: 0,
    expireAt: '',
    promotionType: 'percentage',
    promotionMaxAmount: 0,
    promotionPercentage: 0,
    type: PromotionType.campaign,
    countryId: CountryCodeIso2.NULL,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnTypeChange = (event: SelectChangeEvent<PromotionType>) => {
    const type = event.target.value as PromotionType;
    const shouldResetCountry = !PROMOTION_TYPE_WITH_COUNTRY.includes(type);

    setState((prev) => ({
      ...prev,
      type,
      countryId: shouldResetCountry ? CountryCodeIso2.NULL : prev.countryId,
    }));
  };

  const handleOnCountryChange = (event: SelectChangeEvent<Country['id']>) => {
    setState((prev) => ({
      ...prev,
      countryId: event.target.value as Country['id'],
    }));
  };

  const handleTogglePromotionType = (
    _: React.MouseEvent<HTMLElement>,
    value: 'percentage' | 'fix',
  ) => setState({ ...state, promotionType: value });

  const canGoNext: boolean = useMemo(() => {
    const hasName = Boolean(state.name);
    const hasExpirationDate = Boolean(new Date(state.expireAt).getTime() > new Date().getTime());
    const hasAmountFilled =
      Boolean(state.promotionAmount) ||
      (Boolean(state.promotionMaxAmount) && Boolean(state.promotionPercentage));

    return hasName && hasAmountFilled && hasExpirationDate;
  }, [state]);

  const handleCreate = async () => {
    const isPromotionInPercentage = state.promotionType === 'percentage';

    try {
      const promotionInPercentage = {
        promotionMaxAmount: unitToCents(Number(state.promotionMaxAmount)),
        promotionPercentage: Number(state.promotionPercentage),
      };
      const promotionInFix = {
        promotionAmount: unitToCents(Number(state.promotionAmount)),
      };

      const promotion: CreatePromotionDto = Object.assign(
        {
          name: state.name,
          type: state.type,
          totalBudget: Number(state.totalBudget),
          currency,
          expireAt: new Date(state.expireAt),
          countryId: state.countryId,
        },
        isPromotionInPercentage ? promotionInPercentage : promotionInFix,
      );

      await dispatch(createPromotion(promotion));

      handleNext();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <FormControl margin="dense" component="fieldset" variant="standard" fullWidth>
        <FormGroup sx={{ px: 2, my: 2, gap: 2 }}>
          <TextField
            name="name"
            value={state.name}
            onChange={handleChange}
            variant="outlined"
            label={t('promotion.name')}
            fullWidth
          />

          <FormControl>
            <InputLabel id="promotion-type-label">{t('promotion.type')}</InputLabel>

            <Select
              name="type"
              labelId="promotion-type-label"
              value={state.type}
              onChange={handleOnTypeChange}
              variant="outlined"
              label={t('promotion.type')}
              fullWidth
            >
              {Object.entries(PromotionType).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="promotion-country-label">{t('promotion.country')}</InputLabel>

            <Select
              name="countryId"
              labelId="promotion-country-label"
              value={state.countryId}
              onChange={handleOnCountryChange}
              variant="outlined"
              label={t('promotion.country')}
              fullWidth
              disabled={!PROMOTION_TYPE_WITH_COUNTRY.includes(state.type)}
            >
              {countries.map(({ id, iso2Code }) => (
                <MenuItem key={id} value={id}>
                  {iso2Code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            onChange={handleChange}
            name="expireAt"
            type="date"
            value={state.expireAt}
            variant="outlined"
            fullWidth
            label={t('promotion.expirationDate')}
            InputLabelProps={{ shrink: true }}
          />

          <Divider />

          <Stack spacing={3}>
            <ToggleButtonGroup
              color="primary"
              value={state.promotionType}
              exclusive
              onChange={handleTogglePromotionType}
              aria-label="Platform"
            >
              <ToggleButton value="percentage">Percentage (%)</ToggleButton>
              <ToggleButton value="fix">Fix (€)</ToggleButton>
            </ToggleButtonGroup>

            {state.promotionType === 'fix' ? (
              <TextField
                name="promotionAmount"
                value={state.promotionAmount}
                type="number"
                onChange={handleChange}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currencySymbol(currency)}</InputAdornment>
                  ),
                }}
                label={t('promotion.amountPerPromotion')}
              />
            ) : (
              <>
                <TextField
                  name="promotionPercentage"
                  value={state.promotionPercentage}
                  type="number"
                  onChange={handleChange}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                  label={t('promotion.promotionPercentage')}
                />

                <TextField
                  name="promotionMaxAmount"
                  value={state.promotionMaxAmount}
                  type="number"
                  onChange={handleChange}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{currencySymbol(currency)}</InputAdornment>
                    ),
                  }}
                  label={t('promotion.promotionMaxAmount')}
                />
              </>
            )}
          </Stack>
        </FormGroup>
      </FormControl>

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <LoadingButton
          loading={isCreating}
          disabled={!canGoNext}
          variant="contained"
          color="primary"
          onClick={handleCreate}
        >
          {t('next')}
        </LoadingButton>
      </Box>
    </>
  );
};
