import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Country } from './Country';
import { TermsAndConditionsAcceptance } from './TermsAndConditionsAcceptance';

@Entity()
export class TermsAndConditions {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column()
  url!: string;

  @ManyToOne(() => Country)
  @JoinColumn({ name: 'countryId' })
  country!: Country;

  @Index()
  @Column()
  countryId!: Country['id'];

  @OneToMany(
    () => TermsAndConditionsAcceptance,
    (acceptance) => acceptance.termsAndConditions,
  )
  termsAndConditionsAcceptances!: TermsAndConditionsAcceptance[];

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;
}
