import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
} from 'typeorm';

import { PreBid, PreBidStatus } from './PreBid';

@Entity()
export class PreBidEvent {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @ManyToOne(() => PreBid)
  @JoinColumn({ name: 'preBidId' })
  preBid!: PreBid;

  @Index()
  @Column()
  preBidId!: PreBid['id'];

  @Column({ type: 'enum', enum: PreBidStatus })
  status!: PreBidStatus;

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;
}
