import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../../../../axios/axios.instance';
import { useOwnUser } from '../../../../../../context/own-user.context';
import { User } from '../../../../users';

export const useGeneratePayout = (userId: User['id']) => {
  const { hasPermissions } = useOwnUser();
  const hasPermissionToGeneratePayout = hasPermissions(PERMISSIONS.LEDGER.WRITE_LEDGER_PAYOUT);

  return useMutation(async () => {
    if (!hasPermissionToGeneratePayout) return Promise.reject('No permission');
    const result = await authenticatedInstance.post(`/ledger/payout/${userId}`);
    return result?.data;
  });
};
