import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { TablePagination, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { UpdateOrderItemsShippingStatusModal } from '@/components/UpdateOrderItemsShippingStatus.modal';
import { isSellerBuyerFilter } from '@/components/elements/filters/types';
import { useOwnUser } from '@/context/own-user.context';
import { UnsavedProvider } from '@/context/unsaved.context';
import { UpdateOrderItemsTrackingNumberDialog } from '@/order-items-mass-update/ui/UpdateOrderItemsTrackingNumberDialog';
import { TableToolbar } from '@Elements/TableToolbar';
import { ClearAllFilterButton } from '@Elements/buttons/ClearAllFilterButton';

import { EditMenuOrderItems } from './EditMenuOrderItems';
import {
  OrderItemsMassUpdateTabs,
  OrderItemsMassUpdateTabsValues,
} from './OrderItemsMassUpdateTabs';
import { useOrderItemsMassUpdate } from './hooks/use-order-items-mass-update';
import { OrderItemsMassUpdateTable } from './table/OrderItemsMassUpdateTable';
import { SellerBuyerIntersectionFilter } from './table/filters/SellerBuyerIntersectionFilter/SellerBuyerIntersectionFilter';

type OrderItemsMassUpdateProps = {
  tab: OrderItemsMassUpdateTabsValues;
};

const WrappedOrderItemsMassUpdate = ({ tab }: OrderItemsMassUpdateProps) => {
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();

  const {
    addFilter,
    options,
    orderItems,
    selected,
    loading,
    orderItemsCount,
    openUpdateDialog,
    setOpenUpdateDialog,
    headCells,
    stickyHeadCells,
    onOrderBy,
    onClearAllFilters,
    onChangePage,
    onUpdateOrderItemShippingStatus,
    onUpdateOrderItemTrackingNumber,
    onToggleAll,
    onToggleItem,
  } = useOrderItemsMassUpdate();

  const { seller, buyer, orderId, sellerBuyerIntersection } = options.filters;
  const isReadOnly = !hasPermissions(
    PERMISSIONS.ORDER_ITEM_MASS_UPDATE.WRITE_ORDER_ITEM_MASS_UPDATE,
  );

  return (
    <div className="flex flex-col flex-1 h-full">
      <TableToolbar
        title={<OrderItemsMassUpdateTabs tab={tab} />}
        selectedCount={selected.length}
        tableActions={
          <Box display="flex" gap={2}>
            <SellerBuyerIntersectionFilter
              filter={
                isSellerBuyerFilter(sellerBuyerIntersection) ? sellerBuyerIntersection : undefined
              }
              addFilter={addFilter}
              isUnsaved={selected.length > 0}
              disabled={[seller, buyer, orderId].some(Boolean)}
            />

            {Object.keys(options.filters).length > 0 ? (
              <ClearAllFilterButton
                clearAllFilters={onClearAllFilters}
                filtersCount={Object.keys(options.filters).length}
              />
            ) : undefined}
          </Box>
        }
        selectionActions={
          !isReadOnly ? (
            <Tooltip title={t('update')}>
              <EditMenuOrderItems
                onUpdateShippingStatusClick={() => setOpenUpdateDialog('shipping-status')}
                onUpdateTrackingNumberClick={() => setOpenUpdateDialog('tracking-number')}
              />
            </Tooltip>
          ) : null
        }
      />

      <OrderItemsMassUpdateTable
        disabledSelectRow={Object.keys(options.filters).length === 0}
        orderItems={orderItems}
        selected={selected}
        areAllSelected={orderItems.length > 0 && orderItems.length === selected.length}
        headCells={headCells}
        stickyHeadCells={stickyHeadCells}
        onToggleAll={onToggleAll}
        orderBy={options.order.field ?? 'id'}
        order={(options.order.value.toLowerCase() as 'asc' | 'desc') ?? 'asc'}
        onOrderBy={onOrderBy}
        isUnsaved={selected.length > 0}
        loading={loading}
        onToggleItem={onToggleItem}
        isReadOnly={isReadOnly}
      />

      {!loading && orderItems.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={orderItemsCount}
          rowsPerPage={100}
          page={options.page}
          onPageChange={(_: unknown, newPage: number) => onChangePage(newPage)}
        />
      )}

      {openUpdateDialog === 'shipping-status' && (
        <UpdateOrderItemsShippingStatusModal
          count={selected.length}
          onClose={() => setOpenUpdateDialog(null)}
          onUpdate={onUpdateOrderItemShippingStatus}
        />
      )}

      {openUpdateDialog === 'tracking-number' && (
        <UpdateOrderItemsTrackingNumberDialog
          count={selected.length}
          onClose={() => setOpenUpdateDialog(null)}
          onUpdate={onUpdateOrderItemTrackingNumber}
        />
      )}
    </div>
  );
};

export const OrderItemsMassUpdate = ({ tab }: OrderItemsMassUpdateProps) => (
  <UnsavedProvider>
    <WrappedOrderItemsMassUpdate tab={tab} />
  </UnsavedProvider>
);
