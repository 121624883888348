import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import { ButtonProps, IconButton as Parent } from '@mui/material';

type AvailableIconsList = 'Next' | 'Previous';

const AvailableIcon: Record<AvailableIconsList, React.FC> = {
  Next: NavigateNext,
  Previous: NavigateBefore,
};

interface Props extends ButtonProps {
  iconName: AvailableIconsList;
}

export const PaginationButtonIcon = (props: Props) => {
  const { iconName, ...restProps } = props;
  const Icon: React.FC = AvailableIcon[iconName];
  return (
    <Parent {...restProps}>
      <Icon />
    </Parent>
  );
};
