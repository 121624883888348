import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  ManyToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { User } from './User';

export const DE = 'de'; // German
export const EN = 'en'; // English
export const ES = 'es'; // Spanish
export const FR = 'fr'; // French
export const NL = 'nl'; // Dutch

export const DEFAULT_LANG_ID = EN;

@Entity()
export class Language {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column({ unique: true })
  code!: string;

  @ManyToMany(() => User, (user) => user.followedLanguages)
  followedBy!: User[];

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;
}
