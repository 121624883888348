import { StreamingAsset, MuxAsset } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';

import { authenticatedInstance } from '../axios/axios.instance';
import { useSnackbar } from '../components/elements/snackbar/use-snackbar';

type UseDownloadMuxAsset = {
  requestDownload: () => void;
};

const noop = () => undefined;

export const useDownloadMuxAsset = (currentAsset?: StreamingAsset): UseDownloadMuxAsset => {
  const snackbar = useSnackbar();
  const { mutate } = useMutation(requestMuwDownloadLink, {
    onSuccess: ({ data }) => {
      if (!data) return;
      const { url } = data;
      if (!url) {
        snackbar.warning('mux.download.info');
        return;
      }

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', currentAsset?.id || 'download');
      document.body.appendChild(link);
      link.click();
    },
  });

  const requestDownload =
    currentAsset && !currentAsset?.downloadLink ? () => mutate(currentAsset.id) : noop;

  return {
    requestDownload,
  };
};

async function requestMuwDownloadLink(assetId: StreamingAsset['id']) {
  return authenticatedInstance.get<MuxAsset['master']>(`/mux/${assetId}/download`);
}
