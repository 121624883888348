import { useState } from 'react';

import { CommercialEvent } from '@bits-app/bits-server-data';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  DialogContentText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useListCommercialEventsByShowId } from '../hooks/use-list-commercial-event-by-show-id';

type RemoveFromCommercialEventModalProps = {
  showId: number;
  onConfirm: (commercialEventId: CommercialEvent['id']) => void;
  onCancel: () => void;
};

export const RemoveFromCommercialEventModal = ({
  showId,
  onConfirm,
  onCancel,
}: RemoveFromCommercialEventModalProps) => {
  const { t } = useTranslation();
  const [selectedCommercialEventId, setSelectedCommercialEventId] = useState<
    CommercialEvent['id'] | ''
  >('');
  const { data: commercialEvents, isLoading } = useListCommercialEventsByShowId(showId);

  return (
    <Dialog open>
      <DialogTitle>{t('databaseExplorer.commercialEvent.remove.title')}</DialogTitle>
      <DialogContent>
        <Stack direction={'column'} spacing={2}>
          <DialogContentText>
            {t('databaseExplorer.commercialEvent.removeItem.description')}
          </DialogContentText>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <Select
              value={selectedCommercialEventId}
              onChange={(e: SelectChangeEvent<CommercialEvent['id']>) =>
                setSelectedCommercialEventId(e.target.value as CommercialEvent['id'])
              }
            >
              {commercialEvents?.map((commercialEvent) => (
                <MenuItem key={commercialEvent.id} value={commercialEvent.id}>
                  {commercialEvent.internalName || commercialEvent.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('action.cancel')}</Button>
        <Button
          onClick={() => selectedCommercialEventId && onConfirm(selectedCommercialEventId)}
          disabled={!selectedCommercialEventId}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
