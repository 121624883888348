import type { CommercialEvent } from '@bits-app/bits-server-data';
import { CircularProgress } from '@mui/material';

import { validationSchemasBuilder } from '../..';
import { UpsertForm } from '../../components/upsert-view/UpsertForm';
import { useDuplicateCommercialEvent } from '../hooks/use-duplicate-commercial-event';

type DuplicateCommercialEventProps = {
  commercialEventId: CommercialEvent['id'];
};

export const DuplicateCommercialEvent = ({ commercialEventId }: DuplicateCommercialEventProps) => {
  const { isLoading, mutate, header, schemas } = useDuplicateCommercialEvent(commercialEventId);

  const validationSchemaBuilder = validationSchemasBuilder['CommercialEvent'];

  const shouldReturnNull = !commercialEventId || !schemas.length || !header;
  if (shouldReturnNull) {
    return null;
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <UpsertForm<CommercialEvent>
      schemas={schemas}
      onUpsert={mutate}
      validationSchemaBuilder={validationSchemaBuilder}
    />
  );
};
