import { Permission } from '../permissions';

type OwnUserAttributes = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  hashedPassword?: string;
  isRegisteredWithGoogle: boolean;
  refreshToken?: string;
  permissions: Permission[];
  zendeskAccessToken?: string;
  isAuthenticatedToZendesk?: boolean;
};

export class OwnUser {
  readonly id: string;

  readonly email: string;

  readonly lastName: string;

  readonly firstName: string;

  readonly hashedPassword?: string;

  readonly isRegisteredWithGoogle: boolean;

  readonly refreshToken?: string;

  readonly zendeskAccessToken?: string;

  readonly isAuthenticatedToZendesk?: boolean;

  permissions: Permission[];

  constructor(attributes: OwnUserAttributes) {
    this.id = attributes.id;
    this.email = attributes.email;
    this.firstName = attributes.firstName;
    this.lastName = attributes.lastName;
    this.hashedPassword = attributes.hashedPassword;
    this.isRegisteredWithGoogle = attributes.isRegisteredWithGoogle;
    this.refreshToken = attributes.refreshToken;
    this.zendeskAccessToken = attributes.zendeskAccessToken;
    this.isAuthenticatedToZendesk = Boolean(
      attributes.isAuthenticatedToZendesk,
    );
    this.permissions = attributes.permissions;
  }
}
