import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/redux/store';

const orderItemsMassUpdateSelector = (state: RootState) => state.orderItemsMassUpdate;

export const selectOrderItemsMassUpdate = createSelector(
  orderItemsMassUpdateSelector,
  (state) => state.orderItems,
);

export const selectOrderItemsMassUpdateCount = createSelector(
  orderItemsMassUpdateSelector,
  (state) => state.count,
);

export const selectOrderItemsMassUpdateLoading = createSelector(
  orderItemsMassUpdateSelector,
  (state) => state.loading,
);

export const selectOrderItemsMassUpdating = createSelector(
  orderItemsMassUpdateSelector,
  (state) => state.updating,
);

export const selectOrderItemsMassUpdateFilterCountFor = createSelector(
  [orderItemsMassUpdateSelector, (_, field) => field],
  (state, field) => {
    return state.filters[field]?.count ?? 0;
  },
);

export const selectOrderItemsMassUpdateFilterValuesFor = createSelector(
  [orderItemsMassUpdateSelector, (_, field) => field],
  (state, field) => {
    return state.filters[field]?.values ?? [];
  },
);

export const selectOrderItemsMassUpdateFilterLoadingFor = createSelector(
  [orderItemsMassUpdateSelector, (_, field) => field],
  (state, field) => {
    return state.filters[field]?.loading ?? false;
  },
);
