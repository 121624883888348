import { User } from '@bits-app/bits-server-data';
import { Box } from '@mui/system';

import { AlertBar } from '@/components/elements/AlertBar';

export const UserBanner = ({ user }: { user?: User }) => {
  if (!user) return null;
  const { title, text, disabled, severity, extraText } = checkUser(user);
  if (disabled) return null;
  return (
    <Box width={'100%'} justifyContent={'center'} mb={1}>
      <AlertBar title={title} text={text} severity={severity} extraText={extraText} />
    </Box>
  );
};

const checkUser = (
  user: User,
): {
  title?: string;
  disabled: boolean;
  text?: string;
  severity: 'success' | 'info' | 'warning' | 'error';
  extraText?: Record<string, string>;
} => {
  if (user?.accountDeletedAt || user?.deletedAt) {
    return { title: 'banner.user.deleted', disabled: false, text: undefined, severity: 'error' };
  }

  if (user?.isHardBlocked) {
    return {
      title: 'banner.user.userIsBlocked',
      disabled: false,
      text: undefined,
      severity: 'error',
    };
  }

  if (user?.isBanned) {
    return {
      title: 'banner.user.userIsBanned',
      disabled: false,
      text: undefined,
      severity: 'error',
    };
  }

  if (user?.email && user?.userSso?.email && user?.userSso?.email !== user?.email) {
    return {
      title: 'banner.user.emailSsoMismatch',
      disabled: false,
      severity: 'warning',
    };
  }

  return {
    disabled: true,
    severity: 'error',
  };
};
