import { useMemo } from 'react';

import { ShippingFeeConfig } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { ItemLayout } from '@/voggt-database-explorer/layouts/ItemLayout';

import { Detail } from './Detail';
import { useShippingFeeConfigDetailsView } from './useShippingFeeConfigDetailsView';

export const ShippingFeeConfigDetail = () => {
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();
  const isReadOnly = !hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_SHIPPING_FEE_CONFIG,
  );

  const shippingFeeConfigId = useIdParam();
  const location = useLocation();

  const { loading, shippingFeeConfig, refreshAfterEdit } =
    useShippingFeeConfigDetailsView(shippingFeeConfigId);

  const currentTab = useMemo(() => {
    return ['user'].find((path) => location.pathname.includes(path)) ?? '/';
  }, [location.pathname]);

  const handleOnTabChange = useNavigateInDetailTab(`/badge/${shippingFeeConfigId}`);

  const tabs = [
    {
      path: '/',
      label: t('databaseExplorer.shippingFeeConfig.tabs.main'),
      Element: (
        <Detail
          key="/"
          loading={loading}
          shippingFeeConfig={shippingFeeConfig}
          isReadOnly={isReadOnly}
        />
      ),
    },
  ];

  return (
    <ItemLayout<ShippingFeeConfig>
      id={shippingFeeConfigId}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
      editionDescriptor={
        isReadOnly
          ? undefined
          : {
              goBackName: t('databaseExplorer.editionFormPath.shippingFeeConfigs', {
                shippingFeeConfigId,
              }),
              resourceId: shippingFeeConfigId,
              resourceType: 'Shipping-Fee-Config',
              onSuccess: refreshAfterEdit,
            }
      }
    />
  );
};
