import { Currency, PERMISSIONS } from '@bits-app/voggtpit-shared';

import { useOwnUser } from '@/context/own-user.context';
import { useExpirePromotion } from '@/promotions/hooks/use-expire-promotion';
import { useManagerUsers } from '@/promotions/hooks/use-manager-users/use-manager-users';
import { AddUsersDialog } from '@/promotions/ui/dialogs/promotions-edition/add-users.dialog';
import { useGetCountries } from '@/queries';

import { useEditPromotion } from '../../hooks/use-edit-promotion/use-edit-promotion';
import { usePromotions } from '../../hooks/use-promotions';
import { ManagerUser } from '../dialogs/ManagerUser';
import { ConfirmPromotionExpiration } from '../dialogs/confirm-promotion-expiration';
import { CreatePromotionCampaignStepper } from '../dialogs/promotions-edition/create-promotion.stepper';
import { EditPromotionModal } from '../dialogs/promotions-edition/edit-promotion';
import { PromotionsTable } from '../table/PromotionsTable';
import { usePromotionsTableHeaders } from '../table/hooks/use-promotions-table-headers';

export interface PromotionPageProps {
  currency: Currency;
}

export const Promotions = ({ currency }: PromotionPageProps) => {
  const { hasPermissions } = useOwnUser();
  const {
    promotions,
    count,
    onChangePage,
    options,
    loading,
    launchCampaignCreation,
    closeCampaignCreate,
    isCreatingCampaign,
    onOrderBy,
    addFilter,
    isAddingUsers,
    launchAddUsersDialog,
    closeAddUsersDialog,
  } = usePromotions({ currency });

  const { openExpirationConfirmation, confirmExpiration } = useExpirePromotion();
  const { data: countries, isFetching } = useGetCountries();

  const isReadOnly = !hasPermissions(PERMISSIONS.PROMOTION.WRITE_PROMOTION);

  const { headCells } = usePromotionsTableHeaders({
    addFilter,
    filters: options.filters,
    isReadOnly,
    countries: countries ?? [],
  });

  const { toggleModal, handleSubmit, ...state } = useEditPromotion();

  const { selectPromotion, ...useManagerUsersHook } = useManagerUsers();

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <PromotionsTable
        headCells={headCells}
        promotions={promotions}
        loading={loading}
        options={{ ...options, currency }}
        count={count}
        onChangePage={onChangePage}
        launchCampaignCreation={launchCampaignCreation}
        orderBy={options.order.field ?? 'id'}
        order={(options.order.value.toLowerCase() as 'asc' | 'desc') ?? 'asc'}
        onOrderBy={onOrderBy}
        handleExpirePromotion={openExpirationConfirmation}
        handleEditPromotion={toggleModal}
        isReadOnly={isReadOnly}
        handleManageUser={selectPromotion}
      />

      {isCreatingCampaign && !isReadOnly && !isFetching && (
        <CreatePromotionCampaignStepper
          open={isCreatingCampaign}
          handleClose={closeCampaignCreate}
          currency={currency}
          countries={countries ?? []}
        />
      )}

      {isAddingUsers && !isReadOnly && <AddUsersDialog handleClose={closeAddUsersDialog} />}
      {!isReadOnly && (
        <EditPromotionModal state={state} handleChange={handleSubmit} discard={toggleModal} />
      )}
      {!isReadOnly && <ConfirmPromotionExpiration {...confirmExpiration} />}
      <ManagerUser
        {...useManagerUsersHook}
        close={() => selectPromotion()}
        handleAddUsers={launchAddUsersDialog}
      />
    </div>
  );
};
