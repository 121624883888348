import { useMemo } from 'react';

import { Shipment, Order, Product } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { ItemLayout } from '@/voggt-database-explorer/layouts/ItemLayout';

import { ListWithExport } from '../../../components/ListWithExport';
import {
  CellOrderDefinition,
  CellProductDefinition,
} from '../../../users/hooks/use-cell-definition';

import { Detail } from './Detail';
import { useShipmentDetail } from './useShipmentDetail.hook';

export const ShipmentDetail = () => {
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();
  const shipmentId = useIdParam();
  const location = useLocation();
  const navigate = useNavigate();

  const { shipment, refetchShipment, loading } = useShipmentDetail(shipmentId);

  const currentTab = useMemo(() => {
    return ['orders', 'products'].find((path) => location.pathname.includes(path)) ?? '/';
  }, [location.pathname]);

  const handleOnTabChange = (tab: string) =>
    navigate(`/shipment/${shipmentId}${tab === '/' ? '' : '/' + tab}`);

  const userCanEditShipment = hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_SHIPMENT,
  );

  if (!shipmentId || !shipment) {
    return null;
  }

  const tabs = [
    {
      path: '/',
      label: t('databaseExplorer.shipment.tabs.main'),
      Element: <Detail key="/" loading={loading} shipment={shipment} />,
    },
    {
      path: 'orders',
      label: t('databaseExplorer.shipment.tabs.orders'),
      Element: (
        <ListWithExport<Order & { id: string }>
          key="orders"
          cellDefinition={CellOrderDefinition}
          filters={{ shipmentId }}
          dataName="orders-from-shipment"
        />
      ),
    },
    {
      path: 'products',
      label: t('databaseExplorer.shipment.tabs.products'),
      Element: (
        <ListWithExport<Product & { id: string }>
          key="products"
          cellDefinition={CellProductDefinition}
          filters={{ shipmentId }}
          dataName="products-from-shipment"
        />
      ),
    },
  ];

  return (
    <ItemLayout<Shipment>
      id={shipmentId}
      title={`Shipment ${shipmentId} - ${shipment?.status ?? ''}`}
      description={`From ${shipment?.seller.username ?? ''} to ${
        shipment?.customer.username ?? ''
      }`}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={handleOnTabChange}
      editionDescriptor={
        userCanEditShipment
          ? {
              goBackName: t('databaseExplorer.editionFormPath.shipment', {
                shipmentId,
              }),
              resourceId: shipmentId,
              resourceType: 'Shipment',
              onSuccess: refetchShipment,
            }
          : undefined
      }
    />
  );
};
