import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

export type InfiniteScrollContainerHandle = {
  reset: () => void;
};

type InfiniteScrollContainerProps = {
  maxHeight: React.CSSProperties['maxHeight'];
  onReachEnd: (nextPage: number) => void;
  children: React.ReactNode;
};

export const InfiniteScrollContainer = forwardRef<
  InfiniteScrollContainerHandle,
  InfiniteScrollContainerProps
>(({ onReachEnd, maxHeight, children }, ref) => {
  const { scrollRef, onScroll } = useInfiniteScroll(onReachEnd, ref);

  return (
    <div ref={scrollRef} onScroll={onScroll} style={{ overflowY: 'auto', maxHeight }}>
      {children}
    </div>
  );
});

const useInfiniteScroll = (
  onReachEnd: (nextPage: number) => void,
  ref: React.Ref<InfiniteScrollContainerHandle>,
) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const [page, setPage] = useState(0);
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);

  useImperativeHandle(
    ref,
    () => ({
      reset() {
        setPage(0);
        setPrevScrollHeight(0);
      },
    }),
    [],
  );

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      if (scrollTop + clientHeight === scrollHeight) {
        const hasAlreadyBeenReached = prevScrollHeight >= scrollTop + clientHeight;

        if (!hasAlreadyBeenReached) {
          onReachEnd(page + 1);
          setPage(page + 1);
          setPrevScrollHeight(scrollHeight);
        }
      }
    }
  };

  return {
    scrollRef,
    onScroll,
  };
};
