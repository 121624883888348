import type {
  PaymentMethodBancontact,
  PaymentMethodCard,
  PaymentMethodGiropay,
  PaymentMethodIdeal,
  PaymentMethodPaypal,
  User,
} from '@bits-app/bits-server-data';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { Information, PanelDetails } from '@/components/layouts/PanelDetails';
import { useIdParam } from '@/hooks/use-id-params';
import { formatDate } from '@/utils';
import { DetailsLayout } from '@/voggt-database-explorer/layouts/DetailsLayout';

import { availablePaymentMethodType } from '../../payment-method.type';

import { useDetailsView } from './useDetailsView';

type ConditionnalPaymentMethod<T> = T extends PaymentMethodBancontact
  ? PaymentMethodBancontact
  : T extends PaymentMethodCard
    ? PaymentMethodCard
    : T extends PaymentMethodIdeal
      ? PaymentMethodIdeal
      : T extends PaymentMethodPaypal
        ? PaymentMethodPaypal
        : PaymentMethodGiropay;

export const Detail = <T,>({
  paymentMethodName,
}: {
  paymentMethodName: availablePaymentMethodType;
}) => {
  const paymentMethodId = useIdParam();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const basicPanel: Information<ConditionnalPaymentMethod<T>>[] = [
    { label: 'id', value: 'id' },
    {
      label: 'Created at',
      value: 'createdAt',
      format: (paymentMethod) =>
        paymentMethod?.createdAt
          ? formatDate(paymentMethod.createdAt, 'dd/MM/yyyy HH:mm:ss')
          : undefined,
    },
    {
      label: 'Updated at',
      value: 'updatedAt',
      format: (paymentMethod) =>
        paymentMethod?.updatedAt
          ? formatDate(paymentMethod.updatedAt, 'dd/MM/yyyy HH:mm:ss')
          : undefined,
    },
    {
      label: 'Deleted at',
      value: 'deletedAt',
      format: (paymentMethod) =>
        paymentMethod?.deletedAt
          ? formatDate(paymentMethod.deletedAt, 'dd/MM/yyyy HH:mm:ss')
          : undefined,
    },
  ];

  const {
    loading,
    panelDetailsPaymentMethodPaypal,
    panelDetailsPaymentMethodBancontact,
    panelDetailsPaymentMethodCard,
    panelDetailsPaymentMethodGiropay,
    panelDetailsPaymentMethodIdeal,
    panelDetailsUser,
    paymentMethodData,
  } = useDetailsView<ConditionnalPaymentMethod<T> & { id: string }>(
    paymentMethodId,
    paymentMethodName,
  );

  const panelRef = {
    paymentMethodBancontact: panelDetailsPaymentMethodBancontact,
    paymentMethodCard: panelDetailsPaymentMethodCard,
    paymentMethodGiropay: panelDetailsPaymentMethodGiropay,
    paymentMethodIdeal: panelDetailsPaymentMethodIdeal,
    paymentMethodPaypal: panelDetailsPaymentMethodPaypal,
  }[paymentMethodName] as Information<ConditionnalPaymentMethod<T>>[];

  if (!paymentMethodId) {
    return null;
  }

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'}>
          <Stack spacing={4} direction="row" ml={'auto'} pr={1} pl={1}>
            <ForestAdminLink entity={paymentMethodName} entityId={paymentMethodId} />
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12}>
            <PanelDetails<ConditionnalPaymentMethod<T> & { id: string }>
              title={t('databaseExplorer.panel.main')}
              informations={basicPanel.concat(panelRef)}
              data={paymentMethodData}
              loading={loading}
            />
          </Grid>
        </Grid>

        <Grid xs={12} md={6}>
          <Grid xs={12}>
            <PanelDetails<User>
              title={t('databaseExplorer.panel.user')}
              informations={panelDetailsUser}
              data={paymentMethodData?.user}
              labelRow
              seeDetails={(user) => navigate(`/user/${user.id}`)}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </DetailsLayout>
  );
};
