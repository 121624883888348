import { ChangeEvent, FormEvent, useState } from 'react';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';

export interface UseSearchValueFilterProps {
  addFilter: (filter: AddFilterFunctionParams) => void;
  field: AddFilterFunctionParams['field'];
  defaultValue?: string;
}

export const useSearchValueFilter = ({
  addFilter,
  field,
  defaultValue = '',
}: UseSearchValueFilterProps) => {
  const [value, setValue] = useState<string>(defaultValue);

  const handleClose = () => {
    setValue((prev) => prev);
  };

  const clearFilter = () => {
    setValue('');
    addFilter({ field, values: [] });
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleAddOption = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    addFilter({ field, values: value });
  };

  return {
    value,
    onClose: handleClose,
    onAddOption: handleAddOption,
    onChange: handleOnChange,
    clearFilter,
  };
};
