import { useCallback, useEffect } from 'react';

import {
  Box,
  Drawer,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TextField } from '@Elements/TextInput';
import { Button } from '@Elements/buttons/Button';
import { useAmountInput } from '@Elements/inputs/AmountInput';
import { NumberInput } from '@Elements/inputs/NumberInput';

import { ModeType, usePartnerProgramForm } from '../hooks/usePartnerProgramForm';
import { selectPartnerProgramsCurrent } from '../redux/partner-program.selector';
import { partnerProgramActions } from '../redux/partner-program.slice';

export const PartnerProgramForm = () => {
  const { t } = useTranslation();
  const partnerProgramSelected = useSelector(selectPartnerProgramsCurrent);
  const dispatch = useDispatch();
  const { handleSubmit, isLoading, state, updateState, isCreation, handleDelete, mode, setMode } =
    usePartnerProgramForm();

  const { onAmountChange, onFormatAmount } = useAmountInput(state.amount ?? '', (amount) =>
    updateState({
      action: 'updateField',
      value: { key: 'amount', data: amount },
    }),
  );

  const {
    onAmountChange: onPercentageMaxAmountChange,
    onFormatAmount: onFormatPercentageMaxAmount,
  } = useAmountInput(state.percentageMaxAmount ?? '', (percentageMaxAmount) =>
    updateState({
      action: 'updateField',
      value: { key: 'percentageMaxAmount', data: percentageMaxAmount },
    }),
  );

  const handleClose = useCallback(() => {
    updateState({ action: 'reset', value: null });
    dispatch(partnerProgramActions.selectForEdition(null));
    setMode('percentage');
  }, [dispatch, updateState, setMode]);

  useEffect(() => {
    if (!partnerProgramSelected) {
      handleClose();
    }
  }, [partnerProgramSelected, handleClose]);

  const title = isCreation ? 'action.create' : 'action.update';

  return (
    <Drawer anchor={'right'} open={Boolean(partnerProgramSelected)} onClose={handleClose}>
      <Box
        sx={{
          width: 'calc(100vw / 4)',
          paddingLeft: 6,
          paddingRight: 6,
          display: 'flex',
          flexDirection: 'column ',
        }}
        role="presentation"
        display="flex"
        gap={3}
      >
        <h1>{t(title)}</h1>

        <TextField
          label={t('partnerProgram.form.name')}
          value={state.name}
          required
          onChange={(event) =>
            updateState({ action: 'updateField', value: { key: 'name', data: event.target.value } })
          }
        />
        <TextField
          label={t('partnerProgram.form.description')}
          value={state.description}
          multiline
          rows={3}
          onChange={(event) =>
            updateState({
              action: 'updateField',
              value: { key: 'description', data: event.target.value },
            })
          }
        />

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1">{t('partnerProgram.form.type')}</Typography>
          <Select
            value={mode}
            onChange={(val) => setMode(val.target.value as ModeType)}
            sx={{
              minWidth: 95,
            }}
          >
            <MenuItem value={'fix'}>{t('partnerProgram.form.fix')}</MenuItem>
            <MenuItem value={'percentage'}>{t('partnerProgram.form.percentageBased')}</MenuItem>
          </Select>
        </Box>

        {mode === 'fix' ? (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
            <TextField
              label={t('partnerProgram.form.amount')}
              value={state.amount}
              required
              onChange={onAmountChange}
              onBlur={onFormatAmount}
              inputProps={{
                lang: 'en_US',
                pattern: '[0-9]*.?[0-9]*',
                min: 0,
              }}
              {...inputWithEndSelectStyleOverride}
            />

            <Select
              value={state.currency}
              onChange={(val) =>
                updateState({
                  action: 'updateField',
                  value: { key: 'currency', data: val.target.value },
                })
              }
              sx={{
                borderLeft: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                minWidth: 95,
              }}
            >
              <MenuItem value={'eur'}>{t('partnerProgram.form.eur')}</MenuItem>
              <MenuItem value={'gbp'}>{t('partnerProgram.form.gbp')}</MenuItem>
            </Select>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Stack spacing={1} direction={'column'}>
              <Stack spacing={1} direction={'row'}>
                <TextField
                  label={t('partnerProgram.form.percentage')}
                  value={state.percentage}
                  required
                  onChange={(event) =>
                    updateState({
                      action: 'updateField',
                      value: { key: 'percentage', data: event.target.value },
                    })
                  }
                  onBlur={(event) =>
                    updateState({
                      action: 'updateField',
                      value: { key: 'percentage', data: event.target.value },
                    })
                  }
                  inputProps={{
                    lang: 'en_US',
                    pattern: '[0-9]*.?[0-9]*',
                    min: 0,
                  }}
                />
                <TextField
                  label={t('partnerProgram.form.maxAmount')}
                  value={state.percentageMaxAmount}
                  required
                  onChange={onPercentageMaxAmountChange}
                  onBlur={onFormatPercentageMaxAmount}
                  inputProps={{
                    lang: 'en_US',
                    pattern: '[0-9]*.?[0-9]*',
                    min: 0,
                  }}
                />
              </Stack>
              <Select
                value={state.currency}
                onChange={(val) =>
                  updateState({
                    action: 'updateField',
                    value: { key: 'currency', data: val.target.value },
                  })
                }
                sx={{
                  minWidth: 95,
                }}
              >
                <MenuItem value={'eur'}>{t('partnerProgram.form.eur')}</MenuItem>
              </Select>
            </Stack>
          </Box>
        )}

        <Box>
          <InputLabel>{t('partnerProgram.form.expireType')}</InputLabel>
          <Box sx={{ display: 'flex' }}>
            <NumberInput
              value={state.expireInNumber}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
              onChange={(tx) =>
                updateState({
                  action: 'updateField',
                  value: { key: 'expireInNumber', data: +tx.target.value },
                })
              }
              {...inputWithEndSelectStyleOverride}
            />

            <Select
              value={state.expireInType}
              onChange={(val) =>
                updateState({
                  action: 'updateField',
                  value: { key: 'expireInType', data: val.target.value },
                })
              }
              sx={{
                borderLeft: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                minWidth: 95,
              }}
            >
              <MenuItem value={'days'}>{t('date.day')}</MenuItem>
              <MenuItem value={'weeks'}>{t('date.week')}</MenuItem>
              <MenuItem value={'months'}>{t('date.month')}</MenuItem>
              <MenuItem value={'years'}>{t('date.year')}</MenuItem>
            </Select>
          </Box>
        </Box>

        <Box>
          <InputLabel>{t('partnerProgram.form.isPrivate')}</InputLabel>
          <Box sx={{ display: 'flex' }}>
            <Switch
              checked={state.isPrivate}
              onChange={(val) =>
                updateState({
                  action: 'updateField',
                  value: { key: 'isPrivate', data: val.target.checked },
                })
              }
            />
          </Box>
        </Box>

        <Button onClick={handleSubmit} disabled={isLoading} variant="contained">
          {t('action.save')}
        </Button>
        <Button
          onClick={handleDelete}
          disabled={isLoading || partnerProgramSelected === -1 || !!state.deletedAt}
          variant="outlined"
          color="error"
        >
          {t('action.delete')}
        </Button>
      </Box>
    </Drawer>
  );
};

const inputWithEndSelectStyleOverride = {
  sx: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRight: 'none',
    },
  },
  InputProps: {
    sx: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
};
