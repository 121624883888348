import { Badge } from '@bits-app/bits-server-data';

import { authenticatedInstance } from '@/axios/axios.instance';
import { DraftBadge } from '@/voggt-database-explorer/badge/entity/draft-badge';

export const getBadgeTranslations = async (badge: Badge | DraftBadge) => {
  const result = await authenticatedInstance.get(
    `/database-explorer/badge/${badge.name}/translations`,
  );

  return result.data;
};
