import { Tooltip } from '@mui/material';
import { format } from 'date-fns';

type TooltipWithTimeProps = {
  date: string | Date | null | number;
  children: React.ReactElement;
};

export const TooltipWithTime = ({ date, children }: TooltipWithTimeProps) => {
  return (
    <Tooltip title={date && `${format(new Date(date), 'dd/MM/yyyy HH:mm')}`}>{children}</Tooltip>
  );
};
