import { useState } from 'react';

import { Button } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { parse } from 'papaparse';
import { useDropzone } from 'react-dropzone';

import { useSnackbar } from '../../components/elements/snackbar/use-snackbar';

import {
  getMassImportColumns,
  MASS_IMPORT_COLUMNS_TEMPLATE,
  resolveMassImportFeatureBasedOnCsvHeader,
  resultWithValidation,
  withErrorFromBackend,
} from './helper';
import { MassImportResolver } from './matrice';
import { AllowedFeatureForMassImport, GenericCsv } from './type';
import { useMassImportMutation } from './useMassImportMutation';

export const MassImport = () => {
  const [results, setResults] = useState<GenericCsv[]>([]);
  const snackbar = useSnackbar();

  const handleOnDrop = <T extends File>(file: T[]) => {
    parse<GenericCsv>(file[0], {
      complete(results) {
        results.errors.forEach((error) => {
          snackbar.error(error.message);
        });
        setResults(results.data);
      },
      header: true,
    });
  };

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: handleOnDrop,
    maxFiles: 1,
    accept: { 'text/csv': ['.csv'] },
  });

  const columns = getMassImportColumns(results[0]);
  const { name: featureName, key } = resolveMassImportFeatureBasedOnCsvHeader(results[0]);
  const { resultFormatted, hasError } = resultWithValidation(results, key);

  const {
    executeCommit,
    executeBackendValidation,
    hasPassValidation,
    progression,
    validationErrors,
    commitErrors,
  } = useMassImportMutation(key);

  return (
    <div className="bg-slate-100 p-4 flex flex-col gap-4 max-w-4xl mx-auto">
      <h1 className="font-bold underline mb-4 text-lg">Welcome to the new Mass Import feature</h1>
      <div className="flex flex-row gap-2 text-gray-500">
        <p>Template : </p>
        <ul className="flex flex-row gap-2">
          {(Object.keys(MASS_IMPORT_COLUMNS_TEMPLATE) as AllowedFeatureForMassImport[]).map(
            (key) => (
              <a
                key={key}
                href={window.URL.createObjectURL(
                  new Blob([MASS_IMPORT_COLUMNS_TEMPLATE[key].join(',')], {
                    type: 'text/csv',
                  }),
                )}
                download={`bulk-${key}.csv`}
                className="font-semibold cursor-pointer"
              >
                {key}
              </a>
            ),
          )}
        </ul>
      </div>
      <section className="flex flex-col gap-2 shadow-md w-full bg-white rounded-xl p-4">
        <h4 className="font-semibold">1 - First drop a file to import</h4>
        <div
          className="bg-[#fafafa] rounded-lg p-8 text-center border border-gray-300 border-dashed cursor-pointer"
          {...getRootProps()}
        >
          {isDragActive ? 'Ok, you can release your mouse now' : 'Drop your file here'}
        </div>
      </section>
      <section className="flex flex-col gap-2 shadow-md w-full bg-white rounded-xl p-4">
        <h4 className="font-semibold">2 - Visualize</h4>
        {resultFormatted.length ? (
          <MaterialReactTable
            columns={columns}
            data={withErrorFromBackend(resultFormatted, validationErrors, hasPassValidation)}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableSorting={false}
            enableTopToolbar={false}
          />
        ) : null}
      </section>

      <section className="flex flex-col gap-2 shadow-md w-full bg-white rounded-xl p-4">
        <h4 className="font-semibold">3 - Import</h4>
        {results.length ? (
          <>
            <p> Based on your input, it look like you have {results.length} rows to import</p>
            <p>
              After looking to the column, it appears you want to use{' '}
              <span className="font-bold">{featureName}</span>
            </p>
            <div className="flex justify-end gap-4">
              {key && MassImportResolver[key]?.backendValidationPath ? (
                <Button
                  variant="contained"
                  onClick={() => executeBackendValidation(results)}
                  disabled={hasPassValidation}
                >
                  Pre-validate
                </Button>
              ) : null}

              <Button
                variant="contained"
                onClick={() => executeCommit(results)}
                disabled={hasError || !hasPassValidation}
              >
                Submit ({progression}%)
              </Button>
            </div>
          </>
        ) : null}
      </section>
      {commitErrors.length ? (
        <section className="flex flex-col gap-2 shadow-md w-full bg-white rounded-xl p-4">
          <h4 className="font-semibold">4 - Errors</h4>
          {commitErrors.map((error) => (
            <div key={error.rowId} className="bg-red-100 p-2 rounded-md">
              <p>
                Row {error.rowId} : {error.errorMessage}
              </p>
            </div>
          ))}
        </section>
      ) : null}
    </div>
  );
};
