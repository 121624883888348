import { useState } from 'react';

import { OrderCancellationReason, OrderStatus, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { UpdateOrderItemsShippingStatusModal } from '@/components/UpdateOrderItemsShippingStatus.modal';
import { useOwnUser } from '@/context/own-user.context';
import { massUpdateOrderItemsShippingStatus } from '@/order-items-mass-update/use-cases';
import { useAppDispatch } from '@/redux/reduxAppHooks';
import { Order } from '@/voggt-database-explorer/order/entities/order.entity';

type EditSelectedOrdersStatusProps = {
  orderIds: Order['id'][];
  afterUpdate?: () => void;
};

export const EditSelectedOrdersStatus = ({
  orderIds,
  afterUpdate,
}: EditSelectedOrdersStatusProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { hasPermissions } = useOwnUser();

  const canMassEditOrderStatus =
    hasPermissions(PERMISSIONS.ORDER.WRITE_ORDER_CANCEL) ||
    hasPermissions(PERMISSIONS.ORDER_ITEM_MASS_UPDATE.WRITE_ORDER_ITEM_MASS_UPDATE);

  const dispatch = useAppDispatch();

  const handleUpdateOrderItemShippingStatus = async (
    shippingStatus: OrderStatus,
    cancellationReason?: OrderCancellationReason,
  ) => {
    if (!orderIds.length) {
      return;
    }

    await dispatch(
      massUpdateOrderItemsShippingStatus({
        ids: orderIds,
        shippingStatus,
        cancellationReason,
      }),
    );

    setIsOpen(false);
    afterUpdate?.();
  };

  if (!canMassEditOrderStatus) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={() => setIsOpen(true)}
        size="small"
        sx={{ '& svg': { width: 20, height: 20 } }}
      >
        <Edit />
      </IconButton>

      {isOpen && (
        <UpdateOrderItemsShippingStatusModal
          count={orderIds.length}
          onClose={() => setIsOpen(false)}
          onUpdate={handleUpdateOrderItemShippingStatus}
        />
      )}
    </>
  );
};
