export enum Currency {
  eur = 'eur',
  gbp = 'gbp',
}

export const isCurrency = (value: string | undefined): value is Currency => {
  if (!value) return false;

  return Object.values(Currency).includes(value as Currency);
};
