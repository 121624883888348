import { SellerCompany, User } from '@bits-app/bits-server-data';
import { CircularProgress } from '@mui/material';

import { validationSchemasBuilder } from '@/voggt-database-explorer';
import { UpsertForm } from '@/voggt-database-explorer/components/upsert-view/UpsertForm';

import { useCreateSellerCompany } from './use-create-seller-company';

export const SelleCompanyCreationView = ({ userId }: { userId: User['id'] }) => {
  const { header, schemas, onCreate, isLoading } = useCreateSellerCompany(userId);
  const validationSchemaBuilder = validationSchemasBuilder['Seller-Company'];

  if (!schemas.length || !header) {
    return null;
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <UpsertForm<SellerCompany>
      schemas={schemas}
      header={header}
      onUpsert={onCreate}
      goBackName={'Back'}
      validationSchemaBuilder={validationSchemaBuilder}
    />
  );
};
