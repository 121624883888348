import { Box, Grid, Typography } from '@mui/material';

import { formatDate } from '@/utils';

import { BooleanIcon } from '../../icons/BooleanIcon';
import { CellType } from '../datalist-type';

import { ChipsCell } from './ChipsCell';

type CustomCellProps = {
  value: unknown;
  type: CellType;
};

export const CustomCell = ({ type, value }: CustomCellProps) => {
  if (!value) return null;
  switch (type) {
    case 'component': {
      return <>{value}</>;
    }
    case 'color': {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: value,
                border: '1px solid',
                borderColor: ({ palette }) => palette.grey[500],
              }}
            />
          </Grid>

          <Grid item>{value as string}</Grid>
        </Grid>
      );
    }
    case 'boolean': {
      return <BooleanIcon status={value as boolean} />;
    }
    case 'date':
    case 'date-with-time': {
      const format = type === 'date' ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm';
      return <span title={value as string}>{formatDate(value as Date, format)}</span>;
    }
    case 'image': {
      return <img src={value as string} style={{ width: 50 }} />;
    }
    case 'badge': {
      return <ChipsCell value={value as string} />;
    }
    case 'money':
    default:
      return <Typography fontSize={14}>{value as string}</Typography>;
  }
};
