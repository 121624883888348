import { Column, Index } from 'typeorm';

export abstract class PreAuthorizedPaymentMethod {
  @Column({ type: 'timestamp', nullable: true })
  preAuthorizationExpireAt!: Date | null;

  @Index({ unique: true })
  @Column({ type: 'character varying', nullable: true })
  preAuthorizationStripePaymentIntentId!: string | null;

  @Column({ type: 'timestamp', nullable: true })
  preAuthorizationCreatedAt!: Date;

  @Column({ type: 'int4', nullable: true })
  preAuthorizationAmount!: number | null;

  @Column({ type: 'timestamp', nullable: true })
  preAuthorizationDeletedAt!: Date | null;
}
