import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { selectOrderItemsMassUpdateFilterLoadingFor } from '@/order-items-mass-update/redux/order-items-mass-update.selectors';
import { useUsernameFilter } from '@/order-items-mass-update/ui/table/filters/SellerBuyerIntersectionFilter/hooks/use-username-filter';
import { useAppSelector } from '@/redux/reduxAppHooks';
import { InfiniteScrollContainer } from '@Elements/InfiniteScrollContainer';

import { UsernameFilterProps } from './SellerBuyerIntersectionFilter';

export const UsernameFilter = ({
  selected,
  setSelected,
  inputPlaceholder,
  field,
}: UsernameFilterProps) => {
  const { t } = useTranslation();

  const loading = useAppSelector((state) =>
    selectOrderItemsMassUpdateFilterLoadingFor(state, field),
  );

  const {
    value,
    onChange,
    options,
    noResults,
    onToggle,
    onValuesReachEnd,
    infiniteScrollContainerRef,
  } = useUsernameFilter({ field, selected, setSelected });

  return (
    <div>
      <Box p={2}>
        <TextField
          variant="standard"
          value={value}
          onChange={onChange}
          placeholder={inputPlaceholder}
          InputProps={{
            endAdornment: loading ? <CircularProgress size={16} /> : <Box width={16} />,
          }}
        />
      </Box>

      {options.length > 0 ? (
        <InfiniteScrollContainer
          maxHeight={200}
          onReachEnd={onValuesReachEnd}
          ref={infiniteScrollContainerRef}
        >
          <List>
            {options.map((id) => (
              <ListItemButton key={id} onClick={onToggle(id)} dense>
                <ListItemIcon>
                  <Radio edge="start" checked={selected === id} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={id} />
              </ListItemButton>
            ))}
            {loading && (
              <ListItem>
                <CircularProgress size={20} />
              </ListItem>
            )}
          </List>
        </InfiniteScrollContainer>
      ) : (
        noResults && !loading && <Box p={2}>{t('noEntries', { field })}</Box>
      )}
    </div>
  );
};
