import { forwardRef, useState, useImperativeHandle } from 'react';

import { Button, Grid } from '@mui/material';
import { FileError } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';
import { ImageUpload } from '@/voggt-database-explorer/components/ImageUpload/ImageUpload';
import { useImageUpload } from '@/voggt-database-explorer/components/ImageUpload/use-image-upload.hook';

import { Panel } from '../layouts/Panel';

export type EditorRef = {
  clearImage: () => void;
  close: () => void;
  setImageErrors: (errors: FileError[]) => void;
};

type PanelDetailImageProps = {
  title?: string;
  thumbnailUrl?: string;
  images?: string[];
  alt?: string;
  onEdit?: (image: File) => void;
  maxSize?: number;
  imageAcceptedMimeTypes?: Record<string, string[]>;
};

export const PanelDetailImage = forwardRef<EditorRef, PanelDetailImageProps>(
  ({ title, onEdit, thumbnailUrl, alt, images = [], imageAcceptedMimeTypes, maxSize }, ref) => {
    const [isEditing, setIsEditing] = useState(false);

    const { t } = useTranslation();

    const { image, imageErrors, onDropImage, clearImage, setImageErrors } = useImageUpload('10Mb');

    const handleEdit = () => image && onEdit?.(image);

    useImperativeHandle(
      ref,
      () => {
        return {
          close: () => setIsEditing(false),
          clearImage,
          setImageErrors,
        };
      },
      [clearImage, setImageErrors],
    );

    const onClose = () => {
      setIsEditing(false);
      clearImage();
      setImageErrors([]);
    };

    const firstImage = images?.[0];
    const url = thumbnailUrl || firstImage;

    return (
      <Panel
        title={title || t('databaseExplorer.panel.image')}
        onClickOnAction={() => setIsEditing(true)}
        actionName="Edit"
        skipPadding
      >
        <>
          {isEditing ? (
            <Grid container gap={2} width="100%" justifyContent="center">
              <Grid item flex={1}>
                <ImageUpload
                  maxSize={maxSize}
                  imageAcceptedMimeTypes={imageAcceptedMimeTypes}
                  currentImageUrl={url}
                  imageErrors={imageErrors}
                  onDropImage={onDropImage}
                  image={image}
                />
              </Grid>

              <Grid item container gap={3} justifyContent="flex-end">
                <ButtonOutlined onClick={onClose}>{t('cancel')}</ButtonOutlined>
                <Button
                  variant="contained"
                  onClick={handleEdit}
                  disabled={Boolean(imageErrors?.length) || !image}
                >
                  {t('action.save')}
                </Button>
              </Grid>
            </Grid>
          ) : url ? (
            <img
              className="w-full h-full object-contain max-h-80 bg-slate-100"
              src={url}
              alt={alt}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-48 bg-slate-100">
              No image
            </div>
          )}
        </>
      </Panel>
    );
  },
);
