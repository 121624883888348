import { useState } from 'react';

import { Badge } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { useOwnUser } from '@/context/own-user.context';
import { DraftBadge } from '@/voggt-database-explorer/badge/entity/draft-badge';
import { deleteBadge } from '@/voggt-database-explorer/badge/use-cases/delete-badge';

export const useDeleteBadge = (isDraft?: boolean) => {
  const { hasPermissions } = useOwnUser();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const ownUserIsAllowedToDeleteBadge = hasPermissions(
    PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_BADGE,
  );

  const { mutate, isLoading } = useMutation<void, unknown, Badge['id'] | DraftBadge['id']>({
    mutationFn: (id) => deleteBadge(id, isDraft),
    onSuccess(_, id) {
      snackbar.success('databaseExplorer.badge.delete.success', {
        interpolationMap: { badge: id },
      });

      onClose();
      navigate('/badge');
    },
    onError() {
      snackbar.error('databaseExplorer.badge.delete.error');
    },
  });

  return {
    isOpen,
    onOpen,
    onClose,
    onDeleteBadge: (id: Badge['id'] | DraftBadge['id']) => mutate(id),
    ownUserIsAllowedToDeleteBadge,
    isLoading,
  };
};
