import { User } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '../../../axios/axios.instance';
import { useSnackbar } from '../../../components/elements/snackbar/use-snackbar';
import { useOwnUser } from '../../../context/own-user.context';
import { QUERIES_KEYS } from '../../../queries';

export const useUserDetail = (userId: number) => {
  const { hasPermissions } = useOwnUser();
  const snackbar = useSnackbar();

  const basicCallback = {
    onError: () => {
      snackbar.error('somethingWrongHappened');
    },
  };

  const userQuery = useQuery(
    QUERIES_KEYS.userDetail(userId),
    () => authenticatedInstance.get<User>(`/database-explorer/customer/${userId}`),
    basicCallback,
  );

  const zohoQuery = useQuery(
    QUERIES_KEYS.getZohoUserLink(Number(userId)),
    () => authenticatedInstance.get<string>(`/zoho/${userId}/zoho-user-link`),
    { enabled: hasPermissions(PERMISSIONS.USER.READ_USER_ZOHO_LINK) },
  );

  return {
    user: userQuery.data?.data,
    zohoUrl: zohoQuery.data?.data,
    isLoading: userQuery.isLoading,
  };
};
