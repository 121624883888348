import { useState, useEffect, useMemo } from 'react';

import { ShippingAddress } from '@bits-app/bits-server-data';
import { Schema } from '@bits-app/voggtpit-shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { authenticatedInstance } from '@/axios/axios.instance';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { QUERIES_KEYS } from '@/queries';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export const useCreateShippingAddress = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [error, setError] = useState<string | null>(null);

  const { data: creationSchema = [] } = useQuery(
    QUERIES_KEYS.getCreationShippingAddressSchema(),
    getCreationShippingAddressSchema,
  );

  const { mutate } = useMutation(createShippingAddress, {
    onSuccess: ({ shippingAddressCreatedId, ordersNotUpdated }) => {
      if (ordersNotUpdated.length) {
        snackbar.warning(
          t('databaseExplorer.shippingAddress.create.ordersNotUpdated', { ordersNotUpdated }),
        );
      } else {
        snackbar.success(t('databaseExplorer.shippingAddress.create.success'));
      }
      navigate(`/shipping-address/${shippingAddressCreatedId}`);
    },
    onError: (error: AxiosError) => {
      if (error instanceof CanceledRequestError) {
        setError(null);
        return;
      }
      snackbar.error(error.message);
    },
  });

  useEffect(() => {
    if (error) {
      snackbar.error(error);
    }
  }, [error, snackbar]);

  const schemas = useMemo<Schema<ShippingAddress>[]>(() => {
    return creationSchema.map((schema) => ({
      ...schema,
      categoryLabel: t('databaseExplorer.shippingAddress.details'),
    }));
  }, [creationSchema, t]);

  return {
    header: {
      title: t('databaseExplorer.shippingAddress.create.action'),
    },
    schemas,
    onSubmit: mutate,
  };
};

export type CreateShippingAddressParams = {
  originalShippingAddressId: ShippingAddress['id'];
  shippingAddress: Partial<ShippingAddress>;
};

const createShippingAddress = async ({
  originalShippingAddressId,
  shippingAddress,
}: CreateShippingAddressParams) => {
  const requestResponse = await authenticatedInstance.post(
    `/database-explorer/shipping-address/${originalShippingAddressId}`,
    shippingAddress,
  );
  return requestResponse.data;
};

const getCreationShippingAddressSchema = async () => {
  const requestResponse = await authenticatedInstance.get<Schema<ShippingAddress>[]>(
    '/database-explorer/shipping-address/creation-schema',
  );
  return requestResponse.data;
};
