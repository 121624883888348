import { AxiosError } from 'axios';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { AppThunkAction } from '@/redux/store';

import { selectSellerCancelRequestsInRefund } from '../redux/seller-cancel-requests.selectors';
import { sellerCancelRequestsActions } from '../redux/seller-cancel-requests.slice';

export const getSellerCancelRequestsRefundSummary =
  (): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { sellerCancelRequestsGateway, snackbarGateway }) => {
    dispatch(sellerCancelRequestsActions.setRefundSummaryLoading(true));
    try {
      const orderItemsId = selectSellerCancelRequestsInRefund(getState());

      if (orderItemsId.length === 0) {
        return;
      }

      const summary =
        await sellerCancelRequestsGateway.getSellerCancelRequestsSummary(orderItemsId);

      dispatch(sellerCancelRequestsActions.setRefundSummary(summary));
      dispatch(sellerCancelRequestsActions.setRefundSummaryError(null));
    } catch (error) {
      dispatch(sellerCancelRequestsActions.setRefundSummaryError(String(error)));

      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      if (error instanceof AxiosError) {
        if (error.response?.data?.message) {
          snackbarGateway.error('refund.error', { subText: error.response?.data.message });

          return;
        }
      }

      snackbarGateway.error('refund.error');
    } finally {
      dispatch(sellerCancelRequestsActions.setRefundSummaryLoading(false));
    }
  };
