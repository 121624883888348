import { OrderItemsDistinctValuesFindOptions } from '@bits-app/voggtpit-shared';

import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import logger from '@/logger/logger';
import { AppThunkAction } from '@/redux/store';

import { selectOrderItemsMassUpdateFilterCountFor } from '../redux/order-items-mass-update.selectors';
import { orderItemsMassUpdateActions } from '../redux/order-items-mass-update.slice';

const LIMIT = 100;

export const getOrderItemsNextPageFilterValuesFor =
  ({
    field,
    search,
    page,
    limit = LIMIT,
  }: {
    field: OrderItemsDistinctValuesFindOptions['field'];
    search: OrderItemsDistinctValuesFindOptions['search'];
    page: OrderItemsDistinctValuesFindOptions['page'];
    limit?: OrderItemsDistinctValuesFindOptions['limit'];
  }): AppThunkAction<Promise<void>> =>
  async (dispatch, getState, { orderItemsMassUpdateGateway, snackbarGateway }) => {
    if (search.length < 2) {
      return;
    }

    const count = selectOrderItemsMassUpdateFilterCountFor(getState(), field);

    if (count !== 0 && page * limit >= count) {
      return;
    }

    dispatch(orderItemsMassUpdateActions.setFiltersLoading({ field: field, loading: true }));

    try {
      const result = await orderItemsMassUpdateGateway.getFiltersValueFor({
        field,
        search,
        limit,
        page,
      });

      dispatch(
        orderItemsMassUpdateActions.addFiltersValues({ field: field, values: result.values }),
      );
    } catch (error) {
      logger.error(error);

      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      snackbarGateway.error('somethingWrongHappened');
    } finally {
      dispatch(orderItemsMassUpdateActions.setFiltersLoading({ field: field, loading: false }));
    }
  };
