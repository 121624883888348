import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OwnUser } from '@/domain/entities/own-user.entity';
import { RootState } from '@/redux/store';

type AuthState = {
  ownUser: OwnUser | null;
  ownUserLoading: boolean;
  authLoading: boolean;
  shouldReLog: boolean;
  zendeskLoading: boolean;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    ownUser: null,
    ownUserLoading: true,
    authLoading: false,
    shouldReLog: false,
    zendeskLoading: false,
  } as AuthState,
  reducers: {
    setAuthLoading: (state, { payload: loading }: PayloadAction<boolean>) => {
      state.authLoading = loading;
    },
    setOwnUserLoading: (state, { payload: loading }: PayloadAction<boolean>) => {
      state.ownUserLoading = loading;
    },
    setOwnUser: (state, { payload: ownUser }: PayloadAction<OwnUser | null>) => {
      state.ownUser = ownUser;
    },
    setShouldReLog: (state, { payload }: PayloadAction<AuthState['shouldReLog']>) => {
      state.shouldReLog = payload;
    },
    setZendeskLoading: (state, { payload: loading }: PayloadAction<boolean>) => {
      state.zendeskLoading = loading;
    },
  },
});

const authSelector = (state: RootState) => state.auth;

export const selectOwnUser = createSelector(authSelector, (auth: AuthState) => auth.ownUser);

export const selectOwnUserLoading = createSelector(
  authSelector,
  (auth: AuthState) => auth.ownUserLoading,
);
export const selectAuthLoading = createSelector(
  authSelector,
  (auth: AuthState) => auth.authLoading,
);
export const selectShouldReLog = createSelector(
  authSelector,
  (auth: AuthState) => auth.shouldReLog,
);
export const selectZendeskLoading = createSelector(
  authSelector,
  (auth: AuthState) => auth.zendeskLoading,
);

export const authActions = authSlice.actions;
export default authSlice.reducer;
