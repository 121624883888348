import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';

import { AddFilterFunctionParams } from '@/hooks/use-table-options';

import { FilterButton } from '../../../FilterButton';

import { useSearchValueFilter } from './use-search-value-filter';

export interface SearchValueFilterProps {
  activeFilters?: string;
  inputPlaceholder: string;
  addFilter: (filter: AddFilterFunctionParams) => void;
  field: AddFilterFunctionParams['field'];
}

export const SearchValueFilter = ({
  activeFilters = '',
  inputPlaceholder,
  addFilter,
  field,
}: SearchValueFilterProps) => {
  const { onClose, onAddOption, value, onChange, clearFilter } = useSearchValueFilter({
    addFilter,
    field,
    defaultValue: activeFilters,
  });

  return (
    <FilterButton isActive={activeFilters.length > 0} onClose={onClose} unSaved={false}>
      <Box component="form" onSubmit={onAddOption} p={2}>
        <TextField
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={inputPlaceholder}
        />

        {activeFilters?.length > 0 && (
          <Box display="flex" justifyContent="end" sx={{ width: '100%' }}>
            <Button onClick={clearFilter} variant="contained" color="primary" sx={{ my: 2 }}>
              Clear filter
            </Button>
          </Box>
        )}
      </Box>
    </FilterButton>
  );
};
