import { useEffect, useState } from 'react';

import {
  currencySymbol,
  formatAmount,
  mapRefundReasonValueToKey,
  RefundDebitedAccount,
} from '@bits-app/voggtpit-shared';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/redux/reduxAppHooks';
import { LoadingButton } from '@Elements/buttons/LoadingButton';

import { selectRefundFormLoading, selectRefundSummary } from '../../redux/refund.selectors';

type ConfirmRefundDialogProps = {
  onClose: () => void;
  onConfirm: (toBuyer: boolean, toSeller: boolean) => void;
};

export const ConfirmRefundDialog = ({ onClose, onConfirm }: ConfirmRefundDialogProps) => {
  const [toBuyer, setToBuyer] = useState(true);
  const [toSeller, setToSeller] = useState(true);

  const { t } = useTranslation();

  const refundSummary = useAppSelector(selectRefundSummary);
  const isLoading = useAppSelector(selectRefundFormLoading);

  const areAllGiveaways = refundSummary?.amount === 0;

  useEffect(() => {
    if (!refundSummary) {
      onClose();
    }

    if (refundSummary?.debitedAccount === RefundDebitedAccount.platform) {
      setToSeller(false);
    }
  }, [onClose, refundSummary]);

  useEffect(() => {
    if (areAllGiveaways) {
      setToBuyer(false);
      setToSeller(false);
    }
  }, [areAllGiveaways]);

  if (!refundSummary) {
    return null;
  }

  return (
    <Dialog
      open
      PaperProps={{
        sx: {
          minWidth: 400,
        },
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between" color="common.black">
        {t('refund.confirmation.refundConfirm')}

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Divider
          sx={{
            borderColor: ({ palette }) => palette.common.black,
          }}
        />
      </DialogContent>

      <DialogContent>
        <Row>
          <Typography>{t('orderItemLabels.buyer')}</Typography>
          <Typography>{refundSummary.buyer}</Typography>
        </Row>

        <Row>
          <Typography>{t('orderItemLabels.seller')}</Typography>
          <Typography>{refundSummary.seller}</Typography>
        </Row>

        <Row>
          <Typography>{t('orderItemLabels.refundReason')}</Typography>
          <Typography>
            {refundSummary.refundReason
              ? t(
                  `orderItemLabels.refundReasons.${
                    mapRefundReasonValueToKey[refundSummary.refundReason]
                  }`,
                )
              : ''}
          </Typography>
        </Row>

        <Row>
          <Typography>{t('orderItemLabels.amount')}</Typography>
          <Typography>
            {formatAmount(refundSummary.amount)} {currencySymbol(refundSummary.currency)}
          </Typography>
        </Row>

        <Row>
          <Typography>{t('orderItemLabels.debitedAccount')}</Typography>
          <Typography>
            {refundSummary.debitedAccount
              ? t(`orderItemLabels.debitedAccounts.${refundSummary.debitedAccount}`)
              : ''}
          </Typography>
        </Row>

        <Row
          display="flex"
          mt={3}
          p={1}
          sx={{
            borderTop: ({ palette }) => `1px dashed ${palette.grey[400]}`,
          }}
        >
          <Typography sx={{ paddingTop: ({ spacing }) => spacing(1) }}>
            {t('refund.confirmation.emailsSent.to')}
          </Typography>
          <FormGroup>
            <FormControlLabel
              sx={{
                marginRight: 0,
              }}
              control={
                <Checkbox
                  checked={toBuyer}
                  onChange={(event) => setToBuyer(event.target.checked)}
                  disabled={areAllGiveaways}
                />
              }
              label={t('refund.confirmation.emailsSent.buyer')}
            />
            <FormControlLabel
              sx={{
                marginRight: 0,
              }}
              control={
                <Checkbox
                  checked={toSeller}
                  onChange={(event) => setToSeller(event.target.checked)}
                  disabled={areAllGiveaways}
                />
              }
              label={t('refund.confirmation.emailsSent.seller')}
            />
          </FormGroup>
        </Row>

        <Typography sx={{ color: (theme) => theme.palette.warning.main }}>
          {t('refund.confirmation.giveawaysExcludedFromEmail')}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          padding: ({ spacing }) => spacing(3),
          paddingTop: ({ spacing }) => spacing(1),
        }}
      >
        <Box>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={() => onConfirm(toBuyer, toSeller)}
          >
            {t('refund.confirmation.confirm')}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 4,
  marginBottom: theme.spacing(1),
}));
