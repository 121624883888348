import { useState } from 'react';

import { OrderCancellationReason, PERMISSIONS } from '@bits-app/voggtpit-shared';

import { useOwnUser } from '@/context/own-user.context';
import { useAppDispatch } from '@/redux/reduxAppHooks';
import { Order } from '@/voggt-database-explorer/order/entities/order.entity';
import { cancelOrder } from '@/voggt-database-explorer/use-cases';

export const useCancelOrder = (orderId: Order['id']) => {
  const [open, setOpen] = useState(false);

  const { hasPermissions } = useOwnUser();
  const isAllowedToCancelOrder = hasPermissions(PERMISSIONS.ORDER.WRITE_ORDER_CANCEL);

  const dispatch = useAppDispatch();

  const handleCancelOrder = async (cancellationReason: OrderCancellationReason | null) => {
    if (!cancellationReason) {
      return;
    }

    await dispatch(cancelOrder(orderId, cancellationReason));
    setOpen(false);
  };

  return {
    isAllowedToCancelOrder,
    openCancelOrder: open,
    onOpenCancelOrder: () => setOpen(true),
    onCancelOrder: handleCancelOrder,
    closeCancelOrder: () => setOpen(false),
  };
};
