import { useEffect } from 'react';

import { Language, User } from '@bits-app/bits-server-data';

import { Information } from '@/components/layouts/PanelDetails';
import { useAppDispatch, useAppSelector } from '@/redux/reduxAppHooks';
import { selectResourceLoading } from '@/voggt-database-explorer/redux/database-explorer.slice';
import {
  getResource,
  selectResource,
  useResourceSelector,
} from '@/voggt-database-explorer/redux/use-cases/get-resource';

export const useLanguageDetailsView = (languageId: string) => {
  const dispatch = useAppDispatch();

  const language = useResourceSelector<Language & { users: User[] }>(selectResource);
  const loading = useAppSelector(selectResourceLoading);

  useEffect(() => {
    if (languageId && language.id !== Number(languageId)) {
      dispatch(getResource(+languageId, 'Language'));
    }
  }, [languageId, language.id, dispatch]);

  return {
    language,
    loading,
    panelDetailsLanguage,
  };
};

const panelDetailsLanguage: Information<Language>[] = [
  { value: 'id', label: 'id' },
  { value: 'code', label: 'code' },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
];
