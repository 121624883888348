import { useState, useEffect, useMemo } from 'react';

import { ShippingFeeConfig, ShippingCategory } from '@bits-app/bits-server-data';
import { Schema } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { authenticatedInstance } from '@/axios/axios.instance';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';
import { useGetCountries } from '@/queries';
import { useSnackbar } from '@Elements/snackbar/use-snackbar';

export const useCreateShippingFeeConfig = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [error, setError] = useState<string | null>(null);

  const { data: countries = [] } = useGetCountries();

  useEffect(() => {
    if (error) {
      snackbar.error(error);
    }
  }, [error, snackbar]);

  const schemas = useMemo<Schema<ShippingFeeConfig>[]>(() => {
    return countries.length === 0
      ? []
      : [
          {
            categoryLabel: '',
            fullWidth: true,
            schema: [
              {
                field: 'destinationCountryId',
                label: 'Destination country',
                inputType: 'select',
                options: countries.map(({ id, iso2Code }) => ({
                  label: iso2Code,
                  value: id,
                })),
                value: 'NULL',
              },
              {
                field: 'shippingCountryId',
                label: 'Shipping country',
                inputType: 'select',
                options: countries.map(({ id, iso2Code }) => ({
                  label: iso2Code,
                  value: id,
                })),
                value: 'NULL',
              },
              {
                field: 'intervalCount',
                label: 'Interval count',
                inputType: 'number',
                value: 0,
              },
              {
                field: 'shippingCategory',
                label: 'Shipping category',
                inputType: 'select',
                options: Object.values(ShippingCategory).map((value) => ({
                  value,
                  label: value,
                })),
              },
              {
                field: 'fixedFee',
                label: 'Fixed fee  (in cents)',
                inputType: 'number',
              },
              {
                field: 'intervalFee',
                label: 'Interval fee  (in cents)',
                inputType: 'number',
              },
            ],
          },
        ];
  }, [countries]);

  const onCreate = (shippingFeeConfig: Partial<ShippingFeeConfig>) => {
    authenticatedInstance
      .post('/database-explorer/shipping-fee-config', shippingFeeConfig)
      .then(() => {
        snackbar.success(t('databaseExplorer.shippingFeeConfig.create.success'));
        navigate(-1);
      })
      .catch((error) => {
        if (error instanceof CanceledRequestError) {
          setError(null);
          return;
        }

        setError(error ?? 'error');
      });
  };

  return {
    header: {
      title: t('databaseExplorer.shippingFeeConfig.create.action'),
    },
    schemas,
    onCreate,
  };
};
