import { useEffect, useState } from 'react';

import { Badge, User } from '@bits-app/bits-server-data';

import { authenticatedInstance } from '@/axios/axios.instance';
import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { CanceledRequestError } from '@/domain/errors/CancelRequestError';

type BadgeRelatedUserViewProps = {
  badgeId: Badge['id'];
  cellDefinition: CellDefinition<User>[];
};

export const BadgeRelatedUserView = ({ badgeId, cellDefinition }: BadgeRelatedUserViewProps) => {
  const [users, setUsers] = useState([]);
  const snackbar = useSnackbar();

  useEffect(() => {
    const controller = new AbortController();
    authenticatedInstance
      .get(`/database-explorer/customer/badge/${badgeId}`, { signal: controller.signal })
      .then((result) => {
        setUsers(result.data);
      })
      .catch((error) => {
        if (error instanceof CanceledRequestError) {
          return;
        }
        snackbar.error('databaseExplorer.badge.relatedUsers.error');
      });

    return () => controller.abort();
  }, [badgeId, snackbar]);

  return (
    <ListCustom<User> key="user" cellDefinition={cellDefinition} results={users} enableFilters />
  );
};
