import { useCallback, useEffect, useState } from 'react';

import {
  TransferReason,
  TransferFundType,
  PERMISSIONS,
  TransferFundPayload,
} from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import chunk from 'lodash/chunk';
import { parse as parseCsv } from 'papaparse';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { useOwnUser } from '@/context/own-user.context';

import {
  checkIfAllRowsAreValid,
  CsvType,
  formatRow,
  _checkUsersBalance,
  processAllTransfer,
} from './bulk-direct-fund-helpers';

export const BULK_TRANSFER_NUMBER_PER_CALL = 10;

export const TRANSFER_FUND_TYPE_OPTIONS: TransferFundType[] = ['bank-account', 'stripe-account'];

export const useBulkDirectFundTransfer = (transferFundType: TransferFundType) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [pendingFile, setPendingFile] = useState<TransferFundPayload[]>([]);
  const [checkBalanceIsDone, setCheckBalanceIsDone] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pointer, setPointer] = useState(1);

  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();

  const toggleModal = useCallback(() => setModalIsOpen((prev) => !prev), []);

  const userIsAllowed = hasPermissions(
    PERMISSIONS.DIRECT_FUND_TRANSFER.WRITE_DIRECT_FUND_TRANSFER_UNIT,
  );

  const snackbar = useSnackbar();

  const { mutate: checkUsersBalance, isLoading: checkUserBalanceIsLoading } = useMutation(
    _checkUsersBalance,
    {
      onSuccess: ({ data }) => {
        const allUsersHaveEnoughBalance = data.every((user) => user.isCompatible === true);
        if (allUsersHaveEnoughBalance) {
          setCheckBalanceIsDone(true);
          return;
        }
        const usersWithNotEnoughBalance = data
          .map((user) => user.isCompatible || `User ${user.accountId} not found`)
          .filter((isCompatible) => {
            if (typeof isCompatible !== 'string') {
              return false;
            }

            const isTransferToBankAccount = transferFundType === 'stripe-account';

            const shouldIgnoreError =
              isTransferToBankAccount && isCompatible.match(/User balance of (\d*) is negative/);

            if (shouldIgnoreError) {
              return false;
            }

            return true;
          })
          .join(' - ');
        setCheckBalanceIsDone(true);
        setError(usersWithNotEnoughBalance);
      },
      onError: (error: AxiosError) => {
        setError(error.message);
      },
    },
  );

  const { mutateAsync: startProcess, isLoading: processAllTransferIsLoading } = useMutation(
    processAllTransfer,
    {
      onSuccess: ({ data: potentialErrors }) => {
        const processGetSomeError = potentialErrors.length > 0;
        if (!processGetSomeError) {
          snackbar.success('databaseExplorer.users.paymentDirectTransfer.bulk.success');
        }
        setError(
          potentialErrors
            .map((result) =>
              typeof result.error === 'string'
                ? result.error
                : `Transfer failed for ${result.transfer.userId}`,
            )
            .join(';'),
        );
        return;
      },
      onError: (error: AxiosError) => {
        setError(error.message);
      },
    },
  );

  const onDropFile = useCallback((files: File[]) => {
    const [file] = files;
    const reader = new FileReader();

    reader.onloadstart = () => {
      setError(null);
      setCheckBalanceIsDone(false);
    };

    reader.onload = () => {
      const { result } = reader;
      if (typeof result === 'string') {
        const { data: rows } = parseCsv<CsvType>(result, { header: true });

        if (rows.length === 0) {
          setError(t('databaseExplorer.users.paymentDirectTransfer.bulk.emptyFile'));
          reader.dispatchEvent(new Event('error'));
          return;
        }

        const allRowsAreValid = checkIfAllRowsAreValid(rows);

        if (!allRowsAreValid) {
          setError(t('databaseExplorer.users.paymentDirectTransfer.bulk.invalidFile'));
          reader.dispatchEvent(new Event('error'));
          return;
        }

        const formattedRows = formatRow(rows);

        setPendingFile(formattedRows);
      }
    };
    reader.readAsText(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!pendingFile.length) return;
    checkUsersBalance({ users: pendingFile.map((row) => row.userId) });
  }, [checkUsersBalance, pendingFile]);

  const process = async ({
    transferReason,
    transferType,
    relatedMonth,
  }: {
    transferType: TransferFundType;
    transferReason: TransferReason;
    relatedMonth?: string;
  }) => {
    const chunkedPendingFile = chunk(pendingFile, BULK_TRANSFER_NUMBER_PER_CALL);
    let i = 1;
    for (const chunk of chunkedPendingFile) {
      try {
        await startProcess({
          payload: chunk,
          transferType,
          transferReason,
          relatedMonth,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setPointer(i++);
      }
    }
  };

  return {
    modalIsOpen,
    toggleModal,
    error,
    userIsAllowed,
    onDropFile,
    checkBalanceIsDone,
    startProcess: (formInformation: {
      transferType: TransferFundType;
      transferReason: TransferReason;
      relatedMonth?: string;
    }) => process(formInformation),
    isLoading: processAllTransferIsLoading || checkUserBalanceIsLoading,
    pointer,
    totalLength: pendingFile.length,
  };
};
