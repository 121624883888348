import { CommercialEvent } from '@bits-app/bits-server-data';

import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';

import { cellDefinitionCommercialEvent } from '../const';
import { useListCommercialEvent } from '../hooks/use-list-commercial-event';

import { CreateCommercialEventButton } from './create/CreateCommercialEvent';

export const ListCommercialEvent = () => {
  const { data } = useListCommercialEvent();

  return (
    <div className="h-full flex flex-col flex-1 p-4">
      <ListCustom<CommercialEvent>
        cellDefinition={cellDefinitionCommercialEvent}
        results={data ?? []}
        getPathDetail={(commercialEvent) => `/commercial-event/${commercialEvent.id}`}
        enableFilters
        enableSorting
      >
        <CreateCommercialEventButton />
      </ListCustom>
    </div>
  );
};
