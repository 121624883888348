import { ShippingFeeConfig } from '@bits-app/bits-server-data';
import { Schema } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';

import { validationSchemasBuilder } from '@/voggt-database-explorer';
import { UpsertForm } from '@/voggt-database-explorer/components/upsert-view/UpsertForm';
import { EntityWrapper } from '@/voggt-database-explorer/redux/database-explorer';

import { useCreateShippingFeeConfig } from './use-create-shipping-fee-config';

export type CreateShippingFeeConfig = Pick<
  ShippingFeeConfig,
  'destinationCountryId' | 'shippingCountryId' | 'fixedFee' | 'intervalFee' | 'intervalCount'
>;

export const ShippingFeeConfigCreationView = () => {
  const { t } = useTranslation();

  const { header, schemas, onCreate } = useCreateShippingFeeConfig();
  const validationSchemaBuilder = validationSchemasBuilder['Shipping-Fee-Config'];

  const goBackName = t('databaseExplorer.menu.shipping-fee-config');

  if (!schemas.length || !header) {
    return null;
  }

  return (
    <UpsertForm<ShippingFeeConfig>
      schemas={schemas as Schema<EntityWrapper>[]}
      header={header}
      validationSchemaBuilder={validationSchemaBuilder}
      onUpsert={onCreate}
      goBackName={goBackName}
    />
  );
};
