import {
  Entity,
  PrimaryGeneratedColumn,
  Column,
  ManyToOne,
  JoinColumn,
  ManyToMany,
  JoinTable,
} from 'typeorm';

import { FeaturedShowApplication } from './FeaturedShowApplication';
import { ParentCategory } from './ParentCategory';

@Entity()
export class FeaturedShowConcept {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column()
  name!: string;

  @ManyToOne(() => ParentCategory)
  @JoinColumn({ name: 'parentCategoryId' })
  parentCategory!: ParentCategory | null;

  @Column({ nullable: true })
  parentCategoryId!: ParentCategory['id'] | null;

  @ManyToMany(() => FeaturedShowApplication, (fsa) => fsa.concepts)
  @JoinTable({ name: 'featured_show_application_concept' })
  featuredShowApplications!: FeaturedShowApplication[];
}
