import { useEffect, useState } from 'react';

import {
  PERMISSIONS,
  TransferReason,
  TransferReasonTranslation,
  TransferFundPayload,
} from '@bits-app/voggtpit-shared';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Loader } from '@/components/elements/Loader';
import { ButtonOutlined } from '@/components/elements/buttons/ButtonOutlined';
import { useOwnUser } from '@/context/own-user.context';

import {
  BULK_TRANSFER_NUMBER_PER_CALL,
  TRANSFER_FUND_TYPE_OPTIONS,
  useBulkDirectFundTransfer,
} from '../../hooks/bulk-direct-fund/use-bulk-direct-fund-transfer';

export const BulkTransferFundModal = () => {
  const { handleSubmit, control, watch, register } = useForm<
    Pick<TransferFundPayload, 'transferReason' | 'transferType' | 'relatedMonth'>
  >({
    defaultValues: {
      transferReason: TransferReason.careCommercialGesture,
      transferType: 'stripe-account',
      relatedMonth: undefined,
    },
  });

  const [fileName, setFileName] = useState<string | null>(null);

  const {
    toggleModal,
    modalIsOpen,
    onDropFile,
    checkBalanceIsDone,
    startProcess,
    isLoading,
    error,
    pointer,
    totalLength,
  } = useBulkDirectFundTransfer(watch('transferType'));

  const submit = handleSubmit(startProcess);

  const { hasPermissions } = useOwnUser();

  const { t } = useTranslation();

  const theme = useTheme();
  const { getRootProps, acceptedFiles } = useDropzone({
    onDrop: onDropFile,
    maxFiles: 1,
    accept: { 'text/csv': ['.csv'] },
  });

  const ownUserIsAllowedToBulkTransferFund = hasPermissions(
    PERMISSIONS.DIRECT_FUND_TRANSFER.WRITE_DIRECT_FUND_TRANSFER_BULK,
  );

  const csvTemplateUrl = window.URL.createObjectURL(
    new Blob(['userId,amount,description,currency,orderId,shipmentId,showId'], {
      type: 'text/csv',
    }),
  );

  useEffect(() => {
    if (!modalIsOpen) {
      setFileName(null);
      return;
    }
    if (acceptedFiles.length === 0) {
      setFileName(null);
    } else {
      setFileName(acceptedFiles[0].name);
    }
  }, [acceptedFiles, modalIsOpen]);

  if (!ownUserIsAllowedToBulkTransferFund) {
    return null;
  }

  const submitButtonIsDisabled = !checkBalanceIsDone || isLoading || Boolean(error);

  const shouldFillRelatedMonth = [
    TransferReason.variableBonus,
    TransferReason.fixedBonus,
    TransferReason.commissionCorrection,
    TransferReason.financialAdjustement,
    TransferReason.marketingBuyersActivation,
    TransferReason.marketingCampaigns,
    TransferReason.commercialCampaigns,
  ].includes(watch('transferReason'));

  return (
    <>
      <button
        className="text-slate-900 bg-slate-200 hover:bg-slate-400 focus:ring-2 focus:outline-none focus:ring-sky-300 font-semibold rounded text-xs px-3 py-1.5 text-center inline-flex items-center"
        onClick={toggleModal}
      >
        {t('databaseExplorer.users.paymentDirectTransfer.bulk.action')}
      </button>

      <Dialog open={modalIsOpen} onClose={toggleModal} PaperProps={{ sx: { width: '50%' } }}>
        <DialogTitle display="flex" justifyContent="space-between" color="common.black">
          {t('databaseExplorer.users.paymentDirectTransfer.bulk.title')}

          <IconButton size="small" color="inherit" onClick={toggleModal}>
            <CloseIcon color="inherit" />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
          <Box
            {...getRootProps()}
            sx={{
              backgroundColor: theme.palette.grey[200],
              width: '100%',
              height: '50%',
              minHeight: 100,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            borderRadius={4}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {t('databaseExplorer.users.paymentDirectTransfer.bulk.draganddropzone')}
                {fileName ? (
                  <Typography variant="body2" color={({ palette }) => palette.text.secondary}>
                    {fileName}
                  </Typography>
                ) : null}
              </>
            )}
          </Box>
          <Typography
            component="a"
            href={csvTemplateUrl}
            download="bulk-transfer-fund-to-users.csv"
            variant="caption"
            sx={{
              textAlign: 'right',
              fontStyle: 'italic',
              textDecoration: 'underline',
              cursor: 'pointer',
              display: 'block',
              marginBottom: '16px',
            }}
          >
            {t('databaseExplorer.users.paymentDirectTransfer.bulk.downloadTemplate')}
          </Typography>
          <Stack direction={'column'} spacing={2}>
            {shouldFillRelatedMonth && (
              <TextField
                {...register('relatedMonth')}
                label={t('databaseExplorer.users.paymentDirectTransfer.relatedMonh')}
              />
            )}
            <Controller
              render={({ field }) => (
                <Select {...field}>
                  {Object.keys(TransferReason).map((transferReasonKey) => (
                    <MenuItem key={transferReasonKey} value={transferReasonKey}>
                      {TransferReasonTranslation[transferReasonKey as TransferReason]}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
              name="transferReason"
            />
            <Controller
              render={({ field }) => (
                <Select {...field} label={t('transferFundType')}>
                  {TRANSFER_FUND_TYPE_OPTIONS.map((_transferFundType) => (
                    <MenuItem key={_transferFundType} value={_transferFundType}>
                      {_transferFundType}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
              name="transferType"
            />

            {error && (
              <Typography
                variant="caption"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  display: 'block',
                  marginBottom: '18px',
                  color: 'red',
                }}
              >
                {error}
              </Typography>
            )}
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            paddingRight: ({ spacing }) => spacing(3),
            paddingLeft: ({ spacing }) => spacing(3),
            paddingBottom: ({ spacing }) => spacing(2),
          }}
        >
          {isLoading && (
            <Typography
              variant="caption"
              sx={{
                fontWeight: 'bold',
                display: 'block',
                marginBottom: '18px',
              }}
            >
              Processing : {pointer * BULK_TRANSFER_NUMBER_PER_CALL}/{totalLength}
            </Typography>
          )}
          <ButtonOutlined onClick={toggleModal} disabled={isLoading}>
            {t('action.cancel')}
          </ButtonOutlined>
          <ButtonOutlined onClick={submit} disabled={submitButtonIsDisabled}>
            {t('action.submit')}
          </ButtonOutlined>
        </DialogActions>
      </Dialog>
    </>
  );
};
