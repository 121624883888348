import { useState } from 'react';

import { CommentType, PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Send } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { Panel } from '@/components/layouts/Panel';
import { useOwnUser } from '@/context/own-user.context';
import { formatDate } from '@/utils';
import { IconButton } from '@Elements/buttons/IconButton';
import { TextField } from '@Elements/inputs/TextInput';

import { DeleteComment } from './DeleteComment';
import { CommentsProps, FormattedCommentType, FunctionProps } from './comments.type';
import { getText, useComment } from './use-comments';

export const Comments = ({ entityName, entityId }: CommentsProps) => {
  const { hasPermissions } = useOwnUser();

  const { t } = useTranslation();
  const { comments, onChangeComment, commitComment, deleteComment, state } = useComment({
    entityName,
    entityId,
  });

  const isReadOnly = !hasPermissions(PERMISSIONS.COMMENT.WRITE_COMMENT);

  if (!hasPermissions(PERMISSIONS.COMMENT.READ_COMMENT)) {
    return null;
  }

  return (
    <Panel title="Comments">
      {comments.map(({ comment, answer }, index) => (
        <CommentsGroup
          comment={comment}
          answer={answer}
          key={index}
          textFieldValue={state[comment.id]}
          onChangeComment={onChangeComment}
          commitComment={() => commitComment(comment.id)}
          deleteComment={deleteComment}
          isReadOnly={isReadOnly}
        />
      ))}

      {!isReadOnly && (
        <TextField
          sx={{ marginTop: 2, width: '100%' }}
          placeholder={t('comment.postAction')}
          value={state['root'] || ''}
          onChange={(event) => onChangeComment({ comment: event.target.value })}
          onKeyDown={({ key }) => key === 'Enter' && commitComment()}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => commitComment()} disabled={isCommentEmpty(state['root'])}>
                <Send />
              </IconButton>
            ),
          }}
        />
      )}
    </Panel>
  );
};

const CommentsGroup = ({
  comment,
  answer = [],
  onChangeComment,
  commitComment,
  deleteComment,
  textFieldValue,
  isReadOnly,
}: FormattedCommentType &
  FunctionProps & { textFieldValue: CommentType['comment']; isReadOnly: boolean }) => {
  const [answerIsOpen, setAnswerIsOpen] = useState<boolean>(false);
  const toggleAnswerIsOpen = () => setAnswerIsOpen(!answerIsOpen);
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box sx={{ marginBottom: '12px', marginTop: '8px' }}>
      <SingleComments {...comment} deleteComment={deleteComment} />

      <Box sx={{ marginLeft: 'auto', width: '95%' }}>
        <Accordion disableGutters expanded={answerIsOpen} sx={{ boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ border: 'none', boxShadow: 'none', display: 'none' }}
          />
          <AccordionDetails sx={{ boxShadow: 'none', border: 'none' }}>
            <>
              {answer.map((answerComment, i) => (
                <SingleComments {...answerComment} key={i} deleteComment={deleteComment} />
              ))}
              {!isReadOnly && (
                <TextField
                  css={{ width: '100%', marginTop: '6px' }}
                  placeholder={t('comment.answerAction')}
                  value={textFieldValue || ''}
                  onChange={(event) =>
                    onChangeComment({ comment: event.target.value, parentId: comment.id })
                  }
                  onKeyDown={({ key }) => key === 'Enter' && commitComment()}
                  inputProps={{
                    style: {
                      paddingTop: '6px',

                      paddingBottom: '6px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => commitComment()}
                        size="small"
                        disabled={isCommentEmpty(textFieldValue)}
                      >
                        <Send />
                      </IconButton>
                    ),
                  }}
                />
              )}
            </>
          </AccordionDetails>
        </Accordion>
      </Box>

      <CustomDivider
        onClick={toggleAnswerIsOpen}
        color={theme.palette.grey[400]}
        answer={answer.length}
        isOpen={answerIsOpen}
      />
    </Box>
  );
};
const CustomDivider = ({
  onClick,
  color,
  answer,
  isOpen,
}: {
  onClick: () => void;
  color: React.CSSProperties['backgroundColor'];
  answer: number;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onClick}
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 'auto',
        cursor: 'pointer',
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          backgroundColor: color,
          height: 1,
          maxWidth: '20px',
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
      />
      <p
        css={{
          marginLeft: 12,
          marginRight: 12,
          fontSize: 12,
          marginTop: 8,
          marginBottom: 8,
        }}
      >
        {t(getText(answer, isOpen), { answer })}
      </p>
    </div>
  );
};

const SingleComments = ({
  id,
  createdBy,
  comment,
  createdAt,
  deleteComment,
}: {
  id: CommentType['id'];
  createdBy: CommentType['createdBy'];
  comment: CommentType['comment'];
  createdAt: Date;
  deleteComment?: FunctionProps['deleteComment'];
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: 'auto' }}>
        <Box
          css={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              p: 0,
              fontWeight: 600,
              marginRight: '6px',
              minWidth: 'fit-content',
              fontSize: 12,
            }}
          >
            {createdBy}
          </Typography>
          <Typography sx={{ p: 0, fontSize: 12, color: 'grey' }}>
            {formatDate(createdAt, 'dd/MM/yyyy HH:mm')}
          </Typography>
        </Box>

        <Typography sx={{ p: 0, fontSize: 14 }}>{comment}</Typography>
      </Box>
      {deleteComment ? <DeleteComment commentId={id} deleteComment={deleteComment} /> : null}
    </Box>
  );
};

const isCommentEmpty = (comment?: string) => comment?.trim() === '' || comment === undefined;
