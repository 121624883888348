import { Badge } from '@bits-app/bits-server-data';
import { KeysWithTranslations } from '@bits-app/voggtpit-shared';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { QUERIES_KEYS } from '@/queries';
import { DraftBadge } from '@/voggt-database-explorer/badge/entity/draft-badge';
import { getBadgeTranslations } from '@/voggt-database-explorer/badge/use-cases/get-badge-translations';

type BadgeTranslationsPanelProps = {
  badge: Badge | DraftBadge;
};

export const BadgeTranslationsPanel = ({ badge }: BadgeTranslationsPanelProps) => {
  const { t } = useTranslation();

  const { data: keysWithTranslations } = useQuery<KeysWithTranslations>(
    QUERIES_KEYS.badgeTranslations(badge.name),
    () => getBadgeTranslations(badge),
    { enabled: Boolean(badge.name) },
  );

  return (
    <Card>
      <CardHeader
        title={t('databaseExplorer.badge.panel.translations')}
        titleTypographyProps={{ variant: 'h5' }}
      />

      <CardContent>
        {keysWithTranslations &&
          Object.entries(keysWithTranslations).map(([key, translations]) => (
            <Grid key={key} container flexDirection="column" flexWrap="nowrap" gap={2}>
              <Grid item xs={6}>
                <Typography fontWeight="bold">{key}</Typography>
              </Grid>

              <Stack
                divider={
                  <Divider orientation="horizontal" sx={{ marginTop: 1.5, marginBottom: 1.5 }} />
                }
                spacing={0}
              >
                <Grid item container flexDirection="column" flex={1} gap={2}>
                  <Stack
                    divider={
                      <Divider
                        orientation="horizontal"
                        sx={{ marginTop: 1.5, marginBottom: 1.5 }}
                      />
                    }
                    spacing={0}
                  >
                    {translations.map(({ id, language, translation }) => (
                      <Grid key={id} item container gap={2}>
                        <Grid item xs={1}>
                          {language}
                        </Grid>

                        <Grid item flex={1}>
                          <Typography>{translation}</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
          ))}
      </CardContent>
    </Card>
  );
};
