import WarningIcon from '@mui/icons-material/WarningAmber';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextWithConditionalPrefixIcon } from '@Elements/TextWithConditionalPrefixIcon';

type SellerCancelRequestFooterProps = {
  count: number;
};

export const SellerCancelRequestFooter = ({ count }: SellerCancelRequestFooterProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between" my={1}>
      <Box>
        <TextWithConditionalPrefixIcon
          Icon={WarningIcon}
          sxIconProps={{ color: ({ palette }) => palette.warning.main }}
          isIconVisible
        >
          <Typography>{t('sellerCancelRequests.suspicious')}</Typography>
        </TextWithConditionalPrefixIcon>
      </Box>

      <Box textAlign="right">
        <Typography variant="caption" textTransform="uppercase" letterSpacing={2} color="grey.400">
          {t('sellerCancelRequests.sellerCancelRequestsCount', {
            count,
          })}
        </Typography>
      </Box>
    </Box>
  );
};
