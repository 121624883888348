import { MarketPageType } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useMarketPageList = () => {
  const {
    data: marketPages,
    error,
    isLoading,
    isError,
  } = useQuery({
    queryKey: QUERIES_KEYS.marketPagesList(),
    queryFn: getAllMarketPages,
    initialData: [],
  });

  return {
    marketPages,
    isLoading,
    isError,
    error,
  };
};

const getAllMarketPages = async () => {
  try {
    const { data } = await authenticatedInstance.get<MarketPageType[]>('/market-page');
    return data;
  } catch (error) {
    if (error instanceof AxiosError<{ message?: string }>) {
      throw error.response?.data.message;
    }
    throw String(error);
  }
};
