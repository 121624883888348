import { useState } from 'react';

import { Category } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useOwnUser } from '@/context/own-user.context';

import { CategoryTranslations } from '../category-translations/category-translations';

import { UpsertCategoryForm } from './UpsertCategoryForm';

type UpsertCategoryModalProps = {
  category?: Category;
  categories?: Category[];
  onClose: () => void;
  isOpen: boolean;
};

export const UpsertCategoryModal = ({
  category,
  categories,
  onClose,
  isOpen,
}: UpsertCategoryModalProps) => {
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();

  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<Set<number>>(new Set());
  const [upsertedCategory, setUpsertedCategory] = useState<Category | null>(category ?? null);

  const ownUserCanAddTranslations = hasPermissions(
    PERMISSIONS.CATEGORY.WRITE_CATEGORY_UPDATE_TRANSLATIONS,
  );

  const completeStep = (step: 0 | 1) => {
    setCompletedSteps((steps) => steps.add(step));
    setActiveStep(step + 1);
  };

  const afterCategoryCreationSuccess = (category: Category) => {
    setUpsertedCategory(category);
    completeStep(0);

    if (!ownUserCanAddTranslations) {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen}>
      <Grid container justifyContent="space-between" alignItems="center" p={3} gap={2}>
        <Grid item flex={1}>
          <Stepper activeStep={activeStep}>
            <Step completed={completedSteps.has(0)}>
              <StepButton disabled={!category} onClick={() => setActiveStep(0)}>
                <StepLabel>
                  {!category
                    ? t(`databaseExplorer.category.upsertCategory.create.action`)
                    : t(`databaseExplorer.category.upsertCategory.update.action`)}
                </StepLabel>
              </StepButton>
            </Step>

            {ownUserCanAddTranslations && (
              <Step completed={completedSteps.has(1)}>
                <StepButton disabled={!category} onClick={() => setActiveStep(1)}>
                  <StepLabel>
                    {t('databaseExplorer.category.upsertCategory.form.translate')}
                  </StepLabel>
                </StepButton>
              </Step>
            )}
          </Stepper>
        </Grid>

        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      </Grid>

      <DialogContent
        sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%', gap: 4 }}
      >
        {activeStep === 0 ? (
          <UpsertCategoryForm
            categories={categories}
            category={category}
            afterSuccess={afterCategoryCreationSuccess}
            ownUserCanAddTranslations={ownUserCanAddTranslations}
          />
        ) : null}

        {activeStep === 1 && upsertedCategory ? (
          <CategoryTranslations category={upsertedCategory} afterSuccess={onClose} />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
