import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

interface EnvironmentData {
  environment: string;
}

export const useEnvironment = () => {
  return useQuery<EnvironmentData>({
    queryKey: QUERIES_KEYS.environment(),
    queryFn: async () => {
      const { data } = await authenticatedInstance.get<EnvironmentData>('/environment');
      return data;
    },
  });
};
