import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryColumn,
  UpdateDateColumn,
} from 'typeorm';

import { CountryCodeIso2 } from './CountryCodeIso2';
import { CountryCodeIso3 } from './CountryCodeIso3';
import { Language } from './Language';
import { ShippingAddress } from './ShippingAddress';
import { Show } from './Show';
import { TermsAndConditions } from './TermsAndConditions';
import { User } from './User';

export const DEFAULT_COUNTRY_ID = 'NULL';

@Entity()
export class Country {
  @PrimaryColumn()
  id!: string;

  @Column({ type: 'enum', enum: CountryCodeIso2, unique: true })
  iso2Code!: CountryCodeIso2;

  @Column({ type: 'enum', enum: CountryCodeIso3, unique: true })
  iso3Code!: CountryCodeIso3;

  @Column()
  name!: string;

  @OneToMany(() => Show, (show) => show.country)
  shows!: Show[];

  @OneToMany(() => User, (user) => user.country)
  users!: User[];

  @ManyToOne(() => Language)
  @JoinColumn({ name: 'defaultLanguageId' })
  defaultLanguage!: Language;

  @Column()
  defaultLanguageId!: Language['id'];

  @OneToMany(() => TermsAndConditions, (TaC) => TaC.country)
  termsAndConditions!: TermsAndConditions[];

  @OneToMany(() => ShippingAddress, (sa) => sa.resolvedCountry)
  shippingAddresses!: ShippingAddress[];

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;
}
