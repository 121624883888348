import { useState } from 'react';

import { TableContainer, Table, TableBody, TableRow } from '@mui/material';

import { OrderItem } from '@/entities/order-item.entity';
import { LoadingRow } from '@Elements/LoadingRow';
import { TableCellBodyNoBorderBottom } from '@Elements/StyledTableCell';
import { TableWithStickyColumns } from '@Elements/TableWithStickyColumns';

import { OrderItemsMassUpdateHeader } from './OrderItemsMassUpdateHeader';
import { OrderItemsMassUpdateRow } from './OrderItemsMassUpdateRow';
import { StickyHeader, StickyBody } from './StickyTable';
import { HeadCell } from './hooks/use-header';

type OrderItemsMassUpdateTableProps = {
  orderItems: OrderItem[];
  headCells: HeadCell[];
  stickyHeadCells: HeadCell[];
  areAllSelected: boolean;
  order: 'asc' | 'desc';
  orderBy: string;
  onToggleAll: () => void;
  onOrderBy: (field: string) => void;
  isUnsaved: boolean;
  onToggleItem: (id: number) => void;
  loading: boolean;
  selected: number[];
  disabledSelectRow: boolean;
  isReadOnly: boolean;
};

export const OrderItemsMassUpdateTable = ({
  orderItems,
  headCells,
  stickyHeadCells,
  areAllSelected,
  order,
  orderBy,
  onToggleAll,
  onOrderBy,
  isUnsaved,
  onToggleItem,
  loading,
  selected,
  disabledSelectRow,
  isReadOnly,
}: OrderItemsMassUpdateTableProps) => {
  const [scrollableTableRef, setScrollableTableRef] = useState<HTMLDivElement | null>(null);

  return (
    <TableWithStickyColumns
      tableHeader={
        <StickyHeader
          disabledSelectRow={disabledSelectRow}
          headCells={stickyHeadCells}
          selectedCount={selected.length}
          areAllSelected={areAllSelected}
          onToggleAll={onToggleAll}
          orderBy={orderBy}
          order={order}
          onOrderBy={onOrderBy}
          isUnsaved={isUnsaved}
          loading={loading}
          isReadOnly={isReadOnly}
        />
      }
      tableBody={
        <StickyBody
          disabledSelectRow={disabledSelectRow}
          headCells={stickyHeadCells}
          handleToggleItem={onToggleItem}
          loading={loading}
          orderItems={orderItems}
          selected={selected}
          showEmptyData={orderItems.length === 0 && !loading}
          isReadOnly={isReadOnly}
        />
      }
      sxTableContainer={{
        minWidth: 600,
        maxWidth: 600,
      }}
      mainTableRef={scrollableTableRef}
    >
      <TableContainer
        ref={setScrollableTableRef}
        component="div"
        sx={{ backgroundColor: ({ palette }) => palette.background.paper }}
      >
        <Table stickyHeader>
          <OrderItemsMassUpdateHeader
            headCells={headCells}
            orderBy={orderBy}
            order={order}
            onOrderBy={onOrderBy}
            isUnsaved={isUnsaved}
          />
          <TableBody>
            {orderItems.length === 0 && !loading && (
              <TableRow>
                <TableCellBodyNoBorderBottom colSpan={headCells.length + 1} />
              </TableRow>
            )}

            {loading ? (
              <LoadingRow cellCount={headCells.length + 1} />
            ) : (
              orderItems.map((orderItem) => (
                <OrderItemsMassUpdateRow key={orderItem.id} orderItem={orderItem} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWithStickyColumns>
  );
};
