import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';

import { ButtonContained } from '@Elements/buttons/ButtonContained';

type RestoreSsoProps = {
  isOpen: boolean;
  onConfirm: () => void;
  toggle: () => void;
};

export const RestoreSso = ({ onConfirm, toggle, isOpen }: RestoreSsoProps) => {
  return (
    <Dialog open={isOpen}>
      <Grid container flexDirection="column" p={1} gap={1}>
        <Grid item>
          <DialogTitle id="alert-dialog-title">Restore user SSO</DialogTitle>

          <DialogContent>
            <DialogContentText>
              The SSO will be restored. This means that the user will be able to log in with their
              SSO account only.
            </DialogContentText>
          </DialogContent>
        </Grid>

        <DialogActions>
          <Button onClick={toggle}>Cancel</Button>
          <ButtonContained onClick={onConfirm}>Restore SSO account</ButtonContained>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};
