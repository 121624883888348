import { Badge } from '@bits-app/bits-server-data';

import { authenticatedInstance } from '@/axios/axios.instance';

import { DraftBadge } from '../entity/draft-badge';

export const editBadge = async (
  formData: FormData,
  badgeId: Badge['id'] | DraftBadge['id'],
  isDraft?: boolean,
) => {
  const result = await authenticatedInstance.patch<DraftBadge | Badge>(
    `/database-explorer/badge${isDraft ? '/draft' : ''}/${badgeId}`,
    formData,
    {
      headers: {
        ['Content-Type']: 'multipart/form-data',
      },
    },
  );

  return result.data;
};
