import { SellerNews } from '@bits-app/bits-server-data';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { Information } from '@/components/layouts/PanelDetails';

export const cellDefinitionSellerNews: CellDefinition<SellerNews>[] = [
  {
    id: 'internalName',
    label: 'Internal name',
    minWidth: 250,
  },
  {
    id: 'countryId',
    label: 'Country',
    type: 'internal-link',
    getLink: (sellerNews) => ({
      label: sellerNews?.country?.name,
      link: `/country/${sellerNews?.countryId}`,
    }),
  },
  {
    id: 'title',
    label: 'title',
  },
  {
    id: 'message',
    label: 'Message',
    minWidth: 400,
  },
  {
    id: 'url',
    label: 'Url',
    type: 'external-link',
    getLink: (sellerNews) => ({
      label: sellerNews?.url,
      link: sellerNews?.url,
    }),
  },
  {
    id: 'isActive',
    label: 'Is active',
    type: 'boolean',
  },
  {
    id: 'endAt',
    label: 'End at',
    type: 'date-with-time',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date-with-time',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
  },
  {
    id: 'deletedAt',
    label: 'Deleted at',
    type: 'date-with-time',
  },
];

export const panelInformation: Information<SellerNews>[] = [
  { label: 'Internal name', value: 'internalName' },
  {
    label: 'Country',
    value: 'countryId',
    format: (sellerNews) => sellerNews?.country?.name,
    type: 'internal-link',
    link: (sellerNews) => `/country/${sellerNews?.countryId}`,
  },
  { label: 'Title', value: 'title' },
  { label: 'Message', value: 'message' },
  { label: 'Url', value: 'url', type: 'external-link', link: (sellerNews) => sellerNews?.url },
];

export const timestampPanelInformation: Information<SellerNews>[] = [
  { label: 'Created at', value: 'createdAt', type: 'date-with-time' },
  { label: 'Updated at', value: 'updatedAt', type: 'date-with-time' },
  { label: 'Deleted at', value: 'deletedAt', type: 'date-with-time' },
];

export const statusPanelInformation: Information<SellerNews>[] = [
  { label: 'Is active', value: 'isActive', type: 'boolean' },
  { label: 'Start at', value: 'startAt', type: 'date-with-time' },
  { label: 'End at', value: 'endAt', type: 'date-with-time' },
];
