import { Box, FormHelperText } from '@mui/material';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

type FileWithPreview = File & { preview: string };

type ImageUploadProps = {
  currentImageUrl?: string;
  maxSize?: number;
  imageAcceptedMimeTypes?: Record<string, string[]>;
  image: FileWithPreview | null;
  imageErrors?: FileError[] | null;
  onDropImage: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[]) => void;
  dropImageLabel?: string;
};

export const ImageUpload = ({
  maxSize,
  currentImageUrl,
  imageAcceptedMimeTypes,
  image,
  onDropImage,
  imageErrors,
  dropImageLabel,
}: ImageUploadProps) => {
  const { t } = useTranslation();

  const { getRootProps } = useDropzone({
    onDrop: onDropImage,
    maxFiles: 1,
    accept: imageAcceptedMimeTypes,
    maxSize,
  });

  return (
    <>
      <Box
        {...getRootProps()}
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[200],
          width: '100%',
          height: '50%',
          minHeight: 100,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          px: 2,
          cursor: 'pointer',
        })}
        borderRadius={4}
      >
        {dropImageLabel ?? t('imageUpload.drop')}

        {(image || currentImageUrl) && (
          <Box
            component="img"
            src={image ? image.preview : currentImageUrl}
            sx={{ maxWidth: 50 }}
            onLoad={image ? () => URL.revokeObjectURL(image.preview) : undefined}
          />
        )}
      </Box>

      {imageErrors &&
        imageErrors.map((imageError, index) => (
          <FormHelperText error key={[imageError.code, index].join('-')}>
            {imageError.message}
          </FormHelperText>
        ))}
    </>
  );
};
