import { authActions } from '@/auth/auth.slice';
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError';
import { AppThunkAction } from '@/redux/store';
import { EntityWrapper, ResourceType } from '@/voggt-database-explorer/redux/database-explorer';

export const updateResource =
  <T extends EntityWrapper>(
    {
      resourceId,
      resourceType,
      changes,
    }: {
      resourceId: T['id'];
      resourceType: ResourceType;
      changes: Partial<T>;
    },
    onSuccess?: () => void,
  ): AppThunkAction<Promise<void>> =>
  async (dispatch, _, { databaseExplorerGateway, snackbarGateway }) => {
    try {
      await databaseExplorerGateway.commitChange<T>(resourceId, resourceType, changes);

      snackbarGateway.success('databaseExplorer.successfulUpdate', {
        interpolationMap: { resourceId, resourceType },
      });

      onSuccess?.();
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        dispatch(authActions.setShouldReLog(true));

        return;
      }

      snackbarGateway.error('somethingWrongHappened');
    }
  };
