import {
  Badge,
  Product,
  ShippingAddress,
  Show,
  Currency,
  ProductStatusInAuction,
  ProductType,
  Category,
} from '@bits-app/bits-server-data';
import {
  centsToUnit,
  currencySymbol,
  formatAmount,
  formatAmountWithCurrency,
  ZendeskTicket,
} from '@bits-app/voggtpit-shared';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { differenceInMinutes } from 'date-fns';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { OwnUser } from '@/domain/entities/own-user.entity';
import {
  formatPercentage,
  getForestAdminLink,
  getLinkShippingTracking,
  getStripeCustomerUrl,
  getStripePaymentUrl,
  getStripeTransferUrl,
} from '@/utils';
import { Order } from '@/voggt-database-explorer/order/entities/order.entity';

import { User } from '../users';
import { UserTargetPromotionTypeWithPromotion } from '../views/profile/Promotions/type';

const CURRENCY_OPTIONS = Object.values(Currency) as Currency[];
const PRODUCT_TYPE_OPTIONS = Object.values(ProductType) as ProductType[];
const PRODUCT_STATUS_OPTIONS = Object.values(ProductStatusInAuction) as ProductStatusInAuction[];

export const CellUserDefinition: CellDefinition<User>[] = [
  {
    id: 'id',
    label: 'ID',
    type: 'internal-link',
    getLink: (user) => ({ link: `/user/${user?.id}`, label: user.id }),
  },
  { id: 'username', label: 'Username', type: 'string', enableSorting: true },
  { id: 'email', label: 'Email' },
  { id: 'phoneNumber', label: 'Phone Number' },
  {
    id: 'stripeCustomerId',
    label: 'Stripe Customer',
    type: 'external-link',
    getLink: (user) => ({
      link: getStripeCustomerUrl(user.stripeCustomerId),
      label: user.stripeCustomerId,
    }),
  },
  { id: 'isSeller', label: 'Is Seller', type: 'boolean', width: 120 },
  { id: 'accountDeletedAt', label: 'Deleted at', type: 'date-with-time', enableSorting: true },
  { id: 'createdAt', label: 'Created at', type: 'date-with-time', enableSorting: true },
  { id: 'updatedAt', label: 'Updated at', type: 'date-with-time', enableSorting: true },
];

export const getCellDefinitionForShowInUser = (
  categories?: Category[],
): CellDefinition<Show & { gmv?: number }>[] => {
  return [
    {
      id: 'id',
      label: 'ID',
      type: 'internal-link',
      getLink: (show) => ({ link: `/show/${show?.id}`, label: show?.id }),
    },
    {
      id: 'category',
      label: 'Category',
      accessorFn: (show) => show?.category?.name,
      columnFilter: {
        type: 'multi-select',
        options: (categories ?? [])
          .map((category) => ({ value: category.name }))
          .filter((option) => Boolean(option?.value))
          .sort((optionA, optionB) => optionA.value.localeCompare(optionB.value)),
      },
    },
    { id: 'name', label: 'Show name' },
    {
      id: 'user',
      label: 'Seller',
      accessorFn: (show) => show.user.username,
      type: 'internal-link',
      getLink: (show) => ({ link: `/user/${show?.userId}`, label: show?.user.username }),
    },
    {
      id: 'startAt',
      label: 'Start at',
      type: 'date-with-time',
    },
    {
      id: 'endedAt',
      label: 'End at',
      type: 'date-with-time',
    },
    {
      id: 'gmv',
      label: 'GMV',
      getValue: (show) => formatAmountWithCurrency(show.gmv || 0, show.currency),
      type: 'money',
    },
    {
      id: 'isFeatured',
      label: 'isFeatured',
      type: 'boolean',
      columnFilter: { type: 'switch' },
    },
    {
      id: 'isPremier',
      label: 'isPremier',
      type: 'boolean',
      columnFilter: { type: 'switch' },
    },
    {
      id: 'description',
      label: 'Description',
    },
    {
      id: 'duration' as keyof Show,
      label: 'Duration',
      getValue: (show) => {
        if (!show.startAt || !show.endedAt) return 'Show is not over';
        const start = new Date(show.startAt);
        const end = new Date(show.endedAt);
        const durationMinutes = differenceInMinutes(end, start);
        const hours = Math.floor(durationMinutes / 60);
        const minutes = durationMinutes % 60;
        return `${hours} hours ${minutes} minutes`;
      },
    },
    {
      id: 'currency',
      label: 'Currency',
      type: 'badge',
      columnFilter: {
        type: 'multi-select',
        options: CURRENCY_OPTIONS.map((currency) => ({ value: currency })),
      },
    },
    {
      id: 'percentageFee',
      label: 'Percentage Fee',
      columnFilter: { type: 'text' },
    },
    {
      id: 'fixedFee',
      label: 'Fixed Fee',
      columnFilter: { type: 'text' },
    },
    {
      id: 'isAnniversary',
      label: 'isAnniversary',
      type: 'boolean',
      columnFilter: { type: 'switch' },
    },
    {
      id: 'createdAt',
      label: 'Created at',
      type: 'date-with-time',
    },
    {
      id: 'updatedAt',
      label: 'Updated at',
      type: 'date-with-time',
    },
    {
      id: 'deletedAt',
      label: 'Deleted at',
      type: 'date-with-time',
    },
  ];
};

export const CellShippingAddressDefinition: CellDefinition<ShippingAddress>[] = [
  {
    id: 'id',
    label: 'ID',
    type: 'internal-link',
    getLink: (shippingAddress) => ({
      link: `/shipping-address/${shippingAddress?.id}`,
      label: shippingAddress?.id,
    }),
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'phoneNumber',
    label: 'Phone number',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'inputLine1',
    label: 'Input line 1',
  },
  {
    id: 'inputLine2',
    label: 'Input line 2',
  },
  {
    id: 'inputCity',
    label: 'Input city',
  },
  {
    id: 'inputPostalCode',
    label: 'Postal Code',
  },
  {
    id: 'inputCountry',
    label: 'Country',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    type: 'date',
  },
  {
    id: 'deletedAt',
    label: 'Deleted at',
    type: 'date',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
  },
];

export const CellOrderDefinition: CellDefinition<Order>[] = [
  {
    id: 'id',
    label: 'OrderId',
    type: 'internal-link',
    getLink: (order) => ({ link: `/order/${order?.id}`, label: order.id }),
  },
  {
    id: 'status',
    label: 'Status',
    type: 'badge',
  },
  {
    id: 'paymentStatus',
    label: 'Payment Status',
    type: 'badge',
  },
  {
    id: 'showId',
    label: 'Show ID',
    type: 'internal-link',
    getLink: (order) => ({ link: `/show/${order?.showId}`, label: order?.showId }),
  },
  {
    id: 'seller',
    label: 'Seller',
    type: 'internal-link',
    accessorFn: (order) => order?.seller.username,
    getLink: (order) => ({ link: `/user/${order?.sellerId}`, label: order.seller.username }),
  },
  {
    id: 'customer',
    label: 'Buyer',
    type: 'internal-link',
    accessorFn: (order) => order?.customer.username,
    getLink: (order) => ({ link: `/user/${order?.customerId}`, label: order.customer.username }),
  },
  {
    id: 'product',
    label: 'Product',
    type: 'internal-link',
    accessorFn: (order) => order?.product.name,
    getLink: (order) => ({ link: `/product/${order?.productId}`, label: order.product.name }),
  },
  {
    id: 'amount',
    label: 'Amount',
    type: 'money',
    getValue: (order) =>
      `${formatAmount(centsToUnit(Number(order.amount)))}${currencySymbol(order.currency)}`,
  },
  {
    id: 'shippingAmount',
    label: 'Shipping amount',
    type: 'money',
    getValue: (order) =>
      order.shippingAmount != null
        ? `${formatAmount(centsToUnit(Number(order.shippingAmount)))}${currencySymbol(
            order.currency,
          )}`
        : '',
  },
  {
    id: 'deductedShippingCost',
    label: 'Deducted shipping cost',
    type: 'money',
    getValue: (order) =>
      order.deductedShippingCost != null
        ? `${formatAmount(centsToUnit(Number(order.deductedShippingCost)))}${currencySymbol(
            order.currency,
          )}`
        : '',
  },
  {
    id: 'promotionAmount',
    label: 'Promotion amount',
    type: 'money',
    getValue: (order) =>
      order.promotionAmount != null
        ? `${formatAmount(centsToUnit(Number(order.promotionAmount)))}${currencySymbol(
            order.currency,
          )}`
        : '',
  },
  {
    id: 'promotionTopUpAmountTransferred',
    label: 'Promotion top up amount transferred',
    type: 'money',
    getValue: (order) =>
      order.promotionTopUpAmountTransferred != null
        ? `${formatAmount(
            centsToUnit(Number(order.promotionTopUpAmountTransferred)),
          )}${currencySymbol(order.currency)}`
        : '',
  },
  {
    id: 'promotionTopUpStripeTransferId',
    label: 'Promotion top up stripe transfer ID',
    type: 'external-link',
    getLink: (order) => ({
      link: getStripeTransferUrl(order?.promotionTopUpStripeTransferId),
      label: order?.promotionTopUpStripeTransferId,
    }),
  },
  {
    id: 'refundedAmount',
    label: 'Refunded amount',
    type: 'money',
    getValue: (order) =>
      order.refundedAmount > 0 && order.refundReason
        ? `${formatAmount(centsToUnit(Number(order.refundedAmount)))}${currencySymbol(
            order.currency,
          )}`
        : '',
  },
  {
    id: 'refundReason',
    label: 'Refund reason',
  },
  {
    id: 'refundAgent',
    label: 'Refund agent',
  },
  {
    id: 'refundDebitedAccount',
    label: 'Refund debited account',
  },
  {
    id: 'refundedAt',
    label: 'Refund date',
    type: 'date-with-time',
  },
  {
    id: 'feeDeductibleFromBuyerPayment',
    label: 'Commission',
    type: 'money',
    getValue: (order) =>
      order.feeDeductibleFromBuyerPayment != null
        ? `${formatAmount(
            centsToUnit(Number(order.feeDeductibleFromBuyerPayment)),
          )}${currencySymbol(order.currency)}`
        : '',
  },
  {
    id: 'cancellationReason',
    label: 'Cancellation reason',
  },
  {
    id: 'cancellationRequesterType',
    label: 'Cancellation requester type',
  },
  {
    id: 'cancellationRequestedAt',
    label: 'Cancellation requested at',
    type: 'date-with-time',
  },
  {
    id: 'createdAt',
    label: 'Purchased at',
    type: 'date-with-time',
  },
  {
    id: 'shippingProvider',
    label: 'Shipping Provider',
  },
  {
    id: 'trackingNumber',
    label: 'Tracking Number',
    type: 'external-link',
    getLink: (order) => ({
      link: getLinkShippingTracking(order),
      label: order.trackingNumber ?? '',
    }),
  },
  {
    id: 'stripePaymentIntentId',
    label: 'Payment intent',
    type: 'external-link',
    getLink: (order) => ({
      link: getStripePaymentUrl(order.stripePaymentIntentId || ''),
      label: order.stripePaymentIntentId ?? '',
    }),
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
  },
];

export const CellProductDefinition: CellDefinition<Product>[] = [
  {
    id: 'id',
    label: 'ProductId',
    type: 'internal-link',
    getLink: (product) => ({ link: `/product/${product?.id}`, label: product.id }),
    columnFilter: { type: 'text' },
  },
  {
    id: 'name',
    label: 'Name',
    enableColumnFilter: false,
  },
  {
    id: 'description',
    label: 'Description',
    enableColumnFilter: false,
  },
  {
    id: 'currency',
    label: 'Currency',
    type: 'badge',
    columnFilter: {
      type: 'multi-select',
      options: CURRENCY_OPTIONS.map((currency) => ({ value: currency })),
    },
  },
  {
    id: 'status',
    label: 'Status',
    type: 'badge',
    columnFilter: {
      type: 'multi-select',
      options: PRODUCT_STATUS_OPTIONS.map((type) => ({ value: type })),
    },
  },
  {
    id: 'type',
    label: 'Type',
    type: 'badge',
    columnFilter: {
      type: 'multi-select',
      options: PRODUCT_TYPE_OPTIONS.map((type) => ({ value: type })),
    },
  },
  {
    id: 'show',
    label: 'Show name',
    accessorFn: (product) => product?.show?.name,
    enableColumnFilter: false,
  },
  {
    id: 'showId',
    label: 'Show ID',
    type: 'internal-link',
    getLink: (product) => ({ link: `/show/${product?.showId}`, label: product?.show?.id }),
    columnFilter: { type: 'text' },
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
    enableColumnFilter: false,
  },
];

export const CellBadgeDefinition: CellDefinition<Badge>[] = [
  {
    id: 'id',
    label: 'ID',
    type: 'internal-link',
    getLink: (badge) => ({ link: `/badge/${badge.id}`, label: badge?.id }),
  },
  {
    id: 'filename',
    label: 'Image url',
    type: 'image',
    getValue: (badge) => `${process.env.REACT_APP_CDN_UNOPTIMIZED_BADGES_URL}/${badge.filename}`,
  },
  {
    id: 'name',
    label: 'name',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    type: 'date-with-time',
  },
];

export const CellUserTargetPromotionDefinition: CellDefinition<UserTargetPromotionTypeWithPromotion>[] =
  [
    {
      id: 'name',
      label: 'Name',
      type: 'external-link',
      getLink: (record) => ({
        label: record.promotion.name,
        link: getForestAdminLink({ entityId: record.promotion.id, entity: 'promotion' }),
      }),
    },
    {
      id: 'promotionId',
      label: 'Type',
      type: 'badge',
      getValue: (record) => {
        return record.promotion.type;
      },
    },
    {
      id: 'status',
      label: 'Status',
      type: 'badge',
    },
    {
      id: 'usedAt',
      label: 'Used at',
      type: 'date-with-time',
    },
    {
      id: 'expireAt',
      label: 'Expires at',
      type: 'date',
    },
    {
      id: '_promotionAmount',
      label: 'Amount',
      type: 'money',
      getValue: (record) =>
        record.promotion.promotionAmount
          ? `${formatAmountWithCurrency(
              centsToUnit(record.promotion.promotionAmount),
              record.promotion.currency,
            )}`
          : '',
    },
    {
      id: '_promotionMaxAmount',
      label: 'Max amount',
      type: 'money',
      getValue: (record) =>
        record.promotion.promotionMaxAmount
          ? `${formatAmountWithCurrency(
              centsToUnit(record.promotion.promotionMaxAmount),
              record.promotion.currency,
            )}`
          : '',
    },
    {
      id: '_promotionPercentage',
      label: 'Percentage',
      getValue: (record) =>
        record.promotion.promotionPercentage
          ? formatPercentage(record.promotion.promotionPercentage)
          : '',
    },
    {
      id: 'order',
      label: 'Order',
      type: 'internal-link',
      getLink: (record) => ({
        label: record.order?.id,
        link: `/order/${record.order?.id}`,
      }),
    },
    {
      id: 'referralId',
      label: 'Referral',
      type: 'internal-link',
      getLink: (record) => ({
        label: record?.referral?.username,
        link: `/user/${record?.referralId}`,
      }),
    },
    {
      id: 'createdAt',
      label: 'Created at',
      type: 'date',
    },
    {
      id: 'updatedAt',
      label: 'Updated at',
      type: 'date-with-time',
    },
  ];

const getSatisfactionScore = (score: string) => {
  if (score !== 'good' && score !== 'bad') {
    return 'No response';
  } else return score;
};

export const cellZendeskTicketsDefinition = (ownUser: OwnUser): CellDefinition<ZendeskTicket>[] => {
  return [
    {
      id: 'id',
      label: 'ID',
      type: ownUser.isAuthenticatedToZendesk ? 'external-link' : 'string',
      getLink: (ticket) => ({
        link: `https://voggt.zendesk.com/agent/tickets/${ticket.id}`,
        label: `${ticket.id}`,
        icon: <OpenInNewIcon style={{ width: '1rem' }} />,
      }),
    },
    { id: 'rawSubject', label: 'Subject' },
    { id: 'status', label: 'Status' },
    { id: 'createdAt', label: 'Created At', type: 'date' },
    { id: 'agentName', label: 'Assignee' },
    {
      id: 'satisfactionRating',
      label: 'CSAT',
      getValue: (record) => {
        return getSatisfactionScore(record.satisfactionRating);
      },
    },
  ];
};
