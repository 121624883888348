export type Operator = 'equal' | 'between' | 'above' | 'below';

export type OperatorFilterValue<T> =
  | { operator: Operator; value: T }
  | { operator: 'between'; from: T; to: T };

export const isRangeFilter = <T>(
  filter:
    | { operator: Operator; value: T }
    | { operator: 'between'; from: T; to: T },
): filter is { operator: 'between'; from: T; to: T } => 'to' in filter;

export type DateFilterValue = OperatorFilterValue<string>;
export type NumberFilterValue = OperatorFilterValue<number>;
export type SellerBuyerFilterValue = {
  seller: string;
  buyer: string;
};

export interface FindOptions {
  page: number;
  limit: number;
  search?: string;
  order: {
    field: string;
    value: 'ASC' | 'DESC';
  };
}
